import { TDH_COMPANY_ID } from "../../utils/constants";
import getUserCompanyIdLocal from "../../graphqlQueries/getUserCompanyIdLocal";
import { useQuery } from "@apollo/client";
import { userCompanyIdVar } from "../../utils/ApolloCache";

type UserCompanyIdResult = {
  userCompanyId: string | null;
};

const setCompanyId = (newId: string) => {
  localStorage.setItem(TDH_COMPANY_ID, newId);
  userCompanyIdVar(newId);
  return newId;
};

export const useCompanyId = (): [string | null, (newId: string) => string] => {
  const { data: userCompanyIdData } = useQuery<UserCompanyIdResult>(getUserCompanyIdLocal);
  const companyId = userCompanyIdData?.userCompanyId ?? null;

  return [companyId, setCompanyId];
};
