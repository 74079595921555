import { gql } from "@apollo/client";

const deleteProduct = gql`
  mutation deleteProduct($id: uuid!, $linkIds: [uuid!]) {
    updateProductByPk(pk_columns: { id: $id }, _set: { deletedAt: "now()" }) {
      id
      productInformations {
        verifiedWebshopLink {
          id
        }
        verifiedProductLink {
          id
        }
      }
      productVideos {
        verifiedVideoLink {
          id
        }
      }
    }
    updateVerifiedLink(where: { id: { _in: $linkIds } }, _set: { deletedAt: "now()" }) {
      affected_rows
    }
  }
`;

export type DeleteProductVariables = {
  id: string;
  linkIds: string[];
};

type VerifiedLinkId = {
  id: string;
};

export type ProductInfoLinkIds = {
  verifiedWebshopLink: VerifiedLinkId;
  verifiedProductLink: VerifiedLinkId;
};

export type ProductVideoLinkId = {
  verifiedVideoLink: VerifiedLinkId;
};

export type DeleteProductMutationResult = {
  updateProductByPk: {
    id: string;
    productInformations: ProductInfoLinkIds[];
    productVideos: ProductVideoLinkId[];
  };
  updateVerifiedLink: {
    affected_rows: number;
  };
};

export default deleteProduct;
