import React, { useEffect, useState } from "react";

import LoadingResults from "../curatorWorkspace/LoadingResults";
import NoResults from "../curatorWorkspace/NoResults";
import ProductListContainer from "../ProductListContainer";
import { PublishedProduct } from "../../graphqlQueries/getPublishedProducts";

type PublishedProductListProps = {
  selectedProductId?: string;
  products?: PublishedProduct[];
  loading: boolean;
  paging?: React.ReactNode;
  header: React.ReactNode;
  productListItem: React.ReactNode;
  rootPagePath?: string;
};

let timer: NodeJS.Timeout;
const TIMEOUT_DELAY = 300;

const PublishedProductList = React.forwardRef<HTMLDivElement, PublishedProductListProps>(
  ({ products, loading, paging, header, productListItem }, ref) => {
    const [showLoading, setShowLoading] = useState(false);

    // show loading indicator only after a moment so
    // fast connections don't see flickering UI
    useEffect(() => {
      if (timer) {
        clearTimeout(timer);
      }
      if (loading) {
        timer = setTimeout(() => {
          setShowLoading(true);
        }, TIMEOUT_DELAY);
      } else {
        setShowLoading(false);
      }
    }, [loading]);

    return (
      <ProductListContainer
        ref={ref}
        pagination={!!paging}
        className="published-products-list-container"
      >
        {header}
        {showLoading && <LoadingResults />}
        {products?.length === 0 && !loading && <NoResults />}
        {productListItem}
        {paging && paging}
      </ProductListContainer>
    );
  }
);

export default PublishedProductList;
