import { RegisterOptions, useFormContext } from "react-hook-form";

import { Form } from "react-bootstrap";
import React from "react";

type PhoneInputProps = {
  name: string;
  defaultValue?: string | string[] | number;
  autoFillProtocol?: boolean;
  rules?: RegisterOptions;
  isInvalid?: boolean;
  "aria-describedby"?: string;
  disabled?: boolean;
};

const addAreaCodeIfNotPresent = (event: React.FocusEvent<HTMLInputElement>) => {
  const value = event.target.value.trim();
  if (value.length >= 1 && value.charAt(0) === "0") {
    return value.replace("0", "+358");
  }

  return value;
};

const PhoneInput = ({
  name,
  defaultValue,
  autoFillProtocol = true,
  rules,
  isInvalid,
  "aria-describedby": ariaDescBy,
  disabled,
}: PhoneInputProps) => {
  const { register, errors, setValue } = useFormContext();

  return (
    <Form.Control
      type="tel"
      name={name}
      disabled={disabled}
      defaultValue={defaultValue}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        if (autoFillProtocol) {
          const value = addAreaCodeIfNotPresent(e);
          setValue(name, value, { shouldValidate: true });
        }
      }}
      isInvalid={isInvalid}
      ref={rules ? register(rules) : register()}
      className={errors[name] ? "is-invalid" : ""}
      aria-describedby={ariaDescBy}
    />
  );
};

export default PhoneInput;
