import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaintenanceNoticeListItem } from "./MaintenanceNoticeListItem";
import { useIsSmallScreen } from "../MediaQuery";
import { MaintenanceDataOut, MaintenanceUpdateQueryVars } from "../../types/bannerTypes";
import { getActiveMaintenance } from "../../utils/maintenanceBannerUtils";
import { useMutation } from "@apollo/client";
import { updateMaintenanceQuery } from "../../graphqlQueries/maintenanceBanner/updateMaintenanceQuery";
import { getHasuraRoleContext } from "../../utils/functions";
import { PopoverStateType, UserRole } from "../../types";
import { MaintenanceCtx } from "../../context/MaintenanceContext";

type MaintenanceNoticeListProps = {
  data: MaintenanceDataOut[];
  openEditForm: (openEdit: MaintenanceDataOut) => void;
  deleteMaintenance: (maintenanceId: string) => void;
};

export const MaintenanceNoticeList = ({
  data,
  openEditForm,
  deleteMaintenance,
}: MaintenanceNoticeListProps) => {
  const { t } = useTranslation();
  const isMobile = useIsSmallScreen();
  const { updateStatus } = useContext(MaintenanceCtx);
  const [loading, setLoading] = useState(true);
  const [isActiveMap, setIsActiveMap] = useState<Map<string, boolean>>(new Map<string, boolean>());

  const [openPopovers, setOpenPopovers] = useState<PopoverStateType[]>(
    data.map(({ id }) => ({
      id: id,
      isOpen: false,
    }))
  );

  useEffect(() => {
    const composedData = getActiveMaintenance(data);

    composedData.forEach((it) => {
      setIsActiveMap((curr) => new Map(curr.set(it.id, it.active)));
    });
    setLoading(false);
  }, [data]);

  // Set popover open if id matches, else close
  const changePopOver = (popoverId: string) =>
    setOpenPopovers((prevState) => prevState.map(({ id }) => ({ id, isOpen: id === popoverId })));
  // Close all
  const closePopovers = () =>
    setOpenPopovers((prevState) => prevState.map(({ id }) => ({ id, isOpen: false })));
  // If clicked popover is already open close all, otherwise open it and close others.
  const handlePopoverButtonClick = (popoverId: string) => {
    openPopovers.find(({ id }) => id === popoverId)?.isOpen
      ? closePopovers()
      : changePopOver(popoverId);
  };

  const showPopover = (id: string) => !!openPopovers.find((it) => it.id === id && it.isOpen);

  const [updateMaintenanceBannerData] = useMutation<MaintenanceDataOut, MaintenanceUpdateQueryVars>(
    updateMaintenanceQuery,
    {
      context: getHasuraRoleContext(UserRole.ManageDatahub),
      fetchPolicy: "network-only",
    }
  );

  const handleToggle = async (id: string, checked: boolean) => {
    setLoading(true);

    const idsToBeUpdated = new Set<string>();
    const nextMap = isActiveMap;

    nextMap.forEach((v, k) => {
      if (k === id) {
        idsToBeUpdated.add(k);
        return nextMap.set(k, checked);
      }

      if (isActiveMap.get(k) === true) {
        idsToBeUpdated.add(k);
        nextMap.set(k, false);
      } else {
        nextMap.set(k, false);
      }
    });

    const toUpdateItems = data.filter((it) => idsToBeUpdated.has(it.id));

    try {
      await Promise.all(
        toUpdateItems.map(async (it) => {
          await updateMaintenanceBannerData({
            variables: {
              ...it,
              manualIsActive: !!nextMap.get(it.id),
              setOnImmediately: null,
            },
          });
        })
      );
      updateStatus(true);
      setIsActiveMap(new Map(nextMap));
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <div className={`${!isMobile && "border-top px-3"} pt-3 pb-2`}>
      {!isMobile && (
        <div className="pt-2">
          <p className="font-weight-bold font-heavy mb-1">
            {t("maintenance.dashboard.setOutages")}
          </p>
        </div>
      )}
      <div className="px-md-3 pr-xl-5 w-100">
        {data.map((it, i) => (
          <MaintenanceNoticeListItem
            data={it}
            index={i}
            key={i}
            openEditForm={openEditForm}
            deleteMaintenance={deleteMaintenance}
            handleToggle={(val) => handleToggle(it.id, val)}
            isActive={!!isActiveMap.get(it.id)}
            loading={loading}
            handlePopoverButtonClick={handlePopoverButtonClick}
            showPopover={showPopover(it.id)}
          />
        ))}
      </div>
    </div>
  );
};
