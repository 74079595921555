import React from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { breakpointPixels } from "../../css/breakpoints";
import AccordionContent from "../accordion/AccordionContent";
import AccordionIcon from "../accordion/AccordionIcon";
import AccordionToggle from "../accordion/AccordionToggle";
import MyProductsMenuLink from "./MyProductsMenuLink";
import NavigationMenuLink, { NavigationMenuVariant } from "./NavigationMenuLink";

type ProductNavLinkProps = {
  hasViewOpenProducts: boolean;
  hasManageProducts: boolean;
  isCurator: boolean;
  companyId: string | null;
  filterComponent?: React.ReactNode;
};

const SubMenuContainer = styled.div`
  @media (max-width: ${breakpointPixels.lg}) {
    border-top: 6px solid var(--color-white);
  }
`;

const ProductNavLinks = ({
  hasViewOpenProducts,
  hasManageProducts,
  isCurator,
  companyId,
  filterComponent,
}: ProductNavLinkProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const getInitialLinkTo = () => {
    if (isCurator) {
      return companyId ? `/company/${companyId}/curator-workspace` : "/curator-workspace";
    } else if (hasManageProducts) {
      return companyId ? `/company/${companyId}/products` : "/products";
    } else if (hasViewOpenProducts) {
      return companyId ? `/company/${companyId}/published-products` : "/published-products";
    } else {
      return "/";
    }
  };
  const linkTo = getInitialLinkTo();
  const renderAccordionIcon = (open: boolean) => (
    <AccordionIcon open={open} style={{ marginRight: "23px" }} />
  );

  const location = useLocation();

  const isProductPage =
    location.pathname.includes("products") || location.pathname.includes("curator-workspace");

  return (
    <Accordion defaultActiveKey={isProductPage ? "0" : ""} data-cy="product-dropdown">
      <AccordionToggle
        className="font-light"
        eventKey="0"
        style={{
          backgroundColor: isProductPage ? "var(--color-gray-200)" : "var(--color-white)",
          borderLeft: `4px solid var(${isProductPage ? "--color-gray-600" : "--color-white"})`,
        }}
        renderIcon={renderAccordionIcon}
        customOnclick={() => {
          if (!isProductPage) {
            history.push(linkTo);
          }
        }}
      >
        <AccordionContent customStyle="px-3 py-2 p-0 d-flex">
          {t("navigation.products")}
        </AccordionContent>
      </AccordionToggle>
      <Accordion.Collapse eventKey="0">
        <SubMenuContainer>
          <div data-cy="curator-workspace-nav-link">
            {isCurator && (
              <NavigationMenuLink
                to={companyId ? `/company/${companyId}/curator-workspace` : "/curator-workspace"}
                i18n="navigation.curatorWorkspace"
                variant={NavigationMenuVariant.secondary}
              />
            )}
          </div>
          <div data-cy="own-products">
            {hasManageProducts && (
              <MyProductsMenuLink
                to={companyId ? `/company/${companyId}/products` : "/products"}
                i18n="navigation.myProducts"
                exact
                variant={NavigationMenuVariant.secondary}
              />
            )}
          </div>
          {(isCurator || hasManageProducts) && (
            <NavigationMenuLink
              to={companyId ? `/company/${companyId}/published-products` : "/published-products"}
              i18n="navigation.publishedProducts"
              variant={NavigationMenuVariant.secondary}
            />
          )}
          {filterComponent}
        </SubMenuContainer>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default ProductNavLinks;
