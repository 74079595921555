import { userInfoVar } from "../utils/ApolloCache";
import { isRequestedRoleAPIUser } from "../utils/functions";
import Header from "./Header";
import CompanyDropdown from "./navigation/CompanyDropdown";
import AddCompanyButton from "./navigation/AddCompanyButton";
import UserDropdown from "./navigation/UserDropdown";
import React from "react";

type DefaultHeaderProps = {
  setShowMobileNav: (show: boolean) => void;
  showMobileNav: boolean;
};

export const DefaultHeader = ({ setShowMobileNav, showMobileNav }: DefaultHeaderProps) => {
  const userInfo = userInfoVar();
  const usersCompanyIds = userInfoVar()?.companyIds;
  const showCompanyDropdown = !!(!isRequestedRoleAPIUser(userInfo) && usersCompanyIds);
  const showAddCompanyButton = !isRequestedRoleAPIUser(userInfo) && !usersCompanyIds;

  return (
    <Header
      onMobileNavigationClick={() => {
        setShowMobileNav(!showMobileNav);
      }}
      render={() => (
        <>
          {showCompanyDropdown && usersCompanyIds && (
            <CompanyDropdown usersCompanyIds={usersCompanyIds} />
          )}
          {showAddCompanyButton && <AddCompanyButton />}
          <UserDropdown />
        </>
      )}
    />
  );
};
