import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DataHubImage, Language, ProductType } from "../../types";
import { getProductTypeHeaderKey } from "../../utils/localizationUtils";
import ListItemContainer from "../ListItemContainer";
import { useIsSmallScreen } from "../MediaQuery";
import ProductListItemHeader from "../ProductListItemHeader";
import ProductListItemLanguages from "../ProductListItemLanguages";
import Thumbnail from "../Thumbnail";
import ProductListItemIconContainer from "./ProductListItemIconContainer";

export type PublishedProductListItemType = {
  images: DataHubImage[];
  productName: string;
  companyName: string;
  city: string;
  id: string;
  productType: ProductType;
  updated: string;
  hasBrokenLink?: boolean;
  languages: Language[];
};

type PublishedProductListItemProps = {
  item: PublishedProductListItemType;
  selected: boolean;
  onClick: (id: string) => void;
};

const PublishedProductListItem = ({ item, selected, onClick }: PublishedProductListItemProps) => {
  const { t } = useTranslation();
  const isSmallScreen = useIsSmallScreen();
  const coverImage = item.images.find((image) => image.coverPhoto) ?? item.images[0];

  return (
    <ListItemContainer selected={selected} onClick={() => onClick(item.id)}>
      <Row>
        <Col xs="3" md="4" lg="3">
          <Row className="justify-content-center my-1">
            <div className="color-gray-600">{moment(item.updated).format("D.M.YYYY")}</div>
          </Row>
          <Row className="justify-content-center">
            <Thumbnail
              image={coverImage}
              showDelete={false}
              size="small"
              altText={coverImage.altText ?? ""}
              listItemStyle
            />
          </Row>
        </Col>

        <Col className="ml-2">
          <Row className="my-1 mr-1">
            <ProductListItemHeader>{item.productName}</ProductListItemHeader>
          </Row>
          <Row className="my-1">
            <div className="text-small">{item.companyName}</div>
          </Row>
          <Row className="my-1">
            <ProductListItemIconContainer iconName="map-marker-alt-solid">
              {item.city}
            </ProductListItemIconContainer>
          </Row>
          {isSmallScreen && <Row>{t(getProductTypeHeaderKey(item.productType))}</Row>}
          <Row className="justify-content-between">
            <Col className="px-0" xs="4">
              <ProductListItemLanguages languages={item.languages} />
            </Col>
            {!isSmallScreen && (
              <Col className="text-right mr-1"> {t(getProductTypeHeaderKey(item.productType))}</Col>
            )}
          </Row>
        </Col>
      </Row>
    </ListItemContainer>
  );
};

export default PublishedProductListItem;
