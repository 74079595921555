import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import DummyLink from "../DummyLink";
import { useHistory } from "react-router-dom";
import Icon from "../Icon";
import { BaseData } from "../../types/instructionsAndFaqTypes";
import { useIsSmallScreen } from "../MediaQuery";
import { useTranslation } from "react-i18next";
import { FAQ_PATH, INSTRUCTIONS_PATH } from "../../utils/routeConstants";

type CardProps = {
  role: string;
  data: BaseData[];
  imageUrl: string;
  slug: string;
};

const CARD_INSTRUCTIONS_LIMIT = 5;

const RoleCard = ({ role, data, imageUrl, slug }: CardProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsSmallScreen();
  const handleClick = (slug = "", id = "") =>
    history.push(`${FAQ_PATH}${INSTRUCTIONS_PATH}/${slug}/${id}`);

  return (
    <div className={`mb-4 ${isMobile && "ml-4"}`}>
      <Row className={isMobile ? "flex-column-reverse" : ""}>
        {!isMobile && (
          <Col xs={12} lg={4} md={5}>
            <img className="rounded" src={imageUrl} alt="" width={isMobile ? "175" : "300"} />
          </Col>
        )}
        <Col xs={12} lg={8} md={7}>
          <h5 className="mb-3" style={{ cursor: "pointer" }} onClick={() => handleClick(slug)}>
            {role}
            <Icon className="mb-1 text-muted" name="chevron-right" />
          </h5>
          {data.length > CARD_INSTRUCTIONS_LIMIT ? (
            <>
              {data.slice(0, CARD_INSTRUCTIONS_LIMIT).map((it, i) => (
                <div className="mb-2" key={i}>
                  <DummyLink onClick={() => handleClick(slug, it.id)}>{it.title}</DummyLink>
                </div>
              ))}
              <div>
                <Button variant="ghost" onClick={() => handleClick(slug)}>
                  {t("ukk.showAll", {
                    amount: data.length,
                  })}
                </Button>
              </div>
            </>
          ) : (
            <>
              {data.map((it, i) => (
                <div className="mb-2" key={i}>
                  <DummyLink onClick={() => handleClick(slug, it.id)}>{it.title}</DummyLink>
                </div>
              ))}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RoleCard;
