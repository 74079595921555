import { JoinRequestStatus } from "../types";
import { gql } from "@apollo/client";

const processJoinRequest = gql`
  mutation ProcessJoinRequest($id: uuid!, $approveRequest: Boolean!) {
    ProcessJoinRequest(joinRequestId: $id, approveRequest: $approveRequest) {
      joinRequest {
        id
        status
        updatedAt
      }
    }
  }
`;

export type ProcessJoinRequestVariables = {
  id: string;
  approveRequest: boolean;
};

export type ProcessJoinRequestResult = {
  ProcessJoinRequest: {
    joinRequest: {
      id: string;
      status: JoinRequestStatus;
      updatedAt: string;
    };
  };
};

export default processJoinRequest;
