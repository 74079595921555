import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import PageHeader from "../PageHeader";
import DashboardLayout from "./DashboardLayout";

const Paragraph = styled.p`
  color: var(--color-gray-800);
`;

const B2BDashboard = () => {
  const { t } = useTranslation();

  return (
    <DashboardLayout
      header={<PageHeader>{t("dashboard.header")}</PageHeader>}
      content={
        <div className="col-10 ml-1 py-3" style={{ height: "50vh" }}>
          <Paragraph>{t("dashboard.b2bIntro1")}</Paragraph>
          <br />
          <Paragraph>{t("dashboard.b2bIntro2")}</Paragraph>
        </div>
      }
      withWrapper
    />
  );
};

export default B2BDashboard;
