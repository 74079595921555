import { gql } from "@apollo/client";

export type MarkDeleteMaintenanceVars = {
  id: string;
  manualToggleId: string;
  deletedAt: string;
};

export const markDeleteMaintenance = gql`
  mutation UpdateMaintenance($id: uuid!, $deletedAt: timestamptz!) {
    updateMaintenanceBannerByPk(pk_columns: { id: $id }, _set: { deletedAt: $deletedAt }) {
      id
    }
    updateMaintenanceManualToggle(
      where: { maintenanceBanner: { id: { _eq: $id } } }
      _set: { deletedAt: $deletedAt }
    ) {
      affected_rows
    }
  }
`;
