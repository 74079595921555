import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import Icon, { IconName } from "./../Icon";

export enum NavigationMenuVariant {
  primary = "primary",
  secondary = "secondary",
}

type ToPropWithStateType = { pathname: string; state: Record<string, string> };

type NavigationMenuLinkProps = {
  to: ToPropWithStateType | string;
  i18n: string;
  exact?: boolean;
  icon?: IconName;
  showNotificationIcon?: boolean;
  variant?: NavigationMenuVariant;
};

const NavigationMenuLink = ({
  to,
  i18n,
  icon,
  showNotificationIcon,
  variant = NavigationMenuVariant.primary,
}: NavigationMenuLinkProps) => {
  const { t } = useTranslation();
  return (
    <NavLink
      to={to}
      activeClassName={`${variant}-nav-link-active`}
      className={`${variant}-nav-link nav-link`}
    >
      <div className="d-flex" data-cy="navigation-menu-link">
        {icon && <Icon name={icon} size="medium" className="mr-2" />}
        {t(i18n)}
        {showNotificationIcon && (
          <div className="d-flex align-items-center ml-2">
            <Icon name="circle-filled" color="primary" size="x-small" />
          </div>
        )}
      </div>
    </NavLink>
  );
};

export default NavigationMenuLink;
