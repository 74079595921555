import React, { useContext, useEffect, useState } from "react";

import Navigation from "../components/navigation/Navigation";
import PageLayout from "./PageLayout";
import { MaintenanceFormDataResponse } from "../types/bannerTypes";
import { MaintenanceBanner } from "../components/maintenance/MaintenanceBanner";
import { useQuery } from "@apollo/client";
import { getMaintenanceQuery } from "../graphqlQueries/maintenanceBanner/getMaintenanceQuery";
import { DefaultHeader } from "../components/DefaultHeader";
import { getHasuraRoleContext } from "../utils/functions";
import { UserRole } from "../types";
import { getActiveMaintenance } from "../utils/maintenanceBannerUtils";
import { MaintenanceCtx } from "../context/MaintenanceContext";

type PageProps = {
  children: React.ReactNode;
  sideBar?: React.ReactNode;
  header?: React.ReactNode;
};

const Page = ({ children, header, sideBar }: PageProps) => {
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const { shouldReEvaluate, updateStatus } = useContext(MaintenanceCtx);

  const { data, refetch } = useQuery<MaintenanceFormDataResponse>(getMaintenanceQuery, {
    context: getHasuraRoleContext(UserRole.ViewOpenProducts),
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const doRefetch = async () => {
      await refetch();
      updateStatus(false);
    };
    if (shouldReEvaluate) {
      void doRefetch();
    }
  }, [refetch, shouldReEvaluate, updateStatus]);

  const getBanner = () => {
    if (data && data.maintenanceBanner.length > 0) {
      const composed = getActiveMaintenance(data.maintenanceBanner);
      // list of banners comes in a sorted array. Where the active (if there is one) one should be the first one.
      if (composed.length > 0 && composed[0].active) {
        return <MaintenanceBanner data={composed[0]} />;
      }
      return;
    }

    return;
  };

  const topBar = header || (
    <DefaultHeader showMobileNav={false} setShowMobileNav={setShowMobileNav} />
  );

  return (
    <>
      <PageLayout
        topBar={topBar}
        sideBar={sideBar || <Navigation />}
        content={children}
        banner={getBanner()}
      />
      {showMobileNav && (
        <Navigation showOnMobile={true} onNavClick={() => setShowMobileNav(false)} />
      )}
    </>
  );
};

export default Page;
