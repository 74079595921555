import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type MobileFiltersModalProps = {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
  resultCount: number;
};

const ModalContainer = styled.div`
  height: 100vh;
  position: relative;
`;

const FilterContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  padding-bottom: 100px;
  overflow: scroll;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  background: var(--color-white);
  height: 60px;
  padding: 10px;
  width: 100vw;
  display: flex;
  justify-content: center;
`;

const MobileFiltersModal = ({ children, show, onClose, resultCount }: MobileFiltersModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} className="modal-fullscreen-xl">
      <ModalContainer>
        <FilterContainer>{children}</FilterContainer>
        <ButtonContainer>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            {t("curatorWorkspace.filterModalClose", { count: resultCount })}
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

export default MobileFiltersModal;
