import {
  GetCommentsCountResult,
  GetCommentsCountVariables,
  getCommentsCountDmo,
  getCommentsCountTc,
} from "../../graphqlQueries/getProductCommentsCount";

import { UserRole } from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";
import { useQuery } from "@apollo/client";

export type UseCommentsCountArgs = {
  productId: string;
  userRole: UserRole.ManageProducts | UserRole.ManageCuration;
};

export const useCommentsCount = ({ productId, userRole }: UseCommentsCountArgs) => {
  const commentsCountQuery =
    userRole === UserRole.ManageCuration ? getCommentsCountDmo : getCommentsCountTc;

  const { data: commentsCountData, loading, error } = useQuery<
    GetCommentsCountResult,
    GetCommentsCountVariables
  >(commentsCountQuery, {
    variables: {
      productId: productId,
    },
    context: getHasuraRoleContext(userRole),
  });

  const commentsCount = commentsCountData?.product?.productCommentsAggregate.aggregate.count;
  const newCommentsCount = commentsCountData?.product?.productNewCommentsAggregate.aggregate.count;
  const data = { commentsCount, newCommentsCount };

  return { data, loading, error };
};
