import Icon from "../Icon";
import { Col, Row } from "react-bootstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MaintenanceFormSection } from "../maintenance/MaintenanceFormSection";
import { MaintenanceNoticeList } from "../maintenance/MaintenanceNoticeList";
import { useIsSmallScreen } from "../MediaQuery";
import { DataHubButton } from "../DataHubButton";
import { useMutation, useQuery } from "@apollo/client";
import { MaintenanceFormDataResponse, MaintenanceDataOut } from "../../types/bannerTypes";
import { getMaintenanceQuery } from "../../graphqlQueries/maintenanceBanner/getMaintenanceQuery";
import { getHasuraRoleContext } from "../../utils/functions";
import { UserRole } from "../../types";
import Loading from "../Loading";
import moment from "moment";
import {
  markDeleteMaintenance,
  MarkDeleteMaintenanceVars,
} from "../../graphqlQueries/maintenanceBanner/markDeleteMaintenance";
import { sortMaintenancesByBreakTime } from "../../utils/maintenanceBannerUtils";

const MAX_MAINTENANCE_AMOUNT = 3;

export const MaintenanceBannerSection = () => {
  const { t } = useTranslation();
  const isMobile = useIsSmallScreen();
  const [openModal, setOpenModal] = useState(false);
  const [openEditForm, setOpenEditForm] = useState<MaintenanceDataOut | null>(null);

  const formatData = (data: MaintenanceDataOut[]) => {
    const format = data.map((it) => ({
      ...it,
      bannerStartTime: moment(it.bannerStartTime, "HH:mm").format("HH:mm"),
      breakStartTime: moment(it.breakStartTime, "HH:mm").format("HH:mm"),
      bannerEndTime: moment(it.bannerEndTime, "HH:mm").format("HH:mm"),
      breakEndTime: moment(it.breakEndTime, "HH:mm").format("HH:mm"),
    }));

    return sortMaintenancesByBreakTime(format);
  };

  const { data, loading, refetch } = useQuery<MaintenanceFormDataResponse>(getMaintenanceQuery, {
    context: getHasuraRoleContext(UserRole.ManageDatahub),
    fetchPolicy: "network-only",
  });

  const [deleteMaintenance] = useMutation<MarkDeleteMaintenanceVars>(markDeleteMaintenance, {
    context: getHasuraRoleContext(UserRole.ManageDatahub),
    fetchPolicy: "network-only",
  });

  const handleDelete = async (id: string) => {
    try {
      await deleteMaintenance({
        variables: {
          id,
          deletedAt: moment().toISOString(),
        },
      });

      refetch();
    } catch (e) {
      console.error(e);
    }
  };

  const DesktopSection = () => {
    return (
      <div className="dashboard-card">
        <Row className="justify-content-between px-3 pt-3">
          <Col>
            <div className="d-flex flex-row align-items-center mb-1">
              <Icon name="toolSolid" size="large" />
              <h2 className="text-landing-h4 ml-3 mb-0">{t("maintenance.dashboard.outages")}</h2>
            </div>
            <p className="text-muted">{t("maintenance.dashboard.outageInformation")}</p>
          </Col>
          <Col className="text-right">
            <DataHubButton
              className="ml-auto"
              variant="primary"
              disabled={
                data?.maintenanceBanner && data.maintenanceBanner.length >= MAX_MAINTENANCE_AMOUNT
              }
              onClick={() => setOpenModal(!openModal)}
            >
              {t("maintenance.dashboard.setOutage")}
            </DataHubButton>
          </Col>
        </Row>
        {data?.maintenanceBanner && data?.maintenanceBanner.length > 0 && (
          <MaintenanceNoticeList
            data={formatData(data.maintenanceBanner)}
            openEditForm={setOpenEditForm}
            deleteMaintenance={handleDelete}
          />
        )}
      </div>
    );
  };

  const MobileSection = () => {
    return (
      <div className="dashboard-card pb-3">
        <Row className="px-4 pt-3 align-items-center">
          <Icon name="toolSolid" size="large" />
          <h2 className="text-landing-h4 ml-3 mb-0">{t("maintenance.dashboard.outages")}</h2>
        </Row>
        <Row className="px-4 pt-2">
          <p className="text-muted">{t("maintenance.dashboard.outageInformation")}</p>
        </Row>
        <Row className="justify-content-center pt-3 pb-1">
          <DataHubButton variant="primary" onClick={() => setOpenModal(!openModal)}>
            {t("maintenance.dashboard.setOutage")}
          </DataHubButton>
        </Row>
        {data?.maintenanceBanner && data?.maintenanceBanner.length > 0 && (
          <MaintenanceNoticeList
            data={formatData(data.maintenanceBanner)}
            openEditForm={setOpenEditForm}
            deleteMaintenance={handleDelete}
          />
        )}
      </div>
    );
  };

  return (
    <div className="mb-5">
      <>
        {loading ? (
          <Loading />
        ) : (
          <>
            {isMobile ? (
              <MobileSection />
            ) : (
              <>
                <DesktopSection />
              </>
            )}
          </>
        )}
      </>

      {openModal && (
        <MaintenanceFormSection
          closeModal={(isOnSubmit) => {
            if (isOnSubmit) {
              refetch();
            }
            setOpenModal(!openModal);
          }}
        />
      )}
      {openEditForm && (
        <MaintenanceFormSection
          closeModal={(isOnSubmit) => {
            if (isOnSubmit) {
              refetch();
            }
            setOpenEditForm(null);
          }}
          editModeData={openEditForm}
        />
      )}
    </div>
  );
};
