import { BulletinTargetGroup, UserRole } from "../../types";
import getCompanyNamesQuery, {
  CompaniesResult,
  CompanyNamesQueryVars,
} from "../../graphqlQueries/getCompanyNames";
import getPublishedProductsForDMO, {
  BaseCompanyDataWithProducts,
  BaseCompanyDataWithProductsVars,
} from "../../graphqlQueries/getPublishedProductsForDMO";

import Bulletins from "../bulletin/Bulletins";
import { DMOSummaryHeader } from "./DMOSummaryHeader";
import DashboardLayout from "./DashboardLayout";
import { DmoNotifications } from "./DmoNotifications";
import { HasuradbDmoArea } from "../../graphqlQueries/getCuratorAreas";
import React from "react";
import { getHasuraRoleContext } from "../../utils/functions";
import { useQuery } from "@apollo/client";

type DMODashboardProps = {
  companyId: string;
  currentRole: UserRole;
  curatorAreas: HasuradbDmoArea[];
};

export const DMODashboard: React.FunctionComponent<DMODashboardProps> = ({
  companyId,
  currentRole,
  curatorAreas,
}) => {
  const { data: userCompany } = useQuery<CompaniesResult, CompanyNamesQueryVars>(
    getCompanyNamesQuery,
    {
      variables: {
        companyIds: [companyId],
      },
      context: getHasuraRoleContext(currentRole),
    }
  );

  const { data: companiesAndProducts } = useQuery<
    BaseCompanyDataWithProducts,
    BaseCompanyDataWithProductsVars
  >(getPublishedProductsForDMO, {
    variables: {
      companyId: companyId,
      cities: curatorAreas.map((area) => area.curationArea.id),
    },
    context: getHasuraRoleContext(currentRole),
  });

  const currentCompany = userCompany?.company[0];

  return (
    <DashboardLayout
      header={
        <div className="w-100">
          <DMOSummaryHeader
            currentCompanyId={companyId}
            products={(companiesAndProducts && companiesAndProducts?.product) || []}
            businessName={currentCompany?.businessName || ""}
            amountOfCompanies={companiesAndProducts?.company?.length || 0}
            amountOfProducts={companiesAndProducts?.product.length || 0}
          />
        </div>
      }
      content={
        <>
          {currentCompany && (
            <DmoNotifications company={currentCompany} curatorAreas={curatorAreas} />
          )}
          <Bulletins targetGroup={BulletinTargetGroup.DMO} />
        </>
      }
    />
  );
};
