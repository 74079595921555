import React, { ReactNode } from "react";

import { Form } from "react-bootstrap";
import styled from "styled-components";

type ContainerProps = {
  checked?: boolean;
  disabled?: boolean;
};

const AlternativeRadioButtonContainer = styled.label<ContainerProps>`
  padding: 8px 8px 0px 8px;
  border: ${(props) =>
    props.checked ? "2px solid var(--color-black)" : "1px solid var(--color-gray-600)"};
  background-color: var(--color-gray-100);
  display: block;
  margin-bottom: 10px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  ${(props) => props.disabled && "opacity: 0.6;"}
`;

export type RadioButtonProps = {
  id: string;
  value: string;
  name: string;
  label?: ReactNode;
  disabled?: boolean;
  alternative?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ alternative, ...props }, ref) => {
    const labelStyle =
      props.checked !== undefined
        ? { fontFamily: `var(${props.checked ? "--font-heavy" : "--font-light"})` }
        : {};
    return alternative ? (
      <AlternativeRadioButtonContainer checked={props.checked} disabled={props.disabled}>
        <Form.Check custom type="radio" {...props} ref={ref} />
      </AlternativeRadioButtonContainer>
    ) : (
      <Form.Check custom type="radio" {...props} ref={ref}>
        <Form.Check.Input type="radio" {...props} ref={ref} />
        {props.label && <Form.Check.Label style={labelStyle}>{props.label}</Form.Check.Label>}
      </Form.Check>
    );
  }
);

export default RadioButton;
