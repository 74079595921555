import { gql } from "@apollo/client";

import { BulletinTargetGroup } from "../types";

export const updateBulletinMutation = gql`
  mutation updateBulletin(
    $bulletinId: uuid!
    $titleFi: String
    $titleEn: String
    $contentFi: String
    $contentEn: String
    $targetGroups: [hasuradb_bulletin_target_group_insert_input!]!
  ) {
    updateBulletinByPk(
      pk_columns: { id: $bulletinId }
      _set: { titleFi: $titleFi, titleEn: $titleEn, contentFi: $contentFi, contentEn: $contentEn }
    ) {
      id
    }
    deleteBulletinTargetGroup(where: { bulletinId: { _eq: $bulletinId } }) {
      affected_rows
    }
    insertBulletinTargetGroup(objects: $targetGroups) {
      affected_rows
    }
  }
`;

export type UpdateBulletinVariables = {
  bulletinId: string;
  titleFi: string;
  titleEn: string;
  contentFi: string;
  contentEn: string;
  targetGroups: { targetGroup: BulletinTargetGroup; bulletinId: string }[];
};

export type UpdateBulletinResult = {
  updateBulletinByPk: { id: string };
  deleteBulletinTargetGroup: { affected_rows: number };
  insertBulletinTargetGroup: { affected_rows: number };
};
