import { gql } from "@apollo/client";

const unpublishProduct = gql`
  mutation unpublishProduct($id: uuid!, $companyId: uuid!) {
    updateProductByPk(pk_columns: { id: $id }, _set: { status: draft, curationEnabled: false }) {
      id
    }
    updateProductCuration(
      where: { productId: { _eq: $id } }
      _set: { productCurationStatus: null, curationStatus: null, updatedByCompany: $companyId }
    ) {
      affected_rows
    }
  }
`;

export default unpublishProduct;

export interface UpdateHasuradbProductByPk {
  id: string;
}

export interface UpdateHasuradbProductCuration {
  affected_rows: number;
}

export interface UnpublishProductResult {
  updateProductByPk: UpdateHasuradbProductByPk;
  updateProductCuration: UpdateHasuradbProductCuration;
}

export type UnpublishProductVariables = {
  id: string;
  companyId: string;
};
