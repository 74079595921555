import { Trans, useTranslation } from "react-i18next";

import ErrorContainer from "./ErrorContainer";
import Icon from "../../components/Icon";
import React from "react";

type CompanyNotFoundProps = {
  businessId?: string;
};

const CompanyNotFound: React.FunctionComponent<CompanyNotFoundProps> = ({ businessId }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex w-100 justify-content-center my-2">
        <Icon name="warning-triangle" color="primary-dark" style={{ width: "50px" }} />
      </div>
      <ErrorContainer>
        <h4 className="mb-3">{t("signup.companyNotFoundHeader")}</h4>
        <p className="w-100 text-center font-weight-bold">{businessId || "-"}</p>
        <p>{t("signup.companyNotFoundDesc")}</p>
        <Trans i18nKey="signup.wrongBusinessIdDescription">
          if you have registered i18n placeholder
          <a href="mailto:datahub@visitfinland.com">datahub@visitfinland.com</a>
        </Trans>
      </ErrorContainer>
    </>
  );
};

export default CompanyNotFound;
