import { gql } from "@apollo/client";

const getCitiesInRegions = gql`
  query getCitiesInRegions {
    region {
      region
      cities {
        city
        id
      }
    }
  }
`;

export interface City {
  city: string;
  id: string;
}

export interface HasuradbRegion {
  region: string;
  cities: City[];
}

export interface CitiesQueryResult {
  region: HasuradbRegion[];
}

export default getCitiesInRegions;
