import React, { ReactNode } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

type ContainerProps = {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
};

const RadioBoxContainer = styled.label<ContainerProps>`
  display: flex;
  align-items: center;
  border: 1px solid ${({ error }) => (error ? "var(--color-invalid)" : "var(--color-gray-400)")};
  background-color: var(--color-gray-100);
  margin-bottom: 10px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  padding: ${(props) => (props.checked ? "15px" : "16px")};

  ${(props) => props.checked && "border: 2px solid var(--color-black);"}
  ${(props) => props.disabled && "opacity: 0.6;"}
`;

type RadioBoxProps = {
  id: string;
  value: string;
  name?: string;
  error?: boolean;
  header?: ReactNode;
  description?: ReactNode;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioBox = React.forwardRef<HTMLInputElement, RadioBoxProps>(
  ({ id, header, description, error = false, ...props }, ref) => {
    return (
      <RadioBoxContainer checked={props.checked} disabled={props.disabled} error={error}>
        <Form.Check
          custom
          type="radio"
          id={id}
          {...props}
          label=""
          ref={ref}
          style={{ minHeight: "0" }}
        />
        <div className="m-0 ml-2">
          {header && <div className="font-heavy color-gray-700">{header}</div>}
          {description && <p className="m-0 font-light text-small color-gray-700">{description}</p>}
        </div>
      </RadioBoxContainer>
    );
  }
);

export default RadioBox;
