import { InMemoryCache, ReactiveVar, makeVar } from "@apollo/client";
import { UserInfo } from "../types";
import { offsetLimitPagination } from "@apollo/client/utilities";

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        userCompanyId: {
          read() {
            return userCompanyIdVar();
          },
        },
        userInfo: {
          read() {
            return userInfoVar();
          },
        },
      },
    },
    hasuradb_product: {
      fields: {
        productComments: offsetLimitPagination(),
      },
    },
  },
});

/**
 * Set initial values when we create cache variables.
 */

export const userCompanyIdVar: ReactiveVar<string | null> = makeVar<string | null>(null);

export const userInfoVar: ReactiveVar<UserInfo | null> = makeVar<UserInfo | null>(null);
