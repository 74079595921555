import React from "react";
import ImageUpload from "../imageUpload/ImageUpload";
import { getDatahubApiEndpoint } from "../../utils/functions";
import { ImageDropProps, ImageEndpoint, ImageUploadError } from "../../types";
import { ImageUploadErrorPopover } from "../imageUpload/ImageUploadErrorPopover";

const ImageDrop = ({
  images,
  onChange,
  fileValidator,
  uploadError,
  setUploadError,
  imageRef,
}: ImageDropProps) => {
  return (
    <>
      <ImageUpload
        setInvalidImages={(error) => {
          setUploadError((prev: ImageUploadError[]) => [...prev, ...error]);
        }}
        resetInvalidImages={() => setUploadError([])}
        onChange={onChange}
        images={images}
        single={false}
        imageRef={imageRef}
        name="images"
        validator={fileValidator}
        imageEndpoint={`${getDatahubApiEndpoint()}/${ImageEndpoint.ProductImageEndpoint}`}
      />

      {uploadError.length > 0 && <ImageUploadErrorPopover invalidImages={uploadError} />}
    </>
  );
};

export default ImageDrop;
