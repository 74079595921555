import { Button, Form, Modal } from "react-bootstrap";
import { Language, SelectOption } from "../../types";
import { DatahubSelect } from "../DatahubSelect";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type AddLanguageModalProps = {
  languages: Language[];
  onHide: () => void;
  onAddLanguages: (lang: Language[]) => void;
};

const AddLanguageModal = ({ languages, onHide, onAddLanguages }: AddLanguageModalProps) => {
  const { t } = useTranslation();

  const [selectedLanguages, setSelectedLanguages] = useState<Language[]>([]);

  const options = languages
    .map((lang) => ({
      value: lang,
      label: t(`languages.${lang}`),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Modal show onHide={onHide}>
      <Modal.Header>{t("productInfo.addLanguageHeader")}</Modal.Header>
      <Modal.Body className="px-5 pt-5 pb-3">
        <Form.Group controlId="productType">
          <Form.Label>{t("productInfo.chooseLanguage")}</Form.Label>
          <DatahubSelect
            closeMenuOnSelect={false}
            options={options}
            value={options.filter((l) => selectedLanguages.indexOf(l.value) !== -1)}
            onChange={(options) => {
              const selectOptions = (options as SelectOption[]) || [];
              const values = selectOptions.map((o) => o.value) as Language[];
              const selected = languages.filter((l) => values.indexOf(l) !== -1);
              setSelectedLanguages(selected);
            }}
            isMulti
          />
          <div className="d-flex justify-content-around mt-5 m-3">
            <Button onClick={onHide} variant="secondary">
              {t("common.cancel")}
            </Button>
            <Button
              onClick={() => selectedLanguages && onAddLanguages(selectedLanguages)}
              disabled={selectedLanguages.length === 0}
            >
              {t("productInfo.addLanguageButton")}
            </Button>
          </div>
        </Form.Group>
      </Modal.Body>
    </Modal>
  );
};

export default AddLanguageModal;
