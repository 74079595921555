import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import BusinessHoursSection from "../components/companyForm/BusinessHoursSection";
import CompanyDetailsSection from "../components/companyForm/CompanyDetailsSection";
import CompanyInformationToolbar from "../components/companyForm/CompanyInformationToolbar";
import ContactDetailsSection from "../components/companyForm/ContactDetailsSection";
import DMORegionSection from "../components/companyForm/DMORegionSection";
import ExternalLinksSection from "../components/companyForm/ExternalLinksSection";
import LocationDetailsSection from "../components/companyForm/LocationDetailsSection";
import SaveCompanyModal from "../components/companyForm/SaveCompanyModal";
import { useUpdateSocialMedias } from "../components/hooks/useUpdateSocialMedias";
import Notification from "../components/Notification";
import PageHeader from "../components/PageHeader";
import SocialMediaSection from "../components/productForm/SociaMediaSection";
import deleteBusinessHoursMutation, {
  DeleteBusinessHoursResult,
  DeleteBusinessHoursVariables,
} from "../graphqlQueries/deleteBusinessHours";
import getCuratorAreas, { CuratorAreasResult } from "../graphqlQueries/getCuratorAreas";
import getCompanyData, {
  GetSingleCompanyInfoResult,
  GetSingleCompanyInfoVariables,
} from "../graphqlQueries/getSingleCompanyInfo";
import insertBusinessHoursMutation, {
  InsertBusinessHoursResult,
  InsertBusinessHoursVariables,
} from "../graphqlQueries/insertBusinessHours";
import updateCompanyInfoMutation, {
  UpdateCompanyInfoResult,
  UpdateCompanyInfoVariables,
} from "../graphqlQueries/updateCompanyInfo";
import updateOpeningHoursMutation, {
  UpdateOpeningHoursResult,
  UpdateOpeningHoursVariables,
} from "../graphqlQueries/updateOpeningHours";
import { CompanyInformationValues, DatahubType, ProductSocialMediaLink, UserRole } from "../types";
import { getHasuraRoleContext, isVisitFinlandAdmin } from "../utils/functions";
import {
  mapOpeningHoursExceptionsToFormValues,
  mapOpeningHoursToWeek,
} from "../utils/mapDbProductToFormValues";
import { getUpdateOpeningHoursVariables } from "../utils/productFormUtils/mapProductToHasura";
import Page from "./Page";
import BackNavigationSideBar from "../components/BackNavigationSideBar";

type SaveResult = "noresult" | "ok" | "error";

type CompanyInformationProps = {
  companyId: string;
};

const manageCompanyContext = getHasuraRoleContext(UserRole.ManageCompany);

const CompanyInformation = ({ companyId }: CompanyInformationProps) => {
  const apollo = useApolloClient();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { updateSocialMedia } = useUpdateSocialMedias();
  const { state } = useLocation<{ previousPath: string | undefined }>();

  const canManageCuration = keycloak.hasResourceRole(UserRole.ManageCuration);
  const isVFAdmin = isVisitFinlandAdmin(keycloak);

  const hookFormMethods = useForm<CompanyInformationValues>();
  const { handleSubmit, reset, register } = hookFormMethods;
  const [saveResult, setSaveResult] = useState<SaveResult>("noresult");

  const [companyInfo, setCompanyInfo] = useState<CompanyInformationValues>();

  const { data: companyData, error: companyDataError, refetch: companyDataRefetch } = useQuery<
    GetSingleCompanyInfoResult,
    GetSingleCompanyInfoVariables
  >(getCompanyData, {
    variables: {
      id: companyId,
    },
    context: manageCompanyContext,
    fetchPolicy: "cache-and-network",
  });

  const companyIsDmo = companyData?.companyByPk.datahubType === DatahubType.DMO;
  const [updateCompanyInfo] = useMutation<UpdateCompanyInfoResult, UpdateCompanyInfoVariables>(
    updateCompanyInfoMutation
  );

  useQuery<CuratorAreasResult>(getCuratorAreas, {
    variables: { id: companyId },
    context: manageCompanyContext,
  });

  const onSubmit = async (values: CompanyInformationValues) => {
    const getBusinessHoursId = async () => {
      const { businessHoursEnabled, businessHoursId } = values;
      if (businessHoursEnabled) {
        if (businessHoursId) {
          return businessHoursId;
        }

        const result = await apollo.mutate<InsertBusinessHoursResult, InsertBusinessHoursVariables>(
          {
            mutation: insertBusinessHoursMutation,
            variables: { companyId },
            context: manageCompanyContext,
          }
        );

        return result.data?.insertBusinessHoursOne.id || null;
      }
      return null;
    };

    const updateBusinessHours = async (businessHoursId: string | null) => {
      if (businessHoursId) {
        const updateResult = await apollo.mutate<
          UpdateOpeningHoursResult,
          UpdateOpeningHoursVariables
        >({
          mutation: updateOpeningHoursMutation,
          variables: getUpdateOpeningHoursVariables(values, businessHoursId),
          context: manageCompanyContext,
        });
        return updateResult.data || null;
      } else if (values.businessHoursId) {
        const deleteResult = await apollo.mutate<
          DeleteBusinessHoursResult,
          DeleteBusinessHoursVariables
        >({
          mutation: deleteBusinessHoursMutation,
          variables: { businessHoursId: values.businessHoursId },
          context: manageCompanyContext,
        });
        return deleteResult.data || null;
      }
      return null;
    };

    const getDmoAreas = () => {
      if (!canManageCuration) {
        return [];
      }

      return (
        values.cities?.map((city) => ({
          companyId: values.companyUuid,
          curationAreaId: city.value,
        })) || []
      );
    };

    try {
      const logo = values.images?.[0];
      const socialMediaId = await updateSocialMedia({
        socialMediaId: companyData?.companyByPk?.socialMedia?.id,
        productSocialMediaLinks: values.socialMediaLinks ?? [],
        companyId,
        userRole: UserRole.ManageCompany,
      });

      const businessHoursId = await getBusinessHoursId();

      await updateCompanyInfo({
        variables: {
          id: values.companyUuid,
          businessName: values.businessName,
          description: values.description,
          email: values.email,
          city: values.city,
          streetName: values.streetName,
          postalCode: values.postalCode,
          phone: values.phonenumber,
          websiteUrl: values.website,
          webshopUrl: values.webshop,
          logoUrl: logo?.originalUrl || null,
          logoThumbnailUrl: logo?.thumbnailUrl || null,
          dmoAreas: getDmoAreas(),
          socialMediaId: socialMediaId || null,
          completed: true,
          businessHoursId,
        },
        context: manageCompanyContext,
        refetchQueries: canManageCuration ? [getCuratorAreas] : undefined,
      });

      await updateBusinessHours(businessHoursId);

      await companyDataRefetch();

      setSaveResult("ok");
    } catch (e) {
      setSaveResult("error");
      console.error(e);
    }
  };

  useEffect(() => {
    if (companyDataError) {
      console.error(companyDataError);
    }

    if (companyData) {
      const company = companyData.companyByPk;
      const linkData = company?.socialMedia?.socialMediaLinks || [];

      const socialMediaLinks: ProductSocialMediaLink[] = linkData.map(
        ({ linkType, verifiedLink }) => {
          return {
            linkType,
            verifiedLink,
          };
        }
      );

      const images = company.logoUrl
        ? [
            {
              filename: "",
              originalUrl: company.logoUrl,
              thumbnailUrl: company.logoThumbnailUrl,
              largeUrl: company.logoUrl,
            },
          ]
        : [];

      const cities = company.dmoAreas.map((dmoArea) => ({
        label: dmoArea.curationArea.city,
        value: dmoArea.curationArea.id,
      }));

      const businessHoursId = company.businessHours?.id ?? "";

      const newCompanyInfo: CompanyInformationValues = {
        businessName: company.businessName,
        description: company.description,
        streetName: company.postalAddresses[0]?.streetName ?? "",
        postalCode: company.postalAddresses[0]?.postalCode ?? "",
        city: company.postalAddresses[0]?.city ?? "",
        images: images,
        website: company.websiteUrl,
        webshop: company.webshopUrl,
        businessId: company.businessEntity?.businessId,
        officialName: company.officialName,
        phonenumber: company.contactDetails[0]?.phone ?? "",
        email: company.contactDetails[0]?.email ?? "",
        companyUuid: company.id,
        cities: cities,
        socialMediaLinks,
        companySocialMediaId: company.socialMedia?.id ?? "",
        openingHours: mapOpeningHoursToWeek(company.businessHours?.default),
        openingHoursExceptions: mapOpeningHoursExceptionsToFormValues(
          company.businessHours?.exceptions
        ),
        businessHoursId,
        businessHoursEnabled: !!businessHoursId,
      };
      reset(newCompanyInfo);
      setCompanyInfo(newCompanyInfo);
    }
  }, [companyData, companyDataError, reset]);

  return (
    <Page sideBar={<BackNavigationSideBar backPath={state?.previousPath || "/"} />}>
      <FormProvider {...hookFormMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Control type="hidden" name="companyUuid" ref={register()} />
          <CompanyInformationToolbar
            onReset={() => {
              reset(companyInfo);
            }}
          />
          {companyDataError && <Notification type="danger">{t("common.error")}</Notification>}
          <div className="p-3 p-lg-5 m-md-3 mx-lg-4">
            <PageHeader data-cy="company-information-page-header">
              {canManageCuration ? t("companyInfo.dmoHeader") : t("companyInfo.header")}
            </PageHeader>
            <p>
              {canManageCuration ? t("companyInfo.dmoDescription") : t("companyInfo.description")}
            </p>
            <CompanyDetailsSection isCurator={canManageCuration} />
            {canManageCuration && !isVFAdmin && companyIsDmo && <DMORegionSection />}
            <LocationDetailsSection isCurator={canManageCuration} />
            <BusinessHoursSection />
            <ContactDetailsSection isCurator={canManageCuration} />
            <ExternalLinksSection isCurator={canManageCuration} />
            <SocialMediaSection
              headerKey="companyInfo.socialMediaHeader"
              descriptionKey="companyInfo.socialMediaDescription"
              fieldName="socialMediaLinks"
              isCompanyForm={true}
            />
          </div>
        </Form>
        {saveResult !== "noresult" && (
          <SaveCompanyModal saveResult={saveResult} onHide={() => setSaveResult("noresult")} />
        )}
      </FormProvider>
    </Page>
  );
};

export default CompanyInformation;
