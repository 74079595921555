import { gql } from "@apollo/client";

//TODO: This will return many ID:s when there are more than one company under one businessID.
const getCompanyId = gql`
  query GetCompanyId($businessId: String!) {
    businessEntity(where: { businessId: { _eq: $businessId } }) {
      companies {
        id
      }
    }
  }
`;

export const getCompanyByBusinessId = gql`
  query GetCompanyByBusinessId($businessId: String!) {
    businessEntity(where: { businessId: { _eq: $businessId } }) {
      companies {
        id
        officialName
        businessName
      }
    }
  }
`;

export default getCompanyId;
