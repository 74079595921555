import { StoreObject, useApolloClient, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import deleteProductMutation, {
  DeleteProductMutationResult,
  DeleteProductVariables,
} from "../../graphqlQueries/deleteProduct";
import {
  getProductLinkIdsQuery,
  GetProductLinkIdsResult,
  GetProductLinkIdsVariables,
} from "../../graphqlQueries/getProductLinkIds";
import { PopoverStateType, ProductPublishMode, ProductPublishResult, UserRole } from "../../types";
import { getErrorCount, jumpToError } from "../../utils/formUtils";
import { getHasuraRoleContext } from "../../utils/functions";
import { getLinkIds } from "../../utils/productFormUtils/productUtils";
import ConfirmButton from "../ConfirmButton";
import IconButton from "../IconButton";
import { useIsSmallScreen } from "../MediaQuery";
import SmallMobileMenu from "../SmallMobileMenu";
import SubmitSpinner from "../SubmitSpinner";
import Toolbar from "../Toolbar";
import ErrorNotification from "./ErrorNotification";

type ToolbarProps = {
  productId?: string;
  editMode: boolean;
  onUnpublish: () => void;
  unpublishLoading: boolean;
  setPublishMode: (mode: ProductPublishMode) => void;
  publishMode: ProductPublishMode;
  setPublishResult: (result: ProductPublishResult) => void;
  publishResult: ProductPublishResult;
  onDelete: () => void;
  onDiscardChanges: () => void;
};

const manageProductsContext = getHasuraRoleContext(UserRole.ManageProducts);

const DELETE_ID = "delete-confirm";
const UNPUBLISH_ID = "unpublish-confirm";
const DISCARD_ID = "discard-changes";

const MyProductFormToolbar = ({
  productId,
  editMode,
  onUnpublish,
  unpublishLoading,
  setPublishMode,
  setPublishResult,
  publishMode,
  publishResult,
  onDelete,
  onDiscardChanges,
}: ToolbarProps) => {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();

  const [openPopovers, setOpenPopovers] = useState<PopoverStateType[]>([
    { id: DELETE_ID, isOpen: false },
    { id: UNPUBLISH_ID, isOpen: false },
    { id: DISCARD_ID, isOpen: false },
  ]);
  // Set popover open if id matches, else close
  const changePopOver = (popoverId: string) =>
    setOpenPopovers((prevState) => prevState.map(({ id }) => ({ id, isOpen: id === popoverId })));
  // Close all
  const closePopovers = () =>
    setOpenPopovers((prevState) => prevState.map(({ id }) => ({ id, isOpen: false })));
  // If clicked popover is already open close all, otherwise open it and close others.
  const handlePopoverButtonClick = (popoverId: string) => {
    openPopovers.find(({ id }) => id === popoverId)?.isOpen
      ? closePopovers()
      : changePopOver(popoverId);
  };

  const showPopover = (id: string) => !!openPopovers.find((it) => it.id === id && it.isOpen);

  const [deleteProduct, { loading: deleteLoading }] = useMutation<
    DeleteProductMutationResult,
    DeleteProductVariables
  >(deleteProductMutation, {
    update(cache, { data }) {
      if (data) {
        cache.modify({
          fields: {
            product(existingProducts: StoreObject[] = [], { readField }) {
              const deletedId = data.updateProductByPk.id;
              return existingProducts.filter((product) => readField("id", product) !== deletedId);
            },
          },
        });
      }
    },
    onCompleted: () => {
      onDelete();
    },
    context: manageProductsContext,
  });

  useEffect(() => {
    if (publishResult === "ok" && publishMode !== "publish") {
      setPublishResult("noresult");
    }
  }, [publishResult, setPublishResult, publishMode]);

  const { formState, errors, trigger } = useFormContext();
  const { isSubmitting, submitCount } = formState;

  const isSubmittingPublish = isSubmitting && publishMode === "publish";
  const isSubmittingUpdate = isSubmitting && publishMode === "update";
  const isSubmittingDraft = isSubmitting && publishMode === "draft";

  const errorCount = getErrorCount(errors);

  const anyMutationLoading = unpublishLoading || deleteLoading || isSubmitting;

  const DeleteButton = (
    <ConfirmButton
      id={DELETE_ID}
      confirmStyle="warning"
      showIcon
      headerContent={t("productInfo.deletePopoverHeader")}
      popOverContent={<p className="pb-3">{t("productInfo.deletePopoverDescription")}</p>}
      confirmButtonContent={t("productInfo.deleteButtonText")}
      disabled={anyMutationLoading}
      onConfirmClick={() => {
        if (!productId) {
          onDelete();
        } else {
          apolloClient
            .query<GetProductLinkIdsResult, GetProductLinkIdsVariables>({
              query: getProductLinkIdsQuery,
              variables: { productId },
              context: manageProductsContext,
              fetchPolicy: "network-only",
            })
            .then((result) => {
              const linkIds = result.data;
              return linkIds
                ? getLinkIds(
                    linkIds.productByPk.productInformations,
                    linkIds.productByPk.productVideos
                  )
                : [];
            })
            .then((allLinks) => {
              return deleteProduct({
                variables: {
                  id: productId,
                  linkIds: allLinks,
                },
              });
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }}
      renderTriggerButton={
        <IconButton
          variant="secondary"
          className="ml-3"
          disabled={anyMutationLoading}
          hideIcon={!deleteLoading}
          iconElement={<SubmitSpinner />}
          onClick={() => handlePopoverButtonClick(DELETE_ID)}
        >
          {t("productInfo.deleteButtonText")}
        </IconButton>
      }
      closePopover={handlePopoverButtonClick}
      show={showPopover(DELETE_ID)}
    />
  );

  const DiscardChanges = (
    <ConfirmButton
      id={DISCARD_ID}
      confirmStyle="info"
      showIcon
      headerContent={t("productInfo.discardPopoverHeader")}
      popOverContent={<p className="pb-3">{t("productInfo.discardPopoverDescription")}</p>}
      confirmButtonContent={t("common.discardChanges")}
      triggerButtonContent={t("common.discardChanges")}
      disabled={anyMutationLoading}
      onConfirmClick={() => {
        onDiscardChanges();
      }}
      closePopover={handlePopoverButtonClick}
      show={showPopover(DISCARD_ID)}
    />
  );

  const EditModeButtons = (
    <>
      <ConfirmButton
        id={UNPUBLISH_ID}
        confirmStyle="warning"
        showIcon
        headerContent={t("productInfo.unpublishProductPopoverHeader")}
        popOverContent={
          <p className="pb-3">{t("productInfo.unpublishProductPopoverDescription")}</p>
        }
        confirmButtonContent={t("productInfo.unpublish")}
        onConfirmClick={onUnpublish}
        renderTriggerButton={
          <IconButton
            variant="secondary"
            className="ml-3"
            disabled={anyMutationLoading}
            hideIcon={!unpublishLoading}
            iconElement={<SubmitSpinner />}
            onClick={() => handlePopoverButtonClick(UNPUBLISH_ID)}
          >
            {t("productInfo.unpublish")}
          </IconButton>
        }
        closePopover={handlePopoverButtonClick}
        show={showPopover(UNPUBLISH_ID)}
      />
      <IconButton
        variant="primary"
        type="submit"
        className="ml-3"
        onClick={() => {
          setPublishMode("update");
        }}
        hideIcon={!isSubmittingUpdate}
        iconElement={<SubmitSpinner />}
        disabled={anyMutationLoading}
      >
        {t("productInfo.update")}
      </IconButton>
    </>
  );

  const NewProductButtons = (
    <>
      <IconButton
        variant="light"
        className="ml-3"
        type="submit"
        onClick={() => {
          setPublishMode("draft");
        }}
        hideIcon={!isSubmittingDraft}
        iconElement={<SubmitSpinner />}
        disabled={anyMutationLoading}
      >
        {t("productInfo.saveAsDraft")}
      </IconButton>
      <IconButton
        variant="primary"
        type="submit"
        className="ml-3"
        onClick={() => {
          closePopovers();
          setPublishMode("publish");
        }}
        hideIcon={!isSubmittingPublish}
        iconElement={<SubmitSpinner />}
        disabled={anyMutationLoading}
      >
        {t("productInfo.publish")}
      </IconButton>
    </>
  );

  const showMobileMenuButton = useIsSmallScreen();

  return (
    <Toolbar
      notification={
        <ErrorNotification
          errorCount={errorCount}
          submitCount={submitCount}
          jumpToError={() => jumpToError({ trigger })}
        />
      }
    >
      {!showMobileMenuButton && DeleteButton}
      {!showMobileMenuButton && DiscardChanges}
      {editMode && EditModeButtons}
      {!editMode && NewProductButtons}
      {showMobileMenuButton && (
        <SmallMobileMenu className="p-2 ml-2">
          {DeleteButton}
          {DiscardChanges}
        </SmallMobileMenu>
      )}
    </Toolbar>
  );
};

export default MyProductFormToolbar;
