import { Link, useHistory, useRouteMatch } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getDefaultProductName, hasBrokenLink } from "../../utils/productFormUtils/productUtils";

import { CuratorProduct } from "../../types/curatorProductTypes";
import CuratorProductListItem from "./CuratorProductListItem";
import LoadingResults from "./LoadingResults";
import NoResults from "./NoResults";
import ProductListContainer from "../ProductListContainer";
import { useTranslation } from "react-i18next";

type CuratorProductListProps = {
  selectedProductId?: string;
  products?: CuratorProduct[];
  loading: boolean;
  paging?: React.ReactNode;
  header: React.ReactNode;
  hasNoDMOAreas?: boolean;
  companyId: string;
};

let timer: NodeJS.Timeout;
const TIMEOUT_DELAY = 200;

const CuratorProductList = React.forwardRef<HTMLDivElement, CuratorProductListProps>(
  ({ selectedProductId, products, loading, paging, header, hasNoDMOAreas, companyId }, ref) => {
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory();
    const { path } = useRouteMatch();
    const { t } = useTranslation();

    // show loading indicator only after a moment so
    // fast connections don't see flickering UI
    useEffect(() => {
      if (timer) {
        clearTimeout(timer);
      }
      if (loading) {
        timer = setTimeout(() => {
          setShowLoading(true);
        }, TIMEOUT_DELAY);
      } else {
        setShowLoading(false);
      }
    }, [loading]);

    return (
      <ProductListContainer ref={ref} pagination={!!paging}>
        {header}
        {hasNoDMOAreas && (
          <div className="bg-white px-4 py-4">
            <div className="bold-text mb-2">{t("dmoHeader.checkAreasHeader")}</div>
            <div>
              {t("dmoHeader.checkAreas")}{" "}
              <Link to={`/company/${companyId}/contact-information`}>
                {t("dmoHeader.viewOrganizationInformation")}
              </Link>
            </div>
          </div>
        )}
        {showLoading && <LoadingResults />}
        {products?.length === 0 && !loading && <NoResults />}
        {products &&
          products.length > 0 &&
          products.map((hasuraProduct) => (
            <CuratorProductListItem
              key={hasuraProduct.id}
              selected={hasuraProduct.id === selectedProductId}
              item={{
                productName: getDefaultProductName(hasuraProduct),
                companyName: hasuraProduct.company.businessName,
                images: hasuraProduct.productImages,
                id: hasuraProduct.id,
                city: hasuraProduct.postalAddresses[0].city,
                productType: hasuraProduct.type,
                commentsCount: hasuraProduct.productCommentsAggregate.aggregate.count,
                newCommentsCount: hasuraProduct.productNewCommentsAggregate?.aggregate.count,
                curationStatus: hasuraProduct.productCurations[0].curationStatus,
                productCurationStatus: hasuraProduct.productCurations[0].productCurationStatus,
                updated: hasuraProduct.updatedAt,
                languages: hasuraProduct.productInformations.map((info) => info.language),
                hasBrokenLink: hasBrokenLink(hasuraProduct),
                externalProduct: hasuraProduct.externalSource,
              }}
              onClick={(id) => history.push(`${path}/${id}`)}
            />
          ))}
        {paging && paging}
      </ProductListContainer>
    );
  }
);

export default CuratorProductList;
