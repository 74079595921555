import { useState } from "react";
import { v4 as uuid } from "uuid";

export type RequestResultStatus = "ok" | "error";

type RequestNotificationData = {
  resultStatus: RequestResultStatus;
  message: string;
  key: string;
};

const useRequestNotifications = () => {
  const [notificationsMap] = useState<Map<string, RequestNotificationData>>(
    new Map<string, RequestNotificationData>()
  );
  const [notifications, setNotifications] = useState<RequestNotificationData[]>([]);

  const addNotification = (resultStatus: RequestResultStatus, message: string, key?: string) => {
    const mapKey = key || uuid();
    notificationsMap.set(mapKey, { key: mapKey, resultStatus, message });
    setNotifications(Array.from<RequestNotificationData>(notificationsMap.values()));
  };

  const removeNotification = (key: string) => {
    notificationsMap.delete(key);
    setNotifications(Array.from<RequestNotificationData>(notificationsMap.values()));
  };

  return { addNotification, removeNotification, notifications };
};

export default useRequestNotifications;
