import {
  AggregateCount,
  CompanyName,
  DataHubImage,
  OrderBy,
  PostalAddressCity,
  ProductType,
} from "../types";
import { getProductQueryFilters, getQueryName } from "./productFilters";

import { ProductInformation } from "./getProduct";
import { QueryFilterVariables } from "../utils/filterUtils";
import { gql } from "@apollo/client";

const buildGetPublishedProductsQuery = (filters: QueryFilterVariables) => {
  return gql`
    query ${getQueryName("getPublishedProducts", filters)}(
      $orderBy: order_by!
      $offset: Int!
      $limit: Int!
      ${filters.type ? "$type: [hasuradb_product_types_enum!]!" : ""}
      ${filters.tags ? "$tags: [String!]!" : ""}
      ${filters.search ? "$search: String!" : ""}
      ${filters.targetGroups ? "$targetGroups: [hasuradb_target_groups_enum!]!" : ""}
      ${filters.languages ? "$languages: [hasuradb_languages_enum]" : ""}
      ${filters.cities ? "$cities: [uuid!]!" : ""}
      ${filters.accessible ? "$accessible: Boolean!" : ""}
      ${filters.months ? "$months: [hasuradb_months_enum!]!" : ""}
      ${filters.certificates ? "$certificates: [String!]!" : ""}
    ) {
      product(
        where: ${getProductQueryFilters(filters)}
        order_by: { updatedAt: $orderBy }
        limit: $limit
        offset: $offset
      ) {
        id
        accessible
        companyId
        productImages {
          largeUrl
          originalUrl
          thumbnailUrl
          altText
          copyright
          filename
        }
        productInformations {
          language
          name
          description
        }
        postalAddresses {
          city
        }
        company {
          businessName
        }
        updatedAt
        type
      }
      productAggregate(
        where: ${getProductQueryFilters(filters)}
      ) {
        aggregate {
          count
        }
      }
    }
`;
};

export type PublishedProduct = {
  id: string;
  productImages: DataHubImage[];
  type: ProductType;
  productInformations: ProductInformation[];
  company: CompanyName;
  companyId: string;
  postalAddresses: PostalAddressCity[];
  updatedAt: string;
};

export type GetPublishedProductsResult = {
  product: PublishedProduct[];
  productAggregate: AggregateCount;
};

export type GetPublishedProductsVariables = {
  orderBy: OrderBy;
  limit: number;
  offset: number;
  search?: string;
  type?: string;
  tags?: string[];
  months?: string[];
  targetGroups?: string[];
  cities?: string[];
  certificates?: string[];
  accessible?: boolean;
};

export default buildGetPublishedProductsQuery;
