import { gql } from "@apollo/client";

export const getVerifiedLinksIdsQuery = gql`
  query getVerifiedLinksIds($socialMediaId: uuid!) {
    socialMediaLinks(where: { socialMediaId: { _eq: $socialMediaId } }) {
      verifiedLink {
        id
      }
    }
  }
`;

export type GetVerifiedLinksIdsVars = {
  socialMediaId: string;
};

export type GetVerifiedLinksIdsResult = {
  socialMediaLinks: { verifiedLink: { id: string } }[];
};
