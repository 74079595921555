import { TableEllipsis } from "../../types/companyTypes";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
  getCuratorCompaniesInAreas,
  GetCuratorCompaniesInAreasResult,
  GetCuratorCompaniesInAreasVariables,
} from "../../graphqlQueries/getCuratorCompaniesInAreas";
import { getHasuraRoleContext } from "../../utils/functions";
import { UserRole } from "../../types";
import { NotAvailable } from "../products/NotAvailable";
import moment from "moment/moment";
import { Spinner } from "react-bootstrap";
import React from "react";

type AdminDashboardEllipsisBodyProps = {
  ellipsis: TableEllipsis;
};

export const AdminDashboardEllipsisBody = ({ ellipsis }: AdminDashboardEllipsisBodyProps) => {
  const { t } = useTranslation();

  const getUniquePostalCodes = (postalCodes: string[]) => {
    const uniquePostalCodes = new Set(postalCodes);
    return Array.from(uniquePostalCodes);
  };

  const postalCodes = getUniquePostalCodes(ellipsis.productPostalCodes);

  const { data, loading } = useQuery<
    GetCuratorCompaniesInAreasResult,
    GetCuratorCompaniesInAreasVariables
  >(getCuratorCompaniesInAreas, {
    skip: postalCodes.length === 0,
    variables: {
      postalCodes,
    },
    context: getHasuraRoleContext(UserRole.ManageCuration),
    fetchPolicy: "cache-and-network",
  });

  const CompanyCurators = () => {
    const companyNames = data?.company.map(({ officialName }) => officialName);

    if (!companyNames || companyNames.length < 1) {
      return <NotAvailable />;
    }

    return (
      <>
        {companyNames.map((name, i) => (
          <p className="m-0" key={i}>
            {name}
          </p>
        ))}
      </>
    );
  };

  return (
    <div className="bg-gray-100 py-2 px-3">
      <div>
        <h4>{t("companyTable.officialName")}</h4>
        <p>{ellipsis.officialName}</p>
      </div>
      <div>
        <h4>{t("companyTable.joinedHeader")}</h4>
        <p>{moment(ellipsis.createdAt).format("D.M.YYYY")}</p>
      </div>
      <div>
        <h4>{t("companyTable.companyCurator")}</h4>
        {loading ? <Spinner animation="border" /> : <CompanyCurators />}
      </div>
    </div>
  );
};
