import { DEMO_PATH, LOGIN_PATH, LOGIN_REDIRECT_PARAM, ROOT_PATH } from "../utils/routeConstants";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { createUrl, getPublicUrl } from "../utils/functions";

import LandingPage from "../pages/LandingPage";
import React from "react";
import ZoneAtlasDemo from "../pages/ZoneAtlasDemo";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";

const PublicRoutes = () => {
  const { keycloak } = useKeycloak();
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  return (
    <div>
      <Switch>
        <Route
          exact
          path={LOGIN_PATH}
          render={() => {
            const redirectString = searchParams.get(LOGIN_REDIRECT_PARAM);
            const redirectUrl = createUrl(redirectString);
            if (!redirectUrl) {
              console.debug("Redirect URL is invalid", { url: redirectString });
            }
            return keycloak?.login({
              locale: i18n.language,
              redirectUri: redirectUrl?.href || getPublicUrl(),
            });
          }}
        />
        <Route exact path={ROOT_PATH}>
          <LandingPage />
        </Route>
        <Route exact path={DEMO_PATH}>
          <ZoneAtlasDemo />
        </Route>
        <Route path={ROOT_PATH}>
          <Redirect to={ROOT_PATH} />
        </Route>
      </Switch>
    </div>
  );
};

export default PublicRoutes;
