import {
  FormSectionSubHeader,
  FormSubDescription,
  FormSubSection,
  FormSubSectionContent,
} from "../FormSection";

import { CDN_WELCOME_CYCLIST_LOGO } from "../../utils/constants";
import { CertificateLogo } from "../products/CertificateLogo";
import Checkbox from "../Checkbox";
import ExternalLink from "../ExternalLink";
import { Form } from "react-bootstrap";
import InputContainer from "./InputContainer";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useIsMediumOrSmaller } from "../MediaQuery";
import { useTranslation } from "react-i18next";

const BIKE_LAND_LINK_EN = "https://www.bikeland.fi/en/tervetuloapyorailija";
const BIKE_LAND_LINK = "https://www.bikeland.fi/tervetuloapyorailija";

export const WelcomeCyclistSection: React.FunctionComponent<{ disabled: boolean }> = ({
  disabled,
}) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  const { i18n } = useTranslation();
  const isMediumOrSmallerScreen = useIsMediumOrSmaller();

  return (
    <FormSubSection>
      <FormSubDescription isLastRow>
        <FormSectionSubHeader>{t("productInfo.welcomeCyclistSubHeader")}</FormSectionSubHeader>
        {!disabled && (
          <p>
            {t("productInfo.welcomeCyclistDescription1")}
            <ExternalLink
              className="d-block"
              href={i18n.language === "fi" ? BIKE_LAND_LINK : BIKE_LAND_LINK_EN}
            >
              {t("productInfo.welcomeCyclistReadMoreLink")}
            </ExternalLink>
          </p>
        )}
      </FormSubDescription>
      <FormSubSectionContent>
        {!isMediumOrSmallerScreen && (
          <Form.Label>{t("productInfo.welcomeCyclistCertified")}</Form.Label>
        )}
        <CertificateLogo
          url={CDN_WELCOME_CYCLIST_LOGO}
          size="125"
          altText={t("productInfo.welcomeCyclistLogoAltText")}
        />
        <InputContainer>
          <Checkbox
            id="welcomeCyclistCertified"
            value="welcomeCyclistCertified"
            key="welcomeCyclistCertified"
            name="welcomeCyclistCertified"
            ref={register()}
            label={t("productInfo.welcomeCyclistCertifiedLabel")}
            disabled={disabled}
          />
        </InputContainer>
      </FormSubSectionContent>
    </FormSubSection>
  );
};
