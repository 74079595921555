import {
  AccessibilityType,
  AvailabilityLanguage,
  AvailabilitySeason,
  Certificate,
  Month,
  MyProductFormValues,
  OpeningHours,
  OpeningHoursDates,
  OpeningHoursException,
  OpeningHoursExceptionFormValues,
  OpeningHoursWeek,
  PricingType,
  PricingUnit,
  ProductDetails,
} from "../types";
import { HasuradbProduct, VerifiedLink } from "../graphqlQueries/getProduct";
import { formatCurrency, formatTime } from "./format";

import { MONTHS_FULL_YEAR } from "./constants";
import { NestedValue } from "react-hook-form";
import moment from "moment";
import { parseLocation } from "./location";

export const defaultPricingUnit = { value: "select", label: "select.placeholder" };

const getLinkFormData = (link?: VerifiedLink | null) => ({
  url: link?.url ?? "",
  userVerifiedAt: link?.userVerifiedAt ?? "",
  userVerifiedBy: link?.userVerifiedBy ?? "",
});

export const mapOpeningHoursToWeek = (openingHours: OpeningHours[] = []): OpeningHoursWeek => {
  return openingHours.reduce(
    (acc, hour) =>
      Object.assign(acc, {
        [hour.weekday]: {
          closed: !hour.open,
          opens: formatTime(hour.opens),
          closes: formatTime(hour.closes),
          weekday: hour.weekday,
          date: hour.date || undefined,
        },
      }),
    {}
  );
};

export const mapOpeningHoursExceptionsToFormValues = (
  exceptions: OpeningHoursException[] = []
): OpeningHoursExceptionFormValues[] => {
  return exceptions.map((exception) => ({
    period: {
      startDate: exception.start ? moment(exception.start) : null,
      endDate: exception.end ? moment(exception.end) : null,
    } as NestedValue<OpeningHoursDates>,
    openingHours: mapOpeningHoursToWeek(exception.openingHours),
  }));
};

export const mapDbProductToFormValues = (product: HasuradbProduct): MyProductFormValues => {
  const productType = product.type;

  const productCategories = product.productTags
    ? product.productTags.map((tag) => ({
        value: tag.tag,
        label: "",
      }))
    : [];

  const targetGroups = product.productTargetGroups
    ? product.productTargetGroups.map((group) => group.targetGroupId)
    : [];

  const pricing = product.productPricings?.[0];
  const priceFrom = formatCurrency(pricing?.fromPrice, true);
  const priceTo = formatCurrency(pricing?.toPrice, true);

  const capacity = product.productCapacities?.[0];
  const capacityMin = capacity?.min ? capacity.min.toString() : null;
  const capacityMax = capacity?.max ? capacity.max.toString() : null;

  const duration = product.productDuration?.[0];
  const minutes = duration?.minutes ?? 0;
  const hours = duration?.hours ?? 0;
  const days = duration?.days ?? 0;
  const weeks = duration?.weeks ?? 0;

  const availableMonths: Month[] = product.productAvailableMonths
    ? product.productAvailableMonths.map((month) => month.month)
    : [];

  const eventAvailability = product.productAvailabilities?.[0];
  const startDate = eventAvailability?.startDate ? moment(eventAvailability.startDate) : null;
  const endDate = eventAvailability?.endDate ? moment(eventAvailability.endDate) : null;
  const transportationAvailability = eventAvailability?.transportationAvailability;

  let seasonAvailabilityType = AvailabilitySeason.NotSet;
  if (availableMonths.length > 0) {
    seasonAvailabilityType =
      availableMonths.length === MONTHS_FULL_YEAR
        ? AvailabilitySeason.AllYear
        : AvailabilitySeason.SpecificMonths;
  }

  const images = product.productImages || [];
  const videos =
    product.productVideos.length > 0
      ? product.productVideos.map((video) => ({
          videoLink: getLinkFormData(video.verifiedVideoLink),
          title: video.title,
        }))
      : [];

  const productAddress = product.postalAddresses[0];

  const location = productAddress?.location;
  let longitude, latitude;
  if (location) {
    const parsedLocation = parseLocation(location);
    latitude = parsedLocation.latitude;
    longitude = parsedLocation.longitude;
  }

  const details = product.productInformations.map<ProductDetails>((information) => ({
    productName: information.name,
    productDescription: information.description,
    language: information.language,
    productLink: getLinkFormData(information.verifiedProductLink),
    webshopLink: getLinkFormData(information.verifiedWebshopLink),
  }));

  const availabilityLanguages: AvailabilityLanguage[] = product.productAvailabilityLanguages.map(
    ({ language }) => ({ value: language })
  );

  const coverImage = images.find((image) => image.coverPhoto) ?? images[0];

  const stfCertified = !!product.productCertificates.find((c) => {
    return c.certificate === Certificate.STF;
  });
  const welcomeCyclistCertified = !!product.productCertificates.find(
    (c) => c.certificate === Certificate.WelcomeCyclist
  );

  const getAccessibility = (accessible: boolean | null) => {
    if (accessible !== null) {
      return accessible ? AccessibilityType.Accessible : AccessibilityType.NotAccessible;
    } else {
      return AccessibilityType.Undefined;
    }
  };

  const accessible = getAccessibility(product.accessible);

  const socialMediaId = product.socialMedia?.id || "";
  const companySocialMediaId = product.company.socialMediaId ?? "";
  // if we have both data and socialMediaIds match
  const companySocialMediaEnabled =
    !!socialMediaId && !!companySocialMediaId && socialMediaId === companySocialMediaId;
  const businessHoursId = product.businessHours.id;
  const companyBusinessHoursId = product.company.businessHoursId ?? "";
  const companyHoursEnabled =
    !!businessHoursId && !!companyBusinessHoursId && businessHoursId === companyBusinessHoursId;

  const formValues: MyProductFormValues = {
    availabilityLanguages,
    details,
    productType,
    productCategories,
    productSocialMediaLinks:
      product.socialMedia?.socialMediaLinks?.map(({ linkType, verifiedLink }) => ({
        linkType,
        verifiedLink,
      })) || [],
    targetGroups,
    priceFree: pricing?.pricingType === PricingType.Free,
    priceFrom: pricing?.pricingType === PricingType.Chargeable ? priceFrom : "",
    priceTo: pricing?.pricingType === PricingType.Chargeable ? priceTo : "",
    eventDateRange: {
      startDate,
      endDate,
    },
    capacityMax,
    capacityMin,
    minutes: { label: minutes.toString(), value: minutes },
    hours: { label: hours.toString(), value: hours },
    days: { label: days.toString(), value: days },
    weeks: { label: weeks.toString(), value: weeks },
    availabilitySeason: seasonAvailabilityType,
    specificMonths: seasonAvailabilityType === AvailabilitySeason.AllYear ? [] : availableMonths,
    images: images.map((v) => ({
      filename: v.filename,
      largeUrl: v.largeUrl,
      originalUrl: v.originalUrl,
      thumbnailUrl: v.thumbnailUrl,
      altText: v.altText,
      copyright: v.copyright,
      coverPhoto: v === coverImage,
      orientation: v.orientation,
      originalWidth: v.originalWidth,
      originalHeight: v.originalHeight,
    })),
    openingHours: mapOpeningHoursToWeek(product.businessHours.default),
    openingHoursExceptions: mapOpeningHoursExceptionsToFormValues(product.businessHours.exceptions),
    email: product.contactDetails[0].email,
    phonenumber: product.contactDetails[0].phone,
    streetName: productAddress?.streetName || "",
    postalCode: productAddress?.postalCode || "",
    city: productAddress?.city || "",
    latitude: latitude ? String(latitude) : undefined,
    longitude: longitude ? String(longitude) : undefined,
    videos,
    stfCertified,
    welcomeCyclistCertified,
    accessible,
    pricingUnit: pricing?.pricingUnit
      ? { value: pricing.pricingUnit }
      : { value: PricingUnit.Placeholder },
    businessHoursId,
    socialMediaId,
    companySocialMediaId,
    companySocialMediaEnabled,
    companyBusinessHoursId,
    companyHoursEnabled,
    transportationAvailability,
  };

  return formValues;
};
