//imported from react-simple-timefield
import * as React from "react";
import { ChangeEvent, ReactElement } from "react";

const COLON = ":";
const DEFAULT_VALUE = `00${COLON}00`;

const TIME_POS = {
  H1: 0,
  H2: 1,
  M1: 2,
  M2: 3,
};

const MAX_VALUE = {
  H1: 2,
  M1: 5,
  M2: 9,
  CLOSE: 24,
};
const CURSOR_POS = {
  MAX_H: 2,
  MAX_M: 5,
};

export function isNumber<T>(value: T): boolean {
  const number = Number(value);
  return !isNaN(number) && String(value) === String(number);
}

export function formatTimeItem(value?: string | number): string {
  return `${value || ""}00`.substring(0, TIME_POS.M1);
}

export function validateTimeAndCursor(
  value: string | undefined,
  maxHour: number,
  cursorPosition = 0
): [string | undefined, number] {
  if (!value) {
    return [value, 0];
  }
  const [oldH, oldM] = DEFAULT_VALUE.split(COLON);
  const isClosing = maxHour === MAX_VALUE.CLOSE;
  let newCursorPosition = Number(cursorPosition);
  let [newH, newM] = String(value).split(COLON);
  const allZeros = newH === "00" && newM === "00";

  newH = formatTimeItem(newH);
  if (Number(newH[TIME_POS.H1]) > MAX_VALUE.H1) {
    newH = maxHour.toString();
    if (isClosing) {
      newM = "00";
      newCursorPosition = CURSOR_POS.MAX_M;
    }
    newCursorPosition -= 1;
  } else if (Number(newH[TIME_POS.H1]) === MAX_VALUE.H1) {
    if (Number(oldH[TIME_POS.H1]) === MAX_VALUE.H1 && Number(newH) > maxHour) {
      newH = `2${oldH[TIME_POS.H2]}`;
      newCursorPosition -= CURSOR_POS.MAX_H;
    } else if (Number(newH) > maxHour) {
      newH = maxHour.toString();
      if (isClosing) {
        newM = "00";
        newCursorPosition = CURSOR_POS.MAX_M;
      }
    }
  } else if (isClosing && newCursorPosition === CURSOR_POS.MAX_M && allZeros) {
    newH = maxHour.toString();
    newM = "00";
    newCursorPosition = CURSOR_POS.MAX_M;
  }

  if (newH !== MAX_VALUE.CLOSE.toString()) {
    newM = formatTimeItem(newM);
    if (Number(newM[TIME_POS.H1]) > MAX_VALUE.M1) {
      newM = oldM;
      newCursorPosition -= 1;
    }
  } else if (isClosing && newCursorPosition > CURSOR_POS.MAX_H) {
    newM = "00";
    newCursorPosition = CURSOR_POS.MAX_M;
  }
  const validatedValue = `${newH}${COLON}${newM}`;
  return [validatedValue, newCursorPosition];
}

interface Props {
  value?: string;
  onBlur: (value: string | undefined) => void;
  maxHour: number;
  disabled?: boolean;
}

interface State {
  value: string | undefined;
  _maxLength: number;
}

export default class TimeField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const [validatedTime] = validateTimeAndCursor(this.props.value, props.maxHour);
    this.state = {
      value: validatedTime,
      _maxLength: DEFAULT_VALUE.length,
    };

    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.value !== prevProps.value) {
      const [validatedTime] = validateTimeAndCursor(this.props.value, this.props.maxHour);
      this.setState({
        value: validatedTime,
      });
    }
  }

  onInputChange(event: ChangeEvent<HTMLInputElement>): void {
    const oldValue = this.state.value || "";
    const inputEl = event.target;
    const inputValue = inputEl.value;
    const position = inputEl.selectionEnd || 0;
    const isTyped = inputValue.length > oldValue.length;
    const cursorCharacter = inputValue[position - 1];
    const addedCharacter = isTyped ? cursorCharacter : null;
    const removedCharacter = isTyped ? null : oldValue[position];
    const replacedSingleCharacter =
      inputValue.length === oldValue.length ? oldValue[position - 1] : null;

    let newValue = oldValue;
    let newPosition = position;

    if (addedCharacter !== null) {
      if (position > this.state._maxLength) {
        newPosition = this.state._maxLength;
      } else if (position === TIME_POS.M2 && addedCharacter === COLON) {
        newValue = `${inputValue.substring(0, position - 1)}${COLON}${inputValue.substring(
          position + 1
        )}`;
      } else if (position === TIME_POS.M2 && isNumber(addedCharacter)) {
        newValue = `${inputValue.substring(
          0,
          position - 1
        )}${COLON}${addedCharacter}${inputValue.substring(position + CURSOR_POS.MAX_H)}`;
        newPosition = position + 1;
      } else if (isNumber(addedCharacter)) {
        // user typed a number
        newValue =
          inputValue.substring(0, position - 1) +
          addedCharacter +
          inputValue.substring(position + 1);
        if (position === CURSOR_POS.MAX_H) {
          newPosition = position + 1;
        }
      } else {
        // if user typed NOT a number, then keep old value & position
        newPosition = position - 1;
      }
    } else if (replacedSingleCharacter !== null) {
      // user replaced only a single character
      if (isNumber(cursorCharacter)) {
        if (position - 1 === CURSOR_POS.MAX_H) {
          newValue = `${inputValue.substring(0, position - 1)}${COLON}${inputValue.substring(
            position
          )}`;
        } else {
          newValue = inputValue;
        }
      } else {
        // user replaced a number on some non-number character
        newValue = oldValue;
        newPosition = position - 1;
      }
    } else if (
      typeof cursorCharacter !== "undefined" &&
      cursorCharacter !== COLON &&
      !isNumber(cursorCharacter)
    ) {
      // set of characters replaced by non-number
      newValue = oldValue;
      newPosition = position - 1;
    } else if (removedCharacter !== null) {
      if (position === CURSOR_POS.MAX_H && removedCharacter === COLON) {
        newValue = `${inputValue.substring(0, position - 1)}0${COLON}${inputValue.substring(
          position
        )}`;
        newPosition = position - 1;
      } else {
        // user removed a number
        newValue = `${inputValue.substring(0, position)}0${inputValue.substring(position)}`;
      }
    }

    const [validatedTime, validatedCursorPosition] = validateTimeAndCursor(
      newValue,
      this.props.maxHour,
      newPosition
    );

    this.setState({ value: validatedTime }, () => {
      this.setCursorPos(inputEl, validatedCursorPosition);
    });

    event.persist();
  }

  setCursorPos = (element: HTMLInputElement, pos: number) => {
    element.selectionStart = pos;
    element.selectionEnd = pos;
  };

  render(): ReactElement {
    const { value } = this.state;
    const { onBlur, maxHour, ...props } = this.props;

    return (
      <input
        type="text"
        inputMode={"numeric"}
        placeholder={"00:00"}
        {...props}
        value={value}
        className={"text-center"}
        min={0}
        onFocus={(e) => this.setCursorPos(e.target, 0)}
        onChange={this.onInputChange}
        onBlur={() => onBlur(value)}
      />
    );
  }
}
