import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { BusinessHours } from "../../types";
import { formatDateRange } from "../../utils/format";
import { BasicInfoModal } from "../DataHubModal";
import ViewOpeningHours from "./ViewOpeningHours";
import ViewOpeningHoursExceptions from "./ViewOpeningHoursExceptions";

type BusinessHoursModalProps = {
  onClose: () => void;
  businessHours: BusinessHours;
};

const ExceptionContainer = styled.div`
  flex-basis: 14.6rem;
  padding-bottom: 1rem;
`;

const BusinessHoursModal = ({ businessHours, onClose }: BusinessHoursModalProps) => {
  const { t } = useTranslation();

  return (
    <BasicInfoModal
      header={<h1 className="text-larger m-0 p-0">{t("openingHours.modal.header")}</h1>}
      onCancel={onClose}
      size="lg"
    >
      <ViewOpeningHours
        header={
          <h2 className="text-normal-size pt-2 mb-1">
            {t("openingHours.modal.openingHoursHeader")}
          </h2>
        }
        openingHours={businessHours.default}
      />
      <ViewOpeningHoursExceptions
        header={
          <h2 className="text-normal-size pt-5 mb-0">{t("openingHours.modal.exceptionsHeader")}</h2>
        }
        exceptions={businessHours.exceptions}
        wrap
        renderException={(id, start, end, openingHours) => (
          <ExceptionContainer key={id}>
            <ViewOpeningHours header={formatDateRange(start, end)} openingHours={openingHours} />
          </ExceptionContainer>
        )}
      />
    </BasicInfoModal>
  );
};

export default BusinessHoursModal;
