import { useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import getCompanies from "../../graphqlQueries/getCompanies";
import {
  getJoinRequestDMODataForVF,
  GetJoinRequestDMODataForVFResult,
} from "../../graphqlQueries/getJoinRequests";
import getUserProfilesQuery, { GetUserProfilesResult } from "../../graphqlQueries/getUserProfiles";
import { UserRole } from "../../types";
import { CompanyList, GetCompaniesResult, TableEllipsis } from "../../types/companyTypes";
import { getHasuraRoleContext } from "../../utils/functions";
import { composeCompanyData, composeTableColumns } from "../../utils/tableUtils";
import Bulletins from "../bulletin/Bulletins";
import CompanyTable from "../companies/CompanyTable";
import Icon from "../Icon";
import Loading from "../Loading";
import Notification from "../Notification";
import PageHeader from "../PageHeader";
import UserTable from "../UserTable";
import DashboardLayout from "./DashboardLayout";
import DmoJoinRequests from "./DmoJoinRequests";
import { MaintenanceBannerSection } from "./MaintenanceBannerSection";
import { AdminDashboardEllipsis } from "./AdminDashboardEllipsis";

const VFAdminDashboard = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<string | null>("companyTable");
  const { data, loading, error } = useQuery<GetCompaniesResult>(getCompanies, {
    context: getHasuraRoleContext(UserRole.ManageCuration),
    fetchPolicy: "cache-and-network",
  });
  const isRefetchedData = React.useRef(false);

  const { data: userData } = useQuery<GetUserProfilesResult>(getUserProfilesQuery, {
    context: getHasuraRoleContext(UserRole.ManageCuration),
    fetchPolicy: "cache-and-network",
  });

  const { data: dmoJoinRequests } = useQuery<GetJoinRequestDMODataForVFResult>(
    getJoinRequestDMODataForVF,
    {
      context: getHasuraRoleContext(UserRole.ManageCuration),
      fetchPolicy: "cache-and-network",
    }
  );

  const companyData: CompanyList[] = useMemo(() => composeCompanyData(data?.company || []), [data]);
  const columns = useMemo(() => composeTableColumns(companyData, t), [companyData, t]);

  useEffect(() => {
    if (!isRefetchedData.current && dmoJoinRequests) {
      setActiveTab(
        dmoJoinRequests && dmoJoinRequests.companyJoinRequest.length > 0
          ? "userTable"
          : "companyTable"
      );
      isRefetchedData.current = true;
    }
  }, [dmoJoinRequests]);

  const [openPopovers, setOpenPopovers] = useState<string>("");

  const renderEllipsisComponent = useCallback(
    (ellipsis: TableEllipsis) => {
      if (!ellipsis) {
        return <></>;
      }

      return (
        <AdminDashboardEllipsis
          ellipsis={ellipsis}
          setOpenPopovers={setOpenPopovers}
          openPopovers={openPopovers}
        />
      );
    },
    [openPopovers]
  );

  const getVFAdminContent = () => {
    return (
      <>
        {error ? (
          <Notification type="danger">{t("dashboard.loadingCompaniesFailed")}</Notification>
        ) : (
          <>
            <div className="mb-5">
              <Bulletins canEdit />
            </div>
            <MaintenanceBannerSection />
            <Tabs id="dashboard-tabs" activeKey={activeTab || undefined} onSelect={setActiveTab}>
              <Tab eventKey="companyTable" title={t("dashboard.companyTabTitle")}>
                <div className="bg-white p-3 border-left border-right border-bottom">
                  <h2 className="mb-4 h3-size">{t("dashboard.companyTableTitle")}</h2>
                  <p className="color-gray-800">{t("dashboard.companyTableDescription")}</p>
                  <CompanyTable
                    companyData={companyData}
                    columns={columns}
                    renderEllipsisComponent={renderEllipsisComponent}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="userTable"
                title={
                  <div className="d-flex flex-row align-items-center">
                    {t("dashboard.userTabTitle")}
                    {dmoJoinRequests && dmoJoinRequests.companyJoinRequest.length > 0 && (
                      <Icon className="ml-2" name="circle-filled" color="primary" size="x-small" />
                    )}
                  </div>
                }
              >
                <div className="bg-white p-3 border-left border-right border-bottom">
                  <div className="mb-3">
                    {dmoJoinRequests && dmoJoinRequests.companyJoinRequest.length > 0 && (
                      <>
                        <h2 className="mb-4 h3-size">
                          {`${t("dashboard.pendingJoinRequestsTitle")} (${
                            dmoJoinRequests.companyJoinRequest.length
                          })`}
                        </h2>
                        <p className="color-gray-800">{t("dashboard.pendingJoinRequestsText")}</p>
                      </>
                    )}
                    <DmoJoinRequests dmoJoinRequests={dmoJoinRequests?.companyJoinRequest ?? []} />
                  </div>
                  <h2 className="mb-4 h3-size">{t("dashboard.userTableTitle")}</h2>
                  <p className="color-gray-800">{t("dashboard.userTableDescription")}</p>
                  <UserTable
                    users={userData?.userFullProfile || []}
                    companies={data?.company || []}
                  />
                </div>
              </Tab>
            </Tabs>
          </>
        )}
      </>
    );
  };

  return (
    <DashboardLayout
      header={<PageHeader>{t("dashboard.headerVFAdmin")}</PageHeader>}
      content={loading ? <Loading i18nKey="dashboard.loadingCompanyData" /> : getVFAdminContent()}
    />
  );
};

export default VFAdminDashboard;
