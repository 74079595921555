import { gql } from "@apollo/client";

const verifyLink = gql`
  query verifyLink($link: String!) {
    VerifyLink(link: $link) {
      isValid
      status
    }
  }
`;

export type VerifyLinkQueryResult = {
  VerifyLink: { isValid: boolean; status: string | null };
};

export type VerifyLinkQueryVariables = {
  link: string;
};

export default verifyLink;
