import { gql } from "@apollo/client";

import { AffectedRowsResult, IdResult } from "../types";

const updateCompanyInfoMutation = gql`
  mutation updateCompanyInfo(
    $id: uuid!
    $businessName: String!
    $email: String!
    $phone: String!
    $city: String!
    $postalCode: String!
    $streetName: String!
    $websiteUrl: String
    $webshopUrl: String
    $description: String!
    $logoUrl: String
    $logoThumbnailUrl: String
    $socialMediaId: uuid!
    $dmoAreas: [hasuradb_dmo_area_insert_input!]!
    $completed: Boolean!
    $businessHoursId: uuid
  ) {
    updateCompanyByPk(
      pk_columns: { id: $id }
      _set: {
        businessName: $businessName
        description: $description
        completed: $completed
        websiteUrl: $websiteUrl
        webshopUrl: $webshopUrl
        logoUrl: $logoUrl
        logoThumbnailUrl: $logoThumbnailUrl
        businessHoursId: $businessHoursId
        socialMediaId: $socialMediaId
      }
    ) {
      id
    }

    updatePostalAddress(
      where: { companyId: { _eq: $id } }
      _set: { postalCode: $postalCode, streetName: $streetName, city: $city }
    ) {
      affected_rows
    }

    updateContactDetails(
      where: { companyId: { _eq: $id } }
      _set: { email: $email, phone: $phone }
    ) {
      affected_rows
    }

    deleteDmoArea(where: { companyId: { _eq: $id } }) {
      affected_rows
    }
    insertDmoArea(objects: $dmoAreas) {
      affected_rows
    }
  }
`;

type DmoAreaUpdate = { companyId: string; curationAreaId: string };

export type UpdateCompanyInfoVariables = {
  id: string;
  businessName: string;
  email: string;
  phone: string;
  city: string;
  postalCode: string;
  streetName: string;
  websiteUrl: string;
  webshopUrl: string;
  description: string;
  logoUrl: string | null;
  logoThumbnailUrl: string | null;
  dmoAreas: DmoAreaUpdate[];
  completed: boolean;
  socialMediaId: string | null;
  businessHoursId: string | null;
};

export type UpdateCompanyInfoResult = {
  updateCompanyByPk: IdResult;
  updatePostalAddress: AffectedRowsResult;
  updateContactDetails: AffectedRowsResult;
  deleteDmoArea: AffectedRowsResult;
  insertDmoArea: AffectedRowsResult;
  deleteSocialMediaLinks: AffectedRowsResult;
  insertSocialMediaLinks: AffectedRowsResult;
};

export default updateCompanyInfoMutation;
