import React from "react";

type CommentsProps = {
  hide?: boolean;
};

const CommentsContainer = ({ hide, children }: React.PropsWithChildren<CommentsProps>) => {
  if (hide) {
    return <></>;
  }

  return <div className="d-flex flex-column p-4 mb-3 bg-gray-200">{children}</div>;
};

export default CommentsContainer;
