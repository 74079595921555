import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import markBulletinDeleted, {
  DeleteBulletinResult,
  DeleteBulletinVariables,
} from "../../graphqlQueries/deleteBulletin";
import {
  Bulletin,
  BulletinQueryResponse,
  BulletinQueryVariables,
  buildBulletinQuery,
} from "../../graphqlQueries/getBulletin";
import { BulletinTargetGroup, UserRole } from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";
import Icon from "../Icon";
import { useIsMediumOrSmaller } from "../MediaQuery";
import Notification from "../Notification";
import BulletinForm from "./BulletinForm";
import BulletinList from "./BulletinList";
import ViewBulletin from "./ViewBulletin";

type BulletinProps = {
  targetGroup?: BulletinTargetGroup;
  canEdit?: boolean;
};

type BulletinContainerProps = {
  error: string | null;
  showCreateForm: boolean;
};

const BulletinContainer = styled.div`
  margin: 20px 0px;
  width: 100%;
  padding-top: ${(props: BulletinContainerProps) => (props.error ? "0.5rem" : "0px")};
`;

const Bulletins = ({ targetGroup, canEdit }: BulletinProps) => {
  const { t, i18n } = useTranslation();
  const isMediumScreen = useIsMediumOrSmaller();
  const language = i18n.language;
  const initialLimit = 3;
  const [limit, setLimit] = useState<number>(initialLimit);
  const { data: bulletinData } = useQuery<BulletinQueryResponse, BulletinQueryVariables>(
    buildBulletinQuery(language, targetGroup),
    {
      context: getHasuraRoleContext(UserRole.ViewNews),
      variables: {
        limit: isMediumScreen ? limit : undefined,
      },
      onCompleted: (data) => setBulletinMaxCount(data.bulletinAggregate.aggregate.count),
    }
  );
  const [bulletinMaxCount, setBulletinMaxCount] = useState<number>(0);

  const [error, setError] = useState<string | null>(null);
  const [selectedBulletin, setSelectedBulletin] = useState<Bulletin>(
    bulletinData?.bulletin[0] || ({} as Bulletin)
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);

  useEffect(() => {
    if (bulletinData) {
      setSelectedBulletin(bulletinData.bulletin[0]);
    }
  }, [bulletinData]);

  const bulletinContainerTitle = !showCreateForm
    ? "dashboard.bulletinHeader"
    : isEditing
    ? "dashboard.editingBulletinHeader"
    : "dashboard.createBulletinHeader";

  const deleteBulletin = useMutation<DeleteBulletinResult, DeleteBulletinVariables>(
    markBulletinDeleted,
    {
      context: getHasuraRoleContext(UserRole.ManageDatahub),
      refetchQueries: ["getBulletinsForTargetGroup"],
    }
  );

  return (
    <BulletinContainer showCreateForm={showCreateForm} error={error} className="dashboard-card">
      {error && (
        <Notification type="danger" onClose={() => setError(null)} className="mx-2">
          <p className="mb-0">{t(error)}</p>
        </Notification>
      )}
      <div className="d-flex flex-row align-items-center justify-content-between mb-3 px-3 pt-3">
        <div className="d-flex flex-row align-items-center w-100">
          <Icon name="news" />
          <h2
            className={showCreateForm ? "h4-size fw-normal ml-3 mb-0" : "text-landing-h4 ml-3 mb-0"}
          >
            {t(bulletinContainerTitle)}
          </h2>
          {showCreateForm && isEditing && (
            <p className="text-small color-gray-600 mb-0 ml-auto mr-5">
              {t("dashboard.bulletinCreatedAt")}{" "}
              {moment(selectedBulletin?.createdAt).format("D.M.YYYY")}
            </p>
          )}
          {canEdit && !showCreateForm && (
            <Button
              className="btn-light ml-auto"
              onClick={() => {
                setShowCreateForm(true);
                setIsEditing(false);
              }}
            >
              {t("dashboard.createNewBulletinButton")}
            </Button>
          )}
        </div>
      </div>
      {showCreateForm ? (
        <BulletinForm
          onSubmitForm={() => setShowCreateForm(false)}
          bulletin={isEditing ? selectedBulletin : undefined}
          deleteMutation={deleteBulletin}
          setError={setError}
        />
      ) : bulletinData && bulletinData.bulletin?.length > 0 ? (
        <div className="row border-top m-0 ">
          <div
            className="col-md-6 p-0"
            style={{ overflowY: !isMediumScreen ? "scroll" : "hidden", overflowX: "hidden" }}
          >
            <BulletinList
              bulletins={bulletinData.bulletin}
              selectedBulletin={selectedBulletin}
              onSelect={setSelectedBulletin}
              language={language}
              canEdit={canEdit}
              deleteMutation={deleteBulletin}
              setError={setError}
              setShowCreateForm={setShowCreateForm}
              setIsEditing={setIsEditing}
              setLimit={() => setLimit(limit + initialLimit)}
              canLoadMore={bulletinMaxCount > limit}
            />
          </div>
          {!isMediumScreen && (
            <div className="col col-12 col-sm-6 p-0">
              <ViewBulletin bulletin={selectedBulletin} language={language} />
            </div>
          )}
        </div>
      ) : (
        <div className="container px-4 pb-2">
          <p className="text-small color-gray-600 mt-1">{t("dashboard.noBulletins")}</p>
        </div>
      )}
    </BulletinContainer>
  );
};

export default Bulletins;
