import {
  CurationStatus,
  CuratorEditProductFormValues,
  DatahubType,
  ProductCurationStatus,
  ProductPublishMode,
  ProductPublishResult,
  UserRole,
} from "../../types";
import { FormProvider, useForm } from "react-hook-form";
import { Prompt, useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import getProductQuery, { GetProductQueryData } from "../../graphqlQueries/getProduct";

import CuratorProductToolbar from "./CuratorProductToolbar";
import LastEdit from "../LastEdit";
import ProductForm from "./ProductForm";
import { Spinner } from "react-bootstrap";
import UpdateProductErrorModal from "./UpdateProductErrorModal";
import { getHasuraRoleContext } from "../../utils/functions";
import { hasBrokenLink, isDirty } from "../../utils/productFormUtils/productUtils";
import { mapDbProductToFormValues } from "../../utils/mapDbProductToFormValues";
import { searchAndSetErrorForInvalidUrl } from "../../utils/formUtils";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useUpdateProduct } from "../hooks/useUpdateProduct";
import { useUpdateSocialMedias } from "../hooks/useUpdateSocialMedias";
import { userInfoVar } from "../../utils/ApolloCache";

type ParamsType = {
  productId: string;
};

type LastEditInformation = {
  dateStr: string;
  editorName: string;
};

type CuratorFormProps = {
  companyId: string;
  setShowNotification: (msg: string) => void;
};

export const CURATOR_FORM_NAME = "curator-product-form";

const curatorRoleContext = getHasuraRoleContext(UserRole.ManageCuration);
const publishMode: ProductPublishMode = "update";

const CuratorEditForm = ({ companyId, setShowNotification }: CuratorFormProps) => {
  const { productId } = useParams<ParamsType>();

  const [updateResult, setUpdateResult] = useState<ProductPublishResult>("noresult");
  const [lastEditInfo, setLastEditInfo] = useState<LastEditInformation | undefined>();

  const [nextUrl, setNextUrl] = useState<string>();

  const history = useHistory();

  const { t } = useTranslation();

  const hookFormMethods = useForm<CuratorEditProductFormValues>({ mode: "all" });
  const { reset, formState, setError, getValues, watch } = hookFormMethods;

  const productType = watch("productType");

  const [loadProductData, { called, data: productDataResult }] = useLazyQuery<GetProductQueryData>(
    getProductQuery(UserRole.ManageCuration),
    {
      variables: { id: productId },
      context: curatorRoleContext,
      fetchPolicy: "network-only",
      nextFetchPolicy: "standby",
    }
  );

  const { updateProduct } = useUpdateProduct();
  const { updateSocialMedia } = useUpdateSocialMedias();

  useEffect(() => {
    if (nextUrl) {
      history.push(nextUrl);
    }
  }, [nextUrl, history]);

  useEffect(() => {
    if (productId && !called) {
      loadProductData();
    }
  }, [productId, called, loadProductData]);

  useEffect(() => {
    const productData = productDataResult?.product;
    if (productData) {
      const formValues = mapDbProductToFormValues(productData);
      reset(formValues);

      setLastEditInfo({
        dateStr: productData.updatedAt,
        editorName: productData.updatedByUser?.name ?? "",
      });
      if (hasBrokenLink(productData)) {
        searchAndSetErrorForInvalidUrl(productData, setError);
      }
    }
  }, [productDataResult, reset, setError, setLastEditInfo]);

  const onSubmit = async (formValues: CuratorEditProductFormValues) => {
    setUpdateResult("noresult");

    const productData = productDataResult?.product;
    const productOwnerCompanyId = (productData && productData.company.id) ?? "";
    const productCurations =
      productData && productData.productCurations && productData.productCurations[0];
    const curationStatus = productCurations?.curationStatus || CurationStatus.Pending;
    const currentProductCurationStatus = productCurations?.productCurationStatus;
    const productCurationStatus = currentProductCurationStatus || ProductCurationStatus.New;
    // avoid changing product's social media links' companyId, when curator modifies it
    const companyIdForSocialMedia =
      productOwnerCompanyId !== "" ? productOwnerCompanyId : companyId;

    try {
      const socialMediaId = await updateSocialMedia({
        companySocialMediaId: formValues.companySocialMediaId,
        socialMediaId: formValues.socialMediaId,
        productSocialMediaLinks: formValues.productSocialMediaLinks || [],
        companySocialMediaEnabled: formValues.companySocialMediaEnabled,
        companyId: companyIdForSocialMedia,
        userRole: UserRole.ManageCuration,
      });

      await updateProduct({
        formValues,
        productId,
        companyId: productOwnerCompanyId,
        publishMode,
        productCurationStatus,
        curationStatus,
        productType,
        isCurator: true,
        comment: {
          commenterCompanyId: companyId,
          commenterName: userInfoVar()?.fullName ?? "",
          recipientRole: DatahubType.TC,
        },
        socialMediaId,
      });

      setUpdateResult("ok");
      setShowNotification(t("productNotification.update"));
      setNextUrl(`/company/${companyId}/curator-workspace/${productId}`);
    } catch (e) {
      console.error(`Product update failed for update mode: ${e}`);
      setUpdateResult("error");
    }
  };

  const onDiscardChanges = () => {
    setNextUrl(`/company/${companyId}/curator-workspace/${productId}`);
  };

  const showSpinner = productId && !productDataResult;
  const showPrompt =
    !nextUrl &&
    Object.keys(formState.dirtyFields).length > 0 &&
    isDirty({
      dirty: formState.dirtyFields,
      initial: productDataResult?.product?.productInformations,
      current: getValues().details,
    });

  return (
    <>
      {showSpinner ? (
        <div className="d-flex justify-content-center p-5 w-100 align-items-center">
          <div className="mr-3">
            <Spinner animation="border" />
          </div>
          <h3 className="m-0">{t("productInfo.loadingProductInfo")}</h3>
        </div>
      ) : (
        <FormProvider {...hookFormMethods}>
          <ProductForm
            isCurator
            onSubmit={onSubmit}
            publishMode={publishMode}
            editMode={true}
            companySocialMedia={{
              ...productDataResult?.product?.company.socialMedia,
              id: productDataResult?.product?.company.socialMediaId ?? "",
              companySocialMedia: !!productDataResult?.product?.company.socialMedia,
            }}
            toolbar={<CuratorProductToolbar onDiscardChanges={onDiscardChanges} />}
            lastEdit={
              lastEditInfo ? (
                <LastEdit
                  dateStr={lastEditInfo.dateStr}
                  editorName={lastEditInfo.editorName}
                  align="left"
                />
              ) : null
            }
            id={CURATOR_FORM_NAME}
          />
        </FormProvider>
      )}
      {updateResult === "error" && (
        <UpdateProductErrorModal onHide={() => setUpdateResult("noresult")} />
      )}
      <Prompt when={showPrompt} message={t("productInfo.unsavedChanges")} />
    </>
  );
};

export default CuratorEditForm;
