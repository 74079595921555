import { gql } from "@apollo/client";

export const updateCommentSeenByDmoMutation = gql`
  mutation UpdateCommentSeenByDmo($id: uuid!, $seenByDmoAt: timestamptz!) {
    updateProductCommentsByPk(pk_columns: { id: $id }, _set: { seenByDmoAt: $seenByDmoAt }) {
      id
      seenByDmoAt
    }
  }
`;

export const updateCommentSeenByTcMutation = gql`
  mutation UpdateCommentSeenByTc($id: uuid!, $seenByTcAt: timestamptz!) {
    updateProductCommentsByPk(pk_columns: { id: $id }, _set: { seenByTcAt: $seenByTcAt }) {
      id
      seenByTcAt
    }
  }
`;
