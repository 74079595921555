import React, { useState } from "react";
import { Collapse } from "react-bootstrap";

import CollapseToggleButton from "./CollapseToggleButton";

type CollapsibleProps = {
  id: string;
  header: React.ReactNode;
  defaultOpen: boolean;
  children: React.ReactElement;
  headerSide?: React.ReactNode;
  headerContainerClassName?: string;
};

export const Collapsible = ({
  id,
  header,
  defaultOpen,
  children,
  headerSide,
  headerContainerClassName,
}: CollapsibleProps) => {
  const [open, setOpen] = useState(defaultOpen);
  const contentContainerName = `${id}-collapsible`;

  return (
    <>
      <div className={headerContainerClassName}>
        <div className="d-flex justify-content-between align-items-center">
          <CollapseToggleButton
            onClick={() => setOpen((current) => !current)}
            contentOpen={open}
            iconPosition="left"
            aria-controls={contentContainerName}
            aria-expanded={open}
            variant="secondary"
            className="disable-bg-effects"
          >
            <div className="w-100">{header}</div>
          </CollapseToggleButton>
          {headerSide}
        </div>
      </div>
      <Collapse in={open}>
        <div id={contentContainerName}>{children}</div>
      </Collapse>
    </>
  );
};
