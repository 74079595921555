import { gql } from "@apollo/client";

export const markBulletinDeleted = gql`
  mutation deleteBulletin($bulletinId: uuid!, $deletedAt: timestamptz!) {
    updateBulletinByPk(pk_columns: { id: $bulletinId }, _set: { deletedAt: $deletedAt }) {
      id
    }
    updateBulletinTargetGroup(
      where: { bulletinId: { _eq: $bulletinId } }
      _set: { deletedAt: $deletedAt }
    ) {
      affected_rows
    }
  }
`;

export type DeleteBulletinVariables = {
  bulletinId: string;
  deletedAt: string;
};

export type DeleteBulletinResult = {
  updateBulletinByPk: { id: string; deletedAt: string };
  updateTargetGroup: { affected_rows: number };
};

export default markBulletinDeleted;
