import { gql } from "@apollo/client";

export const validateUserEmailQuery = gql`
  query GetUserId($email: String!) {
    GetUserId(email: $email) {
      userId
      status
    }
  }
`;

export type validateUserEmailVariables = {
  email: string;
};

export type validateUserEmailQueryResult = {
  GetUserId: {
    userId: string;
    status: "not_found" | "ok";
  };
};

export default validateUserEmailQuery;
