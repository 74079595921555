import { DMODashboardCard } from "./DMODashboardCard";
import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import { useIsSmallScreen } from "../MediaQuery";
import { useTranslation } from "react-i18next";

type DMONewJoinRequestsProps = {
  businessName: string;
  newJoinRequests: number;
  companyId: string;
};

const StyledBoxBody = styled.div`
  p {
    font-family: var(--font-light);
    font-weight: normal;
  }
`;

export const DmoNewJoinRequests = ({
  businessName,
  newJoinRequests,
  companyId,
}: DMONewJoinRequestsProps) => {
  const { t } = useTranslation();
  const isMobile = useIsSmallScreen();

  const BoxBody = () => (
    <StyledBoxBody className="w-100">
      <div>
        <p className="text-small text-left m-0">
          <span className="color-gray-600">{t("notifications.toCompanyAccount")} </span>
          <strong>{businessName} </strong>
          <span className="color-gray-600">{t("notifications.requestsInUserManagement")}</span>
        </p>
      </div>
    </StyledBoxBody>
  );

  return (
    <Link
      to={`/company/${companyId}/user-management`}
      className="py-md-0 px-0 text-decoration-none color-gray-900"
    >
      <DMODashboardCard
        iconName="user-plus"
        headerTitle="notifications.newJoinRequests"
        companyId={companyId}
        userJoinRequestsAmount={newJoinRequests}
        newUpdates={newJoinRequests > 0}
        cardType="join-requests"
      >
        {!isMobile && <BoxBody />}
      </DMODashboardCard>
    </Link>
  );
};
