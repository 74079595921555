import { gql } from "@apollo/client";

export const createProductGroupPermissionsMutation = gql`
  mutation CreateProductGroupPermissions(
    $productGroupId: uuid!
    $users: [PermissionInput]
    $companies: [PermissionInput]
  ) {
    CreateProductGroupPermissions(
      productGroupId: $productGroupId
      users: $users
      companies: $companies
    ) {
      status
    }
  }
`;

export type CreateProductGroupPermissionsResult = {
  CreateProductGroupPermissions: {
    status: string;
  };
};

export type CreateProductGroupPermissionsVariables = {
  productGroupId: string;
  users?: {
    userId: string;
    role: string;
  }[];
  companies?: {
    companyId: string;
    role: string;
  }[];
};
