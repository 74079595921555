import { gql } from "@apollo/client";

export const getProductCitiesByCompany = gql`
  query GetProductCitiesByCompany($companyId: uuid!) {
    product(
      where: {
        companyId: { _eq: $companyId }
        deletedAt: { _is_null: true }
        status: { _eq: published }
        productCurations: { curationStatus: { _eq: approved } }
      }
    ) {
      id
      postalAddresses {
        city
      }
    }
  }
`;

export interface ProductCitiesQueryVars {
  companyId: string;
}

export interface ProductCities {
  id: string;
  postalAddresses: {
    city: string;
    postalCode: string;
  }[];
}

export interface ProductCitiesResult {
  product: ProductCities[];
}
