import { gql } from "@apollo/client";

export const getInstructions = gql`
  query GetInstructions($locale: [Locale!]!) {
    rolePages(locales: $locale) {
      id
      title
      subText
      slug
      imageWide(locales: en) {
        url
      }
      imageMobile(locales: en) {
        url
      }
      instructionsComponent(locales: $locale) {
        ... on SubTaskComponent {
          id
          subTitle
          subTitleInstructions {
            id
            title
            instruction {
              raw
            }
            roleTags
            faqs {
              id
              question
              answer {
                html
              }
            }
          }
        }
      }
    }
  }
`;
