import { gql } from "@apollo/client";

const getCuratorAreas = gql`
  query getCuratorAreas($id: uuid!) {
    dmoArea(where: { companyId: { _eq: $id } }) {
      curationArea {
        id
        city
      }
    }
  }
`;

export default getCuratorAreas;

export interface CurationArea {
  id: string;
  city: string;
}

export interface HasuradbDmoArea {
  curationArea: CurationArea;
}

export interface CuratorAreasResultVars {
  id: string;
}

export interface CuratorAreasResult {
  dmoArea: HasuradbDmoArea[];
}
