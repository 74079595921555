import { gql } from "@apollo/client";

export const deleteCommentMutation = gql`
  mutation deleteComment($commentId: uuid!, $deletedAt: timestamptz!) {
    updateProductCommentsByPk(pk_columns: { id: $commentId }, _set: { deletedAt: $deletedAt }) {
      id
      deletedAt
    }
  }
`;

export interface DeleteProductComment {
  commentId: string;
  deletedAt: string;
}

export interface DeleteCommentResult {
  updateProductCommentsByPk: { id: string; deletedAt: string };
}
