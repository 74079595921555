import { MaintenanceDataOut } from "../../types/bannerTypes";
import React, { useState } from "react";
import moment from "moment";
import plant from "../../images/plant.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import wateringCan from "../../images/wateringCan.svg";
import { useIsMediumOrSmaller, useIsSmallScreen } from "../MediaQuery";
import DummyLink from "../DummyLink";
import MaintenanceModal from "./MainenanceModal";

const PlantBackgroundContainer = styled.div`
  width: 59px;
  background-repeat: no-repeat;
  margin-right: 4%;
  background-position-y: bottom;
  background-size: contain;
`;

const StyledBannerContentContainer = styled.div`
  width: 100%;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: 100%;
  align-items: center;
`;

const CHARS_FIT = 130;

const formatData = (data: MaintenanceDataOut) => ({
  ...data,
  breakStartTime: moment(data.breakStartTime, "HH:mm").format("HH.mm"),
  breakEndTime: moment(data.breakEndTime, "HH:mm").format("HH.mm"),
});

export const MaintenanceBanner = ({ data }: { data: MaintenanceDataOut }) => {
  const { t, i18n } = useTranslation();
  const isSmallScreen = useIsSmallScreen();
  const isMediumOrSmaller = useIsMediumOrSmaller();
  const [showModal, setShowModal] = useState<boolean>(false);

  const timeZoneNotHelsinki =
    Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase() !== "europe/helsinki";

  const { titleEn, titleFi, contentEn, contentFi, ...times } = formatData(data);

  const { title, content } =
    i18n.language === "fi"
      ? { title: titleFi, content: contentFi }
      : { title: titleEn, content: contentEn };
  const showShort = isSmallScreen || (isMediumOrSmaller && content.length > CHARS_FIT);

  const { breakEndTime, breakEndDate, breakStartDate, breakStartTime } = times;
  const time = t(`maintenance.dashboard.outageTime${breakStartDate === breakEndDate ? "s" : ""}`, {
    startDate: moment(breakStartDate).format("D.M."),
    startTime: breakStartTime,
    endDate: moment(breakEndDate).format("D.M."),
    endTime: breakEndTime,
  });

  return (
    <div className="m-0 bg-primary-light">
      <div className="d-flex flex-row" style={{ minHeight: "4.5rem", maxHeight: 72 }}>
        <PlantBackgroundContainer
          style={{
            backgroundImage: `url(${plant})`,
          }}
        />
        <StyledBannerContentContainer
          className="d-flex py-2"
          style={{ backgroundImage: `url(${wateringCan})` }}
        >
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <h6 className="mb-1 text-small d-flex flex-wrap">
                <span className={"mr-4"}>{title}</span>
                <span>{time}</span>
              </h6>
              <span className="ml-2 text-muted text-small">
                {timeZoneNotHelsinki && `(${t("maintenance.timezoneHelsinki")})`}
              </span>
            </div>
            {showShort && content && (
              <DummyLink onClick={() => setShowModal(true)}>{t("common.readMore")}</DummyLink>
            )}
            <div>{!showShort && <p className="mb-1 text-small">{content}</p>}</div>
          </div>
        </StyledBannerContentContainer>
      </div>
      {showModal && (
        <MaintenanceModal
          title={title}
          time={time}
          content={content}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
