import { gql } from "@apollo/client";

export const getCityByPostalArea = gql`
  query getCityByPostalArea($postalCode: String) {
    postalArea(where: { postalCode: { _eq: $postalCode } }) {
      id
      city {
        city
      }
    }
  }
`;

export type getCityByPostalAreaVariables = {
  postalCode: string;
};

export type getCityByPostalAreaResult = {
  postalArea: [
    {
      id: string;
      city: {
        city: string;
      };
    }
  ];
};
