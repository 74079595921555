import {
  CurationStatusesFilter,
  Month,
  ProductNotificationFilter,
  ProductTargetGroup,
  ProductType,
} from "../types";
import {
  getCurationStatusKey,
  getMonthKey,
  getProductTypeHeaderKey,
  getTargetGroupKey,
} from "./localizationUtils";

export const targetGroupsOptions = [ProductTargetGroup.BTOC, ProductTargetGroup.BTOB].map(
  (group) => ({
    value: group,
    labelKey: getTargetGroupKey(group),
  })
);

export const monthOptions = Object.values(Month).map((month) => ({
  value: month,
  label: getMonthKey(month),
}));

export const productTypeOptions = [
  {
    value: ProductType.Accommodation,
    labelKey: getProductTypeHeaderKey(ProductType.Accommodation),
  },
  {
    value: ProductType.Attraction,
    labelKey: getProductTypeHeaderKey(ProductType.Attraction),
  },
  {
    value: ProductType.Experience,
    labelKey: getProductTypeHeaderKey(ProductType.Experience),
  },
  {
    value: ProductType.Event,
    labelKey: getProductTypeHeaderKey(ProductType.Event),
  },
  {
    value: ProductType.RentalService,
    labelKey: getProductTypeHeaderKey(ProductType.RentalService),
  },
  {
    value: ProductType.Restaurant,
    labelKey: getProductTypeHeaderKey(ProductType.Restaurant),
  },
  {
    value: ProductType.Shop,
    labelKey: getProductTypeHeaderKey(ProductType.Shop),
  },
  {
    value: ProductType.Venue,
    labelKey: getProductTypeHeaderKey(ProductType.Venue),
  },
  {
    value: ProductType.Transportation,
    labelKey: getProductTypeHeaderKey(ProductType.Transportation),
  },
];

export const curationStatuses = [
  CurationStatusesFilter.New,
  CurationStatusesFilter.Pending,
  CurationStatusesFilter.Updated,
  CurationStatusesFilter.Approved,
  CurationStatusesFilter.ApprovedAndUpdated,
  CurationStatusesFilter.All,
].map((status) => ({
  value: status,
  label: getCurationStatusKey(status),
}));

export const companyProductStatuses = [
  CurationStatusesFilter.Draft,
  CurationStatusesFilter.CurationPending,
  CurationStatusesFilter.Approved,
  CurationStatusesFilter.AllCompanyProducts,
].map((status) => ({
  value: status,
  label: getCurationStatusKey(status),
}));

export const productNotificationOptions = [
  // { value: ProductNotificationFilter.BrokenLink, label: "curatorWorkspace.brokenLinkFilter" },
  { value: ProductNotificationFilter.NewComment, label: "curatorWorkspace.commentFilter" },
];

export const externalSourceOptions = [
  { value: "bokun", label: "Bòkun" },
  { value: "johku", label: "Johku" },
];
