import React from "react";
import styled from "styled-components";

type StepSectionProps = {
  headerText?: React.ReactNode | string;
  children: React.ReactNode;
  className?: string;
  childrenClassName?: string;
};

const SectionContainer = styled.section`
  background: var(--color-white);
`;

const StepSection = ({
  headerText,
  children,
  className = "",
  childrenClassName = "",
}: StepSectionProps) => {
  return (
    <SectionContainer className={className}>
      {headerText && <h2 className="h4-size mt-2 mb-3">{headerText}</h2>}
      <div className={childrenClassName}>{children}</div>
    </SectionContainer>
  );
};

export default StepSection;
