import React from "react";
import ErrorContainer from "./ErrorContainer";
import Icon from "../../components/Icon";
import { useTranslation } from "react-i18next";

const CompanyInfoError = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex w-100 justify-content-center my-2">
        <Icon name="warning-triangle" color="primary-dark" style={{ width: "50px" }} />
      </div>
      <ErrorContainer>
        <h4 className="mb-3">{t("signup.companyInfoErrorHeader")}</h4>
        <p>{t("signup.companyInfoDesc")}</p>
        <a href="mailto:datahub@visitfinland.com">datahub@visitfinland.com</a>
      </ErrorContainer>
    </>
  );
};

export default CompanyInfoError;
