import React, { ReactNode } from "react";
import styled from "styled-components";

export const FormSectionHeader = styled.h3`
  font-size: 18px;
`;

type FormSectionProps = {
  children?: ReactNode;
};
export const FormSection = ({ children }: FormSectionProps) => (
  <section className="row border bg-white mb-3">{children}</section>
);

type FormDescriptionProps = {
  children?: ReactNode;
};
export const FormDescription = ({ children }: FormDescriptionProps) => (
  <aside className="col-xs-12 col-md-4 pb-0 pt-3 px-xl-5 pt-xl-5 px-lg-4 pt-lg-5 pt-md-4 px-md-4">
    {children}
  </aside>
);

type FormSectionContentProps = {
  children?: ReactNode;
};
export const FormSectionContent = ({ children }: FormSectionContentProps) => (
  <div className="col-xs-12 col-md-8 py-3 p-xl-5 px-lg-4 pt-lg-5 p-md-4 border-left">
    {children}
  </div>
);

export const FormSectionSubHeader = styled.h4`
  font-size: 16px;
  line-height: 1.5;
`;

type FormSubSectionProps = {
  children?: ReactNode;
};
export const FormSubSection = ({ children }: FormSubSectionProps) => (
  <section className="row mx-0 w-100">{children}</section>
);

type FormSubDescriptionProps = {
  children?: ReactNode;
  isLastRow?: boolean;
};
export const FormSubDescription = ({ children, isLastRow }: FormSubDescriptionProps) => (
  <aside className={`col-xs-12 col-md-4 px-xl-5 pt-md-4 px-md-4 ${isLastRow ? "pb-xl-5" : ""}`}>
    {children}
  </aside>
);

type FormSubSectionContentProps = {
  children?: ReactNode;
  isLastRow?: boolean;
};
export const FormSubSectionContent = ({ children, isLastRow }: FormSubSectionContentProps) => (
  <div
    className={`col-xs-12 col-md-8 pb-3 px-xl-5 p-md-4 border-left ${isLastRow ? "pb-xl-5" : ""}`}
  >
    {children}
  </div>
);
