import { gql } from "@apollo/client";

import { BulletinTargetGroup } from "../types";

export const insertBulletinOne = gql`
  mutation insertBulletinOne(
    $titleFi: String
    $titleEn: String
    $contentFi: String
    $contentEn: String
    $targetGroups: [hasuradb_bulletin_target_group_insert_input!]!
  ) {
    insertBulletinOne(
      object: {
        titleFi: $titleFi
        titleEn: $titleEn
        contentFi: $contentFi
        contentEn: $contentEn
        bulletinTargetGroups: { data: $targetGroups }
      }
    ) {
      id
    }
  }
`;

export type InsertBulletinVariables = {
  titleFi: string;
  titleEn: string;
  contentFi: string;
  contentEn: string;
  targetGroups: { targetGroup: BulletinTargetGroup }[];
};

export type InsertBulletinResult = {
  id: string;
};
