import { ActionResult, ActionResultResponse, CommentToEdit, UserRole } from "../../types";
import { Button, Form, Spinner } from "react-bootstrap";
import {
  DeleteCommentResult,
  DeleteProductComment,
  deleteCommentMutation,
} from "../../graphqlQueries/deleteComment";
import { DocumentNode, useMutation } from "@apollo/client";
import React, { useState } from "react";
import {
  UpdateCommentResult,
  UpdateProductComment,
  updateCommentMutation,
} from "../../graphqlQueries/updateCommentEdited";

import ConfirmButton from "../ConfirmButton";
import { MAX_COMMENT_LENGTH } from "../../utils/constants";
import { Prompt } from "react-router-dom";
import { getHasuraRoleContext } from "../../utils/functions";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type CommentEditFormData = {
  commentEditText: string;
};

type EditCommentProps = {
  setCommentDeleteResult: (value: ActionResult) => void;
  setCommentEditResult: (value: ActionResult) => void;
  setCommentToEdit: (show: CommentToEdit | undefined) => void;
  commentId: string;
  commentText: string;
  commentsQuery: DocumentNode;
};

const EditCommentForm = ({
  commentText,
  commentId,
  setCommentToEdit,
  setCommentEditResult,
  setCommentDeleteResult,
  commentsQuery,
}: EditCommentProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, reset, watch, formState } = useForm({
    defaultValues: { commentEditText: commentText },
  });
  const [commentLength, setCommentLength] = useState(commentText.length);
  const [show, setShow] = useState(false);

  const [updateComment] = useMutation<UpdateCommentResult, UpdateProductComment>(
    updateCommentMutation
  );

  const [markCommentDeleted] = useMutation<DeleteCommentResult, DeleteProductComment>(
    deleteCommentMutation
  );

  const refetchQueries = [commentsQuery];
  const isSubmitting = formState.isSubmitting;

  const onSubmit = (data: CommentEditFormData) => {
    return updateComment({
      variables: {
        commentText: data.commentEditText,
        commentId,
        editedAt: moment().toISOString(),
      },
      context: getHasuraRoleContext(UserRole.ManageCuration),
    })
      .then(() => {
        reset();
        setCommentToEdit(undefined);
        setCommentEditResult(ActionResultResponse.RESULT_OK);
      })
      .catch((errors) => {
        console.log(errors);
        if (errors.message.includes(ActionResultResponse.LENGTH_ERROR_MESSAGE)) {
          setCommentEditResult(ActionResultResponse.LENGTH_ERROR);
        } else {
          setCommentEditResult(ActionResultResponse.GENERAL_ERROR);
        }
      });
  };

  const deleteComment = (commentId: string) => {
    markCommentDeleted({
      variables: {
        commentId,
        deletedAt: moment().toISOString(),
      },
      context: getHasuraRoleContext(UserRole.ManageCuration),
      refetchQueries,
    })
      .then(() => {
        reset();
        setCommentToEdit(undefined);
        setCommentDeleteResult(ActionResultResponse.RESULT_OK);
      })
      .catch((errors) => {
        console.log(errors);
        setCommentDeleteResult(ActionResultResponse.GENERAL_ERROR);
      });
  };

  const commentNonEmpty = !!watch("commentEditText");

  return (
    <div className="d-flex flex-column mt-2">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="commentEditText">
          <Form.Control
            as="textarea"
            rows={2}
            name="commentEditText"
            isInvalid={!!errors.commentEditText}
            onChange={({ target }) => setCommentLength(target.value.length)}
            ref={register({
              required: "validationErrors.required",
            })}
          />
          <div className="row">
            <div className="col text-right">
              <p
                style={commentLength > MAX_COMMENT_LENGTH ? { color: "var(--color-invalid)" } : {}}
              >{`${commentLength}/${MAX_COMMENT_LENGTH}`}</p>
            </div>
          </div>
          {errors.commentEditText && errors.commentEditText.message && (
            <p className="text-danger">{t(errors.commentEditText.message)}</p>
          )}
        </Form.Group>
        <div className="d-flex justify-content-end mb-1">
          <Button
            className="mr-4"
            variant="secondary"
            onClick={() => {
              reset();
              setCommentToEdit(undefined);
            }}
          >
            {t("commentsCurator.cancel")}
          </Button>
          <ConfirmButton
            id="delete-comment-confirm"
            confirmStyle="warning"
            showIcon
            headerContent={t("commentsCurator.deletePopoverHeader")}
            popOverContent={<p className="pb-3">{t("commentsCurator.deletePopoverDescription")}</p>}
            confirmButtonContent={t("commentsCurator.delete")}
            disabled={false}
            onConfirmClick={() => deleteComment(commentId)}
            renderTriggerButton={
              <Button variant="light" onClick={() => setShow((value) => !value)}>
                {t("commentsCurator.delete")}
              </Button>
            }
            show={show}
            closePopover={() => setShow(!show)}
          />
          <Button variant="primary" type="submit" className="ml-4">
            <div className="d-flex align-items-center justify-content-center">
              {isSubmitting && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              )}
              <div className={isSubmitting ? "ml-2" : ""}>{t("commentsCurator.save")}</div>
            </div>
          </Button>
        </div>
      </Form>
      <Prompt when={commentNonEmpty} message={t("comments.commentNotEmptyPrompt")} />
    </div>
  );
};

export default EditCommentForm;
