import { gql } from "@apollo/client";

const findPostalArea = gql`
  query findPostalArea($postalCode: String) {
    postalAreas: postalAreaAggregate(where: { postalCode: { _eq: $postalCode } }) {
      aggregate {
        count
      }
    }
  }
`;

export interface FindPostalAreaVariables {
  postalCode: string;
}

export interface FindPostalAreaQueryResult {
  postalAreas: {
    aggregate: {
      count: number;
    };
  };
}

export default findPostalArea;
