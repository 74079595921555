import { ExternalProduct, ExternalProductTexts } from "../../utils/productFormUtils/productUtils";

import { ExternalAccount } from "../../types";
import React from "react";
import { useTranslation } from "react-i18next";

type ConfirmExternalAccountProps = {
  externalAccount: ExternalAccount;
  externalProductTexts: ExternalProductTexts;
  importingFrom: ExternalProduct;
};

const ConfirmExternalAccountPanel = ({
  externalAccount,
  externalProductTexts,
  importingFrom,
}: ConfirmExternalAccountProps) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="bold-text mt-3 text-center">{t("importProduct.confirmBokunAccountTitle")}</p>
      <section className="mt-3 d-flex flex-column align-items-center px-5">
        <div className="w-75 p-4 border border-2 mt-4">
          <div className="row">
            <span className="col text-right color-gray-800 text-small">
              {t(externalProductTexts.accountIdLabel)}
            </span>
            <strong className="col bold-text">{externalAccount.vendorId}</strong>
          </div>
          {importingFrom === ExternalProduct.Bokun && (
            <div className="row">
              <span className="col text-right color-gray-800 text-small">
                {t("importProduct.bokunAccountName")}
              </span>
              <span className="col text-small">{externalAccount.companyName}</span>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ConfirmExternalAccountPanel;
