import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translations from "./translations/translations.js";
import "moment";
import "moment/locale/fi";

if (navigator) {
  const lang = navigator.languages
    ? navigator.languages[0]
    : navigator.language || navigator.userLanguage;
  try {
    const savedLanguage = localStorage.getItem("i18nextLng");
    if (!savedLanguage) {
      const isFinnish = !!lang.match(/fi/i);
      const language = isFinnish ? "fi" : "en";
      localStorage.setItem("i18nextLng", language);
      document.querySelector("html")?.setAttribute("lang", language);
    }
  } catch (e) {
    // local storage is not available
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    resources: translations,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
