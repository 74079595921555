import React from "react";
import { Alert } from "react-bootstrap";
import { CSSProperties } from "styled-components";

import Icon, { IconColor, IconName, IconSize } from "./Icon";
import PlainButton from "./PlainButton";

export type NotificationType = "success" | "danger" | "info" | "info-alt";

export type NotificationProps = {
  type: NotificationType;
  children: React.ReactNode;
  onClose?: () => void;
  showIcon?: boolean;
  className?: string;
  style?: CSSProperties;
  iconSize?: IconSize;
};

const iconByType: Record<NotificationType, IconName> = {
  danger: "warning-triangle",
  success: "check",
  info: "exclamation",
  "info-alt": "exclamation",
};

const colorByType: Record<NotificationType, IconColor> = {
  danger: "primary-dark",
  success: "green",
  info: "blue",
  "info-alt": "orange",
};

const Notification = ({
  type,
  children,
  onClose,
  showIcon = true,
  style = {},
  className = "",
  iconSize = "medium",
}: NotificationProps) => (
  <Alert variant={type} className={className} style={style}>
    <div className="d-flex justify-content-between">
      <div>
        {showIcon && (
          <div>
            <Icon
              name={iconByType[type]}
              size={iconSize}
              color={colorByType[type]}
              className="mr-3"
            />
          </div>
        )}
      </div>
      <div className="d-flex align-items-center w-100">{children}</div>
      {onClose && (
        <PlainButton type="button" onClick={() => onClose()}>
          <Icon name="times" size="medium" />
        </PlainButton>
      )}
    </div>
  </Alert>
);

export default Notification;
