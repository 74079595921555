import Notification from "../Notification";
import DummyLink from "../DummyLink";
import React from "react";
import { useTranslation } from "react-i18next";

type ErrorNotificationProps = {
  errorCount: number;
  submitCount: number;
  jumpToError: () => void;
};

const ErrorNotification = ({ errorCount, submitCount, jumpToError }: ErrorNotificationProps) => {
  const { t } = useTranslation();
  return errorCount > 0 ? (
    <Notification type="danger" className="m-0">
      <div className="d-flex flex-wrap w-100 justify-content-between">
        <div>{t("productInfo.validationErrorNotification", { errorCount })}</div>
        <DummyLink onClick={jumpToError}>{t("productInfo.jumpToError")}</DummyLink>
      </div>
    </Notification>
  ) : submitCount > 0 ? (
    <Notification type="success" className="m-0">
      {t("productInfo.errorsFixed")}
    </Notification>
  ) : null;
};

export default ErrorNotification;
