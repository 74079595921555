import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { productTypes } from "../../utils/productFormUtils/productUtils";
import Icon from "../Icon";
import { IconContainer, StyledDropdown, StyledSummaryAmountHeader } from "./DashboardComponents";

type DMODashboardHeaderDropdownProps = {
  amountOfProducts: number;
  productTypeCategories: Record<string, number> | undefined;
};

export const DMODashboardHeaderDropdown: React.FunctionComponent<DMODashboardHeaderDropdownProps> = ({
  amountOfProducts,
  productTypeCategories,
}) => {
  const { t } = useTranslation();
  return (
    <StyledDropdown data-cy="region-published-products">
      <Dropdown.Toggle variant="text">
        <div>
          <StyledSummaryAmountHeader>{amountOfProducts}</StyledSummaryAmountHeader>
        </div>
        <div className="number-details-wrapper flex-column">
          <div>
            <h3 className="mb-0">{t("dashboard.publishedProductsInTotal")}</h3>
          </div>
          <div>
            <span className="numbersByProduct">
              {t("dashboard.numbersByProductCategory")}
              <Icon name="chevron-down" />
            </span>
          </div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100 flex-column" data-cy="region-products-dropdown">
        <Dropdown.Header className="p-3 d-flex align-items-center">
          {t("dashboard.productsByCategory")}
        </Dropdown.Header>
        {productTypeCategories &&
          Object.keys(productTypeCategories).map((category) => {
            const categoryType = productTypes.find((productType) => productType.type === category);
            if (!categoryType) {
              return null;
            }

            return (
              <Dropdown.Item
                key={categoryType.type}
                className="p-3 d-flex justify-content-between align-items-center"
              >
                <div>
                  <IconContainer className="mr-1">
                    <Icon color="primary-dark" name={categoryType.icon} />
                  </IconContainer>
                  <p className="m-0">{t(categoryType.headerKey)}</p>
                </div>
                <h3>{productTypeCategories[categoryType.type]}</h3>
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </StyledDropdown>
  );
};
