import React, { useState } from "react";
import { useDebouncedEffect } from "../../utils/debounce";
import { useQuery } from "@apollo/client";
import { Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Select, { CSSObjectWithLabel, SingleValue } from "react-select";
import Icon from "../Icon";
import { CmsSearchResult, CmsSearchVars } from "../../types/instructionsAndFaqTypes";
import { capitalize } from "../../utils/functions";
import { faqSearch } from "../../graphqlQueries/InstructionsAndFaq/faqSearch";
import { useTranslation } from "react-i18next";
import { FAQ_PATH, INSTRUCTIONS_PATH } from "../../utils/routeConstants";

const basicSelectStyles = {
  container: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontFamily: "var(--font-light)",
  }),
  menu: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontFamily: "var(--font-light)",
  }),
  option: (styles: CSSObjectWithLabel, state: { isFocused: boolean }) => ({
    ...styles,
    cursor: "pointer",
    backgroundColor: state.isFocused ? "var(--color-gray-200)" : "var(--color-white)",
    color: "var(--color-black)",
    "&:active": {
      backgroundColor: "var(--color-primary)",
      color: "var(--color-black)",
    },
    "&:hover": {
      backgroundColor: "var(--color-gray-200)",
    },
  }),
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    color: "var(--color-black)",
    border: "1px solid --color-gray-800",
    backgroundColor: "var(--color-white)",
    borderRadius: 0,
    "&:hover": {
      borderColor: "--color-gray-800",
    },
    padding: "1px",
    cursor: "pointer",
  }),
  placeholder: (styles: CSSObjectWithLabel) => ({
    ...styles,
    color: "var(--color-black)",
  }),
};

const DEBOUNCE_DELAY = 1000;

type InstructionSearchProps = {
  refetchRole?: () => void;
  pathname: string;
};

type SearchSelectType = {
  label: string;
  value: string;
  tags: string[];
};

const DMO_ROLE_KEY = "travel-company";
const CURATOR_ROLE_KEY = "curator";
const API_USER_ROLE_KEY = "api-user";

const mapTagToUrlParam = (tag: string) => {
  switch (tag) {
    case "Kuraattori":
      return CURATOR_ROLE_KEY;
    case "Datanhyodyntaja":
      return API_USER_ROLE_KEY;
    default:
      return DMO_ROLE_KEY;
  }
};

const options = (data: CmsSearchResult | undefined) => {
  if (!data) {
    return [];
  }

  const instructions = data.instructions.map((it) => ({
    label: it.title,
    value: it.id,
    tags: it.roleTags,
  }));

  const faqs = data.faqs.map((it) => {
    return {
      label: it.question,
      value: it.instruction[0]?.id || "",
      tags: it.instruction[0]?.roleTags || "",
    };
  });

  return [...instructions, ...faqs];
};

const InstructionSearch = ({ refetchRole, pathname }: InstructionSearchProps) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [roleKey, param, extraParam] = pathname
    .replaceAll(`${FAQ_PATH}${INSTRUCTIONS_PATH}/`, "")
    .trim()
    .split("/");

  const instructionId = extraParam || param;

  const { data, loading, refetch } = useQuery<CmsSearchResult, CmsSearchVars>(faqSearch, {
    variables: {
      locale: [i18n.language],
      search,
    },
    fetchPolicy: "network-only",
    skip: !search,
  });

  useDebouncedEffect(
    () => {
      if (search) {
        void refetch();
      }
    },
    DEBOUNCE_DELAY,
    [search]
  );

  const handleIncludesTags = (value: string): void => {
    if (!instructionId) {
      return history.push(pathname + value, { item: value });
    }

    if (instructionId === value) {
      return;
    }

    history.replace({
      pathname: pathname.replace(instructionId, value),
    });
  };

  const handleNoTagFound = (newRole: string, value: string) => {
    if (!instructionId) {
      return history.replace({
        pathname: pathname.replace(roleKey, newRole) + value,
      });
    }

    history.replace({
      pathname: pathname.replace(roleKey, newRole).replace(instructionId, value),
    });
  };

  const onChange = (select: SingleValue<SearchSelectType>) => {
    if (!select) {
      return;
    }
    // No role key if search is done on the frontpage
    if (!roleKey) {
      history.push(
        `${pathname}${INSTRUCTIONS_PATH}/${mapTagToUrlParam(select.tags[0])}/${select.value}`,
        { item: select.value }
      );
    }
    // On dmo page
    if (roleKey === DMO_ROLE_KEY) {
      if (select.tags.includes(capitalize(DMO_ROLE_KEY))) {
        handleIncludesTags(select.value);
      } else {
        handleNoTagFound(CURATOR_ROLE_KEY, select.value);
        if (refetchRole) {
          refetchRole();
        }
      }
    }
    // On curator page
    if (roleKey === CURATOR_ROLE_KEY) {
      if (select.tags.includes(capitalize(CURATOR_ROLE_KEY))) {
        handleIncludesTags(select.value);
      } else {
        handleNoTagFound(DMO_ROLE_KEY, select.value);
        if (refetchRole) {
          refetchRole();
        }
      }
    }

    setSearch("");
  };

  return (
    <Row>
      <Select
        className="w-100"
        styles={basicSelectStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: theme.colors,
        })}
        options={options(data)}
        onInputChange={setSearch}
        onChange={onChange}
        isClearable
        isLoading={loading}
        loadingMessage={() => t("common.searching")}
        noOptionsMessage={() => t("userTable.noResults")}
        placeholder={
          <div className="text-left">
            <Icon className="mr-2" name="search" size="medium" color="primary" />
            <span>{t("ukk.searchPlaceholder")}</span>
          </div>
        }
      />
    </Row>
  );
};
export default InstructionSearch;
