import { IconName } from "../../components/Icon";
import { ProductInfoLinkIds, ProductVideoLinkId } from "../../graphqlQueries/deleteProduct";
import { ProductQueryResult } from "../../graphqlQueries/getCompanyProducts";
import {
  HasuraProductVideo,
  ProductInformation,
  VerifiedLink,
} from "../../graphqlQueries/getProduct";
import verifyLink, {
  VerifyLinkQueryResult,
  VerifyLinkQueryVariables,
} from "../../graphqlQueries/verifyLink";
import {
  AllPossibleProductQueryResult,
  ApolloClientObject,
  CurationStatusesFilter,
  dirtyProps,
  ProductName,
  ProductPublishMode,
  ProductType,
  UserRole,
} from "../../types";
import {
  GetCuratorProductsResult,
  ResultsByCompanyFilter,
  ResultsByFilter,
} from "../../types/curatorProductTypes";
import { getHasuraRoleContext } from "../functions";
import { getProductTypeHeaderKey } from "../localizationUtils";

type ProductNameInformation = {
  productInformations: ProductName[];
};

export type ProductDisplayContent = {
  type: ProductType;
  headerKey: string;
  descKey: string;
  icon: IconName;
};

export const composeResultCount = ({
  approved,
  all,
  new: newProduct,
  approvedAndUpdated,
  updated,
  curationPending,
  pending,
  draft,
}: Partial<GetCuratorProductsResult & ProductQueryResult>): ResultsByCompanyFilter &
  ResultsByFilter => ({
  [CurationStatusesFilter.New]: newProduct?.aggregate.count ?? 0,
  [CurationStatusesFilter.Updated]: updated?.aggregate.count ?? 0,
  [CurationStatusesFilter.Pending]: pending?.aggregate.count ?? 0,
  [CurationStatusesFilter.ApprovedAndUpdated]: approvedAndUpdated?.aggregate.count ?? 0,
  [CurationStatusesFilter.Approved]: approved?.aggregate.count ?? 0,
  [CurationStatusesFilter.Draft]: draft?.aggregate.count ?? 0,
  [CurationStatusesFilter.CurationPending]: curationPending?.aggregate.count ?? 0,
  [CurationStatusesFilter.All]: all?.aggregate.count ?? 0,
  [CurationStatusesFilter.AllCompanyProducts]: all?.aggregate.count ?? 0,
});

export const getDefaultProductInformation = (product: ProductNameInformation) => {
  const enInfo = product.productInformations.find((info) => info.language === "en");
  if (enInfo) {
    return enInfo;
  }
  const fiInfo = product.productInformations.find((info) => info.language === "fi");
  if (fiInfo) {
    return fiInfo;
  }

  return product.productInformations[0];
};

export const getDefaultProductName = (product: ProductNameInformation) => {
  const info = getDefaultProductInformation(product);
  return info?.name ?? "";
};

export enum ExternalProduct {
  Bokun = "bokun",
  Johku = "johku",
}

type ExternalProductDisplayContent = {
  type: ExternalProduct;
  icon: IconName;
};

export const externalProductSources: ExternalProductDisplayContent[] = [
  { type: ExternalProduct.Bokun, icon: "bokunOriginal" },
  { type: ExternalProduct.Johku, icon: "johkuOriginal" },
];

export type ImportStepCard = {
  icon: IconName;
  title: string;
  description: string;
  width?: string;
  picture?: {
    src: IconName;
    text: string;
  };
};

export type ExternalProductTexts = {
  importFromHeader: string;
  importFromTitle: string;
  importFromDescription: string;
  importFromLabel: string;
  importFromPlaceHolder: string;
  getAccountError: string;
  accountIdLabel: string;
};

export const bokunImportInfoSteps: ImportStepCard[] = [
  {
    icon: "bokunPink",
    title: "importProduct.bokunFirstStepTitle",
    description: "importProduct.bokunFirstStepDescription",
    width: "5rem",
  },
  {
    icon: "successPink",
    title: "importProduct.bokunSecondStepTitle",
    description: "importProduct.bokunSecondStepDescription",
  },
  {
    icon: "datahubPink",
    title: "importProduct.bokunThirdStepTitle",
    description: "importProduct.bokunThirdStepDescription",
    width: "5.5rem",
    picture: {
      src: "bokunExample",
      text: "importProduct.bokunExampleImageText",
    },
  },
  {
    icon: "bokunDatahubPink",
    title: "importProduct.bokunFourthStepTitle",
    description: "importProduct.bokunFourthStepDescription",
    width: "10.25rem",
  },
];

export const johkuImportInfoSteps: ImportStepCard[] = [
  {
    icon: "johkuPink",
    title: "importProduct.johkuFirstStepTitle",
    description: "importProduct.johkuFirstStepDescription",
    width: "5rem",
  },
  {
    icon: "successPink",
    title: "importProduct.johkuSecondStepTitle",
    description: "importProduct.johkuSecondStepDescription",
  },
  {
    icon: "datahubPink",
    title: "importProduct.johkuThirdStepTitle",
    description: "importProduct.johkuThirdStepDescription",
    width: "5.5rem",
  },
  {
    icon: "johkuDatahubPink",
    title: "importProduct.johkuFourthStepTitle",
    description: "importProduct.johkuFourthStepDescription",
    width: "10.25rem",
  },
];

export const productTypes: ProductDisplayContent[] = [
  {
    type: ProductType.Experience,
    headerKey: getProductTypeHeaderKey(ProductType.Experience),
    descKey: "productCategories.experienceDescription",
    icon: "skiing",
  },
  {
    type: ProductType.Accommodation,
    headerKey: getProductTypeHeaderKey(ProductType.Accommodation),
    descKey: "productCategories.accommodationDescription",
    icon: "home",
  },
  {
    type: ProductType.Attraction,
    headerKey: getProductTypeHeaderKey(ProductType.Attraction),
    descKey: "productCategories.attractionDescription",
    icon: "archway",
  },
  {
    type: ProductType.Restaurant,
    headerKey: getProductTypeHeaderKey(ProductType.Restaurant),
    descKey: "productCategories.restaurantDescription",
    icon: "coffee",
  },
  {
    type: ProductType.Venue,
    headerKey: getProductTypeHeaderKey(ProductType.Venue),
    descKey: "productCategories.venueDescription",
    icon: "venue",
  },
  {
    type: ProductType.RentalService,
    headerKey: getProductTypeHeaderKey(ProductType.RentalService),
    descKey: "productCategories.rentalServiceDescription",
    icon: "car-side",
  },
  {
    type: ProductType.Event,
    headerKey: getProductTypeHeaderKey(ProductType.Event),
    descKey: "productCategories.eventDescription",
    icon: "calendar",
  },
  {
    type: ProductType.Shop,
    headerKey: getProductTypeHeaderKey(ProductType.Shop),
    descKey: "productCategories.shopDescription",
    icon: "shopping-basket",
  },
  {
    type: ProductType.Transportation,
    headerKey: getProductTypeHeaderKey(ProductType.Transportation),
    descKey: "productCategories.transportationDescription",
    icon: "transportation",
  },
];

export const getProductLinks = (
  productInformations: ProductInformation[],
  productVideos: HasuraProductVideo[] = []
) => {
  const productLinks = productInformations.reduce<Array<VerifiedLink | null>>(
    (acc, curr) => [...acc, curr.verifiedProductLink],
    []
  );

  const webshopLinks = productInformations.reduce<Array<VerifiedLink | null>>(
    (acc, curr) => [...acc, curr.verifiedWebshopLink],
    []
  );

  const videoLinks = productVideos.reduce<Array<VerifiedLink | null>>(
    (acc, curr) => [...acc, curr.verifiedVideoLink],
    []
  );

  const filterNull = (links: Array<VerifiedLink | null>): Array<VerifiedLink> => {
    return links.reduce<Array<VerifiedLink>>(
      (acc, link) => (link !== null ? [...acc, link] : acc),
      []
    );
  };

  return {
    productLinks,
    webshopLinks,
    videoLinks,
    allLinks: [...filterNull(productLinks), ...filterNull(webshopLinks), ...filterNull(videoLinks)],
  };
};

export const getLinkIds = (
  productInformations: ProductInfoLinkIds[],
  productVideos: ProductVideoLinkId[]
) => {
  const prodLinks = productInformations.reduce<string[]>(
    (acc, curr) =>
      curr.verifiedProductLink !== null ? [...acc, curr.verifiedProductLink.id] : acc,
    []
  );

  const webshopLinks = productInformations.reduce<string[]>(
    (acc, curr) =>
      curr.verifiedWebshopLink !== null ? [...acc, curr.verifiedWebshopLink.id] : acc,
    []
  );

  const videoLinks = productVideos.reduce<string[]>(
    (acc, curr) => (curr.verifiedVideoLink !== null ? [...acc, curr.verifiedVideoLink.id] : acc),
    []
  );

  return [...webshopLinks, ...prodLinks, ...videoLinks];
};

export const hasBrokenLink = (product: AllPossibleProductQueryResult): boolean => {
  if (!product) {
    return false;
  }
  const productLinks = getProductLinks(product.productInformations, product.productVideos);

  return productLinks.allLinks.some((link) => !!link.invalidAt);
};

/*
Due to bug in react-hook-form productDescription is dirty by default.
 */
export const isDirty = ({ dirty: { details, ...rest }, initial, current }: dirtyProps) => {
  if (Object.keys(rest).length > 0) {
    return true;
  } else if (details?.some((e) => e && Object.keys(e).length > 1)) {
    return true;
  }
  if (!initial || !current) {
    return false;
  } else if (initial.length !== current.length) {
    return true;
  } else {
    return initial.some(
      ({ language, description }) =>
        current.find((c) => c.language === language)?.productDescription !== description
    );
  }
};

type LinkValidatorArguments = {
  apolloClient: ApolloClientObject;
  link: string | undefined;
  errorMessage: string;
  publishMode: ProductPublishMode;
  isDmo: boolean;
};

export const linkValidator = async ({
  apolloClient,
  link,
  errorMessage,
  publishMode,
  isDmo,
}: LinkValidatorArguments) => {
  if (!link || publishMode === "draft") {
    return true;
  }
  const userRole = isDmo ? UserRole.ManageCuration : UserRole.ManageProducts;
  try {
    const validationResult = await apolloClient.query<
      VerifyLinkQueryResult,
      VerifyLinkQueryVariables
    >({
      query: verifyLink,
      variables: {
        link: link,
      },
      context: getHasuraRoleContext(userRole),
    });

    if (validationResult.data.VerifyLink.isValid) {
      return true;
    }
  } catch (error) {
    console.error("Link validation error", { link, error });
  }

  return errorMessage;
};

export const shouldAddCapacity = (type?: ProductType) => {
  return (
    type === ProductType.Venue ||
    type === ProductType.Experience ||
    type === ProductType.Transportation
  );
};

export const shouldAddDuration = (type?: ProductType) => {
  return type === ProductType.Experience;
};
