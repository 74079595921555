import React, { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  DataHubImage,
  FileWithSize,
  ImageEndpoint,
  ImageUploadError,
  ImageUploadErrorEnum,
} from "../../types";
import { getDatahubApiEndpoint } from "../../utils/functions";
import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";
import ImageModal from "../ImageModal";
import ImageUpload from "../imageUpload/ImageUpload";
import Thumbnail from "../Thumbnail";
import { ImageUploadErrorPopover } from "../imageUpload/ImageUploadErrorPopover";

type CompanyDetailsSectionProps = {
  isCurator: boolean;
};

const CompanyDetailsSection = ({ isCurator }: CompanyDetailsSectionProps) => {
  const [viewImage, setViewImage] = useState<DataHubImage | null>();
  const [uploadError, setUploadError] = useState<ImageUploadError[]>([]);

  const { register, errors, watch } = useFormContext();
  const { t } = useTranslation();
  const images: DataHubImage[] = watch("images") || [];

  const onUploadError = useCallback(
    (error: ImageUploadError[]) => {
      setUploadError((prev) => [...prev, ...error]);
    },
    [setUploadError]
  );

  const MIN_LOGO_SIDE = 150;

  const logoValidator = useCallback((file: FileWithSize) => {
    if (file.height < MIN_LOGO_SIDE || file.width < MIN_LOGO_SIDE) {
      return {
        type: ImageUploadErrorEnum.TooSmallLogoDimensions,
        variables: {
          name: file.file.name,
          height: String(file.height),
          width: String(file.width),
        },
      };
    }
  }, []);

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader data-cy="company-details">
          {isCurator
            ? t("companyInfo.organisationDetailsHeader")
            : t("companyInfo.companyDetailsHeader")}
        </FormSectionHeader>
        <p>{t("companyInfo.requiredDescription")}</p>
      </FormDescription>
      <FormSectionContent>
        <Form.Group controlId="officialName">
          <Form.Label>{t("companyInfo.officialName")}</Form.Label>
          <Form.Control plaintext name="officialName" readOnly ref={register()} />
        </Form.Group>
        <Form.Group controlId="businessId">
          <Form.Label>{t("companyInfo.businessId")}</Form.Label>
          <Form.Control plaintext name="businessId" readOnly ref={register()} />
        </Form.Group>
        <Form.Group controlId="businessName">
          <Form.Label>
            {isCurator ? t("companyInfo.organisationName") : t("companyInfo.marketingName")}*
          </Form.Label>
          <Form.Control
            type="text"
            name="businessName"
            className={errors.businessName ? "is-invalid" : ""}
            ref={register({
              required: "validationErrors.required",
            })}
          />
          {errors.businessName && <p className="text-danger">{t(errors.businessName.message)}</p>}
        </Form.Group>

        <Form.Group controlId="description">
          <Form.Label>
            {isCurator
              ? t("companyInfo.organisationDescription")
              : t("companyInfo.companyDescription")}
          </Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            className={errors.description ? "is-invalid" : ""}
            ref={register()}
          />
          {errors.description && <p className="text-danger">{t(errors.description.message)}</p>}
        </Form.Group>
        <Form.Group controlId="companyLogo">
          <Form.Label>{t("companyInfo.companyLogo")}</Form.Label>
          <Controller
            name="images"
            defaultValue={[]}
            render={({ onChange, value }) => {
              const images = value as DataHubImage[];
              return (
                <>
                  <ImageUpload
                    name="images"
                    images={images}
                    hide={images.length !== 0}
                    onChange={onChange}
                    setInvalidImages={onUploadError}
                    resetInvalidImages={() => setUploadError([])}
                    single={true}
                    validator={logoValidator}
                    imageEndpoint={`${getDatahubApiEndpoint()}/${
                      ImageEndpoint.CompanyLogoEndpoint
                    }`}
                  />
                  {uploadError.length > 0 && (
                    <ImageUploadErrorPopover invalidImages={uploadError} />
                  )}
                  <div className="row">
                    {images.map((image, i) => (
                      <div className="col-3 mt-2" key={`Thumbnail_${i}`}>
                        <Thumbnail
                          image={image}
                          onDelete={() => {
                            onChange([]);
                          }}
                          onClick={(clickedImage) => {
                            setViewImage(clickedImage);
                          }}
                          altText={`Thumbnail ${i}`}
                          showDelete
                          noCrop
                        />
                      </div>
                    ))}
                  </div>
                </>
              );
            }}
          />
          {viewImage && (
            <ImageModal
              images={images}
              viewImage={viewImage}
              onHide={() => setViewImage(null)}
              showThumbnails={false}
            />
          )}
        </Form.Group>
      </FormSectionContent>
    </FormSection>
  );
};

export default CompanyDetailsSection;
