import React from "react";
import { Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import Notification from "./Notification";
import ExternalLink from "./ExternalLink";

type TranslationModalProps = {
  onHide: () => void;
};

const TranslationModal = ({ onHide }: TranslationModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal show onHide={onHide} size="lg">
      <Modal.Header closeButton>{t("translationInfoModal.header")}</Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <section className="pr-lg-5">
            {t("translationInfoModal.description")}
            <Notification type={"info"} className={"my-4"}>
              {t("translationInfoModal.info")}
            </Notification>
          </section>
          <section className="mt-4 pr-lg-5">
            <h3 className="mb-3">{t("translationInfoModal.orderFrom")}</h3>
            <p className="mb-0">
              <Trans i18nKey="translationInfoModal.loginText">
                Go to the service
                <ExternalLink href={t("translationInfoModal.loginUrl")}>here</ExternalLink>
              </Trans>
            </p>
            <p>
              <Trans i18nKey="translationInfoModal.instructionsText">
                Read
                <ExternalLink href={t("translationInfoModal.instructionsUrl")}>
                  user instructions
                </ExternalLink>
              </Trans>
            </p>
          </section>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TranslationModal;
