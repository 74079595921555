import { Button, ButtonProps } from "react-bootstrap";

import IconButton from "./IconButton";
import { IconElementPosition } from "../types";
import React from "react";
import { default as c } from "classnames";

export type DataHubButtonVariant = "primary" | "secondary" | "outline" | "ghost" | "ghost-pink";

type DataHubButtonProps = ButtonProps & {
  variant: DataHubButtonVariant;
  className?: string;
  iconElement?: JSX.Element;
  iconPosition?: IconElementPosition;
  hideIcon?: boolean;
  children: React.ReactNode;
};

export const DataHubButton: React.FunctionComponent<DataHubButtonProps> = (props) => {
  const { className, iconElement, iconPosition, hideIcon, disabled, children, variant } = props;

  if (iconElement) {
    return (
      <IconButton
        {...props}
        className={c("btn", className, {
          disabled: disabled,
          "datahub-button-outline": variant === "outline",
        })}
        iconElement={iconElement}
        iconPosition={iconPosition}
        hideIcon={hideIcon}
        bsPrefix="datahub-button"
      >
        {children}
      </IconButton>
    );
  }

  return (
    <Button
      {...props}
      bsPrefix="datahub-button"
      className={c(className, {
        disabled: disabled,
      })}
    >
      {children}
    </Button>
  );
};
