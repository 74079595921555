import React from "react";
import IconButton from "../IconButton";
import Icon from "../Icon";
import { TableEllipsis } from "../../types/companyTypes";
import { useTranslation } from "react-i18next";
import { AdminDashboardEllipsisBody } from "./AdminDashboardEllipsisBody";
import { OverlayTrigger, Popover } from "react-bootstrap";
import AccordionToggle from "../accordion/AccordionToggle";
import AccordionContent from "../accordion/AccordionContent";
import Accordion from "react-bootstrap/Accordion";

type AdminDashboardEllipsisProps = {
  ellipsis: TableEllipsis;
  openPopovers: string;
  setOpenPopovers: (id: string) => void;
};

export const AdminDashboardEllipsis = ({
  ellipsis,
  setOpenPopovers,
  openPopovers,
}: AdminDashboardEllipsisProps) => {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      show={openPopovers === ellipsis.id}
      trigger="click"
      placement="bottom-end"
      overlay={
        <Popover id="popover-positioned-bottom-end">
          <Popover.Content className="p-0">
            {openPopovers && (
              <Accordion defaultActiveKey={ellipsis.id}>
                <AccordionToggle
                  className="rounded bg-gray-100 px-2"
                  iconPosition="right"
                  eventKey={ellipsis.id}
                  isFAQ={false}
                >
                  <AccordionContent customStyle="d-flex">
                    <div className="m-3">{t("companyTable.popoverHeader")}</div>
                  </AccordionContent>
                </AccordionToggle>
                <Accordion.Collapse eventKey={ellipsis.id}>
                  <AdminDashboardEllipsisBody ellipsis={ellipsis} />
                </Accordion.Collapse>
              </Accordion>
            )}
          </Popover.Content>
        </Popover>
      }
    >
      <div className="px-2">
        <IconButton
          variant="secondary"
          iconElement={<Icon name="ellipsis-v" size="medium" style={{ marginTop: "-3px" }} />}
          onClick={() => {
            if (openPopovers === ellipsis.id) {
              setOpenPopovers("");
            } else {
              setOpenPopovers(ellipsis.id);
            }
          }}
          className="p-0 w-100 h-100"
        />
      </div>
    </OverlayTrigger>
  );
};
