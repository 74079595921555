import { ProductGroup, ProductGroupStatus } from "../../types";
import { getEditPath, getGroupStatus } from "../../utils/productGroupUtils";

import { DataHubButton } from "../DataHubButton";
import React from "react";
import { dayMonthYearFormat } from "../../utils/format";
import moment from "moment";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledCardContainer = styled.div`
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.33);
  text-align: left;
`;

const StyledGroupDetail = styled.div`
  border-right: 2px solid var(--color-gray-400);
  font-size: 14px;
  &:last-child {
    border-right: 0;
  }
`;

type StyledStatusTagProps = {
  status: ProductGroupStatus;
};

const getTagColors = ({ status }: StyledStatusTagProps) => {
  let bgColor,
    color = "";
  if (status === "open") {
    bgColor = "dark-blue";
    color = "white";
  } else if (status === "edit") {
    bgColor = "black";
    color = "white";
  } else if (status === "published") {
    bgColor = "gray-200";
    color = "black";
  }
  return `background-color: var(--color-${bgColor});
  color: var(--color-${color});`;
};

const StyledStatusTag = styled.div<StyledStatusTagProps>`
  ${getTagColors}
  padding: 0px 5px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-light);
`;

type GroupListingCardProps = {
  rootPagePath: string;
  group: ProductGroup;
};

const GroupListingCard: React.FunctionComponent<GroupListingCardProps> = ({
  rootPagePath,
  group,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const editPath = getEditPath(rootPagePath, group.id);

  const status = getGroupStatus(group);

  const getStatusText = () => {
    if (status === "open") {
      return t("groupedProducts.groupStatusOpen");
    } else if (status === "edit") {
      return t("groupedProducts.groupStatusEdit");
    } else if (status === "published") {
      return t("groupedProducts.groupStatusLocked");
    }
    return "unknown";
  };

  return (
    <StyledCardContainer className="bg-white border-gray-400 p-4 mb-4 w-100">
      <div className="d-flex justify-content-between">
        <DataHubButton
          variant="ghost-pink"
          className="p-0"
          onClick={() => {
            history.push(editPath);
          }}
        >
          {group.name}
        </DataHubButton>
      </div>
      <div className="d-flex color-gray-600 mb-2">
        {/* <StyledGroupDetail className="pr-2">{group.company}</StyledGroupDetail> */}
        <StyledGroupDetail className="pr-2">
          {t("groupedProducts.createdAt")}: {moment(group.createdAt).format(dayMonthYearFormat)}
        </StyledGroupDetail>
        <StyledGroupDetail className="pr-2 ml-2">
          {t("groupedProducts.productsAmount", {
            amount: group.productsAggregate?.aggregate.count ?? 0,
          })}
        </StyledGroupDetail>
      </div>
      <div className="mb-3">{group.description}</div>
      <div className="d-flex justify-content-end">
        <StyledStatusTag className="d-flex" status={status}>
          {getStatusText()}
        </StyledStatusTag>
      </div>
    </StyledCardContainer>
  );
};

export default GroupListingCard;
