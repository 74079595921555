import { Form } from "react-bootstrap";
import InputContainer from "./InputContainer";
import React from "react";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DatahubSelect } from "../DatahubSelect";

const DurationInputContainer = styled.div`
  max-width: 100px;
  min-width: 92px;
`;

const CapacitySeparator = styled.div`
  align-self: flex-end;
  padding: 0 1rem 0.6rem 1rem;
`;

const minutesOptions = [
  { label: "0", value: 0 },
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  { label: "20", value: 20 },
  { label: "25", value: 25 },
  { label: "30", value: 30 },
  { label: "35", value: 35 },
  { label: "40", value: 40 },
  { label: "45", value: 45 },
  { label: "50", value: 50 },
  { label: "55", value: 55 },
];

const hoursOptions = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
];

const daysOptions = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
];

const weeksOption = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
];

const ProductDurationSection = ({ disabled = false }: { disabled?: boolean }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-4">
      <Form.Label>{t("productInfo.duration")}</Form.Label>
      <InputContainer>
        <div className="d-flex align-items-end my-3">
          <DurationInputContainer>
            <Form.Label>{t("productInfo.durationHours")}</Form.Label>
            <Controller
              name="hours"
              defaultValue={hoursOptions[0]}
              render={({ onChange, onBlur, value }) => (
                <div style={{ width: "92px" }}>
                  <DatahubSelect
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    isMulti={false}
                    options={hoursOptions}
                    isDisabled={disabled}
                  />
                </div>
              )}
            />
          </DurationInputContainer>
          <CapacitySeparator>&#58;</CapacitySeparator>
          <DurationInputContainer>
            <Form.Label>{t("productInfo.durationMinutes")}</Form.Label>
            <Controller
              name="minutes"
              defaultValue={minutesOptions[0]}
              render={({ onChange, onBlur, value }) => (
                <div style={{ width: "92px" }}>
                  <DatahubSelect
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    isMulti={false}
                    options={minutesOptions}
                    isDisabled={disabled}
                  />
                </div>
              )}
            />
          </DurationInputContainer>
        </div>
        <div className="d-flex align-items-end my-3">
          <DurationInputContainer>
            <Form.Label>{t("productInfo.durationDays")}</Form.Label>
            <Controller
              name="days"
              defaultValue={daysOptions[0]}
              render={({ onChange, onBlur, value }) => (
                <div style={{ width: "92px" }}>
                  <DatahubSelect
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    isMulti={false}
                    options={daysOptions}
                    isDisabled={disabled}
                  />
                </div>
              )}
            />
          </DurationInputContainer>
        </div>
        <div className="d-flex align-items-end my-3">
          <DurationInputContainer>
            <Form.Label>{t("productInfo.durationWeeks")}</Form.Label>
            <Controller
              name="weeks"
              defaultValue={weeksOption[0]}
              render={({ onChange, onBlur, value }) => (
                <div style={{ width: "92px" }}>
                  <DatahubSelect
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    isMulti={false}
                    options={weeksOption}
                    isDisabled={disabled}
                  />
                </div>
              )}
            />
          </DurationInputContainer>
        </div>
      </InputContainer>
    </div>
  );
};

export default ProductDurationSection;
