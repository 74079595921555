import {
  BokunJohkuInsertResponse,
  ExternalAccount,
  ExternalVendorId,
  ImportSteps,
  ProductsToImportType,
  UserRole,
} from "../../types";
import { Button, Modal, Spinner } from "react-bootstrap";
import { ExternalProduct, ExternalProductTexts } from "../../utils/productFormUtils/productUtils";
import { FormProvider, useForm } from "react-hook-form";
import {
  GetBokunJohkuCompanyResponse,
  getBokunJohkuCompany,
} from "../../graphqlQueries/getBokunJohkuCompany";
import {
  InsertBokunJohkuImportResponse,
  InsertBokunJohkuImportVariables,
  insertExternalProduct,
} from "../../graphqlQueries/insertExternalProduct";
import React, { useEffect, useRef, useState } from "react";

import ConfirmExternalAccountPanel from "./ConfirmExternalAccountPanel";
import ConnectToExternalProductSourcePanel from "./ConnectToExternalProductSourcePanel";
import ImportProductPanel from "./ImportProductPanel";
import StepIndicator from "../StepIndicator";
import UploadProductsPanel from "./UploadProductsPanel";
import { getHasuraRoleContext } from "../../utils/functions";
import { importProductButtonTexts } from "../../utils/localizationUtils";
import { useApolloClient } from "@apollo/client";
import { useCompanyId } from "../hooks/useCompanyId";
import { useTranslation } from "react-i18next";

type ImportProductWizardProps = {
  showCloseButton: boolean;
  onBackButtonPress: () => void;
  onHide: () => void;
  uploading: (status: boolean) => boolean;
  importingFrom: ExternalProduct;
};

const defaultCompany = { vendorId: "", companyName: "" };

const ImportProductWizard = ({
  showCloseButton,
  onBackButtonPress,
  onHide,
  uploading,
  importingFrom,
}: ImportProductWizardProps) => {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const importSteps = Object.values(ImportSteps);
  // Dont count upload phase as a step
  const importStepsLength = importSteps.filter((it) => it !== ImportSteps.Upload).length;
  const [step, setStep] = useState<ImportSteps>(ImportSteps.Connect);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const currentIndex = importSteps.findIndex((importStep) => importStep === step);
  const [externalAccount, setExternalAccount] = useState<ExternalAccount>(defaultCompany);
  const [vendorId, setVendorId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [productsToImport, setProductsToImport] = useState<ProductsToImportType[]>([]);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const [uploadedProducts, setUploadedProducts] = useState<BokunJohkuInsertResponse[]>([]);
  const [uploadErrorProducts, setUploadErrorProducts] = useState<string[]>([]);

  const [companyId] = useCompanyId();

  const hookFormMethods = useForm();
  const { errors, setError, clearErrors } = hookFormMethods;
  const abortControllerRef = useRef(new AbortController());

  const clearState = () => {
    setExternalAccount(defaultCompany);
    setProductsToImport([]);
    setVendorId("");
  };

  const externalProductTexts: ExternalProductTexts =
    importingFrom === ExternalProduct.Bokun
      ? {
          importFromHeader: "importProduct.importFromBokunHeader",
          importFromTitle: "importProduct.importFromBokunFormTitle",
          importFromDescription: "importProduct.importFromBokunFormDescription",
          importFromLabel: "importProduct.importFromBokunInputLabel",
          importFromPlaceHolder: "importProduct.importFromBokunInputPlaceholder",
          getAccountError: "importProduct.getBokunAccountError",
          accountIdLabel: "importProduct.bokunAccountId",
        }
      : {
          importFromHeader: "importProduct.importFromJohkuHeader",
          importFromTitle: "importProduct.importFromJohkuFormTitle",
          importFromDescription: "importProduct.importFromJohkuFormDescription",
          importFromLabel: "importProduct.importFromJohkuInputLabel",
          importFromPlaceHolder: "importProduct.importFromJohkuInputPlaceholder",
          getAccountError: "importProduct.getJohkuAccountError",
          accountIdLabel: "importProduct.johkuAccountId",
        };

  const uploadOngoing =
    uploadedProducts.length + uploadErrorProducts.length < productsToImport.length;

  const doProductUpload = async (products: ProductsToImportType[]) => {
    uploading(true);
    for (const product of products) {
      try {
        const { data } = await apolloClient.mutate<
          InsertBokunJohkuImportResponse,
          InsertBokunJohkuImportVariables
        >({
          mutation: insertExternalProduct,
          variables: {
            productId: product.productId,
            companyId: companyId ?? "",
          },
          context: {
            ...getHasuraRoleContext(UserRole.ManageProducts),
            fetchOptions: {
              signal: abortControllerRef.current.signal,
            },
          },
          fetchPolicy: "network-only",
        });

        setUploadedProducts((current) => [
          ...current,
          { productId: product.productId, errors: data?.InsertBokunJohkuProduct.errors || 0 },
        ]);
      } catch (e) {
        setUploadErrorProducts((current) => [...current, product.productId]);
        console.error(e);
      }
    }
    uploading(false);
  };

  const nextStep = async () => {
    setLoading(true);
    if (step === ImportSteps.Connect) {
      try {
        const { data } = await apolloClient.query<GetBokunJohkuCompanyResponse, ExternalVendorId>({
          query: getBokunJohkuCompany,
          variables: {
            id: vendorId,
          },
          context: getHasuraRoleContext(UserRole.ManageProducts),
          fetchPolicy: "network-only",
        });
        setExternalAccount({
          vendorId: data.GetBokunJohkuCompany.vendorId,
          companyName: data.GetBokunJohkuCompany.name,
        });
      } catch (e) {
        clearState();
        setError("vendorId", { message: t(externalProductTexts.getAccountError) });
        setLoading(false);
        return;
      }
    }

    if (step === ImportSteps.Import) {
      setActiveIndex((state) => state + 1);
      setStep(importSteps[currentIndex + 1]);
      await doProductUpload(productsToImport);
      return;
    }
    setLoading(false);
    setActiveIndex((state) => state + 1);
    setStep(importSteps[currentIndex + 1]);
  };

  const prevStep = () => {
    setActiveIndex((state) => state - 1);
    if (step === ImportSteps.Connect) {
      onBackButtonPress();
    } else {
      setStep(importSteps[currentIndex - 1]);
    }
  };

  useEffect(() => {
    if (step === ImportSteps.Connect && (errors.vendorId || !vendorId)) {
      setNextDisabled(true);
    } else if (step !== ImportSteps.Import) {
      setNextDisabled(false);
    }
  }, [step, errors.vendorId, vendorId]);

  const ButtonHolder = () => {
    if (step !== ImportSteps.Upload) {
      return (
        <>
          <Button className="btn-secondary mr-2 btn-wide" onClick={() => prevStep()}>
            {t("common.back")}
          </Button>
          <Button
            className="btn-primary btn-wide btn-fit-content"
            disabled={nextDisabled}
            onClick={() => nextStep()}
          >
            {loading ? (
              <Spinner size="sm" animation="border" role="status" />
            ) : (
              <>{t(importProductButtonTexts(step))}</>
            )}
          </Button>
        </>
      );
    }

    return (
      <>
        {uploadOngoing && (
          <Button
            className="btn-secondary btn-wide mr-1"
            onClick={() => {
              abortControllerRef.current.abort();
              onHide();
              clearState();
            }}
          >
            {t("importProduct.cancelUpload")}
          </Button>
        )}
        <Button
          className={`btn-primary btn-fit-content btn-wide mr-2`}
          disabled={uploadOngoing}
          onClick={() => {
            clearState();
            onHide();
          }}
        >
          {uploadOngoing ? (
            <>
              <Spinner className="mb-1" size="sm" animation="border" role="status" />
              <span className="ml-2">{t("importProduct.uploadingProducts")}</span>
            </>
          ) : (
            <>{t("common.done")}</>
          )}
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal.Header closeButton={step === ImportSteps.Upload ? false : showCloseButton}>
        <h3 className="m-0 ml-2 p-0">{t(externalProductTexts.importFromHeader)}</h3>
      </Modal.Header>
      <Modal.Body className="pt-3 px-3 px-sm-5" style={{ minHeight: "430px" }}>
        <div className="d-flex flex-row justify-content-center">
          {step !== ImportSteps.Upload && (
            <StepIndicator stepAmount={importStepsLength} activeIndex={activeIndex} size={18} />
          )}
        </div>

        {step === ImportSteps.Connect && (
          <FormProvider {...hookFormMethods}>
            <ConnectToExternalProductSourcePanel
              setExternalCompany={(externalVendorId) => {
                clearErrors("vendorId");
                setVendorId(externalVendorId);
              }}
              importingFrom={importingFrom}
              externalProductTexts={externalProductTexts}
            />
          </FormProvider>
        )}
        {step === ImportSteps.Confirm && (
          <ConfirmExternalAccountPanel
            externalAccount={externalAccount}
            externalProductTexts={externalProductTexts}
            importingFrom={importingFrom}
          />
        )}
        {step === ImportSteps.Import && externalAccount && (
          <ImportProductPanel
            vendorId={externalAccount.vendorId}
            setNextDisabled={setNextDisabled}
            productsToImport={setProductsToImport}
            importingFrom={importingFrom}
            externalProductTexts={externalProductTexts}
          />
        )}
        {step === ImportSteps.Upload && (
          <UploadProductsPanel
            products={productsToImport}
            uploaded={uploadedProducts}
            failed={uploadErrorProducts}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="pr-5 pb-4 border-top-0">
        <ButtonHolder />
      </Modal.Footer>
    </>
  );
};

export default ImportProductWizard;
