import React from "react";
import { Col, Form } from "react-bootstrap";
import { HintAndCounter } from "../productForm/HintAndCounter";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { capitalize } from "../../utils/functions";

const MAX_TITLE_LENGTH = 200;
const MAX_DESCRIPTION_LENGTH = 200;

type MaintenanceBreakFieldItemProps = {
  lang: "en" | "fi";
  title?: string;
  content?: string;
};

export const MaintenanceBreakFieldItem = ({
  lang,
  title,
  content,
}: MaintenanceBreakFieldItemProps) => {
  const { t } = useTranslation();
  const { register, errors, watch } = useFormContext();

  const titleLang = `title${capitalize(lang)}`;
  const contentLang = `content${capitalize(lang)}`;
  const titleWatch = watch(titleLang);
  const contentWatch = watch(contentLang);

  return (
    <Col xs={12} md={6}>
      <Form.Group controlId={titleLang}>
        <Form.Label>{t(`maintenance.${titleLang}`)}</Form.Label>
        <Form.Control
          type="text"
          name={titleLang}
          className={errors[titleLang] ? "is-invalid" : ""}
          defaultValue={title || t(`maintenance.${titleLang}Placeholder`)}
          ref={register({
            maxLength: {
              value: MAX_TITLE_LENGTH,
              message: t("validationErrors.textTooLong", { max: MAX_TITLE_LENGTH }),
            },
            required: {
              value: !!watch(titleWatch),
              message: t("validationErrors.required"),
            },
          })}
        />
      </Form.Group>
      <Form.Group controlId={contentLang}>
        <Form.Label>{t(`maintenance.${contentLang}`)}</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          name={contentLang}
          className={errors[contentLang] ? "is-invalid" : ""}
          defaultValue={content || t(`maintenance.${contentLang}Placeholder`)}
          ref={register({
            maxLength: {
              value: MAX_TITLE_LENGTH,
              message: t("validationErrors.textTooLong", { max: MAX_DESCRIPTION_LENGTH }),
            },
            required: {
              value: !!watch(contentWatch),
              message: t("validationErrors.required"),
            },
          })}
        />
        <HintAndCounter
          hintText=""
          maxLength={MAX_DESCRIPTION_LENGTH}
          currentLength={contentWatch?.length ?? 0}
          error={errors[contentLang]}
        />
      </Form.Group>
    </Col>
  );
};
