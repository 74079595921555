import { Trans, useTranslation } from "react-i18next";

import { Badge } from "react-bootstrap";
import Icon from "../Icon";
import React from "react";
import { breakpointPixels } from "../../css/breakpoints";
import styled from "styled-components";

const DashboardGuideContainer = styled.div`
  background-color: var(--color-white);
  padding: 2rem 2rem 0 2rem;
  margin: 0 2rem 2rem 2rem;
  border: 1px solid var(--color-gray-400);
`;

const DashboardGuideItem = styled.li`
  margin-bottom: 2rem;
`;

const MainContainer = styled.div`
  @media (min-width: ${breakpointPixels.lg}) {
    border-left: 1px solid var(--color-gray-300);
  }
`;

type DashboardProps = {
  isDmo: boolean;
};

const DashboardGuide = ({ isDmo }: DashboardProps) => {
  const { t } = useTranslation();
  return (
    <MainContainer className="col-md-6">
      {isDmo ? (
        <div>
          <DashboardGuideContainer className="m-0 mr-lg-4 ml-lg-4 mb-lg-4">
            <h4 className="mb-3">{t("dashboard.dmoInstructionHeader")}</h4>
            <ol>
              <DashboardGuideItem>
                <Trans i18nKey="dashboard.dmoInstruction1">
                  1. In the ‘Curator workspace’ page you can see products that are added by
                  companies
                  <Badge variant="primary">{t("productCurationStatus.new")}</Badge>
                </Trans>
              </DashboardGuideItem>
              <DashboardGuideItem>{t("dashboard.dmoInstruction2")}</DashboardGuideItem>
              <DashboardGuideItem>
                <Trans i18nKey="dashboard.dmoInstruction3">
                  3. The commented product will wait for the company to update it
                  <Badge variant="dark">{t("productCurationStatus.changesRequested")}</Badge>
                </Trans>
              </DashboardGuideItem>
              <DashboardGuideItem>
                <Trans i18nKey="dashboard.dmoInstruction4">
                  4. When the product is updated
                  <Badge variant="blue">{t("productCurationStatus.updated")}</Badge>
                  <Badge variant="green">{t("curationStatus.approved")}</Badge>
                </Trans>
              </DashboardGuideItem>
              <DashboardGuideItem>
                <Trans i18nKey="dashboard.dmoInstruction5">
                  5. Once published, the companies can update their products at any time, after
                  which the updated products will be shown as
                  <Badge variant="green" className="mr-2">
                    {t("curationStatus.approved")}
                  </Badge>
                  <Badge variant="blue">{t("productCurationStatus.updated")}</Badge>
                </Trans>
              </DashboardGuideItem>
            </ol>
          </DashboardGuideContainer>
        </div>
      ) : (
        <div>
          <DashboardGuideContainer className="m-0 mr-lg-4 ml-lg-4 mb-lg-4">
            <h4 className="mb-3">{t("dashboard.tcInstructionHeader")}</h4>
            <ol>
              <DashboardGuideItem>{t("dashboard.tcInstruction1")}</DashboardGuideItem>
              <DashboardGuideItem>
                <Trans i18nKey="dashboard.tcInstruction2">
                  2. You can save a product as a draft (
                  <span className="color-gray-600">Draft</span>) or send it for curation when all
                  required info is filled
                  <Icon name="check" size="medium" color="green" />
                  <span className="color-green">Published</span>
                </Trans>
              </DashboardGuideItem>
              <DashboardGuideItem>{t("dashboard.tcInstruction3")}</DashboardGuideItem>
              <DashboardGuideItem>
                <Trans i18nKey="dashboard.tcInstruction4">
                  4. You can see the product-specific change requests made by a curator on your
                  product list (<span className="color-primary-dark">NEW</span>)
                </Trans>
              </DashboardGuideItem>
              <DashboardGuideItem>
                <Trans i18nKey="dashboard.tcInstruction5">
                  5. After editing your product based on the curator's suggestions, the product can
                  be approved to be published on different channels
                  <Icon name="check-double" size="medium" color="green" />
                  <span className="color-green">Julkaistu & hyväksytty</span>
                </Trans>
              </DashboardGuideItem>
            </ol>
          </DashboardGuideContainer>
        </div>
      )}
    </MainContainer>
  );
};

export default DashboardGuide;
