import React from "react";
import TDHLogo from "../../images/datahub-logo.svg";
import { CDN_STF_LOGO } from "../../utils/constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const TCAdvertisements: React.FunctionComponent = () => {
  return (
    <div className="row no-gutters mt-4">
      <div className="col-xs-12 col-md-6 pr-0 pr-md-4 mb-4 mb-md-0">
        <Advertisement
          title={"faqTitle"}
          content={"faqContent"}
          linkText={"faqLink"}
          linkUrl={"faqLinkUrl"}
          gradient={"#fcbdd5 0%, #fccfa9 100%"}
        >
          <img src={TDHLogo} alt={""} width={100} />
        </Advertisement>
      </div>
      <div className={"col-xs-12 col-md-6"}>
        <Advertisement
          title={"STFTitle"}
          content={"STFContent"}
          linkText={"STFLink"}
          linkUrl={"STFLinkUrl"}
          gradient={"#89c59a 0%, #c9e9e2 100%"}
        >
          <img src={CDN_STF_LOGO} alt={""} width={150} style={{ margin: "-17px -14px" }} />
        </Advertisement>
      </div>
    </div>
  );
};

export const Advertisement: React.FunctionComponent<{
  title: string;
  content: string;
  linkText: string;
  linkUrl: string;
  gradient: string;
}> = ({ title, content, linkText, linkUrl, gradient, children }) => {
  const { t } = useTranslation();
  const linkTo = t(`dashboard.${linkUrl}`) ?? "";
  return (
    <div className="dashboard-card h-100 d-flex flex-column">
      <div
        className="w-100"
        style={{ height: 25, background: `linear-gradient(50deg, ${gradient}` }}
      />
      <div className={"px-4 d-flex flex-column h-100 pb-4"}>
        <div className={"pt-2 pb-4"} style={{ height: 70 }}>
          {children}
        </div>
        <div className="d-flex flex-column h-100 justify-content-between">
          <p className="font-heavy">{t(`dashboard.${title}`)}</p>
          <p className="d-none d-sm-block">{t(`dashboard.${content}`)}</p>
          <Link to={linkTo}>{t(`dashboard.${linkText}`)}</Link>
        </div>
      </div>
    </div>
  );
};
