import { gql } from "@apollo/client";

import { IdResult } from "../types";

export const lockProductGroupMutation = gql`
  mutation LockProductGroup($id: uuid!, $published: boolean) {
    updateProductGroupByPk(
      pk_columns: { id: $id }
      _set: { published: true, publishedAt: "now()" }
    ) {
      id
      published
      publishedAt
    }
  }
`;

export type LockProductGroupResult = {
  insertProductGroupOne: IdResult;
};

export type LockProductGroupVariables = {
  id: string;
};
