import React from "react";

import Icon, { IconName } from "../Icon";

type AccordionContentProps = {
  iconName?: IconName;
  children: React.ReactNode;
  customStyle?: string;
};

const AccordionContent = ({ iconName, children, customStyle }: AccordionContentProps) => {
  return (
    <div className={customStyle || "d-flex p-4"}>
      {iconName && <Icon name={iconName} size="medium" className="mr-2" />}
      <div>{children}</div>
    </div>
  );
};

export default AccordionContent;
