import styled from "styled-components";

const ProductListItemHeader = styled.h2`
  margin-bottom: 0.25rem;
  color: var(--color-gray-900);
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default ProductListItemHeader;
