import React, { CSSProperties } from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

import { breakpointPixels } from "../css/breakpoints";

const ToggleIcon = styled.div`
  display: inline-block;
  margin-left: 1em;
  vertical-align: 0.225em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  content: "";
`;

type ToggleProps = {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  "aria-expanded"?: boolean;
  border?: boolean;
  dataCy?: string;
};

export const PlainToggle = React.forwardRef<HTMLButtonElement, ToggleProps>(
  ({ children, onClick, "aria-expanded": expanded, border, dataCy }, ref) => {
    return (
      <button
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick && onClick(e);
        }}
        className={`d-flex px-3 align-items-center h-100 ${
          border ? "border" : "border-0"
        } rounded border-dark font-light color-gray-800 ${expanded ? "bg-light" : "bg-white"}`}
        data-cy={dataCy}
      >
        {children}
        <ToggleIcon />
      </button>
    );
  }
);

type DropdownProps = {
  children: React.ReactNode;
  className?: string;
  style?: Record<string, unknown>;
  labeledBy?: string;
  show?: boolean;
};

const MenuContent = styled.div`
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.25);
  width: 100vw;
  @media (min-width: ${breakpointPixels.md}) {
    width: auto;
  }
`;

export const PlainDropdownMenu = React.forwardRef<HTMLDivElement, DropdownProps>(
  ({ children, style, className, labeledBy }, ref) => {
    return (
      <MenuContent ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        {children}
      </MenuContent>
    );
  }
);

type DropdownItem = {
  text: string;
  value: string;
};

type PlainDropdownProps = {
  selected?: DropdownItem;
  items: DropdownItem[];
  onChange?: (item: DropdownItem) => void;
  style?: CSSProperties;
  toggleBorder?: boolean;
  dataCy?: string;
};

export const DropdownContainer = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
`;

export const DropdownContent = styled.div`
  padding: 0.75rem 0;
  font-family: var(--font-light);
`;

const PlainDropdown = ({
  selected,
  items,
  onChange,
  toggleBorder,
  dataCy,
  style = {},
}: PlainDropdownProps) => {
  return (
    <Dropdown as={DropdownContainer} style={style}>
      <Dropdown.Toggle as={PlainToggle} border={toggleBorder} dataCy={dataCy}>
        {selected && selected.text}
      </Dropdown.Toggle>
      <Dropdown.Menu as={PlainDropdownMenu} popperConfig={{ strategy: "fixed" }}>
        {items.map((item) => (
          <Dropdown.Item
            key={item.value}
            onClick={() => {
              onChange && onChange(item);
            }}
          >
            <DropdownContent>{item.text}</DropdownContent>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PlainDropdown;
