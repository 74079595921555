import { default as c } from "classnames";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { OpeningHoursDay } from "../../types";
import { dayMonthFormat } from "../../utils/format";
import { BasicModal } from "../DataHubModal";
import { OpeningHoursInput } from "./OpeningHoursInput";

const WeekDayButton = styled(Button)`
  &.btn-primary {
    line-height: 1;
    font-family: var(--font-light);
    font-weight: 400;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 1%;
    background-color: var(--white);
    border: 1px solid var(--color-gray-600);
    color: var(--color-gray-600);

    &.selected {
      background-color: var(--color-primary-dark);
      border: 1px solid var(--color-primary-extra-dark);
      color: var(--color-white);
    }

    &:hover {
      background-color: var(--color-gray-100);
      border: 1px solid var(--color-gray-700);
      color: var(--color-gray-700);
    }
  }
`;

const PeriodContainer = styled.div`
  display: flex;
  flex-basis: 3rem;
  justify-content: center;
  align-items: center;
`;

type OpeningHoursModalProps = {
  onHide: () => void;
  weekdays: OpeningHoursDay[];
  openingHoursInputName: string;
};

export const OpeningHoursModal: React.FunctionComponent<OpeningHoursModalProps> = ({
  onHide,
  weekdays,
  openingHoursInputName,
}) => {
  const { t } = useTranslation();
  const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([]);
  const { setValue, watch } = useFormContext();
  const watchMultiple = watch(`${openingHoursInputName}.multiple`);
  const opens = watchMultiple?.opens;
  const closes = watchMultiple?.closes;
  const closed = watchMultiple?.closed;

  const closeModal = () => {
    setSelectedWeekdays([]);
    onHide();
  };

  const getPeriod = () => {
    const firstDate = weekdays[0]?.date;
    const lastDate = weekdays[weekdays.length - 1]?.date;

    if (!firstDate || !lastDate) {
      return "";
    }

    return `${firstDate.format(dayMonthFormat)} - ${lastDate.format(dayMonthFormat)}`;
  };

  const handleWeekDayClick = (weekday: string) => {
    const isSelected = selectedWeekdays.find((o) => o === weekday);
    const newWeekdayArr = isSelected
      ? [...selectedWeekdays.filter((o) => o !== weekday)]
      : [...selectedWeekdays, weekday];

    setSelectedWeekdays(newWeekdayArr);
  };

  const setOpeningTimes = () => {
    selectedWeekdays.forEach((weekday) => {
      setValue(`${openingHoursInputName}.${weekday}.opens`, opens);
      setValue(`${openingHoursInputName}.${weekday}.closed`, closed);
      setValue(`${openingHoursInputName}.${weekday}.closes`, closes);
    });
    closeModal();
  };

  return (
    <BasicModal
      centered={true}
      header={t("productInfo.chooseDaysAndTime")}
      cancelText={t("productInfo.cancel")}
      proceedText={t("productInfo.update")}
      onProceed={setOpeningTimes}
      onCancel={closeModal}
    >
      <div className="d-flex flex-column align-items-stretch">
        <div className="d-flex justify-content-center">
          {weekdays.map(({ labelKey, weekday }) => (
            <WeekDayButton
              key={labelKey}
              className={c("mr-3", {
                selected: selectedWeekdays.find((o) => o === weekday),
              })}
              onClick={() => handleWeekDayClick(weekday)}
            >
              {t(`openingHours.${weekday}.short`)}
            </WeekDayButton>
          ))}
        </div>
        <PeriodContainer>{getPeriod()}</PeriodContainer>
        <div className="d-flex bg-light">
          <OpeningHoursInput inputName={`${openingHoursInputName}.multiple`} align="center" />
        </div>
      </div>
    </BasicModal>
  );
};
