import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import {
  ChangeCurationAndComment,
  getAddCommentMutation,
  InsertCommentResult,
} from "../../graphqlQueries/addComment";
import { CurationStatus, DatahubType, ProductCurationStatus, UserRole } from "../../types";
import { HasuradbProduct, ProductTag } from "../../graphqlQueries/getProduct";
import {
  getMainCategories,
  MainCategoryQueryResult,
  MainCategoryQueryVariables,
} from "../../graphqlQueries/getMainCategories";
import React, { useState } from "react";
import RemoveApprovalModal, {
  RemoveApprovalFormValues,
  RemoveApprovalType,
} from "./RemoveApprovalModal";
import { capitalize, getHasuraRoleContext } from "../../utils/functions";
import removeCurationMutation, {
  RemoveCurationAndCommentVariables,
} from "../../graphqlQueries/removeCuration";
import updateProductCurationByPk, {
  UpdateProductCurationByPkResult,
  UpdateProductCurationByPkVariables,
} from "../../graphqlQueries/updateProductCurationByPk";
import { DocumentNode, useMutation, useQuery } from "@apollo/client";

import Icon from "../Icon";
import IconButton from "../IconButton";
import LastEdit from "../LastEdit";
import Notification from "../Notification";
import { RECOMMENDED_CATEGORY_AMOUNT } from "../../utils/constants";
import RejectProductModal, { RejectProductFormValues } from "./RejectProductModal";
import SmallMobileMenu from "../SmallMobileMenu";
import SubmitSpinner from "../SubmitSpinner";
import { getProductCommentsDmo } from "../../graphqlQueries/getProductComments";
import { hasBrokenLink } from "../../utils/productFormUtils/productUtils";
import moment from "moment";
import { useCommentsCount } from "../hooks/useCommentsCount";
import { useHistory } from "react-router-dom";
import { useIsSmallScreen } from "../MediaQuery";
import { useTranslation } from "react-i18next";
import { userInfoVar } from "../../utils/ApolloCache";
import styled from "styled-components";
import ExternalLink from "../ExternalLink";

const CuratorToolbar = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
`;

type ViewProductCuratorToolbarProps = {
  product: HasuradbProduct;
  showComments: boolean;
  setShowComments: (show: boolean) => void;
  productsQuery: DocumentNode;
  companyId: string;
};

type CuratorUpdateStatusResult = "ok" | "error";

type NotificationData = {
  resultStatus: CuratorUpdateStatusResult;
  messageKey: string;
};

const ViewProductCuratorToolbar = ({
  product,
  showComments,
  setShowComments,
  productsQuery,
  companyId,
}: ViewProductCuratorToolbarProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [productReturnedToDraft, setProductReturnedToDraft] = useState<boolean>(false);

  const [notifications, setNotifications] = useState<NotificationData[]>([]);

  const addNotification = (resultStatus: CuratorUpdateStatusResult, messageKey: string) => {
    setNotifications((notifs) => [...notifs, { resultStatus, messageKey }]);
  };

  const { data: mainCategories } = useQuery<MainCategoryQueryResult, MainCategoryQueryVariables>(
    getMainCategories,
    {
      variables: { productType: product.type },
      context: getHasuraRoleContext(UserRole.ManageCuration),
    }
  );

  const [UpdateProductCurationByPk, { loading: isUpdatingStatus }] = useMutation<
    UpdateProductCurationByPkResult,
    UpdateProductCurationByPkVariables
  >(updateProductCurationByPk);

  const [removeCurationAndComment] = useMutation<
    InsertCommentResult,
    RemoveCurationAndCommentVariables
  >(removeCurationMutation);

  const [changeProductCurationAndComment] = useMutation<
    InsertCommentResult,
    ChangeCurationAndComment
  >(getAddCommentMutation(true));

  const { data: commentsCountData } = useCommentsCount({
    productId: product.id,
    userRole: UserRole.ManageCuration,
  });

  const { commentsCount = 0, newCommentsCount = 0 } = commentsCountData;

  const productCuration = product.productCurations?.[0];

  const curationStatus = productCuration?.curationStatus;
  const productCurationStatus = productCuration?.productCurationStatus;

  const approvedAndUpdated =
    curationStatus === CurationStatus.Approved &&
    productCurationStatus === ProductCurationStatus.Updated;

  const resetNotifications = () => {
    setNotifications([]);
  };

  const showButtons = product.curationEnabled;

  const showMobileMenuButton = useIsSmallScreen();

  const brokenLink = hasBrokenLink(product);

  const isInMainCategory = (
    oneTag: ProductTag,
    mainCategories: MainCategoryQueryResult | undefined
  ) => {
    if (!mainCategories) {
      return false;
    }

    const mainCategoryTags = mainCategories.productTypeMainCategory.flatMap(
      (cat) => cat.categoryGroup.tags
    );

    return mainCategoryTags.find(({ tag }) => tag === oneTag.tag);
  };

  const hasOtherCategories: boolean =
    product.productTags.filter((tag) => !isInMainCategory(tag, mainCategories)).length > 0;

  const SecondaryActionButtons = (
    <>
      <Button
        className="mr-3 mb-3"
        variant="secondary"
        onClick={() => history.push(`/company/${companyId}/curator-workspace/edit/${product.id}`)}
      >
        {t("viewProduct.editProductButton")}
      </Button>

      {curationStatus !== CurationStatus.Approved && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="reject-tooltip">{t("viewProduct.rejectProductTooltip")}</Tooltip>}
        >
          <Button
            className="mr-3 mb-3"
            variant="secondary"
            onClick={() => {
              resetNotifications();
              setShowRejectModal(true);
            }}
          >
            {t("viewProduct.rejectProductButton")}
          </Button>
        </OverlayTrigger>
      )}
    </>
  );

  const ApproveButton = (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="approve-tooltip">{t("viewProduct.approveProductTooltip")}</Tooltip>}
    >
      <IconButton
        className="mb-3"
        variant="primary"
        disabled={isUpdatingStatus}
        iconElement={<Spinner size="sm" animation="border" className="mr-2 mt-1" />}
        hideIcon={!isUpdatingStatus}
        onClick={() => {
          resetNotifications();
          UpdateProductCurationByPk({
            variables: {
              productId: product.id,
              curationApproved: moment().toISOString(),
              curatorCompanyId: companyId,
            },
            context: getHasuraRoleContext(UserRole.ManageCuration),
            refetchQueries: ["getProductDmo", productsQuery],
          })
            .then(() => {
              addNotification("ok", "viewProduct.approveResultOk");
            })
            .catch(() => {
              addNotification("error", "viewProduct.approveResultError");
            });
        }}
      >
        {t("viewProduct.approveProductButton")}
      </IconButton>
    </OverlayTrigger>
  );

  const RemoveApprovalButton = (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="comments-tooltip">{t("viewProduct.removeApprovalTooltip")}</Tooltip>}
    >
      <IconButton
        className="mb-3"
        variant="light"
        iconElement={<Icon name="times" size="medium" color="primary-dark" className="mr-2" />}
        onClick={() => {
          resetNotifications();
          setShowRemoveModal(true);
        }}
      >
        {t("viewProduct.removeApprovalProductButton")}
      </IconButton>
    </OverlayTrigger>
  );

  return (
    <CuratorToolbar className="bg-white border-gray-bottom">
      {brokenLink && (
        <Notification type="info-alt">
          <p className="m-0">{t("viewProduct.productHasBrokenLinkDmo")}</p>
        </Notification>
      )}
      {!!(curationStatus !== CurationStatus.Approved && product.externalSource) && (
        <Notification type="info">
          <div>
            <p className="m-0">
              {t("viewProduct.productIsImported", {
                externalSource: capitalize(product.externalSource),
              })}
            </p>
            <ExternalLink href={t(`viewProduct.checklist_${product.externalSource}`)}>
              {t("viewProduct.checklist")}
            </ExternalLink>
          </div>
        </Notification>
      )}
      {curationStatus !== CurationStatus.Approved &&
        (hasOtherCategories || product.productTags.length > RECOMMENDED_CATEGORY_AMOUNT) && (
          <Notification type="info">
            <p className="m-0">{t("viewProduct.productHasOtherCategories")}</p>
          </Notification>
        )}
      {approvedAndUpdated && (
        <Notification type="info">
          <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
            <div className="my-1">{t("viewProduct.approveChangesInfo")}</div>
            <IconButton
              variant="light"
              className="text-small py-1"
              disabled={isUpdatingStatus}
              hideIcon={!isUpdatingStatus}
              iconElement={<SubmitSpinner />}
              onClick={() => {
                UpdateProductCurationByPk({
                  variables: {
                    productId: product.id,
                    curationApproved: moment().toISOString(),
                    curatorCompanyId: companyId,
                  },
                  context: getHasuraRoleContext(UserRole.ManageCuration),
                  refetchQueries: ["getProductDmo", productsQuery],
                })
                  .then(() => {
                    addNotification("ok", "viewProduct.approveChangesResultOk");
                  })
                  .catch(() => {
                    addNotification("error", "viewProduct.approveChangesResultError");
                  });
              }}
            >
              {t("viewProduct.approveChangesButton")}
            </IconButton>
          </div>
        </Notification>
      )}
      {notifications.map((data) => (
        <Notification
          key={data.messageKey}
          type={data.resultStatus === "ok" ? "success" : "danger"}
          onClose={() => setNotifications((value) => value.filter((val) => val !== data))}
        >
          <div className="my-1">{t(data.messageKey)}</div>
        </Notification>
      ))}
      <div className="px-3 pb-3 mt-3">
        {!productReturnedToDraft && (
          <div className="d-flex justify-content-between flex-wrap-reverse">
            <div className="d-flex flex-grow-1 flex-row-reverse flex-nowrap flex-sm-wrap">
              {showButtons && (
                <>
                  {showMobileMenuButton && (
                    <SmallMobileMenu className="p-2 ml-1 mb-3 ml-sm-2">
                      <div className="mb-n3">{SecondaryActionButtons}</div>
                    </SmallMobileMenu>
                  )}
                  {curationStatus === CurationStatus.Pending ? ApproveButton : RemoveApprovalButton}
                </>
              )}

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="comments-tooltip">{t("viewProduct.commentsTooltip")}</Tooltip>
                }
              >
                <IconButton
                  className="mr-1 mb-3 mr-sm-3"
                  variant="light"
                  onClick={() => setShowComments(!showComments)}
                  iconElement={
                    <Icon className="mr-2" name="circle-filled" color="primary" size="x-small" />
                  }
                  hideIcon={newCommentsCount === 0}
                >
                  {showComments ? t("comments.hide") : `${t("comments.show")} (${commentsCount})`}
                </IconButton>
              </OverlayTrigger>

              {!showMobileMenuButton && SecondaryActionButtons}
            </div>
          </div>
        )}
        <LastEdit
          dateStr={product.updatedAt}
          editorName={
            product.updatedByUser?.name === ""
              ? t("userManagement.userNotFound")
              : product.updatedByUser?.name
          }
        />
      </div>
      {showRemoveModal && (
        <RemoveApprovalModal
          onHide={() => setShowRemoveModal(false)}
          onSubmit={(values: RemoveApprovalFormValues) => {
            const removeCurationVariables = {
              productId: product.id,
              commentText: values.removeReason,
              commentRecipientRole: DatahubType.TC,
              companyId,
              userName: userInfoVar()?.fullName ?? "",
            };

            const removeCurationOptions = {
              variables: removeCurationVariables,
              context: getHasuraRoleContext(UserRole.ManageCuration),
              refetchQueries: [getProductCommentsDmo],
            };

            const curationStatusVars = {
              productCurationStatus: ProductCurationStatus.ChangesRequested,
              curationStatus: CurationStatus.Pending,
            };

            const removeApprovalMutation =
              values.removeType === RemoveApprovalType.RemoveFromChannels
                ? changeProductCurationAndComment({
                    ...removeCurationOptions,
                    variables: { ...removeCurationVariables, ...curationStatusVars },
                  })
                : removeCurationAndComment(removeCurationOptions);

            return removeApprovalMutation
              .then(() => {
                addNotification("ok", "viewProduct.removeApprovalResultOk");
                if (values.removeType === RemoveApprovalType.RemoveCurating) {
                  setProductReturnedToDraft(true);
                }
              })
              .catch(() => {
                addNotification("error", "viewProduct.removeApprovalResultError");
              })
              .finally(() => {
                setShowRemoveModal(false);
              });
          }}
        />
      )}
      {showRejectModal && (
        <RejectProductModal
          onHide={() => setShowRejectModal(false)}
          onSubmit={(values: RejectProductFormValues) => {
            const removeCurationVariables = {
              productId: product.id,
              commentText: values.removeReason,
              companyId,
              userName: userInfoVar()?.fullName ?? "",
            };

            removeCurationAndComment({
              variables: removeCurationVariables,
              context: getHasuraRoleContext(UserRole.ManageCuration),
              refetchQueries: [getProductCommentsDmo],
            })
              .then(() => {
                addNotification("ok", t("viewProduct.rejectProductResultOk"));
                setProductReturnedToDraft(true);
              })
              .catch(() => {
                addNotification("error", t("viewProduct.rejectProductResultError"));
              })
              .finally(() => {
                setShowRejectModal(false);
              });
          }}
        />
      )}
    </CuratorToolbar>
  );
};

export default ViewProductCuratorToolbar;
