import React from "react";
import styled from "styled-components";
import { breakpointPixels } from "../../css/breakpoints";

const StepHeaderContainer = styled.header`
  @media (min-width: ${breakpointPixels.sm}) {
    width: 100%;
    background: var(--color-white);
  }
  width: 100vw;
  background: var(--color-gray-100);
  display: flex;
  justify-content: center;
  padding: 1rem;
  color: var(--color-gray-800);
`;

type StepHeaderProps = {
  children: React.ReactNode;
};

const StepHeader = ({ children }: StepHeaderProps) => (
  <StepHeaderContainer>
    <h1 className="h3-size m-0">{children}</h1>
  </StepHeaderContainer>
);

export default StepHeader;
