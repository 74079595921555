import React from "react";
import { Modal } from "react-bootstrap";

type MaintenanceModalProps = {
  title: string;
  time: string;
  content: string;
  onHide: () => void;
};

const MaintenanceModal = ({ title, time, content, onHide }: MaintenanceModalProps) => {
  return (
    <Modal show animation={false} onHide={onHide}>
      <Modal.Header>
        <h6 className="mb-0">
          <p className="mb-1">{title}</p>
          <p className="mb-0">{time}</p>
        </h6>
      </Modal.Header>
      <Modal.Body className="px-4">
        <p className="color-gray-700">{content}</p>
      </Modal.Body>
    </Modal>
  );
};

export default MaintenanceModal;
