import { Controller, useFormContext } from "react-hook-form";
import {
  AvailabilitySeason,
  Month,
  ProductPublishMode,
  ProductType,
  TransportationAvailabilityEnum,
} from "../../types";
import { getRequiredValidator, validateDateRange } from "../../utils/formUtils";
import { getMonthKey } from "../../utils/localizationUtils";
import Checkbox from "../Checkbox";
import DateRange from "../DateRange";
import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";
import React, { useEffect } from "react";
import { BusinessHours } from "./BusinessHours";
import { BusinessHours as BusinessHoursType } from "../../types";
import { Form } from "react-bootstrap";
import InputContainer from "./InputContainer";
import RadioButton from "../RadioButton";
import { useTranslation } from "react-i18next";

const specificMonths = Object.values(Month).map((month) => ({
  value: month,
  label: getMonthKey(month),
}));

type AvailabilitySectionProps = {
  publishMode: ProductPublishMode;
  isProductGroup: boolean;
  isCurator?: boolean;
  productCompanyBusinessHours?: BusinessHoursType | null;
};

const { AllYear, SpecificMonths } = AvailabilitySeason;

const AvailabilitySection = ({
  publishMode,
  isProductGroup,
  isCurator,
  productCompanyBusinessHours,
}: AvailabilitySectionProps) => {
  const { t } = useTranslation();
  const { register, unregister, errors, watch, setValue, getValues, trigger } = useFormContext();
  const showEventDates = watch("productType") === ProductType.Event;
  const eventDateRange = watch("eventDateRange");
  const productType = watch("productType");
  const watchMonths = watch("availabilitySeason");
  const watchTransportationAvailability = watch("transportationAvailability");
  const isDraft = publishMode === "draft";

  useEffect(() => {
    if (!showEventDates) {
      setValue("eventDateRange", undefined);
      unregister("eventDateRange");
    }
    if (!watchTransportationAvailability) {
      setValue("transportationAvailability", TransportationAvailabilityEnum.BusinessHoursOnly);
    }
  }, [setValue, showEventDates, trigger, unregister, watchTransportationAvailability]);

  const clearMonths = () => {
    setValue("specificMonths", [], { shouldValidate: true });
  };

  const validateMonths = (): string | true => {
    const values = getValues();
    const availabilitySeason = values.availabilitySeason;
    const selectedMonths = values.specificMonths;
    if (availabilitySeason === SpecificMonths && (!selectedMonths || selectedMonths.length === 0)) {
      return "productInfo.specificMonthError";
    }
    return true;
  };

  const transportationAvailabilityOptions = Object.values(TransportationAvailabilityEnum);

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader>{t("productInfo.availabilityHeader")}</FormSectionHeader>
        {!isProductGroup && <p>{t("productInfo.availabilityDescription")}</p>}
      </FormDescription>
      <FormSectionContent>
        {productType === ProductType.Transportation && (
          <div className="mb-3">
            <Form.Label className="mb-3">{t("productInfo.productAvailability")}</Form.Label>
            {transportationAvailabilityOptions.map((opt, i) => (
              <RadioButton
                id={`transportationAvailability-${opt}`}
                name="transportationAvailability"
                value={opt}
                label={t(`productInfo.transportationAvailability${i}`)}
                key={`transportationAvailability-${opt}`}
                disabled={isProductGroup}
                ref={register()}
              />
            ))}
          </div>
        )}
        <Form.Label>{t("productInfo.availabilityLabel")} *</Form.Label>
        <InputContainer hasError={!!errors.availabilitySeason || !!errors.specificMonths}>
          <RadioButton
            id="allYear"
            name="availabilitySeason"
            value={AllYear}
            ref={register({
              validate: {
                required: getRequiredValidator(publishMode),
              },
            })}
            onChange={() => clearMonths()}
            label={t("productInfo.availabilityAllYear")}
            disabled={isProductGroup}
          />
          <RadioButton
            id="specificMonths"
            name="availabilitySeason"
            value={SpecificMonths}
            ref={register({
              validate: {
                required: getRequiredValidator(publishMode),
              },
            })}
            label={t("productInfo.availabilitySpecificMonths")}
            disabled={isProductGroup}
          />
          <div className="pl-4 ml-2 d-flex">
            <div className="pr-4 columns-2">
              {specificMonths.map((month, i) => (
                <Checkbox
                  id={month.value}
                  value={month.value}
                  key={i}
                  name="specificMonths"
                  label={t(month.label)}
                  ref={register({
                    validate: validateMonths,
                  })}
                  disabled={watchMonths === AllYear || isProductGroup}
                  onChange={() => {
                    setValue("availabilitySeason", SpecificMonths);
                  }}
                />
              ))}
            </div>
          </div>
        </InputContainer>
        {errors.specificMonths && (
          <p className="text-danger mt-2">{t(errors.specificMonths.message)}</p>
        )}
        {errors.months && <p className="text-danger">{t(errors.months.message)}</p>}
        {showEventDates && (
          <div>
            <Form.Label
              htmlFor="eventDateRange"
              className={`mt-4${errors.eventDateRange ? " is-invalid" : ""}`}
            >
              {t("productInfo.eventStartAndEndDates")} *
            </Form.Label>
            <Controller
              name="eventDateRange"
              defaultValue={{}}
              rules={{
                validate: (values) =>
                  eventDateRange
                    ? validateDateRange(values.startDate, values.endDate, isDraft)
                    : true,
              }}
              render={({ onChange, value }) => (
                <DateRange
                  value={value}
                  id="event"
                  onChange={(startDate, endDate) => onChange({ startDate, endDate })}
                  disabled={isProductGroup}
                />
              )}
            />
            {errors.eventDateRange && (
              <p className="text-danger mt-2">{t(errors.eventDateRange.message)}</p>
            )}
          </div>
        )}
        <BusinessHours
          publishMode={publishMode}
          disabled={isProductGroup}
          isCurator={isCurator}
          productCompanyBusinessHours={productCompanyBusinessHours}
        />
      </FormSectionContent>
    </FormSection>
  );
};

export default AvailabilitySection;
