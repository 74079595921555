import { AggregateCount } from "../types";
import { gql } from "@apollo/client";

export const getDmoProductAggregates = gql`
  query getDmoProductAggregates($dmo_areas: _uuid!) {
    approvedAndUpdated: hasuradb_dmo_products_aggregate(
      args: { dmo_areas: $dmo_areas }
      where: {
        productCurations: {
          curationStatus: { _eq: approved }
          productCurationStatus: { _eq: updated }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    new: hasuradb_dmo_products_aggregate(
      args: { dmo_areas: $dmo_areas }
      where: {
        productCurations: { curationStatus: { _eq: pending }, productCurationStatus: { _eq: new } }
      }
    ) {
      aggregate {
        count
      }
    }
    updated: hasuradb_dmo_products_aggregate(
      args: { dmo_areas: $dmo_areas }
      where: {
        productCurations: {
          curationStatus: { _eq: pending }
          productCurationStatus: { _eq: updated }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    brokenLinks: hasuradb_dmo_products_aggregate(
      args: { dmo_areas: $dmo_areas }
      where: {
        _or: [
          { productInformations: { verifiedProductLink: { invalidAt: { _is_null: false } } } }
          { productInformations: { verifiedWebshopLink: { invalidAt: { _is_null: false } } } }
          { productVideos: { verifiedVideoLink: { invalidAt: { _is_null: false } } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export type GetDmoProductAggregates = {
  approvedAndUpdated: AggregateCount;
  new: AggregateCount;
  updated: AggregateCount;
  brokenLinks: AggregateCount;
};
