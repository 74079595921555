import { AffectedRowsResult, DataHubImage, ProductGroupProductInput } from "../../types";

import { EditProductInformation } from "./updateProductGroup";
import { gql } from "@apollo/client";

export const updateProductGroupProductsMutation = (hasEditedProductImages?: boolean) => {
  return gql`
	  mutation UpdateProductGroupProducts(
	    $id: uuid!
	    $addedProducts: [hasuradb_product_group_product_insert_input!]!
	    $editedProductIds: [uuid!]
      $editedProductDetails: [hasuradb_product_group_product_information_insert_input!]!
      ${
        hasEditedProductImages
          ? `$editedProductImages: [hasuradb_product_group_product_image_insert_input!]!`
          : ``
      }
     $editedProductInformationIds: [uuid!]
	  ) {
      deleteProductGroupProduct(where: { _and: { productGroupId: { _eq: $id }, productId: { _in: $editedProductIds } } }) {
        affected_rows
      }
      insertProductGroupProduct(objects: $addedProducts) {
        affected_rows
      }
      deleteProductGroupProductInformation(
        where: { id: { _in: $editedProductInformationIds } }
        ) {
          affected_rows
        }
      ${
        hasEditedProductImages
          ? `deleteProductGroupProductImage(where: { productGroupId: { _eq: $id } }) {
          affected_rows
        }`
          : ``
      }
        insertProductGroupProductInformation(objects: $editedProductDetails) {
          affected_rows
        }
        ${
          hasEditedProductImages
            ? `insertProductGroupProductImage(objects: $editedProductImages) {
            affected_rows
          }`
            : ``
        }
	  }
	`;
};

export type UpdateProductGroupProductsResult = {
  deleteProductGroupProduct: AffectedRowsResult;
  deleteProductGroupProductImage: AffectedRowsResult;
  deleteProductGroupProductInformation: AffectedRowsResult;
  insertProductGroupProduct: AffectedRowsResult;
  insertProductGroupProductImage: AffectedRowsResult;
  insertProductGroupProductInformation: AffectedRowsResult;
};

export type UpdateProductGroupProductsVariables = {
  id: string;
  editedProductIds?: (string | undefined)[] | undefined;
  addedProducts: ProductGroupProductInput[];
  editedProductDetails?: EditProductInformation[];
  editedProductImages?: DataHubImage[];
  editedProductInformationIds?: (string | undefined)[] | undefined;
};
