import { gql } from "@apollo/client";

export const getBokunJohkuCompany = gql`
  query GetBokunJohkuCompanyQuery($id: String!) {
    GetBokunJohkuCompany(id: $id) {
      name
      vendorId
    }
  }
`;

export type GetBokunJohkuCompanyResponse = {
  GetBokunJohkuCompany: {
    vendorId: string;
    name: string;
  };
};
