import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { OpeningHours } from "../../types";
import { dayMonthFormat, formatTime } from "../../utils/format";
import { getOpeningHoursWeekdayKey } from "../../utils/localizationUtils";

type ViewProductOpeningHourProps = {
  openingHour: OpeningHours;
};

const HoursContainer = styled.div`
  flex-basis: 100px;
  flex-shrink: 0;
`;

const ViewProductOpeningHour = ({ openingHour }: ViewProductOpeningHourProps) => {
  const { t } = useTranslation();
  const { open, opens, closes } = openingHour;
  const closed = !open;

  const getWeekdayText = () => {
    const date = openingHour.date;
    if (date) {
      return `${moment(date).format(dayMonthFormat)} ${t(
        getOpeningHoursWeekdayKey(openingHour.weekday, true)
      )}`;
    }

    return t(getOpeningHoursWeekdayKey(openingHour.weekday));
  };

  return (
    <div className="d-flex">
      <HoursContainer>{getWeekdayText()}</HoursContainer>
      <span className="mr-3">
        {closed ? (
          <>{t("productInfo.openingHoursClosed")}</>
        ) : (
          <>
            {opens && closes ? (
              <>
                <span>{formatTime(opens)}</span>
                <span>&ndash;{formatTime(closes)}</span>
              </>
            ) : (
              <span>&ndash;</span>
            )}
          </>
        )}
      </span>
    </div>
  );
};

export default ViewProductOpeningHour;
