import { DocumentNode, useMutation } from "@apollo/client";
import processJoinRequestQuery, {
  ProcessJoinRequestResult,
  ProcessJoinRequestVariables,
} from "../../graphqlQueries/processJoinRequest";

import { RequestResultStatus } from "./useNotifications";
import { UserRole } from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";
import { useTranslation } from "react-i18next";

type NotificationCallback = (
  resultStatus: RequestResultStatus,
  message: string,
  key?: string | undefined
) => void;

const useProcessJoinRequest = (role: UserRole) => {
  const { t } = useTranslation();

  const [processJoinRequest] = useMutation<ProcessJoinRequestResult, ProcessJoinRequestVariables>(
    processJoinRequestQuery,
    { context: getHasuraRoleContext(role) }
  );

  const approveUser = (
    joinRequestId: string,
    refetchQueries: DocumentNode[],
    notificationCallback: NotificationCallback
  ) => {
    processJoinRequest({
      variables: { id: joinRequestId, approveRequest: true },
      refetchQueries,
    })
      .then(() => notificationCallback("ok", t("userManagement.approveOkMessage"), "approveUser"))
      .catch((error) => {
        console.log(error);
        notificationCallback("error", t("userManagement.approveErrorMessage"));
      });
  };

  const denyUser = (
    joinRequestId: string,
    refetchQueries: DocumentNode[],
    notificationCallback: NotificationCallback
  ) => {
    processJoinRequest({
      variables: { id: joinRequestId, approveRequest: false },
      refetchQueries,
    })
      .then(() => notificationCallback("ok", t("userManagement.denyOkMessage"), "denyUser"))
      .catch((error) => {
        console.log(error);
        notificationCallback("error", t("userManagement.denyErrorMessage"));
      });
  };

  return { processJoinRequest, denyUser, approveUser };
};

export default useProcessJoinRequest;
