import styled from "styled-components";

const ViewProductHeader = styled.h3`
  font-size: 0.9rem;
  color: var(--color-gray-800);
  line-height: 1rem;
  padding-top: 1rem;
`;

export default ViewProductHeader;
