import { ExternalProductPreview } from "../types";
import { gql } from "@apollo/client";

export const getBokunJohkuProductPreview = gql`
  query GetBokunJohkuProductPreviewQuery($id: String!) {
    GetBokunJohkuProductPreview(id: $id) {
      companyId
      id
      imageId
      url
      name
    }
  }
`;

export type GetBokunJohkuProductPreviewResponse = {
  GetBokunJohkuProductPreview: ExternalProductPreview[];
};
