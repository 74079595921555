import styled from "styled-components";
import { breakpointPixels } from "../css/breakpoints";
const thumbnailWidth = 130;

const ListItemTextContainer = styled.div`
  text-align: left;
  width: 300px;
  @media (max-width: ${breakpointPixels.md}) {
    width: calc(100vw - ${thumbnailWidth}px);
  }
  @media (min-width: ${breakpointPixels.lg}) {
    width: 280px;
  }
  @media (min-width: ${breakpointPixels.xl}) {
    width: 300px;
  }
`;

export default ListItemTextContainer;
