import { gql } from "@apollo/client";

import { SocialMediaPlatform } from "../../types";

export const insertSocialMediaLinksMutation = gql`
  mutation InsertSocialMediaLinks(
    $socialMediaId: uuid
    $verifiedLinksToDelete: [uuid!]
    $objects: [hasuradb_social_media_link_insert_input!]!
  ) {
    deleteSocialMediaLinks(where: { socialMediaId: { _eq: $socialMediaId } }) {
      affected_rows
    }
    deleteVerifiedLink(where: { id: { _in: $verifiedLinksToDelete } }) {
      affected_rows
    }
    insertSocialMediaLinks(objects: $objects) {
      affected_rows
    }
  }
`;

type VerifiedLinksInput = {
  url: string;
  userVerifiedAt: string | null;
  userVerifiedBy: string | null;
};

type SocialMediaLinksInsert = {
  companyId: string;
  linkType: SocialMediaPlatform;
  socialMediaId: string;
  verifiedLink: { data: VerifiedLinksInput };
};

export type InsertSocialMediaLinksVars = {
  socialMediaId: string;
  verifiedLinksToDelete: string[];
  objects: SocialMediaLinksInsert[];
};
