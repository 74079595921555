import React, { useEffect, useRef, useState } from "react";
import AccordionContent from "../../components/accordion/AccordionContent";
import AccordionToggle from "../../components/accordion/AccordionToggle";
import { useQuery } from "@apollo/client";
import { Accordion, Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import DummyLink from "../../components/DummyLink";
import { InnerAccordion } from "../../components/faq/InnerAccordion";
import { getInstructions } from "../../graphqlQueries/InstructionsAndFaq/getInstructions";
import RelatedRolePages from "../../components/faq/RelatedRolePages";
import {
  InstructionResponse,
  InstructionsComponent,
  RoleData,
} from "../../types/instructionsAndFaqTypes";
import InstructionSearch from "../../components/faq/InstructionSearch";
import Icon from "../../components/Icon";
import { useTranslation } from "react-i18next";
import { useIsSmallScreen } from "../../components/MediaQuery";
import styled from "styled-components";
import Footer from "../../components/Footer";
import BackNavigationSideBar from "../../components/BackNavigationSideBar";
import { InstructionPageHolder } from "../../components/faq/InstructionPageHolder";
import { FAQ_PATH, INSTRUCTIONS_PATH } from "../../utils/routeConstants";
import { breakpointPixels } from "../../css/breakpoints";

const InstructionsContainer = styled.div`
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100%;
  max-width: 1080px;
  @media (min-width: ${breakpointPixels.xs}) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const InstructionsRolePage = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const isMobile = useIsSmallScreen();
  const history = useHistory();
  const { pathname } = useLocation();
  const [components, setComponents] = useState<InstructionsComponent[]>([]);
  const [roleData, setRoleData] = useState<RoleData>();
  const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false);
  // if preview mode we have an extra param
  const [roleKey, param, extraParam] = pathname
    .replaceAll(`${FAQ_PATH}${INSTRUCTIONS_PATH}/`, "")
    .trim()
    .split("/");

  const instructionId = extraParam || param;
  const ref = useRef<HTMLDivElement>(null);

  const { loading, error, refetch } = useQuery<InstructionResponse>(getInstructions, {
    variables: {
      locale: [i18n.language === "cimode" ? "en" : i18n.language],
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const rolePage = data.rolePages.find((it) => it.slug === roleKey);

      if (rolePage) {
        setComponents(rolePage.instructionsComponent);
        setRoleData(rolePage);
      }
    },
  });

  useEffect(() => {
    if (instructionId && roleData) {
      if (ref.current) {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: isMobile ? "nearest" : "center",
          inline: isMobile ? "nearest" : "center",
        });
      }
    }
  }, [instructionId, isMobile, roleData]);

  const handleClick = (newIdKey: string) => {
    if (!instructionId) {
      return history.push(pathname + newIdKey);
    }

    if (instructionId === newIdKey) {
      return;
    }

    history.replace({ pathname: pathname.replace(instructionId, newIdKey) });
  };

  if (error) {
    return (
      <InstructionPageHolder>
        <InstructionsContainer className="alert-danger">
          <p className="p-4">{t("common.error")}</p>
        </InstructionsContainer>
      </InstructionPageHolder>
    );
  }

  return (
    <InstructionPageHolder>
      <div className="border-bottom mb-4">
        <div className={isMobile ? "d-flex justify-content-between" : ""}>
          <BackNavigationSideBar backPath={"/faq"} showBottomBorder={false} />
          {isMobile && (
            <div className="my-auto mr-2">
              <Button
                className="btn-secondary"
                onClick={() => setShowMobileSearch(!showMobileSearch)}
              >
                <Icon name="search" size="medium" color="primary-dark" />
              </Button>
            </div>
          )}
        </div>
        {isMobile && showMobileSearch && (
          <Row className="mb-2 ml-2 mr-3 border-bottom">
            <Col className="text-center">
              <InstructionSearch refetchRole={refetch} pathname={pathname} />
            </Col>
          </Row>
        )}
      </div>
      <InstructionsContainer>
        <Row className="mb-4">
          <Col lg={8}>
            <DummyLink onClick={() => history.push(FAQ_PATH)}>
              {t("ukk.backToFrontpageLink")}
            </DummyLink>
            <span className="text-muted"> / {roleData?.title}</span>
          </Col>
          {!isMobile && (
            <Col>
              <InstructionSearch refetchRole={refetch} pathname={pathname} />
            </Col>
          )}
        </Row>
        <div className="mb-5">
          <h1 className="h2">{roleData?.title}</h1>
          {!isMobile && <p className="text-muted w-50">{roleData?.subText}</p>}
          <img
            className="rounded"
            src={isMobile ? roleData?.imageMobile?.url : roleData?.imageWide?.url}
            alt=""
            width="100%"
            height="auto"
          />
        </div>
        {loading ? (
          <div className="row justify-content-center my-5">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            {components?.map((component, i) => (
              <div key={i}>
                <h2 className="h5">{component.subTitle}</h2>
                <Accordion defaultActiveKey={instructionId}>
                  {component.subTitleInstructions?.map((it, i) => (
                    <div
                      className="mb-4"
                      key={i}
                      id={it.id}
                      ref={instructionId === it.id ? ref : null}
                    >
                      <AccordionToggle
                        className="rounded bg-gray-100 px-2 mb-3 shadow-sm"
                        iconPosition="right"
                        customOnclick={() => handleClick(it.id)}
                        eventKey={it.id}
                        isFAQ={true}
                      >
                        <AccordionContent customStyle="d-flex">
                          <p className="font-weight-bold mt-3" style={{ fontSize: "16px" }}>
                            {it.title}
                          </p>
                        </AccordionContent>
                      </AccordionToggle>
                      <Accordion.Collapse eventKey={it.id}>
                        <div className="px-3 overflow-auto">
                          <InnerAccordion data={it} idKey={instructionId} />
                          <hr />
                        </div>
                      </Accordion.Collapse>
                    </div>
                  ))}
                </Accordion>
              </div>
            ))}
          </>
        )}
        {roleData?.id && (
          <RelatedRolePages rolePageId={roleData.id} currentRole={roleData.slug} reload={refetch} />
        )}
        <Row className="p-5">
          <Col className="text-center">
            <h3 className="h4">
              <Icon className="mr-2 mb-1" name="lifeBuoy" size="large" color="white" />
              {t("ukk.footerHeader")}
            </h3>
            <span>
              {t("ukk.footerButton")}{" "}
              <a className="mt-3" href={t("footer.contactLink")}>
                {t("dashboard.supportLink")}
              </a>
            </span>
          </Col>
        </Row>
      </InstructionsContainer>
      <Footer />
    </InstructionPageHolder>
  );
};

export default InstructionsRolePage;
