import {
  CheckTokenResult,
  DatahubType,
  KeycloakTokenWithGroups,
  RequestedGroup,
  RoleGroups,
  UserInfo,
  UserRole,
} from "../types";
import { DeepMap, FieldError } from "react-hook-form";

import type { KeycloakInstance } from "keycloak-js";
import { TOKEN_MIN_VALIDITY_SECONDS } from "./constants";

export const appendSlashToUrl = (url: string): string => {
  if (!url) {
    return "";
  }
  if (url.endsWith("/")) {
    return url;
  }
  return `${url}/`;
};

export const getGraphQLEndpoint = (): string => {
  return `${appendSlashToUrl(String(process.env.REACT_APP_HASURA_SERVER_URL))}v1/graphql`;
};

export const getGraphCMSEndpoint = (): string => {
  return String(process.env.REACT_APP_GRAPHCMS_URL);
};

export const getDatahubApiEndpoint = (): string => {
  return `${appendSlashToUrl(String(process.env.REACT_APP_BACKEND_SERVER_URL))}api/datahub`;
};

export const getPublicUrl = (): string => {
  return appendSlashToUrl(String(process.env.REACT_APP_PUBLIC_URL));
};

const getContextHeaders = (context: Record<string, unknown>) => {
  if (context.headers && typeof context.headers === "object") {
    return context.headers;
  }
  return {};
};

export const getHasuraRoleContext = (userRole: UserRole, context: Record<string, unknown> = {}) => {
  return {
    ...context,
    headers: {
      "x-hasura-requested-role": userRole,
      ...getContextHeaders(context),
    },
  };
};

// Return new array where element is moved from <from> index to <to> index
export const arrayMove = <T>(array: Array<T>, from: number, to: number): Array<T> => {
  const newArray = array.slice();
  newArray.splice(to, 0, newArray.splice(from, 1)[0]);
  return newArray;
};

export const isVisitFinlandAdmin = (keycloak: KeycloakInstance) => {
  const VF_ADMIN_GROUP_NAME = "/Visit Finland Admins";
  const idToken = keycloak?.idTokenParsed as KeycloakTokenWithGroups | undefined;
  return !!idToken?.groups.includes(VF_ADMIN_GROUP_NAME);
};

export const isB2BUser = (user: UserInfo | null): boolean => {
  if (!user) {
    return false;
  }

  return (
    user.requestedGroup === RequestedGroup.B2B && !!user.roleGroups?.includes(RoleGroups.B2BUsers)
  );
};

export const isApiUser = (user: UserInfo | null): boolean => {
  return user?.roleGroups?.includes(RoleGroups.APIUsers) || false;
};

export const isDmoAllAreas = (keycloak: KeycloakInstance): boolean => {
  return keycloak.hasResourceRole(UserRole.CurateAllAreas) || false;
};

export const isDmoOrRequestedDmo = (keycloak: KeycloakInstance, user: UserInfo | null) => {
  const requestedGroup = user?.requestedGroup;
  return requestedGroup === RequestedGroup.DMO || keycloak.hasResourceRole(UserRole.ManageCuration);
};

export const isTcOrRequestedTc = (keycloak: KeycloakInstance, user: UserInfo | null) => {
  const requestedGroup = user?.requestedGroup;
  const TC_GROUP_NAME = "/Travel Company Admins";
  const idToken = keycloak?.idTokenParsed as KeycloakTokenWithGroups | undefined;
  return requestedGroup === RequestedGroup.TC || !!idToken?.groups.includes(TC_GROUP_NAME);
};

export const isDmo = (keycloak: KeycloakInstance) => {
  const DMO_GROUP_NAME = "/Regional Admins";
  const idToken = keycloak?.idTokenParsed as KeycloakTokenWithGroups | undefined;
  return !!idToken?.groups.includes(DMO_GROUP_NAME);
};

export const isTcAdmin = (keycloak: KeycloakInstance) => {
  const TC_GROUP_NAME = "/Travel Company Admins";
  const idToken = keycloak?.idTokenParsed as KeycloakTokenWithGroups | undefined;
  return !!idToken?.groups.includes(TC_GROUP_NAME);
};

export const isRequestedRoleAPIUser = (user?: UserInfo | null) => {
  return user?.requestedGroup === RequestedGroup.B2B;
};

export const isCompanyDmo = (companyDatahubType?: DatahubType): boolean => {
  return companyDatahubType === DatahubType.DMO;
};

export const labelCompare = (a: { label: string }, b: { label: string }) => {
  const nameA = a.label.toUpperCase();
  const nameB = b.label.toUpperCase();
  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  }

  return 0;
};

//check if closing time is at the end of day so UI can show "24:00" instead of db-value
export const isEndOfDay = (time: string): boolean => {
  const endOfDay = "23:59:59";
  return time.substring(0, endOfDay.length) === endOfDay;
};

export const formatNumberWithCurrencySymbol = (value: number): string => {
  return new Intl.NumberFormat("fi-FI", { style: "currency", currency: "EUR" }).format(value);
};

export const isCompanyAllowedForUser = (
  usersCompanyIds: string[] | undefined,
  companyId: string | null
) => {
  if (!usersCompanyIds || !companyId) {
    return false;
  }
  return usersCompanyIds.includes(companyId);
};

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export const createUrl = (url?: string | null) => {
  try {
    return new URL(url || "");
  } catch (error) {
    return undefined;
  }
};

export const getArray = (length: number): number[] => [...Array(length)];

export const checkToken = (keycloak: KeycloakInstance): Promise<CheckTokenResult> => {
  return keycloak
    .updateToken(TOKEN_MIN_VALIDITY_SECONDS)
    .then(() => ({ tokenOk: true }))
    .catch((error) => ({ tokenOk: false, error }));
};

export const getFieldErrorByPath = <T extends Record<string, unknown>>(
  obj: DeepMap<T, FieldError>,
  path: string
) => {
  const pathSegments = path
    .trim()
    .replace(/\[(\w+)\]/g, ".$1")
    .split(".")
    .filter((val) => !!val);
  return getFieldErrorByPathArray(obj, pathSegments);
};

export const getFieldErrorByPathArray = <T extends Record<string, unknown>>(
  obj: DeepMap<T, FieldError>,
  path: string[]
): FieldError | FieldError[] | undefined => {
  const newPath = [...path];
  const pathKey = newPath.shift();
  if (pathKey && pathKey in obj) {
    const objPropVal = obj[pathKey];
    if (newPath.length === 0) {
      if (Array.isArray(objPropVal) && objPropVal[0]?.type) {
        return objPropVal;
      }

      if (typeof objPropVal === "object" && (objPropVal as FieldError)?.type) {
        return objPropVal as FieldError;
      }
    } else if (objPropVal && typeof objPropVal === "object") {
      return getFieldErrorByPathArray(
        objPropVal as DeepMap<Record<string, unknown>, FieldError>,
        newPath
      );
    }
    return undefined;
  }
  return undefined;
};

export const getFieldError = <T extends Record<string, unknown>>(
  obj: DeepMap<T, FieldError>,
  path: string
) => {
  const error = getFieldErrorByPath(obj, path);
  if (!error) {
    return undefined;
  }
  if (Array.isArray(error)) {
    return undefined;
  }
  return error;
};

export const getFieldErrorArray = <T extends Record<string, unknown>>(
  obj: DeepMap<T, FieldError>,
  path: string
) => {
  const error = getFieldErrorByPath(obj, path);
  if (!error) {
    return undefined;
  }
  if (!Array.isArray(error)) {
    return undefined;
  }
  return error;
};

export const getUniqueStringObjects = <T extends Record<string, string>>(
  arrayOfObjects: T[]
): T[] =>
  arrayOfObjects.filter(
    (object, index) =>
      index === arrayOfObjects.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(object))
  );
