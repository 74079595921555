import {
  GetProductCommentsResult,
  getProductCommentsDmo,
  getProductCommentsTc,
} from "../../graphqlQueries/getProductComments";
import { UserRole, ViewMode } from "../../types";

import AddCommentForm from "../comments/AddCommentForm";
import CommentList from "../comments/CommentList";
import CommentsContainer from "../comments/CommentsContainer";
import Loading from "../Loading";
import React from "react";
import { getHasuraRoleContext } from "../../utils/functions";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

type CommentsProps = {
  productId: string;
  viewMode: ViewMode;
};

const PAGINATION_LIMIT = 10;

const Comments = ({ productId, viewMode }: CommentsProps) => {
  const { t } = useTranslation();
  const isDmoViewMode = viewMode === "dmo";
  const currentRole = isDmoViewMode ? UserRole.ManageCuration : UserRole.ManageProducts;

  const commentsQuery = isDmoViewMode ? getProductCommentsDmo : getProductCommentsTc;

  const { data, error, previousData, fetchMore } = useQuery<GetProductCommentsResult>(
    commentsQuery,
    {
      variables: {
        productId,
        limit: PAGINATION_LIMIT,
        offset: 0,
      },
      context: getHasuraRoleContext(currentRole),
      fetchPolicy: "cache-and-network",
    }
  );

  if (error) {
    return <p className="text-danger">{t("comments.errorLoading")}</p>;
  }

  const commentData = data?.product || previousData?.product;

  if (!commentData) {
    return (
      <CommentsContainer>
        <div className="px-3">
          <Loading i18nKey={"common.loading"} />
        </div>
      </CommentsContainer>
    );
  }

  const showMoreHandler = () => {
    fetchMore({
      variables: {
        limit: PAGINATION_LIMIT,
        offset: commentData.productComments.length,
      },
    });
  };

  return (
    <CommentsContainer>
      <AddCommentForm productId={productId} viewMode={viewMode} commentsQuery={commentsQuery} />
      <CommentList
        commentData={commentData}
        showMoreHandler={showMoreHandler}
        viewMode={viewMode}
        commentsQuery={commentsQuery}
      />
    </CommentsContainer>
  );
};

export default Comments;
