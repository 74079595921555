import moment, { Moment } from "moment";

import { isEndOfDay } from "./functions";

const DEFAULT_FRACTION_DIGITS = 2;

export const formatCurrency = (
  number: number | null | undefined,
  allowBlank = false,
  fractionDigits = DEFAULT_FRACTION_DIGITS
): string | null => {
  if (number === null) {
    return null;
  }
  if (!number || number === 0) {
    return "0";
  }

  if (!number && allowBlank) {
    return "";
  }

  const isWholeNumber = number % 1 === 0;
  const stringValue = isWholeNumber ? String(number) : number.toFixed(fractionDigits);

  return stringValue.replace(".", ",");
};

export const formatTime = (postgresTime?: string | null) => {
  if (!postgresTime) {
    return "";
  }
  const midNight = "24:00";
  return isEndOfDay(postgresTime) ? midNight : postgresTime.substring(0, midNight.length);
};

export const dayMonthFormat = "D.M.";
export const dayMonthYearFormat = "D.M.YYYY";
export const isoDayMonthYearFormat = "YYYY-MM-DD";

export const formatDateRange = (start: Moment | string, end: Moment | string): string =>
  `${moment(start).format(dayMonthFormat)} - ${moment(end).format(dayMonthYearFormat)}`;
