import {
  GetJoinRequestsAllUserDataResponse,
  GetJoinRequestsAllUserDataVariables,
  JoinRequestData,
  getJoinRequestsAllUserData,
} from "../graphqlQueries/getJoinRequests";
import { JoinRequestStatus, UserRole } from "../types";
import React, { useEffect, useState } from "react";
import getUserProfilesByCompanyQuery, {
  BasicUserProfile,
  GetUserProfilesByCompanyResult,
  GetUserProfilesByCompanyVariables,
} from "../graphqlQueries/getUserProfilesByCompany";
import removeCompanyUserQuery, {
  RemoveCompanyUserResult,
  RemoveCompanyUserVariables,
} from "../graphqlQueries/removeCompanyUser";

import JoinRequestGrid from "../components/userManagement/JoinRequestGrid";
import Notification from "../components/Notification";
import Page from "./Page";
import PageHeader from "../components/PageHeader";
import UserManagementTable from "../components/userManagement/UserManagementTable";
import { getHasuraRoleContext } from "../utils/functions";
import { getJoinRequestAggregate } from "../graphqlQueries/getJoinRequests";
import { useMutation } from "@apollo/client";
import useProcessJoinRequest from "../components/hooks/useProcessJoinRequest";
import { useQuery } from "@apollo/client";
import useRequestNotifications from "../components/hooks/useNotifications";
import { useTranslation } from "react-i18next";

export type ApprovedUserData = BasicUserProfile & {
  approvedAt: string;
  approvedBy: string;
};

type UserManagementProps = {
  companyId: string;
};

const UserManagement = ({ companyId }: UserManagementProps) => {
  const { t } = useTranslation();

  const [newRequests, setNewRequests] = useState<JoinRequestData[]>([]);
  const [approvedUserData, setApprovedUserData] = useState<ApprovedUserData[]>([]);

  const { notifications, addNotification, removeNotification } = useRequestNotifications();
  const { approveUser, denyUser } = useProcessJoinRequest(UserRole.ManageCompany);

  const [removeCompanyUser] = useMutation<RemoveCompanyUserResult, RemoveCompanyUserVariables>(
    removeCompanyUserQuery,
    { context: getHasuraRoleContext(UserRole.ManageCompany) }
  );

  const { data: joinRequestData } = useQuery<
    GetJoinRequestsAllUserDataResponse,
    GetJoinRequestsAllUserDataVariables
  >(getJoinRequestsAllUserData, {
    variables: {
      companyId: companyId ?? "",
    },
    context: getHasuraRoleContext(UserRole.ManageCompany),
  });

  const { data: userData } = useQuery<
    GetUserProfilesByCompanyResult,
    GetUserProfilesByCompanyVariables
  >(getUserProfilesByCompanyQuery, {
    variables: { companyId },
    context: getHasuraRoleContext(UserRole.ManageCompany),
    fetchPolicy: "cache-and-network",
  });

  const removeUser = (id: string) => {
    removeCompanyUser({
      variables: {
        userId: id,
        companyId,
      },
      refetchQueries: [getUserProfilesByCompanyQuery],
    })
      .then(() => addNotification("ok", t("userManagement.removeOkMessage"), "removeUser"))
      .catch((error) => {
        console.log(error);
        addNotification("error", t("userManagement.removeErrorMessage"));
      });
  };

  useEffect(() => {
    if (joinRequestData) {
      const newJoinRequests = joinRequestData.companyJoinRequest.filter(
        (req) => req.status === JoinRequestStatus.New
      );
      setNewRequests(newJoinRequests);
    }
  }, [joinRequestData]);

  useEffect(() => {
    if (joinRequestData && userData && userData.userFullProfileByCompany.length > 0) {
      const approvedJoinRequests = joinRequestData.companyJoinRequest.reduce<
        Record<string, JoinRequestData>
      >((map, req) => {
        if (req.status === JoinRequestStatus.Approved) {
          map[req.user.id] = req;
        }
        return map;
      }, {});
      const approvedUserData = userData.userFullProfileByCompany.map<ApprovedUserData>((user) => {
        const userJoinReq = approvedJoinRequests[user.id];
        return {
          ...user,
          approvedAt: userJoinReq?.updatedAt ?? "",
          approvedBy: userJoinReq?.updatedBy ?? "",
        };
      });
      setApprovedUserData(approvedUserData);
    }
  }, [joinRequestData, userData]);

  return (
    <Page>
      <div className="sticky-top bg-white">
        {notifications.map((data) => (
          <Notification
            className="mb-1"
            key={data.key}
            type={data.resultStatus === "ok" ? "success" : "danger"}
            onClose={() => removeNotification(data.key)}
          >
            {data.message}
          </Notification>
        ))}
      </div>
      <div className="p-3 m-md-3 mx-lg-4">
        <PageHeader>{t("userManagement.pageTitle")}</PageHeader>
        <p>{t("userManagement.pageDescription")}</p>
        <h2 className="text-landing-h4 ">{`${t("userManagement.requestsListTitle")} (${
          newRequests.length
        })`}</h2>
        <JoinRequestGrid
          requests={newRequests}
          onApprove={(id) =>
            approveUser(
              id,
              [getUserProfilesByCompanyQuery, getJoinRequestAggregate],
              addNotification
            )
          }
          onDeny={(id) => denyUser(id, [getJoinRequestAggregate], removeNotification)}
        />
        <div className="mt-5">
          <h2 className="text-landing-h4 mb-4">{t("userManagement.usersTableTitle")}</h2>
          <UserManagementTable users={approvedUserData} onRemove={removeUser} />
        </div>
      </div>
    </Page>
  );
};

export default UserManagement;
