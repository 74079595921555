import {
  AggregateCount,
  DataHubImage,
  ExternalProductSource,
  ProductStatus,
  ProductType,
} from "../types";
import { HasuraCurationStatus, HasuraProductVideo, ProductInformation } from "./getProduct";
import {
  allCompanyProducts,
  approvedCurationFilter,
  draftCurationFilter,
  getProductQueryFilters,
  getQueryName,
  productCurationPendingFilter,
} from "./productFilters";

import { QueryFilterVariables } from "../utils/filterUtils";
import fragments from "./fragments";
import { gql } from "@apollo/client";

const buildGetCompanyProductsQuery = (filters: QueryFilterVariables, companyId: string) => {
  return gql`
    query ${getQueryName("getCompanyProducts", filters)}(
      $companyId: uuid!
      $offset: Int!
      $limit: Int!
      ${filters.type ? "$type: [hasuradb_product_types_enum!]!" : ""}
      ${filters.tags ? "$tags: [String!]!" : ""}
      ${filters.search ? "$search: String!" : ""}
      ${filters.targetGroups ? "$targetGroups: [hasuradb_target_groups_enum!]!" : ""}
      ${filters.languages ? "$languages: [hasuradb_languages_enum]" : ""}
      ${filters.cities ? "$cities: [uuid!]!" : ""}
      ${filters.accessible ? "$accessible: Boolean!" : ""}
      ${filters.months ? "$months: [hasuradb_months_enum!]!" : ""}
      ${filters.certificates ? "$certificates: [String!]!" : ""}
      ${filters.curationStatus ? "$curationStatus: [hasuradb_curation_status_enum!]!" : ""}
      ${filters.status ? "$status: [hasuradb_product_status_enum!]!" : ""}
      ${
        filters.productCurationStatus
          ? "$productCurationStatus: [hasuradb_product_curation_status_enum!]!"
          : ""
      }) {
      product:product(
        where: ${getProductQueryFilters(filters, "", companyId, true)}
        order_by: { updatedAt: desc }
        limit: $limit
        offset: $offset
      ) {
        id
        ...ProductCommentsCount
        ...ProductNewCommentsCountTc
        productImages(order_by: { orderIndex: asc }) {
          id
          largeUrl
          originalUrl
          thumbnailUrl
          altText
          copyright
          filename
          coverPhoto
        }
        productInformations {
          id
          language
          name
          description
          verifiedProductLink {
            invalidAt
          }
          verifiedWebshopLink {
            invalidAt
          }
        }
        productCurations {
          id
          curationStatus
        }
        productVideos {
          verifiedVideoLink {
            invalidAt
          }
        }
        status
        updatedAt
        type
        externalSource
      }

       draft:productAggregate(where: ${getProductQueryFilters(
         filters,
         draftCurationFilter,
         companyId,
         true
       )} ) {
      aggregate {
        count
      }
    }
     curationPending:productAggregate(where: ${getProductQueryFilters(
       filters,
       productCurationPendingFilter,
       companyId,
       true
     )} ) {
      aggregate {
        count
      }}
       approved:productAggregate(where: ${getProductQueryFilters(
         filters,
         approvedCurationFilter,
         companyId,
         true
       )} ) {
      aggregate {
        count
      }
    }
      all:productAggregate(where: ${getProductQueryFilters(
        filters,
        allCompanyProducts,
        companyId,
        true
      )} ) {
      aggregate {
        count
      }
    }
    productAggregate(where: ${getProductQueryFilters(filters, "", companyId, true)}) {
      aggregate {
          count
      }
    }
    }
    ${fragments.product.commentsCount}
    ${fragments.product.newCommentsCountForTc}
  `;
};

export default buildGetCompanyProductsQuery;

export type HasuraProductType = {
  productType: ProductType;
};

export type HasuradbCompanyProduct = {
  id: string;
  productCommentsAggregate: AggregateCount;
  productNewCommentsAggregate: AggregateCount;
  productImages: DataHubImage[];
  type: ProductType;
  productInformations: ProductInformation[];
  productCurations: HasuraCurationStatus[];
  status: ProductStatus;
  productVideos?: HasuraProductVideo[];
  externalSource?: ExternalProductSource;
};

export type ProductQueryResult = {
  product: HasuradbCompanyProduct[];
  draft: AggregateCount;
  curationPending: AggregateCount;
  approved: AggregateCount;
  all: AggregateCount;
  productAggregate: AggregateCount;
  limit: number;
  offset: number;
};
