import { gql } from "@apollo/client";

import { IdResult } from "../types";

export const deleteProductGroupMutation = gql`
  mutation DeleteProductGroup($id: uuid!) {
    updateProductGroupByPk(
      pk_columns: { id: $id }
      _set: { deletedAt: "now()", published: false }
    ) {
      id
    }
  }
`;

export type DeleteProductGroupResult = {
  updateProductGroupByPk: IdResult;
};

export type DeleteProductGroupVariables = {
  id: string;
};
