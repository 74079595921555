import React, { useEffect } from "react";
import Checkbox from "../Checkbox";
import { useFormContext } from "react-hook-form";
import { getFieldError } from "../../utils/functions";
import { getMin, MaintenanceDateTime } from "./MaintenanceDateTime";
import { useTranslation } from "react-i18next";
import { useIsSmallScreen } from "../MediaQuery";
import { Row } from "react-bootstrap";
import { MaintenanceDataOut } from "../../types/bannerTypes";
import moment from "moment";

const BANNER_START_DATE = "bannerStartDate";
const BANNER_END_DATE = "bannerEndDate";
const BANNER_START_TIME = "bannerStartTime";
const BANNER_END_TIME = "bannerEndTime";
const SET_ON_IMMEDIATELY = "setOnImmediately";

const MaintenanceNotificationSection = ({
  hasEmptyFields,
  editModeData,
}: {
  hasEmptyFields: (bool: boolean) => void;
  editModeData?: MaintenanceDataOut;
}) => {
  const { t } = useTranslation();
  const isMobile = useIsSmallScreen();
  const { register, errors, watch, setValue, trigger } = useFormContext();

  const watchSetOnImmediately = watch(SET_ON_IMMEDIATELY);
  const watchBannerStartTime = watch(BANNER_START_TIME, "");
  const watchBannerStartDate = watch(BANNER_START_DATE, "");
  const watchBannerEndTime = watch(BANNER_END_TIME, "");
  const watchBannerEndDate = watch(BANNER_END_DATE, "");

  const startTimeError = getFieldError(errors, BANNER_START_TIME);
  const startTimeHasErrors = !!startTimeError;

  const endTimeError = getFieldError(errors, BANNER_END_TIME);
  const endTimeHasErrors = !!endTimeError?.message;

  useEffect(() => {
    const isFilled =
      !!watchBannerStartTime &&
      !!watchBannerStartDate &&
      !!watchBannerEndTime &&
      !!watchBannerEndDate;
    hasEmptyFields(isFilled);
  }, [
    hasEmptyFields,
    watchBannerEndTime,
    watchBannerStartDate,
    watchBannerStartTime,
    watchBannerEndDate,
  ]);

  // Set end date to be same or after start date
  useEffect(() => {
    if (watchBannerStartDate && !watchBannerEndDate) {
      setValue(BANNER_END_DATE, watchBannerStartDate);
      trigger(BANNER_END_DATE);
    }

    if (watchBannerStartDate && watchBannerEndDate) {
      if (moment(watchBannerEndDate).isBefore(watchBannerStartDate)) {
        setValue(BANNER_END_DATE, watchBannerStartDate);
        trigger(BANNER_END_DATE);
      }
    }
  }, [setValue, trigger, watchBannerEndDate, watchBannerStartDate]);

  useEffect(() => {
    if (editModeData) {
      setValue(BANNER_START_DATE, editModeData.bannerStartDate);
      setValue(BANNER_END_DATE, editModeData.bannerEndDate);
    }
  }, [editModeData, setValue]);

  const setStartTime = (time: string | undefined) => {
    // If values haven't changed or combinedNumber is undefined, return
    if (!time || watchBannerStartTime === time) {
      return;
    }
    setValue(BANNER_START_TIME, time, { shouldValidate: true });
    trigger(BANNER_END_TIME);
  };

  const setEndTime = (time: string | undefined) => {
    if (!time || watchBannerEndTime === time) {
      return;
    }

    setValue(BANNER_END_TIME, time, { shouldValidate: true });
    trigger(BANNER_START_TIME);
  };

  return (
    <>
      <div className="border-bottom mb-3">
        <p className={`font-weight-bold font-heavy ${isMobile && "pl-2"}`}>
          {t("maintenance.bannerTiming")}
        </p>
        {!isMobile ? (
          <div className="d-flex flex-row justify-content-start" style={{ width: "80%" }}>
            <div className="w-50">
              <MaintenanceDateTime
                formLabel={t("maintenance.bannerOn")}
                groupName={BANNER_START_DATE}
                dateFieldName={BANNER_START_DATE}
                timeFieldName={BANNER_START_TIME}
                setTime={setStartTime}
                timeHasErrors={startTimeHasErrors}
                dateDisabled={watchSetOnImmediately}
                checkbox={
                  <div className="pt-3">
                    <Checkbox
                      id={SET_ON_IMMEDIATELY}
                      label={t("maintenance.setOnImmediately")}
                      defaultChecked={!!editModeData?.setOnImmediately}
                      ref={register()}
                      key={SET_ON_IMMEDIATELY}
                      name={SET_ON_IMMEDIATELY}
                    />
                  </div>
                }
                editDate={editModeData?.bannerStartDate}
                editTime={editModeData?.bannerStartTime}
                minDate={new Date()}
              />
            </div>
            <div style={{ width: "52%" }}>
              <MaintenanceDateTime
                formLabel={t("maintenance.bannerOff")}
                groupName={BANNER_END_DATE}
                dateFieldName={BANNER_END_DATE}
                timeFieldName={BANNER_END_TIME}
                setTime={setEndTime}
                timeHasErrors={endTimeHasErrors}
                editDate={editModeData?.bannerEndDate}
                editTime={editModeData?.bannerEndTime}
                minDate={getMin(watchBannerStartDate)}
              />
            </div>
          </div>
        ) : (
          <>
            <Row className="px-4">
              <MaintenanceDateTime
                formLabel={t("maintenance.bannerOn")}
                groupName={BANNER_START_DATE}
                dateFieldName={BANNER_START_DATE}
                timeFieldName={BANNER_START_TIME}
                setTime={setStartTime}
                timeHasErrors={startTimeHasErrors}
                dateDisabled={watchSetOnImmediately}
                checkbox={
                  <div className="pt-3">
                    <Checkbox
                      id={SET_ON_IMMEDIATELY}
                      label={t("maintenance.setOnImmediately")}
                      defaultChecked={!!editModeData?.setOnImmediately}
                      ref={register()}
                      key={SET_ON_IMMEDIATELY}
                      name={SET_ON_IMMEDIATELY}
                    />
                  </div>
                }
                editDate={editModeData?.bannerStartDate}
                editTime={editModeData?.bannerStartTime}
                minDate={new Date()}
              />
            </Row>
            <Row className="px-4">
              <MaintenanceDateTime
                formLabel={t("maintenance.bannerOff")}
                groupName={BANNER_END_DATE}
                dateFieldName={BANNER_END_DATE}
                timeFieldName={BANNER_END_TIME}
                setTime={setEndTime}
                timeHasErrors={endTimeHasErrors}
                editDate={editModeData?.bannerEndDate}
                editTime={editModeData?.bannerEndTime}
                minDate={getMin(watchBannerStartDate)}
              />
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default MaintenanceNotificationSection;
