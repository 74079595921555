import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NotAvailable } from "./NotAvailable";
import ViewProductSectionHeader from "./ViewProductSectionHeader";
import { SocialMediaLinks } from "../../graphqlQueries/getSingleCompanyInfo";
import { Col, Row } from "react-bootstrap";
import DummyLink from "../DummyLink";
import SocialMediasModal from "./SocialMediasModal";
import SocialMediaLink from "../SocialMediaLink";

type ViewProductSocialsProps = {
  socialMediaLinks?: SocialMediaLinks[];
};

const LINKS_TO_SHOW_LIMIT = 3;

const ViewProductSocials = ({ socialMediaLinks }: ViewProductSocialsProps) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const SocialMediaLinksView = () => {
    if (!socialMediaLinks || socialMediaLinks.length < 1) {
      return (
        <Col className="pb-3">
          <NotAvailable />
        </Col>
      );
    }

    if (socialMediaLinks.length > LINKS_TO_SHOW_LIMIT) {
      return (
        <>
          {socialMediaLinks.slice(0, LINKS_TO_SHOW_LIMIT).map((it, i) => (
            <Col key={i} xs={6}>
              <SocialMediaLink link={it} className={"d-block mb-3"} key={i} />
            </Col>
          ))}
          <Col xs={6}>
            <DummyLink onClick={() => setOpenModal(!openModal)}>
              {t("viewProduct.showAllSocialMediasButton")}
            </DummyLink>
          </Col>
        </>
      );
    }

    return (
      <>
        {socialMediaLinks.map((it, i) => (
          <Col key={i} xs={6}>
            <SocialMediaLink link={it} className={"d-block mb-3"} key={i} />
          </Col>
        ))}
      </>
    );
  };

  return (
    <>
      <ViewProductSectionHeader className="mb-4">
        {t("viewProduct.socialMediaHeader")}
      </ViewProductSectionHeader>
      <Row>
        <SocialMediaLinksView />
      </Row>
      {openModal && socialMediaLinks && (
        <SocialMediasModal
          onClose={() => setOpenModal(!openModal)}
          socialMediaLinks={socialMediaLinks}
        />
      )}
    </>
  );
};

export default ViewProductSocials;
