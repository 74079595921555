import PlainDropdown, { DropdownContent } from "./Dropdown";
import React, { useCallback, useEffect, useState } from "react";

import { Dropdown } from "react-bootstrap";
import Icon from "./Icon";
import moment from "moment";
import { useTranslation } from "react-i18next";

const showTranslationKeys = String(process.env.REACT_APP_SHOW_TRANSLATION_KEYS) === "true";

type Locale = "en" | "fi" | "cimode";

type LanguageDropdownProps = {
  signedIn?: boolean;
};

const languages: { text: string; value: Locale }[] = [
  {
    text: "English",
    value: "en",
  },
  {
    text: "Suomi",
    value: "fi",
  },
];

if (showTranslationKeys) {
  languages.push({
    text: "Näytä käännösavaimet",
    value: "cimode",
  });
}

const LanguageDropdown = ({ signedIn }: LanguageDropdownProps) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(i18n.language);
  const currentLanguage = languages.find((item) => language === item.value);

  const changeLanguage = useCallback(
    (lang: Locale) => {
      setLanguage(lang);
      i18n.changeLanguage(lang);
      if (lang !== "cimode") {
        moment.locale(lang);
        document.querySelector("html")?.setAttribute("lang", lang);
      }
    },
    [setLanguage, i18n]
  );

  useEffect(() => {
    try {
      const savedLanguage = localStorage.getItem("i18nextLng");
      if (savedLanguage) {
        document.querySelector("html")?.setAttribute("lang", savedLanguage);
      }
      if (savedLanguage && !language) {
        changeLanguage(savedLanguage as Locale);
      }
    } catch (e) {
      // local storage not available
    }
  }, [changeLanguage, language]);

  return signedIn ? (
    <Dropdown className="bg-gray-100 border border-gray-200">
      {languages.map((lang) => (
        <Dropdown.Item
          className="pl-3"
          key={lang.value}
          onClick={() => changeLanguage(lang.value as Locale)}
        >
          <DropdownContent>
            <span style={{ width: "30px" }} className="d-inline-block">
              {currentLanguage?.text === lang.text && (
                <Icon name="check" size="medium" className="mr-2 pb-1" />
              )}
            </span>
            {lang.text}
          </DropdownContent>
        </Dropdown.Item>
      ))}
    </Dropdown>
  ) : (
    <PlainDropdown
      style={{ height: "65px" }}
      items={languages}
      selected={currentLanguage}
      onChange={(selectedLanguage) => {
        changeLanguage(selectedLanguage.value as Locale);
      }}
      dataCy="language-dropdown"
    />
  );
};

export default LanguageDropdown;
