import { CurationStatus, DatahubType, ProductCurationStatus } from "../types";

import { gql } from "@apollo/client";

export const getAddCommentMutation = (changeCurationStatus: boolean) => {
  const addCommentMutation = gql`
    mutation AddComment${changeCurationStatus ? "WithCurationStatusChange" : ""}

  (
      $productId: uuid!
      $commentText: String!
      $companyId: uuid!
      $userName: String!
      $commentRecipientRole: hasuradb_datahub_types_enum!
      ${changeCurationStatus ? "$curationStatus: hasuradb_curation_status_enum!" : ""}
      $productCurationStatus: hasuradb_product_curation_status_enum!
    ) {
      insertProductCommentsOne(
        object: {
          productId: $productId
          comment: $commentText
          createdByCompanyId: $companyId
          createdByName: $userName
          recipientRole: $commentRecipientRole
        }
      ) {
        id
      }
      updateProductCuration(
        where: { product: { id: { _eq: $productId } } }
        _set: { updatedByCompany: $companyId, productCurationStatus: $productCurationStatus ${
          changeCurationStatus ? ", curationStatus: $curationStatus" : ""
        }
      }
      ) {
        returning {
          id
          productCurationStatus
          curationStatus
        }
      }
    }
  `;
  return addCommentMutation;
};

export interface InsertProductComment {
  productId: string;
  commentText: string;
  companyId: string;
  userName: string;
  commentRecipientRole: DatahubType;
  productCurationStatus?: ProductCurationStatus;
}

export interface ChangeCurationAndComment extends InsertProductComment {
  curationStatus: CurationStatus;
}

export interface InsertCommentResult {
  insertProductCommentsOne: { id: string };
  updateProductCuration: { affected_rows: number };
}
