import { Nav, Tab } from "react-bootstrap";

import React from "react";
import { SelectCallback } from "react-bootstrap/esm/helpers";
import { default as c } from "classnames";
import styled from "styled-components";

const StyledNav = styled(Nav.Item)`
  background-color: var(--color-gray-200);
  border-radius: 5px 5px 0px 0px;
  border: 1px solid var(--color-gray-400);
  border-bottom: 0;
  position: relative;
  a {
    color: var(--color-black);
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &.error {
    background-color: var(--color-primary-light);
    border: 1px solid var(--color-primary);
    border-bottom: 0;
    a {
      color: var(--color-error);
    }
  }
  &.active {
    background-color: var(--white);
    border-bottom: 1px solid var(--white);
    &:before {
      content: "";
      border-bottom: 1px solid white;
      bottom: -2px;
      position: absolute;
      width: 100%;
    }
  }
`;

type TabValue = string | number | boolean;

type DataHubTabsProps = {
  tabs: {
    label: string;
    value: TabValue;
    hasError: boolean;
    controls?: React.ReactElement;
  }[];
  activeTab: string | number;
  onSelect: SelectCallback;
};

const DataHubTabs: React.FunctionComponent<DataHubTabsProps> = ({ tabs, activeTab, onSelect }) => {
  return (
    <>
      <Tab.Container defaultActiveKey="first" activeKey={activeTab} onSelect={onSelect}>
        {tabs.map((tab, i) => {
          return (
            <StyledNav
              key={`nav-${i}`}
              className={c({
                active: tab.value === activeTab,
                error: tab.hasError,
              })}
            >
              <Nav.Link eventKey={tab.value.toString()}>
                {tab.label}
                {tab.controls}
              </Nav.Link>
            </StyledNav>
          );
        })}
      </Tab.Container>
    </>
  );
};

export default DataHubTabs;
