import { ProductGroupData } from "../../types";
import { gql } from "@apollo/client";

export const getProductGroupDataQuery = (canManage: boolean) => gql`
  query GetProductGroupData($groupId: uuid!) {
    productGroupByPk(id: $groupId) {
      id
      name
      description
      createdAt
      published
      publishedAt
      publishingId
      permissionId
      ${
        canManage
          ? `deletedProducts: products(
        where: {
          product: {
            _or: [
              { status: { _neq: published } }
              { productCurations: { curationStatus: { _neq: approved } } }
            ]
          }
        }
      ) {
        product {
          id
          type
          updatedAt
          productInformations {
            name
          }
          company {
            businessName
          }
          postalAddresses {
            city
          }
        }
      }`
          : ""
      }
      products(
        where: {
          product: {
            _and: {
              status: { _eq: published }
              productCurations: { curationStatus: { _eq: approved } }
            }
          }
        }
      ) {
        product {
          id
          type
          accessible
          duration
          durationType
          businessHours {
            id
            default {
              id
              weekday
              opens
              closes
              open
            }
            exceptions(order_by: { start: asc }) {
              id
              start
              end
              openingHours {
                id
                weekday
                opens
                closes
                open
                date
              }
            }
          }
          socialMedia {
            id
            companySocialMedia
            socialMediaLinks {
              id
              linkId
              linkType
              socialMediaId
              verifiedLink {
                id
                url
                userVerifiedAt
                userVerifiedBy
              }
            }
          }
          externalSource
          productImages(order_by: { orderIndex: asc }) {
            id
            largeUrl
            originalUrl
            originalHeight
            originalWidth
            orientation
            thumbnailUrl
            altText
            copyright
            filename
            coverPhoto
          }
          productVideos {
            id
            title
            verifiedVideoLink {
              id
              url
            }
          }
          productTargetGroups {
            id
            targetGroupId
          }
          productTags {
            id
            tag
          }
          productInformations {
            id
            name
            description
            language
            verifiedProductLink {
              id
              url
            }
            verifiedWebshopLink {
              id
              url
            }
          }
          company {
            id
            businessName
            businessHoursId
            socialMediaId
          }
          contactDetails {
            id
            phone
            email
          }
          productAvailabilities {
            id
            endDate
            startDate
          }
          productAvailableMonths {
            id
            month
          }
          productPricings {
            id
            fromPrice
            toPrice
            pricingUnit
            pricingType
          }
          productAvailabilityLanguages {
            id
            language
          }
          postalAddresses {
            id
            city
            postalCode
            streetName
            location
          }
          productCertificates {
            id
            certificate
          }
          productCapacities {
            id
            min
            max
          }
          productGroupProductImages(where: { productGroupId: { _eq: $groupId } }) {
            id
            productGroupId
            largeUrl
            originalUrl
            originalHeight
            originalWidth
            orientation
            thumbnailUrl
            altText
            copyright
            filename
            coverPhoto
            productId
          }
          productGroupProductInformations(where: { productGroupId: { _eq: $groupId } }) {
            id
            productGroupId
            name
            description
            language
            productId
            verifiedProductLink {
              id
              url
            }
            verifiedWebshopLink {
              id
              url
            }
          }
        }
      }
      permissions {
        id
        role
        user {
          id
          name
        }
        company {
          id
          businessName
        }
      }
    }
  }
`;

export type GetProductGroupDataResult = {
  productGroupByPk: ProductGroupData;
};

export type GetProductGroupDataVariables = {
  groupId: string;
};
