import { Button } from "react-bootstrap";
import Icon from "../Icon";
import React from "react";
import { breakpointPixels } from "../../css/breakpoints";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useIsSmallScreen } from "../MediaQuery";
import { useTranslation } from "react-i18next";
import { COMPANY_PATH, CURATOR_WORKSPACE_PATH } from "../../utils/routeConstants";

type DMODashboardCardProps = {
  headerTitle: string;
  companyId: string;
  cardType: "products" | "join-requests";
  iconName: "bell" | "user-plus";
  newUpdates?: boolean;
  userJoinRequestsAmount?: number;
};

const StyledCard = styled.div`
  margin-top: 1em;
  border-top: 2px solid var(--color-primary);
  min-height: 12rem;
  @media (max-width: ${breakpointPixels.xl}) {
    width: 100%;
    min-height: auto;
  }
`;

const StyledBoxHeader = styled.div`
  min-height: 3.5em;
  @media (max-width: ${breakpointPixels.sm}) {
    border-bottom: 0;
  }
`;

export const DMODashboardCard: React.FunctionComponent<DMODashboardCardProps> = ({
  headerTitle,
  newUpdates,
  companyId,
  iconName,
  cardType,
  userJoinRequestsAmount,
  children,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsSmallScreen();

  const BoxHeader: React.FunctionComponent = () => (
    <StyledBoxHeader className="d-flex flex-row w-100 py-2 px-2 border-gray-300-bottom-2 align-items-center">
      <div className="text-center align-items-center pb-1">
        <Icon size="medium" name={iconName} className="mr-3" />
      </div>
      <div className="px-0 mt-2 align-items-center">
        <h6>{t(headerTitle)}</h6>
      </div>
      {userJoinRequestsAmount && !isMobile && (
        <div className="d-flex ml-2 align-items-center">
          <h2 className="mb-0 mr-1">{userJoinRequestsAmount}</h2>
          <div className="d-flex align-items-center">
            <span>{t("notifications.pieces")}</span>
          </div>
        </div>
      )}
      {isMobile ? (
        <div className="ml-3">
          <Icon size="large" name="arrow-right" color="primary-dark" className="mb-2" />
        </div>
      ) : null}
    </StyledBoxHeader>
  );

  if (isMobile && !newUpdates) {
    return null;
  }

  return (
    <StyledCard className="dashboard-card">
      {isMobile && newUpdates && (
        <Button
          variant="text"
          onClick={() => history.push(`${COMPANY_PATH}/${companyId}${CURATOR_WORKSPACE_PATH}`)}
          className="w-100 px-0"
        >
          <BoxHeader />
        </Button>
      )}
      {!isMobile && (
        <>
          <BoxHeader />
          <div className="px-4 pb-4 pt-3 px-sm-2">{children}</div>
        </>
      )}
    </StyledCard>
  );
};
