import {
  AccessibilityType,
  AvailabilitySeason,
  AvailableMonth,
  BusinessHoursFormValues,
  Certificate,
  CurationStatus,
  Month,
  MyProductFormValues,
  OpeningHours,
  OpeningHoursWeek,
  PricingType,
  ProductCategory,
  ProductCurationStatus,
  ProductDetails,
  ProductFormLink,
  ProductType,
  Weekday,
} from "../../types";
import {
  HasuraProductAddress,
  HasuraProductAvailability,
  HasuraProductCapacity,
  HasuraProductCertificate,
  HasuraProductImage,
  HasuraProductInformation,
  HasuraProductPricing,
  HasuraProductTag,
  HasuraProductTargetGroup,
  HasuraProductVideoInsert,
  HasuraVerifiedLinkInsert,
  HasuraProductDuration,
} from "../../graphqlQueries/insertProduct";

import { BusinessHoursPeriodInsertInput } from "../../graphqlQueries/updateOpeningHours";
import { HasuraAvailabilityLanguage } from "../../graphqlQueries/getProduct";
import { getUniqueStringObjects } from "../functions";
import { isoDayMonthYearFormat } from "../format";

export const getProductType = (formValues: MyProductFormValues): ProductType => {
  return formValues.productType;
};

export const getCategories = (
  productCategories: ProductCategory[] | undefined
): HasuraProductTag[] => {
  if (!productCategories) {
    return [];
  }

  const tags = productCategories.map(({ value: tag }) => ({
    tag,
  }));

  return getUniqueStringObjects(tags);
};

export const getTargetGroups = ({
  targetGroups,
}: MyProductFormValues): HasuraProductTargetGroup[] => {
  if (!targetGroups) {
    return [];
  }

  return targetGroups.map((targetGroup) => ({
    targetGroupId: targetGroup,
  }));
};

const mapLinkFormData = (link: ProductFormLink): HasuraVerifiedLinkInsert | undefined => {
  if (!link.url) {
    return undefined;
  }

  return {
    data: {
      url: link.url,
      userVerifiedAt: link.userVerifiedAt || null,
      userVerifiedBy: link.userVerifiedBy || null,
    },
  };
};

export const getAvailabilityLanguage = ({
  availabilityLanguages,
}: MyProductFormValues): HasuraAvailabilityLanguage[] => {
  if (!availabilityLanguages) {
    return [];
  }
  return availabilityLanguages.map((it) => ({ language: it.value }));
};

export const getVideos = ({ videos }: MyProductFormValues): HasuraProductVideoInsert[] => {
  if (!videos) {
    return [];
  }

  return videos
    .filter((video) => video.title || video.videoLink.url)
    .map((video) => ({
      title: video.title,
      verifiedVideoLink: mapLinkFormData(video.videoLink),
    }));
};

export const getImages = (formValues: MyProductFormValues): HasuraProductImage[] => {
  const images = formValues.images || [];
  return images
    .filter((i) => !!i.filename)
    .map((img, index) => ({
      ...img,
      orderIndex: index,
    }));
};

export const getAvailableMonths = (formValues: MyProductFormValues): AvailableMonth[] => {
  const months = formValues.specificMonths || [];
  const allYear = formValues.availabilitySeason === AvailabilitySeason.AllYear;

  if (allYear) {
    return Object.values(Month).map((month) => ({
      month,
    }));
  }

  return months.map((availableMonth) => ({
    month: availableMonth,
  }));
};

export const getAvailableDays = (formValues: MyProductFormValues): HasuraProductAvailability => {
  const productType = getProductType(formValues);
  const eventStartDate = formValues.eventDateRange?.startDate?.format("YYYY-MM-DD") || null;
  const eventEndDate = formValues.eventDateRange?.endDate?.format("YYYY-MM-DD") || null;

  const checkIfEventDay = (date: string | null) =>
    productType === ProductType.Event ? date : null;

  return {
    startDate: checkIfEventDay(eventStartDate),
    endDate: checkIfEventDay(eventEndDate),
    transportationAvailability: formValues.transportationAvailability || null,
  };
};

export const getPricing = ({
  priceFree,
  priceFrom,
  pricingUnit,
  priceTo,
}: MyProductFormValues): HasuraProductPricing[] => {
  const parseCurrency = (val: string | null) => {
    return val === "" || val === null ? null : parseFloat(val.replace(",", "."));
  };

  const emptyPricing = {
    fromPrice: null,
    toPrice: null,
    pricingUnit: null,
    pricingType: PricingType.Unknown,
  };

  if (!priceFree && !priceFrom && !priceTo) {
    return [emptyPricing];
  }

  return [
    {
      fromPrice: priceFree ? 0 : parseCurrency(priceFrom),
      toPrice: priceFree ? 0 : parseCurrency(priceTo),
      pricingUnit: !priceFrom && !priceTo ? null : pricingUnit?.value || null,
      pricingType: priceFree ? PricingType.Free : PricingType.Chargeable,
    },
  ];
};

export const getCapacity = ({
  capacityMax,
  capacityMin,
}: MyProductFormValues): HasuraProductCapacity[] => {
  return [
    {
      min: capacityMin ? parseInt(capacityMin) : null,
      max: capacityMax ? parseInt(capacityMax) : null,
    },
  ];
};

type OpeningHoursPeriodOut = Omit<BusinessHoursPeriodInsertInput, "businessHoursId">;

export const getDefaultOpeningHours = ({
  openingHours,
}: BusinessHoursFormValues): OpeningHoursPeriodOut => {
  return {
    defaultPeriod: true,
    start: null,
    end: null,
    openingHours: { data: getOpeningHours(openingHours) },
  };
};

export const getOpeningHours = (openingHours: OpeningHoursWeek): OpeningHours[] => {
  return Object.entries(openingHours).map(([weekday, openingData]) => {
    if (!openingData) {
      throw new Error("opening hours data missing while key exists");
    }
    const castedWeekday: Weekday = weekday as Weekday;
    const { opens, closes, closed, date } = openingData;

    const isEmptyTime =
      (opens.length === 0 || Number(opens.replace(":", "")) === 0) &&
      (closes.length === 0 || Number(closes.replace(":", "")) === 0);

    const getTimeValue = (time: string) => {
      if (closed || isEmptyTime || !time) {
        return null;
      }
      return time;
    };

    return {
      open: !closed,
      weekday: castedWeekday,
      opens: getTimeValue(opens),
      closes: getTimeValue(closes),
      date: date || null,
    };
  });
};

export const getOpeningHoursExceptions = ({
  openingHoursExceptions,
}: BusinessHoursFormValues): OpeningHoursPeriodOut[] => {
  return (
    openingHoursExceptions?.map((exception) => {
      const { startDate, endDate } = exception.period;
      const openingHoursData = exception.openingHours;
      return {
        defaultPeriod: false,
        start: startDate?.format(isoDayMonthYearFormat) || null,
        end: endDate?.format(isoDayMonthYearFormat) || null,
        openingHours: openingHoursData
          ? {
              data: getOpeningHours(openingHoursData),
            }
          : null,
      };
    }) ?? []
  );
};

export const getProductInformations = (
  formValues: MyProductFormValues
): HasuraProductInformation[] => {
  return formValues.details.map<HasuraProductInformation>(
    ({
      language,
      productName: name,
      productDescription: description,
      productLink,
      webshopLink,
    }: ProductDetails) => {
      return {
        language,
        name,
        description,
        verifiedProductLink: mapLinkFormData(productLink),
        verifiedWebshopLink: mapLinkFormData(webshopLink),
      };
    }
  );
};

export const getDuration = ({
  minutes,
  hours,
  days,
  weeks,
}: MyProductFormValues): HasuraProductDuration[] => {
  return [
    {
      minutes: minutes?.value ?? 0,
      hours: hours?.value ?? 0,
      days: days?.value ?? 0,
      weeks: weeks?.value ?? 0,
    },
  ];
};

export const getCertificates = ({
  stfCertified,
  welcomeCyclistCertified,
}: {
  stfCertified: boolean;
  welcomeCyclistCertified: boolean;
}) => {
  const arr: HasuraProductCertificate[] = [];

  if (stfCertified) {
    arr.push({ certificate: Certificate.STF });
  }

  if (welcomeCyclistCertified) {
    arr.push({ certificate: Certificate.WelcomeCyclist });
  }

  return arr;
};

export const getAccessibility = (accessible: AccessibilityType) => {
  if (accessible === AccessibilityType.Undefined) {
    return null;
  }

  return accessible === AccessibilityType.Accessible;
};

export const getAddress = (formValues: MyProductFormValues): HasuraProductAddress => {
  // draft product can have empty postalCode and that means it needs to be null
  // because of foreign key constraint from postalCode to existing postal area
  const postalCode = formValues.postalCode === "" ? null : formValues.postalCode;

  return {
    city: formValues.city,
    streetName: formValues.streetName,
    postalCode,
  };
};

export const getLocation = ({ longitude, latitude }: MyProductFormValues) => {
  if (!!longitude && !!latitude) {
    return {
      longitude: parseFloat(longitude.replace(",", ".")),
      latitude: parseFloat(latitude.replace(",", ".")),
    };
  }
};

const getCurationStatus = (curationStatus: boolean): ProductCurationStatus => {
  if (curationStatus) {
    // When curationStatus is approved
    return ProductCurationStatus.Complete;
  }

  return ProductCurationStatus.ChangesRequested;
};

const getProductCurationStatus = (
  curationStatus: boolean,
  productCurationStatus: ProductCurationStatus
): ProductCurationStatus => {
  if (curationStatus || productCurationStatus === ProductCurationStatus.ChangesRequested) {
    return ProductCurationStatus.Updated;
  }

  return productCurationStatus;
};

export const getNewProductCurationStatus = (
  curationStatus: CurationStatus,
  productCurationStatus: ProductCurationStatus,
  isCurator?: boolean
): ProductCurationStatus => {
  const curationStatusApproved = curationStatus === CurationStatus.Approved;

  if (isCurator) {
    return getCurationStatus(curationStatusApproved);
  }

  return getProductCurationStatus(curationStatusApproved, productCurationStatus);
};
