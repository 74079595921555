import React, { useState } from "react";

import { BasicModal } from "../DataHubModal";
import Checkbox from "../Checkbox";
import { useTranslation } from "react-i18next";

type VerifyLinkModalProps = {
  onCancel: () => void;
  onProceed: () => void;
};

export const VerifyLinkModal: React.FC<VerifyLinkModalProps> = ({ onCancel, onProceed }) => {
  const [userConfirmation, setUserConfirmation] = useState(false);
  const { t } = useTranslation();

  return (
    <BasicModal
      header={<b>{t("verifyLink.userVerifyModalHeader")}</b>}
      onCancel={onCancel}
      onProceed={onProceed}
      cancelText={t("common.cancel")}
      proceedText={t("common.confirm")}
      centered
      disableProceed={!userConfirmation}
    >
      <>
        <p>{t("verifyLink.userVerifyModalContent1")}</p>
        <p>{t("verifyLink.userVerifyModalContent2")}</p>
        <Checkbox
          id="userConfirmation"
          name="userConfirmation"
          onChange={(e) => {
            setUserConfirmation(e.target.checked);
          }}
          checked={userConfirmation}
          label={t("verifyLink.userVerifyModalComfirmation")}
        />
      </>
    </BasicModal>
  );
};
