import { Controller, useFormContext } from "react-hook-form";
import { checkIfAllZeros } from "../../utils/timeUtils";
import { MyProductFormValues, OpeningHoursInputFormValues } from "../../types";
import { parseToTimeTuple } from "../../utils/formUtils";

import Checkbox from "../Checkbox";
import { Form } from "react-bootstrap";
import React from "react";
import { TimePicker } from "./TimePicker";
import { getFieldError } from "../../utils/functions";
import { useTranslation } from "react-i18next";

export type OpeningHoursInputProps = {
  inputName: string;
  defaultValues?: OpeningHoursInputFormValues;
  align?: "left" | "center" | "right";
  required?: boolean;
  disabled?: boolean;
};

const MAX_MORNING_CLOSING_TIME = 6;

const ERROR_MESSAGE_REQUIRED = "validationErrors.required";
const ERROR_MESSAGE_CLOSING_TIME_TOO_EARLY = "validationErrors.closingTimeTooEarly";

export const OpeningHoursInput: React.FunctionComponent<OpeningHoursInputProps> = ({
  inputName,
  defaultValues = {
    opens: "",
    closes: "",
    closed: false,
    date: "",
  },
  align = "left",
  required = false,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { register, errors, setValue, watch, trigger, clearErrors, getValues } = useFormContext<
    MyProductFormValues
  >();

  // The name of the closing time field
  const closedName = `${inputName}.closed`;
  // The name of the opening time field
  const closesName = `${inputName}.closes`;
  // The name of the closed on this date checkbox field
  const opensName = `${inputName}.opens`;
  const dateName = `${inputName}.date`;

  const watchClosed = watch(closedName, false);
  const watchCloses = watch(closesName, "");
  const watchOpens = watch(opensName, "");

  const opensError = getFieldError(errors, opensName);
  const opensHasErrors = !!opensError;

  const closesError = getFieldError(errors, closesName);
  const closesHasErrors = !!closesError?.message;

  // TODO: should be moved up in component tree since it cant be used with opening hours exceptions (openingHours is hardcoded)
  const checkIfShouldFillTimes = (): boolean => {
    const { openingHours } = getValues();
    if (!openingHours) {
      return false;
    }
    // If one day has had its opening hours filled then all of the days should have, should
    // return results if input is empty but other days have hours filled in
    return Object.entries(openingHours).some(([_, hours]) => {
      if (!hours) {
        return false;
      }
      const { opens, closes, closed } = hours;
      return closed || !checkIfAllZeros(parseToTimeTuple(opens), parseToTimeTuple(closes));
    });
  };

  const checkClosingTime = () => {
    // if closing time is more than MAX_MORNING_CLOSING_TIME in the morning, it's not valid for the time to be
    // smaller than the opening time nor is it to be the same as opening time
    const openingTimes = parseToTimeTuple(watchOpens);
    const closingTimes = parseToTimeTuple(watchCloses);

    if (
      !watchClosed &&
      openingTimes &&
      closingTimes &&
      Number(closingTimes[0]) > MAX_MORNING_CLOSING_TIME &&
      Number(openingTimes.join("")) >= Number(closingTimes.join(""))
    ) {
      return ERROR_MESSAGE_CLOSING_TIME_TOO_EARLY;
    }

    return true;
  };

  const setOpens = (time: string | undefined) => {
    // If values haven't changed or time is undefined, return
    if (!time || watchOpens === time) {
      return;
    }
    setValue(opensName, time);
    trigger(closesName).then(() => {
      setValue(opensName, time, { shouldValidate: true });
    });
  };

  const setCloses = (time: string | undefined) => {
    // If values haven't changed or time is undefined, return
    if (!time || watchCloses === time) {
      return;
    }
    setValue(closesName, time);
    trigger(opensName).then(() => {
      setValue(closesName, time, { shouldValidate: true });
    });
  };

  const getOpensRequired = () => {
    const hasNoValues = !watchClosed && !watchOpens && !watchCloses;
    if (hasNoValues && (required || checkIfShouldFillTimes())) {
      return "validationErrors.openingHoursRequired";
    }

    if (watchCloses) {
      return ERROR_MESSAGE_REQUIRED;
    }

    return undefined;
  };

  const getErrorElement = (messageKey?: string) => {
    return <div className="text-danger ml-3">{t(messageKey ?? "Missing message key")}</div>;
  };

  const openingHoursDisabled = disabled || watchClosed;

  return (
    <div className="d-flex w-100 flex-column">
      <div className={`d-flex flex-wrap w-100 justify-content-${align}`}>
        <Controller
          name={opensName}
          rules={{
            required: getOpensRequired(),
          }}
          defaultValue={defaultValues.opens}
          render={({ value: time }) => {
            return (
              <TimePicker
                key={opensName}
                disabled={openingHoursDisabled}
                hasErrors={opensHasErrors}
                initialTime={time}
                onBlur={setOpens}
              />
            );
          }}
        />
        <div className="d-flex align-self-center mr-2 ml-2">
          <span>{`–`}</span>
        </div>
        <Controller
          name={closesName}
          rules={{
            required: watchOpens ? ERROR_MESSAGE_REQUIRED : false,
            validate: checkClosingTime,
          }}
          defaultValue={defaultValues.closes}
          render={({ value: time }) => {
            return (
              <TimePicker
                key={closesName}
                isClosing={true}
                disabled={openingHoursDisabled}
                hasErrors={closesHasErrors}
                initialTime={time}
                onBlur={setCloses}
              />
            );
          }}
        />
        <div className="pt-2 ml-2">
          <Checkbox
            id={`${inputName}-closed`}
            defaultChecked={defaultValues.closed}
            ref={register()}
            name={closedName}
            label={t("productInfo.openingHoursClosed")}
            disabled={disabled}
            onChange={(event) => {
              if (event.target.checked) {
                setValue(closesName, "");
                setValue(opensName, "");
                clearErrors([opensName, closesName]);
              }
            }}
          />
        </div>
        <Form.Control
          name={dateName}
          type="hidden"
          value={defaultValues.date}
          ref={register()}
          aria-hidden
        />
      </div>
      {(opensHasErrors || closesHasErrors) && (
        <div className="row">
          {opensHasErrors && getErrorElement(opensError?.message)}
          {closesHasErrors && getErrorElement(closesError?.message)}
        </div>
      )}
    </div>
  );
};
