import React from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../css/breakpoints";

type Props = {
  children: React.ReactNode;
};

export const useIsMediumOrSmaller = () => useMediaQuery({ maxWidth: breakpoints.md });
export const useIsMediumOrLarger = () => useMediaQuery({ minWidth: breakpoints.md });
export const useIsSmallScreen = () => useMediaQuery({ maxWidth: breakpoints.sm - 1 });
export const useIsLargeScreen = () => useMediaQuery({ maxWidth: breakpoints.lg });
export const useIsLargeOrExtraLargeScreen = () => useMediaQuery({ minWidth: breakpoints.xl });
export const useIsVerySmall = () => useMediaQuery({ maxWidth: breakpoints.xs });

export const LargeScreen = ({ children }: Props) => {
  const isLarge = useMediaQuery({ minWidth: breakpoints.lg });
  return <>{isLarge ? children : null}</>;
};

export const LargeOrSmallerScreen = ({ children }: Props) => {
  const isLarge = useMediaQuery({ maxWidth: breakpoints.lg - 1 });
  return <>{isLarge ? children : null}</>;
};

export const MediumOrSmallerScreen = ({ children }: Props) => {
  const isMedium = useIsMediumOrSmaller();
  return <>{isMedium ? children : null}</>;
};

export const SmallScreen = ({ children }: Props) => {
  return <>{useIsSmallScreen() ? children : null}</>;
};
