import React, { CSSProperties, useContext } from "react";
import { AccordionContext, useAccordionToggle } from "react-bootstrap";

import CollapseToggleButton from "../CollapseToggleButton";

type AccordionToggleProps = {
  children: React.ReactNode;
  eventKey: string;
  callback?: (event: string) => void;
  iconPosition?: "left" | "right";
  renderIcon?: (open: boolean) => JSX.Element;
  showIcon?: boolean;
  style?: CSSProperties;
  className?: string;
  customOnclick?: () => void;
  isFAQ?: boolean;
  disabled?: boolean;
};

const AccordionToggle = ({
  children,
  eventKey,
  callback,
  renderIcon,
  showIcon,
  iconPosition,
  className,
  style,
  customOnclick,
  isFAQ = false,
  disabled = false,
}: AccordionToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    customOnclick && customOnclick();
    callback && callback(eventKey);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <CollapseToggleButton
      onClick={decoratedOnClick}
      style={disabled ? { ...style, border: 0 } : style}
      disabled={disabled}
      className={className}
      contentOpen={isCurrentEventKey}
      renderIcon={renderIcon}
      showIcon={showIcon}
      iconPosition={iconPosition}
      isFAQ={isFAQ}
    >
      {children}
    </CollapseToggleButton>
  );
};

export default AccordionToggle;
