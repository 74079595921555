import React from "react";
import IconButton from "./IconButton";
import Icon from "./Icon";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ButtonContainer = styled.div`
  height: 68px;
`;

type BackNavigationSideBarProps = { backPath: string; showBottomBorder?: boolean };

const BackNavigationSideBar = ({
  backPath,
  showBottomBorder = true,
}: BackNavigationSideBarProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ButtonContainer className={`${showBottomBorder && "border-bottom"}`}>
      <IconButton
        variant="secondary"
        iconElement={
          <Icon name="arrow-left" size="medium" className="mr-3" style={{ marginTop: "-3px" }} />
        }
        onClick={() => history.push(backPath)}
        className="p-3 w-100 h-100"
        data-cy="navigation-back"
      >
        {t("common.back")}
      </IconButton>
    </ButtonContainer>
  );
};

export default BackNavigationSideBar;
