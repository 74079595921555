import { useCallback, useState } from "react";

import { CurationStatusesFilter, ProductFilters } from "../../types";

export const initialProductFilters: ProductFilters = {
  curationStatus: CurationStatusesFilter.All,
  withoutCurator: false,
  stfCertifiedFilter: false,
  accessibleFilter: false,
  byAllLanguagesFilter: false,
};

type useProductFiltersReturnProps = {
  filters: ProductFilters;
  setFilters: (newState: ProductFilters) => void;
  defaultFilters: ProductFilters;
  setDefaultFilters: (defaultFilters: ProductFilters) => void;
};

const useProductFilters = (
  productFilters: ProductFilters = initialProductFilters
): useProductFiltersReturnProps => {
  const [filters, setFilters] = useState<ProductFilters>(productFilters);
  // Default filters are either set by initializing the hook with values or by using the
  // generic initial product filters as given to productFilters
  const [defaultFilters, setDefaults] = useState<ProductFilters>(productFilters);

  // To explicitly set default filters
  const setDefaultFilters = useCallback(
    (newFilters: ProductFilters) => {
      // use already set defaultFilters as a fallback for missing values
      setDefaults((defaults) => ({
        ...defaults,
        ...newFilters,
      }));
    },
    [setDefaults]
  );

  return {
    filters,
    setFilters,
    defaultFilters,
    setDefaultFilters,
  };
};

export default useProductFilters;
