import moment from "moment";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, DeepMap, FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  BusinessHoursFormValues,
  OpeningHoursDates,
  OpeningHoursDay,
  OpeningHoursExceptionFormValues,
  OpeningHoursWeek,
} from "../../types";
import { isoDayMonthYearFormat } from "../../utils/format";
import { validateDateRange } from "../../utils/formUtils";
import {
  getOpeningHoursDays,
  getOpeningHoursDaysWeek,
  isoWeekdayToWeekday,
  weekdays,
} from "../../utils/timeUtils";
import DateRange from "../DateRange";
import Notification from "../Notification";
import { OpeningHoursContainer } from "./OpeningHoursContainer";

type OpeningHoursExceptionsProps = {
  name: string;
  defaultPeriod: OpeningHoursDates;
  openingHours?: OpeningHoursWeek;
  errors?: DeepMap<OpeningHoursExceptionFormValues, FieldError>;
  required?: boolean;
  disabled?: boolean;
};

const getExceptionDays = (
  defaultPeriod: OpeningHoursDates,
  openingHours?: OpeningHoursWeek
): OpeningHoursDay[] => {
  const { startDate, endDate } = defaultPeriod;
  if (!startDate || !endDate) {
    return [];
  }
  const periodLength = endDate.diff(startDate, "days") + 1;

  if (periodLength > weekdays.length) {
    return getOpeningHoursDaysWeek(openingHours);
  }

  const dates = Array.from(Array(periodLength)).map((_, i) => {
    const date = moment(startDate).add(i, "days");
    const weekday = isoWeekdayToWeekday(date.isoWeekday());
    return {
      date,
      weekday,
    };
  });

  return getOpeningHoursDays(dates, openingHours);
};

const isPeriodInConflict = (
  period: OpeningHoursDates,
  allExceptions: OpeningHoursExceptionFormValues[]
) => {
  return allExceptions.some((exception) => {
    if (period === exception.period) {
      return false;
    }
    const { startDate: start, endDate: end } = period;
    const { startDate: startComp, endDate: endComp } = exception.period;
    if (start && end && startComp && endComp) {
      // Create new moment dates so that the time of the day is same for all
      const startDate = moment(start.format(isoDayMonthYearFormat));
      const endDate = moment(end.format(isoDayMonthYearFormat));
      const startCompDate = moment(startComp.format(isoDayMonthYearFormat));
      const endCompDate = moment(endComp.format(isoDayMonthYearFormat));

      // Is this period inbetween any other exception
      if (startDate >= startCompDate && startDate <= endCompDate) {
        return true;
      }
      if (endDate >= startCompDate && endDate <= endCompDate) {
        return true;
      }
      // Is any other exception between this period
      if (startCompDate >= startDate && startCompDate <= endDate) {
        return true;
      }
      if (endCompDate >= startDate && endCompDate <= endDate) {
        return true;
      }
    }

    return false;
  });
};

const periodConflictError = "validationErrors.exceptionPeriodConflict";

export const OpeningHoursException: React.FunctionComponent<OpeningHoursExceptionsProps> = ({
  name,
  defaultPeriod,
  openingHours,
  errors,
  required,
  disabled,
}) => {
  const { getValues, trigger } = useFormContext<BusinessHoursFormValues>();
  const [openingHoursDays, setOpeningHoursDays] = useState<OpeningHoursDay[]>(() =>
    getExceptionDays(defaultPeriod, openingHours)
  );
  const { t } = useTranslation();

  const updateOpeningHoursDays = (period: OpeningHoursDates) => {
    const days = getExceptionDays(period);
    if (days.length > 0) {
      setOpeningHoursDays(days);
    }
  };

  const renderPeriodErrors = () => {
    if (errors && errors.period) {
      if (errors.period.message === periodConflictError) {
        return <Notification type="danger">{t(errors.period.message)}</Notification>;
      }
      return <p className="text-danger mt-2">{t(errors.period.message ?? "")}</p>;
    }

    return null;
  };

  return (
    <div>
      <Form.Group controlId={`${name}.period`}>
        <Form.Label className="mt-4">{t("openingHours.exceptions.datePickerLabel")}</Form.Label>
        <Controller
          name={`${name}.period`}
          defaultValue={defaultPeriod}
          rules={{
            validate: {
              required: (newPeriod) =>
                validateDateRange(newPeriod.startDate, newPeriod.endDate, required),
              conflict: (newPeriod) => {
                return isPeriodInConflict(newPeriod, getValues().openingHoursExceptions ?? [])
                  ? periodConflictError
                  : true;
              },
            },
          }}
          render={({ onChange, value }) => (
            <DateRange
              value={value}
              id={`${name}-period`}
              disabled={disabled}
              onChange={(startDate, endDate) => {
                const period = { startDate, endDate };
                onChange(period);
                updateOpeningHoursDays(period);
                if (startDate && endDate) {
                  trigger(`${name}.period`);
                }
              }}
            />
          )}
        />
        {renderPeriodErrors()}
      </Form.Group>
      <OpeningHoursContainer
        name={`${name}.openingHours`}
        openingHoursDays={openingHoursDays}
        valuesRequired={required}
        inputsDisabled={disabled}
      />
    </div>
  );
};
