import { gql } from "@apollo/client";
import { CurationStatus, ProductCurationStatus } from "../types";

const updateProductCurationByPk = gql`
  mutation UpdateProductCurationByPk(
    $productId: uuid!
    $curationApproved: timestamptz
    $curatorCompanyId: uuid!
  ) {
    UpdateProductCurationByPk(
      productId: $productId
      curationApproved: $curationApproved
      curatorCompanyId: $curatorCompanyId
    ) {
      curationStatus
      productCurationStatus
    }
  }
`;

export type UpdateProductCurationByPkVariables = {
  productId: string;
  curationApproved?: string;
  curatorCompanyId: string;
};

export type UpdateProductCurationByPkResult = {
  UpdateProductCurationByPk: {
    curationStatus: CurationStatus;
    productCurationStatus: ProductCurationStatus;
  };
};

export default updateProductCurationByPk;
