import { gql } from "@apollo/client";

export const updateMaintenanceQuery = gql`
  mutation UpdateMaintenance(
    $id: uuid!
    $titleEn: String!
    $titleFi: String!
    $contentEn: String!
    $contentFi: String!
    $bannerStartTime: time!
    $bannerStartDate: date!
    $bannerEndTime: time!
    $bannerEndDate: date!
    $breakStartTime: time!
    $breakStartDate: date!
    $breakEndTime: time!
    $breakEndDate: date!
    $setOnImmediately: timestamptz
    $manualIsActive: Boolean!
  ) {
    updateMaintenanceBannerByPk(
      pk_columns: { id: $id }
      _set: {
        titleEn: $titleEn
        titleFi: $titleFi
        contentEn: $contentEn
        contentFi: $contentFi
        bannerStartTime: $bannerStartTime
        bannerStartDate: $bannerStartDate
        bannerEndTime: $bannerEndTime
        bannerEndDate: $bannerEndDate
        breakStartTime: $breakStartTime
        breakStartDate: $breakStartDate
        breakEndTime: $breakEndTime
        breakEndDate: $breakEndDate
        setOnImmediately: $setOnImmediately
      }
    ) {
      id
    }
    updateMaintenanceManualToggle(
      where: { maintenanceBanner: { id: { _eq: $id } } }
      _set: { manualIsActive: $manualIsActive }
    ) {
      affected_rows
    }
  }
`;
