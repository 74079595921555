import {
  OpeningHours,
  OpeningHoursDay,
  OpeningHoursWeek,
  ProductOpeningHours,
  TimeTuple,
  Weekday,
} from "../types";
import moment, { Moment } from "moment";

import React from "react";
import { getOpeningHoursWeekdayKey } from "./localizationUtils";
import { isoDayMonthYearFormat } from "./format";

export enum openingTimeEnum {
  OPENING = "opening",
  CLOSING = "closing",
}

export type MaxNumbers = 23 | 24 | 59; //eslint-disable-line

export const numberRegex = /^[0-9\b]+$/;
export const MAX_HOURS_FOR_OPENING: MaxNumbers = 23;
export const MAX_HOURS_FOR_CLOSING: MaxNumbers = 24;
const MAX_HOURS: MaxNumbers = 23;
const MAX_MINUTES = 59;
const MAX_TIME_PART_LENGTH = 2;

export const checkIfAllZeros = (
  openingTime: TimeTuple | undefined = ["00", "00"],
  closingTime: TimeTuple | undefined = ["00", "00"]
) => {
  return Number(openingTime.join("")) === 0 && Number(closingTime.join("")) === 0;
};

const parseToNumber = (input: string): string | undefined => {
  let parsedInput = input;
  // Remove the leading zero by picking only the two last digits
  if (input.charAt(0) === "0") {
    parsedInput = input.substring(1);
  }
  if (!numberRegex.test(parsedInput) || !Number(parsedInput)) {
    return undefined;
  }
  if (parsedInput.length > MAX_TIME_PART_LENGTH) {
    return parsedInput.slice(-MAX_TIME_PART_LENGTH);
  }
  return parsedInput;
};

export const checkForNoInput = (
  input: string,
  maxNumber: MaxNumbers,
  type: "hours" | "minutes",
  handleTimeChange: (value: TimeTuple) => void,
  minutes: string,
  hours: string,
  openingTimeType?: openingTimeEnum
): string | undefined => {
  const isInputZero = input === "0";
  const isInputHours = type === "hours";
  // Checks if a zero has been input after a single digit
  const hasLeadingAndTrailingZero =
    input.charAt(0) === "0" && input.charAt(input.length - 1) === "0";

  // If inputting closing time, convert 00 hours to 24. Also if user is typing in 0 after
  // having typed in a single digit before, check if the input has a leading AND trailing
  // zero to convert this into 24 instead of allowing 00 as an input for closing time.
  if (
    ((isInputZero && isInputHours && (minutes === "00" || !minutes)) ||
      hasLeadingAndTrailingZero) &&
    openingTimeType === openingTimeEnum.CLOSING
  ) {
    return "24";
  }

  if (input.length === 0 || isInputZero) {
    handleTimeChange(isInputHours ? ["00", minutes] : [hours, "00"]);
    return;
  }
  // Parse the number to remove leading zeros and check for validity
  const parsedInput = parseToNumber(input);

  if (!parsedInput) {
    return;
  }

  // In case the hours is 24 turn minutes into 00
  if (!isInputHours && Number(hours) === MAX_HOURS_FOR_CLOSING) {
    return "0";
  }

  // If the user is inputting a new number on top of 2 previous numbers,
  //  take the latest inserted one
  if (parsedInput.length > MAX_TIME_PART_LENGTH || Number(parsedInput) > maxNumber) {
    return parsedInput[parsedInput.length - 1];
  }

  return parsedInput;
};

export const handleHours = (
  hourInput: string,
  minuteRef: React.RefObject<HTMLInputElement>,
  handleTimeChange: (value: TimeTuple) => void,
  minutes: string,
  hours: string,
  maxHour: MaxNumbers = MAX_HOURS,
  openingTimeType?: openingTimeEnum
) => {
  const parsedHour = checkForNoInput(
    hourInput,
    maxHour,
    "hours",
    handleTimeChange,
    minutes,
    hours,
    openingTimeType
  );

  if (!parsedHour) {
    return;
  }

  if (parsedHour.length >= MAX_TIME_PART_LENGTH) {
    if (parsedHour.length > MAX_TIME_PART_LENGTH) {
      handleTimeChange([parsedHour.charAt(parsedHour.length - 1), minutes]);
    } else {
      handleTimeChange([parsedHour, minutes]);
    }
    minuteRef.current?.focus();
    return;
  }

  handleTimeChange([parsedHour, minutes]);
};

export const handleMinutes = (
  minuteInput: string,
  handleTimeChange: (value: TimeTuple) => void,
  minutes: string,
  hours: string,
  openingTimeType?: openingTimeEnum
) => {
  const parsedMinutes = checkForNoInput(
    minuteInput,
    MAX_MINUTES,
    "minutes",
    handleTimeChange,
    minutes,
    hours,
    openingTimeType
  );

  if (!parsedMinutes || parsedMinutes.length > MAX_TIME_PART_LENGTH) {
    return;
  }

  handleTimeChange([hours, parsedMinutes]);
};

export const weekdays = [
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
  Weekday.Saturday,
  Weekday.Sunday,
];

type WeekdayIsoValues = {
  [key in Weekday]: number;
};

export const weekdaysIsoValuesMap: WeekdayIsoValues = Object.values(Weekday).reduce(
  (acc, weekday, index) => Object.assign(acc, { [weekday]: index + 1 }),
  {} as WeekdayIsoValues
);

export const weekdaysWithLabels = weekdays.map((weekday) => ({
  value: weekday,
  label: getOpeningHoursWeekdayKey(weekday),
}));

export const isoWeekdayToWeekday = (weekday: number): Weekday => {
  if (
    weekday < weekdaysIsoValuesMap[Weekday.Monday] ||
    weekday > weekdaysIsoValuesMap[Weekday.Sunday]
  ) {
    throw new Error("Invalid ISO weekday");
  }
  return weekdays[weekday - 1];
};

export const mapToOpeningHoursDay = (
  weekday: Weekday,
  openingHours?: ProductOpeningHours,
  date?: Moment
): OpeningHoursDay => {
  return {
    labelKey: getOpeningHoursWeekdayKey(weekday, !!date),
    weekday: weekday,
    date,
    defaultValues: {
      opens: openingHours?.opens ?? "",
      closes: openingHours?.closes ?? "",
      closed: openingHours?.closed ?? false,
      date: date ? date.format(isoDayMonthYearFormat) : undefined,
    },
  };
};

type WeekdayDate = {
  weekday: Weekday;
  date?: Moment;
};

const weekdayDates: WeekdayDate[] = weekdays.map((weekday) => ({ weekday }));

export const getOpeningHoursDays = (
  weekdays: WeekdayDate[],
  defaultValues?: OpeningHoursWeek
): OpeningHoursDay[] => {
  return weekdays.map(({ weekday, date }) => {
    return mapToOpeningHoursDay(weekday, defaultValues?.[weekday], date);
  });
};

export const getOpeningHoursDaysWeek = (defaultValues?: OpeningHoursWeek): OpeningHoursDay[] =>
  getOpeningHoursDays(weekdayDates, defaultValues);

export const sortOpeningHours = (openingHours: OpeningHours[]) =>
  [...openingHours].sort((a, b) => {
    if (a.date && b.date) {
      return moment(a.date).diff(moment(b.date));
    }

    return weekdaysIsoValuesMap[a.weekday] - weekdaysIsoValuesMap[b.weekday];
  });
