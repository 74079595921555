import React from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../PageHeader";
import DashboardGuide from "./DashboardGuide";
import DashboardInformation from "./DashboardInformation";
import DashboardLayout from "./DashboardLayout";

const TCDashboard = () => {
  const { t } = useTranslation();

  return (
    <DashboardLayout
      header={<PageHeader>{t("dashboard.header")}</PageHeader>}
      content={
        <div className="d-flex flex-row">
          <DashboardInformation isDmo={false} />
          <DashboardGuide isDmo={false} />
        </div>
      }
      withWrapper
    />
  );
};

export default TCDashboard;
