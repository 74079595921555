import "leaflet/dist/leaflet.css";

import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { ProductPublishMode, ProductType, UserRole } from "../../types";
import React, { useState } from "react";
import {
  allowedCityFormat,
  capitalizeAddressName,
  capitalizeCityNames,
  getRequiredValidator,
} from "../../utils/formUtils";
import {
  getCityByPostalArea,
  getCityByPostalAreaResult,
  getCityByPostalAreaVariables,
} from "../../graphqlQueries/getCityByPostalArea";

import ExternalLink from "../ExternalLink";
import { HasuraPostalAddress } from "../../graphqlQueries/getSingleCompanyInfo";
import Icon from "../Icon";
import { InfoModal } from "../DataHubModal";
import { default as c } from "classnames";
import { getHasuraRoleContext } from "../../utils/functions";
import { DEFAULT_ZOOM, getLocationFromOSM, NO_POSITION_ZOOM } from "../../utils/OSMutils";
import { postalCodeValidator } from "../../utils/postalCodeValidation";
import { useApolloClient } from "@apollo/client";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  FormSectionSubHeader,
  FormSubDescription,
  FormSubSection,
  FormSubSectionContent,
} from "../FormSection";
import { ProductMap } from "./ProductMap";

const MAX_LONGITUDE = 180;
const MAX_LATITUDE = 90;

const LATITUDE_LABEL = "latitude";
const LONGITUDE_LABEL = "longitude";
const POSTALCODE_LABEL = "postalCode";
const STREETNAME_LABEL = "streetName";
const CITY_LABEL = "city";

type LocationDetailsSectionProps = {
  companyAddress?: HasuraPostalAddress;
  publishMode: ProductPublishMode;
  isProductGroup?: boolean;
};

const MAX_ADDRESS_LENGTH = 100;

const LocationDetailsSection = ({
  companyAddress,
  publishMode,
  isProductGroup,
}: LocationDetailsSectionProps) => {
  const { t } = useTranslation();
  const { register, errors, getValues, setValue, watch, setError, clearErrors } = useFormContext();
  const [hasAddressError, setHasAddressError] = useState<boolean>(false);
  const [currentPosition, setCurrentPosition] = useState<[number, number]>();
  const [isFetchingAddressData, setIsFetchingAddressData] = useState<boolean>(false);
  const [isFetchingPostalCity, setIsFetchingPostalCity] = useState<boolean>(false);
  const [hasStreetAddressWarning, setHasStreetAddressWarning] = useState<boolean>(false);
  const [shouldUpdateAddress, setShouldUpdateAddress] = useState<boolean>(false);
  const [showCoordinatesInstructionModal, setShowCoordinatesInstructionModal] = useState<boolean>(
    false
  );
  const [foundNoResults, setFoundNoResults] = useState<boolean>(false);

  const apolloClient = useApolloClient();
  const productType = watch("productType");
  const longitude = watch(LONGITUDE_LABEL);
  const latitude = watch(LATITUDE_LABEL);
  const streetName = watch(STREETNAME_LABEL);
  const postalCode = watch(POSTALCODE_LABEL);
  const city = watch(CITY_LABEL);

  const singleCoordValidation = React.useCallback(
    (coordInput: string, secondCoord: string, type: "Longitude" | "Latitude") => {
      if (coordInput === "") {
        return;
      }

      if (secondCoord && coordInput === "") {
        return "validationErrors.required";
      }
      const inputMax = type === "Longitude" ? MAX_LONGITUDE : MAX_LATITUDE;

      if (coordInput !== "") {
        const parsedCoord = Number.parseFloat(coordInput.toString().replace(",", "."));
        const isValid =
          !/[^0-9.-]/g.test(coordInput.toString()) &&
          isFinite(parsedCoord) &&
          Math.abs(parsedCoord) <= inputMax;
        return !isValid ? `validationErrors.invalid${type}` : undefined;
      }
    },
    []
  );

  const validatePostalCode = async (postalCode: string): Promise<string | undefined> => {
    console.log("publishmode is", publishMode);
    if ((publishMode === "draft" && postalCode.length === 0) || publishMode === "product-group") {
      return;
    }

    return postalCodeValidator(apolloClient, postalCode, UserRole.ManageProducts);
  };

  const fetchCityForPostalCode = async (postalCode: string) => {
    setIsFetchingPostalCity(true);

    try {
      const hasPostalCodeError = await validatePostalCode(postalCode);
      if (hasPostalCodeError) {
        setIsFetchingPostalCity(false);
        setError(POSTALCODE_LABEL, { message: hasPostalCodeError });
        return;
      } else {
        clearErrors(POSTALCODE_LABEL);
      }

      const res = await apolloClient.query<getCityByPostalAreaResult, getCityByPostalAreaVariables>(
        {
          query: getCityByPostalArea,
          variables: {
            postalCode: postalCode,
          },
          context: getHasuraRoleContext(UserRole.ManageProducts),
        }
      );

      setValue(CITY_LABEL, res.data?.postalArea[0].city.city);
    } catch (e) {
      setError(POSTALCODE_LABEL, { message: "postalCodeError.postalCodeFetchError" });
    }
    setIsFetchingPostalCity(false);
  };

  const setCurrentCoordinatesFromMap = React.useCallback(
    (coords: [string | number, string | number]) => {
      const [lat, lon] = coords;
      const latHasErrors = singleCoordValidation(latitude, longitude, "Latitude");
      const lonHasErrors = singleCoordValidation(longitude, latitude, "Longitude");

      // guarder function to sanity check
      if (latHasErrors || lonHasErrors) {
        if (latHasErrors) {
          setError(LATITUDE_LABEL, { message: latHasErrors });
        } else {
          clearErrors(LATITUDE_LABEL);
        }
        if (lonHasErrors) {
          setError(LONGITUDE_LABEL, { message: lonHasErrors });
        } else {
          clearErrors(LONGITUDE_LABEL);
        }
        return;
      }

      if (!latHasErrors && !lonHasErrors) {
        clearErrors([LATITUDE_LABEL, LONGITUDE_LABEL]);
        setCurrentPosition([Number(lat), Number(lon)]);
        setValue(LATITUDE_LABEL, lat);
        setValue(LONGITUDE_LABEL, lon);
      }
    },
    [setValue, latitude, longitude, clearErrors, setError, singleCoordValidation]
  );

  const getBoundariesForAddress = async () => {
    const { streetName, postalCode } = getValues();
    if (!postalCode) {
      // if there's no postal code inserted, do nothing
      return;
    }
    // start fetching data, show loading screen
    setIsFetchingAddressData(true);
    setHasStreetAddressWarning(false);
    try {
      const res = await getLocationFromOSM([streetName, postalCode]);
      setIsFetchingAddressData(false);
      setShouldUpdateAddress(false);
      if (!res) {
        setValue(LATITUDE_LABEL, undefined);
        setValue(LONGITUDE_LABEL, undefined);
        setCurrentPosition(undefined);
        setFoundNoResults(true);
        return;
      }

      setFoundNoResults(false);
      setHasAddressError(false);

      if (res.type === POSTALCODE_LABEL) {
        setHasStreetAddressWarning(true);
      }
      setCurrentCoordinatesFromMap(res?.coordinates);
    } catch (e) {
      setIsFetchingAddressData(false);
      setHasAddressError(true);
    }
  };

  const shouldDisableAddressSearch = (): boolean => {
    if (!streetName || !postalCode) {
      return true;
    }

    return !(streetName.length > 0 && postalCode.length > 0);
  };

  React.useEffect(() => {
    // if currentPosition hasn't loaded into place, populate it afterwards
    if (!currentPosition && latitude && longitude) {
      setCurrentCoordinatesFromMap([latitude, longitude]);
    }
  }, [currentPosition, latitude, longitude, setCurrentCoordinatesFromMap]);

  return (
    <FormSubSection>
      <FormSubDescription>
        <FormSectionSubHeader>{t("companyInfo.locationHeader")}</FormSectionSubHeader>
        {!isProductGroup && <p>{t("productMap.instructions")}</p>}
      </FormSubDescription>
      <FormSubSectionContent>
        <Row>
          <Col xs="12" md="8">
            <Form.Group controlId={STREETNAME_LABEL}>
              <Form.Label>{t("companyInfo.streetName")}*</Form.Label>
              <Form.Control
                type="text"
                name={STREETNAME_LABEL}
                disabled={isProductGroup}
                ref={register({
                  maxLength: {
                    value: MAX_ADDRESS_LENGTH,
                    message: t("validationErrors.textTooLong", { max: MAX_ADDRESS_LENGTH }),
                  },
                  validate: {
                    required: getRequiredValidator(publishMode, false, t),
                  },
                })}
                className={c({
                  "is-invalid": errors.streetName,
                })}
                onChange={() => setShouldUpdateAddress(true)}
                onBlur={() => setValue("streetName", capitalizeAddressName(streetName.trim()))}
              />
              {productType === ProductType.Experience && (
                <p className="color-gray-600 mt-1">{t("productInfo.addressGuide")}</p>
              )}
              {errors.streetName && <p className="text-danger">{errors.streetName.message}</p>}
              {hasStreetAddressWarning && (
                <div className="d-flex mt-1">
                  <div className="mr-1">
                    <Icon name="exclamation" color="orange" size="medium" />
                  </div>
                  <div>
                    <p className="text-small color-gray-700">
                      {t("validationErrors.addressNotFound")}
                    </p>
                  </div>
                </div>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="postcode">
              <Form.Label>{t("companyInfo.postalCode")}*</Form.Label>
              <Form.Control
                type="text"
                name={POSTALCODE_LABEL}
                disabled={isProductGroup}
                ref={register({
                  validate: validatePostalCode,
                })}
                className={c({
                  "is-invalid": errors.postalCode,
                })}
                onBlur={async () => {
                  if (!postalCode && postalCode.length === 0) {
                    return;
                  }
                  await fetchCityForPostalCode(getValues().postalCode);
                }}
                onChange={() => setShouldUpdateAddress(true)}
              />
              {errors.postalCode && <p className="text-danger">{t(errors.postalCode.message)}</p>}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <Form.Group controlId={CITY_LABEL}>
              <Form.Label>{t("companyInfo.city")}*</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name={CITY_LABEL}
                  disabled={isFetchingPostalCity || isProductGroup}
                  onBlur={() => setValue("city", capitalizeCityNames(city.trim()))}
                  ref={register({
                    maxLength: {
                      value: MAX_ADDRESS_LENGTH,
                      message: t("validationErrors.textTooLong", { max: MAX_ADDRESS_LENGTH }),
                    },
                    validate: {
                      required: getRequiredValidator(publishMode, false, t),
                    },
                    pattern: {
                      value: allowedCityFormat,
                      message: t("validationErrors.invalidCity"),
                    },
                  })}
                  className={c({
                    "is-invalid": errors.city,
                  })}
                />
                {isFetchingPostalCity && (
                  <InputGroup.Append>
                    <InputGroup.Text className="border-0">
                      <Spinner animation="border" size="sm" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>

              {errors.city && <p className="text-danger">{errors.city.message}</p>}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {companyAddress && (
            <Col>
              <Button
                variant="light"
                onClick={() => {
                  const valueUpdateOptions = {
                    shouldValidate: true,
                    shouldDirty: true,
                  };
                  setValue(STREETNAME_LABEL, companyAddress?.streetName, valueUpdateOptions);
                  setValue(POSTALCODE_LABEL, companyAddress?.postalCode, valueUpdateOptions);
                  setValue(CITY_LABEL, companyAddress?.city, valueUpdateOptions);
                  void getBoundariesForAddress();
                }}
              >
                {t("productInfo.fillWithCompanyDetails")}
              </Button>
            </Col>
          )}
          <Col>
            <Button
              disabled={shouldDisableAddressSearch() || isProductGroup}
              variant="light"
              onClick={async () => {
                await getBoundariesForAddress();
              }}
            >
              {t("productMap.getCoordinates")}
              {shouldUpdateAddress && <Icon name="sync" size="medium" className="ml-2" />}
            </Button>
          </Col>
        </Row>
        <Row className="mt-4">
          <ProductMap
            positionFromApi={currentPosition}
            setCurrentPosition={setCurrentCoordinatesFromMap}
            zoomLevel={currentPosition ? DEFAULT_ZOOM : NO_POSITION_ZOOM}
            isFetching={isFetchingAddressData}
            hasError={hasAddressError}
            setShowCoordinatesInstructionModal={setShowCoordinatesInstructionModal}
            foundNoResults={foundNoResults}
            disabled={isProductGroup}
          />
        </Row>
        {showCoordinatesInstructionModal && (
          <InfoModal
            headerText={t("productInfo.coordinateModalHeader")}
            onHide={() => setShowCoordinatesInstructionModal(false)}
            buttonText={t("productInfo.modalCloseButton")}
          >
            <ol className="font-heavy">
              <li>
                <ExternalLink href="https://www.google.com/maps">
                  {t("productInfo.coordinateModalItem1")}
                </ExternalLink>
              </li>
              <li>{t("productInfo.coordinateModalItem2")}</li>
              <li>{t("productInfo.coordinateModalItem3")}</li>
            </ol>
          </InfoModal>
        )}
        <Row className="mt-4">
          <Col xs="12" md="6">
            <Form.Group controlId={LATITUDE_LABEL}>
              <Form.Label>{t("productInfo.latitude")}</Form.Label>
              <Form.Control
                type="text"
                name={LATITUDE_LABEL}
                disabled={isProductGroup}
                ref={register({
                  required:
                    publishMode !== "draft" && publishMode !== "product-group"
                      ? "validationErrors.required"
                      : undefined,
                  validate: (value) => singleCoordValidation(value, longitude, "Latitude"),
                })}
                isInvalid={errors.latitude}
                onChange={(e) => {
                  setCurrentCoordinatesFromMap([e.target.value, longitude]);
                }}
              />
              <p className="color-gray-600 mt-1">{t("productInfo.latitudeGuide")}</p>
              {errors.latitude && <p className="text-danger">{t(errors.latitude.message)}</p>}
            </Form.Group>
          </Col>
          <Col xs="12" md="6">
            <Form.Group controlId={LONGITUDE_LABEL}>
              <Form.Label>{t("productInfo.longitude")}</Form.Label>
              <Form.Control
                type="text"
                name={LONGITUDE_LABEL}
                disabled={isProductGroup}
                ref={register({
                  required:
                    publishMode !== "draft" && publishMode !== "product-group"
                      ? "validationErrors.required"
                      : undefined,
                  validate: (value) => singleCoordValidation(value, latitude, "Longitude"),
                })}
                isInvalid={errors.longitude}
                onChange={(e) => {
                  setCurrentCoordinatesFromMap([latitude, e.target.value]);
                }}
              />
              <p className="color-gray-600 mt-1">{t("productInfo.longitudeGuide")}</p>
              {errors.longitude && <p className="text-danger">{t(errors.longitude.message)}</p>}
            </Form.Group>
          </Col>
        </Row>
      </FormSubSectionContent>
    </FormSubSection>
  );
};

export default LocationDetailsSection;
