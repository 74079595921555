import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  HasuradbProduct,
  ProductAvailableDates,
  ProductPricing,
} from "../../graphqlQueries/getProduct";
import {
  Certificate,
  Month,
  PricingType,
  ProductType,
  TransportationAvailabilityEnum,
} from "../../types";
import {
  CDN_STF_LOGO,
  CDN_WELCOME_CYCLIST_LOGO,
  MONTHS_FULL_YEAR,
  MONTHS_HALF_YEAR,
} from "../../utils/constants";
import { capitalize } from "../../utils/functions";
import {
  getCapacityOrGroupSizeText,
  getMonthKey,
  getPricingTexts,
  getPricingUnitTranslation,
  getProductTypeHeaderKey,
  getTargetGroupKey,
} from "../../utils/localizationUtils";
import Tag from "../Tag";
import { NotAvailable } from "./NotAvailable";
import ViewProductBusinessHours from "./ViewProductBusinessHours";
import ViewProductHeader from "./ViewProductHeader";
import ViewProductSectionHeader from "./ViewProductSectionHeader";
import { CertificateLogo } from "./CertificateLogo";

type ViewProductInformationProps = {
  product: HasuradbProduct;
};

const ViewProductInformation = ({ product }: ViewProductInformationProps) => {
  const { t } = useTranslation();

  const tags = product.productTags.map((tag) => tag.tag);
  const targetGroups = product.productTargetGroups.map((group) => group.targetGroupId);

  const pricing = product.productPricings[0];

  const commaSeparatedTargetGroups =
    targetGroups.length > 0
      ? targetGroups
          .map((groupId) => [t(getTargetGroupKey(groupId)), ", "])
          .flat()
          .slice(0, -1)
          .map((el, i) => <span key={i}>{el}</span>)
      : [];

  const availableDates = product.productAvailabilities[0];
  const transportationAvailability = availableDates.transportationAvailability;

  const monthsFromEnum = Object.values(Month).map((month) => month);

  const openMonths = product.productAvailableMonths
    ? product.productAvailableMonths.map((availableMonth) => ({ month: availableMonth.month }))
    : [];

  const openMonthsSorted = openMonths.sort(
    (a, b) => monthsFromEnum.indexOf(a.month) - monthsFromEnum.indexOf(b.month)
  );

  const capacity = product.productCapacities[0];
  const type = product.type;
  const capacityValues = getCapacityOrGroupSizeText(type, capacity);

  const canHaveCapacity =
    type === ProductType.Experience ||
    type === ProductType.Venue ||
    type === ProductType.Transportation;

  const capacityHeaderText = () => {
    if (type === ProductType.Experience) {
      return "viewProduct.groupSizeHeader";
    }

    if (type === ProductType.Transportation) {
      return "productInfo.passengerCapacityHeader";
    }

    return "viewProduct.capacityHeader";
  };

  const canHaveDuration = type === ProductType.Experience;
  const canHaveDates = type === ProductType.Event;
  const canHaveAvailabilityLanguages = type === ProductType.Experience;

  const pricingValues =
    pricing && getPricingTexts(pricing.fromPrice, pricing.toPrice, pricing.pricingType);

  const showPricingUnit = (pricing: ProductPricing): boolean => {
    return !(
      (pricing.toPrice === 0 && pricing.fromPrice === 0) ||
      pricing.pricingType === PricingType.Free
    );
  };

  const accessible = product.accessible;
  const stfCertified = !!product.productCertificates.find((c) => c.certificate === Certificate.STF);
  const welcomeCyclistCertified = !!product.productCertificates.find(
    (c) => c.certificate === Certificate.WelcomeCyclist
  );

  const displayAvailabilityDates = ({ startDate, endDate }: ProductAvailableDates) => {
    if (startDate === endDate) {
      return moment(startDate).format("D.M.YYYY");
    }

    return `${moment(startDate).format("D.M.YYYY")} - ${moment(endDate).format("D.M.YYYY")}`;
  };

  const AccessibleValue = () => {
    if (accessible === null) {
      return <NotAvailable />;
    }

    return <div className="pb-2">{t(accessible ? "common.yes" : "common.no")}</div>;
  };

  return (
    <>
      <ViewProductSectionHeader className="mb-4">
        {t("viewProduct.productInformation")}
      </ViewProductSectionHeader>
      <Row>
        <Col xs="12" sm="6" md="6" lg="12" xl="6">
          <ViewProductHeader>{t("productInfo.sustainabilityCertified")}</ViewProductHeader>
          {stfCertified ? (
            <CertificateLogo
              url={CDN_STF_LOGO}
              size="200"
              altText={t("productInfo.stfLogoAltText")}
            />
          ) : (
            <NotAvailable />
          )}
          <ViewProductHeader>{t("productInfo.accessibleHeader")}</ViewProductHeader>
          <AccessibleValue />
          <ViewProductHeader>{t("productInfo.productType")}</ViewProductHeader>
          <div className="pb-2">
            <Tag>{t(getProductTypeHeaderKey(product.type))}</Tag>
          </div>
          {commaSeparatedTargetGroups.length > 0 && (
            <>
              <ViewProductHeader>{t("viewProduct.targetGroupsHeader")}</ViewProductHeader>
              <p>{commaSeparatedTargetGroups}</p>
            </>
          )}
          {canHaveCapacity && (
            <>
              <ViewProductHeader>{t(capacityHeaderText())}</ViewProductHeader>
              {capacity && capacityValues ? (
                <>{t(capacityValues.text, { ...capacityValues.values })}</>
              ) : (
                <NotAvailable />
              )}
            </>
          )}
          <ViewProductHeader>{t("viewProduct.pricingHeader")}</ViewProductHeader>
          {pricing && pricing.pricingType !== PricingType.Unknown && pricingValues ? (
            <p>
              {t(pricingValues.text, { ...pricingValues.values })}{" "}
              {showPricingUnit(pricing) && t(getPricingUnitTranslation(pricing.pricingUnit))}
            </p>
          ) : (
            <NotAvailable />
          )}

          {canHaveDuration && (
            <div>
              <ViewProductHeader>{t("productInfo.duration")}</ViewProductHeader>
              {product.productDuration[0]?.minutes ||
              product.productDuration[0]?.hours ||
              product.productDuration[0]?.days ||
              product.productDuration[0]?.weeks ? (
                <p>
                  {product.productDuration[0].hours !== 0 && product.productDuration[0].hours && (
                    <span>
                      {t("viewProduct.durationTypeHours", {
                        count: product.productDuration[0].hours,
                      })}{" "}
                    </span>
                  )}
                  {product.productDuration[0].minutes !== 0 && product.productDuration[0].minutes && (
                    <span>
                      {t("viewProduct.durationTypeMinutes", {
                        count: product.productDuration[0].minutes,
                      })}{" "}
                    </span>
                  )}
                  {product.productDuration[0].days !== 0 && product.productDuration[0].days && (
                    <span>
                      {t("viewProduct.durationTypeDays", {
                        count: product.productDuration[0].days,
                      })}{" "}
                    </span>
                  )}
                  {product.productDuration[0].weeks !== 0 && product.productDuration[0].weeks && (
                    <span>
                      {t("viewProduct.durationTypeWeeks", {
                        count: product.productDuration[0].weeks,
                      })}
                    </span>
                  )}
                </p>
              ) : (
                <NotAvailable />
              )}
            </div>
          )}
          {canHaveAvailabilityLanguages && (
            <div>
              <ViewProductHeader>
                {t("productInfo.languageAvailabilitiesSubHeader")}
              </ViewProductHeader>
              {product.productAvailabilityLanguages.length > 0 ? (
                <>
                  {product.productAvailabilityLanguages.map((tag, i) => (
                    <Tag key={i}>{capitalize(t(`languages.${tag.language}`))}</Tag>
                  ))}
                </>
              ) : (
                <NotAvailable />
              )}
            </div>
          )}
          {canHaveDates && (
            <div>
              <ViewProductHeader>{t("viewProduct.eventDatesHeader")}</ViewProductHeader>
              {availableDates && availableDates.startDate ? (
                <p>{displayAvailabilityDates(availableDates)}</p>
              ) : (
                <NotAvailable />
              )}
            </div>
          )}
        </Col>
        <Col>
          <ViewProductHeader>{t("productInfo.welcomeCyclistCertified")}</ViewProductHeader>
          {welcomeCyclistCertified ? (
            <CertificateLogo
              url={CDN_WELCOME_CYCLIST_LOGO}
              size="120"
              altText={t("productInfo.welcomeCyclistLogoAltText")}
            />
          ) : (
            <NotAvailable />
          )}
          <ViewProductHeader>{t("viewProduct.categoriesHeader")}</ViewProductHeader>
          <div className="pb-2">
            {tags.length > 0 ? (
              tags.map((tag, i) => <Tag key={i}>{t(`tags.${tag}`)}</Tag>)
            ) : (
              <p className="text-muted">{t("viewProduct.noCategories")}</p>
            )}
          </div>
          {product.type === ProductType.Transportation && (
            <>
              <div>
                <ViewProductHeader>{t("productInfo.availabilityHeader")}</ViewProductHeader>
                {transportationAvailability ? (
                  <p>
                    {t(
                      `productInfo.transportationAvailability${Object.values(
                        TransportationAvailabilityEnum
                      ).findIndex((it) => it === transportationAvailability)}`
                    )}
                  </p>
                ) : (
                  <NotAvailable />
                )}
              </div>
            </>
          )}
          {openMonths && openMonths.length !== 0 && (
            <>
              <ViewProductHeader>{t("viewProduct.seasonsHeader")}</ViewProductHeader>
              <div
                className={`pb-2 ${
                  openMonths.length > MONTHS_HALF_YEAR ? "columns-2 d-inline-block" : ""
                }`}
              >
                {openMonths.length === MONTHS_FULL_YEAR ? (
                  <p>{t("productInfo.availabilityAllYear")}</p>
                ) : (
                  <div className="pr-3">
                    {openMonthsSorted.map((month, i) => (
                      <div key={i}>{t(getMonthKey(month.month))}</div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
          <ViewProductBusinessHours businessHours={product.businessHours} />
        </Col>
      </Row>
    </>
  );
};

export default ViewProductInformation;
