import { gql } from "@apollo/client";

export const removeCurationMutation = gql`
  mutation removeCuration(
    $productId: uuid!
    $commentText: String!
    $companyId: uuid!
    $userName: String!
  ) {
    insertProductCommentsOne(
      object: {
        productId: $productId
        comment: $commentText
        createdByCompanyId: $companyId
        createdByName: $userName
        recipientRole: tc
      }
    ) {
      id
    }
    updateProductCuration(
      where: { product: { id: { _eq: $productId } } }
      _set: {
        curationRevoked: "now()"
        productCurationStatus: null
        curationStatus: null
        updatedByCompany: $companyId
      }
    ) {
      returning {
        id
        curationRevoked
        productCurationStatus
        curationStatus
        updatedByCompany
      }
    }
    updateProductByPk(
      pk_columns: { id: $productId }
      _set: { curationEnabled: false, status: draft }
    ) {
      id
      curationEnabled
      status
    }
  }
`;

export default removeCurationMutation;

export interface RemoveCurationAndCommentVariables {
  productId: string;
  commentText: string;
  companyId: string;
  userName: string;
}
