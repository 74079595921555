import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

const Header = styled.header`
  margin-bottom: 2px;
`;

type ProductListHeaderProps = {
  onNewProductClick: () => void;
};

const ProductListHeader = ({ onNewProductClick }: ProductListHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Header className="d-flex align-items-center justify-content-between bg-white px-3 px-xl-4 py-3">
      <h1 className="h4-size m-0">{t("products.header")}</h1>
      <Button
        onClick={() => {
          onNewProductClick();
        }}
      >
        + {t("products.newProductCta")}
      </Button>
    </Header>
  );
};

export default ProductListHeader;
