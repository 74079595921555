import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";
import { ProductPublishMode, ProductTargetGroup } from "../../types";
import React, { useCallback } from "react";

import Checkbox from "../Checkbox";
import { Form } from "react-bootstrap";
import InputContainer from "./InputContainer";
import { getTargetGroupKey } from "../../utils/localizationUtils";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const targetGroups = [ProductTargetGroup.BTOC, ProductTargetGroup.BTOB].map((group) => ({
  value: group,
  label: getTargetGroupKey(group),
}));

type TargetGroupSectionProps = {
  publishMode: ProductPublishMode;
  isProductGroup: boolean;
};

const TargetGroupSection = ({ publishMode, isProductGroup }: TargetGroupSectionProps) => {
  const { t } = useTranslation();
  const { register, errors, trigger, getValues } = useFormContext();

  const validateGroups = () => {
    if (publishMode === "draft") {
      return true;
    }
    const values = getValues();
    const targetGroups = values.targetGroups;
    const invalid = !targetGroups || targetGroups.length === 0;
    return invalid ? "productInfo.targetGroupError" : undefined;
  };

  const handleChange = useCallback((evt) => trigger(evt.target.name as string), [trigger]);

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader>{t("productInfo.targetGroupHeader")}</FormSectionHeader>
        {!isProductGroup && <p>{t("productInfo.targetGroupDescription")}</p>}
      </FormDescription>
      <FormSectionContent>
        <Form.Label>{t("productInfo.targetGroupLabel")} *</Form.Label>
        <InputContainer hasError={!!errors.targetGroups}>
          {targetGroups.map((targetGroup) => (
            <Checkbox
              disabled={isProductGroup}
              id={targetGroup.value}
              value={targetGroup.value}
              key={targetGroup.value}
              name="targetGroups"
              onChange={handleChange}
              label={t(targetGroup.label)}
              ref={register({
                validate: validateGroups,
              })}
            />
          ))}
        </InputContainer>
        {errors.targetGroups && (
          <p className="text-danger mt-2">{t(errors.targetGroups.message)}</p>
        )}
      </FormSectionContent>
    </FormSection>
  );
};

export default TargetGroupSection;
