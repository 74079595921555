import React from "react";
import { useTranslation } from "react-i18next";

import { HasuraProductVideo } from "../../graphqlQueries/getProduct";
import ExternalLink from "../ExternalLink";
import { NotAvailable } from "./NotAvailable";
import ViewProductSectionHeader from "./ViewProductSectionHeader";

type ViewProductVideosProps = {
  videos: HasuraProductVideo[];
};

const ViewProductVideos = ({ videos }: ViewProductVideosProps) => {
  const { t } = useTranslation();
  return (
    <>
      <ViewProductSectionHeader className="mb-4">
        {t("viewProduct.videosHeader")}
      </ViewProductSectionHeader>
      {videos.length > 0 ? (
        videos.map((video) => (
          <ExternalLink
            href={video.verifiedVideoLink?.url}
            key={video.id}
            className="d-block mb-3"
            iconPosition="left"
          >
            {video.title}
          </ExternalLink>
        ))
      ) : (
        <NotAvailable />
      )}
    </>
  );
};

export default ViewProductVideos;
