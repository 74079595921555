import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { HasuradbDmoArea } from "../../graphqlQueries/getCuratorAreas";
import {
  CurationStatusesFilter,
  ExternalSource,
  FilterMode,
  FilterType,
  Language,
  ProductCategory,
  ProductFilters,
  ProductType,
  SelectOption,
  StatusFilterOption,
} from "../../types";
import keycloak from "../../Keycloak";
import { ResultsByFilter } from "../../types/curatorProductTypes";
import { useDebouncedEffect } from "../../utils/debounce";
import Checkbox from "../Checkbox";
import CitySelect from "../CitySelect";
import ExternalLink from "../ExternalLink";
import Icon from "../Icon";
import IconInput from "../IconInput";
import ProductCategorySelect from "../ProductCategorySelect";
import RadioButton from "../RadioButton";
import Separator from "../Separator";
import {
  externalSourceOptions,
  monthOptions,
  productNotificationOptions,
  productTypeOptions,
  targetGroupsOptions,
} from "../../utils/filterOptions";
import { DatahubSelect } from "../DatahubSelect";
import { getProductTypeHeaderKey } from "../../utils/localizationUtils";
import { capitalize, isVisitFinlandAdmin } from "../../utils/functions";
import { removeFiltersByMode } from "../../utils/filterUtils";
import { LANGUAGES } from "../../utils/constants";

type FiltersProps = {
  initialFilters: ProductFilters;
  filters: ProductFilters;
  onFilterChange: (filters: ProductFilters) => void;
  resultsByFilter?: ResultsByFilter;
  filterMode: FilterMode;
  filterType: FilterType;
  statusFilterOptions?: StatusFilterOption[];
  dmoAreas?: HasuradbDmoArea[];
};

const DEBOUNCE_DELAY = 400;

const Filters = ({
  initialFilters,
  filters,
  onFilterChange,
  resultsByFilter,
  filterType,
  filterMode,
  statusFilterOptions,
  dmoAreas,
}: FiltersProps) => {
  const { t } = useTranslation();
  const selectOption = (v: string) => ({ value: v, label: v });

  const [search, setSearch] = useState<string>(filters.search || "");
  const [cities, setCities] = useState<SelectOption[]>(
    filters.cities?.map<SelectOption>((it) => ({
      value: it,
      label: dmoAreas?.find((cities) => cities.curationArea.id === it)?.curationArea.city || "",
    })) || []
  );

  const [productNotification, setProductNotification] = useState<SelectOption | null>(() => {
    return (
      productNotificationOptions.find(({ value }) => value === filters.productNotification) || null
    );
  });

  const [externalSource, setExternalSource] = useState<ExternalSource | null>(() => {
    return externalSourceOptions.find(({ value }) => value === filters.externalSource) || null;
  });

  const [productType, setProductType] = useState<SelectOption | null>(
    filters.productType ? selectOption(filters.productType) : null
  );

  const [months, setMonths] = useState<SelectOption[]>(
    filters.months?.map<SelectOption>((it) => selectOption(it)) || []
  );
  const [categories, setCategories] = useState<ProductCategory[]>(
    filters.productCategories?.map<SelectOption>((it) => selectOption(it)) || []
  );
  const [targetGroups, setTargetGroups] = useState<SelectOption[]>(
    filters.targetGroups?.map<SelectOption>((it) => selectOption(it)) || []
  );
  const [curationStatus, setCurationStatus] = useState(filters.curationStatus);
  const [counts, setCounts] = useState<ResultsByFilter>();
  const [withoutCurator, setWithoutCurator] = useState(filters.withoutCurator);
  const [accessible, setAccessible] = useState(filters.accessibleFilter);
  const [stfCertified, setStfCertified] = useState(filters.stfCertifiedFilter);
  const [byAllLanguages, setByAllLanguages] = useState(filters.byAllLanguagesFilter);
  const dmoCitiesOptions = dmoAreas?.map((city) => ({
    value: city.curationArea.id,
    label: city.curationArea.city,
  }));
  const [selectedLanguages, setSelectedLanguages] = useState<Language[]>(filters.languages || []);

  useEffect(() => {
    if (resultsByFilter) {
      setCounts(resultsByFilter);
    }
  }, [resultsByFilter]);

  useDebouncedEffect(() => onFilterChange({ ...filters, search }), DEBOUNCE_DELAY, [search]);

  const getGroupLabel = ({ label, value }: SelectOption) => {
    const translated = targetGroupsOptions.find((o) => o.value === value)?.labelKey;
    return translated ? t(translated) : label.toLocaleUpperCase();
  };

  const getMonthLabel = (value: string) => {
    const label = monthOptions.find((o) => o.value === value)?.label;
    return label ? t(label) : "";
  };

  const resetFilters = () => {
    // CurationStatus filter shouldn't change
    setCurationStatus(filters.curationStatus);

    // NOTE: These values dont respect filters or initialFilters values
    setSearch("");
    setProductType(null);
    setCategories([]);
    setMonths([]);
    setSelectedLanguages([]);
    setTargetGroups([]);
    setCities([]);
    setProductNotification(null);
    setExternalSource(null);

    setByAllLanguages(initialFilters.byAllLanguagesFilter);
    setAccessible(initialFilters.accessibleFilter);
    setStfCertified(initialFilters.stfCertifiedFilter);
    if (filterMode === FilterMode.VFAdminCuratorWorkspace) {
      setWithoutCurator(initialFilters.withoutCurator);
    }

    onFilterChange({
      ...initialFilters,
      curationStatus: filters.curationStatus,
      productNotification: undefined,
    });

    removeFiltersByMode(filterMode);
  };

  const showDmoCitySelect =
    filterMode === FilterMode.CuratorWorkspace && dmoCitiesOptions && dmoCitiesOptions?.length > 1;
  const showAllCitiesSelect =
    filterMode === FilterMode.VFAdminCuratorWorkspace ||
    filterMode === FilterMode.PublishedProducts;
  const showProductNotificationsSelect = filterMode !== FilterMode.PublishedProducts;
  const showExternalFilter =
    filterMode === FilterMode.VFAdminCuratorWorkspace ||
    (isVisitFinlandAdmin(keycloak) && filterMode === FilterMode.PublishedProducts);

  return (
    <div className="p-3 pr-4 bg-gray-100">
      <Form.Group controlId="search">
        <Form.Label>{t("curatorWorkspace.searchFilterLabel")}</Form.Label>
        <IconInput
          icon={<Icon name="search" size="medium" style={{ transform: "rotateY(180deg)" }} />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
          iconPosition="left"
        />
      </Form.Group>
      <Button
        onClick={() => {
          resetFilters();
        }}
        className="mb-2"
        variant="ghost"
      >
        {t("curatorWorkspace.clearAllLabel")}
      </Button>
      {statusFilterOptions && (
        <Form.Group controlId="status">
          <Form.Label>{t("curatorWorkspace.statusFilterLabel")}</Form.Label>
          <div>
            {statusFilterOptions.map((status) => (
              <RadioButton
                checked={curationStatus === status.value}
                id={`${filterType}_${status.value}`}
                name="productCurationStatus"
                value={status.value}
                label={
                  <>
                    <span>{[t(status.label)]}</span>
                    {counts && <span> ({counts[status.value]})</span>}
                  </>
                }
                key={status.value}
                onChange={(event) => {
                  const newStatus = event.target.value;
                  const isEnumValue = Object.values(CurationStatusesFilter).some(
                    (val) => val === newStatus
                  );
                  if (isEnumValue) {
                    const enumStatus = newStatus as CurationStatusesFilter;
                    setCurationStatus(enumStatus);
                    onFilterChange({
                      ...filters,
                      curationStatus: enumStatus,
                    });
                  }
                }}
              />
            ))}
          </div>
        </Form.Group>
      )}
      {showProductNotificationsSelect && (
        <Form.Group controlId="productNotification">
          <Form.Label>{t("curatorWorkspace.productNotificationLabel")}</Form.Label>
          <DatahubSelect
            value={
              productNotification && {
                value: productNotification.value,
                label: t(productNotification.label),
              }
            }
            options={productNotificationOptions.map<SelectOption>(({ value, label }) => ({
              value,
              label: t(label),
            }))}
            onChange={(option) => {
              const selected =
                option && productNotificationOptions.find((o) => o.value === option.value);

              setProductNotification(option ? { ...option, label: selected?.label || "" } : null);

              onFilterChange({
                ...filters,
                productNotification: option ? selected?.value : undefined,
              });
            }}
          />
        </Form.Group>
      )}
      {filterMode === FilterMode.VFAdminCuratorWorkspace && (
        <div>
          <Separator topMarginRem={1} bottomMarginRem={1} />
          <Checkbox
            id="withoutCurator"
            value="withoutCurator"
            checked={withoutCurator}
            key="withoutCurator"
            label={t("curatorWorkspace.withoutCurator")}
            name="withoutCurator"
            onChange={(event) => {
              setWithoutCurator(event.target.checked);
              onFilterChange({
                ...filters,
                withoutCurator: event.target.checked,
              });
            }}
          />
        </div>
      )}

      {showDmoCitySelect && (
        <Form.Group controlId="city">
          <Form.Label>{t("curatorWorkspace.cityLabel")}</Form.Label>
          <DatahubSelect
            value={cities}
            options={dmoCitiesOptions}
            isMulti
            onChange={(selected) => {
              const selectedCities = selected as SelectOption[];
              setCities(selectedCities);
              const noCities = !selectedCities || selectedCities.length === 0;

              onFilterChange({
                ...filters,
                cities: noCities ? undefined : selectedCities?.map((city) => city.value),
              });
            }}
          />
        </Form.Group>
      )}
      {showAllCitiesSelect && (
        <Form.Group controlId="city">
          <Form.Label>{t("curatorWorkspace.cityLabel")}</Form.Label>
          <CitySelect
            values={cities || []}
            onChange={(selected) => {
              const selectedCities = selected as SelectOption[];
              setCities(selectedCities);
              const noCities = !selectedCities || selectedCities.length === 0;
              onFilterChange({
                ...filters,
                cities: noCities ? undefined : selectedCities?.map((city) => city.value),
              });
            }}
          />
        </Form.Group>
      )}
      <Form.Group controlId="productType">
        <Form.Label>{t("curatorWorkspace.productTypeLabel")}</Form.Label>
        <DatahubSelect
          options={productTypeOptions.map<SelectOption>(({ value, labelKey }) => ({
            value,
            label: t(labelKey),
          }))}
          value={
            productType && {
              value: productType.value,
              label: t(getProductTypeHeaderKey(productType.value as ProductType)),
            }
          }
          onChange={(option) => {
            const selected = option as SelectOption;
            setProductType(selected);

            onFilterChange({
              ...filters,
              productType: option ? selected.value : undefined,
            });
          }}
        />
      </Form.Group>
      <Form.Group controlId="productCategories">
        <Form.Label>{t("curatorWorkspace.productCategoryLabel")}</Form.Label>
        <ProductCategorySelect
          values={categories}
          onChange={(selectedOptions) => {
            setCategories(selectedOptions);
            const noCategories = !selectedOptions || selectedOptions.length === 0;

            onFilterChange({
              ...filters,
              productCategories: noCategories
                ? undefined
                : selectedOptions.map((option) => option.value),
            });
          }}
        />
      </Form.Group>
      {showExternalFilter && (
        <Form.Group controlId="externalSource">
          <Form.Label>{t("curatorWorkspace.externalSource")}</Form.Label>
          <DatahubSelect
            value={
              externalSource && {
                value: externalSource.value,
                label: externalSource.label,
              }
            }
            options={externalSourceOptions.map<ExternalSource>(({ value, label }) => ({
              value,
              label,
            }))}
            onChange={(option) => {
              setExternalSource(option || null);
              onFilterChange({
                ...filters,
                externalSource: option?.value || undefined,
              });
            }}
          />
        </Form.Group>
      )}
      <Form.Group controlId="months">
        <Form.Label>{t("curatorWorkspace.monthLabel")}</Form.Label>
        <DatahubSelect
          isMulti
          options={monthOptions.map<SelectOption>(({ value, label }) => ({
            value,
            label: t(label),
          }))}
          value={months.map((m) => ({
            value: m.value,
            label: getMonthLabel(m.value),
          }))}
          onChange={(selected) => {
            const options = selected as SelectOption[];
            setMonths(options);

            const noOptions = !options || options.length === 0;
            onFilterChange({
              ...filters,
              months: noOptions ? undefined : options.map((option) => option.value),
            });
          }}
          closeMenuOnSelect={false}
        />
      </Form.Group>
      <Form.Group controlId="targetGroups">
        <Form.Label>{t("curatorWorkspace.targetGroupLabel")}</Form.Label>
        <DatahubSelect
          isMulti
          closeMenuOnSelect={false}
          options={targetGroupsOptions.map<SelectOption>(({ value, labelKey }) => ({
            value,
            label: t(labelKey),
          }))}
          value={targetGroups.map((g) => ({
            value: g.value,
            label: getGroupLabel(g),
          }))}
          onChange={(selected) => {
            const options = selected as SelectOption[];
            setTargetGroups(options);

            const noOptions = !options || options.length === 0;
            onFilterChange({
              ...filters,
              targetGroups: noOptions ? undefined : options.map((option) => option.value),
            });
          }}
        />
      </Form.Group>
      <Form.Group controlId="languages" className="mb-1">
        <Form.Label>{t("curatorWorkspace.languageLabel")}</Form.Label>
        <DatahubSelect
          closeMenuOnSelect={false}
          options={LANGUAGES.map((lang) => ({
            value: lang,
            label: capitalize(t(`languages.${lang}`)) as Language,
          })).sort((a, b) => a.label.localeCompare(b.label))}
          value={selectedLanguages.map((l) => ({
            value: l,
            label: capitalize(t(`languages.${l}`)),
          }))}
          onChange={(options) => {
            const selectOptions = (options as SelectOption[]) || [];
            const languages = selectOptions.map((o) => o.value) as Language[];
            setSelectedLanguages(languages);
            onFilterChange({
              ...filters,
              languages,
            });
          }}
          isMulti
        />
      </Form.Group>
      <Form.Group>
        <Checkbox
          id="byAllLanguagesFilter"
          value="byAllLanguagesFilter"
          checked={byAllLanguages}
          key="byAllLanguagesFilter"
          label={t("productInfo.byAllLanguages")}
          name="byAllLanguagesFilter"
          onChange={(event) => {
            setByAllLanguages(event.target.checked);
            onFilterChange({
              ...filters,
              byAllLanguagesFilter: event.target.checked,
            });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-0">
        <Checkbox
          id="stfCertifiedFilter"
          value="stfCertifiedFilter"
          checked={stfCertified}
          key="stfCertifiedFilter"
          label={t("productInfo.sustainabilitySubHeader")}
          name="stfCertifiedFilter"
          onChange={(event) => {
            setStfCertified(event.target.checked);
            onFilterChange({
              ...filters,
              stfCertifiedFilter: event.target.checked,
            });
          }}
        />
        {filterMode !== FilterMode.PublishedProducts && (
          <ExternalLink className="mr-4" href={t("curatorWorkspace.stfCertificateUrl")}>
            {t("curatorWorkspace.stfCertificateLink")}
          </ExternalLink>
        )}
      </Form.Group>
      <Form.Group>
        <Checkbox
          id="accessibleFilter"
          value="accessibleFilter"
          checked={accessible}
          key="accessibleFilter"
          label={t("productInfo.accessibleHeader")}
          name="accessibleFilter"
          onChange={(event) => {
            setAccessible(event.target.checked);
            onFilterChange({
              ...filters,
              accessibleFilter: event.target.checked,
            });
          }}
        />
      </Form.Group>
    </div>
  );
};

export default Filters;
