import { Button, Spinner } from "react-bootstrap";
import {
  CurationStatus,
  MyProductFormValues,
  ProductCurationStatus,
  ProductPublishMode,
  ProductPublishResult,
  ProductStatus,
  UserRole,
} from "../../types";
import { DocumentNode, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FormProvider, useForm } from "react-hook-form";
import { Prompt, useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import buildInsertProductMutation, {
  HasuraInsertDraftVariables,
  HasuraInsertProductResult,
  HasuraInsertProductVariables,
} from "../../graphqlQueries/insertProduct";
import getCompanyData, {
  GetSingleCompanyInfoVariables,
  HasuraCompanyInformationResult,
} from "../../graphqlQueries/getSingleCompanyInfo";
import {
  getInsertPublishedVariables,
  getUpdateOpeningHoursVariables,
} from "../../utils/productFormUtils/mapProductToHasura";
import getProductQuery, { GetProductQueryData } from "../../graphqlQueries/getProduct";
import unpublishProductMutation, {
  UnpublishProductResult,
  UnpublishProductVariables,
} from "../../graphqlQueries/unpublishProduct";
import updateOpeningHoursMutation, {
  UpdateOpeningHoursResult,
  UpdateOpeningHoursVariables,
} from "../../graphqlQueries/updateOpeningHours";

import { ErrorModal } from "../DataHubModal";
import LastEdit from "../LastEdit";
import MyProductFormToolbar from "./MyProductFormToolbar";
import ProductForm from "./ProductForm";
import PublishModal from "./PublishModal";
import UpdateProductErrorModal from "./UpdateProductErrorModal";
import { getHasuraRoleContext } from "../../utils/functions";
import { getInsertDraftVariables } from "../../utils/productFormUtils/mapDraftProductToHasura";
import { hasBrokenLink, isDirty } from "../../utils/productFormUtils/productUtils";
import { mapDbProductToFormValues } from "../../utils/mapDbProductToFormValues";
import { searchAndSetErrorForInvalidUrl } from "../../utils/formUtils";
import { useTranslation } from "react-i18next";
import { useUpdateProduct } from "../hooks/useUpdateProduct";
import { useUpdateSocialMedias } from "../hooks/useUpdateSocialMedias";

type ParamsType = {
  productId?: string;
};

type LastEditInformation = {
  dateStr: string;
  editorName: string;
};

type MyProductFormProps = {
  companyId: string;
  refetchQuery: DocumentNode;
  setShowNotification?: (msg: string) => void;
};

const PRODUCT_FORM_NAME = "product-form";

const manageProductsContext = getHasuraRoleContext(UserRole.ManageProducts);

const MyProductForm = ({ companyId, refetchQuery, setShowNotification }: MyProductFormProps) => {
  const { productId: productIdParams } = useParams<ParamsType>();

  const [productId, setProductId] = useState(productIdParams);
  const [curationStatus, setCurationStatus] = useState<CurationStatus | null>(null);
  const [productCurationStatus, setProductCurationStatus] = useState<ProductCurationStatus | null>(
    null
  );
  const [publishMode, setPublishMode] = useState<ProductPublishMode>("publish");
  const [publishResult, setPublishResult] = useState<ProductPublishResult>("noresult");
  const [editMode, setEditMode] = useState(!!productId);
  const [showUnpublishError, setShowUnpublishError] = useState<boolean>(false);
  const [lastEditInfo, setLastEditInfo] = useState<LastEditInformation | undefined>();
  const [nextUrl, setNextUrl] = useState<string>();

  const history = useHistory();

  useEffect(() => {
    if (nextUrl) {
      history.push(nextUrl);
    }
  }, [nextUrl, productId, history]);

  const { t } = useTranslation();

  const hookFormMethods = useForm<MyProductFormValues>();
  const { reset, formState, watch, getValues, setError } = hookFormMethods;

  const { data: companyData } = useQuery<
    {
      companyByPk: HasuraCompanyInformationResult;
    },
    GetSingleCompanyInfoVariables
  >(getCompanyData, {
    variables: { id: companyId },
    context: getHasuraRoleContext(UserRole.ManageCompany),
    fetchPolicy: "network-only",
    nextFetchPolicy: "standby",
  });

  const companyInfo = companyData?.companyByPk;

  const [loadProductData, { called, data: productDataResult }] = useLazyQuery<GetProductQueryData>(
    getProductQuery(UserRole.ManageProducts),
    {
      variables: { id: productIdParams },
      context: manageProductsContext,
      fetchPolicy: "network-only",
      nextFetchPolicy: "standby",
    }
  );

  const productType = watch("productType");
  const useCompanyHours = watch("companyHoursEnabled", false);

  const [insertProduct] = useMutation<
    HasuraInsertProductResult,
    HasuraInsertProductVariables | HasuraInsertDraftVariables
  >(buildInsertProductMutation(productType, useCompanyHours), {
    context: manageProductsContext,
    refetchQueries: refetchQuery ? [refetchQuery] : undefined,
  });

  const [updateOpeningHours] = useMutation<UpdateOpeningHoursResult, UpdateOpeningHoursVariables>(
    updateOpeningHoursMutation,
    { context: manageProductsContext }
  );

  const [unpublishProduct, { loading: unpublishLoading }] = useMutation<
    UnpublishProductResult,
    UnpublishProductVariables
  >(unpublishProductMutation, { context: manageProductsContext });

  const { updateProduct } = useUpdateProduct();
  const { updateSocialMedia } = useUpdateSocialMedias();

  useEffect(() => {
    if (productIdParams && !called) {
      loadProductData();
    }
  }, [productIdParams, called, loadProductData]);

  useEffect(() => {
    const productData = productDataResult?.product;
    if (productData) {
      const formValues = mapDbProductToFormValues(productData);
      reset(formValues);

      const productCurations = productData.productCurations && productData.productCurations[0];
      setCurationStatus(productCurations?.curationStatus || CurationStatus.Pending);
      const currentProductCurationStatus = productCurations?.productCurationStatus;
      setProductCurationStatus(currentProductCurationStatus || ProductCurationStatus.New);
      setEditMode(productData.status === ProductStatus.Published);
      setPublishMode(productData.status === ProductStatus.Published ? "update" : "publish");
      setLastEditInfo({
        dateStr: productData.updatedAt,
        editorName: productData?.updatedByUser?.name ?? "",
      });

      if (hasBrokenLink(productData)) {
        searchAndSetErrorForInvalidUrl(productData, setError);
      }
    }
  }, [productDataResult, reset, setError]);

  const onSubmit = async (formValues: MyProductFormValues) => {
    setPublishResult("noresult");
    const createNewProduct = !productId;

    const insertMethod = async () => {
      try {
        const socialMediaId = await updateSocialMedia({
          companySocialMediaId: formValues.companySocialMediaId,
          socialMediaId: formValues.socialMediaId,
          productSocialMediaLinks: formValues.productSocialMediaLinks || [],
          companySocialMediaEnabled: formValues.companySocialMediaEnabled,
          companyId,
          userRole: UserRole.ManageProducts,
        });

        const insertResult = await insertProduct({
          variables:
            publishMode === "draft"
              ? getInsertDraftVariables({ ...formValues, socialMediaId }, companyId)
              : getInsertPublishedVariables({ ...formValues, socialMediaId }, companyId),
        });

        if (!insertResult?.data?.insertProductOne) {
          throw new Error("insertProduct did not return any data");
        }

        const insertedProductId = insertResult.data.insertProductOne.id;
        const insertedBusinessHoursId = insertResult.data.insertProductOne.businessHoursId;

        const { companyBusinessHoursId, companyHoursEnabled } = formValues;

        if (!companyHoursEnabled && companyBusinessHoursId !== insertedBusinessHoursId) {
          await updateOpeningHours({
            variables: getUpdateOpeningHoursVariables(formValues, insertedBusinessHoursId),
          });
        }

        setProductId(insertedProductId);
        setPublishResult("ok");
        if (publishMode === "publish") {
          setEditMode(true);
        }
        if (publishMode === "draft" && insertedProductId) {
          setNextUrl(`/company/${companyId}/products/${insertedProductId}`);
        }
      } catch (e) {
        console.error(`Product insert failed for ${publishMode} publish mode. ${e}`);
        setPublishResult("error");
      }
    };

    const updateMethod = async () => {
      try {
        if (!productId) {
          throw new Error("Trying to update product without productId");
        }

        const socialMediaId = await updateSocialMedia({
          companySocialMediaId: formValues.companySocialMediaId,
          socialMediaId: formValues.socialMediaId,
          productSocialMediaLinks: formValues.productSocialMediaLinks || [],
          companySocialMediaEnabled: formValues.companySocialMediaEnabled,
          companyId,
          userRole: UserRole.ManageProducts,
        });
        await updateProduct({
          formValues,
          productId,
          companyId,
          publishMode,
          productCurationStatus,
          curationStatus,
          productType,
          isCurator: false,
          socialMediaId,
        });

        setEditMode(publishMode !== "draft");
        setPublishResult("ok");
        if (publishMode !== "publish" && productId) {
          setNextUrl(`/company/${companyId}/products/${productId}`);
        }

        if (setShowNotification) {
          setShowNotification(t("productNotification.update"));
        }
      } catch (e) {
        console.error(`Product update failed for ${publishMode} publish mode. ${e}`);
        setPublishResult("error");
      }
    };

    return createNewProduct ? insertMethod() : updateMethod();
  };

  const unpublish = async () => {
    try {
      await unpublishProduct({
        variables: {
          id: productId ?? "",
          companyId,
        },
      });

      if (productId) {
        setNextUrl(`/company/${companyId}/products/${productId}`);
      }
    } catch (e) {
      setShowUnpublishError(true);
      console.error(`Unpublishing product ${productId} failed`);
    }
  };

  const showSpinner = productIdParams && !productDataResult;
  const showPrompt =
    !nextUrl &&
    Object.keys(formState.dirtyFields).length > 0 &&
    isDirty({
      dirty: formState.dirtyFields,
      initial: productDataResult?.product?.productInformations,
      current: getValues().details,
    });

  const BasicErrorModal = ({
    onHide,
    header,
    desc,
  }: {
    onHide: () => void;
    header: string;
    desc: string;
  }) => (
    <ErrorModal onHide={onHide} headerText={header}>
      <p>{desc}</p>
      <div className="d-flex w-100 mt-2 justify-content-center">
        <Button onClick={onHide} variant="light">
          {t("productInfo.modalCloseButton")}
        </Button>
      </div>
    </ErrorModal>
  );

  const ShowModal = () => {
    if (publishResult !== "noresult" && publishMode === "publish") {
      return (
        <PublishModal
          publishResult={publishResult}
          onHide={() => {
            if (publishResult === "error") {
              setPublishResult("noresult");
            }

            if (productId) {
              setNextUrl(`/company/${companyId}/products/${productId}`);
            }
          }}
        />
      );
    }

    if (publishResult === "error" && publishMode === "update") {
      return <UpdateProductErrorModal onHide={() => setPublishResult("noresult")} />;
    }

    if (publishResult === "error" && publishMode === "draft") {
      return (
        <BasicErrorModal
          onHide={() => setPublishResult("noresult")}
          header={t("productInfo.productDraftErrorHeader")}
          desc={t("productInfo.draftErrorDesc1")}
        />
      );
    }

    if (showUnpublishError) {
      return (
        <BasicErrorModal
          onHide={() => setShowUnpublishError(false)}
          header={t("productInfo.unpublishErrorHeader")}
          desc={t("productInfo.unpublishErrorDesc1")}
        />
      );
    }

    return null;
  };

  return (
    <>
      {showSpinner ? (
        <div className="d-flex justify-content-center p-5 w-100 align-items-center">
          <div className="mr-3">
            <Spinner animation="border" />
          </div>
          <h3 className="m-0">{t("productInfo.loadingProductInfo")}</h3>
        </div>
      ) : (
        <FormProvider {...hookFormMethods}>
          <ProductForm
            onSubmit={onSubmit}
            publishMode={publishMode}
            editMode={editMode}
            companyInfo={companyInfo}
            toolbar={
              <MyProductFormToolbar
                setPublishMode={setPublishMode}
                productId={productId}
                onUnpublish={unpublish}
                unpublishLoading={unpublishLoading}
                publishResult={publishResult}
                setPublishResult={setPublishResult}
                publishMode={publishMode}
                editMode={editMode}
                onDelete={() => {
                  setNextUrl(`/company/${companyId}/products`);
                }}
                onDiscardChanges={() => {
                  if (productId) {
                    setNextUrl(`/company/${companyId}/products/${productId}`);
                  } else {
                    setNextUrl(`/company/${companyId}/products/`);
                  }
                }}
              />
            }
            lastEdit={
              lastEditInfo ? (
                <LastEdit
                  dateStr={lastEditInfo.dateStr}
                  editorName={lastEditInfo.editorName}
                  align="left"
                />
              ) : null
            }
            id={PRODUCT_FORM_NAME}
          />

          <ShowModal />

          <Prompt when={showPrompt} message={t("productInfo.unsavedChanges")} />
        </FormProvider>
      )}
    </>
  );
};

export default MyProductForm;
