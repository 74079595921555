import JoinRequestCard, { UserDetailRowData } from "./JoinRequestCard";
import { JoinRequestDMODataForVF, JoinRequestData } from "../../graphqlQueries/getJoinRequests";
import React, { useEffect, useState } from "react";

import DummyLink from "../DummyLink";
import UserManagementModal from "./UserManagementModal";
import moment from "moment";
import { useTranslation } from "react-i18next";

const FIRST_REQUESTS_LIMIT = 3;

type DataGeneratorArg = JoinRequestData | JoinRequestDMODataForVF;

type JoinRequestGridProps = {
  customColumnClass?: string;
  requests: JoinRequestData[];
  showUserEmail?: boolean;
  showInRow?: boolean;
  onApprove: (id: string) => void;
  onDeny: (id: string) => void;
  cardDetailsGenerator?: (req: DataGeneratorArg) => UserDetailRowData[];
};

const JoinRequestGrid = ({
  requests,
  onApprove,
  onDeny,
  customColumnClass,
  cardDetailsGenerator,
  showUserEmail,
  showInRow,
}: JoinRequestGridProps) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [visibleRequests, setVisibleRequests] = useState<DataGeneratorArg[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<
    JoinRequestData | JoinRequestDMODataForVF | null
  >(null);

  const showConfirmation = (req: JoinRequestData) => setSelectedRequest(req);
  useEffect(() => {
    if (showAll) {
      setVisibleRequests(requests);
    } else {
      const firstRequests = requests.slice(0, FIRST_REQUESTS_LIMIT);
      setVisibleRequests(firstRequests);
    }
  }, [showAll, requests]);

  const showRequestsToggler = requests.length > FIRST_REQUESTS_LIMIT;

  const defaultUserDetailRowDataGenerator = (req: DataGeneratorArg) => {
    const requestCreatedAt = moment(req.createdAt).format("D.M.YYYY");

    return [
      {
        title: t("userManagement.requestUsername"),
        value: req.userEmail,
      },
      {
        title: t("userManagement.requestCreatedAt"),
        value: requestCreatedAt,
      },
    ];
  };

  return (
    <div>
      <div className="row flex-sm-row flex-column mx-n2 align-content-stretch">
        {visibleRequests.map((req: DataGeneratorArg) => {
          return (
            <div key={req.id} className={customColumnClass || "col-lg-4 col-sm-6 col-12 px-2 mb-3"}>
              <JoinRequestCard
                showInRow={showInRow}
                extraUserData={showUserEmail ? req.userEmail : undefined}
                userName={req.user.name === "" ? t("userManagement.userNotFound") : req.user.name}
                onApprove={() => onApprove(req.id)}
                onDeny={() => showConfirmation(req)}
                userDetailRowData={
                  (cardDetailsGenerator && cardDetailsGenerator(req)) ||
                  defaultUserDetailRowDataGenerator(req)
                }
              />
            </div>
          );
        })}
        {visibleRequests.length === 0 && (
          <div className="col-12 pl-2">{t("userManagement.requestsNoResults")}</div>
        )}
      </div>
      <div className="mt-4">
        {showRequestsToggler && (
          <DummyLink onClick={() => setShowAll(!showAll)}>
            {!showAll
              ? `${t("userManagement.showAllRequestsToggler")} (${requests.length})`
              : t("userManagement.showLessRequestsToggler")}
          </DummyLink>
        )}
      </div>
      {selectedRequest && (
        <UserManagementModal
          title={t("userManagement.denyModalTitle")}
          text={t("userManagement.denyModalText")}
          buttonText={t("userManagement.denyModalButton")}
          onConfirm={() => {
            onDeny(selectedRequest.id);
            setSelectedRequest(null);
          }}
          onCancel={() => {
            setSelectedRequest(null);
          }}
          user={{ name: selectedRequest.user.name, email: selectedRequest.userEmail }}
        />
      )}
    </div>
  );
};

export default JoinRequestGrid;
