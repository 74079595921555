import React from "react";
import { useTranslation } from "react-i18next";
import DummyLink from "../DummyLink";
import { CommentListHeaderTextKeys } from "../../types";

type CommentListHeaderProps = {
  numOfComments: number;
  newestCommentsLimit: number;
  viewAllState: boolean;
  onViewAllClick?: () => void;
  textKeys: CommentListHeaderTextKeys;
};

const CommentListHeader = ({
  numOfComments,
  newestCommentsLimit,
  viewAllState,
  onViewAllClick,
  textKeys,
}: CommentListHeaderProps) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center py-1 mb-1">
      <h4>{`${t(textKeys.header)} (${numOfComments})`}</h4>
      {numOfComments > 0 && numOfComments > newestCommentsLimit && (
        <DummyLink className="ml-3 mb-1" onClick={onViewAllClick}>
          {t(viewAllState ? textKeys.viewNewest : textKeys.viewAll)}
        </DummyLink>
      )}
    </div>
  );
};

export default CommentListHeader;
