import { DatahubType, RequestedGroup, UserRole } from "../../types";
import React, { useEffect } from "react";
import getJoinRequests, {
  GetJoinRequestsResponse,
  GetJoinRequestsVariables,
} from "../../graphqlQueries/getJoinRequests";
import processJoinRequestQuery, {
  ProcessJoinRequestResult,
  ProcessJoinRequestVariables,
} from "../../graphqlQueries/processJoinRequest";
import { useLazyQuery, useMutation } from "@apollo/client";

import Notification from "../Notification";
import { Trans } from "react-i18next";
import { getHasuraRoleContext } from "../../utils/functions";
import { useKeycloak } from "@react-keycloak/web";
import { userInfoVar } from "../../utils/ApolloCache";

const joinRequestGroups = [RequestedGroup.TC, RequestedGroup.DMO];

const isJoinRequestGroup = (group?: RequestedGroup) => {
  return !!group && joinRequestGroups.includes(group);
};

const DashboardNotifications = () => {
  const userInfo = userInfoVar();
  const requestedGroup = userInfo?.requestedGroup;
  const userId = userInfo?.userId ?? "";

  const { keycloak } = useKeycloak();

  const [loadJoinRequestData, { data: joinRequestData }] = useLazyQuery<
    GetJoinRequestsResponse,
    GetJoinRequestsVariables
  >(getJoinRequests, {
    context: getHasuraRoleContext(UserRole.CreateCompany),
  });

  const [processJoinRequest] = useMutation<ProcessJoinRequestResult, ProcessJoinRequestVariables>(
    processJoinRequestQuery,
    {
      context: getHasuraRoleContext(UserRole.CreateCompany),
    }
  );

  useEffect(() => {
    if (userId) {
      if (isJoinRequestGroup(requestedGroup)) {
        loadJoinRequestData({
          variables: {
            userId,
          },
        });
      }
    }
  }, [userId, requestedGroup, loadJoinRequestData]);

  const joinRequests = joinRequestData?.companyJoinRequest;

  // Catch mechanism if approving fails for some reason in the createCompanyAccountWizard
  // Approve dangling joinrequests (if there are any) for the user to TC companies created by the user
  useEffect(() => {
    if (joinRequests && keycloak) {
      const danglingJoinRequests = joinRequests.filter(
        (request) =>
          request.userId === request.company.createdBy &&
          request.company.datahubType === DatahubType.TC
      );
      if (danglingJoinRequests.length > 0) {
        Promise.all(
          danglingJoinRequests.map((request) =>
            processJoinRequest({
              variables: {
                id: request.id,
                approveRequest: true,
              },
            })
          )
        )
          .then(() => keycloak.login())
          .catch((error) => console.log(error));
      }
    }
  }, [joinRequests, processJoinRequest, keycloak]);

  return (
    <>
      {joinRequests &&
        joinRequests.map((req) => (
          <Notification key={req.id} type="info">
            <div className="d-block">
              <Trans i18nKey="dashboard.joinRequestPending">
                Pending request to
                <span className="font-heavy">{{ companyName: req.company.businessName }}</span>
              </Trans>
            </div>
          </Notification>
        ))}
    </>
  );
};

export default DashboardNotifications;
