import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { ProductFormLink, ProductPublishMode, SocialMediaPlatform } from "../../types";
import React, { useMemo, useState } from "react";
import { getRequiredValidator, urlFormatValidator } from "../../utils/formUtils";

import DummyLink from "../DummyLink";
import ExternalLink from "../ExternalLink";
import Icon from "../Icon";
import { IconText } from "../IconText";
import UrlInput from "../UrlInput";
import { VerifyLinkModal } from "./VerifyLinkModal";
import { linkValidator } from "../../utils/productFormUtils/productUtils";
import { useApolloClient } from "@apollo/client";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useUserInfo from "../hooks/useUserInfo";
import PhoneInput from "../PhoneInput";

type VerifiedLinkFormInputProps = {
  controlId: string;
  name: string;
  defaultValue?: ProductFormLink;
  valueType?: SocialMediaPlatform;
  publishMode: ProductPublishMode;
  isDmo: boolean;
  required?: boolean;
  errorKey?: string;
  labelKey: string;
  hintKey?: string;
  verifyUrl?: boolean;
  showDeleteBtnOnLabelRow?: boolean;
  onDeleteItem?: () => void;
  disabled?: boolean;
};

const LINK_VERIFICATION_ERROR_KEY = "validationErrors.linkVerificationFailed";
const LINK_TOO_LONG_ERROR_KEY = "validationErrors.linkTooLong";
const FIELD_INFO_STYLE = "text-small color-gray-600";
const FIELD_ERROR_STYLE = "text-small text-danger font-italic";
const MAX_LINK_LENGTH = 300;
const MAX_PHONE_LENGTH = 20;

export const VerifiedLinkFormInput: React.FunctionComponent<VerifiedLinkFormInputProps> = ({
  controlId,
  name,
  defaultValue,
  valueType,
  publishMode,
  isDmo,
  required,
  errorKey,
  labelKey,
  hintKey,
  verifyUrl = false,
  showDeleteBtnOnLabelRow = false,
  onDeleteItem,
  disabled,
}) => {
  const defaultUrl = defaultValue?.url ?? "";
  const apolloClient = useApolloClient();
  const [urlVerification, setUrlVerification] = useState({
    isVerifying: false,
    isVerified: false,
    url: defaultUrl,
  });
  const [showUserVerificationModal, setShowUserVerificationModal] = useState(false);
  const [userVerified, setUserVerified] = useState({
    verifiedAt: defaultValue?.userVerifiedAt ?? "",
    verifiedBy: defaultValue?.userVerifiedBy ?? "",
  });
  const { t } = useTranslation();
  const { register } = useFormContext();
  const { userInfo } = useUserInfo();

  const { isVerifying, isVerified, url } = urlVerification;
  const isUserVerified = !!userVerified.verifiedAt;

  const validations = useMemo(
    () => ({
      maxLength: {
        value: MAX_LINK_LENGTH,
        message: LINK_TOO_LONG_ERROR_KEY,
      },
      validate: {
        ...(required ? { required: getRequiredValidator(publishMode) } : {}),
        linkFormat: (value: string) =>
          urlFormatValidator(value, "validationErrors.linkFormatInvalid"),
        ...(verifyUrl
          ? {
              linkOk: async (value: string) => {
                if (isUserVerified) {
                  return true;
                }
                setUrlVerification((state) => ({
                  ...state,
                  url: value,
                  isVerifying: true,
                }));

                const result = await linkValidator({
                  apolloClient,
                  link: value,
                  errorMessage: LINK_VERIFICATION_ERROR_KEY,
                  publishMode,
                  isDmo,
                });

                setUrlVerification((state) => ({
                  ...state,
                  isVerified: typeof result !== "string",
                  isVerifying: false,
                }));

                return result;
              },
            }
          : {}),
      },
    }),
    [required, publishMode, verifyUrl, isUserVerified, isDmo, apolloClient]
  );

  const showFieldInfo = () => {
    if (isVerifying) {
      return (
        <IconText
          iconElement={
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          }
          className={FIELD_INFO_STYLE}
        >
          {t("verifyLink.verifyingLink")}
        </IconText>
      );
    }

    if (errorKey) {
      const errorText = (errorArgs?: Record<string, string | number>) => (
        <IconText
          iconElement={<Icon name="warning-triangle" color="invalid" size="small" />}
          className={FIELD_ERROR_STYLE}
        >
          {t(errorKey, errorArgs)}
        </IconText>
      );

      if (errorKey === LINK_TOO_LONG_ERROR_KEY) {
        return errorText({ max: MAX_LINK_LENGTH });
      }

      if (errorKey === LINK_VERIFICATION_ERROR_KEY) {
        return (
          <>
            {errorText()}
            <ul className={FIELD_ERROR_STYLE}>
              <li>{t("validationErrors.linkVerificationFailedHint1")}</li>
              <li>{t("validationErrors.linkVerificationFailedHint2")}</li>
              <li>
                <span>{t("validationErrors.linkVerificationFailedHint3")}</span>
                <ExternalLink className="ml-2" href={url}>
                  {url}
                </ExternalLink>
              </li>
            </ul>
            <DummyLink onClick={() => setShowUserVerificationModal(true)}>
              {t("verifyLink.isLinkValid")}
            </DummyLink>
          </>
        );
      }

      return errorText();
    }

    if (isUserVerified) {
      return (
        <>
          <IconText
            iconElement={<Icon name="check-circle" color="green" size="small" />}
            className={FIELD_INFO_STYLE}
          >
            <>
              {t("verifyLink.linkUserVerified")}
              <DummyLink
                className="ml-2"
                onClick={() => setUserVerified({ verifiedAt: "", verifiedBy: "" })}
              >
                {t("verifyLink.changeLink")}
              </DummyLink>
            </>
          </IconText>
        </>
      );
    }

    if (isVerified && url) {
      return (
        <IconText
          iconElement={<Icon name="check" color="green" size="small" />}
          className={FIELD_INFO_STYLE}
        >
          {t("verifyLink.linkValid")}
        </IconText>
      );
    }

    if (hintKey) {
      return <span className={FIELD_INFO_STYLE}>{t(hintKey)}</span>;
    }

    return null;
  };

  return (
    <Form.Group controlId={controlId}>
      <Row>
        <Col>
          <Form.Label>{`${t(labelKey)}${required ? " *" : ""}`}</Form.Label>
        </Col>
        <Col xs={2}>
          {showDeleteBtnOnLabelRow ? (
            <Button className="mb-1 py-0" variant="ghost" onClick={onDeleteItem}>
              {t("productInfo.delete")}
            </Button>
          ) : (
            <span />
          )}
        </Col>
      </Row>
      {valueType === SocialMediaPlatform.Whatsapp ? (
        <PhoneInput
          name={`${name}.url`}
          defaultValue={defaultUrl}
          disabled={isUserVerified || disabled}
          rules={{
            maxLength: {
              value: MAX_PHONE_LENGTH,
              message: t("validationErrors.textTooLong", { max: MAX_PHONE_LENGTH }),
            },
            validate: {
              required: getRequiredValidator(publishMode, false, t),
            },
          }}
        />
      ) : (
        <UrlInput
          name={`${name}.url`}
          defaultValue={defaultUrl}
          rules={validations}
          disabled={isUserVerified || disabled}
          isInvalid={!!errorKey}
        />
      )}
      <Form.Control
        name={`${name}.userVerifiedAt`}
        type="hidden"
        value={userVerified.verifiedAt}
        ref={register()}
        aria-hidden
      />
      <Form.Control
        name={`${name}.userVerifiedBy`}
        type="hidden"
        value={userVerified.verifiedBy}
        ref={register()}
        aria-hidden
      />
      {showFieldInfo()}
      {showUserVerificationModal && (
        <VerifyLinkModal
          onCancel={() => setShowUserVerificationModal(false)}
          onProceed={() => {
            setShowUserVerificationModal(false);
            setUserVerified({
              verifiedAt: new Date().toISOString(),
              verifiedBy: userInfo?.userId ?? "",
            });
          }}
        />
      )}
    </Form.Group>
  );
};
