import React, { useState } from "react";

import { DataHubButton } from "../../DataHubButton";
import { useTranslation } from "react-i18next";

type ModalProductListHeaderProps = {
  productsLen: number;
  addAll: () => void;
  removeAll: () => void;
};

const ModalProductListHeader = ({
  productsLen,
  addAll,
  removeAll,
}: ModalProductListHeaderProps) => {
  const { t } = useTranslation();
  const [hasAddedAll, setHasAddedAll] = useState(false);

  return (
    <>
      <div className="my-2">
        <h4 className="ml-3 mt-3">{t("groupedProducts.modalAllPublishedProducts")}</h4>
      </div>
      <div className="d-flex justify-content-between px-2">
        <div className="ml-2">
          <DataHubButton
            variant="secondary"
            size="sm"
            onClick={() => {
              if (hasAddedAll) {
                setHasAddedAll(false);
                return removeAll();
              }
              addAll();
              setHasAddedAll(true);
            }}
          >
            {hasAddedAll ? (
              <>{t("groupedProducts.modalRemoveAllButton")}</>
            ) : (
              <>{t("groupedProducts.modalAddAllButton")}</>
            )}
          </DataHubButton>
        </div>
        <div>
          {t("groupedProducts.productsAmount", {
            amount: productsLen,
          })}
        </div>
      </div>
    </>
  );
};

export default ModalProductListHeader;
