import {
  AvailabilityLanguage as AvailabilityLanguageType,
  Language,
  ProductPublishMode,
} from "../../types";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormSectionSubHeader,
  FormSubDescription,
  FormSubSection,
  FormSubSectionContent,
} from "../FormSection";

import { DatahubSelect } from "../DatahubSelect";
import { Form } from "react-bootstrap";
import React from "react";
import { capitalize } from "../../utils/functions";
import { languagesInOrder } from "../../utils/localizationUtils";
import { useTranslation } from "react-i18next";
import DummyLink from "../DummyLink";

const validateSelect = (values: AvailabilityLanguageType[], publishMode: string) => {
  if (publishMode === "draft") {
    return true;
  }

  const invalid = !values || values.length === 0;
  return invalid ? "productInfo.availabilityLanguageError" : true;
};

export const AvailabilityLanguage = ({
  publishMode,
  disabled,
}: {
  publishMode: ProductPublishMode;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { errors } = useFormContext();
  const languageObj = (value: Language) => ({ value, label: capitalize(t(`languages.${value}`)) });
  const options = languagesInOrder.map((value) => languageObj(value));

  return (
    <FormSubSection>
      <FormSubDescription>
        <FormSectionSubHeader>{t("productInfo.languageAvailabilitiesHeader")}</FormSectionSubHeader>
        {!disabled && <p>{t("productInfo.languageAvailabilitiesDescription")}</p>}
      </FormSubDescription>
      <FormSubSectionContent>
        <Form.Label>{t("productInfo.languageAvailabilitiesSubHeader")} *</Form.Label>
        <Controller
          name="availabilityLanguages"
          defaultValue={[]}
          render={({ onChange, value }) => (
            <>
              <DummyLink
                disabled={disabled}
                className={"float-right"}
                onClick={() => onChange(options)}
              >
                {t("common.selectAll")}
              </DummyLink>
              <div className={errors.availabilityLanguages && " is-invalid is-invalid-border"}>
                <DatahubSelect
                  options={options}
                  onChange={onChange}
                  value={value?.map(({ value, label }: AvailabilityLanguageType) =>
                    !label ? languageObj(value) : { value, label }
                  )}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  isDisabled={disabled}
                />
              </div>
            </>
          )}
          rules={{
            validate: (value) => validateSelect(value, publishMode),
          }}
        />
        {errors.availabilityLanguages && (
          <p className="text-danger mt-2">{t(errors.availabilityLanguages.message)}</p>
        )}
      </FormSubSectionContent>
    </FormSubSection>
  );
};
