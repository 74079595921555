import { gql } from "@apollo/client";

const getCompaniesQuery = gql`
  query getCompanies {
    company(order_by: { createdAt: desc }) {
      id
      officialName
      businessName
      createdAt
      postalAddresses {
        city
      }
      businessEntity {
        businessId
      }
      datahubType
      productsAggregate: products_aggregate(
        where: { status: { _eq: published }, deletedAt: { _is_null: true } }
      ) {
        aggregate {
          count
        }
      }
      products(where: { status: { _eq: published } }, order_by: { updatedAt: desc }, limit: 1) {
        updatedAt
        postalAddresses {
          postalCode
        }
      }
      productPostalCodes: products(where: { status: { _eq: published } }) {
        postalAddresses {
          postalCode
        }
      }
    }
  }
`;

export type GetCompaniesQueryResult = {
  company: {
    id: string;
    officialName: string;
    businessName: string;
  }[];
};

export default getCompaniesQuery;
