import { ExternalProduct, ExternalProductTexts } from "../../utils/productFormUtils/productUtils";

import { Form } from "react-bootstrap";
import Icon from "../Icon";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ConnectToExternalProductSourcePanel = ({
  setExternalCompany,
  importingFrom,
  externalProductTexts,
}: {
  setExternalCompany: (externalVendorId: string) => void;
  importingFrom: ExternalProduct;
  externalProductTexts: ExternalProductTexts;
}) => {
  const { t } = useTranslation();
  const { errors, register, setError, clearErrors } = useFormContext();

  const isBokun = importingFrom === ExternalProduct.Bokun;

  const validateBokunId = (bokunId: string) => {
    if (/^[0-9]*$/.test(bokunId)) {
      clearErrors("bokunId");
      setExternalCompany(bokunId);
    } else {
      setError("bokunId", { message: "importProduct.bokunIdValidationFail" });
    }
  };

  const onIdChange = async (vendorId: string) => {
    isBokun ? validateBokunId(vendorId.trim()) : setExternalCompany(vendorId.trim().toLowerCase());
  };

  return (
    <div className="d-flex flex-column align-items-center pt-3">
      <strong className="font-heavy mb-3">{t(externalProductTexts.importFromTitle)}</strong>
      <p>{t(externalProductTexts.importFromDescription)}</p>
      <Form className="w-100" onSubmit={(e) => e.preventDefault()}>
        <Form.Group controlId="vendorId" className="w-100">
          <Form.Label>{t(externalProductTexts.importFromLabel)}</Form.Label>
          <Form.Control
            name="vendorId"
            className={errors.vendorId ? "is-invalid-border" : "w-100"}
            placeholder={t(externalProductTexts.importFromPlaceHolder)}
            onChange={(e) => {
              void onIdChange(e.target.value);
            }}
            ref={register()}
          />
          {errors.vendorId ? (
            <div className="d-flex flex-row pt-1" style={{ height: "2.75rem" }}>
              <Icon name="warning-triangle" color="invalid" size="medium" className="mt-1" />
              <p className="text-danger ml-2 mb-0">
                {errors.vendorId ? t(errors.vendorId.message) : ""}
              </p>
            </div>
          ) : (
            <p style={{ height: "2.75rem" }} />
          )}
        </Form.Group>
      </Form>
      {isBokun && (
        <div>
          <Icon name="bokunIdExample" />
          <div className="color-gray-600 text-small mt-1">
            {t("importProduct.bokunIdExampleText")}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectToExternalProductSourcePanel;
