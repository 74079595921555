import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";
import { ProductPublishMode, ProductType } from "../../types";

import AccessibilitySection from "./AccessibilitySection";
import { AvailabilityLanguage } from "./AvailabilityLanguage";
import CapacitySection from "./CapacitySection";
import GroupSizeSection from "./GroupSizeSection";
import PricingSection from "./PricingSection";
import ProductDurationSection from "./ProductDurationSection";
import React from "react";
import SustainabilitySection from "./SustainabilitySection";
import { TransportationCapacitySection } from "./TransportationCapacitySection";
import { WelcomeCyclistSection } from "./WelcomeCyclistSection";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ProductDetailsSection = ({
  publishMode,
  isProductGroup,
}: {
  publishMode: ProductPublishMode;
  isProductGroup: boolean;
}) => {
  const { t } = useTranslation();

  const { watch } = useFormContext();
  const productType = watch("productType");

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader>{t("productInfo.productDetailsHeader")}</FormSectionHeader>
      </FormDescription>
      {productType !== ProductType.Experience && <div className="border-left" />}
      {productType === ProductType.Transportation && (
        <TransportationCapacitySection disabled={isProductGroup} />
      )}
      {productType === ProductType.Experience && (
        <FormSectionContent>
          <ProductDurationSection disabled={isProductGroup} />
          <GroupSizeSection disabled={isProductGroup} />
        </FormSectionContent>
      )}
      {productType === ProductType.Venue && <CapacitySection disabled={isProductGroup} />}
      <PricingSection disabled={isProductGroup} />
      {productType === ProductType.Experience && (
        <AvailabilityLanguage publishMode={publishMode} disabled={isProductGroup} />
      )}
      <AccessibilitySection disabled={isProductGroup} />
      <SustainabilitySection disabled={isProductGroup} />
      <WelcomeCyclistSection disabled={isProductGroup} />
    </FormSection>
  );
};

export default ProductDetailsSection;
