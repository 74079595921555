import { gql } from "@apollo/client";

export const getCuratorCompaniesInAreas = gql`
  query GetCuratorCompaniesInAreas($postalCodes: [String!]) {
    company(
      where: { dmoAreas: { curationArea: { postalAreas: { postalCode: { _in: $postalCodes } } } } }
    ) {
      id
      officialName
      businessName
    }
  }
`;

export type GetCuratorCompaniesInAreasVariables = {
  postalCodes: string[];
};

export type GetCuratorCompaniesInAreasResult = {
  company: {
    id: string;
    officialName: string;
    businessName: string;
  }[];
};
