import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  CurationStatus,
  DataHubImage,
  ExternalProductSource,
  Language,
  ProductCurationStatus,
  ProductType,
} from "../../types";
import { useIsLargeOrExtraLargeScreen, useIsMediumOrSmaller } from "../MediaQuery";

import CommentContainer from "./CuratorProductListComments";
import Icon from "../Icon";
import ListItemContainer from "../ListItemContainer";
import ListItemTextContainer from "../ListItemTextContainer";
import ListItemThumbnailContainer from "../ListItemThumbnailContainer";
import ProductListItemHeader from "../ProductListItemHeader";
import ProductListItemIconContainer from "../products/ProductListItemIconContainer";
import ProductListItemLanguages from "../ProductListItemLanguages";
import React from "react";
import Thumbnail from "../Thumbnail";
import { default as c } from "classnames";
import { capitalize } from "../../utils/functions";
import { getProductTypeHeaderKey } from "../../utils/localizationUtils";
import moment from "moment";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type CuratorProductListItemProps = {
  item: {
    images: DataHubImage[];
    productName: string;
    companyName: string;
    city: string;
    id: string;
    productType: ProductType;
    commentsCount: number;
    newCommentsCount: number;
    curationStatus: CurationStatus;
    productCurationStatus: ProductCurationStatus;
    languages: Language[];
    updated: Date;
    hasBrokenLink: boolean | undefined;
    externalProduct?: ExternalProductSource;
  };
  selected: boolean;
  onClick: (id: string) => void;
};

const ProductStatusContainer = styled.div`
  text-align: left;
  font-size: 1.1rem;
  display: flex;
  margin-bottom: 0.5rem;
`;

const DateContainer = styled.div`
  flex: 1 0 88px;
`;

const productCurationStatusColor: Record<ProductCurationStatus, string> = {
  new: "primary",
  updated: "blue",
  changes_requested: "dark",
  complete: "light",
};

const productCurationStatusText: Record<ProductCurationStatus, string> = {
  new: "productCurationStatus.new",
  updated: "productCurationStatus.updated",
  changes_requested: "productCurationStatus.changesRequested",
  complete: "",
};

const CuratorProductListItem = ({ selected, item, onClick }: CuratorProductListItemProps) => {
  const { t } = useTranslation();

  const { curationStatus, productCurationStatus, commentsCount, updated } = item;
  const productApproved = curationStatus === CurationStatus.Approved;
  const showProductCurationStatus =
    curationStatus === CurationStatus.Pending ||
    (productApproved && productCurationStatus === ProductCurationStatus.Updated);
  const coverImage = item.images.find((image) => image.coverPhoto) ?? item.images[0];
  const isMediumOrSmaller = useIsMediumOrSmaller();
  const isLargeOrXLarge = useIsLargeOrExtraLargeScreen();
  const showWideProductCard = isMediumOrSmaller || isLargeOrXLarge;

  const productNotificationSection = () => (
    <div className="d-flex row align-items-center mr-0 ml-0 mb-2">
      {item.hasBrokenLink && <Icon name="brokenLink" className="mr-2" />}
      <CommentContainer commentsCount={commentsCount} newCommentsCount={item.newCommentsCount} />
    </div>
  );

  return (
    <ListItemContainer selected={selected} onClick={() => onClick(item.id)}>
      <div className="d-flex flex-wrap text-small mb-2 justify-content-between">
        <div className="d-flex w-100">
          <DateContainer className="mr-1 color-gray-600 text-left">
            {moment(updated).format("D.M.YYYY")}
          </DateContainer>
          <div
            className={`${
              showWideProductCard ? "flex-row flex-wrap" : "flex-column"
            } d-flex w-100 justify-content-between`}
          >
            <ProductStatusContainer>
              {productApproved && (
                <Badge variant="light-gray" className="mr-2">
                  {t("curationStatus.approved")}
                </Badge>
              )}
              {showProductCurationStatus && (
                <Badge
                  variant={productCurationStatusColor[productCurationStatus]}
                  className={c({
                    "font-light": productCurationStatus === ProductCurationStatus.ChangesRequested,
                  })}
                >
                  {t(productCurationStatusText[productCurationStatus])}
                </Badge>
              )}
            </ProductStatusContainer>
            {showWideProductCard && productNotificationSection()}
          </div>
        </div>
        {!showWideProductCard && productNotificationSection()}
      </div>
      <div className="d-flex">
        <ListItemThumbnailContainer>
          <Thumbnail
            image={coverImage}
            showDelete={false}
            altText={coverImage.altText ?? ""}
            listItemStyle
            className="mr-3"
          />
        </ListItemThumbnailContainer>
        <ListItemTextContainer className="color-gray-800">
          <ProductListItemHeader>{item.productName}</ProductListItemHeader>
          <div className="text-small">{item.companyName}</div>
          <div className="d-flex text-small justify-content-between mt-2">
            <ProductListItemIconContainer iconName="map-marker-alt-solid">
              {item.city}
            </ProductListItemIconContainer>
          </div>
          {!showWideProductCard && <ProductListItemLanguages languages={item.languages} />}
        </ListItemTextContainer>
      </div>
      <div className="d-flex mt-1 justify-content-start">
        {item.externalProduct ? (
          <OverlayTrigger
            placement="bottom-start"
            overlay={
              <Tooltip id="external-product-tooltip" className="icon-tooltip">
                {t("viewProduct.externalProductTooltip", {
                  externalSource: capitalize(item.externalProduct),
                })}
              </Tooltip>
            }
          >
            <div style={{ width: "22px", marginRight: "4.5rem", marginTop: "-2px" }}>
              <Icon name="externalProduct" />
            </div>
          </OverlayTrigger>
        ) : (
          <div style={{ width: "22px", marginRight: "4.5rem", marginTop: "-2px" }} />
        )}
        <div className="d-flex flex-wrap justify-content-between w-100">
          {showWideProductCard && <ProductListItemLanguages languages={item.languages} />}
          <div className="pl-2">{t(getProductTypeHeaderKey(item.productType))}</div>
        </div>
      </div>
    </ListItemContainer>
  );
};

export default CuratorProductListItem;
