import React from "react";
import Icon from "../Icon";

type ServiceBoxItemProps = {
  title?: string;
  text?: string;
};

const ServiceBoxItem = ({ title, text }: ServiceBoxItemProps) => (
  <div className="col-md">
    <div className="pt-4 pt-md-0 d-flex">
      <div className="mr-1 color-primary">
        <Icon name="check-circle" size="medium" className="mt-1" />
      </div>
      <div className="col-sm">
        {title && <span className="font-heavy">{`${title} `}</span>}
        {text && <span className="font-light">{text}</span>}
      </div>
    </div>
  </div>
);

export default ServiceBoxItem;
