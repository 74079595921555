import { DatahubType } from "../types";
import { gql } from "@apollo/client";

const getCompanyDatahubType = gql`
  query CompanyDatahubType($companyId: uuid!) {
    companyByPk(id: $companyId) {
      datahubType
    }
  }
`;

type HasuraCompanyDatahubType = {
  datahubType: DatahubType;
};

export type GetCompanyDatahubTypeVariables = {
  companyId: string | null;
};

export type GetCompanyDatahubTypeResult = {
  companyByPk: HasuraCompanyDatahubType;
};

export default getCompanyDatahubType;
