import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { UserDetailDataColumn, UserDetailDataRow } from "./UserDetailDataElement";

export type UserDetailRowData = { title: string; value: string };

type JoinRequestCardProps = {
  userName: string;
  extraUserData?: string;
  userDetailRowData: UserDetailRowData[];
  showInRow?: boolean;
  onApprove: () => void;
  onDeny: () => void;
};

const JoinRequestCard = ({
  userName,
  extraUserData,
  userDetailRowData,
  showInRow,
  onDeny,
  onApprove,
}: JoinRequestCardProps) => {
  const { t } = useTranslation();
  return (
    <div className="p-3 border-gray-300 bg-white d-flex flex-column justify-content-between flex-grow h-100">
      <div>
        <strong>
          <p className="text-normal-size mb-0">{userName}</p>
          {extraUserData && <p className="text-normal-size mb-0 mt-0">{extraUserData}</p>}
        </strong>
        <hr className="mr-n3 ml-n3 border-gray-300-bottom" />
      </div>

      {userDetailRowData.map((row) => {
        return showInRow ? (
          <UserDetailDataRow title={row.title} value={row.value} key={row.title} />
        ) : (
          <UserDetailDataColumn title={row.title} value={row.value} key={row.title} />
        );
      })}

      <div className="row w-100 p-0 m-0">
        <Button variant="light" className="col mr-2" onClick={onDeny}>
          {t("userManagement.requestDenyButton")}
        </Button>
        <Button variant="primary" className="col" onClick={onApprove}>
          {t("userManagement.requestApproveButton")}
        </Button>
      </div>
    </div>
  );
};

export default JoinRequestCard;
