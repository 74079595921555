import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import image1 from "../images/guidelineimages/0.jpg";
import image2 from "../images/guidelineimages/1.jpg";
import image3 from "../images/guidelineimages/2.jpg";
import image4 from "../images/guidelineimages/3-cropped.png";
import image5 from "../images/guidelineimages/4-cropped.png";
import image6 from "../images/guidelineimages/5.jpg";
import image7 from "../images/guidelineimages/6.jpg";
import image8 from "../images/guidelineimages/7.jpg";
import image9 from "../images/guidelineimages/8.jpg";
import image10 from "../images/guidelineimages/9.jpg";
import image11 from "../images/guidelineimages/10.jpg";
import styled from "styled-components";

type ImageGuidelinesModalProps = {
  onHide: () => void;
};

const GuidelineContainer = styled.div`
  max-height: 80vh;
`;

const ImageGuidelinesModal = ({ onHide }: ImageGuidelinesModalProps) => {
  const imageClassName = "my-3 mw-100";
  const { t } = useTranslation();
  return (
    <Modal show size="lg" centered animation={false} onHide={() => onHide()}>
      <Modal.Header closeButton className="pl-0">
        <h3 className="pl-5">{t("imageGuidelines.header")}</h3>
      </Modal.Header>
      <Modal.Body className="px-0">
        <GuidelineContainer className="px-5 overflow-auto">
          <p>
            <strong>{t("imageGuidelines.chooseAppealing")}</strong>{" "}
            {t("imageGuidelines.chooseAppealingDesc")}
          </p>
          <p>
            <strong>{t("imageGuidelines.lessIsMore")}</strong> {t("imageGuidelines.lessIsMoreDesc")}
          </p>
          <p>
            <strong>{t("imageGuidelines.noText")}</strong> {t("imageGuidelines.noTextDesc")}
          </p>
          <p>
            <strong>{t("imageGuidelines.correctDimensions")}</strong>{" "}
            {t("imageGuidelines.correctDimensionsDesc")}
          </p>
          <h3 className="mt-4">{t("imageGuidelines.tipsHeader")}</h3>
          <img className={imageClassName} src={image1} alt={t("imageGuidelines.altTextPhoto1")} />
          <p>
            <strong>{t("imageGuidelines.mainImage")}</strong> {t("imageGuidelines.mainImageDesc")}
          </p>
          <img className={imageClassName} src={image2} alt={t("imageGuidelines.altTextPhoto2")} />
          <img className={imageClassName} src={image3} alt={t("imageGuidelines.altTextPhoto3")} />
          <p>
            <strong>{t("imageGuidelines.dontCrop")}</strong>
          </p>
          <img
            className={imageClassName}
            src={image4}
            alt={t("imageGuidelines.dontCropImageAltText1")}
          />
          <img
            className={imageClassName}
            src={image5}
            alt={t("imageGuidelines.dontCropImageAltText2")}
          />
          <p>{t("imageGuidelines.consistency")}</p>
          <img className={imageClassName} src={image6} alt={t("imageGuidelines.altTextPhoto4")} />
          <img className={imageClassName} src={image7} alt={t("imageGuidelines.altTextPhoto5")} />
          <p>{t("imageGuidelines.natureLandscape")}</p>
          <img className={imageClassName} src={image8} alt={t("imageGuidelines.altTextNature1")} />
          <img className={imageClassName} src={image9} alt={t("imageGuidelines.altTextNature2")} />
          <p>
            <strong>{t("imageGuidelines.scandinavianClarity")}</strong>
          </p>
          <img className={imageClassName} src={image10} alt={t("imageGuidelines.altTextPeople")} />
          <p>
            <strong>{t("imageGuidelines.peopleAndAnimals")}</strong>{" "}
            {t("imageGuidelines.peopleAndAnimalsDesc")}
          </p>
          <img className={imageClassName} src={image11} alt={t("imageGuidelines.altTextClarity")} />
        </GuidelineContainer>
      </Modal.Body>
    </Modal>
  );
};

export default ImageGuidelinesModal;
