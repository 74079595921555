import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type UserManagementModalProps = {
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  user: { name: string; email: string };
  text: string;
  buttonText: string;
};

const UserManagementModal = ({
  onCancel,
  onConfirm,
  title,
  user,
  text,
  buttonText,
}: UserManagementModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      animation={false}
      show
      onHide={onCancel}
      centered
      aria-labelledby="user-management-modal-title"
    >
      <Modal.Header closeButton={true}>
        <h3 id="user-management-modal-title" className="m-0 p-0">
          {title}
        </h3>
      </Modal.Header>
      <Modal.Body className="px-5 pb-4">
        <p>{`${text} ${user.name} (${user.email})?`}</p>
        <Row className="m-0">
          <Button variant="light" className="col mr-3" onClick={onCancel}>
            {t("common.cancel")}
          </Button>
          <Button variant="primary" className="col" onClick={onConfirm}>
            {buttonText}
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UserManagementModal;
