import { gql } from "@apollo/client";

import { IdResult } from "../types";

const insertBusinessHoursMutation = gql`
  mutation InsertBusinessHours($companyId: uuid!) {
    insertBusinessHoursOne(object: { companyId: $companyId }) {
      id
    }
  }
`;

export type InsertBusinessHoursVariables = {
  companyId: string;
};

export type InsertBusinessHoursResult = {
  insertBusinessHoursOne: IdResult;
};

export default insertBusinessHoursMutation;
