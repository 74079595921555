// These should match bootstrap_theme.scss $grid-breakpoints.
export const breakpoints = {
  xs: 370,
  sm: 550,
  md: 768,
  lg: 915,
  xl: 1200,
};

export const breakpointPixels = {
  xs: `${breakpoints.xs}px`,
  sm: `${breakpoints.sm}px`,
  md: `${breakpoints.md}px`,
  lg: `${breakpoints.lg}px`,
  xl: `${breakpoints.xl}px`,
};

export const contentMaxWidth = "1440px";
