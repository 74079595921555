import { Button, Form, Row } from "react-bootstrap";
import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";
import {
  GetProductsWithSocialIdResult,
  GetProductsWithSocialIdVars,
  getProductsWithSocialIdQuery,
} from "../../graphqlQueries/socialMediaQueries/getProductsWithSocialId";
import { ProductPublishMode, SocialMediaPlatform, UserRole } from "../../types";
import React, { useEffect, useState } from "react";
import { SocialMedia } from "../../graphqlQueries/getSingleCompanyInfo";
import { getHasuraRoleContext, isDmo } from "../../utils/functions";
import { getSocialMediaPlatformKey, socialMediaPlatforms } from "../../utils/localizationUtils";
import { useFieldArray, useFormContext } from "react-hook-form";

import Checkbox from "../Checkbox";
import Notification from "../Notification";
import SocialMediaPickerModal from "./SocialMediaPickerModal";
import { VerifiedLinkFormInput } from "./VerifiedLinkFormInput";
import keycloak from "../../Keycloak";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

type SocialMediaSectionProps = {
  publishMode?: ProductPublishMode;
  headerKey: string;
  descriptionKey: string;
  fieldName: string;
  productCompanySocialMedias?: SocialMedia;
  isCompanyForm?: boolean;
  isProductGroup?: boolean;
};

const SocialMediaSection = ({
  publishMode = "publish",
  headerKey,
  descriptionKey,
  fieldName,
  productCompanySocialMedias,
  isCompanyForm = false,
  isProductGroup,
}: SocialMediaSectionProps) => {
  const { t } = useTranslation();
  const userIsDmo = isDmo(keycloak);
  const [manuallyEnteredSocials, setManuallyEnteredSocials] = useState<SocialMedia[]>();
  const [socialMediaPickerModal, setSocialMediaPickerModal] = useState(false);
  const { register, errors, watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: fieldName,
  });
  const socialMediaId = watch("socialMediaId", "");
  const companySocialMediaId = watch("companySocialMediaId", "");
  const platforms = watch(fieldName);
  const companySocialMediaEnabled = watch("companySocialMediaEnabled", false);
  const productSocialMediaLinks = watch("productSocialMediaLinks", []);

  const [getProductsAggregate, productsResult] = useLazyQuery<
    GetProductsWithSocialIdResult,
    GetProductsWithSocialIdVars
  >(getProductsWithSocialIdQuery, {
    context: getHasuraRoleContext(UserRole.ManageProducts),
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (companySocialMediaId) {
      getProductsAggregate({ variables: { socialMediaId: companySocialMediaId } });
    }
  }, [companySocialMediaId, getProductsAggregate]);

  const anyProductsUsingSocialMediaId =
    (productsResult.data?.productAggregate.aggregate.count ?? 0) !== 0;

  const getErrorKey = (index: number): string | undefined =>
    !errors ? undefined : errors[fieldName]?.[index]?.verifiedLink.url.message;

  const showDeleteButton = isCompanyForm
    ? !anyProductsUsingSocialMediaId || fields.length !== 1
    : !companySocialMediaEnabled && !isProductGroup;

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader data-cy="company-social-details">{t(headerKey)}</FormSectionHeader>
        {!isProductGroup && <p>{t(descriptionKey)}</p>}
      </FormDescription>
      <FormSectionContent>
        <Form.Label>{t(headerKey)}</Form.Label>
        {!isCompanyForm && (
          <Form.Group>
            <Row className="pl-3">
              <Checkbox
                id={`company-social-media-enabled`}
                defaultChecked={false}
                ref={register()}
                name={"companySocialMediaEnabled"}
                label={t("productInfo.companySocialMediaEnableButton")}
                disabled={!productCompanySocialMedias}
                onChange={({ target: { checked } }) => {
                  const key = "productSocialMediaLinks";
                  if (checked) {
                    setManuallyEnteredSocials(productSocialMediaLinks || []);
                    setValue(key, productCompanySocialMedias?.socialMediaLinks || []);
                  } else {
                    setValue(key, manuallyEnteredSocials || []);
                  }
                  return checked;
                }}
              />
              {!productCompanySocialMedias && (
                <Notification type="info">
                  {t("productInfo.companySocialMediaNotification")}
                </Notification>
              )}
            </Row>
          </Form.Group>
        )}
        <Form.Control
          id={"social-media-id"}
          name={"socialMediaId"}
          type="hidden"
          defaultValue={socialMediaId}
          ref={register()}
          aria-hidden
        />
        <Form.Control
          id={"company-social-media-id"}
          name={"companySocialMediaId"}
          type="hidden"
          defaultValue={productCompanySocialMedias?.id}
          ref={register()}
          aria-hidden
        />
        {anyProductsUsingSocialMediaId && isCompanyForm && (
          <Notification type="info-alt" showIcon iconSize="medium">
            {t("companyInfo.socialMediaProductsNotification")}
          </Notification>
        )}
        {fields.map((field, i) => (
          <Form.Group controlId={`socialMedia${i}`} key={field.id}>
            <Form.Control
              type="hidden"
              value={field.linkType}
              name={`${fieldName}[${i}].linkType`}
              ref={register()}
            />
            <VerifiedLinkFormInput
              controlId={fieldName}
              name={`${fieldName}[${i}].verifiedLink`}
              valueType={field.linkType}
              publishMode={publishMode}
              isDmo={userIsDmo}
              labelKey={getSocialMediaPlatformKey(field.linkType)}
              defaultValue={field.verifiedLink}
              errorKey={getErrorKey(i)}
              required={!!watch(fieldName)}
              showDeleteBtnOnLabelRow={showDeleteButton}
              onDeleteItem={() => remove(i)}
              disabled={companySocialMediaEnabled || isProductGroup}
            />
          </Form.Group>
        ))}
        <Row className="pl-2">
          {!companySocialMediaEnabled && !isProductGroup && (
            <Button
              className="mt-1 ml-2"
              variant="ghost"
              onClick={() => setSocialMediaPickerModal(!socialMediaPickerModal)}
            >
              {t("productInfo.addSocialMedia")}
            </Button>
          )}
        </Row>
      </FormSectionContent>
      {socialMediaPickerModal && (
        <SocialMediaPickerModal
          onAddSocialMedia={(val) => {
            append(
              val.map((it) => ({
                linkType: it,
              }))
            );
            setSocialMediaPickerModal(!socialMediaPickerModal);
          }}
          socialMedias={socialMediaPlatforms.filter((it) => {
            if (!platforms) {
              return true;
            }

            return !platforms
              .map(({ linkType }: { linkType: SocialMediaPlatform }) => linkType)
              .includes(it);
          })}
          onHide={() => setSocialMediaPickerModal(!socialMediaPickerModal)}
        />
      )}
    </FormSection>
  );
};

export default SocialMediaSection;
