import { DocumentNode, useQuery } from "@apollo/client";
import { ProductUserRole, UserRole, ViewMode } from "../../types";
import React, { useEffect, useRef, useState } from "react";
import getProductQuery, {
  GetProductQueryData,
  HasuradbProduct,
} from "../../graphqlQueries/getProduct";

import Loading from "../Loading";
import Notification from "../Notification";
import ViewProductData from "./ViewProductData";
import { getHasuraRoleContext } from "../../utils/functions";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ViewProductContainer = styled.div`
  font-size: 0.9rem;
  color: var(--color-gray-800);
  overflow-y: auto;
  flex-grow: 1;
  padding-bottom: 2rem;
`;

const headerSize = `
  border-radius: 5px;
  width: 100%;
  height: 220px;
  margin-bottom: 1rem;
`;

export const HeaderImage = styled.div`
  background-color: var(--color-gray-100);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  ${headerSize}
`;

export const HeaderPlaceholder = styled.div`
  ${headerSize}
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-400);
  color: var(--color-gray-800);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
`;

type ParamsType = {
  productId?: string;
};

type RenderCommentsArgs = {
  productId: string;
  viewMode: ViewMode;
  hide?: boolean;
};

type RenderToolbarArgs = {
  product: HasuradbProduct;
  showComments: boolean;
  setShowComments: React.Dispatch<React.SetStateAction<boolean>>;
  productQuery: DocumentNode;
};

export type RenderCommentsProp = (args: RenderCommentsArgs) => React.ReactNode;

export type RenderToolbarProp = (args: RenderToolbarArgs) => React.ReactNode;

type ViewProductProps = {
  viewMode: ViewMode;
  renderComments?: RenderCommentsProp;
  renderToolbar?: RenderToolbarProp;
  showNotification?: string;
  onNotificationClose?: () => void;
  showCarousel?: boolean;
  showOverlay?: boolean;
};

const userRoleForViewMode: {
  [key in ViewMode]: ProductUserRole;
} = {
  company: UserRole.ManageProducts,
  dmo: UserRole.ManageCuration,
  publishedProducts: UserRole.ViewOpenProducts,
};

const ViewProduct = ({
  viewMode,
  renderToolbar,
  renderComments,
  showNotification,
  onNotificationClose,
  showCarousel,
  showOverlay,
}: ViewProductProps) => {
  const { productId } = useParams<ParamsType>();
  const { t } = useTranslation();
  const initialRender = useRef(true);
  const [showComments, setShowComments] = useState<boolean>(false);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (productId) {
      setShowComments(false);
      onNotificationClose && onNotificationClose();
    }
  }, [productId, initialRender, onNotificationClose]);

  const productQuery = getProductQuery(userRoleForViewMode[viewMode]);

  const {
    data: productData,
    loading: productLoading,
    error: productError,
    previousData: productPreviousData,
  } = useQuery<GetProductQueryData>(productQuery, {
    variables: { id: productId },
    context: getHasuraRoleContext(userRoleForViewMode[viewMode]),
    fetchPolicy: "cache-and-network",
  });

  const product = productData?.product || productPreviousData?.product;

  // Show loading only when there is no product data to show
  if ((!productData && !productPreviousData) || (!product && productLoading)) {
    return (
      <ViewProductContainer className="px-5">
        <HeaderPlaceholder style={{ marginTop: "74px" }} />
        <Loading i18nKey="productInfo.loadingProductInfo" />
      </ViewProductContainer>
    );
  }

  if (!productId || productError || !product) {
    return <div className="text-danger">{t("viewProduct.errorLoadingProduct")}</div>;
  }

  return (
    <ViewProductContainer key={`${productId}_viewProduct`}>
      {showNotification && (
        <Notification type="success" onClose={() => onNotificationClose && onNotificationClose()}>
          {showNotification}
        </Notification>
      )}
      {renderToolbar && renderToolbar({ product, showComments, setShowComments, productQuery })}
      <ViewProductData
        product={product}
        commentOptions={
          renderComments
            ? {
                viewMode,
                renderComments,
                showComments,
              }
            : undefined
        }
        showCarousel={showCarousel}
        showOverlay={showOverlay}
      />
    </ViewProductContainer>
  );
};

export default ViewProduct;
