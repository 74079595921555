import { NotificationLink } from "./NotificationLink";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type bgColor = "red" | "gray" | "blue";

type TextBoxProps = {
  bgColor: bgColor;
  text: string;
};

type StateTagProps = {
  bgColor: bgColor;
};

const bgColorChoice: Record<string, string> = {
  red: "--color-primary-dark",
  gray: "--color-gray-300",
  blue: "--color-dark-blue",
};

const StyledStateTag = styled.div<StateTagProps>`
  background-color: var(${({ bgColor }) => bgColorChoice[bgColor]});
  line-height: 1rem;
  margin: 0 2px;
  border-radius: 2px;
  color: ${({ bgColor }) => (bgColor === "gray" ? "black" : "white")};
`;

const TextBox = ({ text, bgColor }: TextBoxProps) => (
  <StyledStateTag bgColor={bgColor} className="py-1 px-2">
    {text}
  </StyledStateTag>
);

type NotificationStatusCategory = "new" | "updated" | "published";

type CurationStatusNotificationLinkProps = {
  statusTypes: NotificationStatusCategory[];
  amountOfComments: number;
  amountOfItems: number;
  onClick: () => void;
  tooltipText: string;
};

const mapStatusToBg = (status: NotificationStatusCategory): bgColor => {
  switch (status) {
    case "new":
      return "red";
    case "published":
      return "gray";
    case "updated":
      return "blue";
  }
};

const CurationStatusNotificationLink: React.FunctionComponent<CurationStatusNotificationLinkProps> = ({
  statusTypes,
  amountOfComments,
  amountOfItems,
  onClick,
  tooltipText,
}) => {
  const { t } = useTranslation();

  const statusTags = (
    <>
      {statusTypes.map((status, i) => (
        <React.Fragment key={`${status}-${i}`}>
          {i > 0 && <span className="small gray-600">{"&"}</span>}
          <TextBox
            bgColor={mapStatusToBg(status)}
            text={
              statusTypes.length > 1
                ? t(`notifications.${status}Short`)
                : t(`notifications.${status}`)
            }
          />
        </React.Fragment>
      ))}
    </>
  );

  return (
    <NotificationLink
      amountOfItems={amountOfItems}
      amountOfComments={amountOfComments}
      onClick={onClick}
      notificationIcon={statusTags}
      tooltipText={tooltipText}
    />
  );
};

export default CurationStatusNotificationLink;
