import { BulletinTargetGroup, UserRole } from "../../types";
import Bulletins from "../bulletin/Bulletins";
import { TCSummaryHeader } from "./TCSummaryHeader";
import DashboardLayout from "./DashboardLayout";
import React from "react";
import { getHasuraRoleContext } from "../../utils/functions";
import { useQuery } from "@apollo/client";
import { TCNotifications } from "./TCNotifications";
import { TCAdvertisements } from "./TCAdvertisements";
import Loading from "../Loading";
import getCompanyNamesQuery, {
  CompaniesResult,
  CompanyNamesQueryVars,
} from "../../graphqlQueries/getCompanyNames";

export const TCDashboard: React.FunctionComponent<{
  companyId: string;
}> = ({ companyId }) => {
  const { data: userCompany } = useQuery<CompaniesResult, CompanyNamesQueryVars>(
    getCompanyNamesQuery,
    {
      variables: {
        companyIds: [companyId],
      },
      context: getHasuraRoleContext(UserRole.ManageCompany),
    }
  );
  const currentCompany = userCompany?.company[0];

  return (
    <DashboardLayout
      header={
        <div className="w-100">
          {currentCompany ? <TCSummaryHeader company={currentCompany} /> : <Loading />}
        </div>
      }
      content={
        <>
          {currentCompany && <TCNotifications company={currentCompany} />}
          <Bulletins targetGroup={BulletinTargetGroup.TC} />
          <TCAdvertisements />
        </>
      }
    />
  );
};
