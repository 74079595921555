import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ErrorModal } from "../DataHubModal";

type UpdateProductErrorModalProps = {
  onHide: () => void;
};

const UpdateProductErrorModal = ({ onHide }: UpdateProductErrorModalProps) => {
  const { t } = useTranslation();
  return (
    <ErrorModal onHide={onHide} headerText={t("productInfo.productUpdateErrorHeader")}>
      <p>{t("productInfo.updateErrorDesc1")}</p>
      <p>{t("productInfo.updateErrorDesc2")}</p>
      <div className="d-flex w-100 mt-2 justify-content-center">
        <Button onClick={onHide} variant="light">
          {t("productInfo.modalCloseButton")}
        </Button>
      </div>
    </ErrorModal>
  );
};

export default UpdateProductErrorModal;
