import React from "react";

type UserDetailRowProps = {
  title: string;
  value: string;
};

export const UserDetailDataRow = ({ title, value }: UserDetailRowProps) => {
  return (
    <div className="d-flex flex-row">
      <p className="col-6 text-small mb-0">{title}</p>
      <p className="col-6 font-heavy color-gray-800">{value}</p>
    </div>
  );
};

export const UserDetailDataColumn = ({ title, value }: UserDetailRowProps) => {
  return (
    <div>
      <p className="text-small mb-0">{title}</p>
      <p className="font-heavy color-gray-800">{value}</p>
    </div>
  );
};
