import { gql } from "@apollo/client";

export const frontPageCounts = gql`
  query FrontpageCounts {
    frontPages(first: 1) {
      productCount
      organizationCount
    }
  }
`;

export type FrontpageCountsResult = {
  frontPages: {
    productCount: number;
    organizationCount: number;
  }[];
};
