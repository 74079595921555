import { BokunJohkuInsertResponse } from "../types";
import { gql } from "@apollo/client";

export const insertExternalProduct = gql`
  mutation BokunJohkuProducts($companyId: String!, $productId: String!) {
    InsertBokunJohkuProduct(companyId: $companyId, productId: $productId) {
      productId
      errors
    }
  }
`;

export type InsertBokunJohkuImportVariables = { productId: string; companyId: string };

export type InsertBokunJohkuImportResponse = {
  InsertBokunJohkuProduct: BokunJohkuInsertResponse;
};
