import { ApolloClientObject, UserRole } from "../types";
import findPostalArea, {
  FindPostalAreaQueryResult,
  FindPostalAreaVariables,
} from "../graphqlQueries/findPostalArea";

import { getHasuraRoleContext } from "./functions";

export const postalCodeExists = async (
  apolloClient: ApolloClientObject,
  postalCode: string,
  userRole: UserRole
) => {
  try {
    const result = await apolloClient.query<FindPostalAreaQueryResult, FindPostalAreaVariables>({
      query: findPostalArea,
      variables: {
        postalCode: postalCode,
      },
      context: getHasuraRoleContext(userRole),
    });

    const postalCodeExists = !!(
      result.data?.postalAreas && result.data.postalAreas.aggregate.count > 0
    );

    return postalCodeExists;
  } catch (e) {
    throw new Error("Error fetching postal code");
  }
};

export const postalCodeValidator = async (
  apolloClient: ApolloClientObject,
  postalCode: string,
  userRole: UserRole
): Promise<string | undefined> => {
  const numberValidator = /^[0-9]{5}$/;
  const semanticallyValid = numberValidator.test(postalCode);

  if (postalCode === "") {
    return "validationErrors.required";
  }

  if (!semanticallyValid) {
    return "validationErrors.postcodeNotValid";
  }

  const exists = await postalCodeExists(apolloClient, postalCode, userRole);
  if (exists) {
    return;
  } else {
    return "postalCodeError.postalCodeNotFound";
  }
};
