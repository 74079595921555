import { gql } from "@apollo/client";

const getCompaniesForProductGroupQuery = gql`
  query getCompanies {
    company {
      id
      businessName
    }
  }
`;

export type getCompaniesForProductGroupQueryResult = {
  company: {
    id: string;
    businessName: string;
  }[];
};

export default getCompaniesForProductGroupQuery;
