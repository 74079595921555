import { Accordion, AccordionCollapse, Form } from "react-bootstrap";
import { CategoryGroup, ProductCategory } from "../../types";
import DropdownTreeSelect, { TreeNode } from "react-dropdown-tree-select";
import React, { useMemo, useState } from "react";

import AccordionContent from "../accordion/AccordionContent";
import AccordionIcon from "../accordion/AccordionIcon";
import AccordionToggle from "../accordion/AccordionToggle";
import { HasuradbCategoryGroup } from "../../graphqlQueries/getTagsWithCategories";
import { HasuradbMainCategoryGroupWithName } from "../../graphqlQueries/getTagsWithMainCategories";
import InputContainer from "./InputContainer";
import { labelCompare } from "../../utils/functions";
import { useTranslation } from "react-i18next";

type ProductCategorySelectProps = {
  onChange: (selectedOptions: ProductCategory[]) => void;
  onBlur?: () => void;
  values: ProductCategory[] | null;
  mainCategories: HasuradbMainCategoryGroupWithName[];
  allCategories: HasuradbCategoryGroup[];
  disabled?: boolean;
};

export const sortCategoryGroups = (group1: CategoryGroup, group2: CategoryGroup) => {
  if (group1.groupName === "other") {
    return 1;
  }
  if (group2.groupName === "other") {
    return -1;
  }
  return group1.groupName.localeCompare(group2.groupName);
};

const ProductFormCategorySelects = ({
  onChange,
  values = [],
  mainCategories,
  allCategories,
  disabled,
}: ProductCategorySelectProps) => {
  const { t } = useTranslation();
  const [expandedMain, setExpandedMain] = useState<string[]>([]);
  const [expandedOther, setExpandedOther] = useState<string[]>([]);

  const groupedMainTags = useMemo(() => {
    if (!mainCategories) {
      return [];
    }

    return mainCategories
      .map((category) => ({
        label: t(`categoryGroups.${category.categoryGroup.groupName}`),
        groupName: category.groupName,
        value: category.groupName,
        className: "groupHeader",
        expanded: expandedMain.includes(category.categoryGroup.groupName),
        children: category.categoryGroup.tags
          .map(({ tag }) => ({
            label: t(`tags.${tag}`),
            value: tag,
            className: "checkboxItem",
            checked: values?.find((v) => v.value === tag),
          }))
          .sort((a, b) => {
            if (a.label.startsWith("Other")) {
              return 1;
            }

            if (b.label.startsWith("Other")) {
              return -1;
            }

            return labelCompare(a, b);
          }),
      }))
      .sort(sortCategoryGroups);
  }, [mainCategories, t, values, expandedMain]);

  const groupedOtherTags = useMemo(() => {
    if (!allCategories) {
      return [];
    }

    const isNotInMainCategory = (category: HasuradbCategoryGroup) => {
      if (!mainCategories) {
        return true;
      }
      return !mainCategories.some(({ groupName }) => groupName === category.groupName);
    };

    return allCategories
      .filter((c) => isNotInMainCategory(c))
      .map((category) => ({
        label: t(`categoryGroups.${category.groupName}`),
        groupName: category.groupName,
        value: category.groupName,
        className: "groupHeader",
        expanded: expandedOther.includes(category.groupName),
        children: category.tags
          .map(({ tag }) => ({
            label: t(`tags.${tag}`),
            value: tag,
            className: "checkboxItem",
            checked: values?.find((v) => v.value === tag),
          }))
          .sort((a, b) => {
            if (a.label.startsWith("Other")) {
              return 1;
            }

            if (b.label.startsWith("Other")) {
              return -1;
            }

            return labelCompare(a, b);
          }),
      }))
      .sort(sortCategoryGroups);
  }, [allCategories, t, values, expandedOther, mainCategories]);

  const isMainTag = (value: string): boolean => {
    const mainTags = mainCategories?.flatMap((m) => m.categoryGroup.tags).map((t) => t.tag);
    return mainTags ? mainTags.some((t) => value === t) : false;
  };

  const mainCategoryTags = values ? values.filter((v) => isMainTag(v.value)) : [];
  const otherTags = values ? values.filter((v) => !isMainTag(v.value)) : [];

  const selectMainOnChange = (_: TreeNode, selectedNodes: TreeNode[]) => {
    onChange([
      ...otherTags,
      ...selectedNodes.map((node) => ({
        value: node.value,
        label: node.label,
      })),
    ]);
  };

  const selectOthersOnChange = (_: TreeNode, selectedNodes: TreeNode[]) => {
    onChange([
      ...mainCategoryTags,
      ...selectedNodes.map((node) => ({
        value: node.value,
        label: node.label,
      })),
    ]);
  };

  const selectedOtherCategoriesAmount = otherTags.length;

  return (
    <>
      <DropdownTreeSelect
        className="mb-3"
        data={groupedMainTags}
        onNodeToggle={(node) => {
          if (node.expanded) {
            setExpandedMain([...expandedMain, node.value]);
          } else {
            setExpandedMain(
              expandedMain.filter((n) => {
                return n !== node.value;
              })
            );
          }
        }}
        texts={{
          placeholder: t("select.placeholder"),
          noMatches: t("select.noMatchesFound"),
        }}
        onChange={selectMainOnChange}
        keepOpenOnSelect
        keepTreeOnSearch={true}
        clearSearchOnChange={true}
        disabled={disabled}
      />
      <Accordion>
        <AccordionToggle
          className="w-auto"
          eventKey="0"
          disabled={disabled}
          renderIcon={(open: boolean) => (
            <AccordionIcon open={open} style={{ marginRight: "23px" }} />
          )}
        >
          <AccordionContent customStyle="d-flex pl-0">
            <div className="m-0 p-0">
              {t("productInfo.selectOtherCategories")}{" "}
              {!disabled && `(${selectedOtherCategoriesAmount})`}
            </div>
          </AccordionContent>
        </AccordionToggle>
        <AccordionCollapse eventKey="0">
          <InputContainer className="mt-3">
            <Form.Label>{t("productInfo.otherCategoriesHeader")}</Form.Label>
            <div className="mb-3">{t("productInfo.otherCategoriesDescription")}</div>
            <Form.Label>{t("productInfo.otherCategoriesSelectHeader")}</Form.Label>
            <DropdownTreeSelect
              data={groupedOtherTags}
              onNodeToggle={(node) => {
                if (node.expanded) {
                  setExpandedOther([...expandedOther, node.value]);
                } else {
                  setExpandedOther(
                    expandedOther.filter((n) => {
                      return n !== node.value;
                    })
                  );
                }
              }}
              texts={{
                placeholder: t("select.placeholder"),
                noMatches: t("select.noMatchesFound"),
              }}
              onChange={selectOthersOnChange}
              keepOpenOnSelect
              keepTreeOnSearch={true}
              clearSearchOnChange={true}
              disabled={disabled}
            />
          </InputContainer>
        </AccordionCollapse>
      </Accordion>
      {disabled && (
        <div className={"mt-1"}>{otherTags.map((v) => t(`tags.${v.value}`)).join(", ")}</div>
      )}
    </>
  );
};

export default ProductFormCategorySelects;
