import React, { useState } from "react";
import DummyLink from "../DummyLink";
import TranslationInfoModal from "../TranslationInfoModal";
import { useTranslation } from "react-i18next";

const OrderTranslationsButton = () => {
  const { t } = useTranslation();
  const [showTranslationModal, setShowTranslationModal] = useState<boolean>(false);
  return (
    <>
      <DummyLink
        className="primary-nav-link nav-link text-decoration-none"
        onClick={() => setShowTranslationModal(true)}
      >
        {t("navigation.orderTranslations")}
      </DummyLink>
      {showTranslationModal && (
        <TranslationInfoModal
          onHide={() => {
            setShowTranslationModal(false);
          }}
        />
      )}
    </>
  );
};

export default OrderTranslationsButton;
