import React from "react";
import { Spinner } from "react-bootstrap";

const iconMargin = "0 5px 0 -10px";

const SubmitSpinner = () => (
  <Spinner
    as="span"
    animation="border"
    style={{
      margin: iconMargin,
    }}
    size="sm"
    role="status"
    aria-hidden="true"
  />
);

export default SubmitSpinner;
