import { AggregateCount, CurationStatus, CurationStatusesFilter } from "../types";

import { gql } from "@apollo/client";

const buildAggregateQuery = (
  status: CurationStatusesFilter,
  productCurationStatus: CurationStatusesFilter,
  curationStatus: CurationStatus
) =>
  `${status}: productCommentsAggregate(
        where: {
        seenByDmoAt: { _is_null: true }
        recipientRole: { _eq: dmo }
          product: {
            postalAddresses: { 
              postalArea: { 
                city: { 
                  id: { 
                    _in: $dmoAreas
                   }
                  }
                }
              }
            productCurations: {
              productCurationStatus: { _eq: ${productCurationStatus} }
              curationStatus: { _eq: ${curationStatus} }
            }
          }
        }
      ) {
        aggregate {
          count
        }
      }`;

const getDmoNewCommentsCount = gql`
    query GetDmoNewCommentsCount($dmoAreas: [uuid!]) {
      ${buildAggregateQuery(
        CurationStatusesFilter.New,
        CurationStatusesFilter.New,
        CurationStatus.Pending
      )}
      ${buildAggregateQuery(
        CurationStatusesFilter.Updated,
        CurationStatusesFilter.Updated,
        CurationStatus.Pending
      )}
      ${buildAggregateQuery(
        CurationStatusesFilter.ApprovedAndUpdated,
        CurationStatusesFilter.Updated,
        CurationStatus.Approved
      )}
    }
  `;

export type GetCompanyNewCommentsCountResult = {
  new: AggregateCount;
  updated: AggregateCount;
  approvedAndUpdated: AggregateCount;
};

export default getDmoNewCommentsCount;
