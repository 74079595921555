import React, { useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import { StyledDropdown, StyledSummaryAmountHeader } from "./DashboardComponents";
import { ProductCities } from "../../graphqlQueries/getProductCitiesByCompany";
import { useQuery } from "@apollo/client";
import { getHasuraRoleContext } from "../../utils/functions";
import { UserRole } from "../../types";
import {
  DmoByCityQueryVars,
  DmoByCityResult,
  getDmoByCity,
} from "../../graphqlQueries/getDmoByCity";
import Accordion from "react-bootstrap/Accordion";

type TCDashboardHeaderDropdownProps = {
  products?: ProductCities[];
};

export const TCDashboardHeaderDropdown: React.FunctionComponent<TCDashboardHeaderDropdownProps> = ({
  products,
}) => {
  const { t } = useTranslation();
  const amountOfProducts = products?.length ?? 0;

  const byCity: Record<string, number> = {};
  products?.forEach((p) =>
    p.postalAddresses.forEach((address) => {
      if (byCity[address.city]) {
        byCity[address.city] = byCity[address.city] + 1;
      } else {
        byCity[address.city] = 1;
      }
    })
  );

  return amountOfProducts > 0 ? (
    <StyledDropdown
      data-cy="company-published-products"
      className="justify-content-center justify-content-md-start"
      style={{ maxWidth: 350 }}
    >
      <Dropdown.Toggle variant="text" className="w-100">
        <div className="blue">
          <StyledSummaryAmountHeader>{amountOfProducts}</StyledSummaryAmountHeader>
        </div>
        <div className="number-details-wrapper flex-column">
          <div className="blue">
            <h3 className="mb-0">{t("dashboard.publishedProductsInTotal")}</h3>
          </div>
          <div className="mr-auto">
            <span className="numbersByProduct">
              {t("dashboard.myCurators")}
              <Icon name="chevron-down" />
            </span>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="flex-column w-100" data-cy="company-products-dropdown">
        {Object.entries(byCity)?.map(([city, count]) => (
          <Dropdown.ItemText key={city} className="p-0">
            <CompanyInfo city={city} count={count} />
          </Dropdown.ItemText>
        ))}
      </Dropdown.Menu>
    </StyledDropdown>
  ) : (
    <div>
      <div className="blue">
        <StyledSummaryAmountHeader>{amountOfProducts}</StyledSummaryAmountHeader>
      </div>
      <div className="number-details-wrapper flex-column">
        <div className="blue">
          <h3 className="mb-0">{t("dashboard.publishedProductsInTotal")}</h3>
        </div>
        <div style={{ color: "var(--color-black)" }}>{t("dashboard.noCurators")}</div>
      </div>
    </div>
  );
};

const CompanyInfo = ({ city, count }: { city: string; count: number }) => {
  const [open, setOpen] = useState<string>("");
  const { data: dmoData } = useQuery<DmoByCityResult, DmoByCityQueryVars>(getDmoByCity, {
    variables: { city },
    context: getHasuraRoleContext(UserRole.ManageProducts),
  });
  const vf = { company: { businessName: "Visit Finland", contactDetails: undefined } };
  const companies = (dmoData?.dmoArea?.length ?? 0) > 0 ? dmoData?.dmoArea : [vf];

  return (
    <Accordion activeKey={open}>
      <Card className="bg-transparent border-0 w-100">
        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          onClick={() => setOpen(open ? "" : "0")}
          className="bg-transparent cursor-pointer d-flex justify-content-between w-100 align-items-center"
        >
          <div style={{ color: "var(--color-black)" }}>
            <p className={"d-inline m-0"}>{city}</p>
            <Icon
              name="chevron-down"
              size="medium"
              className="ml-3"
              style={{ transform: `rotate(${open ? "180" : "0"}deg)` }}
            />
          </div>
          <div className="font-heavy h3 my-1" style={{ color: "var(--color-black)" }}>
            {count}
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0" className="w-100">
          <Card.Body className="bg-gray-100 d-block w-100 pl-5">
            <div className="d-block">
              {companies?.map((dmo) => (
                <div className="d-block my-2 w-100" key={city + dmo.company?.businessName}>
                  <div
                    className="d-block mb-1 overflow-hidden"
                    style={{
                      color: "var(--color-black)",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {dmo.company?.businessName}
                  </div>
                  {dmo.company?.contactDetails?.map((d) => (
                    <div className="small" key={d.email} style={{ color: "var(--color-black)" }}>
                      {d.email}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
