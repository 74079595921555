import React from "react";
import { weekdays } from "../../utils/timeUtils";
import moment from "moment/moment";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useIsSmallScreen } from "../MediaQuery";
import { MaintenanceDataOut } from "../../types/bannerTypes";
import { EditMaintenance } from "./EditMaintenance";
import { DeleteMaintenance } from "./DeleteMaintenance";

type MaintenanceNoticeListItemProps = {
  data: MaintenanceDataOut;
  index: number;
  openEditForm: (openEdit: MaintenanceDataOut) => void;
  deleteMaintenance: (maintenanceId: string) => void;
  isActive: boolean;
  handleToggle: (bool: boolean) => void;
  loading: boolean;
  showPopover: boolean;
  handlePopoverButtonClick: (val: string) => void;
};

export const MaintenanceNoticeListItem = ({
  data,
  index,
  openEditForm,
  deleteMaintenance,
  isActive,
  handleToggle,
  loading,
  showPopover,
  handlePopoverButtonClick,
}: MaintenanceNoticeListItemProps) => {
  const { t } = useTranslation();
  const isMobile = useIsSmallScreen();

  const weekday = weekdays[moment(data.bannerStartDate).isoWeekday()];

  const buildBreakStartTime = `${t(`openingHours.${weekday}.short`)} ${t(
    "maintenance.dashboard.outageTime",
    {
      startDate: moment(data.breakStartDate).format("D.M"),
      startTime: data.breakStartTime,
      endDate: moment(data.breakEndDate).format("D.M"),
      endTime: data.breakEndTime,
    }
  )}`;

  const buildBannerStartTime = () => {
    if (isActive) {
      return t("maintenance.dashboard.noticeTimeActive", {
        startTime: data.bannerStartTime,
        endDate: moment(data.bannerEndDate).format("D.M"),
        endTime: data.bannerEndTime,
      });
    }

    return t("maintenance.dashboard.noticeTime", {
      startDate: moment(data.bannerStartDate).format("D.M"),
      startTime: data.bannerStartTime,
      endDate: moment(data.bannerEndDate).format("D.M"),
      endTime: data.bannerEndTime,
    });
  };

  return (
    <>
      {!isMobile ? (
        <Row
          className={`justify-content-start ${
            isActive ? "border border-primary" : "border"
          } py-1 my-2 align-items-center`}
        >
          <Col xs={2} sm={4} lg={2} xl={3} className="my-auto pr-1">
            <p className="m-0">{buildBreakStartTime}</p>
          </Col>
          <Col xs={4} sm={8} lg={3} xl={4} className="my-auto px-0">
            <span className="text-muted">{buildBannerStartTime()}</span>
          </Col>
          <Col xs={3} sm={6} lg={5} xl={4} className="pt-1">
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <Form.Check
                id={`activeToggle${index}`}
                type="switch"
                label={t(`maintenance.dashboard.notification${isActive ? "On" : "Off"}`)}
                defaultChecked={isActive}
                onChange={(e: { target: { checked: boolean } }) => handleToggle(e.target.checked)}
              />
            )}
          </Col>
          <Col xs={2} sm={6} lg={2} xl={1} className="my-auto px-lg-0">
            <div className="d-flex flex-row justify-content-end pr-2">
              <EditMaintenance data={data} openEditForm={openEditForm} />
              <DeleteMaintenance
                deleteMaintenance={() => deleteMaintenance(data.id)}
                openPopover={showPopover}
                handlePopoverButtonClick={() => handlePopoverButtonClick(data.id)}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <div
          className={`pt-2 pb-1 ${isActive ? "border border-primary" : "border"}`}
          style={{ paddingLeft: "2.3rem", paddingRight: "2rem" }}
        >
          <Row>
            <p className="m-0">{buildBreakStartTime}</p>
          </Row>
          <Row>
            <span className="text-muted text-small">{buildBannerStartTime()}</span>
          </Row>
          <Row className="pt-2 justify-content-between">
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <Form.Check
                id={`activeToggle${index}`}
                type="switch"
                label={t(`maintenance.dashboard.notification${isActive ? "On" : "Off"}`)}
                defaultChecked={isActive}
                onChange={(e: { target: { checked: boolean } }) => handleToggle(e.target.checked)}
              />
            )}
            <div className="d-flex">
              <EditMaintenance data={data} openEditForm={openEditForm} />
              <DeleteMaintenance
                deleteMaintenance={() => deleteMaintenance(data.id)}
                openPopover={showPopover}
                handlePopoverButtonClick={() => handlePopoverButtonClick(data.id)}
              />
            </div>
          </Row>
        </div>
      )}
    </>
  );
};
