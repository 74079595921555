import { gql } from "@apollo/client";
import { UserInfo } from "../types";

const getUserInfoLocal = gql`
  query GetUserInfoLocal {
    userInfo @client
  }
`;

export interface UserInfoData {
  userInfo: UserInfo | null;
}

export default getUserInfoLocal;
