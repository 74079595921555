import React, { useRef, useState } from "react";
import { Controller, RegisterOptions } from "react-hook-form";
import styled from "styled-components";

type InputWithHighlightsProps = {
  name: string;
  defaultValue: string | undefined;
  maxLength: number;
  rules: RegisterOptions;
  isInvalid?: boolean;
};

const CONTAINER_HEIGHT_REM = 10;
const BORDER_WIDTH_PX = 1;
const FOCUS_BORDER_WIDTH_PX = 2;
const TEXTBOX_PADDING_PX = 10;

const Container = styled.div`
  width: auto;
  height: ${CONTAINER_HEIGHT_REM}rem;
  display: block;
  padding-left: 0 !important;
  transform: translateZ(0);
  -webkit-text-size-adjust: none;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
`;

type BackdropProps = {
  isFocused: boolean;
};

const Backdrop = styled.div<BackdropProps>`
  width: 100%;
  height: ${CONTAINER_HEIGHT_REM}rem;
  position: absolute;
  z-index: 1;
  background-color: var(--color-white);
  overflow: auto;
  pointer-events: none;
  transition: transform 1s;
  border-style: solid;
  border-color: transparent;
  border-radius: 0;
  ${({ isFocused }) => `border-width: ${isFocused ? FOCUS_BORDER_WIDTH_PX : BORDER_WIDTH_PX}px;`}
`;

type TextInputProps = {
  isInvalid?: boolean;
};

const TextInput = styled.textarea.attrs<TextInputProps>(({ isInvalid }) => ({
  className: isInvalid ? "is-invalid is-invalid-border" : "",
}))<TextInputProps>`
  width: 100%;
  height: ${CONTAINER_HEIGHT_REM}rem;
  padding: ${TEXTBOX_PADDING_PX}px !important;
  display: block;
  position: absolute;
  z-index: 2;
  margin: 0;
  border-width: ${BORDER_WIDTH_PX}px;
  border-style: solid;
  border-color: var(--color-gray-600);
  border-radius: 0;
  background-color: transparent;
  overflow: auto;
  resize: none;
  transition: transform 1s;
  outline: 0;

  &&:focus {
    border-color: var(--color-gray-800);
    box-shadow: ${({ isInvalid }) =>
      isInvalid ? "0 0 0 0.2rem rgb(220 53 69 / 25%)" : "inset 0px 2px 6px 0px rgb(0 0 0 / 50%)"};
    border-width: ${FOCUS_BORDER_WIDTH_PX}px;
  }
`;

const HighlightsContainer = styled.div`
  padding: ${TEXTBOX_PADDING_PX}px !important;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
`;

const HighlightedText = styled.mark`
  padding: 0;
  border-radius: 3px;
  color: transparent;
  background-color: var(--color-invalid) !important;
`;

const getHighlightedValue = (value: string, maxLength: number): JSX.Element | undefined => {
  if (!value) {
    return;
  }
  if (value.length > maxLength) {
    const text = value.replace(/\n$/g, "\n\n");
    return (
      <HighlightsContainer aria-hidden>
        {text.substring(0, maxLength)}
        <HighlightedText>{text.substring(maxLength)}</HighlightedText>
      </HighlightsContainer>
    );
  }
  return <HighlightsContainer aria-hidden>{value}</HighlightsContainer>;
};

const InputWithHighlights = ({
  name,
  defaultValue,
  maxLength,
  rules,
  isInvalid,
}: InputWithHighlightsProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const highlightRef = useRef<HTMLDivElement>(null);

  const handleScrollTextarea = (scroll: React.UIEvent<HTMLElement>) => {
    if (highlightRef && highlightRef.current) {
      highlightRef.current.scrollTop = scroll.currentTarget.scrollTop;
    }
  };
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value }) => (
        <Container>
          <Backdrop isFocused={isFocused} ref={highlightRef}>
            {getHighlightedValue(value, maxLength)}
          </Backdrop>
          <TextInput
            onScroll={handleScrollTextarea}
            onChange={onChange}
            value={value}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            isInvalid={isInvalid}
          />
        </Container>
      )}
    />
  );
};

export default InputWithHighlights;
