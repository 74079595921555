import React from "react";
import { SocialMediaLinks } from "../graphqlQueries/getSingleCompanyInfo";
import { SocialMediaPlatform } from "../types";
import { getSocialMediaPlatformKey } from "../utils/localizationUtils";
import ExternalLink from "./ExternalLink";
import { useTranslation } from "react-i18next";

type SocialMediaLinkProps = {
  link: SocialMediaLinks;
  className?: string;
};
const WHATSAPP_URL = "https://wa.me/";

const SocialMediaLink = ({ link, className }: SocialMediaLinkProps) => {
  const { t } = useTranslation();
  return link.linkType === SocialMediaPlatform.Whatsapp ? (
    <ExternalLink
      href={`${WHATSAPP_URL}${link.verifiedLink.url.replace(/[+({})[\]\-_]/g, "")}`}
      className={className}
      iconPosition="left"
    >
      {`${t(getSocialMediaPlatformKey(link.linkType))}: ${link.verifiedLink.url}`}
    </ExternalLink>
  ) : (
    <ExternalLink href={link.verifiedLink.url} className={className} iconPosition="left">
      {t(getSocialMediaPlatformKey(link.linkType))}
    </ExternalLink>
  );
};

export default SocialMediaLink;
