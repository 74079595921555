import en from "./en/en.json";
import fi from "./fi/fi.json";

const translations = {
  en: {
    translation: en,
  },
  fi: {
    translation: fi,
  },
};

export default translations;
