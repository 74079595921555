import React from "react";
import { useTranslation } from "react-i18next";

import { BasicInfoModal } from "../DataHubModal";
import { SocialMediaLinks } from "../../graphqlQueries/getSingleCompanyInfo";
import SocialMediaLink from "../SocialMediaLink";

type SocialMediasModalProps = {
  onClose: () => void;
  socialMediaLinks: SocialMediaLinks[];
};

const SocialMediasModal = ({ socialMediaLinks, onClose }: SocialMediasModalProps) => {
  const { t } = useTranslation();

  return (
    <BasicInfoModal
      header={
        <h1 className="text-larger m-0 p-0">{t("viewProduct.showAllSocialMediasModalHeader")}</h1>
      }
      onCancel={onClose}
      size="lg"
    >
      <ul className="list-group list-unstyled ml-2">
        {socialMediaLinks.map((it, i) => (
          <SocialMediaLink link={it} className={"d-block mb-2"} key={i} />
        ))}
      </ul>
    </BasicInfoModal>
  );
};

export default SocialMediasModal;
