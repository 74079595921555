import { gql } from "@apollo/client";

export const insertSocialMediaQuery = gql`
  mutation InsertSocialMedia($companyId: uuid!, $companySocialMedia: Boolean!) {
    insertSocialMediaOne(
      object: { companyId: $companyId, companySocialMedia: $companySocialMedia }
    ) {
      id
    }
  }
`;

export type InsertSocialMediaVars = {
  companyId: string;
  companySocialMedia: boolean;
};

export type InsertSocialMediaResult = {
  insertSocialMediaOne: { id: string };
};
