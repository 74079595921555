import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

type CurrencyInputProps = {
  id?: string;
  name: string;
  value?: string;
  disabled?: boolean;
  className?: string;
  onChange?: (e: React.ChangeEvent) => void;
};

const InputContainer = styled.div`
  position: relative;
`;

const CurrencyCharacterContainer = styled.div`
  position: absolute;
  top: 9px;
  right: 10px;
  color: var(--color-gray-600);
`;

const CurrencyInput = React.forwardRef<HTMLInputElement, CurrencyInputProps>((props, ref) => {
  return (
    <InputContainer>
      <Form.Control ref={ref} type="text" {...props} />
      <CurrencyCharacterContainer>&euro;</CurrencyCharacterContainer>
    </InputContainer>
  );
});

export default CurrencyInput;
