import Icon from "../Icon";
import React from "react";
import { useTranslation } from "react-i18next";

type CommentProps = {
  commentsCount: number;
  newCommentsCount: number;
};

const CommentContainer = ({ commentsCount, newCommentsCount }: CommentProps) => {
  const { t } = useTranslation();

  return (
    <div className="color-gray-600 d-flex">
      {commentsCount > 0 && (
        <>
          <div>{commentsCount}</div>
          <Icon name="comment-alt" size="medium" style={{ marginTop: "2px" }} />
        </>
      )}
      {newCommentsCount > 0 && (
        <div className="text-small color-primary-dark text-uppercase font-weight-bold ml-2">
          {`${t("comments.newComments")} (${newCommentsCount})`}
        </div>
      )}
    </div>
  );
};

export default CommentContainer;
