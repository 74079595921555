import { Accordion, Button } from "react-bootstrap";
import {
  ActionResult,
  ActionResultResponse,
  CommentListTextKeys,
  CommentToEdit,
  DatahubType,
  UserRole,
  ViewMode,
} from "../../types";
import { DocumentNode, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  updateCommentSeenByDmoMutation,
  updateCommentSeenByTcMutation,
} from "../../graphqlQueries/updateCommentSeen";

import AccordionToggle from "../accordion/AccordionToggle";
import CommentListHeader from "./CommentListHeader";
import CommentListItemHeader from "./CommentListItemHeader";
import DummyLink from "../DummyLink";
import EditCommentForm from "./EditCommentForm";
import MultilineText from "../MultilineText";
import Notification from "../Notification";
import { ProductComment } from "../../graphqlQueries/getProductComments";
import { ProductCommentData } from "../../graphqlQueries/getProductComments";
import { getHasuraRoleContext } from "../../utils/functions";
import moment from "moment";
import styled from "styled-components";
import { userInfoVar } from "../../utils/ApolloCache";
import { MAX_COMMENT_LENGTH } from "../../utils/constants";

const NEWEST_COMMENTS_LIMIT = 5;

type CommentListProps = {
  commentData: ProductCommentData;
  showMoreHandler: () => void;
  viewMode: ViewMode;
  commentsQuery: DocumentNode;
  textKeys?: CommentListTextKeys;
};

const CommentListContainer = styled.div`
  background-color: var(--color-gray-200);
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const CommentInfo = styled.div`
  color: var(--color-gray-600);
`;

const userCommentsListTextKeys: CommentListTextKeys = {
  commentListHeader: {
    header: "commentsUser.listHeader",
    viewAll: "comments.viewAll",
    viewNewest: "comments.viewNewest",
  },
  errorLoading: "comments.errorLoading",
  loading: "common.loading",
  noComments: "comments.noComments",
  showMore: "comments.showMore",
  commentNotSeen: "comments.commentNotSeen",
  commentNew: "comments.commentNew",
};

const curatorCommentsListTextKeys: CommentListTextKeys = {
  commentListHeader: {
    header: "commentsCurator.listHeader",
    viewAll: "comments.viewAll",
    viewNewest: "comments.viewNewest",
  },
  errorLoading: "comments.errorLoading",
  loading: "common.loading",
  noComments: "comments.noComments",
  showMore: "comments.showMore",
  edit: "commentsCurator.edit",
  commentNotSeen: "comments.commentNotSeen",
  commentNew: "comments.commentNew",
};

const CommentList = ({
  commentData,
  showMoreHandler,
  viewMode,
  commentsQuery,
  textKeys = viewMode === "dmo" ? curatorCommentsListTextKeys : userCommentsListTextKeys,
}: CommentListProps) => {
  const { t } = useTranslation();

  const [viewAllComments, setViewAllComments] = useState<boolean>(false);

  const userInfo = userInfoVar();
  const isDmoViewMode = viewMode === "dmo";
  const isCompanyViewMode = viewMode === "company";

  const [updateCommentSeenByDmo] = useMutation(updateCommentSeenByDmoMutation);
  const [updateCommentSeenByTc] = useMutation(updateCommentSeenByTcMutation);
  const [commentToEdit, setCommentToEdit] = useState<CommentToEdit | undefined>();
  const [commentEditResult, setCommentEditResult] = useState<ActionResult>(
    ActionResultResponse.NO_RESULT
  );
  const [commentDeleteResult, setCommentDeleteResult] = useState<ActionResult>(
    ActionResultResponse.NO_RESULT
  );

  const allComments = commentData.productComments;
  const comments = viewAllComments ? allComments : allComments.slice(0, NEWEST_COMMENTS_LIMIT);
  const commentsCount = commentData.productCommentsAggregate.aggregate.count;

  const onViewAllClick = () => {
    setViewAllComments((value) => !value);
  };

  const onShowMoreCommentsClick = () => {
    showMoreHandler();
  };

  const onEditCommentClick = (id: string, comment: string) => {
    setCommentToEdit({ id, comment });
  };

  const canEditComment = (item: ProductComment, loggedInUserId: string | undefined) => {
    return isDmoViewMode && item.createdById === loggedInUserId && item.deletedAt === null;
  };

  const canUpdateCommentSeen = ({
    recipientRole,
    seenByDmoAt,
    seenByTcAt,
  }: ProductComment): boolean => {
    switch (recipientRole) {
      case DatahubType.DMO:
        return !seenByDmoAt && isDmoViewMode;
      case DatahubType.TC:
        return !seenByTcAt && isCompanyViewMode;
      default:
        return false;
    }
  };

  const deleteResponseMessage = (result: string): string => {
    if (result === ActionResultResponse.GENERAL_ERROR) {
      return t("commentsCurator.commentDeleteErrorNotificationText");
    }

    return t("commentsCurator.commentDeleteSuccessNotificationText");
  };

  const editResponseMessage = (result: string): string => {
    if (result === ActionResultResponse.GENERAL_ERROR) {
      return t("commentsCurator.commentEditErrorNotificationText");
    }

    if (result === ActionResultResponse.LENGTH_ERROR) {
      return t("commentsCurator.commentSendLengthErrorNotificationText", {
        charLimit: MAX_COMMENT_LENGTH,
      });
    }

    return t("commentsCurator.commentEditSuccessNotificationText");
  };

  return (
    <CommentListContainer>
      <CommentListHeader
        numOfComments={commentsCount}
        newestCommentsLimit={NEWEST_COMMENTS_LIMIT}
        viewAllState={viewAllComments}
        onViewAllClick={onViewAllClick}
        textKeys={textKeys.commentListHeader}
      />
      {comments.length > 0 ? (
        <Accordion>
          {commentDeleteResult !== ActionResultResponse.NO_RESULT && (
            <Notification
              type={commentDeleteResult === ActionResultResponse.RESULT_OK ? "success" : "danger"}
              onClose={() => setCommentDeleteResult(ActionResultResponse.NO_RESULT)}
              className="my-2"
            >
              {deleteResponseMessage(commentDeleteResult)}
            </Notification>
          )}
          {commentEditResult !== ActionResultResponse.NO_RESULT && (
            <Notification
              type={commentEditResult === ActionResultResponse.RESULT_OK ? "success" : "danger"}
              onClose={() => setCommentEditResult(ActionResultResponse.NO_RESULT)}
              className="my-2"
            >
              {editResponseMessage(commentEditResult)}
            </Notification>
          )}
          {comments.map((comment) => (
            <div
              key={comment.id}
              className={`${
                commentToEdit && commentToEdit.id === comment.id
                  ? "bg-gray-200 my-2"
                  : "bg-white mb-1"
              }`}
            >
              <AccordionToggle
                eventKey={comment.id}
                iconPosition="left"
                className="px-2"
                callback={() => {
                  if (canUpdateCommentSeen(comment)) {
                    const timeStampNow = moment().toISOString();

                    const updateSeenCommonVars = {
                      id: comment.id,
                    };

                    isDmoViewMode
                      ? updateCommentSeenByDmo({
                          variables: {
                            ...updateSeenCommonVars,
                            seenByDmoAt: timeStampNow,
                          },
                          context: getHasuraRoleContext(UserRole.ManageCuration),
                        })
                      : updateCommentSeenByTc({
                          variables: {
                            ...updateSeenCommonVars,
                            seenByTcAt: timeStampNow,
                          },
                          context: getHasuraRoleContext(UserRole.ManageProducts),
                        });
                  }
                }}
              >
                <CommentListItemHeader
                  item={comment}
                  viewMode={viewMode}
                  notSeenText={t(textKeys.commentNotSeen)}
                  newText={t(textKeys.commentNew)}
                />
              </AccordionToggle>
              {isDmoViewMode && commentToEdit && commentToEdit.id === comment.id ? (
                <EditCommentForm
                  commentId={commentToEdit.id}
                  commentText={commentToEdit.comment}
                  setCommentToEdit={setCommentToEdit}
                  setCommentEditResult={setCommentEditResult}
                  setCommentDeleteResult={setCommentDeleteResult}
                  commentsQuery={commentsQuery}
                />
              ) : (
                <Accordion.Collapse eventKey={comment.id}>
                  <MultilineText
                    className="mb-0 ml-1 pb-2 text-small"
                    style={{ paddingLeft: "2rem" }}
                  >
                    {comment.deletedAt ? (
                      <CommentInfo>{t("comments.deleted")}</CommentInfo>
                    ) : (
                      <div className="pb-1">{comment.comment}</div>
                    )}
                    {userInfo && textKeys.edit && canEditComment(comment, userInfo.userId) && (
                      <Button
                        onClick={() => onEditCommentClick(comment.id, comment.comment)}
                        className="d-inline-block mb-3"
                        variant="ghost"
                      >
                        {t(textKeys.edit)}
                      </Button>
                    )}
                    {comment.editedAt && !comment.deletedAt ? (
                      <CommentInfo>
                        <Trans i18nKey="comments.edited">
                          Comment edited on
                          {`${moment(comment.editedAt).format("D.M.YYYY HH:mm")}`}
                        </Trans>
                      </CommentInfo>
                    ) : (
                      ""
                    )}
                  </MultilineText>
                </Accordion.Collapse>
              )}
            </div>
          ))}
        </Accordion>
      ) : (
        <p>{t(textKeys.noComments)}</p>
      )}
      <div className="d-flex justify-content-center">
        {comments.length < commentsCount && viewAllComments && (
          <DummyLink onClick={onShowMoreCommentsClick}>{t(textKeys.showMore)}</DummyLink>
        )}
      </div>
    </CommentListContainer>
  );
};

export default CommentList;
