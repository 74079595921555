import { gql } from "@apollo/client";

export const getCuratorAreaCompanies = gql`
  query getCuratorAreaCompanies($curatorAreas: [uuid!]) {
    company(
      where: {
        products: { postalAddresses: { postalArea: { city: { id: { _in: $curatorAreas } } } } }
      }
    ) {
      id
      businessName
      createdAt
      postalAddresses {
        city
      }
      websiteUrl
      contactDetails {
        email
        phone
      }
      datahubType
      productsAggregate: products_aggregate(
        where: { postalAddresses: { postalArea: { city: { id: { _in: $curatorAreas } } } } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;
