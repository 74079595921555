import React, { useCallback, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { RegisterOptions, useFormContext } from "react-hook-form";

import { useDebouncedCallback } from "../utils/debounce";
import { addHttpsIfNotPresent } from "../utils/formUtils";

const DEFAULT_DEBOUNCE_DELAY_MS = 2000;

type UrlInputProps = {
  name: string;
  defaultValue?: string | string[] | number;
  autoFillHttps?: boolean;
  rules?: RegisterOptions;
  isInvalid?: boolean;
  disabled?: boolean;
  debounceDelayMs?: number;
};

const UrlInput = ({
  name,
  defaultValue,
  autoFillHttps = true,
  rules,
  isInvalid,
  disabled,
  debounceDelayMs = DEFAULT_DEBOUNCE_DELAY_MS,
}: UrlInputProps) => {
  const { register, errors, setValue, trigger } = useFormContext();
  const initialRender = useRef(true);

  const addHttpsAndValidate = useCallback(
    (url: string) => {
      const trimmedUrl = url.trim();
      const value = autoFillHttps ? addHttpsIfNotPresent(trimmedUrl) : trimmedUrl;
      setValue(name, value, { shouldValidate: true });
    },
    [name, autoFillHttps, setValue]
  );

  const addHttpsDelayed = useDebouncedCallback(addHttpsAndValidate, debounceDelayMs);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (name && rules) {
      trigger(name);
    }
  }, [name, rules, initialRender, trigger]);

  return (
    <Form.Control
      type="url"
      name={name}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        addHttpsDelayed(e.target.value);
      }}
      isInvalid={isInvalid}
      defaultValue={defaultValue}
      ref={rules ? register(rules) : register()}
      className={errors[name] ? "is-invalid" : ""}
      disabled={disabled}
    />
  );
};

export default UrlInput;
