import { ActionMeta, OnChangeValue } from "react-select";
import { CheckboxOption, DatahubSelect } from "./DatahubSelect";
import React, { useMemo } from "react";
import { SelectOption, UserRole } from "../types";
import getCitiesInRegions, { CitiesQueryResult, City } from "../graphqlQueries/getCitiesInRegions";
import { getHasuraRoleContext, isDmoAllAreas, labelCompare } from "../utils/functions";

import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import DummyLink from "./DummyLink";
import keycloak from "../Keycloak";

type CitySelectProps = {
  onChange: (
    selectedOption: OnChangeValue<SelectOption, true>,
    actionMeta: ActionMeta<SelectOption>
  ) => void;
  values: SelectOption[];
  selectAll?: (selected: SelectOption[]) => void;
};

const CitySelect = ({ onChange, values, selectAll }: CitySelectProps) => {
  const { t } = useTranslation();
  const isAllAreasDmo = isDmoAllAreas(keycloak);
  const { data, loading } = useQuery<CitiesQueryResult>(getCitiesInRegions, {
    context: getHasuraRoleContext(UserRole.ViewOpenProducts),
  });
  const regions = data?.region;

  const groupedOptions = useMemo(() => {
    if (!regions) {
      return [];
    }
    return regions
      .map((region) => ({
        label: region.region,
        options: region.cities
          .map((city) => ({
            label: city.city,
            value: city.id,
          }))
          .sort(labelCompare),
      }))
      .sort(labelCompare);
  }, [regions]);

  if (loading) {
    return null;
  }
  const allCities =
    isAllAreasDmo && regions
      ? regions.map((r) => r.cities.map((c) => ({ label: c.city, value: c.id }))).flat()
      : [];

  const allSelected = allCities.length === values.length;

  return (
    <div>
      <DatahubSelect
        value={values.map((selectOption: SelectOption) => {
          if (!selectOption.label) {
            const foundCity = regions?.reduce<City | undefined>(
              (acc, curr) => acc || curr.cities.find((city) => city.id === selectOption.value),
              undefined
            );

            return {
              value: selectOption.value,
              label: foundCity?.city || "",
            };
          }

          return selectOption;
        })}
        onChange={onChange}
        options={groupedOptions}
        isMulti
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        hideSelectedOptions={false}
        isClearable={values.length !== allCities.length}
        components={{
          Option: CheckboxOption,
        }}
        placeholder={t("companyInfo.dmoSelectPlaceholder")}
      />
      {isAllAreasDmo && selectAll && (
        <div className="mt-3">
          <DummyLink onClick={() => selectAll(allSelected ? [] : allCities.sort(labelCompare))}>
            {allSelected ? t("common.unselectAll") : t("common.selectAll")}
          </DummyLink>
        </div>
      )}
    </div>
  );
};

export default CitySelect;
