import React from "react";
import ViewProductSectionHeader from "./ViewProductSectionHeader";
import ViewProductHeader from "./ViewProductHeader";
import { Row, Col } from "react-bootstrap";
import { PostalAddress } from "../../graphqlQueries/getProduct";
import { useTranslation } from "react-i18next";
import { createGoogleMapsCoordinateUrl, formatLocation, parseLocation } from "../../utils/location";
import { ContactDetail } from "../../types";
import { NotAvailable } from "./NotAvailable";

type ViewProductContactsAndLocationProps = {
  postalAddress?: PostalAddress;
  contactDetails: ContactDetail;
  businessName: string;
};

const ViewProductContactsAndLocation = ({
  postalAddress,
  contactDetails,
  businessName,
}: ViewProductContactsAndLocationProps) => {
  const { t } = useTranslation();

  const location = postalAddress?.location && parseLocation(postalAddress.location);

  return (
    <>
      <ViewProductSectionHeader className="mb-4">
        {t("viewProduct.contactsAndLocation")}
      </ViewProductSectionHeader>
      <Row>
        <Col xs="12" sm="6" md="6" lg="12" xl="6">
          <ViewProductHeader>{t("companyInfo.companyName")}</ViewProductHeader>
          <p>{businessName || <NotAvailable />}</p>
        </Col>
        <Col xs="12" sm="6" md="6" lg="12" xl="6">
          <ViewProductHeader>{t("companyInfo.phonenumber")}</ViewProductHeader>
          <p>{contactDetails.phone || <NotAvailable />}</p>
        </Col>
        <Col xs="12" sm="6" md="6" lg="12" xl="6">
          <ViewProductHeader>{t("companyInfo.email")}</ViewProductHeader>
          <p>{contactDetails.email || <NotAvailable />}</p>
        </Col>
        {postalAddress !== undefined && (
          <Col xs="12" sm="6" md="6" lg="12" xl="6">
            <ViewProductHeader>{t("viewProduct.location")}</ViewProductHeader>
            {postalAddress.streetName || postalAddress.postalCode || postalAddress.city ? (
              <p>
                {postalAddress.streetName}
                {postalAddress.streetName ? ", " : ""}
                {postalAddress.postalCode} {postalAddress.city}
              </p>
            ) : (
              <NotAvailable />
            )}
          </Col>
        )}
        <Col xs="12" sm="6" md="6" lg="12" xl="6">
          <ViewProductHeader>{t("viewProduct.coordinates")}</ViewProductHeader>
          {location ? (
            <>
              <p className="m-0">{formatLocation(location)}</p>
              <a
                href={createGoogleMapsCoordinateUrl(location)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("viewProduct.seeLocationLink")}
              </a>
            </>
          ) : (
            <NotAvailable />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ViewProductContactsAndLocation;
