import React from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import RadioBox from "../RadioBox";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

export enum RemoveApprovalType {
  RemoveFromChannels = "RemoveFromChannels",
  RemoveCurating = "RemoveCurating",
}

export type RemoveApprovalFormValues = {
  removeType: RemoveApprovalType;
  removeReason: string;
};

type RemoveApprovalModalProps = {
  onHide: () => void;
  onSubmit: (values: RemoveApprovalFormValues) => void;
};

const removeTypeOptions = [
  {
    value: RemoveApprovalType.RemoveFromChannels,
    headerKey: "removeApprovalModal.removeFromChannelsHeader",
    descriptionKey: "removeApprovalModal.removeFromChannelsDesc",
  },
  {
    value: RemoveApprovalType.RemoveCurating,
    headerKey: "removeApprovalModal.removeCuratingHeader",
    descriptionKey: "removeApprovalModal.removeCuratingDesc",
  },
];

const RemoveApprovalModal = ({ onHide, onSubmit }: RemoveApprovalModalProps) => {
  const { register, handleSubmit, errors, watch, setValue, formState } = useForm();
  const { isSubmitting } = formState;
  const { t } = useTranslation();

  const removeType = watch("removeType");

  return (
    <Modal show animation={false} onHide={onHide}>
      <Modal.Header>
        <h4 className="mb-0">{t("removeApprovalModal.header")}</h4>
      </Modal.Header>
      <Modal.Body className="px-5">
        <p className="color-gray-700">{t("removeApprovalModal.description")}</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {removeTypeOptions.map((type) => (
            <RadioBox
              name="removeType"
              key={type.value}
              id={type.value}
              value={type.value}
              error={!!errors.removeType}
              checked={removeType === type.value}
              ref={register({
                required: "validationErrors.required",
              })}
              onChange={() => {
                setValue("removeType", type.value);
              }}
              header={t(type.headerKey)}
              description={t(type.descriptionKey)}
            />
          ))}
          {errors.removeType && <p className="text-danger">{t(errors.removeType.message)}</p>}
          <Form.Group controlId="reason">
            <Form.Label>{t("removeApprovalModal.removeReasonLabel")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name="removeReason"
              className={errors.removeReason ? "is-invalid" : ""}
              ref={register({
                required: "validationErrors.required",
              })}
            />
            {errors.removeReason && <p className="text-danger">{t(errors.removeReason.message)}</p>}
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button onClick={() => onHide()} variant="secondary" className="mr-3">
              {t("removeApprovalModal.cancelProductApproval")}
            </Button>
            <Button type="submit">
              {isSubmitting && <Spinner size="sm" animation="border" className="mr-2 mb-1" />}
              {t("removeApprovalModal.submitProductApproval")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveApprovalModal;
