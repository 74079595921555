import React from "react";
import Icon, { IconName } from "../Icon";

type ProductListItemIconContainerProps = {
  children: React.ReactNode;
  iconName: IconName;
};

const ProductListItemIconContainer = ({
  children,
  iconName,
}: ProductListItemIconContainerProps) => (
  <div className="d-flex color-gray-600 text-small" style={{ flexBasis: "50%" }}>
    <Icon name={iconName} size="medium" className="d-block ml-n1" />
    <div>{children}</div>
  </div>
);

export default ProductListItemIconContainer;
