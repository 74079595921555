import { ApolloError } from "@apollo/client";
import React from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";

import { ProductQueryResult } from "../../graphqlQueries/getCompanyProducts";
import { getDefaultProductName, hasBrokenLink } from "../../utils/productFormUtils/productUtils";
import Loading from "../Loading";
import ProductListContainer from "../ProductListContainer";
import EmptyProductItem from "./EmptyProductItem";
import ProductListHeader from "./ProductListHeader";
import ProductListItem, { ProductListItemType } from "./ProductListItem";

type ProductListProps = {
  selectedProductId?: string;
  onNewProductClick: () => void;
  productsData: ProductQueryResult | undefined;
  productsError: ApolloError | undefined;
  productsPreviousData: ProductQueryResult | undefined;
  paging?: React.ReactNode;
};

const ProductList = React.forwardRef<HTMLDivElement, ProductListProps>(
  (
    {
      onNewProductClick,
      selectedProductId,
      productsData,
      productsError,
      productsPreviousData,
      paging,
    },
    ref
  ) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { path } = useRouteMatch();

    if (productsError) {
      return <p className="text-danger">{t("products.errorLoadingProducts")}</p>;
    }

    const products = productsData?.product ? productsData?.product : productsPreviousData?.product;

    if (!products) {
      return (
        <ProductListContainer pagination={!!paging}>
          <ProductListHeader onNewProductClick={onNewProductClick} />
          <div className="px-3">
            <Loading i18nKey="products.loadingProducts" />
          </div>
        </ProductListContainer>
      );
    }

    const listItems = products.map<ProductListItemType>((product) => {
      return {
        productType: product.type,
        images: product.productImages,
        name: getDefaultProductName(product),
        id: product.id,
        commentsCount: product.productCommentsAggregate.aggregate.count,
        newCommentsCount: product.productNewCommentsAggregate.aggregate.count,
        curationStatus: product.productCurations[0].curationStatus,
        productStatus: product.status,
        languages: product.productInformations.map((information) => information.language),
        hasBrokenLink: hasBrokenLink(product),
        externalProduct: product.externalSource,
      };
    });

    return (
      <ProductListContainer ref={ref} pagination={!!paging}>
        <ProductListHeader onNewProductClick={onNewProductClick} />
        {listItems && listItems.length > 0 ? (
          listItems.map((item) => (
            <ProductListItem
              selected={selectedProductId === item.id}
              item={item}
              key={item.id}
              onClick={(id) => {
                history.push(`${path}/${id}`);
              }}
            />
          ))
        ) : (
          <Row>
            <EmptyProductItem />
          </Row>
        )}
        {paging && paging}
      </ProductListContainer>
    );
  }
);

export default ProductList;
