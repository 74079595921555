import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../images/datahub-logo.svg";
import styled from "styled-components";
import LanguageDropdown from "../LanguageDropdown";

const FAQHeaderContainer = styled.header`
  margin: 0 auto;
  height: 65px;
`;

export const InstructionPageHolder = ({ children }: { children: React.ReactNode }) => (
  <div className="w-100">
    <div className="sticky-top border-bottom bg-white">
      <FAQHeaderContainer>
        <div className="pl-3 pr-lg-3 d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <NavLink to="/">
              <img style={{ height: "40px" }} alt="Visit Finland data hub" src={logo} />
            </NavLink>
          </div>
          <div>
            <LanguageDropdown />
          </div>
        </div>
      </FAQHeaderContainer>
    </div>
    <div className="w-100 h-100">{children}</div>
  </div>
);
