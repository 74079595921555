import React from "react";
import { useTranslation } from "react-i18next";
import { TCNotificationWrapper } from "./TCNotificationWrapper";
import { useQuery } from "@apollo/client";
import {
  getJoinRequestAggregate,
  GetJoinRequestAggregateResult,
} from "../../graphqlQueries/getJoinRequests";
import { getHasuraRoleContext } from "../../utils/functions";
import { CurationStatusesFilter, ProductNotificationFilter, UserRole } from "../../types";
import getCompanyNewCommentsCountQuery, {
  GetCompanyNewCommentsCountResult,
} from "../../graphqlQueries/getCompanyNewCommentsCount";
import Icon, { IconName } from "../Icon";
import { IconContainer } from "./DashboardComponents";
import { useHistory } from "react-router-dom";
import DummyLink from "../DummyLink";
import { FILTER_KEY, STATUS_KEY } from "../../utils/filterUtils";
import { COMPANY_PATH, PRODUCTS_PATH } from "../../utils/routeConstants";
import { Company } from "../../graphqlQueries/getCompanyNames";

export const TCNotifications: React.FunctionComponent<{
  company: Company;
}> = ({ company }) => {
  return (
    <div className="row no-gutters mt-4">
      <div className="col-xs-12 col-md-8 pr-0 pr-md-4 mb-4 mb-md-0">
        <ProductNotifications company={company} />
      </div>
      <div className={"col-xs-12 col-md-4"}>
        <JoinRequests company={company} />
      </div>
    </div>
  );
};

const JoinRequests: React.FunctionComponent<{
  company: Company;
}> = ({ company }) => {
  const { t } = useTranslation();
  const { data: joinRequestData } = useQuery<GetJoinRequestAggregateResult>(
    getJoinRequestAggregate,
    {
      variables: { companyId: company.id },
      context: getHasuraRoleContext(UserRole.ManageCompany),
      fetchPolicy: "network-only",
    }
  );

  const count = joinRequestData?.companyJoinRequestAggregate?.aggregate?.count ?? 0;

  return (
    <TCNotificationWrapper
      title={t("notifications.newJoinRequests")}
      titleLeft={`${count}${t("notifications.pieces")}`}
      icon={"user-plus"}
    >
      {count > 0 ? (
        <p className="mb-0">
          {t("notifications.toCompanyAccount")}
          <strong className="font-heavy"> {company.businessName} </strong>
          {t("notifications.requestsInUserManagement")}
        </p>
      ) : (
        <p className="mb-0">{t("userManagement.requestsNoResults")}</p>
      )}
    </TCNotificationWrapper>
  );
};

const ProductNotifications: React.FunctionComponent<{
  company: Company;
}> = ({ company }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data } = useQuery<GetCompanyNewCommentsCountResult>(getCompanyNewCommentsCountQuery, {
    variables: {
      companyId: company.id,
    },
    context: getHasuraRoleContext(UserRole.ManageProducts),
  });

  const onCommentClick = () => {
    history.push({
      pathname: `${COMPANY_PATH}/${company.id}${PRODUCTS_PATH}`,
      search: `${STATUS_KEY}=${CurationStatusesFilter.AllCompanyProducts}&${FILTER_KEY}=${ProductNotificationFilter.NewComment}`,
    });
  };

  const counts = data?.product?.map((p) => p.productNewCommentsAggregate?.aggregate?.count);
  const count = counts?.reduce((a, b) => a + b, 0) ?? 0;

  return (
    <TCNotificationWrapper title={t("notifications.productNotification")} icon={"bell"}>
      <div>
        <Pieces count={count} icon={"exclamation"} onClick={onCommentClick} />
      </div>
    </TCNotificationWrapper>
  );
};

const Pieces: React.FunctionComponent<{
  count: number;
  icon: IconName;
  onClick: () => void;
}> = ({ count, icon, onClick }) => {
  const { t } = useTranslation();
  return (
    <div
      className={"d-flex flex-column mr-auto align-items-center"}
      style={{ width: "fit-content" }}
    >
      <DummyLink
        className="d-flex align-items-center mb-1 text-decoration-none"
        style={{ color: "initial" }}
        onClick={onClick}
      >
        <IconContainer className="mr-1">
          <Icon color="dark-blue" name={icon} />
        </IconContainer>
        <p className="h2 font-heavy mb-0">
          {count}
          <span className="h3">{t("notifications.pieces")}</span>
        </p>
      </DummyLink>
      <div className="d-flex">
        <IconContainer className="mr-1">
          <Icon color="dark-blue" name="comment-alt" />
        </IconContainer>
        <span>{t("comments.newComments").toUpperCase()}</span>
      </div>
    </div>
  );
};
