import { gql } from "@apollo/client";

import { AffectedRowsResult } from "../types";

const deleteBusinessHoursMutation = gql`
  mutation DeleteBusinessHours($businessHoursId: uuid!) {
    deleteOpeningHours(
      where: { businessHoursPeriod: { businessHoursId: { _eq: $businessHoursId } } }
    ) {
      affected_rows
    }
    deleteBusinessHoursPeriod(where: { businessHoursId: { _eq: $businessHoursId } }) {
      affected_rows
    }
    deleteBusinessHours(where: { id: { _eq: $businessHoursId } }) {
      affected_rows
    }
  }
`;

export type DeleteBusinessHoursVariables = {
  businessHoursId: string;
};

export type DeleteBusinessHoursResult = {
  deleteOpeningHours: AffectedRowsResult;
  deleteBusinessHoursPeriod: AffectedRowsResult;
  deleteBusinessHours: AffectedRowsResult;
};

export default deleteBusinessHoursMutation;
