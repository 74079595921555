import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { getArray } from "../utils/functions";
import Icon from "./Icon";

const GuidelineContainer = styled.div`
  max-height: 80vh;
  padding-right: 4rem;
  padding-left: 3rem;
`;

const NoticeBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  margin-bottom: 2rem;
  min-height: 5em;
  background-color: var(--color-light-blue);
  border-left: solid 6px var(--color-blue);
`;

const ALT_TEXT_PARAGRAPHS = 3;
const GOOD_ALT_TEXT_PARAGRAPHS = 5;

export const AltTextGuidelineModal = ({ onHide }: { onHide: () => void }) => {
  const { t } = useTranslation();

  return (
    <Modal show size="lg" centered animation={false} onHide={() => onHide()}>
      <Modal.Header closeButton className="pl-0">
        <h3>{t("altTextGuideline.modalHeader")}</h3>
      </Modal.Header>
      <Modal.Body className="px-0">
        <GuidelineContainer className="overflow-auto">
          <h4 className="h6">{t("altTextGuideline.altTextFunctionHeader")}</h4>
          <ol className="ml-1 pl-3 mt-2 mb-4">
            <li>
              <p className="ml-2 color-gray-800">{t("altTextGuideline.function1")}</p>
            </li>
            <li>
              <p className="ml-2 color-gray-800">{t("altTextGuideline.function2")}</p>
            </li>
          </ol>

          <NoticeBox>
            <div className="col-2 col-md-1 pr-0 text-right">
              <Icon name="info" size="large" color="blue" />
            </div>
            <div className="col mt-1">
              <p>{t("altTextGuideline.notice")}</p>
            </div>
            <div className="col-1 col-md-3" />
          </NoticeBox>

          <div className="mt-5">
            <h4 className="h6">{t("altTextGuideline.exampleHeader")}</h4>
            {getArray(ALT_TEXT_PARAGRAPHS).map((_, i) => (
              <React.Fragment key={i}>
                <p className="ml-0 mb-0 color-gray-800">
                  {i + 1}. {t(`altTextGuideline.badAltText${i + 1}`)}
                </p>
                <p className="ml-0 color-gray-800 font-italic">
                  {t(`altTextGuideline.exampleImgAltText${i + 1}`)}
                </p>
              </React.Fragment>
            ))}
          </div>

          <div className="mt-5">
            <h4 className="h6">{t("altTextGuideline.goodAltTextHeader")}</h4>
            <ol className="ml-1 pl-3 mt-2">
              {getArray(GOOD_ALT_TEXT_PARAGRAPHS).map((_, i) => (
                <li key={i}>
                  <p className="ml-2 color-gray-800">{t(`altTextGuideline.goodAltText${i + 1}`)}</p>
                </li>
              ))}
            </ol>
          </div>
        </GuidelineContainer>
      </Modal.Body>
    </Modal>
  );
};
