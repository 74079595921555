import React from "react";
import { Button } from "react-bootstrap";
import { DataHubImage } from "../types";
import styled from "styled-components";

type ImageSizeOptions = "x-small" | "small" | "medium";

type ThumbnailProps = {
  image?: DataHubImage;
  imageUrl?: string;
  onDelete?: (deleted: DataHubImage) => void;
  onClick?: (clicked: DataHubImage) => void;
  altText: string;
  showDelete?: boolean;
  listItemStyle?: boolean;
  noCrop?: boolean;
  className?: string;
  size?: ImageSizeOptions;
};

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
`;

type ThumbnailContainerProps = {
  noBorder: boolean;
};

const ThumbnailContainer = styled.div<ThumbnailContainerProps>`
  cursor: pointer;
  border: ${({ noBorder }) => (noBorder ? "0" : "solid 1px #979797")};
`;

type ImageProps = {
  roundedCorners: boolean;
  small: boolean;
  size?: ImageSizeOptions;
};

const getImageSize = (small: boolean, size?: ImageSizeOptions) => {
  const sizePairings = {
    "x-small": "38px",
    small: "75px",
    medium: "145px",
  };

  if (!size) {
    return small ? sizePairings.small : sizePairings.medium;
  }

  return sizePairings[size];
};

const ThumbnailImage = styled.div<ImageProps>`
  width: ${({ size, small }) => getImageSize(small, size)};
  height: ${({ size, small }) => getImageSize(small, size)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: ${({ roundedCorners }) => (roundedCorners ? "8px" : "0px")};
`;

const Thumbnail = ({
  image,
  imageUrl,
  onDelete,
  altText,
  onClick,
  showDelete = false,
  listItemStyle = false,
  size,
  noCrop = false,
  className = "",
}: ThumbnailProps) => {
  return (
    <ThumbnailContainer
      noBorder={listItemStyle}
      className={`position-relative d-inline-block ${className}`}
      onClick={() => onClick && image && onClick(image)}
    >
      {noCrop ? (
        <img src={imageUrl ?? image?.thumbnailUrl} alt={altText} />
      ) : (
        <ThumbnailImage
          roundedCorners={listItemStyle}
          size={size}
          small={listItemStyle}
          style={{
            backgroundImage: `url("${imageUrl ?? image?.thumbnailUrl}")`,
          }}
          title={altText}
        />
      )}
      {showDelete && (
        <CloseButtonContainer>
          <Button
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (onDelete && image) {
                onDelete(image);
              }
              e.stopPropagation();
            }}
            variant="secondary"
            className="close py-1 px-2"
          >
            <span aria-hidden="true" className="h3">
              &times;
            </span>
          </Button>
        </CloseButtonContainer>
      )}
    </ThumbnailContainer>
  );
};

export default Thumbnail;
