import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
  FormSectionSubHeader,
  FormSubDescription,
  FormSubSection,
  FormSubSectionContent,
} from "../FormSection";
import { Form } from "react-bootstrap";
import CapacityInput from "./CapacityInput";

export const TransportationCapacitySection = ({ disabled = false }: { disabled?: boolean }) => {
  const { t } = useTranslation();
  const { errors, clearErrors, getValues } = useFormContext();

  const validateMinMax = (minField: boolean) => {
    const capacityMin = parseInt(getValues().capacityMin);
    const capacityMax = parseInt(getValues().capacityMax);

    if (minField && !capacityMin && capacityMin < 1) {
      return "validationErrors.invalidMinCapacity";
    } else if (capacityMax && capacityMin > capacityMax) {
      return "validationErrors.invalidMaxCapacity";
    } else {
      clearErrors(["capacityMin", "capacityMax"]);
    }
  };

  return (
    <FormSubSection>
      <FormSubDescription>
        <FormSectionSubHeader>{t("productInfo.passengerCapacityHeader")}</FormSectionSubHeader>
        {!disabled && <p>{t("productInfo.passengerCapacityDescription")}</p>}
      </FormSubDescription>
      <FormSubSectionContent>
        <Form.Label>{t("productInfo.passengerCapacityLabel")}</Form.Label>
        <CapacityInput validator={validateMinMax} disabled={disabled} />
        {errors.capacityMin && <p className="text-danger">{t(errors.capacityMin.message)}</p>}
        {errors.capacityMax && errors.capacityMax.message !== errors.capacityMin?.message && (
          <p className="text-danger">{t(errors.capacityMax.message)}</p>
        )}
      </FormSubSectionContent>
    </FormSubSection>
  );
};
