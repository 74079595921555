import React from "react";

import { OpeningHours, OpeningHoursException } from "../../types";
import ViewProductHeader from "../products/ViewProductHeader";

type ViewOpeningHoursExceptionsProps = {
  header: React.ReactNode;
  exceptions: OpeningHoursException[];
  renderException: (
    id: string,
    start: string,
    end: string,
    openingHours: OpeningHours[]
  ) => React.ReactNode;
  direction?: "row" | "column";
  wrap?: boolean;
};

const ViewOpeningHoursExceptions = ({
  header,
  exceptions,
  renderException,
  direction = "row",
  wrap = false,
}: ViewOpeningHoursExceptionsProps) => {
  return (
    <div className="d-flex flex-column">
      {typeof header === "string" ? <ViewProductHeader>{header}</ViewProductHeader> : header}
      <div className={`d-flex ${wrap ? "flex-wrap" : "flex-nowrap"} flex-${direction}`}>
        {exceptions.map((exception) => {
          const { id, start, end, openingHours } = exception;
          if (!start || !end) {
            return null;
          }
          return renderException(id, start, end, openingHours);
        })}
      </div>
    </div>
  );
};

export default ViewOpeningHoursExceptions;
