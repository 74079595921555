import "./i18n";
import "./css/bootstrap_theme.scss";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import Loading from "./components/Loading";
import keycloak from "./Keycloak";

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<Loading />}
      initOptions={{ pkceMethod: "S256" }}
    >
      <Router>
        <App />
      </Router>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
