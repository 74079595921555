import React from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

export type RejectProductFormValues = {
  removeReason: string;
};

type RejectProductModalProps = {
  onHide: () => void;
  onSubmit: (values: RejectProductFormValues) => void;
};

const RejectProductModal = ({ onHide, onSubmit }: RejectProductModalProps) => {
  const { register, handleSubmit, errors, formState } = useForm();
  const { isSubmitting } = formState;
  const { t } = useTranslation();

  return (
    <Modal show animation={false} onHide={onHide}>
      <Modal.Header>
        <h4 className="mb-0">{t("rejectProductModal.header")}</h4>
      </Modal.Header>
      <Modal.Body className="px-5">
        <p className="color-gray-700">{t("rejectProductModal.description")}</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {errors.removeType && <p className="text-danger">{t(errors.removeType.message)}</p>}
          <Form.Group controlId="reason">
            <Form.Label>{t("rejectProductModal.rejectReasonLabel")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name="removeReason"
              className={errors.removeReason ? "is-invalid" : ""}
              ref={register({
                required: "validationErrors.required",
              })}
            />
            {errors.removeReason && <p className="text-danger">{t(errors.removeReason.message)}</p>}
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button onClick={() => onHide()} variant="secondary" className="mr-3">
              {t("rejectProductModal.cancelButton")}
            </Button>
            <Button type="submit">
              {isSubmitting && <Spinner size="sm" animation="border" className="mr-2 mb-1" />}
              {t("rejectProductModal.rejectProductButton")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RejectProductModal;
