import { DeepMap, ErrorOption, FieldError } from "react-hook-form";
import { ProductPublishMode, TimeTuple } from "../types";

import { HasuradbProduct } from "../graphqlQueries/getProduct";
import { REQUIRED_ERROR_KEY } from "./validation";
import { TFunction } from "i18next";
import { capitalize } from "./functions";
import { getProductLinks } from "./productFormUtils/productUtils";
import moment from "moment";

// Add leading zero in case of single digits
export const padZero = (input?: string): string => {
  if (!input) {
    return "";
  }

  if (input.length === 1) {
    return `0${input}`;
  }

  return input;
};

const timesWithMidnightException = ([hours, minutes]: TimeTuple) => {
  if (hours === "24") {
    return [hours, "00"];
  }

  return [hours, minutes];
};

export const parseToHHMM = (times: TimeTuple | undefined): string | undefined => {
  if (!times) {
    return;
  }
  const arr = timesWithMidnightException(times).map((time) => {
    if (!time) {
      return undefined;
    }

    return padZero(time);
  });

  return arr.filter((o) => !!o).length > 0 ? arr.join(":") : undefined;
};

export const parseToTimeTuple = (time?: string): TimeTuple | undefined => {
  if (!time) {
    return undefined;
  }
  // Replace undefined indexes with 00
  const timeArr = time.split(":").map((o) => o || "00");
  return [timeArr[0], timeArr[1]];
};

type MaybeFieldError = FieldError | Record<string, unknown>;

const isFieldError = (obj: MaybeFieldError): obj is FieldError => {
  return !!(obj as FieldError).type;
};

const countFieldErrors = (acc: number, errorObj: unknown): number => {
  if (typeof errorObj !== "object" || errorObj === null) {
    return acc;
  }
  if (isFieldError(errorObj as Record<string, unknown>)) {
    return acc + 1;
  }
  return Object.values(errorObj).reduce(countFieldErrors, acc);
};

export const getErrorCount = <T extends Record<string, unknown>>(
  errors: DeepMap<T, FieldError>
): number => {
  return Object.values(errors).flat().reduce<number>(countFieldErrors, 0);
};

export const jumpToError = ({ trigger }: { trigger: () => void }) => {
  const tabs = Array.from(document.getElementsByClassName("error nav-item"));
  const invalidElements = Array.from(document.getElementsByClassName("is-invalid")) as Array<
    HTMLElement
  >;
  const element = invalidElements.find((e) => !!e.offsetParent);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "center" });
    element.focus({ preventScroll: true });
  } else if (tabs && tabs.length > 0) {
    const links = tabs[0].getElementsByTagName("a");
    if (links && links.length > 0) {
      const link = links[0];
      link.click();
      link.scrollIntoView({ behavior: "smooth", block: "center" });
      trigger();
    }
  }
};

export const addHttpsIfNotPresent = (url: string) => {
  if (url !== "") {
    const validator = new RegExp(/^https?:\/\//);
    if (!validator.test(url)) {
      return `https://${url}`;
    }
  }

  return url;
};

export const urlFormatValidator = (url: string, errorMessage: string) => {
  if (url) {
    const validator = new RegExp(/^https?:\/\/[-a-zA-ZöäåÖÄÅ0-9._]{2,286}\.[a-zöäå]{2,6}/);
    if (!validator.test(url)) {
      return errorMessage;
    }
    try {
      new URL(url);
    } catch (error) {
      return errorMessage;
    }
  }

  return true;
};

export const getRequiredValidator = (
  mode: ProductPublishMode,
  isAlwaysRequired = false,
  translator?: TFunction
) => {
  if (mode !== "draft" || isAlwaysRequired) {
    return (value: string) => {
      if (!value || value.trim() === "") {
        if (translator) {
          return translator(REQUIRED_ERROR_KEY);
        }
        return REQUIRED_ERROR_KEY;
      }
      return true;
    };
  }
  return () => true;
};

export function updateArrayWithProductId<T>(array: T[], productId: string) {
  return array.map((item) => ({ ...item, productId }));
}

export const searchAndSetErrorForInvalidUrl = (
  product: HasuradbProduct,
  setError: (name: string, error: ErrorOption) => void
) => {
  const { productLinks, webshopLinks, videoLinks } = getProductLinks(
    product.productInformations,
    product.productVideos
  );

  if (productLinks.length > 0) {
    productLinks.forEach((link, index) => {
      if (link?.invalidAt) {
        // Field name defined in ProductLanguageVersion.tsx
        setError(`details[${index}].productLink.url`, {
          type: "validate",
          message: "validationErrors.linkVerificationFailed",
        });
      }
    });
  }

  if (webshopLinks.length > 0) {
    webshopLinks.forEach((link, index) => {
      if (link?.invalidAt) {
        // Field name defined in ProductLanguageVersion.tsx
        setError(`details[${index}].webshopLink.url`, {
          type: "validate",
          message: "validationErrors.linkVerificationFailed",
        });
      }
    });
  }

  if (videoLinks.length > 0) {
    videoLinks.forEach((link, index) => {
      if (link?.invalidAt) {
        // Field name defined in VideoSection.tsx
        setError(`videos[${index}].videoLink.url`, {
          type: "validate",
          message: "validationErrors.linkVerificationFailed",
        });
      }
    });
  }
};

export const capitalizeAddressName = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const validateDateRange = (
  startDate?: string | moment.Moment | null,
  endDate?: string | moment.Moment | null,
  isDraft = false
): string | true => {
  if (!isDraft) {
    if (!startDate && !endDate) {
      return "validationErrors.required";
    }

    if (!startDate) {
      return "validationErrors.startDateRequired";
    }

    if (!endDate) {
      return "validationErrors.endDateRequired";
    }
  }

  return true;
};

export const allowedCityFormat = /^[a-zA-ZåäöÅÄÖ]{2,}(?:(?:-| - | )?[a-zA-ZåäöÅÄÖ]{2,})?$/;

export const capitalizeCityNames = (cityName: string) => {
  const hasSpaceAndHyphen = /[ - ]/.test(cityName);
  const hasSpace = /[\s]/.test(cityName);
  const hasHyphen = /[-]/.test(cityName);

  if (hasSpaceAndHyphen) {
    return cityName
      .split(" - ")
      .map((name) => capitalize(name))
      .join(" - ");
  }

  if (hasSpace) {
    return cityName.charAt(0).toUpperCase() + cityName.slice(1);
  }

  if (hasHyphen) {
    return cityName
      .split("-")
      .map((name) => capitalize(name))
      .join("-");
  }

  return capitalize(cityName);
};
