import { gql } from "@apollo/client";

const removeCompanyUserQuery = gql`
  mutation removeCompanyUser($companyId: uuid!, $userId: uuid!) {
    RemoveCompanyUser(companyId: $companyId, userId: $userId) {
      message
    }
  }
`;

export type RemoveCompanyUserVariables = {
  companyId: string;
  userId: string;
};

export type RemoveCompanyUserResult = {
  RemoveCompanyUser: {
    message: string;
  };
};

export default removeCompanyUserQuery;
