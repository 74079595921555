import { Redirect, Route, Switch } from "react-router-dom";
import React from "react";
import InstructionsAndFaq from "../pages/instructionsAndFaq/InstructionsAndFaq";
import InstructionsRolePage from "../pages/instructionsAndFaq/InstructionsRolePage";
import { INSTRUCTIONS_PATH, FAQ_PATH, ROOT_PATH } from "../utils/routeConstants";

const CMSRoutes = () => (
  <div>
    <Switch>
      <Route exact path={`${FAQ_PATH}/:preview?`}>
        <InstructionsAndFaq />
      </Route>
      <Route exact path={`${FAQ_PATH}${INSTRUCTIONS_PATH}/:role/:preview?/:id?`}>
        <InstructionsRolePage />
      </Route>
      <Route path={ROOT_PATH}>
        <Redirect to={ROOT_PATH} />
      </Route>
    </Switch>
  </div>
);

export default CMSRoutes;
