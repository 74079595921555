import React from "react";
import { MaintenanceDataOut } from "../../types/bannerTypes";
import Icon from "../Icon";
import { DataHubButton } from "../DataHubButton";

type EditMaintenanceProps = {
  data: MaintenanceDataOut;
  openEditForm: (open: MaintenanceDataOut) => void;
};

export const EditMaintenance = ({ data, openEditForm }: EditMaintenanceProps) => (
  <DataHubButton className="px-0 mr-2" onClick={() => openEditForm(data)} variant="ghost" size="sm">
    <Icon name="edit" size="medium" color="primary-dark" />
  </DataHubButton>
);
