import { gql } from "@apollo/client";

export const faqSearch = gql`
  query SearchInstructions($search: String!, $locale: [Locale!]!) {
    instructions(where: { _search: $search }, locales: $locale) {
      id
      title
      roleTags
    }
    faqs(where: { _search: $search }) {
      instruction(first: 1) {
        id
        roleTags
      }
      question
    }
  }
`;
