import { gql } from "@apollo/client";

import { AffectedRowsResult, OpeningHours } from "../types";

const updateOpeningHoursMutation = gql`
  mutation UpdateOpeningHours(
    $businessHoursId: uuid!
    $openingHoursData: [hasuradb_business_hours_period_insert_input!]!
  ) {
    deleteOpeningHours(
      where: { businessHoursPeriod: { businessHoursId: { _eq: $businessHoursId } } }
    ) {
      affected_rows
    }
    deleteBusinessHoursPeriod(where: { businessHoursId: { _eq: $businessHoursId } }) {
      affected_rows
    }
    insertBusinessHoursPeriod(objects: $openingHoursData) {
      affected_rows
    }
  }
`;

export type BusinessHoursPeriodInsertInput = {
  businessHoursId: string;
  defaultPeriod: boolean;
  start: string | null;
  end: string | null;
  openingHours: {
    data: OpeningHours[];
  } | null;
};

export type UpdateOpeningHoursVariables = {
  businessHoursId: string;
  openingHoursData: BusinessHoursPeriodInsertInput[];
};

export type UpdateOpeningHoursResult = {
  deleteOpeningHours: AffectedRowsResult;
  deleteBusinessHoursPeriod: AffectedRowsResult;
  insertBusinessHoursPeriod: AffectedRowsResult;
};

export default updateOpeningHoursMutation;
