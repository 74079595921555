import moment, { Moment } from "moment";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import fi from "date-fns/locale/fi";
import en from "date-fns/locale/en-US";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import { useIsSmallScreen } from "./MediaQuery";
import { Button } from "react-bootstrap";

type DateRangeProps = {
  value: { endDate: moment.Moment | null; startDate: moment.Moment | null };
  onChange: (startDate: moment.Moment | null, endDate: moment.Moment | null) => void;
  id: string;
  disabled?: boolean;
};
const getDate = (value: Moment | null) => (value ? moment(value).toDate() : null);
const getMoment = (value: Date | null) => (value ? moment(value) : null);

const DateRange = ({ onChange, value, id, disabled }: DateRangeProps) => {
  const { i18n, t } = useTranslation();
  const isMobile = useIsSmallScreen();
  const startRef = useRef<ReactDatePicker | null>(null);
  const endRef = useRef<ReactDatePicker | null>(null);
  const isFinnish = i18n.language === "fi";
  const end = getDate(value.endDate);
  const start = getDate(value.startDate);

  const onStartSelect = (date: Date | null) => {
    const startDate = getMoment(date);
    startRef.current?.setOpen(false);
    endRef.current?.setOpen(true);
    onChange(startDate, null);
  };

  const onEndSelect = (date: Date | null) => {
    const startDate = value.startDate;
    const endDate = getMoment(date);
    endRef.current?.setOpen(false);
    onChange(startDate, startDate?.isAfter(endDate) ? null : endDate);
  };

  return (
    <div className="d-flex">
      <DatePicker
        selectsStart
        ref={startRef}
        id={`${id}_s`}
        disabled={disabled}
        withPortal={isMobile}
        monthsShown={2}
        locale={isFinnish ? fi : en}
        selected={start}
        onChange={onStartSelect}
        startDate={start}
        endDate={end}
        dateFormat="d.M.yyyy"
        placeholderText={t("datepicker.placeholder")}
        wrapperClassName={"autoWidth"}
      />
      <span className={"mx-2"} style={{ alignSelf: "center" }}>
        -
      </span>
      <DatePicker
        selectsEnd
        ref={endRef}
        id={`${id}_e`}
        minDate={start}
        disabled={disabled}
        withPortal={isMobile}
        monthsShown={2}
        locale={isFinnish ? fi : en}
        selected={end}
        onChange={onEndSelect}
        startDate={start}
        endDate={end}
        openToDate={start || new Date()}
        dateFormat="d.M.yyyy"
        placeholderText={t("datepicker.placeholder")}
        wrapperClassName={"autoWidth"}
      />
      {!disabled && (value.startDate || value.endDate) && (
        <Button variant="ghost ml-3" onClick={() => onChange(null, null)}>
          {t("comments.clear")}
        </Button>
      )}
    </div>
  );
};

export default DateRange;
