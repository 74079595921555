import { FormDescription, FormSection, FormSectionHeader } from "../FormSection";
import {
  HasuraContactDetail,
  HasuraPostalAddress,
} from "../../graphqlQueries/getSingleCompanyInfo";

import ContactDetailsSection from "./ContactDetailsSection";
import LocationDetailsSection from "./LocationDetailsSection";
import { ProductPublishMode } from "../../types";
import React from "react";
import { useTranslation } from "react-i18next";

type LocationDetailsSectionProps = {
  companyAddress?: HasuraPostalAddress;
  companyContactDetails?: HasuraContactDetail;
  publishMode: ProductPublishMode;
  isProductGroup?: boolean;
};

const LocationAndContactDetailsSection = ({
  companyAddress,
  companyContactDetails,
  publishMode,
  isProductGroup,
}: LocationDetailsSectionProps) => {
  const { t } = useTranslation();

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader>{t("productInfo.locationAndContactHeader")}</FormSectionHeader>
      </FormDescription>
      <div className="border-left" />

      <LocationDetailsSection
        companyAddress={companyAddress}
        publishMode={publishMode}
        isProductGroup={isProductGroup}
      />
      <ContactDetailsSection
        companyContactDetails={companyContactDetails}
        publishMode={publishMode}
        isProductGroup={isProductGroup}
      />
    </FormSection>
  );
};

export default LocationAndContactDetailsSection;
