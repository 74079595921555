import {
  createProductGroupPermissionsMutation,
  CreateProductGroupPermissionsResult,
  CreateProductGroupPermissionsVariables,
} from "../../graphqlQueries/createProductGroupPermissions";
import {
  deleteProductGroupMutation,
  DeleteProductGroupResult,
  DeleteProductGroupVariables,
} from "../../graphqlQueries/deleteProductGroup";
import {
  getProductGroupDataQuery,
  GetProductGroupDataResult,
  GetProductGroupDataVariables,
} from "../../graphqlQueries/productGroup/getProductGroupData";
import {
  getProductGroupsQuery,
  GetProductGroupsResult,
} from "../../graphqlQueries/productGroup/getProductGroups";
import GroupForm, {
  company,
  ProductGroupFormValues,
  ProductGroupParticipantFormValue,
  user,
} from "./GroupForm";
import {
  lockProductGroupMutation,
  LockProductGroupResult,
  LockProductGroupVariables,
} from "../../graphqlQueries/lockProductGroup";
import { MutationQueryReducersMap, useApolloClient, useQuery } from "@apollo/client";
import {
  ProductGroupImage,
  ProductGroupProductInformation,
  updateProductGroupMutation,
  UpdateProductGroupResult,
  UpdateProductGroupVariables,
} from "../../graphqlQueries/productGroup/updateProductGroup";
import { AlertProps, ProductGroupProductInput, ProductGroupRole, UserRole } from "../../types";
import {
  unLockProductGroupMutation,
  UnLockProductGroupResult,
  UnLockProductGroupVariables,
} from "../../graphqlQueries/unLockProductGroup";
import {
  updateProductGroupProductsMutation,
  UpdateProductGroupProductsResult,
  UpdateProductGroupProductsVariables,
} from "../../graphqlQueries/productGroup/updateProductGroupProducts";
import {
  removeProductGroupPermissionsMutation,
  removeProductGroupPermissionsResult,
  removeProductGroupPermissionsVariables,
} from "../../graphqlQueries/removeProductGroupPermissions";
import {
  updateProductGroupPermissionByPkMutation,
  UpdateProductGroupPermissionByPkResult,
  UpdateProductGroupPermissionByPkVariables,
} from "../../graphqlQueries/updateProductGroupPermissionByPk";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { EDIT_PRODUCT_GROUP } from "./productGroupRouteConstants";
import { HasuradbProduct } from "../../graphqlQueries/getProduct";
import Loading from "../Loading";
import React, { useState } from "react";
import { getHasuraRoleContext } from "../../utils/functions";
import { getOperationName } from "@apollo/client/utilities";
import {
  getEditPath,
  getProductGroupRole,
  getProductGroupVariables,
} from "../../utils/productGroupUtils";
import { useTranslation } from "react-i18next";
import { userInfoVar } from "../../utils/ApolloCache";
import {
  getProductGroupPermissionsQuery,
  GetProductGroupPermissionsResult,
  GetProductGroupPermissionsVariables,
} from "../../graphqlQueries/productGroup/getProductGroupPermissions";

type EditGroupFormProps = {
  rootPagePath: string;
};

export type GroupIdParam = {
  groupId: string;
};

const scrollToNotification = () => {
  const notification = document.getElementById("locked-group-notification-alert");
  notification?.scrollIntoView({ behavior: "smooth" });
};

const getProductGroupsQueryUpdater = ():
  | MutationQueryReducersMap<DeleteProductGroupResult>
  | undefined => {
  const queryName = getOperationName(getProductGroupsQuery);
  if (!queryName) {
    return undefined;
  }
  return {
    [queryName]: (prev, options) => {
      const oldResult = prev as GetProductGroupsResult;
      const deletedGroupId = options.mutationResult.data?.updateProductGroupByPk.id;
      if (!deletedGroupId) {
        return oldResult;
      }
      const newResult: GetProductGroupsResult = {
        productGroup: oldResult.productGroup.filter((group) => group.id !== deletedGroupId),
      };
      return newResult;
    },
  };
};

const EditGroupForm: React.FunctionComponent<EditGroupFormProps> = ({ rootPagePath }) => {
  const history = useHistory();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const location = useLocation<{ alert: AlertProps } | undefined>();
  const [alert, setAlert] = useState<AlertProps | undefined>(location.state?.alert);
  const editParams = useRouteMatch<GroupIdParam>(`${rootPagePath}${EDIT_PRODUCT_GROUP}`)?.params;
  const groupId = editParams?.groupId ?? "";
  const currentUserId = userInfoVar()?.userId;
  const currentCompanyIds = userInfoVar()?.companyIds;

  const { data: userRoles } = useQuery<
    GetProductGroupPermissionsResult,
    GetProductGroupPermissionsVariables
  >(getProductGroupPermissionsQuery, {
    variables: { groupId },
    fetchPolicy: "network-only",
    context: getHasuraRoleContext(UserRole.ManageProductGroup),
  });

  const currentUserRole = getProductGroupRole(
    userRoles?.productGroupByPk,
    currentUserId,
    currentCompanyIds
  );
  const canManage =
    currentUserRole === ProductGroupRole.Admin || currentUserRole === ProductGroupRole.Manager;

  const { data: groupData, loading: groupDataLoading, error: groupDataError } = useQuery<
    GetProductGroupDataResult,
    GetProductGroupDataVariables
  >(getProductGroupDataQuery(canManage), {
    variables: { groupId },
    fetchPolicy: "network-only",
    context: getHasuraRoleContext(UserRole.ManageProductGroup),
  });

  const editedProductDetailsFromDb = groupData?.productGroupByPk?.products
    .map(({ product }: { product: HasuradbProduct }) => {
      return product?.productGroupProductInformations;
    })
    ?.map((information) => {
      return information?.map((info) => info);
    })
    .flat();

  if (groupDataLoading || !currentUserRole) {
    return <Loading />;
  }

  if (!groupData || groupDataError) {
    return <p>{t("common.error")}</p>;
  }

  const displayAlert = (err: Error) => {
    console.error(err);
    setAlert({ type: "danger", text: err.message });
  };

  const onLock = async () => {
    try {
      await apolloClient.mutate<LockProductGroupResult, LockProductGroupVariables>({
        mutation: lockProductGroupMutation,
        variables: {
          id: groupId,
        },
        context: getHasuraRoleContext(UserRole.ManageProductGroup),
      });
      scrollToNotification();
    } catch (err) {
      const error = err as Error;
      displayAlert(error);
    }
  };

  const onUnLock = async () => {
    try {
      await apolloClient.mutate<UnLockProductGroupResult, UnLockProductGroupVariables>({
        mutation: unLockProductGroupMutation,
        variables: {
          id: groupId,
        },
        context: getHasuraRoleContext(UserRole.ManageProductGroup),
      });
      scrollToNotification();
    } catch (err) {
      const error = err as Error;
      displayAlert(error);
    }
  };

  const onSubmit = async (
    formValues: ProductGroupFormValues & {
      currentOrganizations: ProductGroupParticipantFormValue[];
      currentUsers: ProductGroupParticipantFormValue[];
    },
    products: ProductGroupProductInput[],
    editedProductDetails?: ProductGroupProductInformation[],
    editedProductImages?: ProductGroupImage[]
  ) => {
    const {
      currentOrganizations,
      currentUsers,
      usersFromDb: originalUsers,
      organizationsFromDb: originalOrganizations,
      emailsToInvite,
      organizationsToInvite,
      rolesToChange,
    } = formValues;

    const variables: UpdateProductGroupVariables = getProductGroupVariables({
      groupId,
      products,
      productGroup: {
        name: formValues.groupName,
        description: formValues.groupInstructions,
      },
      editedProductImages,
      editedProductDetailsFromDb,
      editedProductDetails,
    });

    const hasEditedProductImages = editedProductImages && editedProductImages?.length > 0;

    try {
      if (canManage) {
        await apolloClient.mutate<UpdateProductGroupResult, UpdateProductGroupVariables>({
          mutation: updateProductGroupMutation(hasEditedProductImages),
          variables,
          context: getHasuraRoleContext(UserRole.ManageProductGroup),
          refetchQueries: [getProductGroupsQuery, getProductGroupDataQuery(canManage)],
        });
      } else if (currentUserRole === ProductGroupRole.Editor) {
        const oldProducts = groupData.productGroupByPk.products;

        const addedProducts = products.filter(
          (p) => !oldProducts.find((gp) => gp.product.id === p.productId)
        );

        const removedProducts = oldProducts
          .filter((gp) => !products.find((p) => gp.product.id === p.productId))
          .map((dp) => ({ productId: dp.product.id }));

        await apolloClient.mutate<
          UpdateProductGroupProductsResult,
          UpdateProductGroupProductsVariables
        >({
          mutation: updateProductGroupProductsMutation(hasEditedProductImages),
          variables: {
            ...variables,
            addedProducts,
            editedProductIds: [...addedProducts, ...removedProducts].map((p) => p.productId),
          },
          context: getHasuraRoleContext(UserRole.ManageProductGroup),
          refetchQueries: [getProductGroupsQuery, getProductGroupDataQuery(canManage)],
        });
      } else {
        displayAlert(new Error("User does not have priviledges to update product group"));
        return;
      }
    } catch (err) {
      const error = err as Error;
      displayAlert(error);
      return;
    }

    try {
      // Only admin and manager can edit users and orgnizations
      if (canManage) {
        const usersToRemove = originalUsers?.filter(
          (user) => !currentUsers.find((currentUser) => currentUser.id === user.id)
        );
        const organizationsToRemove = originalOrganizations?.filter(
          (organization) =>
            !currentOrganizations.find(
              (currentOrganization) => currentOrganization.id === organization.id
            )
        );

        const newOrganizations = organizationsToInvite?.map((organization) => ({
          companyId: organization.value,
          role: ProductGroupRole.Editor,
        }));

        const newUsers = emailsToInvite?.map((user) => ({
          userId: user.value,
          role: ProductGroupRole.Editor,
        }));

        // If there are new organizations or users to be added, add them
        if (
          (newOrganizations && newOrganizations.length > 0) ||
          (newUsers && newUsers.length > 0)
        ) {
          await apolloClient.mutate<
            CreateProductGroupPermissionsResult,
            CreateProductGroupPermissionsVariables
          >({
            mutation: createProductGroupPermissionsMutation,
            variables: {
              productGroupId: groupId,
              users: newUsers,
              companies: newOrganizations,
            },
            context: getHasuraRoleContext(UserRole.ManageProductGroup),
            refetchQueries: [getProductGroupDataQuery(canManage)],
          });
        }
        // If there are users or organizations to be removed, remove them
        if (
          (usersToRemove && usersToRemove.length > 0) ||
          (organizationsToRemove && organizationsToRemove.length > 0)
        ) {
          await apolloClient.mutate<
            removeProductGroupPermissionsResult,
            removeProductGroupPermissionsVariables
          >({
            mutation: removeProductGroupPermissionsMutation,
            variables: {
              productGroupId: groupId,
              permissionRowIds: [
                ...(usersToRemove?.map((user) => user.id) || []),
                ...(organizationsToRemove?.map((organization) => organization.id) || []),
              ],
            },
            context: getHasuraRoleContext(UserRole.ManageProductGroup),
            refetchQueries: [getProductGroupDataQuery(canManage)],
          });
        }

        // If there are roles to be changed, change them
        if (rolesToChange) {
          const toUpdate = (
            e: Record<string, { label: string; value: string } | undefined> = {},
            original: ProductGroupParticipantFormValue[] | undefined = [],
            isUser?: boolean
          ) =>
            Object.entries(e)
              .filter(([k, v]) => original?.find((o) => o.value === k)?.role !== v?.value)
              .map(([k, v]) => ({
                id:
                  groupData.productGroupByPk.permissions.find((p) =>
                    isUser ? p.user?.id === k : p.company?.id === k
                  )?.id ?? "",
                role: v?.value ?? "",
              }));

          const roles = toUpdate(rolesToChange?.company, originalOrganizations).concat(
            toUpdate(rolesToChange?.user, originalUsers, true)
          );

          const updateRole = async ({ id, role = "" }: { id: string; role?: string }) =>
            await apolloClient.mutate<
              UpdateProductGroupPermissionByPkResult,
              UpdateProductGroupPermissionByPkVariables
            >({
              mutation: updateProductGroupPermissionByPkMutation,
              variables: { id, role, groupId },
              context: getHasuraRoleContext(UserRole.ManageProductGroup),
              refetchQueries: [getProductGroupPermissionsQuery],
            });

          await Promise.all(roles.map((role) => updateRole(role)));
        }
      }
      history.replace(getEditPath(rootPagePath, groupId));
      setAlert({ type: "success", text: t("common.success") });
    } catch (err) {
      const error = err as Error;
      displayAlert(error);
    }
  };

  const onRemove = async () => {
    const variables = {
      id: groupId,
    };
    try {
      await apolloClient.mutate<DeleteProductGroupResult, DeleteProductGroupVariables>({
        mutation: deleteProductGroupMutation,
        variables,
        context: getHasuraRoleContext(UserRole.ManageProductGroup),
        updateQueries: getProductGroupsQueryUpdater(),
      });
      history.push(rootPagePath);
    } catch (err) {
      const error = err as Error;
      displayAlert(error);
    }
  };

  return (
    <GroupForm
      rootPagePath={rootPagePath}
      groupData={groupData.productGroupByPk}
      organizationsFromDb={groupData?.productGroupByPk?.permissions
        .filter((permission) => permission.company)
        .map((permission) => company({ permission }))}
      usersFromDb={groupData?.productGroupByPk?.permissions
        .filter((permission) => permission.user)
        .map((permission) => user({ permission }))}
      onSubmit={onSubmit}
      onRemove={onRemove}
      onLock={onLock}
      onUnLock={onUnLock}
      alert={alert}
      closeAlert={() => setAlert(undefined)}
      productGroupRole={currentUserRole}
      productsFromDb={groupData?.productGroupByPk.products}
    />
  );
};

export default EditGroupForm;
