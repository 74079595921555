import { useQuery } from "@apollo/client";
import React from "react";

import getCompanyNewCommentsCountQuery, {
  GetCompanyNewCommentsCountResult,
} from "../../graphqlQueries/getCompanyNewCommentsCount";
import { UserRole } from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";
import { useCompanyId } from "../hooks/useCompanyId";
import NavigationMenuLink, { NavigationMenuVariant } from "./NavigationMenuLink";

type NavigationMenuLinkProps = {
  to: string;
  i18n: string;
  exact?: boolean;
  variant?: NavigationMenuVariant;
};

const MyProductsMenuLink = ({ to, i18n, variant }: NavigationMenuLinkProps) => {
  const [companyId] = useCompanyId();

  const { data } = useQuery<GetCompanyNewCommentsCountResult>(getCompanyNewCommentsCountQuery, {
    variables: {
      companyId: companyId,
    },
    context: getHasuraRoleContext(UserRole.ManageProducts),
  });

  const anyNewComments = !data
    ? false
    : data.product.some((product) => product.productNewCommentsAggregate.aggregate.count > 0);

  return (
    <NavigationMenuLink
      i18n={i18n}
      showNotificationIcon={anyNewComments}
      to={to}
      variant={variant}
    />
  );
};

export default MyProductsMenuLink;
