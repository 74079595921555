import {
  getJoinRequestAggregateForVF,
  getJoinRequestDMODataForVF,
  JoinRequestData,
  JoinRequestDMODataForVF,
} from "../../graphqlQueries/getJoinRequests";
import deleteCompany, {
  DeleteCompanyMutationResult,
  DeleteCompanyVariables,
} from "../../graphqlQueries/deleteCompany";

import JoinRequestGrid from "../../components/userManagement/JoinRequestGrid";
import Notification from "../Notification";
import React from "react";
import { UserDetailRowData } from "../userManagement/JoinRequestCard";
import { UserRole } from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";
import getUserProfilesQuery from "../../graphqlQueries/getUserProfiles";
import moment from "moment";
import { useMutation } from "@apollo/client";
import useProcessJoinRequest from "../hooks/useProcessJoinRequest";
import useRequestNotifications from "../hooks/useNotifications";
import { useTranslation } from "react-i18next";

const DmoJoinRequests = ({ dmoJoinRequests }: { dmoJoinRequests: JoinRequestDMODataForVF[] }) => {
  const { t } = useTranslation();
  const { approveUser, denyUser } = useProcessJoinRequest(UserRole.ManageDatahub);
  const { notifications, addNotification, removeNotification } = useRequestNotifications();

  const generateUserDetailRowData = (joinRequest: JoinRequestData | JoinRequestDMODataForVF) => {
    if ("company" in joinRequest) {
      const dmoRegionsArray =
        joinRequest.company?.dmoAreas.map((area) => area.curationArea.city) ?? [];

      const requestedAt = moment(joinRequest.createdAt).format("D.M.YYYY");

      return [
        {
          title: t("dashboard.joinRequestMarketingName"),
          value: joinRequest.company?.businessName,
        },
        { title: t("dashboard.joinRequestDmoRegions"), value: dmoRegionsArray.join(", ") },
        { title: t("dashboard.joinRequestOfficialName"), value: joinRequest.company?.officialName },
        {
          title: t("dashboard.joinRequestBusinessId"),
          value: joinRequest.company?.businessEntity.businessId,
        },
        { title: t("dashboard.joinRequestRequestedAt"), value: requestedAt },
      ];
    } else {
      return [] as UserDetailRowData[];
    }
  };

  const [markCompanyDeleted] = useMutation<DeleteCompanyMutationResult, DeleteCompanyVariables>(
    deleteCompany
  );

  const removeCompany = (id: string) => {
    const joinReqFound = dmoJoinRequests.find((jr) => jr.id === id);
    if (joinReqFound) {
      const companyId = joinReqFound.company?.id;
      companyId &&
        void markCompanyDeleted({
          variables: { id: companyId },
          context: getHasuraRoleContext(UserRole.ManageDatahub),
        });
    }
  };

  return (
    <>
      {notifications.map((data) => (
        <Notification
          className="mb-1"
          key={data.key}
          type={data.resultStatus === "ok" ? "success" : "danger"}
          onClose={() => removeNotification(data.key)}
        >
          {data.message}
        </Notification>
      ))}
      {dmoJoinRequests?.length > 0 && (
        <JoinRequestGrid
          customColumnClass="col-lg-5 col-sm-6 col-12 pl-2 pr-2 mb-3"
          requests={dmoJoinRequests}
          cardDetailsGenerator={generateUserDetailRowData}
          onDeny={(id) => {
            denyUser(
              id,
              [getJoinRequestAggregateForVF, getJoinRequestDMODataForVF],
              addNotification
            );
            removeCompany(id);
          }}
          onApprove={(id) => {
            void approveUser(
              id,
              [getUserProfilesQuery, getJoinRequestDMODataForVF, getJoinRequestAggregateForVF],
              addNotification
            );
          }}
          showUserEmail
          showInRow
        />
      )}
    </>
  );
};

export default DmoJoinRequests;
