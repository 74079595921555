import {
  FormSectionSubHeader,
  FormSubDescription,
  FormSubSection,
  FormSubSectionContent,
} from "../FormSection";

import { AccessibilityType } from "../../types";
import ExternalLink from "../ExternalLink";
import { Form } from "react-bootstrap";
import InputContainer from "./InputContainer";
import RadioButton from "../RadioButton";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useIsMediumOrSmaller } from "../MediaQuery";
import { useTranslation } from "react-i18next";

const AccessibilitySection: React.FunctionComponent<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  const isMediumOrSmallerScreen = useIsMediumOrSmaller();

  return (
    <FormSubSection>
      <FormSubDescription>
        <FormSectionSubHeader>{t("productInfo.accessibilitySubHeader")}</FormSectionSubHeader>
        {!disabled && (
          <p>
            {t("productInfo.accessibilityDescription")}
            <ExternalLink className="d-block" href={t("productInfo.accessibleReadMoreUrl")}>
              {t("productInfo.accessibleReadMoreLink")}
            </ExternalLink>
          </p>
        )}
      </FormSubDescription>
      <FormSubSectionContent>
        {!isMediumOrSmallerScreen && (
          <Form.Label className="pb-2">{t("productInfo.accessibilitySubHeader")}</Form.Label>
        )}
        <InputContainer>
          <RadioButton
            id="accessible"
            name="accessible"
            value={AccessibilityType.Accessible}
            ref={register()}
            label={t("productInfo.accessible")}
            disabled={disabled}
          />
          <RadioButton
            id="notAccessible"
            name="accessible"
            value={AccessibilityType.NotAccessible}
            ref={register()}
            label={t("productInfo.notAccessible")}
            disabled={disabled}
          />
          <RadioButton
            id="undefined"
            name="accessible"
            value={AccessibilityType.Undefined}
            ref={register()}
            label={t("productInfo.accessibilityUndefined")}
            defaultChecked
            disabled={disabled}
          />
        </InputContainer>
      </FormSubSectionContent>
    </FormSubSection>
  );
};

export default AccessibilitySection;
