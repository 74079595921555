import { useLazyQuery } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";

import { useCompanyId } from "./components/hooks/useCompanyId";
import Routes from "./components/Routes";
import {
  GetJoinRequestsForUserResponse,
  GetJoinRequestsForUserVariables,
  getJoinRequestsForUser,
} from "./graphqlQueries/getJoinRequests";
import { DatahubAccessToken, RequestedGroup, UserInfo, UserRole } from "./types";
import { userInfoVar } from "./utils/ApolloCache";
import { TDH_COMPANY_ID } from "./utils/constants";
import { getHasuraRoleContext, isCompanyAllowedForUser } from "./utils/functions";

enum UserCompanyStatus {
  NotInitialized,
  NoCompany,
  HasCompany,
}

type AppContainerState = {
  dataInitialized: boolean;
  authenticated: boolean;
  hasCompany: UserCompanyStatus;
  companyRequired: boolean;
};

const AuthenticatedAppContainer = () => {
  const { keycloak } = useKeycloak();
  const [appState, setAppState] = useState<AppContainerState>({
    dataInitialized: false,
    authenticated: false,
    hasCompany: UserCompanyStatus.NotInitialized,
    companyRequired: true,
  });

  const [companyId, setCompanyId] = useCompanyId();

  const [
    getJoinRequests,
    { data: joinRequestsForUser, loading: joinRequestsLoading, called: joinRequestsCalled },
  ] = useLazyQuery<GetJoinRequestsForUserResponse, GetJoinRequestsForUserVariables>(
    getJoinRequestsForUser
  );

  useEffect(() => {
    if (keycloak.authenticated && keycloak.tokenParsed) {
      const accessToken = keycloak.tokenParsed as DatahubAccessToken;

      const userInfo: UserInfo = {
        fullName: accessToken.name,
        businessId: accessToken.business_id,
        lastName: accessToken.family_name,
        firstName: accessToken.given_name,
        requestedGroup: accessToken.requested_group,
        companyIds: accessToken.company_ids,
        userId: accessToken.sub,
        roleGroups: accessToken.groups,
        email: accessToken.email,
      };

      userInfoVar(userInfo);

      const companyRequired = userInfo.requestedGroup !== RequestedGroup.B2B;
      const usersFirstCompany = userInfo.companyIds?.[0];

      if (companyRequired) {
        getJoinRequests({
          variables: {
            userId: userInfo.userId || "",
          },
          context: getHasuraRoleContext(UserRole.CreateCompany),
        });
      }

      const initData = () => {
        const storedCompanyId = localStorage.getItem(TDH_COMPANY_ID);
        const storedCompanyIsValid = isCompanyAllowedForUser(userInfo.companyIds, storedCompanyId);
        if (storedCompanyId !== null && storedCompanyIsValid) {
          setCompanyId(storedCompanyId);
        } else if (usersFirstCompany) {
          setCompanyId(usersFirstCompany);
        } else {
          setCompanyId("");
          localStorage.removeItem(TDH_COMPANY_ID);
        }
        setAppState((state) => {
          return {
            ...state,
            companyRequired,
            dataInitialized: true,
          };
        });
      };

      initData();
    }
  }, [keycloak, setCompanyId, getJoinRequests]);

  useEffect(() => {
    if (companyId !== null) {
      setAppState((state) => {
        return {
          ...state,
          authenticated: Boolean(keycloak.authenticated),
          hasCompany: companyId !== "" ? UserCompanyStatus.HasCompany : UserCompanyStatus.NoCompany,
        };
      });
    }
  }, [companyId, keycloak]);

  const hasJoinRequests = (joinRequestsForUser?.companyJoinRequest.length ?? 0) !== 0;

  const authenticatedAndInitialized =
    appState.dataInitialized &&
    appState.authenticated &&
    appState.hasCompany !== UserCompanyStatus.NotInitialized;

  if (!authenticatedAndInitialized) {
    return null;
  }
  if (companyId === null) {
    return null;
  }

  if (joinRequestsLoading && joinRequestsCalled) {
    return null;
  }

  return (
    <Routes
      authenticated={appState.authenticated}
      hasCompany={appState.hasCompany === UserCompanyStatus.HasCompany}
      companyRequired={appState.companyRequired}
      companyId={companyId}
      hasJoinRequests={hasJoinRequests}
    />
  );
};

export default AuthenticatedAppContainer;
