import {
  CREATE_PRODUCT_GROUP,
  EDIT_PRODUCT_GROUP,
} from "../components/groupedProducts/productGroupRouteConstants";
import {
  GetProductGroupsResult,
  getProductGroupsQuery,
} from "../graphqlQueries/productGroup/getProductGroups";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import BackNavigationSideBar from "../components/BackNavigationSideBar";
import CreateGroupForm from "../components/groupedProducts/CreateGroupForm";
import EditGroupForm from "../components/groupedProducts/EditGroupForm";
import HowToUseGroupedProducts from "../components/groupedProducts/HowToUseGroupedProducts";
import Loading from "../components/Loading";
import { MyGroupedProductsListing } from "../components/groupedProducts/MyGroupedProductsListing";
import Notification from "../components/Notification";
import Page from "./Page";
import React from "react";
import { UserRole } from "../types";
import { getHasuraRoleContext } from "../utils/functions";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const GroupedProducts: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const isEditRoute =
    !!useRouteMatch<{ id: string }>(`${path}/edit/:id`)?.params || pathname.includes("create");

  const { data, loading, error } = useQuery<GetProductGroupsResult>(getProductGroupsQuery, {
    context: getHasuraRoleContext(UserRole.ManageProductGroup),
    fetchPolicy: "cache-and-network",
  });

  const productGroups = data?.productGroup ?? [];

  return (
    <Page sideBar={isEditRoute && <BackNavigationSideBar backPath={path} />}>
      <div className="bg-gray-100">
        <Switch>
          <Route path={`${path}/`} exact>
            <div className="p-5">
              <div>
                <h1>{t("groupedProducts.header")}</h1>
              </div>
              <div className="pb-3">{t("groupedProducts.description")}</div>
              <div>
                {loading && <Loading />}
                {error && <Notification type="danger">{t("common.error")}</Notification>}
                {!loading && !error && (
                  <>
                    <div className="mb-5">
                      <HowToUseGroupedProducts
                        shouldShowInstructions={productGroups.length === 0}
                      />
                    </div>
                    <div>
                      <MyGroupedProductsListing groups={productGroups} rootPagePath={path} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Route>
          <Route path={`${path}${CREATE_PRODUCT_GROUP}/:productId?`} exact>
            <CreateGroupForm rootPagePath={path} />
          </Route>
          <Route path={`${path}${EDIT_PRODUCT_GROUP}/:productId?`} exact>
            <EditGroupForm rootPagePath={path} />
          </Route>
        </Switch>
      </div>
    </Page>
  );
};

export default GroupedProducts;
