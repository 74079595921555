import { useQuery } from "@apollo/client";
import React from "react";

import {
  GetJoinRequestAggregateResult,
  getJoinRequestAggregate,
  getJoinRequestAggregateForVF,
} from "../../graphqlQueries/getJoinRequests";
import { UserRole } from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";
import { IconName } from "../Icon";
import NavigationMenuLink from "./NavigationMenuLink";

type NavigationMenuLinkProps = {
  to: string;
  i18n: string;
  exact?: boolean;
  tabIcon?: IconName;
  vfadmin?: boolean;
  companyId?: string | null;
};

const MenuLinkWithJoinRequestNotification = ({
  to,
  i18n,
  tabIcon,
  vfadmin,
  companyId,
}: NavigationMenuLinkProps) => {
  const query = vfadmin ? getJoinRequestAggregateForVF : getJoinRequestAggregate;
  const context = vfadmin
    ? getHasuraRoleContext(UserRole.ManageDatahub)
    : getHasuraRoleContext(UserRole.ManageCompany);
  const variables = vfadmin ? undefined : { companyId: companyId };

  const { data: newJoinRequests } = useQuery<GetJoinRequestAggregateResult>(query, {
    variables,
    context,
  });

  const anyNewJoinRequests = !!(
    newJoinRequests && newJoinRequests.companyJoinRequestAggregate.aggregate.count > 0
  );

  return (
    <NavigationMenuLink
      i18n={i18n}
      showNotificationIcon={anyNewJoinRequests}
      to={to}
      icon={tabIcon || undefined}
    />
  );
};

export default MenuLinkWithJoinRequestNotification;
