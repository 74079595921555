import React from "react";
import { Controller } from "react-hook-form";
import {
  FormSection,
  FormDescription,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";
import { useTranslation } from "react-i18next";
import CitySelect from "../CitySelect";

const DMORegionSection = () => {
  const { t } = useTranslation();
  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader>{t("companyInfo.dmoRegionSectionHeader")}</FormSectionHeader>
        <p>{t("companyInfo.dmoRegionSectionDesc1")}</p>
        <p>{t("companyInfo.dmoRegionSectionDesc2")}</p>
      </FormDescription>
      <FormSectionContent>
        <Controller
          name="cities"
          defaultValue={[]}
          render={({ onChange, value }) => {
            return <CitySelect values={value} onChange={onChange} selectAll={onChange} />;
          }}
        />
      </FormSectionContent>
    </FormSection>
  );
};

export default DMORegionSection;
