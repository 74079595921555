import { gql } from "@apollo/client";

import {
  CurationStatus,
  DataHubImage,
  DatahubType,
  PricingType,
  PricingUnit,
  ProductCurationStatus,
  ProductStatus,
  ProductType,
} from "../types";
import { shouldAddCapacity, shouldAddDuration } from "../utils/productFormUtils/productUtils";
import { HasuraAvailabilityLanguage } from "./getProduct";
import {
  HasuraProductAddress,
  HasuraProductCertificate,
  HasuraProductInformation,
  HasuraProductVideoInsert,
} from "./insertProduct";

const getUpdateProductQueryName = (addComment: boolean, hasCapacity: boolean) => {
  return `UpdateProduct${hasCapacity ? "WithCapacity" : ""}${addComment ? "AndAddComment" : ""}`;
};

const buildUpdateProductMutation = ({
  addComment,
  type,
}: {
  addComment: boolean;
  type?: ProductType;
}) => {
  const hasDuration = shouldAddDuration(type);
  const hasCapacity = shouldAddCapacity(type);
  const hasAvailabilityLanguage = type === ProductType.Experience;

  return gql`
    mutation ${getUpdateProductQueryName(addComment, hasCapacity)}(
      $id: uuid!
      $productInformations: [hasuradb_product_information_insert_input!]!
      $type: hasuradb_product_types_enum!
      $tags: [hasuradb_product_tag_insert_input!]!
      $targetGroups: [hasuradb_product_target_group_insert_input!]!
      $images: [hasuradb_product_image_insert_input!]!
      $email: String
      $phone: String
      $availableDays: hasuradb_product_availability_set_input!
      $pricing: hasuradb_product_pricing_set_input
      ${
        hasAvailabilityLanguage
          ? "$availabilityLanguages: [hasuradb_product_availability_language_insert_input!]!"
          : ""
      }
      ${hasCapacity ? "$capacity: [hasuradb_product_capacity_insert_input!]!" : ""}
      ${hasDuration ? "$duration: [hasuradb_product_duration_insert_input!]!" : ""}
      $address: hasuradb_postal_address_set_input!
      $productCurationStatus: hasuradb_product_curation_status_enum
      $availableMonths: [hasuradb_product_available_months_insert_input!]!
      $videos: [hasuradb_product_video_insert_input!]!
      $status: hasuradb_product_status_enum!
      $curationEnabled: Boolean!
      $accessible: Boolean,
      $certification: [hasuradb_product_certificate_insert_input!]!
      $curationStatus: hasuradb_curation_status_enum
      $companyId: uuid!
      $verifiedLinks: [uuid!]!
      $businessHoursId: uuid!
      $socialMediaId: uuid
      ${
        addComment
          ? `
      $commentText: String!
      $commentCompanyId: uuid!
      $commentUsername: String!
      $commentRecipientRole: hasuradb_datahub_types_enum!
      `
          : ""
      }
    ) {
      deleteProductTargetGroups(where: { productId: { _eq: $id } }) {
        affected_rows
      }
      insertProductTargetGroups(objects: $targetGroups) {
        affected_rows
      }

      deleteProductTags(where: { productId: { _eq: $id } }) {
        affected_rows
      }
      insertProductTags(objects: $tags) {
        affected_rows
      }

      deleteProductImage(where: { productId: { _eq: $id } }) {
        affected_rows
      }
      insertProductImage(objects: $images) {
        affected_rows
      }

      deleteProductInformation(where: { productId: { _eq: $id } }) {
        affected_rows
      }
      deleteProductVideos(where: { productId: { _eq: $id } }) {
        affected_rows
      }

      insertProductInformation(objects: $productInformations) {
        affected_rows
        returning { id }
      }
      insertProductVideos(objects: $videos) {
        affected_rows
        returning { id }
      }

      deleteProductAvailableMonths(where: { productId: { _eq: $id } }) {
        affected_rows
      }
      insertProductAvailableMonths(objects: $availableMonths) {
        affected_rows
      }

      deleteProductAvailabilityLanguage(where: {productId: {_eq: $id}}) {
        affected_rows
      }

         ${
           hasAvailabilityLanguage
             ? `insertProductAvailabilityLanguage(objects: $availabilityLanguages) {
        affected_rows
      }`
             : ""
         }

      deleteProductCertificate(where: { productId: { _eq: $id } }) {
        affected_rows
      }
      
      insertProductCertificate(objects: $certification) {
        affected_rows
      }

      updateVerifiedLink(where: { id: { _in: $verifiedLinks } }, _set: { deletedAt: "now()" }) {
        affected_rows
      }

      updatePostalAddress(where: { productId: { _eq: $id } }, _set: $address) {
        affected_rows
      }

      updateContactDetails(
        where: { productId: { _eq: $id } }
        _set: { email: $email, phone: $phone }
      ) {
        affected_rows
      }

      updateProductAvailability(where: { productId: { _eq: $id } }, _set: $availableDays) {
        affected_rows
      }

      updateProductPricing(where: { productId: { _eq: $id } }, _set: $pricing) {
        affected_rows
      }

      deleteProductCapacity(where: { productId: { _eq: $id } }) {
        affected_rows
      }

      ${
        hasCapacity
          ? `insertProductCapacity(objects: $capacity) {
        affected_rows
      }`
          : ""
      }
      
      deleteProductDuration(where: { productId: { _eq: $id } }) {
        affected_rows
      }
      ${
        hasDuration
          ? `insertProductDuration(objects: $duration) {
        affected_rows        
      }`
          : ""
      }


      updateProductCuration(
        where: { productId: { _eq: $id } }
        _set: { productCurationStatus: $productCurationStatus, curationStatus: $curationStatus, updatedByCompany: $companyId }
      ) {
        affected_rows
      }

      updateProductByPk(
        pk_columns: { id: $id }
        _set: {
          type: $type
          status: $status
          curationEnabled: $curationEnabled
          accessible: $accessible
          businessHoursId: $businessHoursId
          socialMediaId: $socialMediaId

        }
      ) {
        id
        type
        status
        curationEnabled
        accessible
        businessHoursId
        socialMediaId
      }

      ${
        addComment
          ? `insertProductCommentsOne(
        object: {
          productId: $id
          comment: $commentText
          createdByCompanyId: $commentCompanyId
          createdByName: $commentUsername
          recipientRole: $commentRecipientRole
        }
      ) {
        id
      }`
          : ""
      }
    }
  `;
};

type UpdateObject = {
  productId: string;
};

export type HasuraUpdateProductTag = UpdateObject & {
  tag: string;
};

export type HasuraUpdateProductTargetGroup = UpdateObject & {
  targetGroupId: string;
};

type HasuraUpdateImage = DataHubImage & {
  productId: string;
  orderIndex?: number;
};

type HasuraProductAvailability = {
  startDate: string | null;
  endDate: string | null;
};

type HasuraUpdatePricing = {
  fromPrice: number | null;
  toPrice: number | null;
  pricingUnit: PricingUnit | null;
  pricingType?: PricingType | null;
  productId?: string;
};

type HasuraProductCapacity = {
  min: number | null;
  max: number | null;
  productId?: string;
};

type HasuraUpdateCertificate = HasuraProductCertificate & {
  certificate: string;
  productId?: string;
};

type HasuraUpdateProductAddress = HasuraProductAddress;

type HasuraProductAvailableMonths = {
  month: string;
  productId?: string;
};

type HasuraProductDuration = {
  minutes: number | 0;
  hours: number | 0;
  days: number | 0;
  weeks: number | 0;
  productId?: string;
};

export type HasuraUpdateProductVariables = {
  id: string;
  productInformations: HasuraProductInformation[];
  type: ProductType;
  tags: HasuraUpdateProductTag[];
  images: HasuraUpdateImage[];
  targetGroups: HasuraUpdateProductTargetGroup[];
  email?: string;
  phone?: string;
  availableDays: HasuraProductAvailability;
  pricing?: HasuraUpdatePricing;
  availabilityLanguages?: HasuraAvailabilityLanguage[];
  capacity?: HasuraProductCapacity;
  address: HasuraUpdateProductAddress;
  availableMonths: HasuraProductAvailableMonths[];
  duration?: HasuraProductDuration;
  status: ProductStatus;
  curationEnabled: boolean;
  videos: HasuraProductVideoInsert[];
  accessible: boolean | null;
  certification: HasuraUpdateCertificate[];
  companyId: string;
  verifiedLinks: string[];
  businessHoursId: string;
  socialMediaId: string | null;
  productCurationStatus?: ProductCurationStatus;
  curationStatus?: CurationStatus;
};

export type HasuraUpdateProductWithCommentVariables = HasuraUpdateProductVariables & {
  commentText: string;
  commentCompanyId: string;
  commentUsername: string;
  commentRecipientRole: DatahubType;
};

export interface UpdateResultAffectedRows {
  affected_rows: number;
}

export interface UpdateHasuradbProductByPk {
  id: string;
}

export interface UpdateProductResult {
  deleteProductTargetGroups: UpdateResultAffectedRows;
  insertProductTargetGroups: UpdateResultAffectedRows;
  deleteProductTags: UpdateResultAffectedRows;
  insertProductTags: UpdateResultAffectedRows;
  deleteProductImage: UpdateResultAffectedRows;
  insertProductImage: UpdateResultAffectedRows;
  deleteProductVideos: UpdateResultAffectedRows;
  insertProductVideos: UpdateResultAffectedRows;
  deleteProductAvailableMonths: UpdateResultAffectedRows;
  insertProductAvailableMonths: UpdateResultAffectedRows;
  deleteProductAvailabilityLanguages: UpdateResultAffectedRows;
  insertProductAvailabilityLanguages: UpdateResultAffectedRows;
  deleteProductInformation: UpdateResultAffectedRows;
  insertProductInformation: UpdateResultAffectedRows;
  updatePostalAddress: UpdateResultAffectedRows;
  updateContactDetails: UpdateResultAffectedRows;
  updateProductAvailability: UpdateResultAffectedRows;
  insertProductPricing: UpdateResultAffectedRows;
  updateProductPricing: UpdateResultAffectedRows;
  insertProductCapacity: UpdateResultAffectedRows;
  updateProductCapacity: UpdateResultAffectedRows;
  updateProductCuration: UpdateResultAffectedRows;
  updateProductByPk: UpdateHasuradbProductByPk;
  deleteProductCertification: UpdateResultAffectedRows;
  insertProductCertification: UpdateResultAffectedRows;
  deleteVerifiedLink: UpdateResultAffectedRows;
  insertProductDuration: UpdateResultAffectedRows;
}

export default buildUpdateProductMutation;
