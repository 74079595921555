import React from "react";
import styled from "styled-components";

type StepIndicatorProps = {
  stepAmount: number;
  activeIndex: number;
  size?: number;
};

type StepProps = {
  current: boolean;
  size?: number;
};

const Step = styled.div<StepProps>`
  display: inline-block;
  margin-right: 16px;
  background-color: ${({ current }) =>
    current ? "var(--color-primary)" : "var(--color-gray-400)"};
  ${({ size }) => (size ? `width: ${size}px; height: ${size}px;` : "width: 10px; height: 10px;")}
  border-radius: 50%;
  &:last-of-type {
    margin-right: 0;
  }
`;

const StepIndicator = ({ stepAmount, activeIndex, size }: StepIndicatorProps) => (
  <div className="p-2">
    {Array.from(Array(stepAmount)).map((_, i) => (
      <Step current={i <= activeIndex} key={`step${i}`} size={size} />
    ))}
  </div>
);

export default React.memo(StepIndicator);
