import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { BusinessHours } from "../../types";
import { formatDateRange } from "../../utils/format";
import BusinessHoursModal from "../businessHours/BusinessHoursModal";
import ViewOpeningHours from "../businessHours/ViewOpeningHours";
import ViewOpeningHoursExceptions from "../businessHours/ViewOpeningHoursExceptions";
import DummyLink from "../DummyLink";

type ViewProductBusinessHoursProps = {
  businessHours: BusinessHours;
};

const ViewProductBusinessHours = ({ businessHours }: ViewProductBusinessHoursProps) => {
  const { t } = useTranslation();

  const [showBusinessHoursModal, setShowBusinessHoursModal] = useState(false);

  if (businessHours.default.filter(({ opens, closes }) => opens && closes).length === 0) {
    return null;
  }

  return (
    <>
      <ViewOpeningHours
        header={t("productInfo.openingHoursLabel")}
        openingHours={businessHours.default}
      />
      {businessHours.exceptions.length > 0 && (
        <>
          <ViewOpeningHoursExceptions
            header={t("productInfo.openingHoursExceptionsLabel")}
            direction="column"
            exceptions={businessHours.exceptions}
            renderException={(id, start, end) => (
              <span key={id}>{formatDateRange(start, end)}</span>
            )}
          />
          <DummyLink onClick={() => setShowBusinessHoursModal(true)}>
            {t("productInfo.openingHoursShowAllButton")}
          </DummyLink>
        </>
      )}
      {showBusinessHoursModal && (
        <BusinessHoursModal
          onClose={() => setShowBusinessHoursModal(false)}
          businessHours={businessHours}
        />
      )}
    </>
  );
};

export default ViewProductBusinessHours;
