import { gql } from "@apollo/client";

import { BusinessHours, DatahubType, ProductFormLink, SocialMediaPlatform } from "../types";

const getCompanyData = gql`
  query companyData($id: uuid!) {
    companyByPk(id: $id) {
      active
      businessName
      businessHours {
        id
        default {
          id
          weekday
          opens
          closes
          open
        }
        exceptions(order_by: { start: asc }) {
          id
          start
          end
          openingHours {
            id
            weekday
            opens
            closes
            open
            date
          }
        }
      }
      contactDetails {
        createdAt
        email
        id
        phone
      }
      description
      id
      officialName
      postalAddresses {
        city
        id
        postalCode
        streetName
      }
      businessEntity {
        businessId
      }
      dmoAreas {
        curationArea {
          city
          id
        }
      }
      socialMedia {
        socialMediaLinks {
          linkType
          verifiedLink {
            id
            url
            userVerifiedAt
            userVerifiedBy
          }
        }
        id
        companySocialMedia
      }
      websiteUrl
      webshopUrl
      logoUrl
      logoThumbnailUrl
      sustainabilityInfo
      completed
      datahubType
    }
  }
`;

export type HasuraContactDetail = {
  __typename: string;
  created: Date;
  email: string;
  id: string;
  phone: string;
};

export type HasuraPostalAddress = {
  __typename: string;
  city: string;
  id: string;
  postalCode: string;
  streetName: string;
};

export type HasuraBusinessEntity = {
  businessId: string;
};

export type CurationArea = {
  city: string;
  id: string;
};

export type DmoArea = {
  curationArea: CurationArea;
};

export type GetSingleCompanyInfoVariables = {
  id: string;
};
export type SocialMediaLinks = {
  id: string;
  linkType: SocialMediaPlatform;
  verifiedLink: ProductFormLink;
};

export type SocialMedia = {
  id: string;
  socialMediaLinks?: SocialMediaLinks[];
  companySocialMedia: boolean;
};

export type HasuraCompanyInformationResult = {
  active: boolean;
  businessEntity: HasuraBusinessEntity;
  businessName: string;
  businessHours: BusinessHours | null;
  contactDetails: HasuraContactDetail[];
  description: string;
  id: string;
  officialName: string;
  postalAddresses: HasuraPostalAddress[];
  webshopUrl: string;
  websiteUrl: string;
  logoUrl: string;
  logoThumbnailUrl: string;
  sustainabilityInfo?: string;
  completed: boolean;
  dmoAreas: DmoArea[];
  socialMedia: SocialMedia;
  datahubType: DatahubType;
};

export type GetSingleCompanyInfoResult = {
  companyByPk: HasuraCompanyInformationResult;
};

export default getCompanyData;
