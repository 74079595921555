import React from "react";
import { IconElementPosition } from "../types";
import Icon from "./Icon";

type ExternalLinkProps = {
  href: string;
  showIcon?: boolean;
  children: React.ReactNode;
  className?: string;
  iconPosition?: IconElementPosition;
};

const ExternalLink = ({
  href,
  children,
  showIcon = true,
  className,
  iconPosition = "right",
}: ExternalLinkProps) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
    <span className={iconPosition === "right" ? "d-inline" : "d-inline-flex"}>
      {showIcon && iconPosition === "left" && (
        <Icon className="flex-shrink-0 mr-1" name="external-link-alt" size="medium" />
      )}
      {children}
      {showIcon && iconPosition === "right" && (
        <Icon
          className="flex-shrink-0 ml-1"
          style={{ marginBottom: "3px" }}
          name="external-link-alt"
          size="medium"
        />
      )}
    </span>
  </a>
);

export default ExternalLink;
