import { MutationTuple } from "@apollo/client";
import React, { ReactNode, useContext } from "react";
import {
  Accordion,
  AccordionContext,
  Button,
  Card,
  ListGroupItem,
  useAccordionToggle,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { breakpointPixels } from "../../css/breakpoints";
import { DeleteBulletinResult, DeleteBulletinVariables } from "../../graphqlQueries/deleteBulletin";
import { Bulletin } from "../../graphqlQueries/getBulletin";
import { useIsMediumOrSmaller } from "../MediaQuery";
import BulletinListElementHeader from "./BulletinListElementHeader";
import ViewBulletin from "./ViewBulletin";
import { shortenText } from "../../utils/localizationUtils";

const PREVIEW_LENGTH = 65;

type BulletinListProps = {
  bulletins: Bulletin[];
  selectedBulletin: Bulletin;
  onSelect: (bulletin: Bulletin) => void;
  language: string;
  setShowCreateForm?: (bool: boolean) => void;
  canEdit?: boolean;
  setLimit?: () => void;
  setIsEditing?: (bool: boolean) => void;
  canLoadMore?: boolean;
  setError: (error: string | null) => void;
  deleteMutation?: MutationTuple<DeleteBulletinResult, DeleteBulletinVariables>;
};

const ListContainer = styled.div`
  max-height: 20rem;

  @media (max-width: ${breakpointPixels.md}) {
    max-height: 50vh;
  }

  &&::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 1px;
    width: 100%;
    height: 1.6rem;
    background-image: linear-gradient(to top, rgba(222, 226, 230, 0) 70%, rgba(0, 0, 0, 0.19));
    pointer-events: none;
    z-index: 5;
  }

  &&::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: calc(100% - 15px);
    height: 1.7rem;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    pointer-events: none;
    z-index: 5;
  }

  &&::-webkit-scrollbar {
    background-color: var(--color-gray-300);
  }

  &&::-webkit-scrollbar-track {
    background-color: var(--color-gray-300);
  }

  &&::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: var(--white);
    border-radius: 1px;
  }
`;
const BulletinList = ({
  bulletins,
  selectedBulletin,
  onSelect,
  language,
  canEdit,
  setShowCreateForm,
  setIsEditing,
  deleteMutation,
  setError,
  setLimit,
  canLoadMore,
}: BulletinListProps) => {
  const isMediumScreen = useIsMediumOrSmaller();

  const ContextAwareToggle = ({
    children,
    eventKey,
    callback,
  }: {
    children: ReactNode;
    eventKey: string;
    callback?: (key: string) => void;
  }) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <Accordion.Toggle
        as={Card.Header}
        eventKey={eventKey}
        style={{
          backgroundColor: "white",
          borderTop: isCurrentEventKey ? "0.625rem solid var(--color-light-blue)" : "none",
          borderBottom: "none",
        }}
        onClick={decoratedOnClick}
      >
        {children}
      </Accordion.Toggle>
    );
  };
  const { t } = useTranslation();

  return (
    <div>
      {!isMediumScreen ? (
        <ListContainer>
          {bulletins.map((bulletin) => {
            return (
              <ListGroupItem
                active={selectedBulletin?.id === bulletin.id}
                action
                as="div"
                key={bulletin.id}
                eventKey={bulletin.id}
                onClick={() => onSelect(bulletin)}
              >
                <BulletinListElementHeader
                  bulletin={bulletin}
                  onSelect={onSelect}
                  setShowCreateForm={setShowCreateForm}
                  canEdit={canEdit}
                  setIsEditing={setIsEditing}
                  deleteMutation={deleteMutation}
                  setError={setError}
                />
                <div>
                  <h3 className="h4-size clamp-1">
                    {language === "en" ? bulletin.titleEn : bulletin.titleFi}
                  </h3>
                  <p className="mb-0 clamp-1">
                    {shortenText(
                      language === "en" ? bulletin.contentEn : bulletin.contentFi,
                      PREVIEW_LENGTH
                    )}
                  </p>
                </div>
              </ListGroupItem>
            );
          })}
        </ListContainer>
      ) : (
        <div>
          <Accordion defaultActiveKey={bulletins[0].id}>
            {bulletins.map((bulletin) => {
              return (
                <Card onClick={() => onSelect(bulletin)} key={bulletin.id}>
                  <ContextAwareToggle eventKey={bulletin.id}>
                    <BulletinListElementHeader
                      bulletin={bulletin}
                      onSelect={onSelect}
                      setShowCreateForm={setShowCreateForm}
                      canEdit={canEdit}
                      setIsEditing={setIsEditing}
                      deleteMutation={deleteMutation}
                      setError={setError}
                    />
                    <h3 className="h4-size">
                      {language === "en" ? bulletin.titleEn : bulletin.titleFi}
                    </h3>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey={bulletin.id}>
                    <ViewBulletin bulletin={bulletin} language={language} withoutTitle />
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
          <Button className="btn-ghost p-3 w-100" onClick={setLimit} disabled={!canLoadMore}>
            {t("common.showMore")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default BulletinList;
