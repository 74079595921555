import {
  AffectedRowsResult,
  DataHubImage,
  IdResult,
  Language,
  ProductGroupInput,
  ProductGroupProductInput,
} from "../../types";
import { ProductInformation, VerifiedLink } from "../getProduct";

import { gql } from "@apollo/client";

export type UpdateProductGroupResult = {
  deleteProductGroupProduct: AffectedRowsResult;
  insertProductGroupProduct: AffectedRowsResult;
  updateProductGroupByPk: IdResult & {
    name: string;
    description: string;
  };
};

export type ProductGroupProductInformation = ProductInformation & {
  id?: string;
  productId: string;
};

export type ProductGroupImage = DataHubImage & {
  id?: string;
  productGroupId: string;
  productId: string;
};

export type EditProductInformation = {
  id?: string;
  productId: string;
  productGroupId: string;
  language: Language;
  name: string;
  description: string;
  verifiedProductLink?: VerifiedLink | null;
  verifiedWebshopLink?: VerifiedLink | null;
};

export type UpdateProductGroupVariables = {
  id: string;
  productGroup: ProductGroupInput;
  products: ProductGroupProductInput[];
  editedProductDetails?: EditProductInformation[];
  editedProductImages?: DataHubImage[];
  editedProductInformationIds?: (string | undefined)[] | undefined;
};

export const updateProductGroupMutation = (hasEditedProductImages?: boolean) => {
  return gql`
           mutation UpdateProductGroup(
             $id: uuid!
             $productGroup: hasuradb_product_group_set_input!
             $products: [hasuradb_product_group_product_insert_input!]!
             $editedProductDetails: [hasuradb_product_group_product_information_insert_input!]!
             ${
               hasEditedProductImages
                 ? `$editedProductImages: [hasuradb_product_group_product_image_insert_input!]!`
                 : ``
             }
             $editedProductInformationIds: [uuid!]
           ) {
             deleteProductGroupProduct(where: { productGroupId: { _eq: $id } }) {
               affected_rows
             }
             insertProductGroupProduct(objects: $products) {
               affected_rows
             }

             deleteProductGroupProductInformation(
               where: { id: { _in: $editedProductInformationIds } }
             ) {
               affected_rows
             }
            ${
              hasEditedProductImages
                ? `deleteProductGroupProductImage(where: { productGroupId: { _eq: $id } }) {
                          affected_rows
                        }`
                : ``
            }

             insertProductGroupProductInformation(objects: $editedProductDetails) {
               affected_rows
             }

             ${
               hasEditedProductImages
                 ? `insertProductGroupProductImage(objects: $editedProductImages) {
               affected_rows
             }`
                 : ``
             }

             updateProductGroupByPk(pk_columns: { id: $id }, _set: $productGroup) {
               id
               name
               description
             }
           }
         `;
};
