import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BulletinTargetGroup } from "../../types";
import { getBulletinTargetGroupKeys } from "../../utils/localizationUtils";
import Checkbox from "../Checkbox";

const BulletinFormTargetGroupSection = () => {
  const { t } = useTranslation();
  const { register, errors, getValues } = useFormContext();
  const checkboxOptions = [
    BulletinTargetGroup.DMO,
    BulletinTargetGroup.TC,
    BulletinTargetGroup.API,
  ].map((type) => {
    return { value: type, label: getBulletinTargetGroupKeys(type) };
  });

  const validateGroups = () => {
    const values = getValues();
    const targetGroups = values.targetGroups;
    const invalid = !targetGroups || targetGroups.length === 0;
    return invalid ? "validationErrors.requiredData" : undefined;
  };

  return (
    <div className="d-flex flex-column flex-sm-row mb-3">
      <Form.Label>{t("dashboard.bulletinTargetGroupCheckboxLabel")} * </Form.Label>
      {checkboxOptions.map((option) => {
        return (
          <Checkbox
            id={option.value}
            className="ml-sm-4"
            value={option.value}
            key={option.value}
            name="targetGroups"
            label={t(option.label)}
            ref={register({
              validate: validateGroups,
            })}
          />
        );
      })}
      {errors.targetGroups && <p className="text-danger ml-2">{t(errors.targetGroups.message)}</p>}
    </div>
  );
};

export default BulletinFormTargetGroupSection;
