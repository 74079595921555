import { gql } from "@apollo/client";

import { DatahubType, JoinRequestStatus } from "../types";

const getJoinRequests = gql`
  query getJoinRequests($userId: uuid!) {
    companyJoinRequest(where: { _and: { userId: { _eq: $userId }, status: { _eq: new } } }) {
      id
      userId
      company {
        businessName
        createdBy
        datahubType
      }
    }
  }
`;

export const getJoinRequestsForUser = gql`
  query getJoinRequestsForUser($userId: uuid!) {
    companyJoinRequest(where: { _and: { userId: { _eq: $userId }, status: { _eq: new } } }) {
      companyId
    }
  }
`;

export const getJoinRequestsAllUserData = gql`
  query getAllUserDataJoinRequests($companyId: uuid!) {
    companyJoinRequest(
      where: { companyId: { _eq: $companyId }, status: { _in: [new, approved] } }
      order_by: { createdAt: asc }
    ) {
      companyId
      createdAt
      id
      updatedAt
      updatedBy
      user {
        id
        name
      }
      userEmail
      status
    }
  }
`;

export const getJoinRequestDMODataForVF = gql`
  query getJoinRequestDMODataForVF {
    companyJoinRequest(where: { _and: { vfAdminRequired: { _eq: true }, status: { _eq: new } } }) {
      id
      company {
        businessName
        id
        dmoAreas {
          curationArea {
            city
          }
        }
        businessEntity {
          businessId
        }
        officialName
      }
      user {
        id
        name
      }
      userEmail
      createdAt
      updatedAt
    }
  }
`;

export const getJoinRequestAggregateForVF = gql`
  query getJoinRequestAggregateForVF {
    companyJoinRequestAggregate(
      where: { _and: { status: { _eq: new }, vfAdminRequired: { _eq: true } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getJoinRequestAggregate = gql`
  query getJoinRequestAggregate($companyId: uuid!) {
    companyJoinRequestAggregate(
      where: {
        _and: {
          status: { _eq: new }
          vfAdminRequired: { _eq: false }
          companyId: { _eq: $companyId }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export type GetJoinRequestAggregateResult = {
  companyJoinRequestAggregate: {
    aggregate: {
      count: number;
    };
  };
};

export type JoinRequestData = {
  id: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
  user: {
    id: string;
    name: string;
  };
  userEmail: string;
  status: JoinRequestStatus;
};

export type JoinRequestDMODataForVF = JoinRequestData & {
  company: {
    businessName: string;
    id: string;
    dmoAreas: {
      curationArea: {
        city: string;
      };
    }[];
    businessEntity: {
      businessId: string;
      id: string;
    };
    officialName: string;
  };
};

export type GetJoinRequestDMODataForVFResult = {
  companyJoinRequest: JoinRequestDMODataForVF[];
};

export type GetJoinRequestsAllUserDataResponse = {
  companyJoinRequest: JoinRequestData[];
};

export type GetJoinRequestsAllUserDataVariables = {
  companyId: string;
};

export type GetJoinRequestsForUserVariables = {
  userId: string;
};

export type GetJoinRequestsForUserResponse = {
  companyJoinRequest: {
    companyId: string;
  }[];
};

export type GetJoinRequestsResponse = {
  companyJoinRequest: {
    id: string;
    userId: string;
    company: {
      businessName: string;
      createdBy: string;
      datahubType: DatahubType;
    };
  }[];
};

export type GetJoinRequestsVariables = {
  userId: string;
};

export default getJoinRequests;
