import { AggregateCount, ProductCommentsUserRole, UserRole } from "../types";
import { DocumentNode, gql } from "@apollo/client";

import fragments from "./fragments";

const getFragmentData = (spread: string, fragment?: DocumentNode) => ({ spread, fragment });

const getNewCommentsFragment = (userRole: ProductCommentsUserRole) => {
  switch (userRole) {
    case UserRole.ManageProducts:
      return getFragmentData(
        "...ProductNewCommentsCountTc",
        fragments.product.newCommentsCountForTc
      );
    case UserRole.ManageCuration:
      return getFragmentData(
        "...ProductNewCommentsCountDmo",
        fragments.product.newCommentsCountForDmo
      );
    default:
      return getFragmentData("");
  }
};

const buildGetCommentsCountQuery = (userRole: ProductCommentsUserRole) => {
  const { spread, fragment } = getNewCommentsFragment(userRole);

  const queryName = `GetCommentsCount${userRole === UserRole.ManageCuration ? "Dmo" : "Tc"}`;

  const query = gql`
    query ${queryName}($productId: uuid!) {
      product: productByPk(id: $productId) {
        id
        ...ProductCommentsCount
        ${spread}
      }
    }
    ${fragments.product.commentsCount}
    ${fragment}
  `;

  return query;
};

export const getCommentsCountTc = buildGetCommentsCountQuery(UserRole.ManageProducts);

export const getCommentsCountDmo = buildGetCommentsCountQuery(UserRole.ManageCuration);

export type GetCommentsCountResult = {
  product: {
    id: string;
    productCommentsAggregate: AggregateCount;
    productNewCommentsAggregate: AggregateCount;
  };
};

export type GetCommentsCountVariables = {
  productId: string;
};
