import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";

import AuthenticatedAppContainer from "./AuthenticatedAppContainer";
import PublicRoutes from "./components/PublicRoutes";
import ApolloServiceProvider from "./utils/ApolloServiceProvider";
import { checkToken } from "./utils/functions";
import ApolloCMSServiceProvider from "./utils/ApolloCMSServiceProvider";
import { useLocation } from "react-router-dom";
import CMSRoutes from "./components/CMSRoutes";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PK4S83N2",
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  const { keycloak, initialized } = useKeycloak();
  const { pathname } = useLocation();

  useEffect(() => {
    const handler = async () => {
      if (document.visibilityState === "visible") {
        const { error } = await checkToken(keycloak);
        if (error) {
          console.error("Token refresh failed (visibilitychange)", error);
        }
      }
    };
    document.addEventListener("visibilitychange", handler);

    return () => {
      document.removeEventListener("visibilitychange", handler);
    };
  }, [keycloak]);

  return (
    <>
      {initialized && pathname.includes("faq") ? (
        <ApolloCMSServiceProvider isPreview={pathname.includes("preview")}>
          <CMSRoutes />
        </ApolloCMSServiceProvider>
      ) : (
        <>
          {initialized && keycloak.authenticated ? (
            <ApolloServiceProvider>
              <AuthenticatedAppContainer />
            </ApolloServiceProvider>
          ) : (
            <ApolloCMSServiceProvider isPreview={pathname.includes("preview")}>
              <PublicRoutes />
            </ApolloCMSServiceProvider>
          )}
        </>
      )}
    </>
  );
};

export default withTranslation()(App);
