import React from "react";
import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import StepIndicator from "../../components/StepIndicator";
import { CompanyInformation } from "../../types";
import StepHeader from "./StepHeader";
import StepSection from "./StepSection";

type CreateAccountIntroProps = {
  onNext: () => void;
  companyInformation: CompanyInformation;
  isCurator: boolean;
  backButtonOnClick?: () => void;
};

const CreateAccountIntro = ({
  onNext,
  companyInformation,
  isCurator,
  backButtonOnClick,
}: CreateAccountIntroProps) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center flex-column">
      <StepHeader>
        {isCurator
          ? t("signup.createAccountIntroHeaderCurator")
          : t("signup.createAccountIntroHeaderCompany")}
      </StepHeader>
      <StepIndicator stepAmount={2} activeIndex={0} />
      <section className="w-100 mt-2 mb-3 color-gray-800 border p-2">
        <div className="color-gray-900 text-larger">{companyInformation.companyName}</div>
        <div className="text-small color-gray-800">{companyInformation.businessId}</div>
      </section>
      <StepSection className="mb-5">
        {isCurator ? (
          <p>{t("signup.createAccountIntroDescCurator")}</p>
        ) : (
          <p className="font-weight-bold">{t("signup.createAccountIntroDesc1")}</p>
        )}
      </StepSection>
      <div
        className={backButtonOnClick ? "d-flex flex-row justify-content-between w-100" : "d-block"}
      >
        {backButtonOnClick && (
          <Button
            role="link"
            variant="secondary"
            onClick={() => {
              backButtonOnClick();
            }}
          >
            {t("common.back")}
          </Button>
        )}
        <Button className={backButtonOnClick && "w-50"} onClick={() => onNext()}>
          {t("signup.continueCta")}
        </Button>
      </div>
      <p className="mt-4 px-3 text-smaller color-gray-800 text-center">
        <Trans i18nKey="signup.wrongBusinessIdDescription">
          if you have registered i18n placeholder
          <a href="mailto:datahub@visitfinland.com">datahub@visitfinland.com</a>
        </Trans>
      </p>
    </div>
  );
};

export default CreateAccountIntro;
