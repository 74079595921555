import { DataHubImage, Language, ViewMode } from "../../types";
import { HasuradbProduct, ProductInformation } from "../../graphqlQueries/getProduct";
import { HeaderImage, HeaderPlaceholder, RenderCommentsProp } from "./ViewProduct";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import Icon from "../Icon";
import IconButton from "../IconButton";
import ProductDescriptionLanguageTabs from "../productForm/productDescription/ProductDescriptionLanguageTabs";
import Separator from "../Separator";
import ViewProductContactsAndLocation from "./ViewProductContactsAndLocation";
import ViewProductDetails from "./ViewProductDetails";
import ViewProductImages from "./ViewProductImages";
import ViewProductInformation from "./ViewProductInformation";
import ViewProductVideos from "./ViewProductVideos";
import { sortProductsByLanguage } from "../../utils/localizationUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ViewProductSocials from "./ViewProductSocials";

type ViewProductDataProps = {
  product: HasuradbProduct;
  commentOptions?: {
    renderComments: RenderCommentsProp;
    showComments: boolean;
    viewMode: ViewMode;
  };
  showCarousel?: boolean;
  showOverlay?: boolean;
};

const StyledProductDetailContainer = styled.div`
  border: 1px solid var(--color-gray-400);
`;

const OverlayText = styled.div`
  position: absolute;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  width: 100%;
  padding: 1.3rem 1rem 0.5rem 1rem;
  text-align: right;
`;

const ViewProductData = ({
  product,
  commentOptions,
  showCarousel = true,
  showOverlay = true,
}: ViewProductDataProps) => {
  const { t } = useTranslation();
  const {
    id: productId,
    productImages: images,
    productVideos: videos,
    productInformations: details,
    socialMedia,
  } = product;

  const { renderComments, showComments, viewMode } = commentOptions || {};

  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(
    sortProductsByLanguage(details)?.[0]?.language || null
  );
  const [productDetails, setProductDetails] = useState<ProductInformation[] | null>(null);

  const [coverImageToOpen, setCoverImageToOpen] = useState<DataHubImage | undefined>(undefined);

  const postalAddress = product.postalAddresses[0];
  const contactDetails = product.contactDetails[0];
  const businessName = product.company.businessName;

  const firstImage = images.length > 0 ? images[0] : undefined;
  const coverImage = images.find((image) => image.coverPhoto) || firstImage;

  useEffect(() => {
    const sorted = sortProductsByLanguage(details);
    setProductDetails(sorted);
    setSelectedLanguage(sorted[0]?.language);
  }, [details]);

  const ImageSection = () => {
    if (!coverImage) {
      return <HeaderPlaceholder>{t("viewProduct.headerImagePlaceholderText")}</HeaderPlaceholder>;
    }

    if (!showCarousel) {
      return (
        <div className="position-relative">
          <HeaderImage
            style={{
              backgroundImage: coverImage ? `url("${coverImage.largeUrl}")` : "",
            }}
          />
        </div>
      );
    }

    return (
      <>
        <div className="position-relative">
          <HeaderImage
            style={{
              backgroundImage: coverImage ? `url("${coverImage.largeUrl}")` : "",
            }}
          />
          {showOverlay && (
            <OverlayText className="text-small">
              <OverlayTrigger
                placement="bottom-end"
                overlay={
                  <Tooltip id="open-image-modal-tooltip" className="pt-2">
                    {t("viewProduct.headerImageTooltip")}
                  </Tooltip>
                }
              >
                <IconButton
                  className="text-white py-0 font-weight-normal font-light"
                  variant="ghost"
                  iconElement={
                    <Icon
                      className="ml-1"
                      name="image-placeholder"
                      size="small"
                      style={{ marginBottom: "2px" }}
                    />
                  }
                  iconPosition="right"
                  onClick={() => setCoverImageToOpen(coverImage)}
                >
                  {t("viewProduct.openHeaderImageText")}
                </IconButton>
              </OverlayTrigger>
            </OverlayText>
          )}
        </div>
        <ViewProductImages
          images={images}
          openCoverImage={coverImageToOpen}
          onModalClose={() => setCoverImageToOpen(undefined)}
        />
      </>
    );
  };

  return (
    <div className="px-2 pt-2">
      {renderComments && viewMode && renderComments({ productId, viewMode, hide: !showComments })}
      <ImageSection />
      {productDetails && (
        <ProductDescriptionLanguageTabs
          fields={
            productDetails ? productDetails.map((detail) => ({ language: detail.language })) : []
          }
          activeLanguage={selectedLanguage}
          setActiveLanguage={(language) => setSelectedLanguage(language)}
        />
      )}
      <StyledProductDetailContainer className="px-3">
        {details && <ViewProductDetails selectedLanguage={selectedLanguage} details={details} />}
        <Separator />
        <ViewProductInformation product={product} />
        <Separator />
        <ViewProductContactsAndLocation
          businessName={businessName}
          postalAddress={postalAddress}
          contactDetails={contactDetails}
        />
        <Separator />
        <ViewProductVideos videos={videos} />
        <Separator />
        <ViewProductSocials socialMediaLinks={socialMedia?.socialMediaLinks} />
      </StyledProductDetailContainer>
    </div>
  );
};

export default ViewProductData;
