import React from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { DEFAULT_PAGE_SIZE_OPTIONS, PAGE_SIZE_OPTIONS } from "../utils/pagingUtils";
import Icon from "./Icon";
import { useTranslation } from "react-i18next";
import { DropdownOption } from "../types";
import { useIsLargeOrExtraLargeScreen, useIsMediumOrLarger, useIsSmallScreen } from "./MediaQuery";

type CustomPaginationProps = {
  pageAmount: number;
  pageShowAmountOption: (selectedPage: DropdownOption) => void;
  pageIndicator: string;
  toFirstPage: () => void;
  toPreviousPage: () => void;
  toNextPage: () => void;
  toLastPage: () => void;
  disableNext: boolean;
  disablePrevious: boolean;
};

export const CustomPagination = ({
  pageAmount,
  pageShowAmountOption,
  pageIndicator,
  toFirstPage,
  toPreviousPage,
  toNextPage,
  toLastPage,
  disableNext,
  disablePrevious,
}: CustomPaginationProps) => {
  const { t } = useTranslation();
  const getIconColor = (disabled: boolean) => (disabled ? "gray-900" : "gray-500");

  const smallMedia = useIsSmallScreen();
  const mediumMedia = useIsMediumOrLarger();
  const largeMedia = useIsLargeOrExtraLargeScreen();
  const allFits = largeMedia || (!smallMedia && !mediumMedia);

  return (
    <div className={`pagination justify-content-around`}>
      <div className="d-flex">
        {allFits && (
          <span className="d-flex flex-column justify-content-center ml-2">
            {t("companyTable.show")}
          </span>
        )}
        <DropdownButton
          className="d-flex flex-column mr-4 justify-content-center"
          variant="secondary"
          title={`${pageAmount} ${t("companyTable.rows")}`}
        >
          {PAGE_SIZE_OPTIONS.map((it, i) => (
            <Dropdown.Item
              key={i}
              onClick={() => {
                pageShowAmountOption(DEFAULT_PAGE_SIZE_OPTIONS[i]);
              }}
            >
              {it}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
      <div className="d-flex">
        {allFits && (
          <Button className="btn-secondary shadow-none p-0" onClick={toFirstPage} disabled={false}>
            <Icon color={getIconColor(!disablePrevious)} size="small" name={"doubleAngleLeft"} />
          </Button>
        )}
        <Button
          className="btn-secondary shadow-none"
          onClick={toPreviousPage}
          disabled={disablePrevious}
        >
          <Icon color={getIconColor(!disablePrevious)} size="small" name="angleLeft" />
        </Button>
        <span className="d-flex flex-column justify-content-center">{pageIndicator}</span>
        <Button className="btn-secondary shadow-none" onClick={toNextPage} disabled={disableNext}>
          <Icon color={getIconColor(!disableNext)} size="small" name="angleRight" />
        </Button>
        {allFits && (
          <Button
            className="btn-secondary shadow-none p-0"
            onClick={toLastPage}
            disabled={disableNext}
          >
            <Icon color={getIconColor(!disableNext)} size="small" name="doubleAngleRight" />
          </Button>
        )}
      </div>
    </div>
  );
};
