import {
  GetJoinRequestAggregateResult,
  getJoinRequestAggregate,
} from "../../graphqlQueries/getJoinRequests";

import { Company } from "../../graphqlQueries/getCompanyNames";
import { DMOProductsToCheck } from "./DMOProductsToCheck";
import { DmoNewJoinRequests } from "./DmoNewJoinRequests";
import { HasuradbDmoArea } from "../../graphqlQueries/getCuratorAreas";
import React from "react";
import { UserRole } from "../../types";
import { breakpointPixels } from "../../css/breakpoints";
import { getHasuraRoleContext } from "../../utils/functions";
import styled from "styled-components";
import { useQuery } from "@apollo/client";

const StyledNotificationWrapper = styled.div`
  .cardWrapper {
    &:first-child {
      width: 69%;
    }
    &:nth-child(2) {
      width: 30%;
    }
  }

  @media (max-width: ${breakpointPixels.xl}) {
    .cardWrapper {
      &:first-child {
        width: 100%;
      }
      &:nth-child(2) {
        width: 100%;
      }

      a:first-child {
        width: 100%;
      }
    }
  }
`;

type DMONotificationsProps = {
  company: Company;
  curatorAreas: HasuradbDmoArea[];
};

export const DmoNotifications = ({ company, curatorAreas }: DMONotificationsProps) => {
  const companyId = company.id;

  const { data: joinRequestData } = useQuery<GetJoinRequestAggregateResult>(
    getJoinRequestAggregate,
    {
      variables: { companyId },
      context: getHasuraRoleContext(UserRole.ManageCuration),
      fetchPolicy: "network-only",
    }
  );

  const newJoinRequests = joinRequestData?.companyJoinRequestAggregate.aggregate.count || 0;

  return (
    <StyledNotificationWrapper className="d-flex w-100 flex-wrap flex-xl-row flex-md-column justify-content-between">
      <div className="cardWrapper w-100">
        <DMOProductsToCheck companyId={companyId} curatorAreas={curatorAreas} />
      </div>
      {newJoinRequests ? (
        <div className="cardWrapper">
          <DmoNewJoinRequests
            businessName={company.businessName}
            newJoinRequests={newJoinRequests}
            companyId={companyId}
          />
        </div>
      ) : (
        <div className="col-11 col-md" />
      )}
    </StyledNotificationWrapper>
  );
};
