import React, { useState } from "react";

import { DataHubButton } from "../DataHubButton";
import Icon from "../Icon";
import { default as c } from "classnames";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TOAST_MAX_DISPLAY_TIME = 1200;

const StyledToastNotification = styled.div`
  opacity: 0;
  transition: opacity 0.2s linear;
`;

type LockedGroupNotificationProps = {
  publishingId: string;
  isLocked: boolean;
};

const LockedGroupNotification: React.FunctionComponent<LockedGroupNotificationProps> = ({
  publishingId,
  isLocked,
}) => {
  const { t } = useTranslation();
  const [showCopiedToast, setShowCopiedToast] = useState<boolean>(false);

  return (
    <div
      id="locked-group-notification-alert"
      className={c("p-4 d-flex flex-row", {
        "bg-orange-light": !isLocked,
        "bg-light-blue": isLocked,
      })}
    >
      <div className="mr-2">
        <Icon name={isLocked ? "lock" : "unlock"} size="medium" />
      </div>
      <div>
        <div className="font-weight-bold">
          {isLocked
            ? t("groupedProducts.lockedGroupHeader")
            : t("groupedProducts.unLockedGroupHeader")}
        </div>
        <div className="mb-4">
          {isLocked
            ? t("groupedProducts.lockedGroupNotificationBody")
            : t("groupedProducts.unLockedGroupNotificationBody")}
        </div>
        <div className="d-flex flex-row align-items-center">
          {t("groupedProducts.groupPublishingId")}:
          <span className="font-weight-bold ml-2 mr-4">{publishingId}</span>
          <DataHubButton
            variant="secondary"
            size="sm"
            onClick={() => {
              navigator.clipboard.writeText(publishingId);
              setShowCopiedToast(true);
              setTimeout(() => {
                setShowCopiedToast(false);
              }, TOAST_MAX_DISPLAY_TIME);
            }}
          >
            {t("groupedProducts.publishingIdCopyButton")}
          </DataHubButton>
          <StyledToastNotification
            className="ml-2"
            style={{ opacity: showCopiedToast ? "1" : "0" }}
          >
            <Icon name="check" size="small" /> {t("groupedProducts.copiedLinkToastBody")}
          </StyledToastNotification>
        </div>
      </div>
    </div>
  );
};

export default LockedGroupNotification;
