import { gql } from "@apollo/client";

export const getDmoByCity = gql`
  query GetDmoByCity($city: String) {
    dmoArea(where: { curationArea: { city: { _eq: $city } } }) {
      company {
        businessName
        contactDetails {
          email
        }
      }
    }
  }
`;

export interface DmoByCityQueryVars {
  city: string;
}

export interface DmoByCity {
  company?: {
    businessName: string;
    contactDetails?: {
      email: string;
    }[];
  };
}

export interface DmoByCityResult {
  dmoArea: DmoByCity[];
}
