import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { IconElementPosition } from "../types";

type IconButtonProps = ButtonProps & {
  iconElement: JSX.Element;
  iconPosition?: IconElementPosition;
  hideIcon?: boolean;
};

const IconButton = ({
  iconElement,
  iconPosition = "left",
  hideIcon,
  children,
  ...buttonProps
}: IconButtonProps) => {
  const iconLeft = iconPosition === "left";
  return (
    <Button {...buttonProps}>
      <div className="d-flex align-items-center">
        {iconLeft && !hideIcon && iconElement}
        {children}
        {!iconLeft && !hideIcon && iconElement}
      </div>
    </Button>
  );
};

export default IconButton;
