import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Icon from "../../components/Icon";

//TODO: check if this is relevant anymore
const ROOT_REDIRECT_DELAY_MS = 100;

const JoinRequestSent = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="d-flex align-items-center flex-column color-gray-900">
      <Icon name="check-circle" color="gray-400" style={{ width: "50px" }} />
      <h1 className="text-normal-size font-light my-4">
        {t("signup.companyExistsWizardRequestSentTitle")}
      </h1>

      <p className="text-center px-2 text-larger">
        {t("signup.companyExistsWizardRequestSentMainText")}
      </p>
      <p className="my-3 text-center">
        {t("signup.companyExistsWizardRequestSentWhileWaitingText")}
      </p>
      <Button
        className="mt-5"
        onClick={() => {
          setTimeout(() => {
            history.push("/");
          }, ROOT_REDIRECT_DELAY_MS);
        }}
      >
        {t("signup.companyExistsWizardRequestSentWhileWaitingButton")}
      </Button>
    </div>
  );
};

export default JoinRequestSent;
