import React from "react";

type CertificateLogoProps = {
  url: string;
  size: string;
  altText: string;
};

const logoUrl = (url: string, size: string) => `${url}?s=${size}`;

export const CertificateLogo = ({ size, url, altText }: CertificateLogoProps) => (
  <img className="d-block" src={logoUrl(url, size)} alt={altText} />
);
