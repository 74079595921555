import { FormProvider, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import { BasicInfoModal } from "../../DataHubModal";
import { DataHubButton } from "../../DataHubButton";
import { HasuradbProduct } from "../../../graphqlQueries/getProduct";
import { MyProductFormValues } from "../../../types";
import Notification from "../../Notification";
import ProductForm from "../../productForm/ProductForm";
import { mapDbProductToFormValues } from "../../../utils/mapDbProductToFormValues";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type EditProductsToGroupModalProps = {
  onHide: () => void;
  product?: HasuradbProduct;
  productGroupId: string;
  handleProductEditing: (values: HasuradbProduct) => void;
};

const StyledModalContainer = styled(BasicInfoModal)`
  .product-modal-toolbar {
    position: sticky;
    bottom: 0;
    margin: -1.5em;
    z-index: 9999;
    justify-content: flex-end;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.22);
    button {
      &:first-child {
        margin-right: 0.5em;
      }
    }
  }
  .modal-content {
    max-height: 95vh;
    overflow-y: scroll;
  }
`;

const EditProductsToGroupModal: React.FunctionComponent<EditProductsToGroupModalProps> = ({
  onHide,
  product,
  productGroupId,
  handleProductEditing,
}) => {
  const hookFormMethods = useForm<MyProductFormValues>();
  const [initialValues, setInitialValues] = useState<MyProductFormValues>();
  const { reset, getValues, watch } = hookFormMethods;
  const { t } = useTranslation();
  const images = watch("images");
  const details = watch("details");
  const hasChanges =
    details
      ?.map((detail) => detail.productDescription)
      .sort()
      .toString() !==
      initialValues?.details
        ?.map((detail) => detail.productDescription)
        .sort()
        .toString() || JSON.stringify(images) !== JSON.stringify(initialValues?.images);

  useEffect(() => {
    if (product) {
      const hasEditedInformationData = product.productGroupProductInformations
        ? product.productGroupProductInformations.length > 0
        : false;
      const hasEditedImageData = product.productGroupProductImages
        ? product.productGroupProductImages.length > 0
        : false;

      const data = {
        ...product,
        productInformations: hasEditedInformationData
          ? product.productGroupProductInformations
          : product.productInformations,
        productImages: hasEditedImageData
          ? product.productGroupProductImages
          : product.productImages,
      };

      const mappedData = mapDbProductToFormValues(data as HasuradbProduct);
      setInitialValues(mappedData);
      reset(mappedData);
    }
  }, [reset, product]);

  if (!product) {
    return <></>;
  }

  const onSubmit = async (values: MyProductFormValues) => {
    const productInformations = values.details.map((detail, i) => {
      return {
        ...product?.productInformations?.[i],
        productGroupId,
        productId: product.id,
        description: detail.productDescription,
      };
    });

    handleProductEditing({
      ...product,
      productGroupProductInformations: productInformations,
      productGroupProductImages: values.images?.map((image) => {
        return {
          ...image,
          productGroupId,
          productId: product.id,
        };
      }),
    });
    onHide();
  };

  return (
    <StyledModalContainer
      size="xl"
      header={t("groupedProducts.editProductModalHeader")}
      onCancel={onHide}
    >
      <h1>{t("groupedProducts.editProductHeader")}</h1>
      <Notification type="info">{t("groupedProducts.editProductNotification")}</Notification>
      <FormProvider {...hookFormMethods}>
        <ProductForm
          onSubmit={onSubmit}
          publishMode={"product-group"}
          editMode={true}
          id={"edit-product-group-product-form"}
          toolbar={
            <div className="product-modal-toolbar d-flex bg-white p-4">
              <DataHubButton variant="ghost" onClick={() => onHide()}>
                {t("common.cancel")}
              </DataHubButton>
              <DataHubButton
                disabled={!hasChanges}
                variant="primary"
                onClick={() => onSubmit(getValues())}
              >
                {t("common.save")}
              </DataHubButton>
            </div>
          }
          isModal={true}
        />
      </FormProvider>
    </StyledModalContainer>
  );
};

export default EditProductsToGroupModal;
