import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";

import DataHubFormField from "../DataHubFormField";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type GroupNameAndDescriptionSectionProps = {
  isLocked?: boolean;
};

const GroupNameAndDescriptionSection: React.FunctionComponent<GroupNameAndDescriptionSectionProps> = ({
  isLocked,
}) => {
  const { t } = useTranslation();

  const { register, errors } = useFormContext();
  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader>{t("groupedProducts.groupNameAndInstructionsHeader")}</FormSectionHeader>
      </FormDescription>
      <FormSectionContent>
        <div>
          <DataHubFormField
            controlId={"groupName"}
            name={"groupName"}
            label={t("groupedProducts.groupName")}
            isRequired={true}
            ref={register({
              required: "validationErrors.required",
            })}
            disabled={isLocked}
            errorMessage={errors?.groupName?.message}
          />
        </div>
        <div>
          <DataHubFormField
            controlId={"groupInstructions"}
            name={"groupInstructions"}
            label={t("groupedProducts.groupInstructions")}
            errorMessage={errors?.groupDescription?.message}
            ref={register()}
            as="textarea"
            disabled={isLocked}
            infoText={t("groupedProducts.instructionsAid")}
          />
        </div>
      </FormSectionContent>
    </FormSection>
  );
};

export default GroupNameAndDescriptionSection;
