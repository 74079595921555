import {
  ActionResult,
  ActionResultResponse,
  AddCommentFormTextKeys,
  DatahubType,
  ProductCurationStatus,
  UserRole,
  ViewMode,
} from "../../types";
import { Button, Spinner } from "react-bootstrap";
import { DocumentNode, useMutation } from "@apollo/client";
import {
  InsertCommentResult,
  InsertProductComment,
  getAddCommentMutation,
} from "../../graphqlQueries/addComment";
import React, { useState } from "react";

import { Form } from "react-bootstrap";
import { MAX_COMMENT_LENGTH } from "../../utils/constants";
import Notification from "../Notification";
import { Prompt } from "react-router-dom";
import { getHasuraRoleContext } from "../../utils/functions";
import { useCompanyId } from "../hooks/useCompanyId";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { userInfoVar } from "../../utils/ApolloCache";

type CommentFormData = {
  commentText: string;
};

type AddCommentProps = {
  productId: string;
  commentsQuery: DocumentNode;
  textKeys?: AddCommentFormTextKeys;
  viewMode?: ViewMode;
};

const commentFormCuratorTextKeys: AddCommentFormTextKeys = {
  formHeader: "commentsCurator.formHeader",
  commentHint: "commentsCurator.commentHint",
  send: "comments.send",
  clear: "comments.clear",
  leavePrompt: "comments.commentNotEmptyPrompt",
};

const commentFormUserTextKeys: AddCommentFormTextKeys = {
  formHeader: "commentsUser.formHeader",
  commentHint: "commentsUser.commentHint",
  send: "comments.send",
  clear: "comments.clear",
  leavePrompt: "comments.commentNotEmptyPrompt",
};

const AddCommentForm = ({
  productId,
  commentsQuery,
  viewMode,
  textKeys = viewMode === DatahubType.DMO ? commentFormCuratorTextKeys : commentFormUserTextKeys,
}: AddCommentProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, reset, watch, setValue } = useForm();
  const [sendResult, setSendResult] = useState<ActionResult>(ActionResultResponse.NO_RESULT);
  const [commentLength, setCommentLength] = useState(0);
  const [companyId] = useCompanyId();
  const commentNonEmpty = !!watch("commentText");
  const requestChangesMutation = getAddCommentMutation(false);

  const [requestChanges, { loading: isSubmitting }] = useMutation<
    InsertCommentResult,
    InsertProductComment
  >(requestChangesMutation);

  const refetchQueries = [commentsQuery];

  const resetForm = () => {
    reset();
    setCommentLength(0);
  };

  const onSubmit = async ({ commentText }: CommentFormData) => {
    const baseVariables = {
      productId,
      commentText,
      companyId: companyId ?? "",
      userName: userInfoVar()?.fullName ?? "",
    };

    // TODO: get companyId, userName from hasura session-variables (needs rbac config)
    const doChangeRequest = async () => {
      if (viewMode === DatahubType.DMO) {
        return await requestChanges({
          variables: {
            ...baseVariables,
            commentRecipientRole: DatahubType.TC,
            productCurationStatus: ProductCurationStatus.ChangesRequested,
          },
          context: getHasuraRoleContext(UserRole.ManageCuration),
          refetchQueries,
        });
      }

      return await requestChanges({
        variables: {
          ...baseVariables,
          commentRecipientRole: DatahubType.DMO,
          productCurationStatus: ProductCurationStatus.Updated,
        },
        context: getHasuraRoleContext(UserRole.ManageProducts),
        refetchQueries,
      });
    };

    // Because we first need to reset the form to prevent the Prompt from trigging,
    // to prevent the user from losing the possibly long comment,
    // we set the previously sent comment back in the textbox in cases when an error happens.
    // TODO: Fix better.
    try {
      resetForm();
      await doChangeRequest();
      setSendResult(ActionResultResponse.RESULT_OK);
    } catch (error) {
      setValue("commentText", baseVariables.commentText);
      if (error instanceof Error) {
        console.debug(error.message);
        if (error.message.includes(ActionResultResponse.LENGTH_ERROR_MESSAGE)) {
          setSendResult(ActionResultResponse.LENGTH_ERROR);
        } else {
          setSendResult(ActionResultResponse.GENERAL_ERROR);
        }
      } else {
        console.error("Unexpected error", error);
      }
    }
  };

  const responseMessage = (result: string): string => {
    if (result === ActionResultResponse.GENERAL_ERROR) {
      return t("commentsCurator.commentSendErrorNotificationText");
    }

    if (result === ActionResultResponse.LENGTH_ERROR) {
      return t("commentsCurator.commentSendLengthErrorNotificationText", {
        charLimit: MAX_COMMENT_LENGTH,
      });
    }

    return t("commentsCurator.commentSendSuccessNotificationText");
  };

  return (
    <div className="d-flex flex-column">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="commentText">
          <Form.Label>
            <h4>{t(textKeys.formHeader)}</h4>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            name="commentText"
            className={errors.commentText ? "is-invalid" : ""}
            onChange={({ target }) => setCommentLength(target.value.length)}
            ref={register({
              required: "validationErrors.required",
            })}
          />
          <div className="row">
            <div className="col">
              <p className="text-small color-gray-600">{t(textKeys.commentHint)}</p>
            </div>
            <div className="col-4 text-right">
              <p
                style={commentLength > MAX_COMMENT_LENGTH ? { color: "var(--color-invalid)" } : {}}
              >{`${commentLength}/${MAX_COMMENT_LENGTH}`}</p>
            </div>
          </div>
          {errors.commentText && <p className="text-danger">{t(errors.commentText.message)}</p>}
        </Form.Group>
        <div className="d-flex justify-content-end mb-1">
          <Button variant="secondary" onClick={() => resetForm()}>
            {t(textKeys.clear)}
          </Button>
          <Button variant="primary" type="submit" className="ml-4">
            <div className="d-flex align-items-center justify-content-center">
              {isSubmitting && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              )}
              <div className={isSubmitting ? "ml-2" : ""}>{t(textKeys.send)}</div>
            </div>
          </Button>
        </div>
      </Form>
      {sendResult !== ActionResultResponse.NO_RESULT && (
        <Notification
          type={sendResult === ActionResultResponse.RESULT_OK ? "success" : "danger"}
          onClose={() => setSendResult(ActionResultResponse.NO_RESULT)}
          className="my-2"
        >
          {responseMessage(sendResult)}
        </Notification>
      )}
      <Prompt when={commentNonEmpty} message={t("comments.commentNotEmptyPrompt")} />
    </div>
  );
};

export default AddCommentForm;
