import React from "react";
import { useTranslation } from "react-i18next";

import Icon from "../Icon";

type CommentProps = {
  commentsCount: number;
  newCommentsCount: number;
  additionalStyle?: string;
};

const CommentContainer = ({ additionalStyle, commentsCount, newCommentsCount }: CommentProps) => {
  const { t } = useTranslation();

  return (
    <div className={`color-gray-600 d-flex ${additionalStyle}`}>
      {commentsCount > 0 && (
        <>
          <div>{commentsCount}</div>
          <Icon name="comment-alt" size="medium" />
        </>
      )}
      {newCommentsCount > 0 && (
        <div className="text-small color-primary-dark text-uppercase font-weight-bold ml-2">
          {`${t("comments.newComments")} (${newCommentsCount})`}
        </div>
      )}
    </div>
  );
};

export default CommentContainer;
