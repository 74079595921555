import getCuratorAreas, {
  CuratorAreasResult,
  CuratorAreasResultVars,
} from "../../graphqlQueries/getCuratorAreas";

import { DMODashboard } from "./DMODashboard";
import Notification from "../Notification";
import React from "react";
import { UserRole } from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

type DMODashboardWrapperProps = {
  companyId: string;
};

export const DMODashboardWrapper: React.FunctionComponent<DMODashboardWrapperProps> = ({
  companyId,
}) => {
  const currentRole = UserRole.ManageCuration;
  const { t } = useTranslation();

  const { data: curatorAreas, error: curatorAreaError } = useQuery<
    CuratorAreasResult,
    CuratorAreasResultVars
  >(getCuratorAreas, {
    variables: { id: companyId },
    context: getHasuraRoleContext(currentRole),
  });

  if (curatorAreaError) {
    return <Notification type="danger">{t("common.error")}</Notification>;
  }

  if (!curatorAreas) {
    return null;
  }

  return (
    <DMODashboard
      companyId={companyId}
      currentRole={UserRole.ManageCuration}
      curatorAreas={curatorAreas.dmoArea}
    />
  );
};
