import { gql } from "@apollo/client";

export const getAllCuratorAreaCompanies = gql`
  query geAllCuratorAreaCompanies($curatorAreas: [uuid!]) {
    company(where: { postalAddresses: { postalArea: { city: { id: { _in: $curatorAreas } } } } }) {
      id
      businessName
      createdAt
      websiteUrl
      contactDetails {
        email
        phone
      }
      datahubType
      productsAggregate: products_aggregate {
        aggregate {
          count
        }
      }
      postalAddresses {
        city
      }
    }
  }
`;
