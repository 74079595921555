import { HasuradbCategoryGroup } from "./getTagsWithCategories";
import { HasuradbMainCategoryGroup } from "./getMainCategories";
import { gql } from "@apollo/client";

export const getTagsWithMainCategories = gql`
  query getTagsWithMainCategories($productType: hasuradb_product_types_enum!) {
    mainCategories: productTypeMainCategory(where: { productType: { _eq: $productType } }) {
      groupName
      categoryGroup {
        groupName
        tags(order_by: { tag: asc }) {
          tag
        }
      }
    }
    allCategories: categoryGroup {
      groupName
      tags(order_by: { tag: asc }) {
        tag
      }
    }
  }
`;

export type MainTagsQueryVariables = {
  productType: string;
};
export type HasuradbMainCategoryGroupWithName = HasuradbMainCategoryGroup & {
  groupName: string;
};

export interface MainTagsQueryResult {
  mainCategories: HasuradbMainCategoryGroupWithName[];
  allCategories: HasuradbCategoryGroup[];
}
