import { useQuery } from "@apollo/client";

import getUserInfoLocal, { UserInfoData } from "../../graphqlQueries/getUserInfoLocal";

const useUserInfo = () => {
  const { data, error } = useQuery<UserInfoData>(getUserInfoLocal);
  const userInfo = data?.userInfo || null;

  return { userInfo, error };
};

export default useUserInfo;
