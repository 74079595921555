import { gql } from "@apollo/client";

import { ProductGroup, ProductGroupInput, ProductGroupProductInput } from "../../types";

export const insertProductGroupMutation = gql`
  mutation InsertProductGroup($productGroup: hasuradb_product_group_insert_input!) {
    insertProductGroupOne(object: $productGroup) {
      id
      name
      description
      createdAt
      published
      publishedAt
      productsAggregate: products_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export type InsertProductGroupResult = {
  insertProductGroupOne: ProductGroup;
};

export type InsertProductGroupVariables = {
  productGroup: ProductGroupInput & {
    products?: {
      data: ProductGroupProductInput[];
    };
  };
};
