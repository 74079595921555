import React from "react";
import styled from "styled-components";

import Footer from "../Footer";
import DataHubUserLogos from "../landingPage/DataHubUserLogos";
import DashboardSupport from "./DashboardSupport";
import ApolloCMSServiceProvider from "../../utils/ApolloCMSServiceProvider";

type DashboardLayoutProps = {
  header: React.ReactNode;
  content: React.ReactNode;
  withWrapper?: boolean;
};

const ContentWrapper = styled.div`
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.33);
  background-color: var(--color-white);
  padding: 1.75rem 1.5rem;
`;

const DashboardLayout: React.FunctionComponent<DashboardLayoutProps> = ({
  header,
  content,
  withWrapper,
}) => {
  return (
    <div className="p-3 m-md-3 mx-lg-4">
      {withWrapper ? (
        <ContentWrapper>
          <div className="pl-2">{header}</div>
          {content}
        </ContentWrapper>
      ) : (
        <>
          {header}
          {content}
        </>
      )}
      <div className="row">
        <ApolloCMSServiceProvider isPreview={false}>
          <DataHubUserLogos isDashboard={true} />
        </ApolloCMSServiceProvider>
        <DashboardSupport />
        <Footer />
      </div>
    </div>
  );
};

export default DashboardLayout;
