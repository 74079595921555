import DummyLink from "../DummyLink";
import React from "react";
import Separator from "../Separator";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DashboardSubHeader = styled.h4`
  margin-bottom: 1rem;
`;

type DashboardProps = {
  isDmo: boolean;
};

const DashboardInformation = ({ isDmo }: DashboardProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      {isDmo ? (
        <>
          <div className="pr-4 col-md-6">
            <Separator />
            <DashboardSubHeader>{t("dashboard.dmoUsefulLinks")}</DashboardSubHeader>
            <ol>
              <li>
                <div>{t("dashboard.dmoUsefulLink1Header")}</div>
                <DummyLink onClick={() => history.push("contact-information")}>
                  {t("dashboard.dmoUsefulLink1")}
                </DummyLink>
              </li>
              <li>
                <div>{t("dashboard.dmoUsefulLink2Header")}</div>
                <DummyLink onClick={() => history.push("curator-workspace")}>
                  {t("dashboard.dmoUsefulLink2")}
                </DummyLink>
              </li>
              <li>
                <div>{t("dashboard.dmoUsefulLink3Header")}</div>
                <DummyLink onClick={() => history.push("products")}>
                  {t("dashboard.dmoUsefulLink3")}
                </DummyLink>
              </li>
            </ol>
          </div>
        </>
      ) : (
        <div className="pr-4 col-md-6">
          <DashboardSubHeader data-cy="dashboard-subheader-tc">
            {t("dashboard.tcSubHeader")}
          </DashboardSubHeader>
          <p>{t("dashboard.tcIntro1")}</p>
          <p>{t("dashboard.tcIntro3")}</p>
          <Separator />
          <DashboardSubHeader>{t("dashboard.tcUsefulLinks")}</DashboardSubHeader>
          <ol>
            <li>
              <div>{t("dashboard.tcUsefulLink1Header")}</div>
              <DummyLink onClick={() => history.push("contact-information")}>
                {t("dashboard.tcUsefulLink1")}
              </DummyLink>
            </li>
            <li>
              <div>{t("dashboard.tcUsefulLink2Header")}</div>
              <DummyLink onClick={() => history.push("products")}>
                {t("dashboard.tcUsefulLink2")}
              </DummyLink>
            </li>
          </ol>
        </div>
      )}
    </>
  );
};

export default DashboardInformation;
