import React from "react";
import { useTranslation } from "react-i18next";
import { CurationStatusesFilter, UserRole } from "../../types";
import { TCDashboardHeaderDropdown } from "./TCDashboardHeaderDropdown";
import { DataHubButton } from "../DataHubButton";
import { useHistory } from "react-router-dom";
import { COMPANY_PATH, PRODUCTS_PATH } from "../../utils/routeConstants";
import { STATUS_KEY } from "../../utils/filterUtils";
import {
  StyledMainHeader,
  StyledSummaryHeaderContainer,
  SummaryNumberContainer,
} from "./DashboardComponents";
import { Company } from "../../graphqlQueries/getCompanyNames";
import {
  getProductCitiesByCompany,
  ProductCitiesQueryVars,
  ProductCitiesResult,
} from "../../graphqlQueries/getProductCitiesByCompany";
import { useQuery } from "@apollo/client";
import { getHasuraRoleContext } from "../../utils/functions";

export const TCSummaryHeader: React.FunctionComponent<{ company: Company }> = ({ company }) => {
  const { t } = useTranslation();

  const { data: productData } = useQuery<ProductCitiesResult, ProductCitiesQueryVars>(
    getProductCitiesByCompany,
    {
      variables: { companyId: company.id },
      context: getHasuraRoleContext(UserRole.ManageProducts),
    }
  );

  return (
    <StyledSummaryHeaderContainer className="row p-4 dashboard-card no-gutters text-center text-md-left">
      <div className="col-xs-12 col-md-6 mb-4 mb-md-0">
        <div>
          <div data-cy="dashboard-subheader-tc">
            <h4>{t("dashboard.header")}</h4>
          </div>
          <div>
            <StyledMainHeader className="clamp-2">{company.businessName}</StyledMainHeader>
          </div>
          <div className="d-none d-md-block">
            <Buttons company={company} />
          </div>
        </div>
      </div>
      <div data-cy="curator-summary" className="col-xs-12 col-md-6">
        <div className="ml-0 ml-md-4">
          <div>
            <h4>{t("dashboard.companySummaryTitle")}</h4>
          </div>
          <div className="d-flex justify-content-between flex-sm-row flex-column">
            {company && (
              <SummaryNumberContainer className="w-100">
                <TCDashboardHeaderDropdown products={productData?.product} />
              </SummaryNumberContainer>
            )}
          </div>
        </div>
        <div className="d-block d-md-none">
          <Buttons company={company} />
        </div>
      </div>
    </StyledSummaryHeaderContainer>
  );
};

const Buttons: React.FunctionComponent<{ company: Company }> = ({ company }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onNewProductClick = () => {
    history.push({
      pathname: `${COMPANY_PATH}/${company.id}${PRODUCTS_PATH}`,
      search: `${STATUS_KEY}=${CurationStatusesFilter.Draft}`,
      state: { newProduct: true },
    });
  };

  const onOwnProductsClick = () => {
    history.push({
      pathname: `${COMPANY_PATH}/${company.id}${PRODUCTS_PATH}`,
      search: `${STATUS_KEY}=${CurationStatusesFilter.AllCompanyProducts}`,
    });
  };

  return (
    <div className="d-flex justify-content-center justify-content-md-start">
      <DataHubButton variant="primary" className="mt-auto mr-4" onClick={onNewProductClick}>
        + {t("products.newProductCta")}
      </DataHubButton>
      <DataHubButton variant="secondary" className="mt-auto" onClick={onOwnProductsClick}>
        {t("navigation.myProducts")}
      </DataHubButton>
    </div>
  );
};
