import { CurationStatus, ProductStatus, UserRole } from "../../types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "react-bootstrap";
import { HasuradbProduct } from "../../graphqlQueries/getProduct";
import Icon from "../Icon";
import IconButton from "../IconButton";
import LastEdit from "../LastEdit";
import Notification from "../Notification";
import { useIsSmallScreen } from "../MediaQuery";
import SmallMobileMenu from "../SmallMobileMenu";
import TranslationInfoModal from "../TranslationInfoModal";
import { capitalize } from "../../utils/functions";
import { hasBrokenLink } from "../../utils/productFormUtils/productUtils";
import { useCommentsCount } from "../hooks/useCommentsCount";
import { useCompanyId } from "../hooks/useCompanyId";
import { useTranslation } from "react-i18next";
import ExternalLink from "../ExternalLink";

type ViewProductCompanyToolbarProps = {
  product: HasuradbProduct;
  showComments: boolean;
  setShowComments: (show: boolean) => void;
};

const ViewProductCompanyToolbar = ({
  showComments,
  setShowComments,
  product,
}: ViewProductCompanyToolbarProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [companyId] = useCompanyId();

  const showMobileMenuButton = useIsSmallScreen();

  const [showTranslationModal, setShowTranslationModal] = useState<boolean>(false);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  const { data: commentsCountData } = useCommentsCount({
    productId: product.id,
    userRole: UserRole.ManageProducts,
  });

  const { commentsCount = 0, newCommentsCount = 0 } = commentsCountData;

  const brokenLink = hasBrokenLink(product);

  const productId = product.id;
  const showCommentsButton = product.status === ProductStatus.Published || commentsCount > 0;
  const curationStatus = product.productCurations?.[0].curationStatus;

  const OrderTranslationButton = (
    <Button
      variant="secondary"
      className="mr-3 mb-3"
      onClick={() => {
        setShowTranslationModal(true);
        setShowMobileMenu(false);
      }}
    >
      {t("translationInfoModal.header")}
    </Button>
  );

  return (
    <div className="sticky-top bg-white border-gray-bottom" style={{ zIndex: 1 }}>
      {brokenLink && (
        <Notification type="info-alt">
          <p className="m-0">{t("viewProduct.productHasBrokenLinkTc")}</p>
        </Notification>
      )}
      {!!(curationStatus !== CurationStatus.Approved && product.externalSource) && (
        <Notification type="info">
          <div>
            <p className="m-0">
              {t("viewProduct.productIsImported", {
                externalSource: capitalize(product.externalSource),
              })}
            </p>
            <ExternalLink href={t(`viewProduct.checklist_${product.externalSource}`)}>
              {t("viewProduct.checklist")}
            </ExternalLink>
          </div>
        </Notification>
      )}
      <div className="px-3 pb-3 pt-3">
        <div className="d-flex justify-content-between flex-wrap-reverse">
          <div className="d-flex flex-grow-1 flex-row-reverse flex-nowrap flex-sm-wrap">
            {showMobileMenuButton && (
              <SmallMobileMenu
                className="p-2 ml-1 mb-3 ml-sm-2"
                show={showMobileMenu}
                setShow={setShowMobileMenu}
              >
                <div className="mb-n3">{OrderTranslationButton}</div>
              </SmallMobileMenu>
            )}
            <Button
              variant="light"
              className="mb-3"
              onClick={() => history.push(`/company/${companyId}/products/edit/${productId}`)}
            >
              {t("viewProduct.editProductButton")}
            </Button>
            {showCommentsButton && (
              <IconButton
                className="mr-1 mb-3 mr-sm-3"
                variant="light"
                iconElement={
                  <Icon className="mr-2" name="circle-filled" color="primary" size="x-small" />
                }
                hideIcon={newCommentsCount === 0}
                onClick={() => setShowComments(!showComments)}
              >
                {showComments ? t("comments.hide") : `${t("comments.show")} (${commentsCount})`}
              </IconButton>
            )}
            {!showMobileMenuButton && OrderTranslationButton}
          </div>
        </div>
        <LastEdit
          dateStr={product.updatedAt}
          editorName={
            product.updatedByUser?.name === ""
              ? t("userManagement.userNotFound")
              : product.updatedByUser?.name
          }
        />
      </div>
      {showTranslationModal && (
        <TranslationInfoModal onHide={() => setShowTranslationModal(false)} />
      )}
    </div>
  );
};

export default ViewProductCompanyToolbar;
