import { gql } from "@apollo/client";

import { ProductInfoLinkIds, ProductVideoLinkId } from "./deleteProduct";

export const getProductLinkIdsQuery = gql`
  query GetProductLinkIds($productId: uuid!) {
    productByPk(id: $productId) {
      productInformations {
        verifiedWebshopLink {
          id
        }
        verifiedProductLink {
          id
        }
      }
      productVideos {
        verifiedVideoLink {
          id
        }
      }
    }
  }
`;

export type GetProductLinkIdsVariables = {
  productId: string;
};

export type GetProductLinkIdsResult = {
  productByPk: {
    productInformations: ProductInfoLinkIds[];
    productVideos: ProductVideoLinkId[];
  };
};
