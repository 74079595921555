import React from "react";
import ViewProductHeader from "./ViewProductHeader";
import { useTranslation } from "react-i18next";
import ExternalLink from "../ExternalLink";
import { NotAvailable } from "./NotAvailable";

type ViewProductExternalLinksProps = {
  webpage?: string;
  webshop?: string;
};

const ViewProductExternalLinks = ({ webpage, webshop }: ViewProductExternalLinksProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ViewProductHeader>{t("productInfo.externalLinks")}</ViewProductHeader>
      {!webpage && !webshop ? (
        <NotAvailable />
      ) : (
        <div className="d-flex mb-4">
          {webpage && (
            <ExternalLink className="mr-4" href={webpage}>
              {t("viewProduct.webpage")}
            </ExternalLink>
          )}
          {webshop && <ExternalLink href={webshop}>{t("viewProduct.webshop")}</ExternalLink>}
        </div>
      )}
    </>
  );
};

export default ViewProductExternalLinks;
