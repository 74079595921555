import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Icon from "./Icon";
import IconButton from "./IconButton";

type SmallMobileMenuProps = {
  className?: string;
  show?: boolean;
  setShow?: (show: boolean) => void;
};

const SmallMobileMenu = ({
  className,
  children,
  show,
  setShow,
}: React.PropsWithChildren<SmallMobileMenuProps>) => {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  return (
    <OverlayTrigger
      show={setShow ? show : showMobileMenu}
      placement="bottom"
      overlay={
        <Popover id="mobileMenu">
          <Popover.Content>
            <div className="d-flex justify-content-between">{children}</div>
          </Popover.Content>
        </Popover>
      }
    >
      <IconButton
        variant="secondary"
        className={className}
        iconElement={<Icon name="ellipsis-v" size="medium" />}
        onClick={() => (setShow ? setShow(!show) : setShowMobileMenu(!showMobileMenu))}
      />
    </OverlayTrigger>
  );
};

export default SmallMobileMenu;
