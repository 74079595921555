import React from "react";
import Icon, { IconName } from "../Icon";

export const TCNotificationWrapper: React.FunctionComponent<{
  title: string;
  titleLeft?: string;
  icon: IconName;
}> = ({ title, titleLeft, icon, children }) => {
  return (
    <div className="dashboard-card h-100">
      <div className={"d-flex h-100 flex-column"}>
        <div
          className="border-gray-300-bottom-2"
          style={{ borderTop: "5px solid var(--color-dark-blue)" }}
        >
          <div className="px-4 py-3">
            <div
              className="d-inline-flex w-100 flex-wrap justify-content-between"
              style={{ verticalAlign: "sub" }}
            >
              <div className="mt-auto">
                <Icon size="medium" name={icon} className="mr-3" style={{ verticalAlign: "sub" }} />
                <h6 className="mb-0 d-inline">{title}</h6>
              </div>
              {titleLeft && <div className="h3 mb-0 font-heavy">{titleLeft}</div>}
            </div>
          </div>
        </div>
        <div className="px-4 py-2 mb-auto mt-auto">{children}</div>
      </div>
    </div>
  );
};
