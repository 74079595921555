import { Button, Col, Form, Row } from "react-bootstrap";
import {
  FormSectionSubHeader,
  FormSubDescription,
  FormSubSection,
  FormSubSectionContent,
} from "../FormSection";

import { HasuraContactDetail } from "../../graphqlQueries/getSingleCompanyInfo";
import PhoneInput from "../PhoneInput";
import { ProductPublishMode } from "../../types";
import React from "react";
import { default as c } from "classnames";
import { getRequiredValidator } from "../../utils/formUtils";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type ContactDetailsSectionProps = {
  companyContactDetails?: HasuraContactDetail;
  publishMode: ProductPublishMode;
  isProductGroup?: boolean;
};

const MAX_PHONE_LENGTH = 20;
const MAX_EMAIL_LENGTH = 100;

const ContactDetailsSection = ({
  companyContactDetails,
  publishMode,
  isProductGroup,
}: ContactDetailsSectionProps) => {
  const { t } = useTranslation();
  const { register, errors, setValue } = useFormContext();

  return (
    <FormSubSection>
      <FormSubDescription isLastRow>
        <FormSectionSubHeader>{t("productInfo.contactDetailsHeader")}</FormSectionSubHeader>
        {!isProductGroup && <p>{t("productInfo.contactDetailsDescription")}</p>}
      </FormSubDescription>
      <FormSubSectionContent isLastRow>
        <Row>
          <Col xs="12" lg="6">
            <Form.Group controlId="phonenumber">
              <Form.Label>{t("productInfo.phonenumber")}*</Form.Label>
              <PhoneInput
                name="phonenumber"
                aria-describedby="phoneHelpBlock"
                disabled={isProductGroup}
                rules={{
                  maxLength: {
                    value: MAX_PHONE_LENGTH,
                    message: t("validationErrors.textTooLong", { max: MAX_PHONE_LENGTH }),
                  },
                  validate: {
                    required: getRequiredValidator(publishMode, false, t),
                  },
                }}
              />
              <Form.Text id="phoneHelpBlock" className="text-normal-size color-gray-600">
                {t("productInfo.phonenumberHelp")}
              </Form.Text>
              {errors.phonenumber && <p className="text-danger">{errors.phonenumber.message}</p>}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="email">
              <Form.Label>{t("productInfo.email")}*</Form.Label>
              <Form.Control
                type="text"
                name="email"
                disabled={isProductGroup}
                ref={register({
                  maxLength: {
                    value: MAX_EMAIL_LENGTH,
                    message: t("validationErrors.textTooLong", { max: MAX_EMAIL_LENGTH }),
                  },
                  validate: {
                    required: getRequiredValidator(publishMode, false, t),
                  },
                  pattern: {
                    value: /[@]/,
                    message: t("validationErrors.emailNotValid"),
                  },
                })}
                className={c({
                  "is-invalid": errors.email,
                })}
              />
              {errors.email && <p className="text-danger">{errors.email.message}</p>}
            </Form.Group>
          </Col>
        </Row>
        {companyContactDetails && (
          <Row>
            <Col>
              <Button
                variant="light"
                disabled={isProductGroup}
                onClick={() => {
                  const valueUpdateOptions = {
                    shouldValidate: true,
                    shouldDirty: true,
                  };
                  setValue("email", companyContactDetails?.email, valueUpdateOptions);
                  setValue("phonenumber", companyContactDetails?.phone, valueUpdateOptions);
                }}
              >
                {t("productInfo.fillWithCompanyDetails")}
              </Button>
            </Col>
          </Row>
        )}
      </FormSubSectionContent>
    </FormSubSection>
  );
};

export default ContactDetailsSection;
