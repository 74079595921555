import React, { useEffect, useState } from "react";

import { CustomPagination } from "./CustomPagination";
import { DropdownOption } from "../types";
import ProductListPagingContainer from "./products/ProductListPagingContainer";
import { useTranslation } from "react-i18next";

type PagingProps = {
  productsLength: number;
  totalProductCount: number;
  productsLoading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  selectedAmountOption: DropdownOption;
  setSelectedAmountOption: React.Dispatch<React.SetStateAction<DropdownOption>>;
};

export const Paging = ({
  productsLength,
  totalProductCount,
  productsLoading,
  page,
  setPage,
  selectedAmountOption,
  setSelectedAmountOption,
}: PagingProps) => {
  const [pageAmount, setPageAmount] = useState(0);
  const { t } = useTranslation();
  const handlePageSettingChange = (selectedPage: DropdownOption) => {
    setSelectedAmountOption(selectedPage);
    setPage(0);
  };
  const limit = selectedAmountOption.value;

  useEffect(() => {
    if (!productsLoading) {
      const pageAmount = totalProductCount === 0 ? 0 : Math.ceil(totalProductCount / limit);
      setPageAmount(pageAmount);
    }
  }, [limit, productsLoading, totalProductCount]);

  if (productsLength < 0) {
    return null;
  }
  const disablePrevious = page === 0;
  const disableNext = page === pageAmount - 1;

  return (
    <ProductListPagingContainer className="product-list-paging-container">
      <CustomPagination
        pageAmount={limit}
        pageShowAmountOption={handlePageSettingChange}
        pageIndicator={t("companyTable.pageIndicator", {
          pageIndex: page + 1,
          pageCount: pageAmount,
        })}
        toFirstPage={() => setPage(0)}
        toPreviousPage={() => setPage(page - 1)}
        toNextPage={() => setPage(page + 1)}
        toLastPage={() => setPage(pageAmount - 1)}
        disableNext={disableNext}
        disablePrevious={disablePrevious}
      />
    </ProductListPagingContainer>
  );
};
