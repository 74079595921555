import { gql } from "@apollo/client";

export const removeProductGroupPermissionsMutation = gql`
  mutation RemoveProductGroupPermissions($productGroupId: uuid!, $permissionRowIds: [uuid!]!) {
    RemoveProductGroupPermissions(
      productGroupId: $productGroupId
      permissionRowIds: $permissionRowIds
    ) {
      status
    }
  }
`;

export type removeProductGroupPermissionsResult = {
  CreateProductGroupPermissions: {
    status: string;
  };
};

export type removeProductGroupPermissionsVariables = {
  productGroupId: string;
  permissionRowIds: string[];
};
