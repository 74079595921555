import { MutationTuple } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { DeleteBulletinResult, DeleteBulletinVariables } from "../../graphqlQueries/deleteBulletin";
import { Bulletin } from "../../graphqlQueries/getBulletin";
import ConfirmButton from "../ConfirmButton";
import DummyLink from "../DummyLink";
import Icon from "../Icon";

type BulletinListHeaderProps = {
  bulletin: Bulletin;
  onSelect: (bulletin: Bulletin) => void;
  setShowCreateForm?: (bool: boolean) => void;
  canEdit?: boolean;
  setIsEditing?: (bool: boolean) => void;
  setError: (error: string | null) => void;
  deleteMutation?: MutationTuple<DeleteBulletinResult, DeleteBulletinVariables>;
};

const BulletinListElementHeader = ({
  bulletin,
  canEdit,
  setShowCreateForm,
  setIsEditing,
  onSelect,
  setError,
  deleteMutation,
}: BulletinListHeaderProps) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const deleteAction = deleteMutation?.[0];

  return (
    <div className="d-flex flex-row justify-content-between mt-2">
      <p className="text-small color-gray-600 mb-0">
        {moment(bulletin.createdAt).format("D.M.YYYY")}
      </p>
      {canEdit && setShowCreateForm && setIsEditing && (
        <span>
          <DummyLink
            onClick={() => {
              onSelect(bulletin);
              setShowCreateForm(true);
              setIsEditing(true);
            }}
          >
            <Icon name="edit" size="medium" />
          </DummyLink>
          {deleteAction && (
            <ConfirmButton
              id="delete-confirm"
              showIcon
              confirmStyle="warning"
              headerContent={t("dashboard.deleteBulletinPopoverHeader")}
              popOverContent={
                <p className="pb-3">{t("dashboard.deleteBulletinPopoverDescription")}</p>
              }
              confirmButtonContent={t("common.delete")}
              onConfirmClick={() => {
                deleteAction({
                  variables: {
                    bulletinId: bulletin.id,
                    deletedAt: moment().toISOString(),
                  },
                }).catch(() => setError("dashboard.bulletinDeleteError"));
              }}
              triggerButtonContent={t("common.discardChanges")}
              renderTriggerButton={
                <DummyLink
                  onClick={() => {
                    setShow((value) => !value);
                  }}
                >
                  <Icon className="ml-3" name="trash" size="medium" />
                </DummyLink>
              }
              show={show}
              closePopover={() => setShow(!show)}
            />
          )}
        </span>
      )}
    </div>
  );
};

export default BulletinListElementHeader;
