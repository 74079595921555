import styled from "styled-components";

const Tag = styled.div`
  display: inline-block;
  border-radius: 3px;
  padding: 0.25rem 0.5rem;
  background-color: var(--color-gray-100);
  color: var(--color-gray-700);
  margin: 0.25rem 0.5rem 0.25rem 0;
  font-size: 0.9rem;
`;

export default Tag;
