import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Icon from "./Icon";

const PopOverContainer = styled.div`
  width: 350px;
`;

const IconContainer = styled.div`
  display: inline-block;
  width: 22px;
  flex-shrink: 0;
`;

type ConfirmButtonProps = {
  id: string;
  onTriggerButtonClick?: () => void;
  onConfirmClick?: () => void;
  onCancelButtonClick?: () => void;
  confirmStyle?: "warning" | "info";
  triggerButtonContent?: React.ReactNode;
  confirmButtonContent?: React.ReactNode;
  cancelButtonContent?: React.ReactNode;
  headerContent: React.ReactNode;
  popOverContent: React.ReactNode;
  confirmButtonVariant?: string;
  disabled?: boolean;
  showIcon?: boolean;
  renderTriggerButton?: JSX.Element;
  renderConfirmButton?: JSX.Element;
  popoverPlacement?: Placement;
  show: boolean;
  closePopover: (val: string) => void;
};

const ConfirmButton = ({
  id,
  onTriggerButtonClick,
  onConfirmClick,
  onCancelButtonClick,
  headerContent,
  triggerButtonContent,
  confirmButtonContent,
  cancelButtonContent,
  popOverContent,
  confirmStyle = "info",
  disabled = false,
  renderTriggerButton,
  confirmButtonVariant = "light",
  renderConfirmButton,
  showIcon,
  popoverPlacement = "bottom",
  show,
  closePopover,
}: ConfirmButtonProps) => {
  const { t } = useTranslation();

  const popover = (
    <Popover id={id}>
      <Popover.Title>
        {showIcon && (
          <IconContainer className="mr-3">
            <Icon
              name={confirmStyle === "info" ? "exclamation" : "warning-triangle"}
              color="primary-dark"
            />
          </IconContainer>
        )}
        {headerContent}
      </Popover.Title>
      <Popover.Content>
        <PopOverContainer>
          {popOverContent}
          <div className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => {
                closePopover(id);
                onCancelButtonClick && onCancelButtonClick();
              }}
            >
              {cancelButtonContent ?? t("common.cancel")}
            </Button>
            {renderConfirmButton || (
              <Button
                variant={confirmButtonVariant}
                onClick={() => {
                  closePopover(id);
                  onConfirmClick && onConfirmClick();
                }}
              >
                {confirmButtonContent ?? t("common.confirm")}
              </Button>
            )}
          </div>
        </PopOverContainer>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger show={show} placement={popoverPlacement} overlay={popover}>
      {renderTriggerButton || (
        <Button
          variant="secondary"
          className="ml-3"
          disabled={disabled}
          onClick={() => {
            closePopover(id);
            onTriggerButtonClick && onTriggerButtonClick();
          }}
        >
          {triggerButtonContent}
        </Button>
      )}
    </OverlayTrigger>
  );
};

export default ConfirmButton;
