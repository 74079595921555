import {
  CurationStatus,
  DataHubImage,
  ExternalProductSource,
  Language,
  ProductStatus,
  ProductType,
} from "../../types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import CommentContainer from "./ProductComments";
import Icon from "../Icon";
import ListItemContainer from "../ListItemContainer";
import ListItemTextContainer from "../ListItemTextContainer";
import ListItemThumbnailContainer from "../ListItemThumbnailContainer";
import ProductListItemHeader from "../ProductListItemHeader";
import ProductListItemLanguages from "../ProductListItemLanguages";
import React from "react";
import Thumbnail from "../Thumbnail";
import { capitalize } from "../../utils/functions";
import { getProductTypeHeaderKey } from "../../utils/localizationUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export type ProductListItemType = {
  productType: ProductType;
  images: DataHubImage[];
  name: string;
  id: string;
  commentsCount: number;
  newCommentsCount: number;
  curationStatus: CurationStatus;
  productStatus: ProductStatus;
  languages: Language[];
  hasBrokenLink?: boolean;
  externalProduct?: ExternalProductSource;
};

type ProductListItemProps = {
  item: ProductListItemType;
  selected: boolean;
  onClick: (id: string) => void;
};

type StatusContainerProps = {
  status: ProductStatus;
};
const StatusContainer = styled.div<StatusContainerProps>`
  color: ${({ status }) =>
    status === ProductStatus.Published ? "var(--color-green);" : "var(--color-gray-600);"}
  font-size: 0.9rem;
  display: flex;
`;

const IconContainer = styled.div`
  width: 22px;
  margin-right: 3px;
  margin-top: -2px;
`;

const StatusTextContainer = styled.div`
  margin-top: 1px;
`;

const ProductTypeContainer = styled.div`
  color: var(--color-gray-800);
  font-size: 0.9rem;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ThumbnailPlaceHolder = styled.div`
  width: 75px;
  height: 75px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-400);
  border-radius: 8px;
  flex-shrink: 0;
`;

const ProductListItem = ({ item, onClick, selected }: ProductListItemProps) => {
  const { t } = useTranslation();
  const coverImage = item.images.find((image) => image.coverPhoto) ?? item.images[0];

  return (
    <ListItemContainer onClick={() => onClick(item.id)} selected={selected}>
      <div className="d-flex">
        <ListItemThumbnailContainer>
          {item.images.length > 0 ? (
            <Thumbnail
              image={coverImage}
              showDelete={false}
              altText={coverImage.altText ?? ""}
              listItemStyle
              className="mr-3"
            />
          ) : (
            <ThumbnailPlaceHolder className="mr-3" />
          )}
        </ListItemThumbnailContainer>

        <ListItemTextContainer>
          <ProductListItemHeader>{item.name ? item.name : ""}</ProductListItemHeader>
          <RowContainer>
            <ProductTypeContainer className="mb-2">
              {t(getProductTypeHeaderKey(item.productType))}
            </ProductTypeContainer>
            <div className="d-flex row align-items-center mr-0">
              {item.hasBrokenLink && <Icon name="brokenLink" className="mr-2" />}
              <CommentContainer
                commentsCount={item.commentsCount}
                newCommentsCount={item.newCommentsCount}
              />
            </div>
          </RowContainer>
          <StatusContainer status={item.productStatus}>
            {item.productStatus === ProductStatus.Published && (
              <IconContainer>
                {item.curationStatus === CurationStatus.Approved ? (
                  <Icon name="check-double" />
                ) : (
                  <Icon name="check" />
                )}
              </IconContainer>
            )}
            {item.productStatus === ProductStatus.Draft ? (
              <StatusTextContainer>{t("productInfo.draftStatus")}</StatusTextContainer>
            ) : (
              <StatusTextContainer>
                {item.curationStatus === CurationStatus.Approved
                  ? t("productInfo.publishedAndApproved")
                  : t("productInfo.inReview")}
              </StatusTextContainer>
            )}
          </StatusContainer>
        </ListItemTextContainer>
      </div>
      <div className="d-flex mt-1 justify-content-start ml-n1">
        {item.externalProduct ? (
          <OverlayTrigger
            placement="bottom-start"
            overlay={
              <Tooltip id="external-product-tooltip" className="icon-tooltip">
                {t("viewProduct.externalProductTooltip", {
                  externalSource: capitalize(item.externalProduct),
                })}
              </Tooltip>
            }
          >
            <div style={{ width: "22px", marginRight: "4.5rem", marginTop: "-2px" }}>
              <Icon name="externalProduct" />
            </div>
          </OverlayTrigger>
        ) : (
          <div style={{ width: "22px", marginRight: "4.5rem", marginTop: "-2px" }} />
        )}
        <ProductListItemLanguages languages={item.languages} />
      </div>
    </ListItemContainer>
  );
};

export default ProductListItem;
