import styled from "styled-components";

const PlainButton = styled.button`
  outline: none;
  border: 0;
  background: transparent;
  color: var(--color-gray-800);
  &:active,
  &:focus {
    outline: 0;
  }
  text-align: left;
  padding: 0;
  line-height: 0;
`;

export default PlainButton;
