import { gql } from "@apollo/client";

export const updateCommentMutation = gql`
  mutation updateComment($commentId: uuid!, $commentText: String!, $editedAt: timestamptz!) {
    updateProductCommentsByPk(
      pk_columns: { id: $commentId }
      _set: { comment: $commentText, editedAt: $editedAt }
    ) {
      id
      comment
      editedAt
    }
  }
`;

export interface UpdateProductComment {
  commentText: string;
  commentId: string;
  editedAt: string;
}

export interface UpdateCommentResult {
  updateProductCommentsByPk: { id: string; comment: string };
}
