import React, { ReactElement, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { TimePicker } from "../productForm/TimePicker";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fi from "date-fns/locale/fi";
import en from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import { useIsSmallScreen } from "../MediaQuery";

export const getMin = (start: Date) => (start ? moment(start).toDate() : new Date());

type MaintenanceDateTimeProps = {
  formLabel: string;
  groupName: string;
  dateFieldName: string;
  timeFieldName: string;
  setTime: (time: string | undefined) => void;
  timeHasErrors: boolean;
  dateDisabled?: boolean;
  checkbox?: ReactElement;
  editDate?: moment.Moment | string;
  editTime?: string;
  minDate?: Date;
};

export const MaintenanceDateTime = ({
  formLabel,
  groupName,
  dateFieldName,
  timeFieldName,
  setTime,
  timeHasErrors,
  dateDisabled = false,
  checkbox,
  editTime,
  editDate,
  minDate,
}: MaintenanceDateTimeProps) => {
  const { t, i18n } = useTranslation();
  const { setValue } = useFormContext();
  const isMobile = useIsSmallScreen();
  const isFinnish = i18n.language === "fi";

  useEffect(() => {
    if (dateDisabled) {
      setValue(dateFieldName, editDate || moment());
      setValue(timeFieldName, editTime || moment().format("HH:mm"));
    }
  }, [dateDisabled, dateFieldName, editDate, editTime, setValue, timeFieldName]);

  return (
    <Form.Group controlId={groupName}>
      <Form.Label>{formLabel}</Form.Label>
      <Row>
        <Col xs={8}>
          <Controller
            name={dateFieldName}
            rules={{
              required: true,
            }}
            defaultValue={
              editDate ? (typeof editDate === "string" ? moment(editDate) : editDate) : ""
            }
            render={({ value, onChange }) => {
              return (
                <DatePicker
                  showDisabledMonthNavigation
                  id={dateFieldName}
                  withPortal={isMobile}
                  locale={isFinnish ? fi : en}
                  placeholderText={t("datepicker.placeholder")}
                  dateFormat="d.M.yyyy"
                  minDate={minDate}
                  selected={moment(value).isValid() ? moment(value).toDate() : null}
                  onChange={(date) => onChange(moment(date))}
                  disabled={dateDisabled}
                />
              );
            }}
          />
        </Col>
        <Col className="pl-0" xs={4}>
          <Controller
            name={timeFieldName}
            rules={{
              required: true,
            }}
            defaultValue={editTime || ""}
            render={({ value: time }) => {
              if (!time && editTime) {
                setValue(timeFieldName, editTime);
              }
              return (
                <TimePicker
                  key={timeFieldName}
                  disabled={dateDisabled}
                  hasErrors={timeHasErrors}
                  initialTime={time || editTime}
                  onBlur={setTime}
                />
              );
            }}
          />
        </Col>
      </Row>
      {checkbox}
    </Form.Group>
  );
};
