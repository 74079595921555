import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import StepHeader from "./StepHeader";
import StepSection from "./StepSection";
import StepIndicator from "../../components/StepIndicator";
import { LoginCompanyAccount, UserRole } from "../../types";
import CitySelect from "../../components/CitySelect";
import { postalCodeValidator } from "../../utils/postalCodeValidation";
import { useApolloClient } from "@apollo/client";
import IconButton from "../../components/IconButton";
import SubmitSpinner from "../../components/SubmitSpinner";

type CreateCompanyAccountFormProps = {
  onBack: (values: LoginCompanyAccount) => void;
  onSubmit: (account: LoginCompanyAccount) => void;
  companyAccount?: LoginCompanyAccount;
  isCurator: boolean;
};

const InputSection = ({ children }: { children: React.ReactNode }) => (
  <section className="p-3 mt-2 bg-light">{children}</section>
);

const CreateCompanyAccountForm = ({
  onBack,
  onSubmit,
  companyAccount,
  isCurator,
}: CreateCompanyAccountFormProps) => {
  const { t } = useTranslation();

  const { register, handleSubmit, errors, watch, control, reset, formState } = useForm();

  const submitActivated = formState.isSubmitting || formState.isSubmitted;

  useEffect(() => {
    reset(companyAccount);
  }, [companyAccount, reset]);
  const apolloClient = useApolloClient();

  const validatePostalCode = async (postalCode: string) => {
    try {
      return await postalCodeValidator(apolloClient, postalCode, UserRole.CreateCompany);
    } catch (err) {
      return "validationErrors.postcodeNotValid";
    }
  };

  return (
    <Form className="d-flex align-items-center flex-column" onSubmit={handleSubmit(onSubmit)}>
      <StepHeader>
        {isCurator
          ? t("signup.createAccountIntroHeaderCurator")
          : t("signup.createAccountIntroHeaderCompany")}
      </StepHeader>
      <StepIndicator stepAmount={2} activeIndex={1} />
      <StepSection
        headerText={
          isCurator
            ? t("signup.createAccountFormHeaderCurator")
            : t("signup.createAccountFormHeader")
        }
        className="mb-4 w-100"
        childrenClassName="p-2"
      >
        <>
          <InputSection>
            <Form.Group controlId="companyName" className="m-0">
              <Form.Label>
                {isCurator
                  ? t("signup.organisationAccountNameLabel")
                  : t("signup.companyAccountNameLabel")}
                *
              </Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                ref={register({
                  required: "validationErrors.required",
                })}
                className={errors.companyName ? "is-invalid" : ""}
              />
              <p className="text-smaller color-gray-700 mt-2 mb-0">
                {t("signup.companyAccountNameDescription")}
              </p>
              {errors.companyName && <p className="text-danger">{t(errors.companyName.message)}</p>}
            </Form.Group>
          </InputSection>
          <h3 className="h4-size my-3">
            {isCurator ? t("signup.organisationContactDetails") : t("signup.companyContactDetails")}
          </h3>
          <InputSection>
            <Form.Group controlId="phone">
              <Form.Label>{t("companyInfo.phonenumber")}*</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                ref={register({
                  required: "validationErrors.required",
                })}
                className={errors.phone ? "is-invalid" : ""}
              />
              {errors.phone && <p className="text-danger">{t(errors.phone.message)}</p>}
            </Form.Group>
            <Form.Group controlId="email" className="m-0">
              <Form.Label>{t("companyInfo.email")}*</Form.Label>
              <Form.Control
                type="text"
                name="email"
                ref={register({
                  required: "validationErrors.required",
                  pattern: {
                    value: /[@]/,
                    message: "validationErrors.emailNotValid",
                  },
                })}
                className={errors.email ? "is-invalid" : ""}
              />
              {errors.email && <p className="text-danger">{t(errors.email.message)}</p>}
            </Form.Group>
          </InputSection>
          <h3 className="h4-size my-3">
            {isCurator
              ? t("signup.organisationLocationDetails")
              : t("signup.companyLocationDetails")}
          </h3>
          <InputSection>
            <Form.Group controlId="streetName">
              <Form.Label>{t("companyInfo.streetName")}*</Form.Label>
              <Form.Control
                type="text"
                name="streetName"
                ref={register({
                  required: "validationErrors.required",
                })}
                className={errors.streetName ? "is-invalid" : ""}
              />
              {errors.streetName && <p className="text-danger">{t(errors.streetName.message)}</p>}
            </Form.Group>
            <Form.Group controlId="postalCode">
              <Form.Label>{t("companyInfo.postalCode")}*</Form.Label>
              <Form.Control
                type="text"
                name="postalCode"
                ref={register({
                  validate: validatePostalCode,
                })}
                className={errors.postalCode ? "is-invalid" : ""}
              />
              {errors.postalCode && <p className="text-danger">{t(errors.postalCode.message)}</p>}
            </Form.Group>
            <Form.Group controlId="city" className="m-0">
              <Form.Label>{t("companyInfo.city")}*</Form.Label>
              <Form.Control
                type="text"
                name="city"
                ref={register({
                  required: "validationErrors.required",
                })}
                className={errors.city ? "is-invalid" : ""}
              />
              {errors.city && <p className="text-danger">{t(errors.city.message)}</p>}
            </Form.Group>
          </InputSection>
          {isCurator && (
            <div className="mb-5 pb-4">
              <h3 className="h4-size my-3">{t("signup.curatorRegionHeader")}</h3>
              <InputSection>
                <Form.Group controlId="cities">
                  <Form.Label>{t("signup.selectCuratedRegions")}</Form.Label>
                  <Controller
                    control={control}
                    name="cities"
                    defaultValue={[]}
                    render={({ onChange, value }) => {
                      return <CitySelect values={value} onChange={onChange} />;
                    }}
                  />
                  <p className="text-smaller color-gray-700 mt-2 mb-0">
                    {t("signup.dmoCuratedRegionsDescription")}
                  </p>
                </Form.Group>
              </InputSection>
            </div>
          )}
        </>
      </StepSection>
      <div className="d-flex w-100 justify-content-between mb-4">
        <Button variant="secondary" onClick={() => onBack(watch() as LoginCompanyAccount)}>
          {t("common.back")}
        </Button>
        <IconButton
          variant="primary"
          disabled={submitActivated}
          hideIcon={!submitActivated}
          iconElement={<SubmitSpinner />}
          type="submit"
        >
          {t("signup.saveAccountCta")}
        </IconButton>
      </div>
    </Form>
  );
};

export default CreateCompanyAccountForm;
