import { Form, FormControlProps } from "react-bootstrap";

import React from "react";
import { useTranslation } from "react-i18next";

type DataHubFormFieldProps = FormControlProps & {
  controlId: string;
  label: string;
  name: string;
  errorMessage?: string;
  isRequired?: boolean;
  infoText?: string;
};

const DataHubFormField = React.forwardRef((props: DataHubFormFieldProps, ref) => {
  const { t } = useTranslation();
  const {
    controlId,
    label,
    name,
    isRequired,
    errorMessage,
    infoText,
    ...onlyFormControlProps
  } = props;

  return (
    <Form.Group controlId={controlId}>
      <Form.Label>
        {label} {isRequired && "*"}
      </Form.Label>
      <Form.Control {...onlyFormControlProps} name={name} ref={ref} />
      {errorMessage && <p className="mt-1 text-danger">{t(errorMessage)}</p>}
      {infoText && <p className="ml-1 mt-1 text-small color-gray-700">{infoText}</p>}
    </Form.Group>
  );
});

export default DataHubFormField;
