import { Form } from "react-bootstrap";
import { SelectOption, SocialMediaPlatform } from "../../types";
import { DatahubSelect } from "../DatahubSelect";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getSocialMediaPlatformKey } from "../../utils/localizationUtils";
import { BasicModal } from "../DataHubModal";

type SocialMediaPickerModalProps = {
  socialMedias: SocialMediaPlatform[];
  onHide: () => void;
  onAddSocialMedia: (social: SocialMediaPlatform[]) => void;
};

const SocialMediaPickerModal = ({
  socialMedias,
  onHide,
  onAddSocialMedia,
}: SocialMediaPickerModalProps) => {
  const { t } = useTranslation();

  const [selectedLanguages, setSelectedLanguages] = useState<SocialMediaPlatform[]>([]);

  const options = socialMedias.map((social) => ({
    value: social,
    label: t(getSocialMediaPlatformKey(social)),
  }));

  return (
    <BasicModal
      proceedOnMouseDown
      header={t("socialMediaModal.header")}
      onCancel={onHide}
      onProceed={() => selectedLanguages && onAddSocialMedia(selectedLanguages)}
      proceedText={t("socialMediaModal.add")}
      cancelText={t("common.cancel")}
      disableProceed={selectedLanguages.length === 0}
    >
      <Form.Group controlId="productType">
        <Form.Label>{t("socialMediaModal.subHeader")}</Form.Label>
        <DatahubSelect
          isMulti
          isRelative
          closeMenuOnSelect={false}
          options={options}
          value={options.filter((l) => selectedLanguages.indexOf(l.value) !== -1)}
          onChange={(options) => {
            const selectOptions = (options as SelectOption[]) || [];
            const values = selectOptions.map((o) => o.value) as SocialMediaPlatform[];
            const selected = socialMedias.filter((l) => values.indexOf(l) !== -1);
            setSelectedLanguages(selected);
          }}
        />
      </Form.Group>
    </BasicModal>
  );
};

export default SocialMediaPickerModal;
