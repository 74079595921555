import { Col, Form, Row } from "react-bootstrap";
import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";

import PhoneInput from "../PhoneInput";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type ContactDetailsSectionProps = {
  isCurator: boolean;
};

const ContactDetailsSection = ({ isCurator }: ContactDetailsSectionProps) => {
  const { register, errors } = useFormContext();
  const { t } = useTranslation();

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader data-cy="company-contact-details">
          {t("companyInfo.contactDetailsHeader")}
        </FormSectionHeader>
        <p>
          {isCurator
            ? t("companyInfo.organisationContactDetailsDescription")
            : t("companyInfo.contactDetailsDescription")}
        </p>
      </FormDescription>
      <FormSectionContent>
        <Row>
          <Col xs="12" md="6">
            <Form.Group controlId="phonenumber">
              <Form.Label>{t("companyInfo.phonenumber")}*</Form.Label>
              <PhoneInput
                name="phonenumber"
                aria-describedby="phoneHelpBlock"
                rules={{
                  required: "validationErrors.required",
                }}
              />
              <Form.Text id="phoneHelpBlock" className="text-normal-size color-gray-600">
                {t("companyInfo.phonenumberHelp")}
              </Form.Text>
              {errors.phonenumber && <p className="text-danger">{t(errors.phonenumber.message)}</p>}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="email">
              <Form.Label>{t("companyInfo.email")}*</Form.Label>
              <Form.Control
                type="text"
                name="email"
                ref={register({
                  required: "validationErrors.required",
                  pattern: {
                    value: /[@]/,
                    message: "validationErrors.emailNotValid",
                  },
                })}
                className={errors.email ? "is-invalid" : ""}
              />
              {errors.email && <p className="text-danger">{t(errors.email.message)}</p>}
            </Form.Group>
          </Col>
        </Row>
      </FormSectionContent>
    </FormSection>
  );
};

export default ContactDetailsSection;
