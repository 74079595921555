import { Form } from "react-bootstrap";
import InputContainer from "./InputContainer";
import React from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const CapacityInputContainer = styled.div`
  max-width: 100px;
`;

const CapacitySeparator = styled.div`
  align-self: flex-end;
  padding: 0 1rem 0.6rem 1rem;
`;

type CapacityInputProps = {
  validator: (minField: boolean) => string | undefined;
  disabled?: boolean;
};

const CapacityInput = ({ validator, disabled }: CapacityInputProps) => {
  const { t } = useTranslation();
  const { register, errors } = useFormContext();

  return (
    <InputContainer hasError={!!errors.capacityMin || !!errors.capacityMax}>
      <div className="d-flex align-items-end my-3">
        <CapacityInputContainer>
          <Form.Label>{t("productInfo.capacityLabelCapacityMin")}</Form.Label>
          <Form.Control
            name="capacityMin"
            type="text"
            className={errors.capacityMin ? "is-invalid-border" : ""}
            ref={register({
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: "validationErrors.invalidCapacity",
              },
              validate: () => validator(true),
            })}
            disabled={disabled}
          />
        </CapacityInputContainer>
        <CapacitySeparator>&ndash;</CapacitySeparator>
        <CapacityInputContainer>
          <Form.Label>{t("productInfo.capacityLabelCapacityMax")}</Form.Label>
          <Form.Control
            name="capacityMax"
            type="text"
            className={errors.capacityMax ? "is-invalid-border" : ""}
            ref={register({
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: "validationErrors.invalidCapacity",
              },
              validate: () => validator(false),
            })}
            disabled={disabled}
          />
        </CapacityInputContainer>
      </div>
    </InputContainer>
  );
};

export default CapacityInput;
