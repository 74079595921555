import React from "react";
import { useTranslation } from "react-i18next";

const EmptyProductItem = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white w-100 text-center p-4 mx-3">
      <h4>{t("products.emptyProductItemHeader")}</h4>
    </div>
  );
};

export default EmptyProductItem;
