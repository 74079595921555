import {
  EditProductInformation,
  ProductGroupImage,
  ProductGroupProductInformation,
} from "../../graphqlQueries/productGroup/updateProductGroup";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import {
  AlertProps,
  ProductGroupData,
  ProductGroupPermission,
  ProductGroupProductInput,
  ProductGroupRole,
  RoleType,
} from "../../types";
import React, { useEffect, useState } from "react";

import AddParticipantsToGroupSection from "./AddParticipantsToGroupSection";
import AddProductsToGroup from "./AddProductsToGroup";
import GroupFormToolbar from "./GroupFormToolbar";
import GroupNameAndDescriptionSection from "./GroupNameAndDescriptionSection";
import { HasuradbDeletedProduct, HasuradbProduct } from "../../graphqlQueries/getProduct";
import LockedGroupNotification from "./LockedGroupNotification";
import { Prompt } from "react-router-dom";
import { getGroupStatus } from "../../utils/productGroupUtils";
import { useTranslation } from "react-i18next";
import Notification from "../Notification";

const DISPLAY_ALERT_TIME = 5000;

const changesInProducts = (
  formData: (HasuradbProduct | HasuradbDeletedProduct)[],
  dbData?: ProductGroupData
) => {
  const dbProductIds = dbData
    ? [...dbData.products, ...(dbData.deletedProducts ?? [])]
        .map((p) => p.product.id)
        .sort()
        .join("") ?? ""
    : "";
  const formProductIds = formData
    .map((p) => p.id)
    .sort()
    .join("");

  return dbProductIds !== formProductIds;
};

export const company = ({ permission }: { permission: ProductGroupPermission }) => ({
  id: permission.id,
  label: permission.company?.businessName || "",
  value: permission.company?.id || "",
  role: permission.role,
});

export const user = ({ permission }: { permission: ProductGroupPermission }) => ({
  id: permission.id,
  label: permission?.user?.name || "",
  value: permission.user?.id || "",
  role: permission.role,
});

export type EditedProductGroupProduct = HasuradbProduct & {
  productGroupProductImages?: ProductGroupImage[];
  productGroupProductInformations?: EditProductInformation[];
};

export type ProductGroupParticipantFormValue = {
  id: string;
  label: string;
  value: string;
  role: ProductGroupRole;
  type?: string;
};

export type ProductGroupFormValues = {
  groupName: string;
  groupInstructions: string;
  organizationsFromDb?: ProductGroupParticipantFormValue[];
  usersFromDb?: ProductGroupParticipantFormValue[];
  emailsToInvite?: { label: string; value: string }[];
  organizationsToInvite?: { label: string; value: string; id?: string }[];
  rolesToChange?: Record<RoleType, Record<string, { label: string; value: string } | undefined>>;
};

const changesInParticipants = (
  usersFromDb: ProductGroupParticipantFormValue[],
  organizationsFromDb: ProductGroupParticipantFormValue[],
  currentUsers: ProductGroupParticipantFormValue[],
  currentOrganizations: ProductGroupParticipantFormValue[]
) => {
  return (
    [...organizationsFromDb, ...usersFromDb]
      .map((participant) => participant.value)
      .sort()
      .join("") !==
    [...currentUsers, ...currentOrganizations]
      .map((participant) => participant.value)
      .sort()
      .join("")
  );
};

type GroupFormProps = {
  rootPagePath: string;
  onSubmit: (
    formValues: ProductGroupFormValues & {
      currentOrganizations: ProductGroupParticipantFormValue[];
      currentUsers: ProductGroupParticipantFormValue[];
    },
    products: ProductGroupProductInput[],
    editedProductDetails?: ProductGroupProductInformation[],
    editedProductImages?: ProductGroupImage[]
  ) => Promise<void>;
  onRemove?: () => Promise<void>;
  groupData?: ProductGroupData;
  onLock?: () => Promise<void>;
  onUnLock?: () => Promise<void>;
  organizationsFromDb?: ProductGroupParticipantFormValue[];
  usersFromDb?: ProductGroupParticipantFormValue[];
  emailsToInvite?: ProductGroupParticipantFormValue[];
  organizationsToInvite?: ProductGroupParticipantFormValue[];
  productGroupRole: ProductGroupRole | null;
  productsFromDb?: { product: HasuradbProduct }[];
  alert?: AlertProps;
  closeAlert?: () => void;
};

const GroupForm: React.FunctionComponent<GroupFormProps> = ({
  onSubmit,
  onRemove,
  groupData,
  rootPagePath,
  onLock,
  onUnLock,
  organizationsFromDb = [],
  usersFromDb = [],
  productGroupRole,
  productsFromDb,
  alert,
  closeAlert,
}) => {
  const { id: groupId, name: groupName, publishingId } = groupData || {
    id: "",
    name: "",
    publishingId: "",
  };

  const [currentUsers, setCurrentUsers] = useState<ProductGroupParticipantFormValue[]>(usersFromDb);
  const [currentOrganizations, setCurrentOrganizations] = useState<
    ProductGroupParticipantFormValue[]
  >(organizationsFromDb);
  const [shouldAllowPrompt, setShouldAllowPrompt] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isEditingProductId, setIsEditingProductId] = useState<string | undefined>();
  const [editedProductsData, setEditedProductsData] = useState<EditedProductGroupProduct[]>([]);
  const status = (groupData && getGroupStatus(groupData)) || undefined;

  const isLocked = !!(publishingId && status === "published");
  const isUnlocked = !!(publishingId && status === "edit");

  const { t } = useTranslation();

  const [products, setProducts] = useState<HasuradbProduct[]>([]);
  const [deletedProducts, setDeletedProducts] = useState<HasuradbDeletedProduct[]>([]);
  const hookFormMethods = useForm<ProductGroupFormValues>();
  const { handleSubmit, reset, formState } = hookFormMethods;

  useEffect(() => {
    if (alert && closeAlert) {
      const notification = document.getElementsByClassName("focusable-alert")[0];
      notification.scrollIntoView({ block: "center" });
      const alertTimeout = setTimeout(() => {
        closeAlert();
      }, DISPLAY_ALERT_TIME);
      return () => clearTimeout(alertTimeout);
    }
  }, [alert, closeAlert]);

  useEffect(() => {
    if (groupData) {
      const organizations = groupData.permissions
        .filter((permission) => permission.company)
        .map((permission) => company({ permission }));
      const users = groupData.permissions
        .filter((permission) => permission.user)
        .map((permission) => user({ permission }));
      const products = groupData.products.map((product) => ({
        ...product.product,
        companyId: product.product.company.id,
      }));
      const formData: ProductGroupFormValues = {
        groupName: groupData.name,
        groupInstructions: groupData.description,
        organizationsFromDb: organizations,
        usersFromDb: users,
        emailsToInvite: [],
        organizationsToInvite: [],
      };
      reset(formData);
      setProducts(products);
      setDeletedProducts(groupData.deletedProducts?.map((p) => p.product) ?? []);
      setEditedProductsData([]);
      setCurrentOrganizations(organizations);
      setCurrentUsers(users);
    }
  }, [groupData, reset]);

  const isEditing = !!groupData;

  const isAdmin = productGroupRole === ProductGroupRole.Admin;
  const canManage = isAdmin || productGroupRole === ProductGroupRole.Manager;
  const canEditProducts = canManage || productGroupRole === ProductGroupRole.Editor;

  const noChangesInState =
    !changesInProducts([...products, ...deletedProducts], groupData) &&
    Object.keys(formState.dirtyFields).length === 0 &&
    !changesInParticipants(usersFromDb, organizationsFromDb, currentUsers, currentOrganizations) &&
    (!editedProductsData || editedProductsData.length === 0);

  const enableLocking = (groupData?.products.length ?? 0) > 0 && noChangesInState && canManage;

  const showPrompt = shouldAllowPrompt && !noChangesInState && !modalOpen;

  const handleProductEditing = (editedProduct: HasuradbProduct) => {
    setEditedProductsData([
      ...editedProductsData.filter((product) => product.id !== editedProduct.id),
      editedProduct,
    ]);
  };

  const getEditedProductInformation = () => {
    if (editedProductsData) {
      const mapProductId = editedProductsData.map((p) => {
        const infos = p?.productGroupProductInformations?.map(
          (it) =>
            ({
              ...it,
              productId: p.id,
            } as ProductGroupProductInformation)
        );
        return infos ? [...infos] : [];
      });
      return mapProductId.flat();
    }

    return undefined;
  };

  const getEditedProductImages = () => {
    if (editedProductsData) {
      const mapProductId = editedProductsData.map((p) => {
        return [
          ...(p?.productGroupProductImages
            ? p.productGroupProductImages.map((it) => ({
                ...it,
                id: it?.id ?? undefined,
                productId: p.id,
                productGroupId: it?.productGroupId ?? "",
              }))
            : []),
        ];
      });

      return mapProductId.flat();
    }

    return undefined;
  };

  return (
    <FormProvider {...hookFormMethods}>
      <Form
        onSubmit={handleSubmit(({ organizationsToInvite, ...formValues }) => {
          setIsSaving(true);
          if (alert && closeAlert) {
            closeAlert();
          }
          return onSubmit(
            {
              ...formValues,
              currentOrganizations,
              currentUsers,
              usersFromDb,
              organizationsFromDb,
              organizationsToInvite: organizationsToInvite?.filter(
                (o) => !organizationsFromDb?.find((db) => db.value === o.value)
              ),
            },
            [...products, ...deletedProducts].map((p) => ({
              productId: p.id,
              productGroupId: groupId,
            })),
            getEditedProductInformation(),
            getEditedProductImages()
          ).then(() => setIsSaving(false));
        })}
      >
        <div className="mb-4">
          {isLocked && <LockedGroupNotification isLocked={true} publishingId={publishingId} />}
          {isUnlocked && <LockedGroupNotification isLocked={false} publishingId={publishingId} />}
        </div>
        <div className="mx-4">
          <div className="pl-4">
            {alert?.text && (
              <Notification className={"focusable-alert"} type={alert.type}>
                {alert.type === "danger" && `${t("common.error")}: `}
                {alert.text}
              </Notification>
            )}
            <h1>
              {!isEditing
                ? t("groupedProducts.addNewGroup")
                : `${t("groupedProducts.groupTitle")}: ${groupName}`}
            </h1>
            <div>
              {
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id="Tooltip" className="icon-tooltip">
                      {t(`groupedProducts.roleTagTooltip.${productGroupRole}`)}
                    </Tooltip>
                  }
                >
                  <div className="font-weight-bold bg-gray-300 d-inline p-1 text-small cursor-pointer">
                    {productGroupRole?.replace(/^./, productGroupRole[0].toUpperCase())}
                  </div>
                </OverlayTrigger>
              }
            </div>
          </div>
          <div className="px-sm-5 pt-5">
            <GroupNameAndDescriptionSection isLocked={isLocked || (!!groupId && !canManage)} />
            <AddProductsToGroup
              canManage={canManage}
              rootPagePath={rootPagePath}
              isLocked={isLocked || !canEditProducts}
              products={products}
              setProducts={setProducts}
              deletedProducts={deletedProducts}
              setDeletedProducts={setDeletedProducts}
              groupId={groupId}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              setIsEditingProductId={setIsEditingProductId}
              isEditingProductId={isEditingProductId}
              productsFromDb={productsFromDb}
              handleProductEditing={handleProductEditing}
              editedProductsData={editedProductsData}
            />
            <AddParticipantsToGroupSection
              isLocked={isLocked}
              currentOrganizations={currentOrganizations}
              currentUsers={currentUsers}
              setCurrentOrganizations={setCurrentOrganizations}
              setCurrentUsers={setCurrentUsers}
              currentRole={productGroupRole}
            />
          </div>
        </div>
        <GroupFormToolbar
          rootPagePath={rootPagePath}
          isEditing={isEditing}
          onRemove={onRemove}
          hasUnSavedChanges={!noChangesInState}
          shouldEnableLockingControls={enableLocking}
          isLocked={isLocked}
          onUnLock={onUnLock}
          onLock={onLock}
          shouldDisableSave={noChangesInState || isSaving}
          canRemove={isAdmin}
          setShouldAllowPrompt={(value) => setShouldAllowPrompt(value)}
        />
      </Form>
      <Prompt when={showPrompt} message={t("productInfo.unsavedChanges")} />
    </FormProvider>
  );
};

export default GroupForm;
