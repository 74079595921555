import { gql } from "@apollo/client";

export const getLogos = gql`
  query GetLogos($locale: [Locale!]!) {
    channelLogos(locales: $locale) {
      id
      rowNumber
      logoRow {
        ... on Logo {
          id
          name
          url
          width
          default: logo(locales: en) {
            url
          }
          logo(locales: $locale) {
            url
          }
        }
      }
    }
  }
`;
