import {
  ApolloClient,
  ApolloProvider,
  concat,
  HttpLink,
  NormalizedCacheObject,
} from "@apollo/client";
import React, { useEffect, useState } from "react";

import Loading from "../components/Loading";
import { cache } from "./ApolloCache";
import { getGraphCMSEndpoint } from "./functions";
import { setContext } from "@apollo/client/link/context";

const cms = new HttpLink({
  uri: getGraphCMSEndpoint(),
});

const ApolloServiceProvider = ({
  children,
  isPreview,
}: {
  children: React.ReactNode;
  isPreview: boolean;
}) => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(
    null
  );

  useEffect(() => {
    const authMiddleware = setContext(async (_, { headers }) => {
      // add the authorization to the headers
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${process.env.REACT_APP_GRAPHCMS_PREVIEW_TOKEN}`,
        },
      };
    });

    const client = new ApolloClient({
      link: isPreview ? concat(authMiddleware, cms) : cms,
      cache,
    });

    setApolloClient(client);
  }, [isPreview]);

  if (!apolloClient) {
    return <Loading />;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloServiceProvider;
