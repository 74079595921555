import { gql } from "@apollo/client";

export const updateProductGroupPermissionByPkMutation = gql`
  mutation UpdateProductGroupPermissionByPk($id: uuid!, $role: String!, $groupId: uuid!) {
    UpdateProductGroupPermissionByPk(id: $id, role: $role, groupId: $groupId) {
      id
    }
  }
`;

export type UpdateProductGroupPermissionByPkResult = {
  UpdateProductGroupPermissionByPk: {
    id?: string;
  };
};

export type UpdateProductGroupPermissionByPkVariables = {
  id: string;
  role: string;
  groupId: string;
};
