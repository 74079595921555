import { useMutation } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import React, { useState } from "react";

import insertCompany, {
  InsertCompanyResult,
  InsertCompanyVariables,
} from "../../graphqlQueries/companyInsert";
import { getJoinRequestsForUser } from "../../graphqlQueries/getJoinRequests";
import processJoinRequestQuery, {
  ProcessJoinRequestResult,
  ProcessJoinRequestVariables,
} from "../../graphqlQueries/processJoinRequest";
import {
  CompanyInformation,
  DatahubType,
  JoinRequestStatus,
  LoginCompanyAccount,
  RequestedGroup,
  UserRole,
} from "../../types";
import { userInfoVar } from "../../utils/ApolloCache";
import { getHasuraRoleContext } from "../../utils/functions";
import CreateAccountError from "./CreateAccountError";
import CreateAccountIntro from "./CreateAccountIntro";
import CreateAccountSaveConfirmation from "./CreateAccountSaveConfirmation";
import CreateCompanyAccountForm from "./CreateCompanyAccountForm";

export type WizardStep =
  | "CreateAccountIntro"
  | "CreateAccountForm"
  | "SaveConfirmation"
  | "SaveError"
  | "JoinError";

type CreateAccountWizardProps = {
  companyInformation?: CompanyInformation;
  backButtonOnClick?: () => void;
};

const CreateAccountWizard = ({
  companyInformation,
  backButtonOnClick,
}: CreateAccountWizardProps) => {
  const [step, setStep] = useState<WizardStep>("CreateAccountIntro");
  const [companyAccount, setCompanyAccount] = useState<LoginCompanyAccount>();

  const userInfo = userInfoVar();

  const isCurator = userInfo?.requestedGroup === RequestedGroup.DMO;

  const [insertCompanyInfo] = useMutation<InsertCompanyResult, InsertCompanyVariables>(
    insertCompany
  );

  const [processJoinRequest] = useMutation<ProcessJoinRequestResult, ProcessJoinRequestVariables>(
    processJoinRequestQuery
  );

  const { keycloak } = useKeycloak();

  const saveAccount = (account: LoginCompanyAccount) => {
    insertCompanyInfo({
      variables: {
        businessName: account.companyName,
        email: account.email,
        phone: account.phone,
        streetName: account.streetName,
        city: account.city,
        postalCode: account.postalCode,
        description: "",
        websiteUrl: "",
        dmoAreas: isCurator
          ? account.cities?.map((city) => ({
              curationAreaId: city.value,
            })) ?? []
          : [],
        datahubType: isCurator ? DatahubType.DMO : DatahubType.TC,
      },
      context: getHasuraRoleContext(UserRole.CreateCompany),
      refetchQueries: [getJoinRequestsForUser],
    })
      .then((result) => {
        if (!result.data) {
          return Promise.reject(new Error("No data from InsertCompany"));
        }
        const newCompanyId = result.data.InsertCompany.id;

        if (isCurator) {
          setStep("SaveConfirmation");
        } else {
          const joinRequestId = result.data.InsertCompany.joinRequestId;
          processJoinRequest({
            variables: { id: joinRequestId, approveRequest: true },
            context: getHasuraRoleContext(UserRole.CreateCompany),
          })
            .then((res) => {
              if (res.data?.ProcessJoinRequest.joinRequest.status === JoinRequestStatus.Approved) {
                keycloak?.login({
                  redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/company/${newCompanyId}`,
                });
              } else {
                setStep("SaveConfirmation");
              }
            })
            .catch((err) => {
              console.log(err);
              setStep("JoinError");
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setStep("SaveError");
      });
  };

  if (!companyInformation) {
    return null;
  }

  return (
    <div>
      {step === "CreateAccountIntro" && companyInformation && (
        <CreateAccountIntro
          onNext={() => {
            setStep("CreateAccountForm");
          }}
          companyInformation={companyInformation}
          isCurator={isCurator}
          backButtonOnClick={backButtonOnClick}
        />
      )}
      {step === "CreateAccountForm" && (
        <CreateCompanyAccountForm
          onBack={(account) => {
            setCompanyAccount(account);
            setStep("CreateAccountIntro");
          }}
          onSubmit={(account) => {
            setCompanyAccount(account);
            saveAccount(account);
          }}
          companyAccount={companyAccount}
          isCurator={isCurator}
        />
      )}
      {step === "SaveConfirmation" && <CreateAccountSaveConfirmation isCurator={isCurator} />}
      {step === "SaveError" && (
        <CreateAccountError
          headerKey="signup.createAccountErrorHeader"
          descriptionKey="signup.createAccountErrorDesc"
        />
      )}
      {step === "JoinError" && (
        <CreateAccountError
          headerKey="signup.joinCompanyErrorHeader"
          descriptionKey="signup.joinCompanyErrorDesc"
        />
      )}
    </div>
  );
};

export default CreateAccountWizard;
