import { ArrayField, useFormContext } from "react-hook-form";
import { Language, ProductDetails } from "../../../types";

import { Button } from "react-bootstrap";
import { DataHubPopOver } from "../../DataHubPopOver";
import DataHubTabs from "../../DataHubTabs";
import Icon from "../../Icon";
import { MAX_FULL_LENGTH_LANGUAGES } from "./ProductDescriptionSection";
import React from "react";
import { capitalize } from "../../../utils/functions";
import { useTranslation } from "react-i18next";

type ProductDescriptionLanguageTabsProps = {
  activeLanguage: Language | null;
  setActiveLanguage: (val: Language) => void;
  setShowLanguageModal?: (val: boolean) => void;
  setShowLanguageDeletionPopover?: (val: number | null) => void;
  showLanguageDeletionPopover?: number | null;
  fields: Partial<ArrayField<ProductDetails>>[];
  onLanguageRemoval?: (index: number) => void;
  isEditing?: boolean;
  isProductGroup?: boolean;
};

const ProductDescriptionLanguageTabs: React.FunctionComponent<ProductDescriptionLanguageTabsProps> = ({
  activeLanguage,
  fields,
  setShowLanguageDeletionPopover,
  showLanguageDeletionPopover,
  setShowLanguageModal,
  setActiveLanguage,
  onLanguageRemoval,
  isEditing,
  isProductGroup,
}) => {
  const { t } = useTranslation();
  const errors = useFormContext()?.errors;

  return (
    <div className="d-flex justify-end">
      {activeLanguage && (
        <DataHubTabs
          onSelect={(val) => {
            if (val === activeLanguage) {
              return;
            }
            setActiveLanguage(val as Language);
            return;
          }}
          tabs={fields.map((field, i) => ({
            label:
              fields.length > MAX_FULL_LENGTH_LANGUAGES
                ? capitalize(t(`languages.short.${field.language}`))
                : capitalize(t(`languages.${field.language}`)),
            value: field.language as string,
            hasError: errors?.details?.[i],
            controls:
              isEditing && fields.length > 1 ? (
                <>
                  <DataHubPopOver
                    show={i === showLanguageDeletionPopover}
                    trigger="click"
                    placement="bottom"
                    icon="warning-triangle"
                    confirmText={t("productInfo.deleteLanguagePopoverButtonText")}
                    cancelText={t("common.cancel")}
                    header={t("productInfo.deleteLanguagePopoverHeader")}
                    onCancel={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e?.stopPropagation();
                      if (setShowLanguageDeletionPopover) {
                        setShowLanguageDeletionPopover(null);
                      }
                    }}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e?.stopPropagation();
                      if (onLanguageRemoval) {
                        onLanguageRemoval(i);
                      }
                    }}
                    variant="confirmAndCancel"
                    triggerButton={
                      <Button
                        variant="text"
                        className="p-0 d-flex"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (setShowLanguageDeletionPopover) {
                            setShowLanguageDeletionPopover(i);
                          }
                        }}
                      >
                        <Icon size="small" color="gray-900" name="times" className="ml-2" />
                      </Button>
                    }
                  >
                    <div> {t("productInfo.deleteLanguagePopoverDescription")}</div>
                  </DataHubPopOver>
                </>
              ) : undefined,
          }))}
          activeTab={activeLanguage}
        />
      )}
      {isEditing && !isProductGroup && (
        <Button
          className="mt-1 ml-2"
          variant="ghost"
          onClick={() => setShowLanguageModal && setShowLanguageModal(true)}
        >
          {t("productInfo.addNewLanguage")}
        </Button>
      )}
    </div>
  );
};

export default ProductDescriptionLanguageTabs;
