import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import BackNavigationSideBar from "../components/BackNavigationSideBar";
import Comments from "../components/comments/Comments";
import { companyProductStatuses } from "../utils/filterOptions";
import Filters from "../components/curatorWorkspace/Filters";
import useProductFilters, { initialProductFilters } from "../components/hooks/useProductFilters";
import { useIsLargeScreen, useIsMediumOrSmaller } from "../components/MediaQuery";
import MobileNavigationHeader from "../components/MobileNavigationHeader";
import Navigation from "../components/navigation/Navigation";
import MyProductForm from "../components/productForm/MyProductForm";
import ProductList from "../components/products/ProductList";
import ViewProduct from "../components/products/ViewProduct";
import ViewProductCompanyToolbar from "../components/products/ViewProductCompanyToolbar";
import ProductTypeModal from "../components/ProductTypeModal";
import buildGetCompanyProductsQuery, {
  ProductQueryResult,
} from "../graphqlQueries/getCompanyProducts";
import {
  CurationStatusesFilter,
  FilterMode,
  FilterType,
  ProductType,
  DropdownOption,
  UserRole,
  ProductFilters,
} from "../types";
import { ResultsByCompanyFilter } from "../types/curatorProductTypes";
import {
  FILTER_KEY,
  STATUS_KEY,
  getQueryFilterVariables,
  MY_PRODUCT_FILTERS,
  sessionStorageInitialFilters,
} from "../utils/filterUtils";
import Page from "./Page";
import { DEFAULT_PAGE_SIZE_OPTIONS } from "../utils/pagingUtils";
import { useQuery } from "@apollo/client";
import { getHasuraRoleContext } from "../utils/functions";
import { Paging } from "../components/Paging";
import { composeResultCount } from "../utils/productFormUtils/productUtils";

const ViewProductContainer = styled.div`
  flex-grow: 1;
  background: var(--color-white);
  height: 100%;
`;

const ProductViewer = styled.div`
  display: flex;
  height: 100%;
`;

type ProductPageProps = {
  companyId: string;
};

const Products = ({ companyId }: ProductPageProps) => {
  const history = useHistory();
  const location = useLocation<{ newProduct?: boolean }>();
  const { path } = useRouteMatch();
  const onNewProductClick = (type: ProductType) => {
    history.push(`${path}/create`, { newProductType: type });
  };
  const [showProductTypeModal, setShowProductTypeModal] = useState<boolean>(
    !!location.state?.newProduct
  );

  const viewParams = useRouteMatch<{ id: string }>(`${path}/:id`)?.params;
  const editParams = useRouteMatch<{ id: string }>(`${path}/edit/:id`)?.params;

  const isMedium = useIsMediumOrSmaller();
  const isLarge = useIsLargeScreen();
  const showMobileNavigation = !!((viewParams?.id && isMedium) || (editParams?.id && isLarge));
  const [resultsByFilter, setResultsByFilter] = useState<ResultsByCompanyFilter>();
  const [showNotification, setShowNotification] = useState<string>("");
  const onNotificationClose = useCallback(() => setShowNotification(""), []);

  const showProductList = !isMedium || (isLarge && !viewParams?.id);

  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);

  const sessionFilters: ProductFilters = sessionStorageInitialFilters(MY_PRODUCT_FILTERS);

  const initialFiltersCompanyProducts = {
    ...sessionFilters,
    curationStatus:
      (searchParams.get(STATUS_KEY) as CurationStatusesFilter) ||
      sessionFilters?.curationStatus ||
      CurationStatusesFilter.AllCompanyProducts,

    productNotification: searchParams.get(FILTER_KEY) || sessionFilters?.productNotification,
  };

  const { filters, setFilters } = useProductFilters(initialFiltersCompanyProducts);

  useEffect(() => {
    sessionStorage.setItem(MY_PRODUCT_FILTERS, JSON.stringify(filters));
  }, [filters]);

  const [getProductsQuery, getProductsFilters] = useMemo(() => {
    const queryVariables = getQueryFilterVariables(filters);
    const query = buildGetCompanyProductsQuery(queryVariables, companyId);
    return [query, queryVariables];
  }, [filters, companyId]);

  const isEditRoute = !!useRouteMatch<{ id: string }>(`${path}/edit/:id`)?.params;

  const [page, setPage] = useState(0);
  const [selectedAmountOption, setSelectedAmountOption] = useState<DropdownOption>(
    // get the last value of page size options
    DEFAULT_PAGE_SIZE_OPTIONS.slice(-1)[0]
  );
  const listElement = useRef<HTMLDivElement>(null);
  const resetScroll = () => listElement?.current?.scrollTo(0, 0);
  const limit = selectedAmountOption.value;

  const {
    data: productsData,
    error: productsError,
    previousData: productsPreviousData,
    loading: productsLoading,
  } = useQuery<ProductQueryResult>(getProductsQuery, {
    variables: {
      ...getProductsFilters,
      companyId,
      limit,
      offset: page * limit,
    },
    context: getHasuraRoleContext(UserRole.ManageProducts),
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    history.push({
      pathname: pathname,
      search: `${STATUS_KEY}=${filters.curationStatus}`,
    });
  }, [history, pathname, filters]);

  useEffect(() => {
    resetScroll();
  }, [filters]);

  useEffect(() => {
    if (productsData && setResultsByFilter) {
      setResultsByFilter(composeResultCount(productsData));
    }
  }, [productsData, setResultsByFilter]);

  const productsLength = productsData?.product?.length ?? 0;

  return (
    <Page
      header={showMobileNavigation ? <MobileNavigationHeader backUrl={path} /> : null}
      sideBar={
        isEditRoute ? (
          <BackNavigationSideBar backPath={pathname.replace("edit/", "")} />
        ) : (
          <Navigation
            filterComponent={
              <Filters
                initialFilters={{
                  ...initialProductFilters,
                  cities: undefined,
                  productCategories: undefined,
                }}
                filterType={FilterType.DesktopFilters}
                filters={filters}
                onFilterChange={(newFilters) => {
                  setFilters(newFilters);
                  setPage(0);
                }}
                filterMode={FilterMode.CompanyProducts}
                statusFilterOptions={companyProductStatuses}
                resultsByFilter={resultsByFilter}
              />
            }
          />
        )
      }
    >
      <div className="h-100 bg-light">
        <Switch>
          <Route path={`${path}/create`}>
            <MyProductForm companyId={companyId} refetchQuery={getProductsQuery} />
          </Route>
          <Route path={`${path}/edit/:productId`}>
            <MyProductForm
              companyId={companyId}
              refetchQuery={getProductsQuery}
              setShowNotification={setShowNotification}
            />
          </Route>
          <ViewProductContainer>
            <ProductViewer>
              <Route path={`${path}`}>
                {showProductList && (
                  <ProductList
                    ref={listElement}
                    selectedProductId={viewParams?.id}
                    onNewProductClick={() => {
                      setShowProductTypeModal(true);
                    }}
                    productsData={productsData}
                    productsError={productsError}
                    productsPreviousData={productsPreviousData}
                    paging={
                      <Paging
                        productsLength={productsLength}
                        totalProductCount={productsData?.productAggregate.aggregate.count ?? 0}
                        productsLoading={productsLoading}
                        page={page}
                        setPage={setPage}
                        selectedAmountOption={selectedAmountOption}
                        setSelectedAmountOption={setSelectedAmountOption}
                      />
                    }
                  />
                )}
                <Route path={`${path}/:productId`}>
                  <ViewProduct
                    viewMode="company"
                    showNotification={showNotification}
                    onNotificationClose={onNotificationClose}
                    renderComments={({ hide, ...args }) => {
                      return !hide && <Comments {...args} />;
                    }}
                    renderToolbar={(args) => {
                      return <ViewProductCompanyToolbar {...args} />;
                    }}
                  />
                </Route>
              </Route>
            </ProductViewer>
          </ViewProductContainer>
        </Switch>
        <ProductTypeModal
          show={showProductTypeModal}
          onHide={() => {
            setShowProductTypeModal(false);
          }}
          onSelect={(type) => {
            onNewProductClick(type);
            setShowProductTypeModal(false);
          }}
        />
      </div>
    </Page>
  );
};

export default Products;
