import Icon from "./Icon";
import { Language } from "../types";
import React from "react";
import styled from "styled-components";
import { customLanguageSort } from "../utils/localizationUtils";

type ProductListItemLanguagesProps = {
  languages: Language[];
};

const LanguageList = styled.ul`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 28px;
`;

const LanguageListItem = styled.li`
  list-style: none;
  display: inline;
  :after {
    content: ", ";
  }
  :last-of-type:after {
    content: " ";
  }
`;

const Container = styled.div`
  margin-bottom: -6px;
`;

/*
  Flexbox truncated text container needs to have min-width to
  work. See https://css-tricks.com/flexbox-truncated-text/
*/
const LanguagesContainer = styled.div`
  min-width: 0;
`;

const ProductListItemLanguages = ({ languages }: ProductListItemLanguagesProps) => {
  const sortedLanguages = customLanguageSort(languages);
  return (
    <LanguagesContainer>
      <Container className="d-flex text-muted bg-transparent p-0">
        <div>
          <Icon name="language" size="medium" />
        </div>
        <LanguageList className="m-0 ml-1 p-0">
          {sortedLanguages.map((language) => (
            <LanguageListItem key={language} className="text-capitalize">
              {language}
            </LanguageListItem>
          ))}
        </LanguageList>
      </Container>
    </LanguagesContainer>
  );
};

export default ProductListItemLanguages;
