import {
  BusinessHours as BusinessHoursType,
  BusinessHoursFormValues,
  MyProductFormValues,
  ProductPublishMode,
  UserRole,
} from "../../types";
import React, { useEffect, useState } from "react";
import getBusinessHoursQuery, {
  GetBusinessHoursResult,
  GetBusinessHoursVariables,
} from "../../graphqlQueries/getBusinessHours";
import {
  mapOpeningHoursExceptionsToFormValues,
  mapOpeningHoursToWeek,
} from "../../utils/mapDbProductToFormValues";

import Checkbox from "../Checkbox";
import { Form } from "react-bootstrap";
import Notification from "../Notification";
import { OpeningHoursContainer } from "./OpeningHoursContainer";
import { OpeningHoursExceptionsContainer } from "./OpeningHoursExceptionsContainer";
import Separator from "../Separator";
import { getHasuraRoleContext } from "../../utils/functions";
import { useApolloClient } from "@apollo/client";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type BusinessHoursProps = {
  publishMode: ProductPublishMode;
  disabled?: boolean;
  isCurator?: boolean;
  productCompanyBusinessHours?: BusinessHoursType | null;
};

export const BusinessHours = ({
  publishMode,
  disabled,
  isCurator,
  productCompanyBusinessHours,
}: BusinessHoursProps) => {
  const { reset, setValue, getValues, register, watch } = useFormContext<MyProductFormValues>();
  const { t } = useTranslation();

  const businessHoursId = watch("businessHoursId", "");
  const companyBusinessHoursId = watch("companyBusinessHoursId", "");
  const companyHoursEnabled = watch("companyHoursEnabled", false);

  const apollo = useApolloClient();
  const [companyBusinessHours, setCompanyBusinessHours] = useState<
    BusinessHoursFormValues | undefined
  >();
  const [productBusinessHours, setProductBusinessHours] = useState<
    BusinessHoursFormValues | undefined
  >();

  useEffect(() => {
    if (companyBusinessHoursId) {
      apollo
        .query<GetBusinessHoursResult, GetBusinessHoursVariables>({
          query: getBusinessHoursQuery,
          variables: { businessHoursId: companyBusinessHoursId },
          context: getHasuraRoleContext(
            isCurator ? UserRole.ManageCuration : UserRole.ManageProducts
          ),
        })
        .then((result) => {
          setBusinessHours(result.data.businessHoursByPk);
        });
    }
  }, [companyBusinessHoursId, apollo, isCurator]);

  useEffect(() => {
    if (productCompanyBusinessHours) {
      setValue("companyBusinessHoursId", productCompanyBusinessHours.id);
      setBusinessHours(productCompanyBusinessHours);
    }
  }, [productCompanyBusinessHours, setValue]);

  const setBusinessHours = (businessHours: BusinessHoursType) => {
    setCompanyBusinessHours({
      openingHours: mapOpeningHoursToWeek(businessHours?.default),
      openingHoursExceptions: mapOpeningHoursExceptionsToFormValues(businessHours?.exceptions),
    });
  };

  const companyHoursDisabled = !companyBusinessHoursId || !companyBusinessHours;

  return (
    <div>
      <Form.Control
        id={"business-hours-id"}
        name={"businessHoursId"}
        type="hidden"
        defaultValue={businessHoursId}
        ref={register()}
        aria-hidden
      />
      <Form.Control
        id={"company-business-hours-id"}
        name={"companyBusinessHoursId"}
        type="hidden"
        defaultValue={companyBusinessHoursId}
        ref={register()}
        aria-hidden
      />
      <Form.Group>
        <Form.Label className="mt-4">{t("productInfo.openingHoursLabel")}</Form.Label>
        <Checkbox
          id={`company-hours-enabled`}
          defaultChecked={false}
          ref={register()}
          name={"companyHoursEnabled"}
          label={t("productInfo.companyHoursEnableButton")}
          disabled={companyHoursDisabled || disabled}
          onChange={(event) => {
            const isEnabled = event.target.checked;
            const formValues = getValues();
            if (isEnabled) {
              setProductBusinessHours({
                openingHours: formValues.openingHours,
                openingHoursExceptions: formValues.openingHoursExceptions,
              });
              reset({ ...formValues, ...(companyBusinessHours ?? {}) });
            } else {
              reset({ ...formValues, ...(productBusinessHours ?? {}) });
            }
          }}
        />
        {companyHoursDisabled && (
          <Notification type="info">{t("productInfo.companyHoursNotification")}</Notification>
        )}
        <OpeningHoursContainer
          name="openingHours"
          inputsDisabled={companyHoursEnabled || disabled}
        />
      </Form.Group>
      <Separator />
      <Form.Group>
        <OpeningHoursExceptionsContainer
          headerKey="productInfo.openingHoursExceptionsLabel"
          valuesRequired={publishMode !== "draft"}
          inputsDisabled={companyHoursEnabled || disabled}
        />
      </Form.Group>
    </div>
  );
};
