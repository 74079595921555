import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpointPixels } from "../../css/breakpoints";
import ServiceHeader from "./ServiceHeader";

type ServiceBoxProps = {
  header: string;
  text: string;
  sectionId: string;
  children: React.ReactNode;
  background?: "white" | "gray";
};

type ServiceTextProps = {
  hyphenate: boolean;
};

const ServiceText = styled.p<ServiceTextProps>`
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-gray-800);
  margin-bottom: 40px;
  max-width: 75%;
  hyphens: ${({ hyphenate }) => (hyphenate ? "auto" : "none")};
  @media (max-width: ${breakpointPixels.md}) {
    max-width: 100%;
  }
`;

const ServiceBox = ({
  header,
  text,
  sectionId,
  children,
  background = "gray",
}: ServiceBoxProps) => {
  const { i18n } = useTranslation();
  return (
    <div className={`mt-5 px-3`}>
      <div className="d-flex flex-column align-items-center">
        <ServiceHeader sectionId={sectionId}>{header}</ServiceHeader>
        <ServiceText hyphenate={i18n.language === "fi"}>{text}</ServiceText>
      </div>
      <div
        className={`row pt-0 pt-md-4 pb-4 px-3 ${
          background === "gray" ? "bg-gray-100" : "bg-white"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ServiceBox;
