import React from "react";
import ErrorContainer from "./ErrorContainer";
import Icon from "../../components/Icon";
import { useTranslation } from "react-i18next";

type CreateAccountErrorProps = {
  headerKey: string;
  descriptionKey: string;
};

const CreateAccountError = ({ headerKey, descriptionKey }: CreateAccountErrorProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex w-100 justify-content-center my-2">
        <Icon name="warning-triangle" color="primary-dark" style={{ width: "50px" }} />
      </div>
      <ErrorContainer>
        <h4 className="mb-3">{t(headerKey)}</h4>
        <p>{t(descriptionKey)}</p>
      </ErrorContainer>
    </>
  );
};

export default CreateAccountError;
