import styled from "styled-components";

type ListItemContainerProps = {
  selected: boolean;
  isPreview?: boolean;
};

const ListItemContainer = styled.button<ListItemContainerProps>`
  outline: none;
  overflow: hidden;
  width: 100%;
  border: 0;
  border-left: 4px solid
    var(${({ selected }) => (selected ? "--color-gray-600" : "--color-gray-200")});
  border-bottom: 1px solid var(--color-gray-200);
  background: var(${({ selected }) => (selected ? "--color-gray-200" : "--color-white")});
  padding: 1rem;

  &:active,
  &:focus {
    outline: 0;
  }
`;

export default ListItemContainer;
