import { AggregateCount, CompanyName, DatahubType } from "../types";

import fragments from "./fragments";
import { gql } from "@apollo/client";

const buildGetProductComments = (isDmoViewMode: boolean) => gql`
  query getProductComments${
    isDmoViewMode ? "Dmo" : "Tc"
  }($productId: uuid!, $limit: Int, $offset: Int) {
    product:productByPk(id: $productId) {
      id
      productComments(order_by: { createdAt: desc }, limit: $limit, offset: $offset) {
        id
        comment
        deletedAt
        editedAt
        editedBy
        createdAt
        createdById
        createdByName
        createdByCompany {
          businessName
        }
        seenByTcAt
        seenByDmoAt
        recipientRole
      }
      ...ProductCommentsCount
      ${isDmoViewMode ? "...ProductNewCommentsCountDmo" : "...ProductNewCommentsCountTc"}
    }
  }
  ${fragments.product.commentsCount}
  ${
    isDmoViewMode
      ? fragments.product.newCommentsCountForDmo
      : fragments.product.newCommentsCountForTc
  }
`;

export const getProductCommentsTc = buildGetProductComments(false);

export const getProductCommentsDmo = buildGetProductComments(true);

export type ProductComment = {
  id: string;
  comment: string;
  createdAt: string;
  createdById: string;
  createdByName: string;
  createdByCompany: CompanyName;
  seenByTcAt: string;
  seenByDmoAt: string;
  recipientRole: DatahubType;
  deletedAt?: string;
  editedAt?: string;
  editedBy?: string;
};

export type ProductCommentData = {
  id: string;
  productComments: ProductComment[];
  productCommentsAggregate: AggregateCount;
  productNewCommentsAggregate: AggregateCount;
};

export type GetProductCommentsResult = {
  product: ProductCommentData;
};
