import { DropdownContainer, PlainDropdownMenu, PlainToggle } from "../Dropdown";
import getCompanyNamesQuery, {
  CompaniesResult,
  Company,
} from "../../graphqlQueries/getCompanyNames";
import { getHasuraRoleContext, isCompanyAllowedForUser } from "../../utils/functions";

import { Dropdown } from "react-bootstrap";
import React from "react";
import { TDH_COMPANY_ID } from "../../utils/constants";
import { UserRole } from "../../types";
import { breakpointPixels } from "../../css/breakpoints";
import styled from "styled-components";
import { useCompanyId } from "../hooks/useCompanyId";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

type CompanyDropdownProps = {
  usersCompanyIds: string[];
};

const CompanyNameContainer = styled.div`
  @media (max-width: ${breakpointPixels.sm}) {
    text-overflow: ellipsis;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    display: inherit;
    text-align: end;
  }
`;

const CompanyDropdown = ({ usersCompanyIds }: CompanyDropdownProps) => {
  const { t } = useTranslation();

  const [companyId] = useCompanyId();
  const history = useHistory();
  const storedCompanyId = localStorage.getItem(TDH_COMPANY_ID);

  const { data: usersCompanies, loading } = useQuery<CompaniesResult>(getCompanyNamesQuery, {
    variables: {
      companyIds: usersCompanyIds,
    },
    context: getHasuraRoleContext(UserRole.ManageCompany),
    fetchPolicy: "cache-first",
  });

  const updateSelectedCompany = (company: Company) => {
    history.push(`/company/${company.id}/dashboard`);
  };

  const companyName = isCompanyAllowedForUser(usersCompanyIds, storedCompanyId)
    ? usersCompanies?.company.find((company) => companyId === company.id)?.businessName
    : usersCompanies?.company[0].businessName;

  if (!isCompanyAllowedForUser(usersCompanyIds, storedCompanyId) && usersCompanies) {
    updateSelectedCompany(usersCompanies?.company[0]);
  }

  return (
    <>
      {usersCompanies && (
        <Dropdown as={DropdownContainer} style={{ height: "65px" }} className="d-block">
          <Dropdown.Toggle as={PlainToggle} id="select-company-dropdown">
            <div className="d-block">
              {!loading && <CompanyNameContainer>{companyName}</CompanyNameContainer>}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu as={PlainDropdownMenu} popperConfig={{ strategy: "fixed" }}>
            <div className="py-3">
              {usersCompanies.company.length > 0 &&
                usersCompanies.company.map((company, i) => (
                  <Dropdown.Item
                    key={i}
                    onClick={() => {
                      updateSelectedCompany(company);
                    }}
                  >
                    {company.businessName}
                  </Dropdown.Item>
                ))}
            </div>
            <hr className="mx-3 my-0" />
            <Dropdown.Item
              className="font-heavy p-3"
              onClick={() => {
                history.push("/signup");
              }}
            >
              {t("common.addCompany")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default CompanyDropdown;
