import React from "react";
import { useHistory } from "react-router-dom";
import Icon from "../components/Icon";
import IconButton from "../components/IconButton";
import { useTranslation } from "react-i18next";

type MobileNavigationHeaderProps = {
  backUrl: string;
};

const MobileNavigationHeader = ({ backUrl }: MobileNavigationHeaderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className="mr-3 p-2">
      <IconButton
        key="back"
        iconElement={
          <Icon name="arrow-left" size="medium" style={{ marginTop: "-3px", marginRight: "3px" }} />
        }
        onClick={() => history.push(backUrl)}
        variant="secondary"
      >
        {t("common.back")}
      </IconButton>
    </div>
  );
};

export default MobileNavigationHeader;
