import {
  AggregateCount,
  BusinessHours,
  ContactDetail,
  CurationStatus,
  DataHubImage,
  ExternalProductSource,
  Language,
  Month,
  PricingType,
  PricingUnit,
  ProductCurationStatus,
  ProductStatus,
  ProductTargetGroup,
  ProductType,
  ProductUserRole,
  TransportationAvailabilityEnum,
  UserRole,
} from "../types";
import { EditProductInformation, ProductGroupImage } from "./productGroup/updateProductGroup";

import { SocialMedia } from "./getSingleCompanyInfo";
import { gql } from "@apollo/client";

const getQueryNameSuffix = (userRole: ProductUserRole) => {
  switch (userRole) {
    case UserRole.ViewOpenProducts:
      return "B2b";
    case UserRole.ManageProducts:
      return "Tc";
    case UserRole.ManageCuration:
      return "Dmo";
    default:
      return "Unknown";
  }
};

const getQueryName = (userRole: ProductUserRole) => {
  return `getProduct${getQueryNameSuffix(userRole)}`;
};

const buildGetProductQuery = (userRole: ProductUserRole) => {
  const viewOpenProducts = userRole === UserRole.ViewOpenProducts;
  const showBrokenLinks = userRole !== UserRole.ViewOpenProducts;

  const brokenLinkFields = showBrokenLinks
    ? `invalidAt
  userVerifiedAt
  userVerifiedBy`
    : "";

  return gql`
    query ${getQueryName(userRole)}($id: uuid!) {
      product:productByPk( id: $id ) {
        id
        type
        accessible
        updatedAt
        businessHours {
          id
          default {
            id
            weekday
            opens
            closes
            open
          }
          exceptions(order_by: { start: asc }) {
            id
            start
            end
            openingHours {
              id
              weekday
              opens
              closes
              open
              date
            }
          }
        }
        socialMedia{
          id
          companySocialMedia
          socialMediaLinks {
            id
            linkId
            linkType
            socialMediaId
            verifiedLink {
              id
              url
              userVerifiedAt
              userVerifiedBy
            }
          }
        }
        externalSource
        productImages(order_by: { orderIndex: asc }) {
          id
          largeUrl
          originalUrl
          originalHeight
          originalWidth
          orientation
          thumbnailUrl
          altText
          copyright
          filename
          coverPhoto
        }
        productVideos {
          id
          title
          verifiedVideoLink {
            id
            url
            ${brokenLinkFields}
          }
        }
        productTargetGroups {
          id
          targetGroupId
        }
        productTags {
          id
          tag
        }
        productInformations {
          id
          name
          description
          language
          verifiedProductLink {
            id
            url
            ${brokenLinkFields}
          }
          verifiedWebshopLink {
            id
            url
            ${brokenLinkFields}
          }
        }
        company {
          id
          businessName
          businessHoursId
          socialMediaId
          ${
            viewOpenProducts
              ? ""
              : `
            socialMedia {
              socialMediaLinks {
                linkType
                verifiedLink {
                  id
                  url
                  userVerifiedAt
                  userVerifiedBy
                }
              }
            }
          `
          }
        }
        contactDetails {
          id
          phone
          email
        }
        productAvailabilities {
          id
          endDate
          startDate
          transportationAvailability
        }
        productAvailableMonths {
          id
          month
        }
        productPricings {
          id
          fromPrice
          toPrice
          pricingUnit
          pricingType
        }
        productAvailabilityLanguages {
          id
          language
        }
        postalAddresses {
          id
          city
          postalCode
          streetName
          location
        }
        productCertificates {
          id
          certificate
        }
        productCapacities {
          id
          min
          max
        }
        productDuration {
          id
          minutes
          hours
          days
          weeks
        }
        ${
          viewOpenProducts
            ? ""
            : `
            productCurations {
              id
              curationStatus
              productCurationStatus
            }
            curationEnabled
            status
          `
        }
        ${
          viewOpenProducts
            ? ""
            : `
            updatedByUser {
              name
            }
          `
        }
      }
    }
  `;
};

export interface ProductTargetGroupObj {
  targetGroupId: ProductTargetGroup;
}

export interface ProductTag {
  tag: string;
}

export interface HasuraProductType {
  productType: ProductType;
}

export interface VerifiedLink {
  id: string;
  invalidAt?: string | null;
  userVerifiedAt?: string | null;
  userVerifiedBy?: string | null;
  url: string;
}

export interface ProductInformation {
  id: string;
  language: Language;
  name: string;
  description: string;
  verifiedProductLink: VerifiedLink | null;
  verifiedWebshopLink: VerifiedLink | null;
}

export interface ProductAvailableDates {
  id: string;
  endDate: string;
  startDate: string;
  transportationAvailability: TransportationAvailabilityEnum | null;
}

export interface ProductPricing {
  fromPrice: number | null;
  toPrice: number | null;
  pricingUnit: PricingUnit | null;
  pricingType: PricingType;
}

export interface ProductCapacity {
  id: string;
  min: number;
  max: number;
}

export interface ProductCertificate {
  id: string;
  certificate: string;
}

export interface ProductDuration {
  id: string;
  minutes: number;
  hours: number;
  days: number;
  weeks: number;
}

export interface PostalAddress {
  id: string;
  city: string;
  postalCode: string;
  streetName: string;
  location?: string;
}

interface Company {
  id: string;
  businessName: string;
  businessHoursId: string | null;
  socialMediaId: string | null;
  socialMedia: SocialMedia;
}

export interface HasuraCurationStatus {
  id: string;
  curationStatus: CurationStatus;
  productCurationStatus: ProductCurationStatus;
}

export interface AvailableMonth {
  id: string;
  month: Month;
}

export interface UpdatedByUser {
  name: string;
}

export type HasuraProductVideo = {
  id: string;
  title: string;
  verifiedVideoLink: VerifiedLink;
};

export type HasuraAvailabilityLanguage = {
  language: Language;
  productId?: string;
};

export interface HasuradbProduct {
  id: string;
  productCommentsAggregate?: AggregateCount;
  productNewCommentsAggregate?: AggregateCount;
  productImages: DataHubImage[];
  productVideos: HasuraProductVideo[];
  productTargetGroups: ProductTargetGroupObj[];
  productTags: ProductTag[];
  type: ProductType;
  productInformations: ProductInformation[];
  contactDetails: ContactDetail[];
  productAvailabilities: ProductAvailableDates[];
  productAvailableMonths: AvailableMonth[];
  productPricings: ProductPricing[];
  productAvailabilityLanguages: HasuraAvailabilityLanguage[];
  productCapacities: ProductCapacity[];
  productDuration: ProductDuration[];
  postalAddresses: PostalAddress[];
  businessHours: BusinessHours;
  socialMedia?: SocialMedia;
  company: Company;
  productCurations?: HasuraCurationStatus[];
  curationEnabled?: boolean;
  status?: ProductStatus;
  updatedAt: string;
  updatedByUser?: UpdatedByUser;
  productCertificates: ProductCertificate[];
  accessible: boolean | null;
  externalSource?: ExternalProductSource;
  productGroupProductImages?: ProductGroupImage[];
  productGroupProductInformations?: EditProductInformation[];
}

export interface HasuradbDeletedProduct {
  id: string;
  type: ProductType;
  updatedAt: string;
  company: {
    businessName: string;
  };
  postalAddresses: {
    city: string;
  }[];
  productInformations: {
    name: string;
  }[];
}

export interface GetProductQueryData {
  product?: HasuradbProduct;
}

export const getProductB2bQuery = buildGetProductQuery(UserRole.ViewOpenProducts);

export const getProductTcQuery = buildGetProductQuery(UserRole.ManageProducts);

export const getProductDmoQuery = buildGetProductQuery(UserRole.ManageCuration);

const getProductQuery = (userRole: ProductUserRole) => {
  switch (userRole) {
    case UserRole.ViewOpenProducts:
      return getProductB2bQuery;
    case UserRole.ManageProducts:
      return getProductTcQuery;
    case UserRole.ManageCuration:
      return getProductDmoQuery;
    default:
      throw new Error("Invalid user role");
  }
};

export default getProductQuery;
