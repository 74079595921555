import React from "react";
import PublicHeader from "../components/PublicHeader";
import styled from "styled-components";
import punkaharju from "../images/punkaharju_saimaa.jpg";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { breakpointPixels } from "../css/breakpoints";
import DataHubUserLogos from "../components/landingPage/DataHubUserLogos";

const HeroImage = styled.div`
  height: 250px;
  background: url(${punkaharju}) no-repeat center center;
  background-size: cover;
  background-position: bottom;
  @media (max-width: ${breakpointPixels.md}) {
    padding: 5rem 2rem 0 2rem;
  }
`;

const Container = styled.div`
  margin-left: auto !important;
  margin-right: auto !important;
  padding-top: 3rem !important;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 1080px;
  @media (max-width: ${breakpointPixels.lg}) {
    padding: 3rem 2rem 0 2rem;
  }
  @media (max-width: ${breakpointPixels.sm}) {
    padding: 3rem 0 0 0;
  }
`;

const zoneAtlasBaseUrl = "https://visitfinland.maps.zoneatlas.com";

const ZoneAtlasDemo = () => {
  const { t, i18n } = useTranslation();
  const zoneAtlasUrl =
    i18n.language === "fi" ? zoneAtlasBaseUrl.concat("?lang=fi") : zoneAtlasBaseUrl;
  return (
    <div className="w-100">
      <div className="sticky-top border-bottom bg-white">
        <PublicHeader />
      </div>
      <div className="w-100 h-100">
        <HeroImage />
        <Container>
          <div className="row ml-3">
            <div className="col-12 col-lg-9">
              <h3 className="pt-5 h2-size">{t("zoneAtlas.header")}</h3>
              <div className="mt-4 font-light">{t("zoneAtlas.introText1")}</div>
              <div className="mt-4 font-light pb-5">{t("zoneAtlas.introText2")}</div>
            </div>
          </div>
          <div className="row">
            <div className="w-100 mx-lg-4">
              {/* iFrame code snippet from ZoneAtlas: */}
              <iframe
                width="100"
                height="600"
                scrolling="no"
                frameBorder="0"
                allow="fullscreen; geolocation"
                id="zoneatlas-map-iframe"
                title="zoneatlas-map-iframe"
                src={zoneAtlasUrl}
                style={{ width: "100%", background: "transparent" }}
              ></iframe>
            </div>
          </div>
          <DataHubUserLogos />
        </Container>
        <Footer />
      </div>
    </div>
  );
};

export default ZoneAtlasDemo;
