import { gql } from "@apollo/client";

const fragments = {
  product: {
    commentsCount: gql`
      fragment ProductCommentsCount on hasuradb_product {
        productCommentsAggregate: productComments_aggregate {
          aggregate {
            count
          }
        }
      }
    `,
    newCommentsCountForTc: gql`
      fragment ProductNewCommentsCountTc on hasuradb_product {
        productNewCommentsAggregate: productComments_aggregate(
          where: { seenByTcAt: { _is_null: true }, recipientRole: { _eq: tc } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    newCommentsCountForDmo: gql`
      fragment ProductNewCommentsCountDmo on hasuradb_product {
        productNewCommentsAggregate: productComments_aggregate(
          where: { seenByDmoAt: { _is_null: true }, recipientRole: { _eq: dmo } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    curatorProductData: gql`
      fragment CuratorProductData on hasuradb_product {
        id
        type
        updatedAt
        externalSource

        productImages(order_by: { orderIndex: asc }) {
          id
          largeUrl
          originalUrl
          thumbnailUrl
          altText
          copyright
          filename
          coverPhoto
        }
        productInformations {
          id
          name
          description
          language
          verifiedProductLink {
            id
            invalidAt
          }
          verifiedWebshopLink {
            id
            invalidAt
          }
        }
        productVideos {
          id
          verifiedVideoLink {
            id
            invalidAt
          }
        }
        company {
          id
          businessName
        }
        postalAddresses {
          id
          city
        }
        productCurations {
          id
          curationStatus
          productCurationStatus
        }
      }
    `,
  },
};

export default fragments;
