import { Moment } from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { dayMonthFormat } from "../../utils/format";
import { OpeningHoursInput, OpeningHoursInputProps } from "./OpeningHoursInput";

type OpeningHoursProps = OpeningHoursInputProps & {
  labelKey: string;
  date?: Moment;
};

const OpeningHours = ({ labelKey, date, ...inputProps }: OpeningHoursProps) => {
  const { t } = useTranslation();

  const getOpeningHoursLabel = () => {
    if (date) {
      return `${date.format(dayMonthFormat)} ${t(labelKey)}`;
    }

    return t(labelKey);
  };

  return (
    <div className="d-flex mb-2 px-3 bg-light">
      <div className="d-inline-block my-2" style={{ minWidth: "120px" }}>
        {getOpeningHoursLabel()}
      </div>
      <OpeningHoursInput {...inputProps} />
    </div>
  );
};

export default OpeningHours;
