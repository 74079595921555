import React from "react";
import Icon from "../Icon";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FieldError } from "react-hook-form";

type HintAndCounterProps = {
  maxLength: number;
  currentLength: number;
  hintText: string;
  error: FieldError; // assumes that error message has already been translated
};

const DIV_STYLE = "d-flex align-items-center";
const FIELD_INFO_STYLE = "text-small color-gray-600";
const FIELD_ERROR_STYLE = "text-small text-danger font-italic";
const FIELD_COUNTER_STYLE = "ml-auto text-small color-gray-600";

const Counter = styled.span`
  font-style: normal !important;
  float: right;
`;

export const HintAndCounter = ({
  maxLength,
  currentLength,
  hintText,
  error,
}: HintAndCounterProps) => {
  const tooManyChars = currentLength > maxLength;
  const { t } = useTranslation();

  const counter = (
    <Counter>
      {t("productInfo.characters")}: {error?.message && <br />}
      {currentLength} / {maxLength}
    </Counter>
  );
  if (error || tooManyChars) {
    const divStyle = { minInlineSize: "16px" };
    return (
      <div className={DIV_STYLE}>
        <Icon name="warning-triangle" color="invalid" size="small" style={divStyle} />
        <div className="ml-2">
          <div className={FIELD_ERROR_STYLE}>
            {error ? error.message : t("productInfo.nameTooLongError", { maxLength: maxLength })}
          </div>
        </div>
        <div className={FIELD_COUNTER_STYLE}>{counter}</div>
      </div>
    );
  }
  return (
    <div className={DIV_STYLE}>
      <div className={FIELD_INFO_STYLE}>{hintText}</div>
      <div className={FIELD_COUNTER_STYLE}>{counter}</div>
    </div>
  );
};
