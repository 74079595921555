import React from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { default as c } from "classnames";

type LoadingProps = {
  i18nKey?: string;
  size?: "sm";
};

const Loading = ({ i18nKey = "common.loading", size }: LoadingProps) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center">
      <Spinner size={size} animation="border" />
      <h3
        className={c("m-0", {
          "text-smaller": size === "sm",
          "ml-2": size === "sm",
          "ml-3": size !== "sm",
        })}
      >
        {t(i18nKey)}
      </h3>
    </div>
  );
};

export default Loading;
