import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MaintenanceBreakFieldItem } from "./MaintenanceBreakFieldItem";
import { getFieldError } from "../../utils/functions";
import { getMin, MaintenanceDateTime } from "./MaintenanceDateTime";
import { useIsSmallScreen } from "../MediaQuery";
import { MaintenanceDataOut } from "../../types/bannerTypes";
import moment from "moment";

const BREAK_START_DATE = "breakStartDate";
const BREAK_END_DATE = "breakEndDate";
const BREAK_START_TIME = "breakStartTime";
const BREAK_END_TIME = "breakEndTime";

type MaintenanceBreakSectionProps = {
  hasEmptyFields: (bool: boolean) => void;
  editModeData?: MaintenanceDataOut;
};

export const MaintenanceBreakSection = ({
  hasEmptyFields,
  editModeData,
}: MaintenanceBreakSectionProps) => {
  const { t } = useTranslation();
  const isMobile = useIsSmallScreen();
  const { errors, watch, setValue, trigger } = useFormContext();

  const watchBreakStartTime = watch(BREAK_START_TIME, "");
  const watchBreakStartDate = watch(BREAK_START_DATE, "");
  const watchBreakEndTime = watch(BREAK_END_TIME, "");
  const watchBreakEndDate = watch(BREAK_END_DATE, "");

  const startError = getFieldError(errors, BREAK_START_TIME);
  const startHasErrors = !!startError;

  const endError = getFieldError(errors, BREAK_END_TIME);
  const endHasErrors = !!endError?.message;

  useEffect(() => {
    const isFilled =
      !!watchBreakStartTime && !!watchBreakStartDate && !!watchBreakEndTime && !!watchBreakEndDate;
    hasEmptyFields(isFilled);
  }, [
    hasEmptyFields,
    watchBreakStartTime,
    watchBreakStartDate,
    watchBreakEndTime,
    watchBreakEndDate,
  ]);

  useEffect(() => {
    if (watchBreakStartDate && !watchBreakEndDate) {
      setValue(BREAK_END_DATE, watchBreakStartDate);
      trigger(BREAK_END_DATE);
    }

    if (watchBreakStartDate && watchBreakEndDate) {
      if (moment(watchBreakEndDate).isBefore(watchBreakStartDate)) {
        setValue(BREAK_END_DATE, watchBreakStartDate);
        trigger(BREAK_END_DATE);
      }
    }
  }, [setValue, trigger, watchBreakEndDate, watchBreakStartDate]);

  useEffect(() => {
    if (editModeData) {
      setValue(BREAK_START_DATE, editModeData.breakStartDate);
      setValue(BREAK_END_DATE, editModeData.breakEndDate);
    }
  }, [editModeData, setValue]);

  const setStartTime = (time: string | undefined) => {
    // If values haven't changed or combinedNumber is undefined, return
    if (!time || watchBreakStartTime === time) {
      return;
    }
    setValue(BREAK_START_TIME, time, { shouldValidate: true });
    trigger(BREAK_END_TIME);
  };

  const setEndTime = (time: string | undefined) => {
    if (!time || watchBreakEndTime === time) {
      return;
    }

    setValue(BREAK_END_TIME, time, { shouldValidate: true });
    trigger(BREAK_START_TIME);
  };

  return (
    <>
      <div>
        <p className="font-weight-bold font-heavy">{t("maintenance.outage")}</p>
        {!isMobile ? (
          <div className="d-flex flex-row justify-content-start" style={{ width: "80%" }}>
            <div className="w-50">
              <MaintenanceDateTime
                formLabel={t("maintenance.startTime")}
                groupName={BREAK_START_DATE}
                dateFieldName={BREAK_START_DATE}
                timeFieldName={BREAK_START_TIME}
                timeHasErrors={startHasErrors}
                setTime={setStartTime}
                editTime={editModeData?.breakStartTime}
                editDate={editModeData?.breakStartDate}
                minDate={new Date()}
              />
            </div>
            <div style={{ width: "52%" }}>
              <MaintenanceDateTime
                formLabel={t("maintenance.endTime")}
                groupName={BREAK_END_DATE}
                dateFieldName={BREAK_END_DATE}
                timeFieldName={BREAK_END_TIME}
                timeHasErrors={endHasErrors}
                setTime={setEndTime}
                editTime={editModeData?.breakEndTime}
                editDate={editModeData?.breakEndDate}
                minDate={getMin(watchBreakStartDate)}
              />
            </div>
          </div>
        ) : (
          <>
            <Row className="px-4">
              <MaintenanceDateTime
                formLabel={t("maintenance.startTime")}
                groupName={BREAK_START_DATE}
                dateFieldName={BREAK_START_DATE}
                timeFieldName={BREAK_START_TIME}
                timeHasErrors={startHasErrors}
                setTime={setStartTime}
                editTime={editModeData?.breakStartTime}
                editDate={editModeData?.breakStartDate}
                minDate={new Date()}
              />
            </Row>
            <Row className="px-4">
              <MaintenanceDateTime
                formLabel={t("maintenance.endTime")}
                groupName={BREAK_END_DATE}
                dateFieldName={BREAK_END_DATE}
                timeFieldName={BREAK_END_TIME}
                timeHasErrors={endHasErrors}
                setTime={setEndTime}
                editTime={editModeData?.breakEndTime}
                editDate={editModeData?.breakEndDate}
                minDate={getMin(watchBreakStartDate)}
              />
            </Row>
          </>
        )}
      </div>

      <Row className="px-2 px-md-0 px-lg-0">
        <MaintenanceBreakFieldItem
          lang="fi"
          title={editModeData?.titleFi}
          content={editModeData?.contentFi}
        />
        <MaintenanceBreakFieldItem
          lang="en"
          title={editModeData?.titleEn}
          content={editModeData?.contentEn}
        />
      </Row>
    </>
  );
};
