import { gql } from "@apollo/client";

export const getProductGroupPermissionsQuery = gql`
  query ProductGroupPermission($groupId: uuid!) {
    productGroupByPk(id: $groupId) {
      permissions {
        role
        userId
        companyId
      }
    }
  }
`;

export type GetProductGroupPermissionsResult = {
  productGroupByPk: { permissions: { role: string; userId?: string; companyId?: string }[] };
};

export type GetProductGroupPermissionsVariables = {
  groupId: string;
};
