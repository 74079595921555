import { Col, Form, Row } from "react-bootstrap";
import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";
import {
  allowedCityFormat,
  capitalizeAddressName,
  capitalizeCityNames,
} from "../../utils/formUtils";

import React from "react";
import { UserRole } from "../../types";
import { postalCodeValidator } from "../../utils/postalCodeValidation";
import { useApolloClient } from "@apollo/client";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type LocationDetailsSectionProps = {
  isCurator: boolean;
};

const LocationDetailsSection = ({ isCurator }: LocationDetailsSectionProps) => {
  const { register, errors, watch, setValue } = useFormContext();
  const { t } = useTranslation();

  const apolloClient = useApolloClient();

  const streetName = watch("streetName");
  const city = watch("city");

  const validatePostalCode = (postalCode: string) => {
    try {
      return postalCodeValidator(apolloClient, postalCode, UserRole.ManageCompany);
    } catch (err) {
      return "validationErrors.postcodeNotValid";
    }
  };

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader data-cy="company-location-details">
          {t("companyInfo.locationHeader")}
        </FormSectionHeader>
        <p>
          {isCurator
            ? t("companyInfo.organisationLocationDetailsDescription")
            : t("companyInfo.locationDetailsDescription")}
        </p>
      </FormDescription>
      <FormSectionContent>
        <Row>
          <Col xs="12" md="6">
            <Form.Group controlId="streetName">
              <Form.Label>{t("companyInfo.streetName")}*</Form.Label>
              <Form.Control
                type="text"
                name="streetName"
                ref={register({
                  required: "validationErrors.required",
                })}
                onBlur={() => setValue("streetName", capitalizeAddressName(streetName.trim()))}
                className={errors.streetName ? "is-invalid" : ""}
              />
              {errors.streetName && <p className="text-danger">{t(errors.streetName.message)}</p>}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="postalCode">
              <Form.Label>{t("companyInfo.postalCode")}*</Form.Label>
              <Form.Control
                type="text"
                name="postalCode"
                ref={register({
                  validate: validatePostalCode,
                })}
                className={errors.postalCode ? "is-invalid" : ""}
              />
              {errors.postalCode && <p className="text-danger">{t(errors.postalCode.message)}</p>}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <Form.Group controlId="city">
              <Form.Label>{t("companyInfo.city")}*</Form.Label>
              <Form.Control
                type="text"
                name="city"
                onBlur={() => setValue("city", capitalizeCityNames(city.trim()))}
                ref={register({
                  required: "validationErrors.required",
                  pattern: {
                    value: allowedCityFormat,
                    message: t("validationErrors.invalidCity"),
                  },
                })}
                className={errors.city ? "is-invalid" : ""}
              />
              {errors.city && <p className="text-danger">{t(errors.city.message)}</p>}
            </Form.Group>
          </Col>
        </Row>
      </FormSectionContent>
    </FormSection>
  );
};

export default LocationDetailsSection;
