import { Location } from "../types";
/*
 * Parse location from Postgres point type string
 * "(latitude, longitude)" => {
 *  latitude: number,
 *  longitude: number
 *  }
 */
export const parseLocation = (locationStr: string): Location => {
  const parsedLocation = locationStr
    .replace("(", "")
    .replace(")", "")
    .split(",")
    .map((s) => parseFloat(s.trim()));
  const latitude = parsedLocation[0];
  const longitude = parsedLocation[1];

  return {
    latitude,
    longitude,
  };
};

export const createGoogleMapsCoordinateUrl = (location: Location): string => {
  return `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`;
};

export const formatLocation = (location: Location): string => {
  return `${location.latitude}, ${location.longitude}`;
};
