import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { OpeningHoursDay } from "../../types";
import { getOpeningHoursDaysWeek } from "../../utils/timeUtils";
import OpeningHours from "./OpeningHours";
import { OpeningHoursModal } from "./OpeningHoursModal";

const StyledModalButton = styled(Button)`
  color: var(--color-primary-dark);
  text-decoration: underline;
  font-weight: var(--font-light);
`;

type OpeningHoursContainerProps = {
  name: string;
  openingHoursDays?: OpeningHoursDay[];
  valuesRequired?: boolean;
  inputsDisabled?: boolean;
};

export const OpeningHoursContainer: React.FunctionComponent<OpeningHoursContainerProps> = ({
  name,
  openingHoursDays = getOpeningHoursDaysWeek(),
  valuesRequired,
  inputsDisabled,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const toggleOpeningHoursModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <div>
      {!inputsDisabled && openingHoursDays.length > 0 && (
        <div className="pb-4">
          <StyledModalButton
            className="p-0 font-light"
            variant="text"
            onClick={() => toggleOpeningHoursModal()}
          >
            {t("productInfo.addMultipleOpeningTimes")}
          </StyledModalButton>
        </div>
      )}
      {openingHoursDays.map((day) => (
        <OpeningHours
          key={`${name}-${day.weekday}`}
          inputName={`${name}.${day.weekday}`}
          date={day.date}
          defaultValues={day.defaultValues}
          labelKey={day.labelKey}
          required={valuesRequired}
          disabled={inputsDisabled}
        />
      ))}
      {openModal && (
        <OpeningHoursModal
          onHide={() => toggleOpeningHoursModal()}
          weekdays={openingHoursDays}
          openingHoursInputName={name}
        />
      )}
    </div>
  );
};
