import React, { useState } from "react";
import CollapseToggleButton from "../CollapseToggleButton";
import { Collapse, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ImageDropProps } from "../../types";
import ImageDrop from "./ImageDrop";

const ImageAccordion = ({
  images,
  onChange,
  fileValidator,
  uploadError,
  setUploadError,
}: ImageDropProps) => {
  const { t } = useTranslation();
  const [imageAccordionOpen, setImageAccordionOpen] = useState(false);
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <CollapseToggleButton
          onClick={() => setImageAccordionOpen((current) => !current)}
          contentOpen={imageAccordionOpen}
          iconPosition={"center"}
          aria-controls={"image-accordion"}
          aria-expanded={imageAccordionOpen}
          variant="primary"
          className="disable-bg-effects"
        >
          <div>
            <div className="color-primary-dark bg-white">{`+ ${t("productInfo.addNewImage")}`}</div>
          </div>
        </CollapseToggleButton>
      </div>
      <Collapse in={imageAccordionOpen}>
        <div id={"image-accordion"}>
          <>
            <Form.Label className={"mt-4"}>{t("productInfo.addImages")} *</Form.Label>
            <ImageDrop
              images={images}
              onChange={onChange}
              setUploadError={setUploadError}
              uploadError={uploadError}
              fileValidator={fileValidator}
            />
          </>
        </div>
      </Collapse>
    </>
  );
};

export default ImageAccordion;
