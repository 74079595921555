import { Language } from "../../types";
import MultilineText from "../MultilineText";
import { NotAvailable } from "./NotAvailable";
import { ProductInformation } from "../../graphqlQueries/getProduct";
import React from "react";
import ViewProductExternalLinks from "./ViewProductExternalLinks";
import ViewProductHeader from "./ViewProductHeader";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type ViewProductDetailsProps = {
  details: ProductInformation[];
  selectedLanguage: Language | null;
};

const ProductHeader = styled.h1`
  font-size: 1.25rem;
  color: var(--color-gray-900);
  line-height: 1.5rem;
  margin-bottom: 2rem;
`;

const ViewProductDetails = ({ details, selectedLanguage }: ViewProductDetailsProps) => {
  const { t } = useTranslation();
  const selectedProductInformation = details.find((e) => e.language === selectedLanguage);

  return (
    <>
      <ViewProductHeader>{t("productInfo.productName")}</ViewProductHeader>
      <ProductHeader>{selectedProductInformation?.name || <NotAvailable />}</ProductHeader>
      <ViewProductHeader>{t("productInfo.productDescription")}</ViewProductHeader>
      <MultilineText>{selectedProductInformation?.description || <NotAvailable />}</MultilineText>
      <ViewProductExternalLinks
        webpage={selectedProductInformation?.verifiedProductLink?.url}
        webshop={selectedProductInformation?.verifiedWebshopLink?.url}
      />
    </>
  );
};

export default ViewProductDetails;
