import { gql } from "@apollo/client";

export const getMaintenanceQuery = gql`
  query GetMaintenance {
    maintenanceBanner(where: { deletedAt: { _is_null: true } }) {
      bannerEndDate
      bannerEndTime
      bannerStartDate
      bannerStartTime
      breakEndDate
      breakEndTime
      breakStartDate
      breakStartTime
      contentEn
      contentFi
      id
      setOnImmediately
      titleEn
      titleFi
      maintenanceManualToggle {
        id
        manualIsActive
        updatedAt
      }
    }
  }
`;
