import { Language } from "../types";

export const TDH_COMPANY_ID = "datahub_company_id";
export const MAX_COMMENT_LENGTH = 5000;
export const BF_STATISTICS_URL =
  "https://www.businessfinland.fi/suomalaisille-asiakkaille/palvelut/matkailun-edistaminen/tutkimukset-ja-tilastot/visit-finland-datahub";
export const COORDINATES_FINLAND: [number, number] = [61.9241, 25.7482]; //eslint-disable-line
export const OSM_API_URL = "https://nominatim.openstreetmap.org";
export const SEARCH_DEBOUNCE_MS = 300;
export const MONTHS_HALF_YEAR = 6;
export const MONTHS_FULL_YEAR = 12;
export const TOKEN_MIN_VALIDITY_SECONDS = 30;
export const LANGUAGES: Language[] = [
  "en",
  "fi",
  "sv",
  "de",
  "zh",
  "ru",
  "ja",
  "fr",
  "it",
  "ko",
  "es",
];
export const RECOMMENDED_CATEGORY_AMOUNT = 5;
export const CDN_STF_LOGO =
  "https://cdn-datahub.visitfinland.com/images/datahub/STF_logo_thumbnail_v2.png";
export const CDN_WELCOME_CYCLIST_LOGO =
  "https://cdn-datahub.visitfinland.com/images/datahub/welcome_cyclist_logo.png";
