import styled from "styled-components";

type InputContainerProps = {
  hasError?: boolean;
};

const InputContainer = styled.section.attrs<InputContainerProps>(({ hasError }) => ({
  className: hasError ? "is-invalid is-invalid-border" : "",
}))<InputContainerProps>`
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  background-color: var(--color-gray-100);
  padding: 1rem 1rem 0.5rem 1rem;
`;

export default InputContainer;
