import { useKeycloak } from "@react-keycloak/web";
import React, { RefObject, useCallback, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { FileRejection, useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  DataHubImage,
  FileWithSize,
  ImageUploadError,
  ImageUploadErrorEnum,
  ImageValidationResult,
} from "../../types";
import {
  createObjectUrl,
  isFilWithSize,
  isImageUploadError,
  uploadImageForm,
  validateImages,
} from "../../utils/imageUploadUtils";

type ImageUploadProps = {
  setInvalidImages: (error: ImageUploadError[]) => void;
  resetInvalidImages: () => void;
  maxSize?: number;
  single?: boolean;
  validator: (file: FileWithSize) => ImageUploadError | undefined;
  onChange: (images: DataHubImage[]) => void;
  images: DataHubImage[];
  name: string;
  hide?: boolean;
  imageEndpoint: string;
  imageRef?: RefObject<HTMLInputElement>;
};

type DropContainerProps = {
  hasErrors?: boolean;
};

const DEFAULT_MAX_SIZE = 15_728_640; // 15mb
const MAX_FILES_AMOUNT = 15;

const DropContainer = styled.div.attrs<DropContainerProps>(({ hasErrors }) => ({
  className: hasErrors ? "is-invalid" : "",
}))<DropContainerProps>`
  padding: 2rem;
  background: var(--color-gray-100);
  border: 2px dashed #979fa6;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    border: 2px solid var(--color-black);
    background: var(--color-gray-200);
    outline: none;
  }
`;

const ImageUpload = ({
  setInvalidImages,
  resetInvalidImages,
  validator,
  images,
  onChange,
  maxSize = DEFAULT_MAX_SIZE,
  single = false,
  name,
  hide,
  imageEndpoint,
  imageRef,
}: ImageUploadProps) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState<boolean>(false);
  const { errors } = useFormContext();

  const { keycloak } = useKeycloak();
  const authToken = keycloak?.token;

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      // reset invalid images before uploading
      resetInvalidImages();

      if (acceptedFiles.length === 0) {
        return;
      }

      const settledImages = await Promise.allSettled(acceptedFiles.map(createObjectUrl));

      const promiseFulfilled = settledImages.filter(({ status }) => {
        return status === "fulfilled";
      }) as PromiseFulfilledResult<FileWithSize>[];

      const successfulObjectUrlImages = promiseFulfilled.map((it) => it.value);

      const promiseRejected = settledImages.filter(
        (it) => it.status === "rejected"
      ) as PromiseRejectedResult[];

      const invalidObjectUrlImages: ImageUploadError[] = promiseRejected.map((image) => ({
        type: ImageUploadErrorEnum.UploadFailed,
        variables: {
          name: image.reason,
        },
      }));

      const imageValidator = validateImages(validator);
      const validated: ImageValidationResult[] = successfulObjectUrlImages
        .map(imageValidator)
        .filter((it) => it); // remove undefined

      const invalid = validated.filter(isImageUploadError);

      if (invalidObjectUrlImages.length > 0 || invalid.length > 0) {
        setInvalidImages([...invalidObjectUrlImages, ...invalid]);
      }

      const valid = validated.filter(isFilWithSize);

      // dont do fetch request on empty array
      if (valid.length < 1) {
        return;
      }

      const formData = new FormData();

      if (single) {
        const firstFile = valid[0].file;
        formData.append("images", firstFile, firstFile.name);
      } else {
        valid.forEach(({ file }) => {
          formData.append("images", file, file.name);
        });
      }

      setUploading(true);
      await uploadImageForm(imageEndpoint, authToken, formData, images, onChange, setInvalidImages);

      setUploading(false);
    },
    [
      validator,
      single,
      imageEndpoint,
      authToken,
      setInvalidImages,
      images,
      onChange,
      resetInvalidImages,
    ]
  );

  const onDropRejected = useCallback(
    (droppedFiles: FileRejection[]) => {
      if (droppedFiles.length > MAX_FILES_AMOUNT) {
        setInvalidImages([
          {
            type: ImageUploadErrorEnum.TooManyFiles,
          },
        ]);

        return;
      }

      const invalidImages = droppedFiles.map(({ file }) => {
        return {
          type: ImageUploadErrorEnum.TooBigFile,
          variables: {
            name: file.name,
          },
        };
      });

      setInvalidImages(invalidImages);
    },
    [setInvalidImages]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "image/*": [],
    },
    maxSize,
    maxFiles: MAX_FILES_AMOUNT,
  });

  return hide ? (
    <></>
  ) : (
    <>
      <DropContainer {...getRootProps()} ref={imageRef} hasErrors={errors[name]}>
        <input {...getInputProps()} name={"images"} disabled={uploading} />
        {uploading ? (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner animation="border" />
            <span className="ml-3">{t("imageUpload.uploading")}</span>
          </div>
        ) : (
          <div className="cursor-pointer">
            <Trans i18nKey="imageUpload.dragAndDropDescription">
              Drop images or click to
              <Button variant="ghost" style={{ paddingTop: "0.1rem" }}>
                browse
              </Button>
            </Trans>
          </div>
        )}
      </DropContainer>
      {errors[name] && <p className="text-danger">{t(errors[name].message)}</p>}
    </>
  );
};
export default ImageUpload;
