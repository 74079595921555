import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type CreateBulletinNewsSectionProps = {
  language: string;
};

const BulletinFormNewsSection = ({ language }: CreateBulletinNewsSectionProps) => {
  const { t } = useTranslation();

  const { register, errors, watch } = useFormContext();
  const titleLang = `title${language}`;
  const contentLang = `content${language}`;
  const title = watch(titleLang);
  const content = watch(contentLang);

  return (
    <>
      <Form.Group controlId={titleLang}>
        <Form.Label className="mb-1">{t(`dashboard.bulletinFormTitle${language}Label`)}</Form.Label>
        <Form.Control
          name={titleLang}
          className={errors[titleLang] ? "is-invalid" : ""}
          ref={register({
            required: content ? "validationErrors.required" : undefined,
          })}
          placeholder={t(`dashboard.bulletinFormTitle${language}Placeholder`)}
        />
        {errors[titleLang] && <p className="text-danger">{t(errors[titleLang].message)}</p>}
      </Form.Group>
      <Form.Group controlId={contentLang}>
        <Form.Label className="mb-1">
          {t(`dashboard.bulletinFormContent${language}Label`)}
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          name={contentLang}
          className={errors[contentLang] ? "is-invalid" : ""}
          ref={register({
            required: title ? "validationErrors.required" : undefined,
            maxLength: 500,
          })}
          placeholder={t(`dashboard.bulletinFormContent${language}Placeholder`)}
        />
        <p
          className={
            errors[contentLang] && errors[contentLang].type === "maxLength"
              ? "text-small text-danger mt-1"
              : "text-small color-gray-600 mt-1"
          }
        >
          {t("common.chars", {
            current: content?.length || 0,
            max: "500",
          })}
        </p>
        {errors[contentLang] && errors[contentLang].message && (
          <p className="text-danger">{t(errors[contentLang].message)}</p>
        )}
      </Form.Group>
    </>
  );
};

export default BulletinFormNewsSection;
