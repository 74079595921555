import React from "react";
import ConfirmButton from "../ConfirmButton";
import { DataHubButton } from "../DataHubButton";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";

type DeleteMaintenanceProps = {
  deleteMaintenance: () => void;
  openPopover: boolean;
  handlePopoverButtonClick: () => void;
};

export const DeleteMaintenance = ({
  deleteMaintenance,
  openPopover,
  handlePopoverButtonClick,
}: DeleteMaintenanceProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmButton
      id="delete-maintenance-confirm"
      confirmStyle="warning"
      showIcon
      headerContent={t("maintenance.deletePopoverHeader")}
      popOverContent={<p className="pb-3">{t("maintenance.deletePopoverDescription")}</p>}
      confirmButtonContent={t("common.delete")}
      disabled={false}
      onConfirmClick={deleteMaintenance}
      popoverPlacement="top-end"
      renderTriggerButton={
        <DataHubButton
          className="px-0"
          variant="ghost"
          size="sm"
          onClick={handlePopoverButtonClick}
        >
          <Icon name="trash" size="medium" color="primary-dark" />
        </DataHubButton>
      }
      closePopover={handlePopoverButtonClick}
      show={openPopover}
    />
  );
};
