import React, { CSSProperties, ReactNode } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

type ContainerProps = {
  checked?: boolean;
  disabled?: boolean;
};

const AlternativeCheckboxContainer = styled.label<ContainerProps>`
  padding: 8px 8px 0px 8px;
  border: ${(props) =>
    props.checked ? "2px solid var(--color-black)" : "1px solid var(--color-gray-600)"};
  background-color: var(--color-gray-100);
  display: block;
  margin-bottom: 10px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  ${(props) => props.disabled && "opacity: 0.6;"}
`;

type CheckboxProps = {
  id: string;
  value?: string;
  name: string;
  label?: ReactNode;
  disabled?: boolean;
  alternative?: boolean;
  checked?: boolean;
  style?: CSSProperties;
  className?: string;
  defaultChecked?: boolean;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ alternative, ...props }, ref) => {
    return alternative ? (
      <AlternativeCheckboxContainer
        checked={props.checked}
        disabled={props.disabled}
        className={props.className}
      >
        <Form.Check custom type="checkbox" {...props} ref={ref} />
      </AlternativeCheckboxContainer>
    ) : (
      <Form.Check custom type="checkbox" {...props}>
        <Form.Check.Input type="checkbox" {...props} ref={ref} />
        {props.label && (
          <Form.Check.Label
            style={{ fontFamily: `var(${props.checked ? "--font-heavy" : "--font-light"})` }}
          >
            {props.label}
          </Form.Check.Label>
        )}
      </Form.Check>
    );
  }
);

export default Checkbox;
