import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

type LastEditProps = {
  dateStr: string;
  editorName?: string;
  align?: "left" | "right";
};

const LastEdit = ({ dateStr, editorName, align = "right" }: LastEditProps) => {
  const { t } = useTranslation();

  return (
    <div className={`m-0 color-gray-600 text-${align}`}>
      {t("viewProduct.lastEdit")}
      {moment(dateStr).format("D.M.YYYY HH:mm")}
      {editorName && (
        <>
          {", "}
          {editorName}
        </>
      )}
    </div>
  );
};

export default LastEdit;
