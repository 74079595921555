import { gql } from "@apollo/client";

export const getProductsWithSocialIdQuery = gql`
  query GetProductsWithSocialIAggregate($socialMediaId: uuid!) {
    productAggregate(where: { socialMediaId: { _eq: $socialMediaId } }) {
      aggregate {
        count
      }
    }
  }
`;

export type GetProductsWithSocialIdVars = {
  socialMediaId: string;
};

export type GetProductsWithSocialIdResult = {
  productAggregate: {
    aggregate: {
      count: number;
    };
  };
};
