import { Button, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { DataHubImage } from "../../types";
import ImageModal from "../ImageModal";
import Thumbnail from "../Thumbnail";
import { useIsMediumOrSmaller } from "../MediaQuery";

type ViewProductImagesProps = {
  images: DataHubImage[];
  openCoverImage?: DataHubImage;
  onModalClose: () => void;
};

const ViewProductImages = ({ images, openCoverImage, onModalClose }: ViewProductImagesProps) => {
  const isMediumOrSmaller = useIsMediumOrSmaller();
  const [viewImage, setViewImage] = useState<DataHubImage | null>();
  const THUMBNAIL_LENGTH = 10;

  useEffect(() => {
    if (openCoverImage) {
      setViewImage(openCoverImage);
    }
  }, [openCoverImage]);

  if (!images) {
    return null;
  }

  const ThumbnailItem = ({ image }: { image: DataHubImage }) => (
    <Thumbnail
      image={image}
      showDelete={false}
      altText={image.altText ?? ""}
      listItemStyle
      size={"x-small"}
      className="mr-2 mb-2"
      onClick={() => setViewImage(image)}
    />
  );

  const slicedImages = images.slice(0, THUMBNAIL_LENGTH);

  return (
    <>
      {!isMediumOrSmaller && (
        <Row className="mx-0 pb-2">
          {images.length > THUMBNAIL_LENGTH ? (
            <>
              {slicedImages.map((img, i) => (
                <ThumbnailItem image={img} key={i} />
              ))}
              <Button
                className="my-auto color-primary-dark text-larger"
                variant="ghost"
                onClick={() => setViewImage(images[0])}
              >
                + {images.length - THUMBNAIL_LENGTH}
              </Button>
            </>
          ) : (
            <>
              {images.map((img, i) => (
                <ThumbnailItem key={i} image={img} />
              ))}
            </>
          )}
        </Row>
      )}
      {viewImage && (
        <ImageModal
          images={images}
          viewImage={viewImage}
          onHide={() => {
            setViewImage(null);
            onModalClose();
          }}
        />
      )}
    </>
  );
};

export default ViewProductImages;
