import { CurationStatus, ProductType } from "../types";

import { BaseCompanyData } from "../types/companyTypes";
import { gql } from "@apollo/client";

const getPublishedProductsForDMO = gql`
    query getPublishedProductsForDMO($companyId: uuid, $cities: [uuid!]) {
      company(
        where: { products: { postalAddresses: { postalArea: { city: { id: { _in: $cities } } } } } }
      ) {
        id
        businessName
      }

      product(
        where: {
          postalAddresses: { postalArea: { city: { id: { _in: $cities } } } }
          productCurations: { curationStatus: { _eq: ${CurationStatus.Approved} } }
        }
      ) {
        id
        type
      }
    }
  `;

export type BaseCompanyDataWithProductsVars = {
  companyId: string;
  cities: string[];
};

export interface BaseCompanyDataWithProducts {
  company: BaseCompanyData[];
  product: {
    id: string;
    type: ProductType;
  }[];
}

export default getPublishedProductsForDMO;
