import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import getProductsWithBusinessHoursId, {
  GetProductsWithBusinessHoursIdResult,
  GetProductsWithBusinessHoursIdVariables,
} from "../../graphqlQueries/getProductsWithBusinessHoursId";
import { CompanyInformationValues, UserRole } from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";
import Checkbox from "../Checkbox";
import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";
import Notification from "../Notification";
import { OpeningHoursContainer } from "../productForm/OpeningHoursContainer";
import { OpeningHoursExceptionsContainer } from "../productForm/OpeningHoursExceptionsContainer";
import Separator from "../Separator";

const BusinessHoursSection = () => {
  const { register, watch } = useFormContext<CompanyInformationValues>();
  const { t } = useTranslation();

  const businessHoursId = watch("businessHoursId", "");
  const businessHoursEnabled = watch("businessHoursEnabled", false);

  const [getProducts, productsResult] = useLazyQuery<
    GetProductsWithBusinessHoursIdResult,
    GetProductsWithBusinessHoursIdVariables
  >(getProductsWithBusinessHoursId, {
    context: getHasuraRoleContext(UserRole.ManageProducts),
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (businessHoursId) {
      getProducts({ variables: { businessHoursId } });
    }
  }, [businessHoursId, getProducts]);

  const anyProductsUsingCompanyHours = (productsResult.data?.product ?? []).length > 0;

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader data-cy="company-business-hours">
          {t("companyInfo.businessHoursHeader")}
        </FormSectionHeader>
        <p>{t("companyInfo.businessHoursDescription")}</p>
      </FormDescription>
      <FormSectionContent>
        <Checkbox
          id={`business-hours-enabled`}
          defaultChecked={false}
          ref={register()}
          name={"businessHoursEnabled"}
          label={t("companyInfo.businessHoursEnableButton")}
          disabled={anyProductsUsingCompanyHours}
        />
        {anyProductsUsingCompanyHours && (
          <Notification type="info-alt" showIcon iconSize="medium">
            {t("companyInfo.businessHoursProductsNotification")}
          </Notification>
        )}
        <Form.Control
          id={"business-hours-id"}
          name={"businessHoursId"}
          type="hidden"
          defaultValue={businessHoursId}
          ref={register()}
          aria-hidden
        />
        <Form.Group>
          <Form.Label>{t("companyInfo.openingHoursLabel")}</Form.Label>
          <OpeningHoursContainer
            name="openingHours"
            valuesRequired={businessHoursEnabled}
            inputsDisabled={!businessHoursEnabled}
          />
        </Form.Group>
        <Separator />
        <Form.Group>
          <OpeningHoursExceptionsContainer
            headerKey="companyInfo.openingHoursExceptionsLabel"
            valuesRequired
            inputsDisabled={!businessHoursEnabled}
          />
        </Form.Group>
      </FormSectionContent>
    </FormSection>
  );
};

export default BusinessHoursSection;
