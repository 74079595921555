import { MutationTuple, useMutation } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DeleteBulletinResult, DeleteBulletinVariables } from "../../graphqlQueries/deleteBulletin";
import { Bulletin } from "../../graphqlQueries/getBulletin";
import {
  InsertBulletinResult,
  InsertBulletinVariables,
  insertBulletinOne,
} from "../../graphqlQueries/insertBulletin";
import {
  UpdateBulletinResult,
  UpdateBulletinVariables,
  updateBulletinMutation,
} from "../../graphqlQueries/updateBulletin";
import { BulletinTargetGroup, PopoverStateType, UserRole } from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";
import ConfirmButton from "../ConfirmButton";
import BulletinFormNewsSection from "./BulletinFormNewsSection";
import BulletinFormTargetGroupSection from "./BulletinFormTargetGroupSection";

type BulletinFormData = {
  titleEn: string;
  titleFi: string;
  contentEn: string;
  contentFi: string;
  targetGroups: BulletinTargetGroup[];
};

type CreateBulletinProps = {
  onSubmitForm: () => void;
  bulletin?: Bulletin;
  setError: (error: string | null) => void;
  deleteMutation?: MutationTuple<DeleteBulletinResult, DeleteBulletinVariables>;
};

const mapDbBulletinToForm = (bulletin: Bulletin) => {
  const { titleEn, titleFi, contentEn, contentFi, bulletinTargetGroups } = bulletin;
  const targetGroups = bulletinTargetGroups.map((tg) => tg.targetGroup);
  return {
    titleEn,
    titleFi,
    contentEn,
    contentFi,
    targetGroups,
  };
};

const DELETE_ID = "delete-confirm";
const CANCEL_ID = "cancel-confirm";

const BulletinForm = ({
  onSubmitForm,
  bulletin,
  deleteMutation,
  setError,
}: CreateBulletinProps) => {
  const { t } = useTranslation();
  const [openPopovers, setOpenPopovers] = useState<PopoverStateType[]>([
    { id: DELETE_ID, isOpen: false },
    { id: CANCEL_ID, isOpen: false },
  ]);
  // Set popover open if id matches, else close
  const changePopOver = (popoverId: string) =>
    setOpenPopovers((prevState) => prevState.map(({ id }) => ({ id, isOpen: id === popoverId })));
  // Close all
  const closePopovers = () =>
    setOpenPopovers((prevState) => prevState.map(({ id }) => ({ id, isOpen: false })));
  // If clicked popover is already open close all, otherwise open it and close others.
  const handlePopoverButtonClick = (popoverId: string) => {
    openPopovers.find(({ id }) => id === popoverId)?.isOpen
      ? closePopovers()
      : changePopOver(popoverId);
  };

  const showPopover = (popoverId: string) =>
    !!openPopovers.find(({ id, isOpen }) => id === popoverId && isOpen);

  const deleteAction = deleteMutation?.[0];
  const hookFormMethods = useForm();
  const { handleSubmit, reset, watch, formState } = hookFormMethods;

  const [createBulletin, { loading }] = useMutation<InsertBulletinResult, InsertBulletinVariables>(
    insertBulletinOne
  );

  const [updateBulletin, { loading: loadingUpdate }] = useMutation<
    UpdateBulletinResult,
    UpdateBulletinVariables
  >(updateBulletinMutation);

  const onSubmit = (data: BulletinFormData) => {
    const bulletinId = bulletin?.id;

    if (!bulletinId) {
      const newTargetGoup = data.targetGroups.map((group) => {
        return { targetGroup: group };
      });

      createBulletin({
        variables: { ...data, targetGroups: newTargetGoup },
        context: getHasuraRoleContext(UserRole.ManageDatahub),
        refetchQueries: ["getBulletinsForTargetGroup"],
      })
        .then(() => {
          setError(null);
          onSubmitForm();
        })
        .catch((e) => {
          console.log(e);
          setError("dashboard.bulletinCreateError");
        });
    } else {
      const targetGroupWithIds = data.targetGroups.map((group) => {
        return { targetGroup: group, bulletinId: bulletinId };
      });

      updateBulletin({
        variables: { ...data, targetGroups: targetGroupWithIds, bulletinId },
        context: getHasuraRoleContext(UserRole.ManageDatahub),
        refetchQueries: ["getBulletinsForTargetGroup"],
      })
        .then(() => {
          setError(null);
          onSubmitForm();
        })
        .catch((e) => {
          console.log(e);
          setError("dashboard.bulletinCreateError");
        });
    }
  };

  useEffect(() => {
    if (bulletin) {
      const formValues = mapDbBulletinToForm(bulletin);
      reset(formValues);
    } else {
      reset();
    }
  }, [bulletin, reset]);

  const contentEn = watch("contentEn");
  const contentFi = watch("contentFi");

  return (
    <div className="px-sm-5 px-3 pt-1 pb-3">
      <FormProvider {...hookFormMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <BulletinFormTargetGroupSection />
          <div className="d-flex flex-column flex-sm-row">
            <div className="col px-0 pr-sm-2">
              <BulletinFormNewsSection language="Fi" />
            </div>
            <div className="col px-0 pl-sm-2">
              <BulletinFormNewsSection language="En" />
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row justify-content-end mb-1">
            {bulletin?.id && deleteAction && (
              <ConfirmButton
                id={DELETE_ID}
                showIcon
                confirmStyle="warning"
                headerContent={t("dashboard.deleteBulletinPopoverHeader")}
                popOverContent={
                  <p className="pb-3">{t("dashboard.deleteBulletinPopoverDescription")}</p>
                }
                confirmButtonContent={t("common.delete")}
                onConfirmClick={() => {
                  deleteAction({
                    variables: { bulletinId: bulletin.id, deletedAt: moment().toISOString() },
                  })
                    .then(() => onSubmitForm())
                    .catch(() => setError("dashboard.bulletinDeleteError"));
                }}
                triggerButtonContent={t("common.discardChanges")}
                renderTriggerButton={
                  <Button
                    variant="btn-light"
                    className="mr-sm-5 mb-3 mb-sm-0"
                    onClick={() => handlePopoverButtonClick(DELETE_ID)}
                  >
                    {t("common.delete")}
                  </Button>
                }
                closePopover={handlePopoverButtonClick}
                show={showPopover(DELETE_ID)}
              />
            )}
            {formState.isDirty ? (
              <ConfirmButton
                id={CANCEL_ID}
                confirmStyle="info"
                showIcon
                headerContent={t("dashboard.bulletinUnsavedChanges")}
                popOverContent={
                  <p className="pb-3">{t("dashboard.bulletinUnsavedChangesPopoverDescription")}</p>
                }
                confirmButtonContent={t("common.discardChanges")}
                onConfirmClick={() => {
                  reset();
                  onSubmitForm();
                }}
                triggerButtonContent={t("common.discardChanges")}
                renderTriggerButton={
                  <Button variant="btn-light" onClick={() => handlePopoverButtonClick(CANCEL_ID)}>
                    {t("common.cancel")}
                  </Button>
                }
                closePopover={handlePopoverButtonClick}
                show={showPopover(CANCEL_ID)}
              />
            ) : (
              <Button
                variant="btn-light"
                onClick={() => {
                  reset();
                  onSubmitForm();
                }}
              >
                {t("common.cancel")}
              </Button>
            )}
            <Button
              variant="primary"
              type="submit"
              className="ml-sm-4 mt-3 mt-sm-0"
              disabled={!contentEn && !contentFi}
            >
              <div className="d-flex align-items-center justify-content-center">
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                <div className={loading || loadingUpdate ? "ml-2" : ""}>
                  {t("dashboard.createBulletinButtonSend")}
                </div>
              </div>
            </Button>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default BulletinForm;
