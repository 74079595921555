import { ArrayField, useFieldArray, useFormContext } from "react-hook-form";
import { Button, Tab } from "react-bootstrap";
import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../../FormSection";
import { Language, ProductDetails, ProductPublishMode } from "../../../types";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import AddLanguageModal from "../AddLanguageModal";
import { DataHubPopOver } from "../../DataHubPopOver";
import { DatahubSelect } from "../../DatahubSelect";
import DummyLink from "../../DummyLink";
import ExternalLink from "../../ExternalLink";
import { LANGUAGES } from "../../../utils/constants";
import Notification from "../../Notification";
import ProductDescriptionLanguageTabs from "./ProductDescriptionLanguageTabs";
import ProductLanguageVersion from "./ProductLanguageVersion";
import TranslationInfoModal from "../../TranslationInfoModal";
import { breakpointPixels } from "../../../css/breakpoints";
import { capitalize } from "../../../utils/functions";
import styled from "styled-components";
import { useIsSmallScreen } from "../../MediaQuery";

export const MAX_FULL_LENGTH_LANGUAGES = 5;
const INITIAL_LANGUAGE: Language = "en";

const StyledTabsContainer = styled.div`
  margin-left: 33%;
  @media (max-width: ${breakpointPixels.xl}) {
    margin-left: 0;
  }
`;

const ProductDescriptionSection: React.FunctionComponent<{
  publishMode: ProductPublishMode;
  isProductGroup: boolean;
}> = ({ publishMode, isProductGroup }) => {
  const { t } = useTranslation();
  const [showLanguageModal, setShowLanguageModal] = useState<boolean>(false);
  const [showTranslationModal, setShowTranslationModal] = useState<boolean>(false);
  const [showLanguageDeletionPopover, setShowLanguageDeletionPopover] = useState<number | null>(
    null
  );
  const [notifications, setNotifications] = useState<Language[]>([]);
  const { errors, control, watch } = useFormContext();
  const { fields, append, insert, remove } = useFieldArray<ProductDetails>({
    control,
    name: "details",
  });
  const [activeLanguage, setActiveLanguage] = useState<Language | null>(null);
  const isMobile = useIsSmallScreen();
  const details: Partial<ArrayField<ProductDetails>>[] = watch("details");

  const availableLanguages: Language[] = LANGUAGES.filter(
    (lang) => !fields.find((f) => f.language === lang)
  );

  const initialRender = React.useRef(true);

  useEffect(() => {
    if (!activeLanguage) {
      setActiveLanguage(fields[0]?.language as Language);
    }
    if (initialRender.current && fields.length === 0) {
      append({
        language: (fields[0]?.language as Language) || INITIAL_LANGUAGE,
      });
      initialRender.current = false;
    }
    if (details && details.length > fields.length) {
      append(details);
    }
  }, [initialRender, fields, append, details, activeLanguage]);

  const addedLanguagesOptions = fields.map((field, i) => ({
    value: field.language,
    label: capitalize(t(`languages.${field.language}`)),
    hasError: errors.details?.[i],
  }));

  const onLanguageRemoval = (indexToRemove: number) => {
    // Array of fields with the removable index already removed to get the realistic
    // current index
    const fieldsWithIndexRemoved = fields.filter((_, i) => i !== indexToRemove);

    // Get the next or the previous index to display as active language if current active language
    // has been removed
    const getNextIndex = (): number => {
      if (fields[indexToRemove].language !== activeLanguage) {
        return fieldsWithIndexRemoved.findIndex((field) => field.language === activeLanguage);
      }

      // If index is 0, return the next in line. If >0, return the previous index
      return fieldsWithIndexRemoved.length > 0
        ? indexToRemove > 0
          ? fields.findIndex((_, i) => i === indexToRemove) - 1
          : 0
        : 0;
    };
    // Get the next active language from the list with the removed index filtered out
    const newActiveLanguage = fieldsWithIndexRemoved[getNextIndex()].language;

    remove(indexToRemove);
    setActiveLanguage(newActiveLanguage as Language);
    setShowLanguageDeletionPopover(null);
  };

  return (
    <>
      {!isMobile && (
        <StyledTabsContainer>
          <ProductDescriptionLanguageTabs
            activeLanguage={activeLanguage}
            setActiveLanguage={setActiveLanguage}
            setShowLanguageModal={setShowLanguageModal}
            setShowLanguageDeletionPopover={setShowLanguageDeletionPopover}
            fields={fields}
            showLanguageDeletionPopover={showLanguageDeletionPopover}
            onLanguageRemoval={(index: number) => onLanguageRemoval(index)}
            isEditing={true}
            isProductGroup={isProductGroup}
          />
        </StyledTabsContainer>
      )}
      <FormSection>
        <FormDescription>
          <FormSectionHeader>{t("productInfo.productDetailsHeader")}</FormSectionHeader>
          {isProductGroup ? (
            <p>{t("productInfo.editDetails")}</p>
          ) : (
            <div>
              <Trans i18nKey="productInfo.productDetailsDesc">
                It is recommended that your information is in line with
                <ExternalLink href={t("productInfo.productDetailsDescLink")}>
                  Visit Finland's internationalization criteria.
                </ExternalLink>
              </Trans>
              <p>
                <Trans i18nKey="productInfo.productDetailsDesc2">
                  You can create new language versions of your product by pressing
                  <i className="text-nowrap">
                    {{ addNewLanguage: t("productInfo.addNewLanguage") }}
                  </i>
                  .
                </Trans>
              </p>
              <p>{t("productInfo.productDetailsAddFinnish")}</p>
              <p>
                <DummyLink onClick={() => setShowTranslationModal(true)}>
                  {t("productInfo.translationDescription")}
                </DummyLink>
              </p>
            </div>
          )}
        </FormDescription>

        <FormSectionContent>
          {isMobile && !isProductGroup && (
            <>
              <div className="mb-4">
                <Button
                  className="mt-1 ml-2"
                  variant="ghost"
                  onClick={() => setShowLanguageModal(true)}
                >
                  {t("productInfo.addNewLanguage")}
                </Button>
              </div>
              <div className="pb-4">
                <DatahubSelect
                  options={addedLanguagesOptions}
                  value={addedLanguagesOptions.find(
                    (language) => language.value === activeLanguage
                  )}
                  onChange={(item) => {
                    if (!item) {
                      return;
                    }
                    setActiveLanguage(item.value as Language);
                  }}
                />
              </div>
            </>
          )}
          {activeLanguage &&
            details.map((field, i) => {
              const hasError = errors.details?.[i];

              if (field.language === activeLanguage) {
                return (
                  <Tab.Pane eventKey={field.language} key={i}>
                    <div className="px-3 d-flex justify-content-between flex-column w-100 pb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>{capitalize(t(`languages.${field.language}`))}</h5>
                        {hasError && (
                          <div className="ml-4 text-danger">{t("productInfo.missingDetails")}</div>
                        )}
                        {isMobile && (
                          <div>
                            <>
                              <DataHubPopOver
                                placement="bottom"
                                icon="warning-triangle"
                                header={t("productInfo.deleteLanguagePopoverHeader")}
                                onCancel={() => {
                                  setShowLanguageDeletionPopover(null);
                                }}
                                onConfirm={() => onLanguageRemoval(i)}
                                variant="confirmAndCancel"
                                show={!!showLanguageDeletionPopover}
                                triggerButton={
                                  <Button
                                    variant="ghost"
                                    onClick={() => {
                                      setShowLanguageDeletionPopover(i);
                                    }}
                                  >
                                    {t("common.delete")}
                                  </Button>
                                }
                              >
                                <div> {t("productInfo.deleteLanguagePopoverDescription")}</div>
                              </DataHubPopOver>
                            </>
                          </div>
                        )}
                      </div>
                      <div>
                        {t("productInfo.fillInLanguageDescription", {
                          language: t(`languages.${field.language}`),
                        })}
                      </div>
                    </div>
                    <div key={field.id} className="mb-3">
                      {field.language && notifications.includes(field.language) && (
                        <Notification
                          type="info"
                          className="m-0"
                          onClose={() => {
                            setNotifications(
                              notifications.filter((lang) => lang !== field.language)
                            );
                          }}
                        >
                          {t("productInfo.newLanguageAddedNotification")}
                        </Notification>
                      )}
                      <ProductLanguageVersion
                        fields={fields}
                        publishMode={publishMode}
                        isProductGroup={isProductGroup}
                        currentLanguage={activeLanguage}
                      />
                    </div>
                  </Tab.Pane>
                );
              }
              return null;
            })}
        </FormSectionContent>
      </FormSection>

      {showLanguageModal && (
        <AddLanguageModal
          onHide={() => setShowLanguageModal(false)}
          languages={availableLanguages}
          onAddLanguages={(selected) => {
            setShowLanguageModal(false);
            setNotifications([...notifications, ...selected]);
            insert(
              fields.length,
              selected.map((lang) => ({ language: lang }))
            );
          }}
        />
      )}

      {showTranslationModal && (
        <TranslationInfoModal
          onHide={() => {
            setShowTranslationModal(false);
          }}
        />
      )}
    </>
  );
};

export default ProductDescriptionSection;
