import React from "react";
import styled from "styled-components";
import { breakpointPixels } from "../css/breakpoints";

export const ToolbarContainer = styled.div`
  border-bottom: 1px solid var(--color-gray-400);
  background: var(--color-white);
  position: sticky;
  top: 0;
  z-index: 1001;
`;

const ToolbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem 0.75rem 0;
  @media (min-width: ${breakpointPixels.md}) {
    justify-content: flex-end;
  }
  @media (min-width: ${breakpointPixels.lg}) {
    padding-right: 4rem;
  }
`;

type ToolbarProps = {
  children: React.ReactNode;
  notification?: React.ReactNode;
};

const Toolbar = ({ children, notification }: ToolbarProps) => (
  <ToolbarContainer>
    <ToolbarContent>{children}</ToolbarContent>
    {notification && notification}
  </ToolbarContainer>
);

export default Toolbar;
