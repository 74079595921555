import React from "react";
import styled from "styled-components";

type ServiceHeaderProps = {
  children: React.ReactNode;
  sectionId: string;
};

const HeaderHighLighter = styled.div`
  width: 50px;
  height: 6px;
  margin: 16px auto 12px auto;
  background-color: var(--color-primary);
`;

const ServiceHeader = ({ sectionId, children }: ServiceHeaderProps) => (
  <h4 className="pl-3 text-landing-h4" id={sectionId}>
    {children}
    <HeaderHighLighter />
  </h4>
);

export default ServiceHeader;
