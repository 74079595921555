import { gql } from "@apollo/client";

const deleteCompany = gql`
  mutation deleteCompany($id: uuid!) {
    updateCompanyByPk(pk_columns: { id: $id }, _set: { deletedAt: "now()" }) {
      id
    }
    updatePostalAddress(where: { companyId: { _eq: $id } }, _set: { deletedAt: "now()" }) {
      returning {
        id
      }
    }
    updateContactDetails(where: { companyId: { _eq: $id } }, _set: { deletedAt: "now()" }) {
      returning {
        id
      }
    }
    updateDmoArea(where: { companyId: { _eq: $id } }, _set: { deletedAt: "now()" }) {
      returning {
        id
      }
    }
  }
`;

export type DeleteCompanyVariables = {
  id: string;
};

export type DeleteCompanyMutationResult = {
  updateCompanyByPk: {
    id: string;
  };
  updatePostalAddress: {
    returning: {
      id: string;
    };
  };
  updateContactDetails: {
    returning: {
      id: string;
    };
  };
  updateDmoArea: {
    returning: {
      id: string;
    };
  };
};

export default deleteCompany;
