import { gql } from "@apollo/client";

import { BulletinTargetGroup } from "../types";

export const buildBulletinQuery = (language: string, targetGroup?: BulletinTargetGroup) => {
  const languageSelectorClause = language === "en" ? "titleEn" : "titleFi";

  const getBulletinsForTargetGroup = gql`
  query getBulletinsForTargetGroup($limit: Int, $offset: Int) {
   ${
     targetGroup
       ? `bulletin(
            where: {_and: { bulletinTargetGroups: { targetGroup: { _eq: ${targetGroup} } } ${languageSelectorClause}: {_neq: ""} }}
            limit: $limit,
            offset: $offset,
            order_by: {createdAt: desc})`
       : `bulletin(
            where: {${languageSelectorClause}: {_neq: ""} }
            limit: $limit,
            offset: $offset,
            order_by: {createdAt: desc})`
   } 
    {
      id
      titleEn
      titleFi
      contentEn
      contentFi
      createdAt
      updatedAt
      bulletinTargetGroups {
       id
        targetGroup
      }
    }
    ${
      targetGroup
        ? `bulletinAggregate(
          where: {_and: { bulletinTargetGroups: { targetGroup: { _eq: ${targetGroup} } } ${languageSelectorClause}: {_neq: ""} }} )`
        : `bulletinAggregate(
          where: {${languageSelectorClause}: {_neq: ""} }
        )`
    } {
      aggregate {
        count
      }
    }
  }
`;

  return getBulletinsForTargetGroup;
};

export type BulletinQueryVariables = {
  limit?: number;
  offset?: number;
};

export type Bulletin = {
  id: string;
  titleEn: string;
  titleFi: string;
  contentEn: string;
  contentFi: string;
  createdAt: string;
  updatedAt: string;
  bulletinTargetGroups: {
    id: string;
    targetGroup: string;
  }[];
};

export type BulletinQueryResponse = {
  bulletin: Bulletin[];
  bulletinAggregate: { aggregate: { count: number } };
};
