import {
  ExternalProduct,
  ImportStepCard,
  bokunImportInfoSteps,
  johkuImportInfoSteps,
} from "../../utils/productFormUtils/productUtils";
import React, { useState } from "react";

import DummyLink from "../DummyLink";
import Icon from "../Icon";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type ImportGuideProps = {
  externalProductType: ExternalProduct;
};

const ImportGuide = ({ externalProductType }: ImportGuideProps) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const importInfoSteps =
    externalProductType === ExternalProduct.Bokun ? bokunImportInfoSteps : johkuImportInfoSteps;

  const importGuideTexts =
    externalProductType === ExternalProduct.Bokun
      ? {
          importProductButton: "importProduct.bokunButton",
          importProductHeader: "importProduct.bokunGuideHeader",
          importNote1: "importProduct.bokunNoteText1",
          importNote2: "importProduct.bokunNoteText2",
          importNote3: "importProduct.bokunNoteText3",
        }
      : {
          importProductButton: "importProduct.johkuButton",
          importProductHeader: "importProduct.johkuGuideHeader",
          importNote1: "importProduct.johkuNoteText1",
          importNote2: "importProduct.johkuNoteText2",
          importNote3: "importProduct.johkuNoteText3",
        };

  return (
    <div className="text-center">
      <DummyLink className="text-center" onClick={() => setShow(true)}>
        {t(importGuideTexts.importProductButton)}
      </DummyLink>
      <Modal show={show} animation={false} onHide={() => setShow(false)} centered size="lg">
        <Modal.Header closeButton={true}>
          <h3>{t(importGuideTexts.importProductHeader)}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="px-5 mx-5 text-left">
            {importInfoSteps.map((step: ImportStepCard) => (
              <div key={step.title} className="mb-4 d-flex flex-column">
                <Icon
                  name={step.icon}
                  style={step.width ? { width: step.width } : undefined}
                  className="align-self-center"
                />
                <p className="bold-text mt-3 mb-1">{t(step.title)}</p>
                <p className="color-gray-600 text-small mt-0">{t(step.description)}</p>
                {step.picture && (
                  <div>
                    <Icon name={step.picture.src} />
                    <p className="color-gray-600 text-small">{t(step.picture.text)}</p>
                  </div>
                )}
              </div>
            ))}
            <p className="color-primary-dark bold-text mb-3 mt-5 text-left">{t("common.note")}</p>
            <p className="color-gray-600 text-left text-small">{t(importGuideTexts.importNote1)}</p>
            <p className="color-gray-600 text-left text-small">{t(importGuideTexts.importNote2)}</p>
            <p className="color-gray-600 text-left text-small">{t(importGuideTexts.importNote3)}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImportGuide;
