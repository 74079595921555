import moment from "moment";
import React from "react";
import styled from "styled-components";

import { breakpointPixels } from "../../css/breakpoints";
import { Bulletin } from "../../graphqlQueries/getBulletin";

type BulletinNewsProps = {
  bulletin: Bulletin;
  language: string;
  withoutTitle?: boolean;
};

const Container = styled.div`
  height: 100%;
  padding: 1.9rem 1rem 0 1.5rem;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: ${breakpointPixels.md}) {
    padding: 0.25rem 1rem;
    border-top: none;
    overflow-y: hidden;
  }

  &&::-webkit-scrollbar {
    background-color: var(--color-gray-300);
  }

  &&::-webkit-scrollbar-track {
    background-color: var(--color-gray-300);
  }

  &&::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: var(--white);
    border-radius: 1px;
  }
`;

const ViewBulletin = ({ bulletin, language, withoutTitle }: BulletinNewsProps) => (
  <Container className="pt-2">
    {!withoutTitle && (
      <>
        <p className="text-small color-gray-600 my-2">
          {moment(bulletin?.createdAt).format("D.M.YYYY")}
        </p>
        <h3 className="h4-size">{language === "en" ? bulletin?.titleEn : bulletin?.titleFi}</h3>
      </>
    )}
    <p style={{ minHeight: 200, overflowWrap: "anywhere", whiteSpace: "pre-line" }}>
      {language === "en" ? bulletin?.contentEn : bulletin?.contentFi}
    </p>
  </Container>
);

export default ViewBulletin;
