import { FormDescription, FormSection, FormSectionHeader } from "../FormSection";

import ImageSection from "./ImageSection";
import { ProductPublishMode } from "../../types";
import React from "react";
import VideoSection from "./VideoSection";
import { useTranslation } from "react-i18next";

type ImageSectionProps = {
  publishMode: ProductPublishMode;
  isProductGroup: boolean;
};

const ImageAndVideoSection = ({ publishMode, isProductGroup }: ImageSectionProps) => {
  const { t } = useTranslation();
  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader>{t("productInfo.imageAndVideoSectionHeader")}</FormSectionHeader>
      </FormDescription>
      <div className="border-left" />
      <ImageSection publishMode={publishMode} isProductGroup={isProductGroup} />
      <VideoSection publishMode={publishMode} isProductGroup={isProductGroup} />
    </FormSection>
  );
};

export default ImageAndVideoSection;
