import { Button } from "react-bootstrap";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddCompanyButton = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Button
      className="font-heavy m-3 btn-light"
      onClick={() => {
        history.push("/signup");
      }}
    >
      {t("common.addCompany")}
    </Button>
  );
};

export default AddCompanyButton;
