import React from "react";
import { default as c } from "classnames";
import styled from "styled-components";
import TimeField from "./TimeField";

/**
 * Notes: Number input type doesn't allow leading zeros for padding so text input
 * is applied instead of a native number input, but to force mobile to use numpads
 * we're using the pattern attribute.
 */

const StyledTimePickerContainer = styled.div`
  border: 1px solid;
  max-width: 4.5em;
  background: var(--white);

  &.disabled {
    border: 0;
    color: var(--color-gray-600);
    background-color: var(--color-gray-200);
  }

  &.is-invalid {
    background-image: none;
    padding-right: 0.75rem;
  }

  input[type="text"] {
    border: 0;
    padding: 0;
    width: inherit;
    height: fit-content;
    background: transparent;

    &:disabled {
      color: var(--color-gray-600);
    }

    ::selection,
    &:focus-visible {
      background: var(--color-primary-dark);
      color: var(--color-white);
    }

    &:focus-visible {
      outline: 0;
    }
  }
`;

type TimePickerProps = {
  disabled?: boolean;
  initialTime?: string;
  hasErrors?: boolean;
  isClosing?: boolean;
  onBlur: (value: string | undefined) => void;
};

const MAX_H2 = {
  opening: 23,
  closing: 24,
};

export const TimePicker: React.FunctionComponent<TimePickerProps> = ({
  disabled,
  initialTime,
  hasErrors,
  isClosing,
  onBlur,
}) => {
  return (
    <StyledTimePickerContainer
      className={c("d-flex flex-col form-control", {
        disabled: disabled,
        "is-invalid": hasErrors,
      })}
    >
      <TimeField
        disabled={disabled}
        value={initialTime}
        onBlur={onBlur}
        maxHour={isClosing ? MAX_H2.closing : MAX_H2.opening}
      />
    </StyledTimePickerContainer>
  );
};
