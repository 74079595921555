import React, { useEffect, useState } from "react";
import { Form, InputGroup, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  HeaderGroup,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";

import { ColumnList, CompanyList, TableEllipsis } from "../../types/companyTypes";
import { SEARCH_DEBOUNCE_MS } from "../../utils/constants";
import Icon from "../Icon";
import { TABLE_PAGE_SIZE } from "../../utils/pagingUtils";
import { CustomPagination } from "../CustomPagination";
import { capitalize } from "../../utils/functions";

const compareIgnoreCase = (val1 = "", val2 = "") => {
  if (!val1 || !val2) {
    return val1 ? 1 : -1;
  }
  return val1.toString().toLowerCase().localeCompare(val2.toString().toLowerCase());
};

const StyledTable = styled(Table)`
  margin-bottom: 0;
  min-width: 65rem;
  overflow: hidden;
  tbody:nth-child(2) > tr:nth-child(2n) {
    background-color: var(--color-white);
  }

  tbody:nth-child(2) > tr:nth-child(2n + 1) {
    background-color: var(--color-gray-100);
  }
`;

type CompanyTableProps = {
  companyData: CompanyList[];
  columns: ColumnList[];
  renderEllipsisComponent?: (data: TableEllipsis) => JSX.Element;
};

const CompanyTable = ({ companyData, columns, renderEllipsisComponent }: CompanyTableProps) => {
  const { t } = useTranslation();

  const onSearchChange = useAsyncDebounce((value: string) => {
    setGlobalFilter(value);
  }, SEARCH_DEBOUNCE_MS);

  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    onSearchChange(searchValue);
  }, [searchValue, onSearchChange]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    footerGroups,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable<CompanyList>(
    {
      columns,
      data: companyData,
      initialState: {
        pageIndex: 0,
        pageSize: TABLE_PAGE_SIZE,
        sortBy: [
          {
            id: "businessName",
            desc: false,
          },
        ],
      },
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          if (columnName === "productCount") {
            return row1.values[columnName] - row2.values[columnName];
          }
          if (columnName === "contactDetails") {
            // contactDetails come as an array
            return compareIgnoreCase(row1.values[columnName][0], row2.values[columnName][0]);
          }

          return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
        },
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const headerSort = ({ isSorted, isSortedDesc }: HeaderGroup<CompanyList>) => {
    if (!isSorted) {
      return (
        <>
          <Icon name="chevron-up" />
          <Icon name="chevron-down" />
        </>
      );
    }

    if (isSortedDesc) {
      return (
        <>
          <Icon name="chevron-up" />
          <Icon color="primary" name="chevron-down" />
        </>
      );
    }

    return (
      <>
        <Icon color="primary" name="chevron-up" />
        <Icon name="chevron-down" />
      </>
    );
  };

  return (
    <>
      <div className="row w-75">
        <Form.Group
          controlId="company-search"
          className="d-flex col-12 col-md-5 pr-0 align-items-center mb-4"
        >
          <div>
            <Form.Label className="m-0 mr-3">{t("companyTable.searchLabel")}</Form.Label>
          </div>

          <InputGroup>
            <Form.Control
              type="search"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder={t("companyTable.searchPlaceholder", {
                count: preGlobalFilteredRows.length,
              })}
              value={searchValue}
            />
          </InputGroup>
        </Form.Group>
        <div className="col-12 col-md-6 p-md-0 pb-3 px-0 mt-2 ml-3">
          {footerGroups.map((group) => (
            <span {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <span {...column.getFooterProps()}>{column.render("Footer")}</span>
              ))}
            </span>
          ))}
        </div>
      </div>
      <StyledTable striped hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr className="bg-white" {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => {
                if (column.id === "ellipsis") {
                  return <th key={index} />;
                }

                return (
                  <OverlayTrigger
                    key={index}
                    placement="bottom-end"
                    overlay={
                      <Tooltip id="table-column-tooltip" className="pt-2">
                        {t(`companyTable.columnTooltip${capitalize(column.id)}`)}
                      </Tooltip>
                    }
                  >
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span className="ml-1">{headerSort(column)}</span>
                    </th>
                  </OverlayTrigger>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length === 0 && (
            <tr className="bg-white">
              <td colSpan={6}>{t("companyTable.noResults")}</td>
            </tr>
          )}
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  const title = cell.column.id as string;
                  const isEllipsis = title === "ellipsis";

                  return (
                    <React.Fragment key={index}>
                      {isEllipsis && renderEllipsisComponent ? (
                        <td className="p-0" style={{ verticalAlign: "middle" }}>
                          {renderEllipsisComponent(row.values.ellipsis)}
                        </td>
                      ) : (
                        <td
                          style={{
                            width: title === "businessName" ? "20%" : undefined,
                            verticalAlign: "middle",
                          }}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={7}>
              <CustomPagination
                pageAmount={pageSize}
                pageShowAmountOption={(arg) => setPageSize(arg.value)}
                pageIndicator={t("companyTable.pageIndicator", {
                  pageIndex: pageIndex + 1,
                  pageCount,
                })}
                toFirstPage={() => gotoPage(0)}
                toPreviousPage={previousPage}
                toNextPage={nextPage}
                toLastPage={() => gotoPage(pageCount - 1)}
                disableNext={!canNextPage}
                disablePrevious={!canPreviousPage}
              />
            </th>
          </tr>
        </tfoot>
      </StyledTable>
    </>
  );
};

export default CompanyTable;
