import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import { ExternalProductPreview } from "../../types";
import ImportProductCard from "./ImportProductCard";
import { useTranslation } from "react-i18next";
import IconInput from "../IconInput";
import Icon from "../Icon";

type ExternalProductListProps = {
  productList: ExternalProductPreview[];
  setNextDisabled: (arg: boolean) => void;
  productsToImport: (data: string[]) => void;
};

const ExternalProductList = ({
  productList,
  setNextDisabled,
  productsToImport,
}: ExternalProductListProps) => {
  const { t } = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");
  const filteredProducts = productList.filter((p) =>
    p.name.toUpperCase().includes(search.toUpperCase())
  );

  useEffect(() => {
    productsToImport(selectedProducts);

    if (selectedProducts.length === 0) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [productsToImport, selectedProducts, setNextDisabled]);

  const onSelectAllClick = () => {
    if (!selectedProducts.length) {
      setSelectedProducts(filteredProducts.map((product) => product.id));
    } else {
      setSelectedProducts([]);
    }
  };

  const handleProductCardClick = (productId: string) => {
    const copySelected = [...selectedProducts];
    const productIdIndex = copySelected.findIndex((selected) => selected === productId);
    if (productIdIndex > -1) {
      copySelected.splice(productIdIndex, 1);
      setSelectedProducts(copySelected);
    } else {
      copySelected.push(productId);
      setSelectedProducts(copySelected);
    }
  };

  return (
    <>
      <div className="align-self-start w-50 mt-4">
        <IconInput
          icon={<Icon name="search" size="medium" style={{ transform: "rotateY(180deg)" }} />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onClear={search ? () => setSearch("") : undefined}
          value={search}
          placeholder={t("importProduct.searchPlaceholder", {
            count: productList.length,
          })}
          iconPosition="left"
        />
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center w-100 mt-3">
        <div className="d-flex flex-row">
          <div>
            {t("importProduct.totalProductsFoundLong")}
            <strong className="font-heavy mr-3">{` ${productList.length} ${t(
              "common.pieces"
            )}`}</strong>
          </div>
          {selectedProducts.length > 0 && (
            <div>
              {t("importProduct.productsSelectedCount")}
              <strong className="color-primary-dark font-heavy">{` ${selectedProducts.length} ${t(
                "common.pieces"
              )}`}</strong>
            </div>
          )}
        </div>

        <Button onClick={() => onSelectAllClick()} className="btn-light">
          {selectedProducts.length ? t("common.unselectAll") : t("common.selectAll")}
        </Button>
      </div>
      <div
        className="row flex-sm-row flex-column px-n2 mt-3 align-content-stretch"
        style={{ maxHeight: "19rem", overflowY: "auto", width: "calc(100% + 0.5rem)" }}
      >
        {filteredProducts.map((product) => {
          const isSelected = selectedProducts.some((selected) => selected === product.id);

          return (
            <ImportProductCard
              key={product.id}
              item={product}
              selected={isSelected}
              onCardClick={() => handleProductCardClick(product.id)}
            />
          );
        })}
      </div>
    </>
  );
};

export default ExternalProductList;
