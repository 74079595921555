import { CuratorEditProductFormValues, ProductPublishMode } from "../../types";
import {
  HasuraCompanyInformationResult,
  SocialMedia,
} from "../../graphqlQueries/getSingleCompanyInfo";

import AvailabilitySection from "./AvailabilitySection";
import { Form } from "react-bootstrap";
import ImageAndVideoSection from "./ImageAndVideoSection";
import LocationAndContactDetailsSection from "./LocationAndContactDetailsSection";
import ProductDescriptionSection from "./productDescription/ProductDescriptionSection";
import ProductDetailsSection from "./ProductDetailsSection";
import ProductTypeAndCategorySection from "./ProductTypeAndCategorySection";
import React from "react";
import SocialMediaSection from "./SociaMediaSection";
import TargetGroupSection from "./TargetGroupSection";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormValues = CuratorEditProductFormValues;

type ProductFormProps = {
  toolbar: React.ReactNode;
  publishMode: ProductPublishMode;
  editMode: boolean;
  companyInfo?: HasuraCompanyInformationResult;
  companySocialMedia?: SocialMedia;
  onSubmit: (values: FormValues) => Promise<void>;
  id: string;
  lastEdit?: React.ReactNode;
  isModal?: boolean;
  isCurator?: boolean;
};

const ProductForm = ({
  toolbar,
  publishMode,
  editMode,
  companyInfo,
  companySocialMedia,
  onSubmit,
  id,
  lastEdit,
  isModal,
  isCurator,
}: ProductFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();
  const isProductGroup = publishMode === "product-group";

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id={id}>
      {!isModal && toolbar}
      <div className="p-3 px-lg-5 m-md-3 mx-lg-4">
        <div className="row">
          <div className="col-12 mb-3">
            <h1 className="mb-2">{t("productInfo.formHeaderNew")}</h1>
            <p className="mb-1">{t("productInfo.pageDescription")}</p>
            {!!lastEdit && lastEdit}
          </div>
        </div>
        <ProductDescriptionSection publishMode={publishMode} isProductGroup={isProductGroup} />
        <ProductTypeAndCategorySection
          editMode={editMode}
          publishMode={publishMode}
          isProductGroup={isProductGroup}
        />
        <ProductDetailsSection publishMode={publishMode} isProductGroup={isProductGroup} />
        <AvailabilitySection
          publishMode={publishMode}
          isProductGroup={isProductGroup}
          isCurator={isCurator}
          productCompanyBusinessHours={companyInfo?.businessHours}
        />
        <ImageAndVideoSection publishMode={publishMode} isProductGroup={isProductGroup} />
        <SocialMediaSection
          headerKey="productInfo.socialMediaHeader"
          descriptionKey="productInfo.socialMediaDescription"
          publishMode={publishMode}
          fieldName="productSocialMediaLinks"
          productCompanySocialMedias={companySocialMedia || companyInfo?.socialMedia}
          isProductGroup={isProductGroup}
        />
        <TargetGroupSection publishMode={publishMode} isProductGroup={isProductGroup} />
        <LocationAndContactDetailsSection
          companyAddress={companyInfo?.postalAddresses[0]}
          companyContactDetails={companyInfo?.contactDetails[0]}
          publishMode={publishMode}
          isProductGroup={isProductGroup}
        />
      </div>
      {isModal && toolbar}
    </Form>
  );
};

export default ProductForm;
