import React, { useMemo } from "react";
import CompanyTable from "./CompanyTable";
import PageHeader from "../PageHeader";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
import { ApolloError, useQuery } from "@apollo/client";
import { getHasuraRoleContext } from "../../utils/functions";
import { UserRole } from "../../types";
import { getCuratorAreaCompanies } from "../../graphqlQueries/getCuratorAreaCompanies";
import { ColumnList, CompanyList, GetCuratorAreaCompanies } from "../../types/companyTypes";
import Notification from "../Notification";
import { composeCompanyListingData, composeTableColumns } from "../../utils/tableUtils";
import { HasuradbDmoArea } from "../../graphqlQueries/getCuratorAreas";
import { getAllCuratorAreaCompanies } from "../../graphqlQueries/getAllCuratorAreaCompanies";

type CompanyTableProps = {
  error: ApolloError | undefined;
  loading: boolean;
  data: CompanyList[];
  columns: ColumnList[];
};

const CompanyTables = ({ dmoAreas }: { dmoAreas: HasuradbDmoArea[] }) => {
  const { t } = useTranslation();
  const curatorAreas: string[] = dmoAreas.map((area) => area.curationArea.id);

  const {
    data: areaCompanies,
    loading: areaCompaniesLoading,
    error: areaCompaniesError,
  } = useQuery<GetCuratorAreaCompanies>(getCuratorAreaCompanies, {
    variables: {
      curatorAreas: curatorAreas,
    },
    context: getHasuraRoleContext(UserRole.ManageCuration),
    fetchPolicy: "cache-and-network",
  });

  const {
    data: allAreaCompanies,
    loading: allAreaCompaniesLoading,
    error: allCompaniesError,
  } = useQuery<GetCuratorAreaCompanies>(getAllCuratorAreaCompanies, {
    variables: {
      curatorAreas: curatorAreas,
    },
    context: getHasuraRoleContext(UserRole.ManageCuration),
    fetchPolicy: "cache-and-network",
  });

  const companyData: CompanyList[] = useMemo(
    () => composeCompanyListingData(areaCompanies?.company || []),
    [areaCompanies]
  );
  const columns = useMemo(() => composeTableColumns(companyData, t), [companyData, t]);

  const noProductCompanyData: CompanyList[] = useMemo(
    () =>
      composeCompanyListingData(
        allAreaCompanies?.company.filter((it) => it.productsAggregate.aggregate.count === 0) || []
      ),
    [allAreaCompanies]
  );
  const noProductColumns = useMemo(() => composeTableColumns(noProductCompanyData, t), [
    noProductCompanyData,
    t,
  ]);

  const TableView = ({ error, loading, data, columns }: CompanyTableProps) => {
    if (error) {
      return <Notification type="danger">{t("dashboard.loadingCompaniesFailed")}</Notification>;
    }

    if (loading) {
      return (
        <div>
          <PageHeader>{t("dashboard.headerVFAdmin")}</PageHeader>
          <Loading i18nKey="dashboard.loadingCompanyData" />
        </div>
      );
    }

    return <CompanyTable companyData={data} columns={columns} />;
  };

  return (
    <>
      <div className="mt-5 ml-3">
        <div className="mb-4">
          <h2 data-cy="companies-table">{t("companies.areaCompanies")}</h2>
          <p className="col col-md-6 p-0 pr-4 pr-md-0">{t("companies.bodyText")}</p>
        </div>
        <TableView
          error={areaCompaniesError}
          loading={areaCompaniesLoading}
          data={companyData}
          columns={columns}
        />
      </div>
      <div className="my-5 ml-3">
        <div className="mb-4">
          <h2 data-cy="companies-table-no-product">{t("companies.areaCompaniesNoProducts")}</h2>
        </div>
        <TableView
          error={allCompaniesError}
          loading={allAreaCompaniesLoading}
          data={noProductCompanyData}
          columns={noProductColumns}
        />
      </div>
    </>
  );
};

export default CompanyTables;
