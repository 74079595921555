import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BusinessHoursFormValues, OpeningHoursExceptionFormValues } from "../../types";
import { dayMonthYearFormat } from "../../utils/format";
import { Collapsible } from "../Collapsible";
import ConfirmButton from "../ConfirmButton";
import Icon from "../Icon";
import IconButton from "../IconButton";
import Separator from "../Separator";
import { OpeningHoursException } from "./OpeningHoursException";

type OpeningHoursExceptionsContainerProps = {
  headerKey: string;
  valuesRequired?: boolean;
  inputsDisabled?: boolean;
};

const OPENING_HOURS_EXCEPTIONS_FIELD = "openingHoursExceptions";

export const OpeningHoursExceptionsContainer = ({
  headerKey,
  valuesRequired,
  inputsDisabled,
}: OpeningHoursExceptionsContainerProps) => {
  const { errors, watch } = useFormContext<BusinessHoursFormValues>();
  const { fields, append, remove } = useFieldArray<OpeningHoursExceptionFormValues>({
    name: OPENING_HOURS_EXCEPTIONS_FIELD,
  });

  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const openingHoursExceptions = watch(OPENING_HOURS_EXCEPTIONS_FIELD);

  const getOpeningHoursPeriodHeader = (index: number) => {
    const header = t("openingHours.exceptions.header");
    const period = openingHoursExceptions?.[index]?.period;
    if (period) {
      const { startDate, endDate } = period;
      if (startDate && endDate) {
        return `${header} ${startDate.format(dayMonthYearFormat)} - ${endDate.format(
          dayMonthYearFormat
        )}`;
      }
    }
    return header;
  };

  return (
    <div>
      {fields.length > 0 && <Form.Label>{t(headerKey)}</Form.Label>}
      {fields.map((field, index) => {
        if (!field.period) {
          return null;
        }
        const exceptionName = `${OPENING_HOURS_EXCEPTIONS_FIELD}[${index}]`;
        const error = errors.openingHoursExceptions?.[index];
        return (
          <div key={field.id}>
            <Collapsible
              id={exceptionName}
              headerContainerClassName={
                error ? "is-invalid-border bg-light-primary border-primary-dark" : ""
              }
              header={<div className="px-3">{getOpeningHoursPeriodHeader(index)}</div>}
              headerSide={
                !inputsDisabled ? (
                  <ConfirmButton
                    id={`${exceptionName}-delete`}
                    headerContent={t("openingHours.exceptions.deletePeriodConfirmHeader")}
                    popOverContent={
                      <p>{t("openingHours.exceptions.deletePeriodConfirmContent")}</p>
                    }
                    popoverPlacement={"bottom-end"}
                    confirmStyle="warning"
                    showIcon
                    renderTriggerButton={
                      <IconButton
                        variant="ghost"
                        iconElement={<Icon name="trash" size="medium" />}
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        <span className="pl-1">{t("common.delete")}</span>
                      </IconButton>
                    }
                    confirmButtonContent={t("openingHours.exceptions.deletePeriodConfirmButton")}
                    onConfirmClick={() => remove(index)}
                    show={show}
                    closePopover={() => setShow(!show)}
                  />
                ) : undefined
              }
              defaultOpen={!field.period?.startDate}
            >
              <OpeningHoursException
                name={exceptionName}
                defaultPeriod={field.period}
                openingHours={field.openingHours}
                errors={error}
                required={valuesRequired}
                disabled={inputsDisabled}
              />
            </Collapsible>
            <Separator />
          </div>
        );
      })}

      {!inputsDisabled && (
        <Button
          variant="ghost"
          onClick={() => {
            append({
              period: { startDate: null, endDate: null },
            } as OpeningHoursExceptionFormValues);
          }}
        >
          {`+ ${t("openingHours.exceptions.newPeriodButton")}`}
        </Button>
      )}
    </div>
  );
};
