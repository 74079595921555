import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { breakpointPixels } from "../../css/breakpoints";
import Accordion from "react-bootstrap/Accordion";
import { Card } from "react-bootstrap";
import Icon from "../Icon";
import { useQuery } from "@apollo/client";
import { Logo, LogoData, LogoResponse } from "../../types/instructionsAndFaqTypes";
import { getLogos } from "../../graphqlQueries/InstructionsAndFaq/getLogos";
import Loading from "../Loading";

const Divider = styled.div`
  height: 1px;
  margin-top: 0.25rem;
  background-color: var(--color-primary);
`;

const LogoContainer = styled.div`
  width: 50%;
  align-self: center;
  height: 100px;
  @media (min-width: ${breakpointPixels.sm}) {
    width: 33.3%;
  }
  @media (min-width: ${breakpointPixels.md}) {
    width: 25%;
  }
  @media (min-width: ${breakpointPixels.lg}) {
    width: 20%;
  }
`;

const UserLogo = ({ logo, className = "" }: { logo: Logo; className?: string }) => (
  <LogoContainer className={`text-center mt-5 mt-md-4 p-3 ${className}`}>
    <a
      href={logo.url}
      target="_blank"
      rel="noopener noreferrer"
      className="d-flex h-100 align-items-center justify-content-center"
    >
      <img
        src={logo.logo?.url ?? logo.default?.url}
        alt={logo.name}
        width={logo.width}
        style={{ maxWidth: "100%" }}
      />
    </a>
  </LogoContainer>
);

const DataHubUserLogos = ({ isDashboard = false }) => {
  const { t, i18n } = useTranslation();
  const isFinnish = i18n.language === "fi";
  const [logos, setLogos] = useState<LogoData[]>([]);
  const mainLogos = logos.find((l) => l.rowNumber === 0);
  const rest = logos.filter((l) => l.rowNumber !== 0);

  const { loading, error } = useQuery<LogoResponse>(getLogos, {
    variables: {
      locale: [isFinnish ? "fi" : "en"],
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setLogos(data.channelLogos);
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return null;
  }

  if (isDashboard) {
    return (
      <div className="pt-5 pb-2 mx-auto col-11 col-md-10 col-lg-9 mb-3">
        <p className="text-center h4 font-heavy">{t("landing.dataHubIsUsedBy")}</p>
        <p className="text-center mb-0">{t("landing.dataHubSeeMore")}</p>
        <LogoRow title={t("landing.dataHubMore")} mainLogos={mainLogos} logos={rest} />
      </div>
    );
  }

  return (
    <div className="py-5 px-3">
      <h4 className="text-center text-landing-h4">{t("landing.dataHubIsUsedBy")}</h4>
      <p className="text-center mb-0">{t("landing.dataHubSeeMore")}</p>
      <Divider />
      <LogoRow title={t("landing.dataHubMore")} mainLogos={mainLogos} logos={rest} />
    </div>
  );
};

interface LogoRowProps {
  title: string;
  mainLogos?: LogoData;
  logos: LogoData[];
}

const classNames: Record<string, string> = {
  index0: "d-none d-sm-block d-md-none",
  index1: "d-block d-lg-none",
  main3: "d-block d-sm-none d-md-block",
  main4: "d-none d-lg-block",
};

const LogoRow = ({ title, mainLogos, logos }: LogoRowProps) => {
  const [open, setOpen] = useState<string>("");

  return (
    <div className="w-100">
      <div className="d-flex flex-wrap">
        {mainLogos?.logoRow.map((logo, index) => (
          <UserLogo key={logo.id} logo={logo} className={classNames[`main${index}`] ?? ""} />
        ))}
      </div>
      <Accordion activeKey={open}>
        <Card className="bg-transparent border-0">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            style={{ zIndex: 2 }}
            onClick={() => setOpen(open ? "" : "0")}
            className="bg-transparent border-0 p-0 cursor-pointer mt-3"
          >
            <p className={"h4 d-inline font-heavy"}>{title}</p>
            <Icon
              name="chevron-right"
              size="medium"
              style={{ transform: `rotate(${open ? "90" : "0"}deg)` }}
            />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="p-0" style={{ marginTop: -10 }}>
              <div className="d-flex flex-wrap">
                {[...logos]
                  .sort((a, b) => a.rowNumber - b.rowNumber)
                  .map((row) =>
                    row.logoRow.map((logo, index) => (
                      <UserLogo
                        key={logo.id}
                        logo={logo}
                        className={row.rowNumber === 1 ? classNames[`index${index}`] ?? "" : ""}
                      />
                    ))
                  )}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default DataHubUserLogos;
