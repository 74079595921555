import { gql } from "@apollo/client";

export const getRolePages = gql`
  query GetRolePages($locale: [Locale!]!) {
    rolePages(locales: $locale) {
      id
      title
      subText
      image(locales: en) {
        url
      }
      slug
      instructionsComponent(locales: $locale) {
        ... on SubTaskComponent {
          id
          subTitleInstructions {
            id
            title
          }
        }
      }
    }
  }
`;
