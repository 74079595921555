import { gql } from "@apollo/client";

export const getOtherRolePages = gql`
  query GetOtherRolePages($currentRolePage: ID!, $locale: [Locale!]!) {
    rolePages(where: { NOT: { id: $currentRolePage } }, locales: $locale) {
      id
      title
      slug
    }
  }
`;
