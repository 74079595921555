import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import deleteBusinessHoursMutation, {
  DeleteBusinessHoursResult,
  DeleteBusinessHoursVariables,
} from "../../graphqlQueries/deleteBusinessHours";
import {
  GetProductLinkIdsResult,
  GetProductLinkIdsVariables,
  getProductLinkIdsQuery,
} from "../../graphqlQueries/getProductLinkIds";
import insertBusinessHoursMutation, {
  InsertBusinessHoursResult,
  InsertBusinessHoursVariables,
} from "../../graphqlQueries/insertBusinessHours";
import updateOpeningHoursMutation, {
  UpdateOpeningHoursResult,
  UpdateOpeningHoursVariables,
} from "../../graphqlQueries/updateOpeningHours";
import buildUpdateProductMutation, {
  HasuraUpdateProductVariables,
  HasuraUpdateProductWithCommentVariables,
  UpdateProductResult,
} from "../../graphqlQueries/updateProduct";
import {
  CurationStatus,
  CuratorEditProductFormValues,
  DatahubType,
  MyProductFormValues,
  ProductCurationStatus,
  ProductPublishMode,
  ProductType,
  UserRole,
} from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";
import { getUpdateDraftVariables } from "../../utils/productFormUtils/mapDraftProductToHasura";
import {
  getUpdateOpeningHoursVariables,
  getUpdateProductVariables,
} from "../../utils/productFormUtils/mapProductToHasura";
import { getLinkIds } from "../../utils/productFormUtils/productUtils";

type UpdateProductOptions = {
  formValues: MyProductFormValues | CuratorEditProductFormValues;
  productId: string;
  companyId: string;
  publishMode: ProductPublishMode;
  productCurationStatus: ProductCurationStatus | null;
  curationStatus: CurationStatus | null;
  productType: ProductType;
  isCurator: boolean;
  comment?: {
    commenterCompanyId: string;
    commenterName: string;
    recipientRole: DatahubType;
  };
  socialMediaId: string;
};

const isCuratorProductFormValues = (
  formValues: MyProductFormValues | CuratorEditProductFormValues
): formValues is CuratorEditProductFormValues => {
  return (formValues as CuratorEditProductFormValues).curatorComment !== undefined;
};

const manageProductsContext = getHasuraRoleContext(UserRole.ManageProducts);
const manageCurationContext = getHasuraRoleContext(UserRole.ManageCuration);

export const useUpdateProduct = () => {
  const apollo = useApolloClient();

  const updateProduct = useCallback(
    async ({
      formValues,
      productId,
      companyId,
      publishMode,
      productCurationStatus,
      curationStatus,
      productType,
      isCurator,
      comment,
      socialMediaId,
    }: UpdateProductOptions) => {
      const roleContext = isCurator ? manageCurationContext : manageProductsContext;
      const getBasicUpdateVariables = () => {
        const baseUpdateVars = {
          productId,
          formValues: { ...formValues, socialMediaId },
          companyId,
        };

        if (publishMode === "draft") {
          return getUpdateDraftVariables(baseUpdateVars);
        }

        return getUpdateProductVariables({
          ...baseUpdateVars,
          productCurationStatus,
          curationStatus,
          isCurator,
        });
      };

      const getBusinessHoursId = async (businessHoursId: string) => {
        if (businessHoursId) {
          return businessHoursId;
        }
        const insertResult = await apollo.mutate<
          InsertBusinessHoursResult,
          InsertBusinessHoursVariables
        >({
          mutation: insertBusinessHoursMutation,
          variables: { companyId },
          context: roleContext,
        });
        return insertResult.data?.insertBusinessHoursOne.id ?? "";
      };

      //TODO: If link has not been changed, it should not be inserted and deleted
      const linkIds = (
        await apollo.query<GetProductLinkIdsResult, GetProductLinkIdsVariables>({
          query: getProductLinkIdsQuery,
          variables: { productId },
          context: roleContext,
        })
      ).data;
      const basicUpdateVars = getBasicUpdateVariables();
      const updateVars = {
        ...basicUpdateVars,
        verifiedLinks: linkIds
          ? getLinkIds(linkIds.productByPk.productInformations, linkIds.productByPk.productVideos)
          : [],
        businessHoursId: await getBusinessHoursId(basicUpdateVars.businessHoursId),
      };

      if (isCuratorProductFormValues(formValues) && formValues.curatorComment !== "") {
        if (!comment) {
          throw new Error("Trying to add comment without specifying comment parameters");
        }
        const updateVariables = {
          ...updateVars,
          commentText: formValues.curatorComment,
          commentCompanyId: comment.commenterCompanyId,
          commentUsername: comment.commenterName,
          commentRecipientRole: comment.recipientRole,
        };

        await apollo.mutate<UpdateProductResult, HasuraUpdateProductWithCommentVariables>({
          mutation: buildUpdateProductMutation({ addComment: true, type: productType }),
          variables: updateVariables,
          context: roleContext,
        });
      } else {
        await apollo.mutate<UpdateProductResult, HasuraUpdateProductVariables>({
          mutation: buildUpdateProductMutation({ addComment: false, type: productType }),
          variables: updateVars,
          context: roleContext,
        });
      }

      const {
        companyBusinessHoursId,
        companyHoursEnabled,
        businessHoursId: originalBusinessHoursId,
      } = formValues;

      const updatedBusinessHoursId = updateVars.businessHoursId;

      // Update opening hours only if company hours are not used
      if (!companyHoursEnabled && companyBusinessHoursId !== updatedBusinessHoursId) {
        await apollo.mutate<UpdateOpeningHoursResult, UpdateOpeningHoursVariables>({
          mutation: updateOpeningHoursMutation,
          variables: getUpdateOpeningHoursVariables(formValues, updatedBusinessHoursId),
          context: roleContext,
        });
      }
      // Delete old redundant product business hours when company hours are used
      if (companyHoursEnabled && originalBusinessHoursId !== updatedBusinessHoursId) {
        await apollo.mutate<DeleteBusinessHoursResult, DeleteBusinessHoursVariables>({
          mutation: deleteBusinessHoursMutation,
          variables: { businessHoursId: originalBusinessHoursId },
          context: roleContext,
        });
      }
    },
    [apollo]
  );

  return { updateProduct };
};
