import { gql } from "@apollo/client";

import { IdResult } from "../types";

const getProductsWithBusinessHoursId = gql`
  query GetProductsWithBusinessHoursId($businessHoursId: uuid!) {
    product(where: { businessHoursId: { _eq: $businessHoursId } }) {
      id
    }
  }
`;

export type GetProductsWithBusinessHoursIdVariables = {
  businessHoursId: string;
};

export type GetProductsWithBusinessHoursIdResult = {
  product: IdResult[];
};

export default getProductsWithBusinessHoursId;
