import { useApolloClient, useMutation } from "@apollo/client";
import { useCallback } from "react";

import {
  buildDeleteSocialMediaDataMutation,
  DeleteSocialLinksVars,
} from "../../graphqlQueries/socialMediaQueries/deleteSocialMediaLinks";
import {
  getVerifiedLinksIdsQuery,
  GetVerifiedLinksIdsResult,
  GetVerifiedLinksIdsVars,
} from "../../graphqlQueries/socialMediaQueries/getVerifiedLinksIds";
import {
  insertSocialMediaQuery,
  InsertSocialMediaResult,
  InsertSocialMediaVars,
} from "../../graphqlQueries/socialMediaQueries/insertSocialMedia";
import {
  insertSocialMediaLinksMutation,
  InsertSocialMediaLinksVars,
} from "../../graphqlQueries/socialMediaQueries/insertSocialMediaLinks";
import { ProductSocialMediaLink, UserRole } from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";

type UpdateSocialsUserRole =
  | UserRole.ManageCompany
  | UserRole.ManageProducts
  | UserRole.ManageCuration;

type UpdateSocialsOptions = {
  socialMediaId?: string;
  productSocialMediaLinks: ProductSocialMediaLink[];
  companySocialMediaEnabled?: boolean;
  companyId: string;
  companySocialMediaId?: string;
  userRole: UpdateSocialsUserRole;
};

export const useUpdateSocialMedias = () => {
  const apollo = useApolloClient();
  const [insertSocialMedia] = useMutation<InsertSocialMediaResult, InsertSocialMediaVars>(
    insertSocialMediaQuery
  );
  const [insertSocialMediaLinks] = useMutation<void, InsertSocialMediaLinksVars>(
    insertSocialMediaLinksMutation
  );

  const updateSocialMedia = useCallback(
    async ({
      socialMediaId: oldSocialMediaId,
      companySocialMediaId,
      productSocialMediaLinks,
      companyId,
      companySocialMediaEnabled,
      userRole,
    }: UpdateSocialsOptions): Promise<string> => {
      const roleContext = getHasuraRoleContext(userRole);

      const getSocialMediaId = async (
        socialMediaId: string | undefined | null
      ): Promise<string> => {
        if (socialMediaId) {
          return socialMediaId;
        }

        const { data } = await insertSocialMedia({
          variables: {
            companyId,
            companySocialMedia: userRole === UserRole.ManageCompany,
          },
          context: roleContext,
        });

        if (!data?.insertSocialMediaOne.id) {
          throw new Error("error inserting socialMedia");
        }

        return data.insertSocialMediaOne.id;
      };

      const getVerifiedLinks = async (socialMediaId: string | undefined) => {
        if (!socialMediaId) {
          return [];
        }
        const { data: verifiedLinkIds } = await apollo.query<
          GetVerifiedLinksIdsResult,
          GetVerifiedLinksIdsVars
        >({
          query: getVerifiedLinksIdsQuery,
          variables: { socialMediaId },
          context: roleContext,
          fetchPolicy: "network-only",
        });

        return verifiedLinkIds.socialMediaLinks.map((it) => it.verifiedLink.id);
      };

      const deleteSocialMediaData = async (
        socialMediaId: string,
        verifiedLinkIds: string[]
      ): Promise<void> => {
        if (oldSocialMediaId && companySocialMediaId && oldSocialMediaId !== companySocialMediaId) {
          await apollo.mutate<void, DeleteSocialLinksVars>({
            mutation: buildDeleteSocialMediaDataMutation(userRole),
            variables: {
              verifiedLinksToDelete: verifiedLinkIds,
              socialMediaId,
            },
            context: roleContext,
          });
        }
      };

      try {
        const verifiedLinkIds = await getVerifiedLinks(oldSocialMediaId);

        if (userRole !== UserRole.ManageCompany) {
          const filledWithCompanySocials = companySocialMediaEnabled && companySocialMediaId;

          if (filledWithCompanySocials) {
            // Remove old social media links from product when company links are
            // taken into use. For company this is never used.
            if (oldSocialMediaId) {
              await deleteSocialMediaData(oldSocialMediaId, verifiedLinkIds);
            }
            return companySocialMediaId || "";
          }
        }

        if (productSocialMediaLinks.length > 0) {
          // OldSocialMediaId and companySocialMediaId match but
          // companySocialMediaEnabled is false which means the user switched from
          // company socials to manual socials for the product. For company this
          // is always false.
          const changedFromCompanySocials =
            userRole !== UserRole.ManageCompany &&
            !companySocialMediaEnabled &&
            companySocialMediaId === oldSocialMediaId;

          // New socialMedia entity needs to be created if switched from company
          // socials or one doesn't exist otherwise the old id is returned.
          const socialMediaId = await getSocialMediaId(
            !changedFromCompanySocials ? oldSocialMediaId : null
          );

          // Delete all old links and add new ones to the socialMedia entity
          await insertSocialMediaLinks({
            variables: {
              socialMediaId,
              verifiedLinksToDelete: !changedFromCompanySocials ? verifiedLinkIds : [],
              objects: productSocialMediaLinks
                .filter((l) => !!l.verifiedLink.url)
                .map(({ linkType, verifiedLink }) => ({
                  companyId,
                  linkType,
                  socialMediaId,
                  verifiedLink: {
                    data: {
                      url: verifiedLink.url,
                      userVerifiedAt: verifiedLink.userVerifiedAt || null,
                      userVerifiedBy: verifiedLink.userVerifiedBy || null,
                    },
                  },
                })),
            },
            context: roleContext,
          });

          return (companySocialMediaEnabled && companySocialMediaId) || socialMediaId;
        } else {
          //If no social media links delete all old social media data if existing
          if (oldSocialMediaId) {
            await deleteSocialMediaData(oldSocialMediaId, verifiedLinkIds);
          }
          return "";
        }
      } catch (e) {
        throw new Error(`Error updating socials: ${e}`);
      }
    },
    [insertSocialMedia, insertSocialMediaLinks, apollo]
  );

  return { updateSocialMedia };
};
