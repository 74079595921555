import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ConfirmModal, ErrorModal } from "../DataHubModal";

type SaveCompanyModalProps = {
  saveResult: "ok" | "error";
  onHide: () => void;
};

const SaveCompanyModal = ({ saveResult, onHide }: SaveCompanyModalProps) => {
  const error = saveResult === "error";
  const { t } = useTranslation();

  const CloseButton = (
    <div className="d-flex w-100 mt-2 justify-content-center">
      <Button onClick={() => onHide()} variant="light">
        {t("common.close")}
      </Button>
    </div>
  );

  return error ? (
    <ErrorModal headerText={t("companyInfo.saveModalErrorHeader")} onHide={onHide}>
      <div>
        <p>{t("companyInfo.saveModalErrorDescription1")}</p>
        <p>{t("companyInfo.saveModalErrorDescription2")}</p>
      </div>
      {CloseButton}
    </ErrorModal>
  ) : (
    <ConfirmModal headerText={t("companyInfo.saveModalOkHeader")} onHide={onHide}>
      <p>{t("companyInfo.saveModalOkDescription")}</p>
      {CloseButton}
    </ConfirmModal>
  );
};

export default SaveCompanyModal;
