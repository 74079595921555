import { ExternalProduct, ExternalProductTexts } from "../../utils/productFormUtils/productUtils";
import { ExternalProductPreview, ProductsToImportType, UserRole } from "../../types";
import {
  GetBokunJohkuProductPreviewResponse,
  getBokunJohkuProductPreview,
} from "../../graphqlQueries/getBokunJohkuProductPreview";
import React, { useCallback, useState } from "react";

import ExternalProductList from "./ExternalProductList";
import Notification from "../Notification";
import { Spinner } from "react-bootstrap";
import { getHasuraRoleContext } from "../../utils/functions";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

type ImportProductPanelProps = {
  vendorId: string;
  setNextDisabled: (arg: boolean) => void;
  productsToImport: (prods: ProductsToImportType[]) => void;
  importingFrom: ExternalProduct;
  externalProductTexts: ExternalProductTexts;
};

const ImportProductPanel = ({
  vendorId,
  setNextDisabled,
  productsToImport,
  externalProductTexts,
}: ImportProductPanelProps) => {
  const { t } = useTranslation();
  const [productPreviews, setProductPreviews] = useState<ExternalProductPreview[]>([]);
  const [loadingError, setLoadingError] = useState(false);
  const currentRole = UserRole.ManageProducts;

  const { loading: loadingQuery } = useQuery<GetBokunJohkuProductPreviewResponse, { id: string }>(
    getBokunJohkuProductPreview,
    {
      variables: { id: vendorId },
      context: getHasuraRoleContext(currentRole),
      fetchPolicy: "network-only",
      onCompleted: (data) => setProductPreviews(data.GetBokunJohkuProductPreview),
      onError: (error) => {
        setLoadingError(true);
        console.error(error);
      },
    }
  );

  const handleImport = useCallback(
    (products: string[]) => {
      if (!products) {
        return;
      }

      productsToImport(
        products.map((product) => {
          return {
            productId: product,
            name: productPreviews.find((it) => it.id === product)?.name || "",
          };
        })
      );
    },
    [productPreviews, productsToImport]
  );

  const renderLoading = () => {
    if (loadingError) {
      return (
        <div
          className="justify-content-between align-items-center w-100 mt-5 mb-4"
          style={{ minHeight: "5rem" }}
        >
          <Notification type="danger">{t("importProduct.previewError")}</Notification>
        </div>
      );
    }
    if (loadingQuery) {
      setNextDisabled(true);
      return (
        <div style={{ minHeight: "5rem" }}>
          <Spinner className="mt-5 mb-5" animation="border" />
        </div>
      );
    }
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <p>{`${t(externalProductTexts.accountIdLabel)} ${vendorId}`}</p>
        <h4>{t("importProduct.chooseProductsTitle")}</h4>
        {renderLoading() || (
          <ExternalProductList
            productList={productPreviews}
            setNextDisabled={setNextDisabled}
            productsToImport={handleImport}
          />
        )}
      </div>
      <p className="mt-3 color-gray-600 text-small">{t("importProduct.importPanelInfo")}</p>
    </>
  );
};

export default ImportProductPanel;
