import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { breakpointPixels } from "../../css/breakpoints";

export const StyledDropdown = styled(Dropdown)`
/* Styles to override Bootstrap default class styles */
    .dropdown-toggle {
      &.btn:focus {
        background-color: var(--color-gray-200);
      }
      &::after {
        display: none;
      }
    }
    button {
        @media (max-width: ${breakpointPixels.sm}) {
          padding: 0;
        }
    }

div {
  .number-details-wrapper {
    min-width: 12em;
  }

    &.dropdown-menu.show {
      position: static;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.33);

      .dropdown-item {
        p {
          color: var(--color-gray-900);
        }

        h3 {
          font-size: 2rem;
        }
      }

      .dropdown-item,
      .dropdown-header {
        cursor: unset;
      }

      & .dropdown-item:nth-child(2n + 1),
      .dropdown-header {
        background-color: var(--color-gray-100);
        &:hover {
          background-color: var(--color-gray-200);
        }
      }

      .dropdown-header {
        font-size: var(--h3-font-size);
        color: var(--color-gray-900);
      }
    }
`;

export const StyledSummaryAmountHeader = styled.h2`
  font-size: 60px;
`;

export const IconContainer = styled.div`
  height: 30px;
  width: 30px;
`;

export const StyledSummaryHeaderContainer = styled.div`
  justify-items: space-between;

  h4 {
    font-family: var(--font-light);
  }

  @media (max-width: ${breakpointPixels.xl}) {
    &:div:first-child {
      width: 100%;
    }
  }
`;

export const SummaryNumberContainer = styled.div`
  div,
  .btn {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    color: var(--color-primary);
    display: flex;
    .blue {
      color: var(--color-dark-blue);
    }
    h2 {
      font-family: AvenirBlack;
      font-size: 4rem;
      &:first-child {
        margin-right: 0.2em;
      }
    }
    h3 {
      size: var(--h3-font-size);
    }

    div:nth-child(2) {
      align-self: center;
      align-content: start;
    }

    .number-details-wrapper {
      flex-wrap: wrap;
      align-self: center;
      min-width: 8em;

      span.numbersByProduct {
        font-weight: 300;
        color: var(--color-gray-900);
        font-family: "AvenirRoman", "Helvetica Neue", Arial, sans-serif;
      }
    }

    }

    @media (max-width: ${breakpointPixels.sm}) {
      padding: 0;
    }
  }
`;

export const StyledSummaryNumberContainerParent = styled.div`
  max-width: 60%;
  @media (max-width: ${breakpointPixels.md}) {
    max-width: 100%;
  }
`;

export const StyledMainHeader = styled.h1`
  word-break: break-word;
  font-size: 3rem;
  @media (max-width: ${breakpointPixels.md}) {
    font-size: 2.5rem;
  }
  @media (max-width: ${breakpointPixels.sm}) {
    font-size: 2rem;
  }
`;
