import CapacityInput from "./CapacityInput";
import { Form } from "react-bootstrap";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const GroupSizeSection: React.FunctionComponent<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();
  const { errors, clearErrors, getValues } = useFormContext();

  const validateMinMax = (minField: boolean) => {
    const capacityMin = parseInt(getValues().capacityMin);
    const capacityMax = parseInt(getValues().capacityMax);

    if (minField && !capacityMin && capacityMin < 1) {
      return "validationErrors.invalidMinGroupSize";
    } else if (capacityMax && capacityMin > capacityMax) {
      return "validationErrors.invalidMaxGroupSize";
    } else {
      clearErrors(["capacityMin", "capacityMax"]);
    }
  };

  return (
    <div className="mt-4">
      <Form.Label>{t("productInfo.groupSizeLabel")}</Form.Label>
      <CapacityInput validator={validateMinMax} disabled={disabled} />
      {errors.capacityMin && <p className="text-danger">{t(errors.capacityMin.message)}</p>}
      {errors.capacityMax && errors.capacityMax.message !== errors.capacityMin?.message && (
        <p className="text-danger">{t(errors.capacityMax.message)}</p>
      )}
    </div>
  );
};

export default GroupSizeSection;
