import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import styled, { css } from "styled-components";

import AccordionIcon from "./accordion/AccordionIcon";

const BaseStyle = css`
  outline: none;
  width: 100%;
  border: 0;
  text-align: left;
  padding: 0;
  :not(.btn-primary) {
    color: var(--color-gray-800) !important;
  }

  &.btn-primary:not([disabled]) {
    color: var(--color-primary-dark) !important;
  }

  &.contentOpen {
    background-color: var(--color-light-blue) !important;
  }
`;

const ToggleButton = styled(Button)`
  ${BaseStyle}
  background: var(--color-white) !important;
  &:hover,
  &:active,
  &:focus {
    outline: 0;
    border: 0;
    box-shadow: none !important;
  }

  &.btn-primary.contentOpen {
    background-color: var(--color-white) !important;
  }
`;

const ToggleButtonFaq = styled(Button)`
  ${BaseStyle}
  background: var(--color-gray-100) !important;
  border-bottom: solid 1px var(--color-gray-300) !important;
  &:hover,
  &:active,
  &:focus {
    background: var(--color-light-blue);
    outline: 0;
    border: 0;
    box-shadow: none !important;
  }
`;

type IconPosition = "left" | "right" | "center";

type CollapseToggleButtonProps = ButtonProps & {
  contentOpen: boolean;
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
  iconPosition?: IconPosition;
  renderIcon?: (open: boolean) => JSX.Element;
  showIcon?: boolean;
  isFAQ?: boolean;
};

const CollapseToggleButton = ({
  children,
  renderIcon = (open) => <AccordionIcon open={open} />,
  showIcon = true,
  iconPosition = "right",
  contentOpen,
  isFAQ,
  className,
  ...buttonProps
}: CollapseToggleButtonProps) => {
  const getIcon = (position: IconPosition) =>
    iconPosition === position && showIcon ? renderIcon(contentOpen) : null;
  const iconClass = `d-flex ${iconPosition === "center" ? "" : "justify-content-between"}`;

  return (
    <>
      {isFAQ ? (
        <ToggleButtonFaq
          {...buttonProps}
          className={`${contentOpen ? `contentOpen ${className}` : className}`}
        >
          <div className={`${iconClass} align-items-center`}>
            {getIcon("left")}
            {children}
            {getIcon("center")}
            {getIcon("right")}
          </div>
        </ToggleButtonFaq>
      ) : (
        <ToggleButton
          {...buttonProps}
          className={`${contentOpen ? `contentOpen ${className}` : className}`}
        >
          <div className={`${iconClass} align-items-center`}>
            {getIcon("left")}
            {children}
            {getIcon("center")}
            {getIcon("right")}
          </div>
        </ToggleButton>
      )}
    </>
  );
};

export default CollapseToggleButton;
