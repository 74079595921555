import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ProductGroup } from "../../types";
import { DataHubButton } from "../DataHubButton";
import GroupListingCard from "./GroupListingCard";
import { CREATE_PRODUCT_GROUP } from "./productGroupRouteConstants";

type MyGroupedProductsListingProps = {
  groups: ProductGroup[];
  rootPagePath: string;
};

export const MyGroupedProductsListing: React.FunctionComponent<MyGroupedProductsListingProps> = ({
  groups,
  rootPagePath,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const createGroupPath = `${rootPagePath}${CREATE_PRODUCT_GROUP}`;

  return (
    <div>
      <div className="d-flex justify-content-between mb-4">
        <div>
          <h3>{t("groupedProducts.myGroupedProducts")}</h3>
        </div>
        {groups.length > 0 && (
          <div>
            <DataHubButton variant="primary" onClick={() => history.push(createGroupPath)}>
              + {t("groupedProducts.newGroup")}
            </DataHubButton>
          </div>
        )}
      </div>
      <div>
        {groups.length > 0 ? (
          groups.map((group) => {
            return <GroupListingCard rootPagePath={rootPagePath} group={group} key={group.id} />;
          })
        ) : (
          <div className="border-gray-400 bg-white p-5 d-flex flex-column align-items-center">
            <div className="mb-3">{t("groupedProducts.noGroupedProducts")}</div>
            <div>
              <DataHubButton variant="primary" onClick={() => history.push(createGroupPath)}>
                + {t("groupedProducts.newGroup")}
              </DataHubButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
