import { BokunJohkuInsertResponse, ProductsToImportType } from "../../types";

import Icon from "../Icon";
import React from "react";
import { Spinner } from "react-bootstrap";
import { breakpointPixels } from "../../css/breakpoints";
import { shortenText } from "../../utils/localizationUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type UploadProductsPanelProps = {
  products: ProductsToImportType[];
  uploaded: BokunJohkuInsertResponse[];
  failed: string[];
};

const NAME_LENGTH = 30;

const NameCell = styled.td`
  min-width: 20%;
  white-space: nowrap;
  @media (max-width: ${breakpointPixels.sm}) {
    min-width: 50%;
    white-space: normal;
  }
`;

const UploadProductsPanel = ({ products, uploaded, failed }: UploadProductsPanelProps) => {
  const { t } = useTranslation();

  const uploadStatus = (id: string) => {
    const succeeded = uploaded.find((it) => it.productId === id);

    if (succeeded) {
      return (
        <>
          <div className="d-flex flex-row">
            <div style={{ width: "2rem" }}>
              <Icon
                className="mr-2"
                style={{ marginBottom: "0.3rem" }}
                name="check"
                size="medium"
              />
            </div>
            <span>{t("common.done")}!</span>
          </div>
          {succeeded.errors > 0 && (
            <div className="text-small" style={{ marginLeft: "2rem" }}>
              {t("importProduct.imageUploadError")}
            </div>
          )}
        </>
      );
    }

    if (failed.includes(id)) {
      return (
        <>
          <Icon
            className="mr-2"
            style={{ marginBottom: "0.3rem" }}
            name="warning-triangle"
            size="medium"
            color="invalid"
          />
          {t("importProduct.uploadError")}
        </>
      );
    }

    return <Spinner style={{ marginBottom: "0.3rem" }} animation="border" size="sm" />;
  };

  return (
    <div className="mt-4">
      <table className="table table-borderless">
        <tbody>
          {products.map(({ productId, name }, i) => (
            <tr key={i}>
              <NameCell>{shortenText(name, NAME_LENGTH)}</NameCell>
              <td className="text-muted">{uploadStatus(productId)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UploadProductsPanel;
