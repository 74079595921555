import { breakpointPixels } from "../../css/breakpoints";
import styled from "styled-components";

const ProductListPagingContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 350px;
  height: 50px;
  @media (max-width: ${breakpointPixels.md}) {
    width: 100%;
  }
  @media (min-width: ${breakpointPixels.lg}) {
    width: 318px;
  }
  @media (min-width: ${breakpointPixels.xl}) {
    width: 440px;
  }
  background: var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.4) 0 -5px 5px -5px;
`;

export default ProductListPagingContainer;
