import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import Icon from "../Icon";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type CommentHolderProps = {
  newCommentsText: string;
};

const StyledButtonContainer = styled(Button)`
  border-radius: 12px;
    &:hover {
      background-color: var(--color-gray-200);
    }
    &.disabled {
      &:hover {
        background-color: initial;
      }
    }
  }
`;

type NotificationLinkProps = {
  notificationIcon: JSX.Element;
  amountOfItems: number;
  onClick: () => void;
  amountOfComments?: number;
  tooltipText: string;
};

const CommentHolder = ({ newCommentsText }: CommentHolderProps) => {
  return (
    <div className="text-uppercase d-flex flex-row font-light">
      <div>
        <Icon size="medium" name="comment-alt" color="primary" />
      </div>
      <div>
        <span className="small ml-2" style={{ color: "var(--color-primary-dark)" }}>
          {newCommentsText}
        </span>
      </div>
    </div>
  );
};

export const NotificationLink: React.FunctionComponent<NotificationLinkProps> = ({
  notificationIcon,
  amountOfComments,
  amountOfItems,
  onClick,
  tooltipText,
}) => {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      placement={"top"}
      overlay={
        <Tooltip id="Tooltip" className="icon-tooltip">
          {tooltipText}
        </Tooltip>
      }
    >
      <StyledButtonContainer
        className={`d-flex py-1 px-2 flex-column ${amountOfItems === 0 ? "disabled" : ""}`}
        variant="text"
        disabled={amountOfItems === 0}
        onClick={onClick}
        style={{ opacity: amountOfItems === 0 ? "0.3" : "1" }}
      >
        <div className="d-flex align-items-center">
          {notificationIcon}
          <div className="d-flex ml-2 align-items-center">
            <h2 className="mb-0">{amountOfItems}</h2>
            {<span>{t("notifications.pieces")}</span>}
          </div>
        </div>
        {!!amountOfComments && (
          <CommentHolder newCommentsText={`${t("comments.newComments")} (${amountOfComments})`} />
        )}
      </StyledButtonContainer>
    </OverlayTrigger>
  );
};
