import { COMPANY_PATH, CURATOR_WORKSPACE_PATH } from "../../utils/routeConstants";
import { CurationStatusesFilter, UserRole } from "../../types";
import { DEFAULTS_KEY, FILTER_KEY, STATUS_KEY } from "../../utils/filterUtils";
import {
  GetDmoProductAggregates,
  getDmoProductAggregates,
} from "../../graphqlQueries/getDmoProductAggregates";
import getDmoNewCommentsCount, {
  GetCompanyNewCommentsCountResult,
} from "../../graphqlQueries/getDmoNewCommentsCount";

import CurationStatusNotificationLink from "./CurationStatusNotificationLink";
import { DMODashboardCard } from "./DMODashboardCard";
import { HasuradbDmoArea } from "../../graphqlQueries/getCuratorAreas";
import Icon from "../Icon";
import { NotificationLink } from "./NotificationLink";
import React from "react";
import { getHasuraRoleContext } from "../../utils/functions";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

type DMOProductsToCheckProps = {
  companyId: string;
  curatorAreas: HasuradbDmoArea[];
};

enum NotificationVariants {
  NewProds = "new",
  UpdatedProds = "updated",
  PublishedAndUpdatedProds = "publishedAndUpdated",
  Published = "published",
  Updated = "updated",
  BrokenLink = "brokenLink",
}

export const DMOProductsToCheck = ({ companyId, curatorAreas }: DMOProductsToCheckProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const areaIds = curatorAreas.map((it) => it.curationArea.id);

  const { data: dmoProductAggregates } = useQuery<GetDmoProductAggregates>(
    getDmoProductAggregates,
    {
      variables: { dmo_areas: `{${areaIds.join(",")}}` },
      context: getHasuraRoleContext(UserRole.ManageCuration),
      fetchPolicy: "network-only",
    }
  );

  const approvedAndUpdatedProducts = dmoProductAggregates?.approvedAndUpdated.aggregate.count || 0;
  const updatedProducts = dmoProductAggregates?.updated.aggregate.count || 0;
  const newProducts = dmoProductAggregates?.new.aggregate.count || 0;
  const brokenLinkProducts = dmoProductAggregates?.brokenLinks.aggregate.count || 0;

  const { data: newComments } = useQuery<GetCompanyNewCommentsCountResult>(getDmoNewCommentsCount, {
    variables: { dmoAreas: areaIds },
    context: getHasuraRoleContext(UserRole.ManageCuration),
  });

  const newProductComments = newComments?.new.aggregate.count;
  const updateComments = newComments?.updated.aggregate.count;
  const approvedAndUpdatedComments = newComments?.approvedAndUpdated.aggregate.count;

  const newUpdates = !!(
    approvedAndUpdatedProducts ||
    newProducts ||
    updatedProducts ||
    approvedAndUpdatedComments ||
    newProductComments ||
    updateComments ||
    brokenLinkProducts
  );

  const toCuratorWorkspaceWithStatus = (curationStatus: CurationStatusesFilter): void =>
    history.push({
      pathname: `${COMPANY_PATH}/${companyId}${CURATOR_WORKSPACE_PATH}`,
      search: `${STATUS_KEY}=${curationStatus}&${DEFAULTS_KEY}=true`,
    });

  const toCuratorWorkspaceWithFilter = (
    curationStatus: CurationStatusesFilter,
    filter: string
  ): void =>
    history.push({
      pathname: `${COMPANY_PATH}/${companyId}${CURATOR_WORKSPACE_PATH}`,
      search: `${STATUS_KEY}=${curationStatus}&${FILTER_KEY}=${filter}&${DEFAULTS_KEY}=true`,
    });

  const toCuratorWorkspaceWithFilters = (notification: string): void => {
    if (notification === NotificationVariants.NewProds) {
      return toCuratorWorkspaceWithStatus(CurationStatusesFilter.New);
    }

    if (notification === NotificationVariants.UpdatedProds) {
      return toCuratorWorkspaceWithStatus(CurationStatusesFilter.Updated);
    }

    if (notification === NotificationVariants.PublishedAndUpdatedProds) {
      return toCuratorWorkspaceWithStatus(CurationStatusesFilter.ApprovedAndUpdated);
    }

    if (notification === NotificationVariants.BrokenLink) {
      return toCuratorWorkspaceWithFilter(
        CurationStatusesFilter.All,
        NotificationVariants.BrokenLink
      );
    }
  };

  const clickHandler = (hasNew: number | undefined, notification: string): void => {
    if (!hasNew) {
      return;
    }

    toCuratorWorkspaceWithFilters(notification);
  };

  return (
    <DMODashboardCard
      headerTitle="notifications.productNotification"
      newUpdates={newUpdates}
      companyId={companyId}
      iconName="bell"
      cardType="products"
    >
      <div className="d-flex flex-row justify-content-between flex-wrap">
        <CurationStatusNotificationLink
          statusTypes={[NotificationVariants.NewProds]}
          amountOfItems={newProducts}
          amountOfComments={newProductComments || 0}
          onClick={() =>
            clickHandler(newProducts || newProductComments, NotificationVariants.NewProds)
          }
          tooltipText={t("dmoDashboard.tooltips.newProds")}
        />
        <CurationStatusNotificationLink
          statusTypes={[NotificationVariants.UpdatedProds]}
          amountOfItems={updatedProducts}
          amountOfComments={updateComments || 0}
          onClick={() =>
            clickHandler(updatedProducts || updateComments, NotificationVariants.UpdatedProds)
          }
          tooltipText={t("dmoDashboard.tooltips.updatedProds")}
        />
        <CurationStatusNotificationLink
          statusTypes={[NotificationVariants.Published, NotificationVariants.Updated]}
          amountOfItems={approvedAndUpdatedProducts}
          amountOfComments={approvedAndUpdatedComments || 0}
          onClick={() =>
            clickHandler(
              approvedAndUpdatedProducts || approvedAndUpdatedComments,
              NotificationVariants.PublishedAndUpdatedProds
            )
          }
          tooltipText={t("dmoDashboard.tooltips.publishedAndUpdated")}
        />
        <NotificationLink
          amountOfItems={brokenLinkProducts}
          onClick={() => clickHandler(brokenLinkProducts, NotificationVariants.BrokenLink)}
          notificationIcon={<Icon name="brokenLink" color="primary" size="large" />}
          tooltipText={t("dmoDashboard.tooltips.brokenLinks")}
        />
      </div>
    </DMODashboardCard>
  );
};
