import React from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionToggle from "../accordion/AccordionToggle";
import AccordionContent from "../accordion/AccordionContent";
import { Instruction } from "../../types/instructionsAndFaqTypes";
import { useIsSmallScreen } from "../MediaQuery";
import { RichText } from "@graphcms/rich-text-react-renderer";
import { useTranslation } from "react-i18next";

type InnerAccordionProps = { data: Instruction; idKey: string };

export const InnerAccordion = ({ data, idKey }: InnerAccordionProps) => {
  const { t } = useTranslation();
  const isMobile = useIsSmallScreen();

  // if no faqs show instruction rich content
  if (!data.faqs?.length && data?.instruction?.raw?.children) {
    return <RichText content={data.instruction.raw.children} />;
  }
  // if also no rich text
  if (!data?.instruction?.raw?.children) {
    return <div />;
  }

  return (
    <>
      <RichText content={data.instruction.raw.children} />
      <div className={isMobile ? "mt-5" : "my-5 pl-5"}>
        <h3 style={{ fontSize: "18px" }}>{t("ukk.relatedFaqs")}</h3>
        <Accordion defaultActiveKey={idKey}>
          {data?.faqs &&
            data.faqs.map((it, i) => (
              <div key={i}>
                <AccordionToggle
                  className="rounded bg-gray-100 px-2 mb-3 shadow-sm"
                  iconPosition="right"
                  eventKey={it.id}
                  isFAQ={true}
                >
                  <AccordionContent customStyle="d-flex">
                    <p className="mt-3" style={{ fontSize: "16px" }}>
                      {it.question}
                    </p>
                  </AccordionContent>
                </AccordionToggle>
                <Accordion.Collapse eventKey={it.id}>
                  <div
                    className="overflow-auto"
                    dangerouslySetInnerHTML={{ __html: it.answer.html }}
                  />
                </Accordion.Collapse>
              </div>
            ))}
        </Accordion>
      </div>
    </>
  );
};
