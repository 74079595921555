import moment from "moment";
import React, { useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CellProps, Column, useTable } from "react-table";

import { ApprovedUserData } from "../../pages/UserManagement";
import { userInfoVar } from "../../utils/ApolloCache";
import Notification from "../Notification";
import UserManagementModal from "./UserManagementModal";

type UserManagementTableProps = {
  users: ApprovedUserData[];
  onRemove: (id: string) => void;
};

const UserManagementTable = ({ users, onRemove }: UserManagementTableProps) => {
  const [selectedUser, setSelectedUser] = useState<ApprovedUserData | null>(null);

  const { t } = useTranslation();
  const currentUserId = userInfoVar()?.userId;

  const columns = useMemo<Column<ApprovedUserData>[]>(() => {
    return [
      {
        Header: t<string>("userManagement.usersTableHeaderUsername"),
        accessor: "username" as const,
      },
      {
        Header: t<string>("userManagement.usersTableHeaderLastName"),
        accessor: "lastName" as const,
      },
      {
        Header: t<string>("userManagement.usersTableHeaderFirstName"),
        accessor: "firstName" as const,
      },
      {
        Header: t<string>("userManagement.usersTableHeaderDateApproved"),
        accessor: "approvedAt" as const,
        Cell: (props: CellProps<ApprovedUserData>): string => {
          return props.value ? moment(props.value).format("DD.MM.YYYY") : "";
        },
      },
      {
        Header: t<string>("userManagement.usersTableHeaderActions"),
        accessor: "id" as const,
        Cell: (props: CellProps<ApprovedUserData>): JSX.Element => {
          const userData: ApprovedUserData = props.row.original;
          return (
            <Button
              variant="light"
              onClick={() => setSelectedUser(userData)}
              disabled={currentUserId === props.value && users.length <= 1}
            >
              {t<string>("userManagement.usersTableRemoveButton")}
            </Button>
          );
        },
      },
    ];
  }, [t, currentUserId, users.length]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<
    ApprovedUserData
  >({
    columns,
    data: users,
  });
  return (
    <>
      <Table className="bg-white" bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className="bg-white" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} data-cy="user-table">
          {rows.length === 0 && (
            <tr className="bg-white">
              <td colSpan={5}>{t("userManagement.usersTableNoResults")}</td>
            </tr>
          )}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="bg-white">
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: ".25rem 1rem",
                        verticalAlign: "middle",
                        width: cell.column.getHeaderProps().key === "header_id" ? "100px" : "auto",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {users.length === 1 && users[0].id === currentUserId && (
        <Notification type="info-alt">{t("userManagement.onlyUserWarning")}</Notification>
      )}
      {selectedUser && (
        <UserManagementModal
          title={t("userManagement.removeModalTitle")}
          text={t("userManagement.removeModalText")}
          buttonText={t("userManagement.removeModalButton")}
          user={{
            name: `${selectedUser.firstName} ${selectedUser.lastName}`,
            email: selectedUser.username,
          }}
          onCancel={() => setSelectedUser(null)}
          onConfirm={() => {
            onRemove(selectedUser.id);
            setSelectedUser(null);
          }}
        />
      )}
    </>
  );
};

export default UserManagementTable;
