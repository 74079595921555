import { gql } from "@apollo/client";

export const getInstructionPages = gql`
  query GetInstructionPages($locale: [Locale!]!) {
    instructionPages(locales: $locale) {
      title
      subText {
        html
      }
      image(locales: en) {
        url
      }
      imageMobile(locales: en) {
        url
      }
    }
  }
`;
