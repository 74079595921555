import React from "react";
import PageHeader from "../components/PageHeader";
import Page from "./Page";

const APIInstructions = () => (
  <Page>
    <div>
      <PageHeader>API Instructions</PageHeader>
    </div>
  </Page>
);

export default APIInstructions;
