import styled from "styled-components";

const ModalHeaderIconContainer = styled.div`
  display: inline-block;
  width: 22px;
  margin-top: -2px;
  flex-shrink: 0;
`;

export default ModalHeaderIconContainer;
