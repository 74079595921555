import { Accordion, AccordionCollapse, Col, Row } from "react-bootstrap";
import { ExternalProduct, externalProductSources } from "../../utils/productFormUtils/productUtils";

import AccordionContent from "../accordion/AccordionContent";
import AccordionIcon from "../accordion/AccordionIcon";
import AccordionToggle from "../accordion/AccordionToggle";
import Icon from "../Icon";
import ImportGuide from "./ImportGuide";
import { ProductTypeButton } from "../ProductTypeModal";
import React from "react";
import { useTranslation } from "react-i18next";

type ImportProductAccordionProps = {
  onChildClick: (arg: ExternalProduct) => void;
};

const ImportProductAccordion = ({ onChildClick }: ImportProductAccordionProps) => {
  const { t } = useTranslation();

  return (
    <Accordion>
      <AccordionToggle
        className="w-auto"
        eventKey="0"
        renderIcon={(open: boolean) => (
          <AccordionIcon open={open} style={{ marginRight: "23px" }} />
        )}
      >
        <AccordionContent customStyle="d-flex p-4 pl-0">
          <h3 className="m-0 p-0">{t("productInfo.importProductTitle")}</h3>
        </AccordionContent>
      </AccordionToggle>
      <AccordionCollapse eventKey="0">
        <>
          <p className="ml-4">{t("productInfo.importProductIngress")}</p>
          <Row>
            {externalProductSources.map((productSource) => {
              return (
                <Col xs="6" lg="3" key={productSource.type}>
                  <ProductTypeButton
                    className="border justify-content-center mb-1"
                    onClick={() => onChildClick(productSource.type)}
                  >
                    <Icon name={productSource.icon} />
                  </ProductTypeButton>
                  <ImportGuide externalProductType={productSource.type} />
                </Col>
              );
            })}
          </Row>
        </>
      </AccordionCollapse>
    </Accordion>
  );
};

export default ImportProductAccordion;
