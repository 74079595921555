import { Button, Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";
import {
  getTagsWithMainCategories,
  MainTagsQueryResult,
  MainTagsQueryVariables,
} from "../../graphqlQueries/getTagsWithMainCategories";
import { ProductPublishMode, ProductType, UserRole } from "../../types";
import React, { useEffect, useState } from "react";
import { getProductTypeHeaderKey, getProductTypeIcon } from "../../utils/localizationUtils";

import { BasicModal } from "../DataHubModal";
import Icon from "../Icon";
import ProductFormCategorySelects from "./ProductFormCategorySelects";
import ProductTypeModal from "../ProductTypeModal";
import { getHasuraRoleContext } from "../../utils/functions";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

type ProductTypeAndCategorySectionProps = {
  editMode: boolean;
  publishMode: ProductPublishMode;
  isProductGroup: boolean;
};

const CategorySelectContainer = styled.div`
  min-height: 130px;
`;

const ProductTypeAndCategorySection = ({
  editMode,
  isProductGroup,
}: ProductTypeAndCategorySectionProps) => {
  const { t } = useTranslation();
  const location: { state: { newProductType: ProductType } } = useLocation();
  const initialProductType = location?.state?.newProductType;
  const { register, errors, watch, setValue, reset, getValues } = useFormContext();

  const productType = watch("productType");

  const [getTagsAndMainCategories, { data }] = useLazyQuery<
    MainTagsQueryResult,
    MainTagsQueryVariables
  >(getTagsWithMainCategories, {
    variables: { productType },
    context: getHasuraRoleContext(
      isProductGroup ? UserRole.ManageProductGroup : UserRole.ManageProducts
    ),
  });

  const [showProductTypeModal, setShowProductTypeModal] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const mainCategories = data?.mainCategories ?? [];

  const allCategories = data?.allCategories ?? [];

  const resetProductSpecificFields = () => {
    reset({
      ...getValues(),
      capacityMax: undefined,
      capacityMin: undefined,
      duration: undefined,
    });
  };

  useEffect(() => {
    if (!productType && !initialProductType && !editMode) {
      setShowProductTypeModal(true);
    }
  }, [productType, setShowProductTypeModal, initialProductType, editMode]);

  useEffect(() => {
    if (productType) {
      getTagsAndMainCategories();
    }
  }, [productType, getTagsAndMainCategories]);

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader>{t("productInfo.productTypeAndCategoryHeader")}</FormSectionHeader>
        {!isProductGroup && <p>{t("productInfo.productTypeAndCategoryDesc")}</p>}
      </FormDescription>
      <FormSectionContent>
        <Form.Group controlId="productType">
          <Form.Label className="m-0">{t("productInfo.productType")}</Form.Label>
          <Form.Control
            name="productType"
            defaultValue={initialProductType}
            ref={register()}
            className="d-none"
          />
          {productType && (
            <div className="mt-2">
              <Button
                variant="light"
                disabled={isProductGroup}
                onClick={() => setShowWarningModal(true)}
              >
                <div className="d-inline">
                  <Icon
                    color="primary-dark"
                    name={getProductTypeIcon(productType)}
                    size="medium"
                    className="mb-1"
                  />
                </div>
                <span className="ml-2">{t(getProductTypeHeaderKey(productType))}</span>
              </Button>
            </div>
          )}

          {showWarningModal && (
            <BasicModal
              header={t("productType.warningModalHeader")}
              onCancel={() => setShowWarningModal(false)}
              onProceed={() => {
                setShowProductTypeModal(true);
                setShowWarningModal(false);
              }}
              proceedText={t("common.confirm")}
              cancelText={t("common.cancel")}
              centered
            >
              {t("productType.warningModalBody")}
            </BasicModal>
          )}

          <ProductTypeModal
            show={showProductTypeModal}
            onHide={() => {
              if (productType) {
                setShowProductTypeModal(false);
              }
            }}
            onSelect={(type) => {
              setValue("productType", type);
              resetProductSpecificFields();
              setShowProductTypeModal(false);
            }}
            showCloseButton={!!productType}
          />
          {errors.productType && <p className="text-danger">{t(errors.productType.message)}</p>}
        </Form.Group>
        <Form.Group controlId="productCategories">
          <Form.Label>{t("productInfo.selectMainCategories")}</Form.Label>
          <CategorySelectContainer>
            <Controller
              name="productCategories"
              defaultValue={[]}
              render={({ onChange, value }) => (
                <ProductFormCategorySelects
                  onChange={onChange}
                  values={value}
                  mainCategories={mainCategories}
                  allCategories={allCategories}
                  disabled={isProductGroup}
                />
              )}
            />
            {errors.productCategories && (
              <p className="text-danger">{t(errors.productCategories.message)}</p>
            )}
          </CategorySelectContainer>
        </Form.Group>
      </FormSectionContent>
    </FormSection>
  );
};

export default ProductTypeAndCategorySection;
