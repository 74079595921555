import { ExternalProductPreview } from "../../types";
import Icon from "../Icon";
import React from "react";
import { shortenText } from "../../utils/localizationUtils";
import styled from "styled-components";

const NAME_LENGTH = 30;

type ImportProductCardProps = {
  item: ExternalProductPreview;
  selected: boolean;
  onCardClick: () => void;
};

const ProductCard = styled.div`
  min-height: 10.6rem;
  border: 1px solid
    ${(props: { selected: boolean }) => (!props.selected ? "var(--color-gray-300)" : "black")};
  background: ${(props: { selected: boolean }) =>
    !props.selected ? "var(--color-gray-100)" : "white"};
  padding: 0.5rem;
  text-align: center;
`;

const ImportProductCard = ({ item, selected, onCardClick }: ImportProductCardProps) => {
  const { url, name } = item;

  return (
    <div
      className="col-sm-3 col-12 px-1 mb-2"
      onClick={onCardClick}
      role="button"
      onKeyDown={(e) => {
        if (e.key === " " || e.key === "Enter") {
          onCardClick();
        }
      }}
    >
      <ProductCard selected={selected}>
        {url ? (
          <img
            src={url}
            alt=""
            style={{ height: "6rem", width: "100%", objectFit: "fill", borderRadius: "8px" }}
          />
        ) : (
          <div
            className="bg-gray-200 d-flex flex-column justify-content-center align-items-center"
            style={{
              borderRadius: "8px",
              height: "6rem",
              width: "100%",
            }}
          >
            <Icon name="image-placeholder" color="primary" size="large" />
          </div>
        )}
        <p className="mb-0 mt-2 mh-25">{shortenText(name, NAME_LENGTH)}</p>
      </ProductCard>
    </div>
  );
};

export default ImportProductCard;
