import { DropdownOption } from "../types";

export const PAGE_SIZE_OPTIONS = [10, 50, 100, 200]; //eslint-disable-line

export const getPageSizeOptions = (pageSizeOptions: number[]): DropdownOption[] =>
  pageSizeOptions.map((amount) => ({
    value: amount,
  }));

export const DEFAULT_PAGE_SIZE_OPTIONS = getPageSizeOptions(PAGE_SIZE_OPTIONS);

export const TABLE_PAGE_SIZE = 10;
