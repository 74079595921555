import React from "react";
import styled from "styled-components";

type DummyLinkElementProps = {
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  style?: Record<string, string>;
};

const DummyLinkElement = styled.span<DummyLinkElementProps>`
  color: ${({ disabled }) => (disabled ? "var(--color-gray-600);" : "var(--color-primary-dark);")}
  text-decoration: underline;
  cursor: ${({ disabled }) => (disabled ? "default;" : "pointer;")}
  pointer-events: ${({ disabled }) => (disabled ? "none;" : "auto;")}
  outline-color: var(--color-focus);
`;

const DummyLink = (props: DummyLinkElementProps) => {
  return (
    <DummyLinkElement
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === " " || e.key === "Enter") {
          props.onClick && props.onClick();
        }
      }}
      role="button"
      {...props}
    />
  );
};

export default DummyLink;
