import React from "react";
import { useTranslation } from "react-i18next";
import { OrderBy } from "../../types";
import { Dropdown } from "react-bootstrap";
import IconButton from "../IconButton";
import Icon from "../Icon";
import DummyLink from "../DummyLink";
import { breakpointPixels } from "../../css/breakpoints";
import styled from "styled-components";
import { LargeOrSmallerScreen } from "../MediaQuery";

const sortOptions = [
  {
    labelKey: "curatorWorkspace.sortAscending",
    value: OrderBy.Ascending,
  },
  {
    labelKey: "curatorWorkspace.sortDescending",
    value: OrderBy.Descending,
  },
];

type FilteredListHeaderProps = {
  orderBy: OrderBy;
  onSortOrderChange: (orderBy: OrderBy) => void;
  onShowFiltersClick: () => void;
  headerText: string;
};

const HeaderContainer = styled.header`
  padding: 0;
  
  @media (min-width: ${breakpointPixels.lg}) {
    padding-top 14px;
    padding-bottom: 14px;
  }
`;

const FilteredListHeader = ({
  orderBy,
  onSortOrderChange,
  onShowFiltersClick,
  headerText,
}: FilteredListHeaderProps) => {
  const { t } = useTranslation();
  return (
    <>
      <LargeOrSmallerScreen>
        <div className="bg-white border-bottom p-3 pl-4">
          <h1 className="m-0 h4-size">{headerText}</h1>
        </div>
      </LargeOrSmallerScreen>
      <HeaderContainer className="bg-white border-bottom d-flex justify-content-between align-items-center sticky-top">
        <h1 className="h4-size m-0 ml-4 d-none d-lg-block" data-cy="curator-workspace">
          {headerText}
        </h1>
        <Dropdown>
          <Dropdown.Toggle variant="secondary">
            {t("curatorWorkspace.sortToggleLabel")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {sortOptions.map((item) => (
              <Dropdown.Item
                key={item.value}
                className="py-2"
                onClick={() => {
                  onSortOrderChange(item.value);
                }}
                active={item.value === orderBy}
              >
                {t(item.labelKey)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <div className="d-block d-lg-none">
          <IconButton
            variant="secondary"
            onClick={onShowFiltersClick}
            iconElement={<Icon name="filter" size="medium" color="primary-dark" />}
            className="p-3"
          >
            <DummyLink>{t("curatorWorkspace.showFiltersButton")}</DummyLink>
          </IconButton>
        </div>
      </HeaderContainer>
    </>
  );
};

export default FilteredListHeader;
