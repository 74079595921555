import {
  DropdownOption,
  FilterMode,
  FilterType,
  OrderBy,
  ProductFilters,
  UserRole,
} from "../types";
import {
  PUBLISHED_PRODUCT_FILTERS,
  getQueryFilterVariables,
  sessionStorageInitialFilters,
} from "../utils/filterUtils";
import PublishedProductListItem, {
  PublishedProductListItemType,
} from "../components/products/PublishedProductListItem";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import buildGetPublishedProductsQuery, {
  GetPublishedProductsResult,
  GetPublishedProductsVariables,
  PublishedProduct,
} from "../graphqlQueries/getPublishedProducts";
import { useIsLargeScreen, useIsMediumOrSmaller } from "../components/MediaQuery";
import useProductFilters, { initialProductFilters } from "../components/hooks/useProductFilters";

import { DEFAULT_PAGE_SIZE_OPTIONS } from "../utils/pagingUtils";
import FilteredListHeader from "../components/products/FilteredListHeader";
import Filters from "../components/curatorWorkspace/Filters";
import LastEdit from "../components/LastEdit";
import MobileFiltersModal from "../components/curatorWorkspace/MobileFiltersModal";
import MobileNavigationHeader from "../components/MobileNavigationHeader";
import Navigation from "../components/navigation/Navigation";
import Page from "./Page";
import { Paging } from "../components/Paging";
import PublishedProductList from "../components/products/PublishedProductList";
import { ResultsByFilter } from "../types/curatorProductTypes";
import ViewProduct from "../components/products/ViewProduct";
import { getDefaultProductName } from "../utils/productFormUtils/productUtils";
import { getHasuraRoleContext } from "../utils/functions";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const ViewProductContainer = styled.div`
  flex-grow: 1;
  background: var(--color-white);
  height: 100%;
`;

const ProductViewer = styled.div`
  display: flex;
  height: 100%;
`;

const mapProduct = (product: PublishedProduct): PublishedProductListItemType => ({
  productName: getDefaultProductName(product),
  companyName: product.company.businessName,
  images: product.productImages,
  id: product.id,
  city: product.postalAddresses[0]?.city ?? "",
  productType: product.type,
  updated: product.updatedAt,
  languages: product.productInformations.map((information) => information.language),
});

const PublishedProducts = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();
  const listElement = useRef<HTMLDivElement>(null);
  const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.Ascending);

  const { filters, setFilters } = useProductFilters(
    sessionStorageInitialFilters(PUBLISHED_PRODUCT_FILTERS)
  );

  useEffect(() => {
    sessionStorage.setItem(PUBLISHED_PRODUCT_FILTERS, JSON.stringify(filters));
  }, [filters]);

  const [resultsByFilter] = useState<ResultsByFilter>();
  const [page, setPage] = useState(0);
  const [pageAmount, setPageAmount] = useState(0);
  const [selectedAmountOption, setSelectedAmountOption] = useState<DropdownOption>(
    // get the last value of page size options
    DEFAULT_PAGE_SIZE_OPTIONS.slice(-1)[0]
  );
  const resetScroll = () => listElement?.current?.scrollTo(0, 0);

  const limit = selectedAmountOption.value;

  const [getPublishedProductsQuery, getPublishedProductsFilters] = useMemo(() => {
    const queryVariables = getQueryFilterVariables(filters);
    const query = buildGetPublishedProductsQuery(queryVariables);
    return [query, queryVariables];
  }, [filters]);

  const { data: getPublishedProductsData, loading: getPublishedProductsLoading } = useQuery<
    GetPublishedProductsResult,
    GetPublishedProductsVariables
  >(getPublishedProductsQuery, {
    context: getHasuraRoleContext(UserRole.ViewOpenProducts),
    variables: {
      ...getPublishedProductsFilters,
      limit,
      offset: page * limit,
      orderBy: orderBy,
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (!getPublishedProductsLoading) {
      resetScroll();

      const totalProductCount: number =
        getPublishedProductsData?.productAggregate.aggregate.count ?? 0;
      const pageAmount = totalProductCount === 0 ? 0 : Math.ceil(totalProductCount / limit);

      setPageAmount(pageAmount);
    }
  }, [getPublishedProductsData, getPublishedProductsLoading, limit, pageAmount]);

  const products = getPublishedProductsData?.product ?? [];
  const routeParams = useRouteMatch<{ id: string }>(`${path}/:id`)?.params;
  const selectedProductId = routeParams?.id;
  const isMedium = useIsMediumOrSmaller();
  const isLarge = useIsLargeScreen();
  const showProductList = !isMedium || (isLarge && !selectedProductId);
  const showMobileNavigation = selectedProductId && isMedium;
  const productsLength = products?.length ?? 0;

  const handleFilterChange = (newFilters: ProductFilters) => {
    setFilters({
      ...newFilters,
      curationStatus: undefined,
    });
    setPage(0);
  };

  return (
    <Page
      header={showMobileNavigation ? <MobileNavigationHeader backUrl={path} /> : null}
      sideBar={
        <Navigation
          filterComponent={
            <Filters
              initialFilters={{
                ...initialProductFilters,
                cities: undefined,
                productCategories: undefined,
              }}
              filterType={FilterType.DesktopFilters}
              filters={filters}
              onFilterChange={(newFilters: ProductFilters) => handleFilterChange(newFilters)}
              filterMode={FilterMode.PublishedProducts}
            />
          }
        />
      }
    >
      <ViewProductContainer>
        <ProductViewer>
          <Route path={`${path}`}>
            {showProductList && (
              <PublishedProductList
                ref={listElement}
                selectedProductId={selectedProductId}
                products={products}
                loading={getPublishedProductsLoading}
                header={
                  <>
                    <FilteredListHeader
                      orderBy={orderBy}
                      onSortOrderChange={(orderBy: OrderBy) => setOrderBy(orderBy)}
                      onShowFiltersClick={() => {
                        setShowMobileFilters(true);
                      }}
                      headerText={t("publishedProducts.header")}
                    />
                    <MobileFiltersModal
                      show={showMobileFilters}
                      onClose={() => {
                        setShowMobileFilters(false);
                      }}
                      resultCount={productsLength}
                    >
                      <Filters
                        initialFilters={{
                          ...initialProductFilters,
                          cities: undefined,
                          productCategories: undefined,
                        }}
                        filterType={FilterType.MobileFilters}
                        filters={filters}
                        onFilterChange={(newFilters: ProductFilters) =>
                          handleFilterChange(newFilters)
                        }
                        filterMode={FilterMode.PublishedProducts}
                        resultsByFilter={resultsByFilter}
                      />
                    </MobileFiltersModal>
                  </>
                }
                productListItem={
                  <>
                    {products &&
                      products.length > 0 &&
                      products.map((product) => (
                        <PublishedProductListItem
                          key={product.id}
                          item={mapProduct(product)}
                          onClick={(id) => history.push(`${path}/${id}`)}
                          selected={product.id === selectedProductId}
                        />
                      ))}
                  </>
                }
                paging={
                  <Paging
                    productsLength={productsLength}
                    totalProductCount={
                      getPublishedProductsData?.productAggregate.aggregate.count ?? 0
                    }
                    productsLoading={getPublishedProductsLoading}
                    page={page}
                    setPage={setPage}
                    selectedAmountOption={selectedAmountOption}
                    setSelectedAmountOption={setSelectedAmountOption}
                  />
                }
              />
            )}
            <Route path={`${path}/:productId`}>
              <ViewProduct
                viewMode="publishedProducts"
                renderToolbar={({ product }) => {
                  return (
                    <div className="mt-3 px-3">
                      <LastEdit dateStr={product.updatedAt} />
                    </div>
                  );
                }}
              />
            </Route>
          </Route>
        </ProductViewer>
      </ViewProductContainer>
    </Page>
  );
};

export default PublishedProducts;
