import { gql } from "@apollo/client";
import { DatahubType } from "../types";

const insertCompany = gql`
  mutation InsertCompany(
    $businessName: String!
    $email: String!
    $phone: String!
    $city: String!
    $postalCode: String!
    $streetName: String!
    $websiteUrl: String
    $description: String!
    $dmoAreas: [dmo_area]!
    $datahubType: String!
  ) {
    InsertCompany(
      businessName: $businessName
      city: $city
      description: $description
      companyEmail: $email
      phone: $phone
      postalCode: $postalCode
      streetName: $streetName
      websiteUrl: $websiteUrl
      dmoAreas: $dmoAreas
      datahubType: $datahubType
    ) {
      id
      joinRequestId
    }
  }
`;

export type InsertCompanyResult = {
  InsertCompany: {
    id: string;
    joinRequestId: string;
  };
};

export type InsertCompanyVariables = {
  businessName: string;
  email: string;
  phone: string;
  city: string;
  postalCode: string;
  streetName: string;
  websiteUrl: string;
  description: string;
  dmoAreas: { curationAreaId: string }[];
  datahubType: DatahubType;
};

export default insertCompany;
