import React from "react";
import { Form } from "react-bootstrap";
import {
  FormSection,
  FormDescription,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import UrlInput from "../UrlInput";

type ExternalLinksSectionProps = {
  isCurator: boolean;
};

const ExternalLinksSection = ({ isCurator }: ExternalLinksSectionProps) => {
  const { errors } = useFormContext();
  const { t } = useTranslation();

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader data-cy="company-external-link-details">
          {t("companyInfo.externalLinks")}
        </FormSectionHeader>
        <p>
          {isCurator
            ? t("companyInfo.organisationExternalLinksDescription")
            : t("companyInfo.externalLinksDescription")}
        </p>
      </FormDescription>
      <FormSectionContent>
        <Form.Group controlId="website">
          <Form.Label>{t("companyInfo.website")}</Form.Label>
          <UrlInput name="website" />
          {errors.website && <p className="text-danger">{t(errors.website.message)}</p>}
        </Form.Group>
        <Form.Group controlId="webshop">
          <Form.Label>{t("companyInfo.webshop")}</Form.Label>
          <UrlInput name="webshop" />
          {errors.webshop && <p className="text-danger">{t(errors.webshop.message)}</p>}
        </Form.Group>
      </FormSectionContent>
    </FormSection>
  );
};

export default ExternalLinksSection;
