import {
  BusinessHoursFormValues,
  CurationStatus,
  MyProductFormValues,
  ProductCurationStatus,
  ProductStatus,
} from "../../types";
import {
  HasuraInsertBaseVariables,
  HasuraInsertDraftVariables,
  HasuraInsertProductVariables,
} from "../../graphqlQueries/insertProduct";
import {
  getAccessibility,
  getAddress,
  getAvailabilityLanguage,
  getAvailableDays,
  getAvailableMonths,
  getCapacity,
  getCategories,
  getCertificates,
  getDefaultOpeningHours,
  getDuration,
  getImages,
  getLocation,
  getNewProductCurationStatus,
  getOpeningHoursExceptions,
  getPricing,
  getProductInformations,
  getProductType,
  getTargetGroups,
  getVideos,
} from "./productFormDataMapper";

import { BusinessHoursPeriodInsertInput } from "../../graphqlQueries/updateOpeningHours";
import { HasuraUpdateProductVariables } from "../../graphqlQueries/updateProduct";
import { updateArrayWithProductId } from "../formUtils";

const getBusinessHoursId = (formValues: MyProductFormValues) => {
  const { companyHoursEnabled, companyBusinessHoursId, businessHoursId } = formValues;
  if (companyHoursEnabled) {
    return companyBusinessHoursId || undefined;
  } else {
    return (companyBusinessHoursId !== businessHoursId && businessHoursId) || undefined;
  }
};

const baseProduct = (
  formValues: MyProductFormValues,
  companyId: string
): HasuraInsertBaseVariables => {
  const address = getAddress(formValues);
  const location = getLocation(formValues);

  if (!location) {
    address.location = null;
  } else {
    address.location = `(${location.latitude}, ${location.longitude})`;
  }

  return {
    accessible: getAccessibility(formValues.accessible),
    address,
    availableDays: getAvailableDays(formValues),
    availableMonths: getAvailableMonths(formValues),
    companyId,
    duration: getDuration(formValues),
    email: formValues.email,
    images: getImages(formValues),
    phone: formValues.phonenumber,
    pricing: getPricing(formValues),
    availabilityLanguages: getAvailabilityLanguage(formValues),
    capacity: getCapacity(formValues),
    productInformations: getProductInformations(formValues),
    certification: getCertificates({
      stfCertified: formValues.stfCertified,
      welcomeCyclistCertified: formValues.welcomeCyclistCertified,
    }),
    tags: getCategories(formValues.productCategories),
    targetGroups: getTargetGroups(formValues),
    type: getProductType(formValues),
    videos: getVideos(formValues),
    businessHoursId: getBusinessHoursId(formValues),
    socialMediaId: formValues.socialMediaId || null,
  };
};

export const composeDraftProduct = (
  formValues: MyProductFormValues,
  companyId: string
): HasuraInsertDraftVariables => ({
  ...baseProduct(formValues, companyId),
  status: ProductStatus.Draft,
  curationEnabled: false,
});

export const composePublishedProduct = (
  formValues: MyProductFormValues,
  companyId: string
): HasuraInsertProductVariables => ({
  ...baseProduct(formValues, companyId),
  status: ProductStatus.Published,
  curationEnabled: true,
  curationStatus: CurationStatus.Pending,
  productCurationStatus: ProductCurationStatus.New,
});

const baseInsertVariables = (
  data: HasuraInsertProductVariables | HasuraInsertDraftVariables,
  productId: string
) => {
  const updatedCapacities =
    data.capacity && data.capacity[0]
      ? updateArrayWithProductId(data.capacity, productId)
      : undefined;

  const updatedDurations =
    data.duration && data.duration[0]
      ? updateArrayWithProductId(data.duration, productId)
      : undefined;

  return {
    availableMonths: updateArrayWithProductId(data.availableMonths, productId),
    availabilityLanguages: updateArrayWithProductId(data.availabilityLanguages, productId),
    images: updateArrayWithProductId(data.images, productId),
    productInformations: updateArrayWithProductId(data.productInformations, productId),
    certification: updateArrayWithProductId(data.certification, productId),
    tags: updateArrayWithProductId(data.tags, productId),
    targetGroups: updateArrayWithProductId(data.targetGroups, productId),
    videos: updateArrayWithProductId(data.videos, productId),
    capacity: updatedCapacities && updatedCapacities[0],
    duration: updatedDurations && updatedDurations[0],
  };
};

type ComposeUpdatedProductVariables = {
  data: HasuraInsertProductVariables;
  curationStatus: CurationStatus | null;
  productCurationStatus: ProductCurationStatus | null;
  isCurator?: boolean;
  productId: string;
};

export const composeUpdatedProduct = ({
  data,
  curationStatus,
  productCurationStatus,
  productId,
  isCurator,
}: ComposeUpdatedProductVariables): HasuraUpdateProductVariables => {
  const newCurationStatus = curationStatus === null ? CurationStatus.Pending : curationStatus;
  const newProductCurationStatus =
    productCurationStatus === null || curationStatus === null
      ? ProductCurationStatus.New
      : getNewProductCurationStatus(curationStatus, productCurationStatus, isCurator);

  return {
    ...data,
    ...baseInsertVariables(data, productId),
    id: productId,
    curationEnabled: true,
    curationStatus: newCurationStatus,
    pricing: data.pricing[0],
    status: ProductStatus.Published,
    productCurationStatus: newProductCurationStatus,
    verifiedLinks: [],
    businessHoursId: data.businessHoursId ?? "",
  };
};

type ComposeDraftUpdateProductVariables = {
  data: HasuraInsertDraftVariables;
  productId: string;
};

export const composeDraftUpdateProduct = ({
  data,
  productId,
}: ComposeDraftUpdateProductVariables): HasuraUpdateProductVariables => ({
  ...data,
  ...baseInsertVariables(data, productId),
  id: productId,
  curationEnabled: false,
  pricing: data.pricing[0],
  status: ProductStatus.Draft,
  verifiedLinks: [],
  businessHoursId: data.businessHoursId ?? "",
});

export const composeOpeningHoursData = (
  formValues: BusinessHoursFormValues,
  businessHoursId: string
): BusinessHoursPeriodInsertInput[] => {
  return [
    getDefaultOpeningHours(formValues),
    ...getOpeningHoursExceptions(formValues),
  ].map((period) => ({ businessHoursId, ...period }));
};
