import React, { useCallback, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { Key } from "ts-key-enum";

import { DataHubImage } from "../types";
import { useDebouncedCallback } from "../utils/debounce";
import Thumbnail from "./Thumbnail";

type ImageModalProps = {
  images: DataHubImage[];
  viewImage: DataHubImage;
  onHide: () => void;
  showThumbnails?: boolean;
};

type ContainerProps = {
  current: boolean;
};

const ThumbnailContainer = styled.div<ContainerProps>`
  opacity: ${({ current }) => (current ? "1" : "0.5")};
  border: 2px solid ${({ current }) => (current ? "var(--color-black)" : "transparent")};
  height: 79px;
  border-radius: 10px;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
`;

const LOADING_DELAY = 300;

const ImageModal = ({ images, viewImage, onHide, showThumbnails = true }: ImageModalProps) => {
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const [currentImage, setCurrentImage] = useState<DataHubImage>(viewImage);

  const setImageLoadingDelayed = useDebouncedCallback(setIsImageLoading, LOADING_DELAY);

  const setImage = useCallback(
    (img: DataHubImage) => {
      setImageLoadingDelayed(true);
      setCurrentImage(img);
    },
    [setImageLoadingDelayed]
  );

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      const index = currentImage ? images.indexOf(currentImage) : 0;
      if (e.key === Key.ArrowRight) {
        if (index + 1 > images.length - 1) {
          return;
        }
        const next = (index + 1) % images.length;
        setImage(images[next]);
      } else if (e.key === Key.ArrowLeft) {
        if (index === 0) {
          return;
        }
        const previous = (index - 1) % images.length;
        setImage(images[previous]);
      }
    };
    window.addEventListener("keydown", handleKey);
    return () => window.removeEventListener("keydown", handleKey);
  }, [currentImage, images, setImage]);

  return (
    <Modal
      show
      size="xl"
      centered
      animation={false}
      onHide={() => onHide()}
      className="modal-fullscreen-lg"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div
          className="border-gray-300 w-100 position-relative"
          style={{
            textAlign: "center",
            height: "30vh",
            display: !isImageLoading ? "none" : "block",
          }}
        >
          <div
            className="position-absolute"
            style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          >
            <Spinner animation="border" />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <img
            className="img-fluid"
            src={currentImage?.largeUrl}
            alt={currentImage?.altText || ""}
            style={{
              maxHeight: "calc(100vh - 260px)",
              display: isImageLoading ? "none" : "block",
            }}
            onLoad={() => {
              setImageLoadingDelayed(false);
            }}
          />
        </div>
        {showThumbnails && (
          <div className="d-flex align-items-center justify-content-center flex-wrap mt-3">
            {images.map((image, i) => (
              <ThumbnailContainer className="mr-2 mb-1" current={image === currentImage} key={i}>
                <Thumbnail
                  image={image}
                  onClick={(image) => {
                    setImage(image);
                  }}
                  altText={image.altText || ""}
                  listItemStyle
                />
              </ThumbnailContainer>
            ))}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
