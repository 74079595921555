import {
  Certificate,
  CurationStatus,
  CurationStatusesFilter,
  FilterMode,
  Language,
  ProductCurationStatus,
  ProductFilters,
  ProductStatus,
} from "../types";

export type QueryFilterVariables = {
  areas?: string;
  search?: string;
  type?: string;
  tags?: string[];
  months?: string[];
  targetGroups?: string[];
  languages?: Language[];
  byAllLanguages?: boolean;
  curationStatus?: string[];
  productCurationStatus?: string[] | null;
  certificates?: string[];
  accessible?: boolean;
  cities?: string[];
  status?: string[] | null;
  productNotification?: string;
  externalSource?: string;
  tcCompanyIds?: string[];
};

export const STATUS_KEY = "status";
export const FILTER_KEY = "filter";
export const DEFAULTS_KEY = "defaults";
export const PUBLISHED_PRODUCT_FILTERS = "PUBLISHED_PRODUCT_FILTERS";
export const MY_PRODUCT_FILTERS = "MY_PRODUCT_FILTERS";
export const CURATOR_PRODUCT_FILTERS = "CURATOR_PRODUCT_FILTERS";

export const sessionStorageInitialFilters = (key: string): ProductFilters => {
  const sessionStorageItem = sessionStorage.getItem(key);
  const sessionFilters: ProductFilters = sessionStorageItem ? JSON.parse(sessionStorageItem) : null;

  return {
    search: sessionFilters?.search || "",
    stfCertifiedFilter: sessionFilters?.stfCertifiedFilter || false,
    accessibleFilter: sessionFilters?.accessibleFilter || false,
    byAllLanguagesFilter: sessionFilters?.byAllLanguagesFilter || false,
    productType: sessionFilters?.productType,
    productCategories: sessionFilters?.productCategories,
    cities: sessionFilters?.cities,
    targetGroups: sessionFilters?.targetGroups,
    months: sessionFilters?.months,
    curationStatus: sessionFilters?.curationStatus,
    withoutCurator: sessionFilters?.withoutCurator || false,
    productNotification: sessionFilters?.productNotification,
  };
};

export const removeAllFiltersFromSessionStorage = () => {
  sessionStorage.removeItem(PUBLISHED_PRODUCT_FILTERS);
  sessionStorage.removeItem(MY_PRODUCT_FILTERS);
  sessionStorage.removeItem(CURATOR_PRODUCT_FILTERS);
};

export const removeFiltersByMode = (filterMode: FilterMode): void => {
  if (filterMode === FilterMode.PublishedProducts) {
    sessionStorage.removeItem(PUBLISHED_PRODUCT_FILTERS);
  }
  if (filterMode === FilterMode.CuratorWorkspace) {
    sessionStorage.removeItem(CURATOR_PRODUCT_FILTERS);
  }
  if (filterMode === FilterMode.CompanyProducts) {
    sessionStorage.removeItem(MY_PRODUCT_FILTERS);
  }
};

const getQueryStatus = (
  filterStatus: string
): [CurationStatus[] | null, ProductCurationStatus[] | null, ProductStatus[] | null] => {
  switch (filterStatus) {
    case CurationStatusesFilter.New:
      return [[CurationStatus.Pending], [ProductCurationStatus.New], null];
    case CurationStatusesFilter.Updated:
      return [[CurationStatus.Pending], [ProductCurationStatus.Updated], null];
    case CurationStatusesFilter.Pending:
      return [[CurationStatus.Pending], [ProductCurationStatus.ChangesRequested], null];
    case CurationStatusesFilter.Approved:
      return [[CurationStatus.Approved], null, null];
    case CurationStatusesFilter.ApprovedAndUpdated:
      return [[CurationStatus.Approved], [ProductCurationStatus.Updated], null];
    case CurationStatusesFilter.All:
      return [[CurationStatus.Pending, CurationStatus.Approved], null, null];
    case CurationStatusesFilter.Draft:
      return [null, null, [ProductStatus.Draft]];
    case CurationStatusesFilter.CurationPending:
      return [[CurationStatus.Pending], null, [ProductStatus.Published]];
    case CurationStatusesFilter.AllCompanyProducts:
      return [null, null, null];
    default:
      throw new Error("Unknown curation status");
  }
};

const getQueryFilterVariables = (
  filters: ProductFilters,
  curatorAreaIds?: string[],
  tcCompanyIds?: string[]
): QueryFilterVariables => {
  const variables: QueryFilterVariables = {};

  const citiesFilterActive = !!filters.cities && filters.cities.length > 0;

  if (curatorAreaIds && curatorAreaIds.length > 0) {
    const cities = filters.cities ?? [];
    const areaIds = citiesFilterActive ? cities : curatorAreaIds;
    variables.areas = `{${areaIds.join(",")}}`;
  } else if (citiesFilterActive) {
    variables.cities = filters.cities;
  }

  if (filters.search) {
    variables.search = `%${filters.search.split(" ").join("%")}%`;
  }

  if (filters.productType) {
    variables.type = filters.productType;
  }

  if (filters.productCategories) {
    variables.tags = filters.productCategories;
  }

  if (filters.months) {
    variables.months = filters.months;
  }

  if (filters.targetGroups) {
    variables.targetGroups = filters.targetGroups;
  }

  if (filters.externalSource) {
    variables.externalSource = filters.externalSource;
  }

  if (filters.byAllLanguagesFilter) {
    variables.byAllLanguages = true;
  }

  if (filters.languages && filters.languages.length > 0) {
    variables.languages = filters.languages;
  }

  if (filters.accessibleFilter) {
    variables.accessible = true;
  }

  if (filters.stfCertifiedFilter) {
    variables.certificates = [Certificate.STF];
  }

  if (tcCompanyIds && tcCompanyIds.length > 0) {
    variables.tcCompanyIds = tcCompanyIds;
  }

  if (filters.curationStatus) {
    const [curationStatus, productCurationStatus, productStatus] = getQueryStatus(
      filters.curationStatus
    );
    if (curationStatus) {
      variables.curationStatus = curationStatus;
    }
    if (productCurationStatus) {
      variables.productCurationStatus = productCurationStatus;
    }
    if (productStatus) {
      variables.status = productStatus;
    }
  }

  if (filters.productNotification) {
    variables.productNotification = filters.productNotification;
  }
  return variables;
};

export { getQueryFilterVariables };
