import { OSM_API_URL } from "./constants";

export const DEFAULT_ZOOM = 13;
export const NO_POSITION_ZOOM = 4;

type OSMObject = {
  boundingbox: [number, number][];
  class: string;
  display_name: string;
  importance: number;
  lat: string;
  licence: string;
  lon: string;
  place_id: number;
  type: string;
};

type AddressResponse = { coordinates: [number, number]; type: "streetAddress" | "postalCode" };

/**
 *
 * @param addressQuery The query for the address as a tuple. Expecting [streetAddress, postalCode] format.
 * @returns the results from OSM.
 */
export const fetchAddressDetails = (addressQuery: [string, string]): Promise<OSMObject[]> => {
  const fetchQuery = `${OSM_API_URL}/search?street=${addressQuery[0]}&postalcode=${addressQuery[1]}&country=Finland&countrycodes=["fi"]&format=json`;

  return fetch(fetchQuery, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch(() => {
      throw new Error("Error fetching results from OSM");
    });
};

export const getLocationFromOSM = async (
  addressQuery: [string, string]
): Promise<AddressResponse | null> => {
  const res = await fetchAddressDetails(addressQuery);
  if (!res) {
    return null;
  }

  if (res.length > 0) {
    // get coordinates from res and set them for the map
    const { lat, lon } = res[0];
    return {
      coordinates: [Number(lat), Number(lon)],
      type: "streetAddress",
    };
  } else {
    // if address wasn't found, show the appropriate notification
    // try again with only the postal code
    const postalCodeRes = await fetchAddressDetails(["", addressQuery[1]]);

    if (postalCodeRes && postalCodeRes.length > 0) {
      const { lat, lon } = postalCodeRes[0];
      return {
        coordinates: [Number(lat), Number(lon)],
        type: "postalCode",
      };
    } else {
      return null;
    }
  }
};
