import React from "react";
import { useQuery } from "@apollo/client";
import getCuratorAreas, { CuratorAreasResult } from "../graphqlQueries/getCuratorAreas";
import { getHasuraRoleContext } from "../utils/functions";
import { UserRole } from "../types";
import Notification from "../components/Notification";
import { useTranslation } from "react-i18next";
import CompanyTables from "../components/companies/CompanyTables";
import MobileNavigationHeader from "../components/MobileNavigationHeader";
import Page from "./Page";
import { useIsMediumOrSmaller } from "../components/MediaQuery";
import { useRouteMatch } from "react-router-dom";

const Companies = ({ companyId }: { companyId: string }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const idParam = useRouteMatch<{ id: string }>(`${path}/:id`)?.params;
  const selectedProductId = idParam?.id;
  const isMedium = useIsMediumOrSmaller();
  const showMobileNavigation = selectedProductId && isMedium;

  const { data, error } = useQuery<CuratorAreasResult>(getCuratorAreas, {
    variables: { id: companyId },
    context: getHasuraRoleContext(UserRole.ManageCuration),
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return (
      <Page header={showMobileNavigation ? <MobileNavigationHeader backUrl={path} /> : null}>
        <Notification type="danger">{t("common.error")}</Notification>
      </Page>
    );
  }

  if (!data) {
    return null;
  }

  const dmoAreas = data.dmoArea;

  return (
    <Page header={showMobileNavigation ? <MobileNavigationHeader backUrl={path} /> : null}>
      <CompanyTables dmoAreas={dmoAreas} />
    </Page>
  );
};

export default Companies;
