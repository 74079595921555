import { gql } from "@apollo/client";

import { IdResult } from "../types";

export const unLockProductGroupMutation = gql`
  mutation UnlockProductGroup($id: uuid!, $published: boolean) {
    updateProductGroupByPk(pk_columns: { id: $id }, _set: { published: false }) {
      id
      published
    }
  }
`;

export type UnLockProductGroupResult = {
  insertProductGroupOne: IdResult;
};

export type UnLockProductGroupVariables = {
  id: string;
};
