import React from "react";
import defaultLogo from "../../../images/noImage.jpeg";

import { DataHubButton } from "../../DataHubButton";
import { HasuradbDeletedProduct } from "../../../graphqlQueries/getProduct";
import Icon from "../../Icon";
import ProductListItemIconContainer from "../../products/ProductListItemIconContainer";
import { getProductTypeHeaderKey } from "../../../utils/localizationUtils";
import { useIsSmallScreen } from "../../MediaQuery";
import { useTranslation } from "react-i18next";
import { dayMonthYearFormat } from "../../../utils/format";
import moment from "moment";
import Thumbnail from "../../Thumbnail";
import {
  ItemDetailsContainer,
  ListItemContainer,
  ProductListItemHeader,
} from "./AddProductsProductListItem";

type AddProductsProductListItemProps = {
  item: HasuradbDeletedProduct;
  onButtonClick: (product: HasuradbDeletedProduct) => void;
};

const AddProductsProductListItem = ({ item, onButtonClick }: AddProductsProductListItemProps) => {
  const { t } = useTranslation();
  const isSmallScreen = useIsSmallScreen();

  return (
    <ListItemContainer key={item.id} selected={false} isPreview={true}>
      <div className="color-error font-heavy d-block pb-2 d-sm-none">
        <Icon
          name="warning-triangle"
          size="medium"
          className="cursor-default font-heavy pr-1"
          style={{ width: 26, height: 26, verticalAlign: "bottom" }}
        />
        {t("groupedProducts.notAvailable")}
      </div>
      <div className="d-flex justify-content-between pb-2">
        <div>
          <div className="color-gray-600">{moment(item.updatedAt).format(dayMonthYearFormat)}</div>
        </div>
        <div className="color-error font-heavy mr-auto pl-4 pr-1 d-none d-sm-block">
          <Icon
            name="warning-triangle"
            size="medium"
            className="cursor-default font-heavy pr-1"
            style={{ width: 26, height: 26, verticalAlign: "bottom" }}
          />
          {t("groupedProducts.notAvailable")}
        </div>
        <div className="d-flex flex-row mb-auto">
          <DataHubButton
            iconElement={<Icon name="trash" size="medium" style={{ marginTop: "-3px" }} />}
            variant="secondary"
            size="sm"
            onClick={() => onButtonClick(item)}
          >
            {t("common.delete")}
          </DataHubButton>
        </div>
      </div>
      <ItemDetailsContainer selected={false}>
        <div className="d-flex w-100">
          <div className="pl-0 mr-4">
            <Thumbnail
              imageUrl={defaultLogo}
              showDelete={false}
              size="small"
              altText={""}
              listItemStyle
            />
          </div>
          <div className="w-100">
            <div className="mb-1 mr-1">
              <ProductListItemHeader className="clamp-2">
                {item.productInformations[0].name}
              </ProductListItemHeader>
            </div>
            <div className="my-1 text-small text-left">{item.company.businessName}</div>
            <div className="my-1">
              <ProductListItemIconContainer iconName="map-marker-alt-solid">
                {item.postalAddresses[0]?.city ?? ""}
              </ProductListItemIconContainer>
            </div>
            {isSmallScreen && <div>{t(getProductTypeHeaderKey(item.type))}</div>}
            <div className="d-flex justify-content-end">
              {!isSmallScreen && (
                <div className="text-right mr-1">{t(getProductTypeHeaderKey(item.type))}</div>
              )}
            </div>
          </div>
        </div>
      </ItemDetailsContainer>
    </ListItemContainer>
  );
};

export default AddProductsProductListItem;
