import { breakpointPixels } from "../css/breakpoints";
import styled from "styled-components";

const ProductListContainer = styled.div<{ pagination: boolean }>`
  overflow-y: auto;
  flex-basis: 350px;
  @media (max-width: ${breakpointPixels.md}) {
    flex-basis: 100%;
  }
  @media (min-width: ${breakpointPixels.lg}) {
    flex-basis: 318px;
  }
  @media (min-width: ${breakpointPixels.xl}) {
    flex-basis: 440px;
  }
  padding-right: 4px;
  flex-shrink: 0;
  background-color: var(--color-gray-100);
  ${(props) => props.pagination && "margin-bottom: 50px;"}
`;

export default ProductListContainer;
