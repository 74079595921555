import React from "react";
import styled from "styled-components";
import { breakpointPixels, contentMaxWidth } from "../css/breakpoints";

type PageLayoutProps = {
  topBar?: React.ReactNode;
  content: React.ReactNode;
  sideBar?: React.ReactNode;
  banner?: React.ReactNode;
};

const Content = styled.main<{ showBanner: boolean }>`
  width: 100%;
  max-width: calc(${contentMaxWidth} - var(--navigation-width));
  ${({ showBanner }) =>
    showBanner
      ? "height: calc(100vh - var(--banner-header-height));"
      : "calc(100vh - var(--header-height) - 1px);"}
  height: ${({ showBanner }) =>
    showBanner ? "calc(100vh - (var(--banner-header-height) + 10px))" : "calc(100vh - 68px)"};
  overflow: auto;

  @media (min-width: ${breakpointPixels.lg}) {
    margin-left: var(--navigation-width);
    width: calc(100% - var(--navigation-width));
  }
`;

const SideBar = styled.nav<{ showBanner: boolean }>`
  @media (min-width: ${breakpointPixels.lg}) {
    width: 100%;
    width: var(--navigation-width);
    height: ${({ showBanner }) =>
      showBanner ? "calc(100vh - (var(--banner-header-height) + 10px))" : "calc(100vh - 68px)"};
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const PageLayout = ({ topBar, content, sideBar, banner }: PageLayoutProps) => (
  <div className="container-fluid bg-light p-0" style={{ marginLeft: 0 }}>
    {topBar && (
      <>
        {banner && (
          <div className="d-flex flex-row">
            <div className="sticky-top w-100">{banner}</div>
          </div>
        )}
        <div className="d-flex flex-row">
          <header className="border-bottom bg-white sticky-top w-100">{topBar}</header>
        </div>
      </>
    )}
    <div className="d-flex flex-row">
      {sideBar && (
        <aside className="position-fixed">
          <SideBar showBanner={!!banner} className="border-right bg-white d-none d-lg-block">
            {sideBar}
          </SideBar>
        </aside>
      )}
      <Content showBanner={!!banner}>{content}</Content>
    </div>
  </div>
);

export default PageLayout;
