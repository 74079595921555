import "leaflet/dist/leaflet.css";

import L, { LatLngExpression, LeafletMouseEvent } from "leaflet";
import { MapContainer, Marker, TileLayer, useMap, useMapEvent, useMapEvents } from "react-leaflet";
import Notification, { NotificationType } from "../../components/Notification";

import { COORDINATES_FINLAND } from "../../utils/constants";
import DummyLink from "../DummyLink";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import mapIcon from "../../images/map-marker-png.png";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DEFAULT_ZOOM, NO_POSITION_ZOOM } from "../../utils/OSMutils";

const HAS_POSITION_ZOOM = 17;

const StyledMapContainer = styled.div`
  width: 100%;
  height: 272px;
  position: relative;
`;

const StyledMapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1001;
`;

const StyledSpinnerContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

type ProductMapProps = {
  setCurrentPosition: (coords: [number, number]) => void;
  setShowCoordinatesInstructionModal: (value: boolean) => void;
  positionFromApi?: [number, number];
  isFetching?: boolean;
  zoomLevel?: number;
  hasError?: boolean;
  foundNoResults?: boolean;
  disabled?: boolean;
};

export const ProductMap: React.FunctionComponent<ProductMapProps> = ({
  setCurrentPosition,
  setShowCoordinatesInstructionModal,
  positionFromApi,
  isFetching,
  zoomLevel = DEFAULT_ZOOM,
  hasError,
  foundNoResults,
  disabled,
}) => {
  const [currentZoomLevel, setCurrentZoomLevel] = React.useState(zoomLevel);
  const currentPositionRef = React.useRef<[number, number] | null>(null);
  const mapRef = React.useRef<L.Map>(null);
  const { t } = useTranslation();

  const [currentPositionAbc, setCurrentPositionAbc] = useState<[number, number]>(
    COORDINATES_FINLAND
  );

  useEffect(() => {
    if (positionFromApi) {
      setCurrentPositionAbc(positionFromApi);
    }
  }, [positionFromApi, setCurrentPositionAbc]);

  useEffect(() => {
    // If there's a current position set, zoom in the map from the default level
    if (!currentPositionRef.current && positionFromApi) {
      setCurrentZoomLevel(HAS_POSITION_ZOOM);
    }
    if (positionFromApi) {
      currentPositionRef.current = positionFromApi;
    }
  }, [setCurrentZoomLevel, positionFromApi]);

  L.Marker.prototype.options.icon = L.icon({
    iconUrl: mapIcon,
    iconSize: [18, 28], //eslint-disable-line
    iconAnchor: [10, 28], //eslint-disable-line
    className: disabled ? "cursor-default" : "",
  });

  const addPos = (p: [number, number]) => setCurrentPosition(p);

  const ZoomHandler = () => {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCurrentZoomLevel(mapEvents.getZoom());
      },
    });
    return null;
  };

  const ClickHandler = ({ addPos }: { addPos: (pos: [number, number]) => void }) => {
    useMapEvent("click", (event: LeafletMouseEvent) => {
      // If the map is disabled, do nothing
      if (disabled) {
        return;
      }
      const { lat, lng } = event.latlng;
      // if current zoom level is bigger or same than the default one,
      // overwrite the state with the current level
      if (currentZoomLevel >= DEFAULT_ZOOM) {
        setCurrentZoomLevel(currentZoomLevel);
      } else {
        // instead overwrite it with street level zoom
        setCurrentZoomLevel(DEFAULT_ZOOM);
      }
      addPos([lat, lng]);
    });
    return null;
  };

  // Function to update the center and boundaries
  const PositionView = ({ center, zoom }: { center: LatLngExpression; zoom: number }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  };

  const MarkerView = ({ currentPosition }: { currentPosition: LatLngExpression }) => {
    if (currentPosition === COORDINATES_FINLAND) {
      return null;
    }

    return (
      <Marker
        draggable={!disabled}
        position={currentPosition}
        eventHandlers={{
          dragend: ({ target }) => {
            const { lat, lng } = target.getLatLng();
            addPos([lat, lng]);
          },
        }}
      />
    );
  };

  const LoaderOverlay = () => {
    return (
      <StyledMapOverlay className="w-100">
        <StyledSpinnerContainer>
          <Spinner animation="border" variant="dark" />
        </StyledSpinnerContainer>
      </StyledMapOverlay>
    );
  };

  type MapNotificationBarProps = {
    bannerType: NotificationType;
  };

  const MapNotificationBar: React.FunctionComponent<MapNotificationBarProps> = ({
    bannerType,
    children,
  }) => {
    return (
      <StyledMapOverlay className="w-100">
        <div className="m-2 box-shadow">
          <Notification type={bannerType}>
            <p className="font-light">{children}</p>
          </Notification>
        </div>
      </StyledMapOverlay>
    );
  };

  return (
    <StyledMapContainer>
      {hasError && (
        <MapNotificationBar bannerType="danger">
          {t("productMap.genericErrorMessage")}{" "}
          <DummyLink onClick={() => setShowCoordinatesInstructionModal(true)}>
            {t("productInfo.locationDetailsDescriptionLink")}
          </DummyLink>
        </MapNotificationBar>
      )}
      {foundNoResults && (
        <MapNotificationBar bannerType="info-alt">
          {t("productMap.noResultsFound")}{" "}
          <DummyLink onClick={() => setShowCoordinatesInstructionModal(true)}>
            {t("productInfo.locationDetailsDescriptionLink")}
          </DummyLink>
        </MapNotificationBar>
      )}
      <MapContainer
        innerRef={mapRef}
        zoom={currentZoomLevel}
        zoomControl={!disabled}
        doubleClickZoom={!disabled}
        dragging={!disabled}
        center={currentPositionAbc || COORDINATES_FINLAND}
        scrollWheelZoom={false}
        className="w-100"
        style={{ height: "272px", position: "relative", cursor: disabled ? "unset" : "pointer" }}
      >
        {isFetching && <LoaderOverlay />}
        <ClickHandler addPos={addPos} />
        <>
          <ZoomHandler />
          <MarkerView currentPosition={currentPositionAbc} />
          <PositionView
            center={currentPositionAbc || COORDINATES_FINLAND}
            zoom={currentPositionAbc === COORDINATES_FINLAND ? NO_POSITION_ZOOM : currentZoomLevel}
          />
        </>
        <TileLayer
          {...{
            attribution:
              '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          }}
        />
      </MapContainer>
    </StyledMapContainer>
  );
};
