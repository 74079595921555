import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ProductType } from "../../types";
import { BF_STATISTICS_URL } from "../../utils/constants";
import { productTypes } from "../../utils/productFormUtils/productUtils";
import ExternalLink from "../ExternalLink";
import { DMODashboardHeaderDropdown } from "./DMODashboardHeaderDropdown";
import {
  StyledMainHeader,
  StyledSummaryAmountHeader,
  StyledSummaryHeaderContainer,
  StyledSummaryNumberContainerParent,
  SummaryNumberContainer,
} from "./DashboardComponents";

interface DMOSummaryHeaderProps {
  currentCompanyId: string;
  businessName: string;
  amountOfCompanies: number;
  amountOfProducts: number;
  products: { type: ProductType }[];
}

export const DMOSummaryHeader: React.FunctionComponent<DMOSummaryHeaderProps> = ({
  currentCompanyId,
  businessName,
  amountOfCompanies,
  amountOfProducts,
  products,
}) => {
  const { t } = useTranslation();

  const productTypeCategories = products
    .map((product) => productTypes.find((type) => type.type === product.type))
    .reduce<Record<string, number>>((map, val) => {
      if (!val) {
        return map;
      }
      map[val.type] = (map[val.type] || 0) + 1;
      return map;
    }, {});

  return (
    <StyledSummaryHeaderContainer className="w-100 d-flex flex-xl-row flex-column p-4 dashboard-card">
      <div className="mr-4">
        <div data-cy="dashboard-subheader-dmo">
          <h4>{t("dashboard.header")}</h4>
        </div>
        <div>
          <StyledMainHeader className="clamp-2">{businessName}</StyledMainHeader>
        </div>
      </div>
      <div style={{ width: "inherit" }} data-cy="region-summary">
        <div>
          <h4>{t("dashboard.regionSummaryTitle")}</h4>
        </div>
        <StyledSummaryNumberContainerParent className="d-flex justify-content-between flex-sm-row flex-column">
          <SummaryNumberContainer>
            <div data-cy="region-company-count">
              <div>
                <StyledSummaryAmountHeader>{amountOfCompanies}</StyledSummaryAmountHeader>
              </div>
              <div className="number-details-wrapper flex-column">
                <div>
                  <h3 className="mb-0">{t("dashboard.companiesTotal")}</h3>
                </div>
                <div>
                  <Link
                    to={currentCompanyId ? `/company/${currentCompanyId}/companies` : "/companies"}
                  >
                    {t("dashboard.viewCompanyList")}
                  </Link>
                </div>
              </div>
            </div>
          </SummaryNumberContainer>
          <SummaryNumberContainer>
            <DMODashboardHeaderDropdown
              amountOfProducts={amountOfProducts}
              productTypeCategories={productTypeCategories}
            />
          </SummaryNumberContainer>
        </StyledSummaryNumberContainerParent>

        <div className="d-flex justify-content-end" data-cy="region-numbers">
          <ExternalLink href={BF_STATISTICS_URL}>
            {t("dashboard.viewRegionalNumbersInDepth")}
          </ExternalLink>
        </div>
      </div>
    </StyledSummaryHeaderContainer>
  );
};
