import { gql } from "@apollo/client";

import { BusinessHours } from "../types";

const getBusinessHoursQuery = gql`
  query GetBusinessHours($businessHoursId: uuid!) {
    businessHoursByPk(id: $businessHoursId) {
      id
      default {
        id
        weekday
        opens
        closes
        open
      }
      exceptions(order_by: { start: asc }) {
        id
        start
        end
        openingHours {
          id
          weekday
          opens
          closes
          open
          date
        }
      }
    }
  }
`;

export type GetBusinessHoursResult = {
  businessHoursByPk: BusinessHours;
};

export type GetBusinessHoursVariables = {
  businessHoursId: string;
};

export default getBusinessHoursQuery;
