import { Controller, useFormContext } from "react-hook-form";
import {
  FormSectionSubHeader,
  FormSubDescription,
  FormSubSection,
  FormSubSectionContent,
} from "../FormSection";
import React, { useEffect } from "react";

import Checkbox from "../Checkbox";
import CurrencyInput from "./CurrencyInput";
import { DatahubSelect } from "../DatahubSelect";
import { Form } from "react-bootstrap";
import InputContainer from "./InputContainer";
import { PricingUnit } from "../../types";
import { getPriceUnitKey } from "../../utils/localizationUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PriceSeparator = styled.div`
  align-self: flex-end;
  padding: 0 1rem 0.6rem 1rem;
`;

const PriceInputContainer = styled.div`
  max-width: 100px;
`;

const FREE_PRICE = "priceFree";
const PRICING_UNIT = "pricingUnit";
const PRICE_FROM = "priceFrom";
const PRICE_TO = "priceTo";

const PricingSection: React.FunctionComponent<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();
  const { register, errors, watch, setValue, clearErrors, getValues } = useFormContext();

  const freePrice = watch(FREE_PRICE);
  const pricingUnit = watch(PRICING_UNIT);
  const priceFrom = watch(PRICE_FROM);
  const priceTo = watch(PRICE_TO);

  const validateMinMax = () => {
    const from = parseFloat(getValues().priceFrom);
    const to = parseFloat(getValues().priceTo);

    if (from > to) {
      return "validationErrors.maxPriceMinPriceError";
    } else {
      clearErrors([PRICE_FROM, PRICE_TO]);
    }
  };

  const pricingUnitPlaceholder = { label: t("select.placeholder"), value: PricingUnit.Placeholder };

  const clearPriceRange = () => {
    setValue(PRICE_FROM, "");
    setValue(PRICE_TO, "");
    setValue(PRICING_UNIT, pricingUnitPlaceholder);
    clearErrors([PRICE_FROM, PRICE_TO, PRICING_UNIT]);
  };

  const pricingUnitOptions = [
    PricingUnit.Person,
    PricingUnit.Hour,
    PricingUnit.Day,
    PricingUnit.Week,
    PricingUnit.Placeholder,
  ].map((pricingUnit) => ({
    value: pricingUnit,
    label: t(getPriceUnitKey(pricingUnit)),
  }));

  useEffect(() => {
    if (pricingUnit && !pricingUnit.label) {
      const selectedPricingUnit = pricingUnitOptions.find(
        (pricingOption) => pricingOption.value === pricingUnit.value
      );
      setValue(PRICING_UNIT, selectedPricingUnit);
    }
  }, [pricingUnit, pricingUnitOptions, setValue]);

  return (
    <FormSubSection>
      <FormSubDescription>
        <FormSectionSubHeader>{t("productInfo.pricingHeader")}</FormSectionSubHeader>
        {!disabled && <p>{t("productInfo.pricingDescription")}</p>}
      </FormSubDescription>
      <FormSubSectionContent>
        <Form.Label>{t("productInfo.pricingLabel")}</Form.Label>
        <InputContainer
          hasError={
            !!errors.priceFrom || !!errors.priceTo || !!errors.price || !!errors.pricingUnit
          }
        >
          <Checkbox
            id="free"
            name={FREE_PRICE}
            ref={register()}
            onChange={(event) => {
              if (event.target.checked) {
                clearPriceRange();
              }
            }}
            label={t("productInfo.pricingLabelFree")}
            disabled={disabled}
          />
          <div className="d-flex align-items-end my-3">
            <PriceInputContainer>
              <Form.Label>{t("productInfo.pricingLabelPriceFrom")}</Form.Label>
              <CurrencyInput
                name={PRICE_FROM}
                onChange={() => {
                  setValue(FREE_PRICE, false);
                }}
                className={errors.priceFrom ? "is-invalid-border" : ""}
                ref={register({
                  required: priceTo && "validationErrors.setFromPrice",
                  pattern: {
                    value: /^\d+(,\d{1,2})?$/,
                    message: "validationErrors.invalidPrice",
                  },
                  validate: () => validateMinMax(),
                })}
                disabled={freePrice || disabled}
              />
            </PriceInputContainer>
            <PriceSeparator>&ndash;</PriceSeparator>
            <PriceInputContainer>
              <Form.Label>{t("productInfo.pricingLabelPriceTo")}</Form.Label>
              <CurrencyInput
                name={PRICE_TO}
                className={errors.priceTo ? "is-invalid-border" : ""}
                onChange={() => {
                  setValue(FREE_PRICE, false);
                }}
                ref={register({
                  pattern: {
                    value: /^\d+(,\d{1,2})?$/,
                    message: "validationErrors.invalidPrice",
                  },
                  validate: () => validateMinMax(),
                })}
                disabled={freePrice || disabled}
              />
            </PriceInputContainer>
          </div>
          <div className="mt-1">
            <Form.Label>{t("productInfo.pricingUnitLabel")}</Form.Label>
            <Controller
              name={PRICING_UNIT}
              disabled={freePrice}
              defaultValue={pricingUnitPlaceholder}
              rules={{
                validate: (value) => {
                  if (freePrice || (!priceFrom && !priceTo)) {
                    return true;
                  } else if (value && (!value.value || value.value === "select")) {
                    return "validationErrors.pricingUnitError";
                  }
                },
              }}
              render={({ onChange, onBlur, value }) => {
                return (
                  <div style={{ maxWidth: "150px" }}>
                    <DatahubSelect
                      options={pricingUnitOptions.filter(
                        (pricingOption) => pricingOption.value !== PricingUnit.Placeholder
                      )}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      isDisabled={freePrice || disabled}
                    />
                  </div>
                );
              }}
            />
          </div>

          {errors.priceFrom && <p className="text-danger">{t(errors.priceFrom.message)}</p>}
          {errors.priceTo && errors.priceTo.message !== errors.priceFrom?.message && (
            <p className="text-danger">{t(errors.priceTo.message)}</p>
          )}
          {errors.pricingUnit && <p className="text-danger">{t(errors.pricingUnit.message)}</p>}
        </InputContainer>
        {errors.price && <p className="text-danger">{t(errors.price.message)}</p>}
      </FormSubSectionContent>
    </FormSubSection>
  );
};

export default PricingSection;
