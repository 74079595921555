import { HasuraInsertDraftVariables } from "../../graphqlQueries/insertProduct";
import { HasuraUpdateProductVariables } from "../../graphqlQueries/updateProduct";
import { MyProductFormValues } from "../../types";
import { BaseUpdateParams } from "./mapProductToHasura";
import { composeDraftProduct, composeDraftUpdateProduct } from "./productToHasuraComposer";

type UpdateDraftParams = BaseUpdateParams;

export const getInsertDraftVariables = (
  formValues: MyProductFormValues,
  companyId: string
): HasuraInsertDraftVariables => composeDraftProduct(formValues, companyId);

export const getUpdateDraftVariables = ({
  productId,
  formValues,
  companyId,
}: UpdateDraftParams): HasuraUpdateProductVariables =>
  composeDraftUpdateProduct({
    data: getInsertDraftVariables(formValues, companyId),
    productId,
  });
