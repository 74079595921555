import React, { useState } from "react";

import { DataHubButton } from "../DataHubButton";
import Icon from "../Icon";
import { breakpointPixels } from "../../css/breakpoints";
import { default as c } from "classnames";
import infoImage1 from "../../images/product-group-info-1.svg";
import infoImage2 from "../../images/product-group-info-2.svg";
import infoImage3 from "../../images/product-group-info-3.svg";
import infoImage4 from "../../images/product-group-info-4.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledStepsContainer = styled.div`
  @media (max-width: ${breakpointPixels.md}) {
    flex-direction: column;
  }
`;

const StyledStepCard = styled.div`
  align-items: center;

  .step-image-container {
    height: 135px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
`;

const steps = [
  {
    image: infoImage1,
    header: "groupedProducts.createNewGroup.header",
    description: "groupedProducts.createNewGroup.description",
  },
  {
    image: infoImage2,
    header: "groupedProducts.chooseProducts.header",
    description: "groupedProducts.chooseProducts.description",
  },
  {
    image: infoImage3,
    header: "groupedProducts.ready.header",
    description: "groupedProducts.ready.description",
  },
  {
    image: infoImage4,
    header: "groupedProducts.fetchProducts.header",
    description: "groupedProducts.fetchProducts.description",
  },
];

type HowToUseGroupedProdutsProps = {
  shouldShowInstructions: boolean;
};

const HowToUseGroupedProduts: React.FunctionComponent<HowToUseGroupedProdutsProps> = ({
  shouldShowInstructions,
}) => {
  const { t } = useTranslation();
  const [showInstructions, setShowInstructions] = useState(shouldShowInstructions);

  return (
    <div
      className={c({
        "bg-white p-3 border-gray-400": showInstructions,
      })}
    >
      <DataHubButton
        variant="ghost-pink"
        onClick={() => setShowInstructions(!showInstructions)}
        iconElement={<Icon name="info" size="medium"></Icon>}
        className="mb-3 p-0"
      >
        <>
          <div className="ml-2 mr-2"> {t("groupedProducts.howGroupedProductsWork")}</div>
          <div>
            <Icon name={showInstructions ? "chevron-up" : "chevron-down"}></Icon>
          </div>
        </>
      </DataHubButton>

      <StyledStepsContainer style={{ display: !showInstructions ? "none" : "flex" }}>
        {steps.map((step, i) => (
          <StyledStepCard className="p-3 text-center" key={step.header}>
            <div className="step-image-container mb-3">
              <img src={step.image} alt={t(step.description)} />
            </div>
            <div className="font-weight-bold mb-2">
              {i + 1}. {t(step.header)}
            </div>
            <div className="color-gray-600">{t(step.description)}</div>
          </StyledStepCard>
        ))}
      </StyledStepsContainer>
    </div>
  );
};

export default HowToUseGroupedProduts;
