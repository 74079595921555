import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import MaintenanceNotificationSection from "./MaintenanceNotificationSection";
import { MaintenanceBreakSection } from "./MaintenanceBreakSection";
import { BasicModal } from "../DataHubModal";
import { useTranslation } from "react-i18next";
import { isoDayMonthYearFormat } from "../../utils/format";
import { useMutation } from "@apollo/client";
import { insertMaintenanceQuery } from "../../graphqlQueries/maintenanceBanner/insertMaintenanceQuery";
import { getHasuraRoleContext } from "../../utils/functions";
import { UserRole } from "../../types";
import {
  MaintenanceDataOut,
  MaintenanceFormData,
  MaintenanceResponseDataIn,
  MaintenanceUpdateQueryVars,
} from "../../types/bannerTypes";
import { updateMaintenanceQuery } from "../../graphqlQueries/maintenanceBanner/updateMaintenanceQuery";
import Notification from "../Notification";

type MaintenanceFormSectionProps = {
  closeModal: (isOnSubmit?: boolean) => void;
  editModeData?: MaintenanceDataOut;
};

export const MaintenanceFormSection = ({
  closeModal,
  editModeData,
}: MaintenanceFormSectionProps) => {
  const { t } = useTranslation();
  const hookFormMethods = useForm();
  const { handleSubmit } = hookFormMethods;
  const [notificationIsFilled, setNotificationIsFilled] = useState(true);
  const [breakIsFilled, setBreakIsFilled] = useState(true);
  const [queryError, setQueryError] = useState(false);
  const formIsFilled = notificationIsFilled && breakIsFilled;

  const [insertMaintenanceBannerData] = useMutation<MaintenanceFormData>(insertMaintenanceQuery, {
    context: getHasuraRoleContext(UserRole.ManageDatahub),
    fetchPolicy: "network-only",
  });

  const [updateMaintenanceBannerData] = useMutation<
    MaintenanceResponseDataIn,
    MaintenanceUpdateQueryVars
  >(updateMaintenanceQuery, {
    context: getHasuraRoleContext(UserRole.ManageDatahub),
    fetchPolicy: "network-only",
  });

  const updateMethod = async (values: MaintenanceDataOut) => {
    await updateMaintenanceBannerData({
      variables: {
        ...values,
        id: editModeData?.id || "",
        setOnImmediately: values.setOnImmediately ? new Date().toISOString() : null,
        manualIsActive: false,
      },
    });
  };

  const insertMethod = async (values: MaintenanceFormData) => {
    await insertMaintenanceBannerData({
      variables: {
        ...values,
        setOnImmediately: values.setOnImmediately ? new Date().toISOString() : null,
        bannerEndDate: values.bannerEndDate.format(isoDayMonthYearFormat),
        bannerStartDate: values.bannerStartDate.format(isoDayMonthYearFormat),
        breakEndDate: values.breakEndDate.format(isoDayMonthYearFormat),
        breakStartDate: values.breakStartDate.format(isoDayMonthYearFormat),
        maintenanceManualToggle: {
          manualIsActive: false,
        },
      },
    });
  };

  const onSubmit = async (values: MaintenanceFormData) => {
    setQueryError(false);
    try {
      if (editModeData) {
        // todo: fix types
        await updateMethod(({ ...values } as unknown) as MaintenanceDataOut);
      } else {
        await insertMethod(values);
      }

      closeModal(true);
    } catch (e) {
      setQueryError(true);
      console.error(e);
    }
  };

  return (
    <FormProvider {...hookFormMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <BasicModal
          centered={true}
          size={"lg"}
          header={t(`maintenance.${editModeData ? "updateOutage" : "createOutage"}`)}
          cancelText={t("common.back")}
          proceedText={t(`maintenance.${editModeData ? "updateNotice" : "setNewNotice"}`)}
          disableProceed={!formIsFilled}
          onProceed={() => handleSubmit(onSubmit)()}
          onCancel={closeModal}
        >
          <>
            {queryError && (
              <Notification type="danger">{t("importProduct.previewError")}</Notification>
            )}
            <MaintenanceNotificationSection
              editModeData={editModeData}
              hasEmptyFields={setNotificationIsFilled}
            />
            <MaintenanceBreakSection
              editModeData={editModeData}
              hasEmptyFields={setBreakIsFilled}
            />
          </>
        </BasicModal>
      </Form>
    </FormProvider>
  );
};
