import React, { createContext, ReactNode, useState } from "react";

type MaintenanceCtxType = {
  shouldReEvaluate: boolean;
  updateStatus: (bool: boolean) => void;
};

export const MaintenanceCtx = createContext<MaintenanceCtxType>({
  updateStatus: (bool) => bool,
  shouldReEvaluate: false,
});

export const MaintenanceCtxProvider: React.FC<ReactNode> = ({ children }) => {
  const [shouldReEvaluate, setShouldReEvaluate] = useState<boolean>(false);

  const updateStatus = (bool: boolean) => setShouldReEvaluate(bool);

  return (
    <MaintenanceCtx.Provider value={{ shouldReEvaluate, updateStatus }}>
      {children}
    </MaintenanceCtx.Provider>
  );
};
