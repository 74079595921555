import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import ModalHeaderIconContainer from "../ModalHeaderIconContainer";
import { ConfirmModal, ErrorModal } from "../DataHubModal";

type PublishModalProps = {
  publishResult: "ok" | "error";
  onHide: () => void;
};

const PublishModal = ({ publishResult, onHide }: PublishModalProps) => {
  const error = publishResult === "error";
  const { t } = useTranslation();

  const CloseButton = (
    <div className="d-flex w-100 mt-2 justify-content-center">
      <Button onClick={() => onHide()} variant="light">
        {t("productInfo.modalCloseButton")}
      </Button>
    </div>
  );

  return error ? (
    <ErrorModal headerText={t("productInfo.publishErrorHeader")} onHide={onHide}>
      <div>
        <p>{t("productInfo.publishErrorDesc1")}</p>
        <p>{t("productInfo.publishErrorDesc2")}</p>
      </div>
      {CloseButton}
    </ErrorModal>
  ) : (
    <ConfirmModal headerText={t("productInfo.publishOkHeader")} onHide={onHide}>
      <div>
        <div className="d-flex">
          <ModalHeaderIconContainer className="mr-2">
            <Icon color="green" name="exclamation-circle" />
          </ModalHeaderIconContainer>
          <p>{t("productInfo.publishOkCuratorDesc")}</p>
        </div>
      </div>
      {CloseButton}
    </ConfirmModal>
  );
};

export default PublishModal;
