import Select, {
  components,
  CSSObjectWithLabel,
  GroupBase,
  OptionProps,
  Props,
} from "react-select";

import Checkbox from "./Checkbox";
import React from "react";
import { SelectOption } from "../types";
import { useTranslation } from "react-i18next";

export const basicSelectStyles = {
  container: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontWeight: 400,
    fontFamily: "var(--font-light)",
  }),
  // TODO: Replace with the actual state typing.
  // eslint-disable-next-line
  menu: (styles: CSSObjectWithLabel, state: any) => {
    if (state?.selectProps?.isRelative) {
      styles.position = "relative";
    }
    return {
      ...styles,
      fontWeight: 400,
      fontFamily: "var(--font-light)",
      zIndex: 2,
    };
  },
  // TODO: Replace with the actual state typing.
  // eslint-disable-next-line
  option: (styles: CSSObjectWithLabel, state: any) => {
    const optionHasError = state.options.find(
      (option: { value: string; label: string; hasError?: boolean }) => option.value === state.value
    )?.hasError;

    return {
      ...styles,
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      border: optionHasError && "1px solid var(--color-primary)",
      paddingTop: "1em",
      paddingBottom: "1em",
      backgroundColor: state.isFocused
        ? "var(--color-gray-200)"
        : optionHasError
        ? "var(--color-primary-light)"
        : "var(--color-white)",
      color: optionHasError ? "var(--color-error)" : "var(--color-black)",
      "&:after": {
        display: "block",
        content: '""',
        backgroundColor: optionHasError && "var(--color-error)",
        maskImage:
          optionHasError &&
          `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M 16 3.21875 L 15.125 4.71875 L 3.125 25.5 L 2.28125 27 L 29.71875 27 L 28.875 25.5 L 16.875 4.71875 Z M 16 7.21875 L 26.25 25 L 5.75 25 Z M 15 14 L 15 20 L 17 20 L 17 14 Z M 15 21 L 15 23 L 17 23 L 17 21 Z'/%3E%3C/svg%3E")`,

        backgroundSize: "100% 100%",
        height: "18px",
        width: "16px",
        alignSelf: "center",
      },
      "&:first-of-type": {
        borderBottom: "0",
      },
      "&:active": {
        backgroundColor: "var(--color-primary)",
        color: "var(--color-black)",
      },
      "&:hover": {
        backgroundColor: "var(--color-gray-200)",
      },
    };
  },
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    color: "var(--color-black)",
    border: "1px solid #343a40",
    backgroundColor: "#f8f9fa",
    borderRadius: 0,
    "&:hover": {
      borderColor: "#343a40",
    },
    padding: "1px",
    cursor: "pointer",
    fontWeight: 400,
  }),
  multiValue: (styles: CSSObjectWithLabel, state: { isDisabled: boolean }) => ({
    ...styles,
    color: state.isDisabled ? "var(--color-gray-600)" : "initial",
    "& div": {
      color: "inherit",
    },
    backgroundColor: "var(--color-light-blue)",
  }),
  placeholder: (styles: CSSObjectWithLabel, state: { isDisabled: boolean }) => ({
    ...styles,
    color: state.isDisabled ? "hsl(0, 0%, 60%)" : "var(--color-black)",
    fontWeight: 400,
  }),
  indicatorSeparator: (styles: CSSObjectWithLabel) => ({
    ...styles,
    marginLeft: "5px",
  }),
  // TODO: Replace with the actual state typing.
  // eslint-disable-next-line
  dropdownIndicator: (styles: CSSObjectWithLabel, state: any) => {
    const hasError = !!state.options.find(
      (option: { value: string; label: string; hasError?: boolean }) => option.hasError
    );

    return {
      ...styles,
      height: "100%",
      marginRight: "-2px",
      backgroundColor: hasError ? "var(--color-primary-light)" : "",
      color: hasError ? "var(--color-error)" : "",
      border: hasError ? "1px solid var(--color-error)" : "",
    };
  },
};

export const CheckboxOption = <
  IsMulti extends boolean = false,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
>(
  props: OptionProps<SelectOption, IsMulti, Group>
) => {
  // Changing of the selected state is handled by Option component so Checkbox is used
  // only for visualisation. However, onChange handler is needed when Checkbox is used
  // as controlled component so empty handler function is provided
  const onChangeHandler = () => {}; // eslint-disable-line

  return (
    <components.Option {...props}>
      <Checkbox
        id={props.label}
        name={props.label}
        value={props.data.value}
        style={{
          pointerEvents: "none",
          minHeight: "0",
        }}
        checked={props.isSelected}
        onChange={onChangeHandler}
        label={<>{props.label}</>}
      />
    </components.Option>
  );
};

export const DatahubSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group> & { isRelative?: boolean }
) => {
  const { t } = useTranslation();

  return (
    <Select
      styles={basicSelectStyles}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#e0eefd",
          primary: "#1f2123",
          danger: "#1f2123",
          dangerLight: "#e0eefd",
        },
      })}
      placeholder={t("select.placeholder")}
      {...props}
    />
  );
};
