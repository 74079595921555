import {
  createProductGroupPermissionsMutation,
  CreateProductGroupPermissionsResult,
  CreateProductGroupPermissionsVariables,
} from "../../graphqlQueries/createProductGroupPermissions";
import GroupForm, { ProductGroupFormValues } from "./GroupForm";
import {
  insertProductGroupMutation,
  InsertProductGroupResult,
  InsertProductGroupVariables,
} from "../../graphqlQueries/productGroup/insertProductGroup";
import { getProductGroupsQuery } from "../../graphqlQueries/productGroup/getProductGroups";
import { AlertProps, ProductGroupProductInput, ProductGroupRole, UserRole } from "../../types";

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { getHasuraRoleContext } from "../../utils/functions";
import { userInfoVar } from "../../utils/ApolloCache";
import { getEditPath, getProductGroupVariables } from "../../utils/productGroupUtils";
import {
  ProductGroupImage,
  ProductGroupProductInformation,
  updateProductGroupMutation,
  UpdateProductGroupResult,
  UpdateProductGroupVariables,
} from "../../graphqlQueries/productGroup/updateProductGroup";

type CreateGroupFormProps = {
  rootPagePath: string;
};

const CreateGroupForm: React.FunctionComponent<CreateGroupFormProps> = ({ rootPagePath }) => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const { t } = useTranslation();
  const [alert, setAlert] = useState<AlertProps | undefined>(undefined);

  const onSubmit = async (
    formValues: ProductGroupFormValues,
    products: ProductGroupProductInput[],
    editedProductDetails?: ProductGroupProductInformation[],
    editedProductImages?: ProductGroupImage[]
  ) => {
    const addProducts = products.filter((p) => !!p.productGroupdId);
    const variables = {
      productGroup: {
        name: formValues.groupName,
        description: formValues.groupInstructions,
        products: { data: addProducts },
      },
    };
    try {
      const result = await apolloClient.mutate<
        InsertProductGroupResult,
        InsertProductGroupVariables
      >({
        mutation: insertProductGroupMutation,
        variables,
        context: getHasuraRoleContext(UserRole.ManageProductGroup),
      });

      const resultData = result.data?.insertProductGroupOne;
      const productGroupId = resultData?.id;
      if (!productGroupId) {
        throw new Error("No id returned");
      }
      const userId = userInfoVar()?.userId;
      if (!userId) {
        throw new Error("No userId found");
      }

      const usersCompanyIds = userInfoVar()?.companyIds ?? [];
      const userCompanyPermissions = usersCompanyIds.map((companyId) => ({
        companyId,
        role: ProductGroupRole.Admin,
      }));

      const usersFromDb: {
        userId: string;
        role: ProductGroupRole;
      }[] =
        formValues?.emailsToInvite?.map((user) => ({
          userId: user.value,
          role: ProductGroupRole.Editor,
        })) || [];

      const organizationsFromDb: { companyId: string; role: ProductGroupRole }[] =
        formValues?.organizationsToInvite?.map((organization) => ({
          companyId: organization.value,
          role: ProductGroupRole.Editor,
        })) || [];

      await apolloClient.mutate<
        CreateProductGroupPermissionsResult,
        CreateProductGroupPermissionsVariables
      >({
        mutation: createProductGroupPermissionsMutation,
        variables: {
          productGroupId: productGroupId,
          users: [{ userId, role: ProductGroupRole.Admin }, ...usersFromDb],
          companies: [...userCompanyPermissions, ...organizationsFromDb],
        },
        context: getHasuraRoleContext(UserRole.ManageProductGroup),
        refetchQueries: [getProductGroupsQuery],
      });

      const createProducts = products.filter((p) => !p.productGroupdId);

      if (resultData && createProducts.length > 0) {
        const variables: UpdateProductGroupVariables = getProductGroupVariables({
          groupId: productGroupId,
          products: createProducts.map((p) => ({ ...p, productGroupId })),
          productGroup: {
            name: formValues.groupName,
            description: formValues.groupInstructions,
          },
          editedProductImages,
          editedProductDetailsFromDb: [],
          editedProductDetails,
        });
        const hasEditedProductImages = editedProductImages && editedProductImages?.length > 0;
        await apolloClient.mutate<UpdateProductGroupResult, UpdateProductGroupVariables>({
          mutation: updateProductGroupMutation(hasEditedProductImages),
          variables,
          context: getHasuraRoleContext(UserRole.ManageProductGroup),
          refetchQueries: [getProductGroupsQuery],
        });
      }
      history.replace(getEditPath(rootPagePath, productGroupId), {
        alert: { type: "success", text: t("common.success") },
      });
    } catch (err) {
      console.error(err);
      const error = err as Error;
      setAlert({ type: "danger", text: error.message });
    }
  };

  return (
    <GroupForm
      onSubmit={onSubmit}
      rootPagePath={rootPagePath}
      productGroupRole={ProductGroupRole.Admin}
      alert={alert}
      closeAlert={() => setAlert(undefined)}
    />
  );
};

export default CreateGroupForm;
