import moment from "moment";
import { MaintenanceDataOut, PrioritizedMaintenanceData } from "../types/bannerTypes";

export const sortMaintenancesByBreakTime = (data: MaintenanceDataOut[]) => {
  return data.sort((a, b) => {
    const dateA = moment(a.breakStartDate, "DD-MM-YYYY");
    const dateB = moment(b.breakStartDate, "DD-MM-YYYY");
    const startTimeA = moment(a.breakStartTime, "HH:mm");
    const startTimeB = moment(b.breakStartTime, "HH:mm");
    const endTimeA = moment(a.breakEndTime, "HH:mm");
    const endTimeB = moment(b.breakEndTime, "HH:mm");

    if (dateA.isSame(dateB)) {
      // If also time is same compare end time
      if (!startTimeA.isSame(startTimeB)) {
        return startTimeA.diff(startTimeB);
      }
      return endTimeA.diff(endTimeB);
    }

    return dateA.diff(dateB);
  });
};

export const sortByActive = (arr: PrioritizedMaintenanceData[]) =>
  arr.sort((a, b) => Number(b.active) - Number(a.active));
/*
  Find out if there is more items with active value true.
  Compare these values and find which one is prioritized.
  Prioritized is the one with manualActive === true.
 */
export const removeDuplicateActives = (
  items: PrioritizedMaintenanceData[]
): PrioritizedMaintenanceData[] => {
  const activeArr = items.filter((it) => it.active);
  const notActiveArr = items.filter((it) => !it.active);
  const prioritized = new Set<string>();

  if (activeArr.length > 1) {
    // figure which one is prioritized
    activeArr.forEach((it) => {
      if (it.maintenanceManualToggle.manualIsActive) {
        prioritized.add(it.id);
      }
    });
  }

  const activeBanner = activeArr.map((it) => {
    if (prioritized.size > 0 && !prioritized.has(it.id)) {
      return {
        ...it,
        active: false,
      };
    }

    return it;
  });

  return [...activeBanner, ...notActiveArr];
};

const setBannerActiveIfManuallyToggled = (items: MaintenanceDataOut[]) => {
  return items.map((it) => {
    const isToggledBeforeStart =
      moment(it.maintenanceManualToggle.updatedAt, "YYYY-MM-DD") < moment(it.bannerStartDate);
    const isToggledAfterEnd =
      moment(it.maintenanceManualToggle.updatedAt, "YYYY-MM-DD") > moment(it.bannerEndDate);
    const isBetween = moment(it.maintenanceManualToggle.updatedAt, "YYYY-MM-DD").isBetween(
      moment(it.bannerStartDate),
      moment(it.bannerEndDate)
    );
    const startDateIsSame =
      moment(it.maintenanceManualToggle.updatedAt).format("YYYY-MM-DD") ===
      moment(it.bannerStartDate).format("YYYY-MM-DD");

    // if the manual toggle has been toggled WHILE a the banner is on,
    // its updatedAt timestamp will between end and start date of banner
    // which means banner should "naturally" go off
    const isSetActiveManuallyToday = startDateIsSame && it.maintenanceManualToggle.manualIsActive;

    if (isBetween || isSetActiveManuallyToday) {
      return {
        ...it,
        active: it.maintenanceManualToggle.manualIsActive,
      };
    }
    // manual toggle has been pressed before banner goes on naturally, means manualIsActive is false and it passed the previous check
    if (isToggledBeforeStart || isToggledAfterEnd) {
      return {
        ...it,
        active: it.maintenanceManualToggle.manualIsActive,
      };
    }
    // manual toggle has been never toggled
    // or bannerEndDate has been passed,
    // use the natural timing to show banner
    return {
      ...it,
      active: isBannerActive({
        startTime: moment(it.bannerStartTime, "HH:mm"),
        startDate: moment(it.bannerStartDate),
        endTime: moment(it.bannerEndTime, "HH:mm"),
        endDate: moment(it.bannerEndDate),
      }),
    };
  });
};

const isBigger = (format: string) => (a: moment.Moment, b: moment.Moment) =>
  a.format(format) > b.format(format);

const isEqual = (format: string) => (a: moment.Moment, b: moment.Moment) =>
  a.format(format) === b.format(format);

// Figure out if bannerStart date and time are after the current date and time
// Also figure out if bannerEnd date and time are in past
export const isBannerActive = ({
  startTime,
  startDate,
  endTime,
  endDate,
}: {
  startTime: moment.Moment;
  startDate: moment.Moment;
  endTime: moment.Moment;
  endDate: moment.Moment;
}): boolean => {
  const currentDateTime = moment();
  const isBiggerDate = isBigger("YYYY-MM-DD");
  const isBiggerTime = isBigger("HH:mm");
  const isEqualDate = isEqual("YYYY-MM-DD");

  const startIsInFuture = isBiggerDate(startDate, currentDateTime);
  const startIsInPast = isBiggerDate(currentDateTime, startDate);
  const startIsCurrentDate = isEqualDate(startDate, currentDateTime);
  const startTimeInFuture = isBiggerTime(startTime, currentDateTime);

  const endIsCurrentDate = isEqualDate(currentDateTime, endDate);
  const endIsInPast = isBiggerDate(currentDateTime, endDate);
  const endIsInFuture = isBiggerDate(endDate, currentDateTime);
  const endTimeInPast = isBiggerTime(currentDateTime, endTime);

  // if start date in future return false
  if (startIsInFuture) {
    return false;
  }
  // if start date is current
  if (startIsCurrentDate) {
    // start time is past
    if (startTimeInFuture) {
      return false;
    }
    // check if end date is today and check time
    if (endIsCurrentDate && !endTimeInPast) {
      return true;
    }
    if (endIsCurrentDate && endTimeInPast) {
      return false;
    }
    if (!startTimeInFuture) {
      return true;
    }
  }
  // if start date is in past
  if (startIsInPast) {
    if (endIsInPast) {
      return false;
    }
    // if current date check time
    if (endIsCurrentDate) {
      // return true if banner end time is larger than current time
      return !endTimeInPast;
    }
    // if end date in future return true
    if (endIsInFuture) {
      return true;
    }
  }

  return true;
};

export const getActiveMaintenance = (items: MaintenanceDataOut[]): PrioritizedMaintenanceData[] => {
  const itemsWithSetActive: { id: string; activeDate: Date }[] = [];
  const compareDates = (a: Date, b: Date) => (a > b ? a : b);
  // find items which would override current active banner based on which one is newest
  items.forEach((it) => {
    const { updatedAt: manualUpdatedAt } = it.maintenanceManualToggle;
    // no dates, early return
    if (!manualUpdatedAt && !it.setOnImmediately) {
      return;
    }
    // has been se on immediately, new entry
    if (!manualUpdatedAt && it.setOnImmediately) {
      itemsWithSetActive.push({
        id: it.id,
        activeDate: new Date(it.setOnImmediately),
      });
    }
    // old entry but edited to be set on immediately
    if (manualUpdatedAt && it.setOnImmediately) {
      itemsWithSetActive.push({
        id: it.id,
        activeDate: compareDates(new Date(manualUpdatedAt), new Date(it.setOnImmediately)),
      });
    }
  });

  if (itemsWithSetActive.length > 0) {
    // find newest active and set to true, others to false
    const newest = itemsWithSetActive.reduce((acc, curr) =>
      acc.activeDate > curr.activeDate ? acc : curr
    );

    const res = items.map((it) => {
      // If banner has been setOnImmediately we should if it has expired
      const isExpired = it.setOnImmediately ? new Date(it.setOnImmediately) < new Date() : false;
      // use setOnImmediately as boolean instead of isBannerActive()
      const useSetOnImmediately = !!it.setOnImmediately && !isExpired;

      const shouldBeActive =
        useSetOnImmediately ||
        isBannerActive({
          startTime: moment(it.bannerStartTime, "HH:mm"),
          startDate: moment(it.bannerStartDate),
          endTime: moment(it.bannerEndTime, "HH:mm"),
          endDate: moment(it.bannerEndDate),
        });

      if (it.id === newest.id) {
        return {
          ...it,
          active: shouldBeActive,
        };
      } else {
        return {
          ...it,
          active: false,
        };
      }
    });
    // remove duplicate actives if any and sort to right order
    return sortByActive(removeDuplicateActives(res));
  }

  const activeBanners = setBannerActiveIfManuallyToggled(items);

  return sortByActive(removeDuplicateActives(activeBanners));
};
