import { gql } from "@apollo/client";
import { AggregateCount } from "../types";
import fragments from "./fragments";

const getCompanyNewCommentsCount = gql`
  query GetCompanyNewCommentsCount($companyId: uuid!) {
    product(
      where: { companyId: { _eq: $companyId }, deletedAt: { _is_null: true } }
      order_by: { updatedAt: desc }
    ) {
      id
      ...ProductNewCommentsCountTc
    }
  }
  ${fragments.product.newCommentsCountForTc}
`;

export type GetCompanyNewCommentsCountResult = {
  product: {
    id: string;
    productNewCommentsAggregate: AggregateCount;
  }[];
};

export default getCompanyNewCommentsCount;
