import { gql } from "@apollo/client";

export const insertMaintenanceQuery = gql`
  mutation InsertMaintenance(
    $titleEn: String!
    $titleFi: String!
    $contentEn: String!
    $contentFi: String!
    $bannerStartTime: time!
    $bannerStartDate: date!
    $bannerEndTime: time!
    $bannerEndDate: date!
    $breakStartTime: time!
    $breakStartDate: date!
    $breakEndTime: time!
    $breakEndDate: date!
    $setOnImmediately: timestamptz!
    $maintenanceManualToggle: maintenanceManualToggle_insert_input!
  ) {
    insertMaintenanceBanner(
      objects: {
        titleEn: $titleEn
        titleFi: $titleFi
        contentEn: $contentEn
        contentFi: $contentFi
        bannerStartTime: $bannerStartTime
        bannerStartDate: $bannerStartDate
        bannerEndTime: $bannerEndTime
        bannerEndDate: $bannerEndDate
        breakStartTime: $breakStartTime
        breakStartDate: $breakStartDate
        breakEndTime: $breakEndTime
        breakEndDate: $breakEndDate
        setOnImmediately: $setOnImmediately
        maintenanceManualToggle: { data: $maintenanceManualToggle }
      }
    ) {
      affected_rows
    }
  }
`;
