import { DataHubButton, DataHubButtonVariant } from "./DataHubButton";
import { OverlayTrigger, Popover } from "react-bootstrap";
import React, { CSSProperties, ReactElement } from "react";

import Icon from "./Icon";
import { OverlayTriggerType } from "react-bootstrap/esm/OverlayTrigger";
import { Placement } from "react-bootstrap/esm/Overlay";

type DataHubPopOverProps = {
  trigger?: OverlayTriggerType | OverlayTriggerType[] | undefined;
  triggerButton: ReactElement;
  placement: Placement;
  header: string;
  confirmText?: string;
  confirmButtonVariant?: DataHubButtonVariant;
  cancelButtonVariant?: DataHubButtonVariant;
  cancelText?: string;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onConfirm?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  show?: boolean;
  variant?: "basic" | "confirmAndCancel";
  icon?: "warning-triangle";
  customStyle?: CSSProperties;
  customClassName?: string;
};

export const DataHubPopOver: React.FunctionComponent<DataHubPopOverProps> = ({
  trigger,
  placement,
  header,
  icon,
  triggerButton,
  show,
  confirmText,
  confirmButtonVariant = "primary",
  cancelButtonVariant = "secondary",
  cancelText,
  onCancel,
  onConfirm,
  variant = "basic",
  children,
  customClassName,
  customStyle,
}) => {
  return (
    <OverlayTrigger
      show={show}
      trigger={trigger}
      placement={placement}
      overlay={
        <Popover
          id={`popover-positioned-${placement}`}
          style={customStyle}
          bsPrefix={customClassName || undefined}
        >
          <Popover.Title>
            <div className="d-flex">
              {icon && (
                <div className="mr-3">
                  <Icon size="medium" color="primary" name={icon} />
                </div>
              )}
              <div>{header}</div>
            </div>
          </Popover.Title>
          <Popover.Content>
            {children}
            {variant === "confirmAndCancel" && onCancel && onConfirm && (
              <>
                <div className="pt-4 d-flex flex-row justify-content-between">
                  <DataHubButton
                    variant={cancelButtonVariant}
                    className="mr-2"
                    style={{
                      minWidth: "150px",
                    }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onCancel(e)}
                  >
                    {cancelText}
                  </DataHubButton>
                  <DataHubButton
                    variant={confirmButtonVariant}
                    style={{
                      minWidth: "150px",
                    }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onConfirm(e)}
                  >
                    {confirmText}
                  </DataHubButton>
                </div>
              </>
            )}
          </Popover.Content>
        </Popover>
      }
    >
      {triggerButton}
    </OverlayTrigger>
  );
};
