import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Separator from "../Separator";

const DashboardSubHeader = styled.h4`
  margin-bottom: 1rem;
`;

const Support = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="row mb-5">
        <div className="col-12">
          <Separator topMarginRem={0.5} bottomMarginRem={1} />
          <DashboardSubHeader>{t("dashboard.supportHeader")}</DashboardSubHeader>
          <div>
            <div>{t("dashboard.supportInfo")}</div>
            <a href={`mailto:${t("dashboard.supportLink")}`}>{t("dashboard.supportLink")}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
