import { IconElementPosition } from "../types";
import React from "react";

type IconTextProps = {
  iconElement: JSX.Element;
  iconPosition?: IconElementPosition;
  hideIcon?: boolean;
  className?: string;
  iconSpacing?: number;
};

export const IconText: React.FC<IconTextProps> = ({
  children,
  iconElement,
  iconPosition = "left",
  hideIcon,
  className,
  iconSpacing = 2,
}) => {
  const iconLeft = iconPosition === "left";
  const getIconSpacing = () => {
    const iconSpacingClass = `${iconLeft ? "ml" : "mr"}-${iconSpacing}`;
    return !hideIcon ? iconSpacingClass : "";
  };
  return (
    <div className="d-flex align-items-center">
      {iconLeft && !hideIcon && iconElement}
      <div className={getIconSpacing()}>
        <div className={className}>{children}</div>
      </div>
      {!iconLeft && !hideIcon && iconElement}
    </div>
  );
};
