import { DatahubType, ViewMode } from "../../types";

import { ProductComment } from "../../graphqlQueries/getProductComments";
import React from "react";
import moment from "moment";

type CommentListItemHeaderProps = {
  item: ProductComment;
  notSeenText: string;
  newText: string;
  viewMode: ViewMode;
};

const CommentListItemHeader = ({
  notSeenText,
  newText,
  item,
  viewMode,
}: CommentListItemHeaderProps) => {
  const commentRecipient = item.recipientRole;
  const commentSeenByDmo = !!item.seenByDmoAt;
  const commentSeenByTc = !!item.seenByTcAt;
  const isDmoViewMode = viewMode === "dmo";

  const isCommentNewForRole =
    (commentRecipient === DatahubType.DMO && !commentSeenByDmo && isDmoViewMode) ||
    (commentRecipient === DatahubType.TC && !commentSeenByTc && !isDmoViewMode);

  const isCommentUnreadForRole =
    (commentRecipient === DatahubType.TC && !commentSeenByTc && isDmoViewMode) ||
    (commentRecipient === DatahubType.DMO && !commentSeenByDmo && !isDmoViewMode);

  return (
    <div className="d-flex justify-content-between align-items-center bg-white w-100 ml-2 mr-2 py-2">
      <h5 className="text-small color-gray-600 m-0">
        {`${moment(item.createdAt).format("D.M.YYYY HH:mm")} ${item.createdByName}, ${
          item.createdByCompany.businessName
        }`}
      </h5>
      {!item.seenByTcAt && (
        <div
          className={`text-small text-uppercase font-weight-bold ${
            isCommentNewForRole ? "color-primary-dark" : "color-gray-600"
          }`}
        >
          {isCommentNewForRole && newText}
          {isCommentUnreadForRole && notSeenText}
        </div>
      )}
    </div>
  );
};

export default CommentListItemHeader;
