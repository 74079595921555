import { gql } from "@apollo/client";

import {
  AvailableMonth,
  CurationStatus,
  DataHubImage,
  Language,
  PricingType,
  PricingUnit,
  ProductCurationStatus,
  ProductStatus,
  ProductType,
  TransportationAvailabilityEnum,
} from "../types";
import { shouldAddCapacity } from "../utils/productFormUtils/productUtils";
import { HasuraAvailabilityLanguage } from "./getProduct";

const getBusinessHoursMutation = (useCompanyHours: boolean) => {
  return useCompanyHours
    ? "businessHoursId: $businessHoursId"
    : `businessHours: {
        data:  {
          companyId: $companyId
        }
      }`;
};

const getBusinessHoursVariables = (useCompanyHours: boolean) => {
  return useCompanyHours ? "$businessHoursId: uuid!" : "";
};

const getInsertProductQueryName = (hasCapacity: boolean, useCompanyHours: boolean) => {
  return `InsertProduct${hasCapacity ? "WithCapacity" : ""}${
    !useCompanyHours ? "WithBusinessHours" : ""
  }`;
};

const buildInsertProductMutation = (type: ProductType, useCompanyHours: boolean) => {
  const hasCapacity = shouldAddCapacity(type);
  const hasAvailabilityLanguage = type === ProductType.Experience;

  return gql`
    mutation ${getInsertProductQueryName(hasCapacity, useCompanyHours)}(
      $productInformations: [hasuradb_product_information_insert_input!]!
      $type: hasuradb_product_types_enum!
      $companyId: uuid!
      $tags: [hasuradb_product_tag_insert_input!]!
      $targetGroups: [hasuradb_product_target_group_insert_input!]!
      $images: [hasuradb_product_image_insert_input!]!
      $email: String
      $phone: String
      $availableDays: [hasuradb_product_availability_insert_input!]!
      $pricing: [hasuradb_product_pricing_insert_input!]!
      $address: [hasuradb_postal_address_insert_input!]!
      $availableMonths: [hasuradb_product_available_months_insert_input!]!
      $duration: [hasuradb_product_duration_insert_input!]!
      $status: hasuradb_product_status_enum!
      $curationEnabled: Boolean!
      $videos: [hasuradb_product_video_insert_input!]!
      $accessible: Boolean
      $certification: [hasuradb_product_certificate_insert_input!]!
      $curationStatus: hasuradb_curation_status_enum
      $productCurationStatus: hasuradb_product_curation_status_enum
      ${
        hasAvailabilityLanguage
          ? "$availabilityLanguages: [hasuradb_product_availability_language_insert_input!]!"
          : ""
      }
      ${hasCapacity ? "$capacity: [hasuradb_product_capacity_insert_input!]!" : ""}
      ${getBusinessHoursVariables(useCompanyHours)}
      $socialMediaId: uuid!
    ) {
      insertProductOne(
        object: {
          companyId: $companyId
          type: $type
          productInformations: { data: $productInformations}
          productTags: { data: $tags }
          productTargetGroups: { data: $targetGroups }
          productImages: { data: $images }
          contactDetails: { data: { phone: $phone, email: $email } }
          productAvailabilities: { data: $availableDays }
          productPricings: { data: $pricing }
          postalAddresses: { data: $address }
          productAvailableMonths: { data: $availableMonths }
          productDuration: { data: $duration }
          curationEnabled: $curationEnabled
          productCurations: {
            data: { curationStatus: $curationStatus, productCurationStatus: $productCurationStatus }
          }
          status: $status
          productVideos: { data: $videos }
          accessible: $accessible
          productCertificates: { data: $certification }
          ${hasCapacity ? "productCapacities: { data: $capacity }" : ""}
          ${getBusinessHoursMutation(useCompanyHours)}
          socialMediaId: $socialMediaId
          ${
            hasAvailabilityLanguage
              ? "productAvailabilityLanguages: { data: $availabilityLanguages }"
              : ""
          }
        }
      ) {
        id
        businessHoursId
      }
    }
  `;
};

export default buildInsertProductMutation;

export type HasuraProductTag = {
  tag: string;
};

export type HasuraProductCertificate = {
  certificate: string;
};

export type HasuraProductImage = DataHubImage & {
  orderIndex?: number;
};

export type HasuraProductTargetGroup = {
  targetGroupId: string;
};

export type HasuraProductAvailability = {
  startDate: string | null;
  endDate: string | null;
  transportationAvailability?: TransportationAvailabilityEnum | null;
};

export type HasuraProductPricing = {
  fromPrice: number | null;
  toPrice: number | null;
  pricingUnit: PricingUnit | null;
  pricingType: PricingType;
};

export type HasuraProductCapacity = {
  min: number | null;
  max: number | null;
};

export type HasuraProductAddress = {
  city: string;
  postalCode: string | null;
  streetName: string;
  location?: string | null;
};

export type HasuraVerifiedLink = {
  userVerifiedAt: string | null;
  userVerifiedBy: string | null;
  url: string;
};

export type HasuraVerifiedLinkInsert = {
  data: HasuraVerifiedLink;
};

export type HasuraProductInformation = {
  name: string;
  description: string;
  language: Language;
  verifiedProductLink?: HasuraVerifiedLinkInsert;
  verifiedWebshopLink?: HasuraVerifiedLinkInsert;
  productId?: string;
};

export type HasuraProductVideoInsert = {
  title: string;
  verifiedVideoLink?: HasuraVerifiedLinkInsert;
};

export type HasuraProductDuration = {
  minutes: number;
  hours: number;
  days: number;
  weeks: number;
};

export type HasuraInsertBaseVariables = {
  productInformations: HasuraProductInformation[];
  type: ProductType;
  companyId: string;
  tags: HasuraProductTag[];
  images: HasuraProductImage[];
  targetGroups: HasuraProductTargetGroup[];
  email?: string;
  phone?: string;
  pricing: HasuraProductPricing[];
  availabilityLanguages: HasuraAvailabilityLanguage[];
  address: HasuraProductAddress;
  availableDays: HasuraProductAvailability;
  availableMonths: AvailableMonth[];
  duration?: HasuraProductDuration[];
  videos: HasuraProductVideoInsert[];
  accessible: boolean | null;
  certification: HasuraProductCertificate[];
  capacity?: HasuraProductCapacity[];
  businessHoursId?: string;
  socialMediaId: string | null;
};

export type HasuraInsertProductVariables = HasuraInsertBaseVariables & {
  status: ProductStatus.Published;
  curationEnabled: true;
  curationStatus: CurationStatus;
  productCurationStatus: ProductCurationStatus;
};

export type HasuraInsertDraftVariables = HasuraInsertBaseVariables & {
  status: ProductStatus.Draft;
  curationEnabled: false;
};

export type HasuraInsertProductResult = {
  insertProductOne: {
    id: string;
    businessHoursId: string;
  };
};
