import { Button, Col } from "react-bootstrap";

import { ReactComponent as List } from "../icons/list.svg";
import { NavLink } from "react-router-dom";
import React from "react";
import { contentMaxWidth } from "../css/breakpoints";
import logo from "../images/datahub-logo.svg";
import mobileLogo from "../images/logo.png";
import { useIsSmallScreen } from "./MediaQuery";

type HeaderProps = {
  onMobileNavigationClick: () => void;
  render: () => JSX.Element;
  hideMobileNav?: boolean;
};

const Header = ({ onMobileNavigationClick, render, hideMobileNav }: HeaderProps) => {
  const showMobileLogo = useIsSmallScreen();

  return (
    <div className="mx-auto">
      <Col
        className="d-flex justify-content-between align-items-center"
        style={{ width: contentMaxWidth, maxWidth: "100%", overflow: "hidden" }}
      >
        <div className="py-1 px-0 flex-grow-1 d-lg-flex align-items-center">
          <NavLink to="/">
            <img
              className="d-block"
              style={{ height: "40px" }}
              alt="Visit Finland DataHub"
              src={showMobileLogo ? mobileLogo : logo}
            />
          </NavLink>
        </div>
        <div className="d-flex">{render()}</div>
        {!hideMobileNav && (
          <div className="d-lg-none mr-2">
            <Button
              variant="secondary"
              onClick={() => {
                onMobileNavigationClick();
              }}
            >
              <List />
            </Button>
          </div>
        )}
      </Col>
    </div>
  );
};

export default Header;
