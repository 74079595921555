import React from "react";
import Loading from "../Loading";

const LoadingResults = () => {
  return (
    <div className="p-4 bg-4">
      <Loading i18nKey="curatorWorkspace.loadingResults" />
    </div>
  );
};

export default LoadingResults;
