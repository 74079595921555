import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Checkbox from "../../components/Checkbox";
import {
  GetJoinRequestsForUserResponse,
  GetJoinRequestsForUserVariables,
  getJoinRequestsForUser,
} from "../../graphqlQueries/getJoinRequests";
import {
  JoinRequestObject,
  JoinRequestResult,
  insertJoinRequests,
} from "../../graphqlQueries/joinCompany";
import { CompanyInformation, UserRole } from "../../types";
import { userInfoVar } from "../../utils/ApolloCache";
import { getHasuraRoleContext } from "../../utils/functions";
import CreateAccountWizard from "./CreateAccountWizard";
import JoinRequestSent from "./JoinRequestSent";
import StepHeader from "./StepHeader";

type Company = { id: string; officialName: string; businessName: string };

type CompanyExistsWizardProps = {
  companyInformation: CompanyInformation | undefined;
  companies: Company[] | undefined;
};

export type CompanyExistsWizardStep =
  | "JoinOrCreateCompanyIntro"
  | "JoinRequestSent"
  | "CreateNewCompany";

const generateJoinRequestObjects = (
  companyIdArray: string[],
  userId: string,
  userEmail: string
): JoinRequestObject[] => {
  return companyIdArray.map((companyId) => {
    return { companyId, userId, userEmail };
  });
};

const CompanyExistsWizard = ({ companyInformation, companies }: CompanyExistsWizardProps) => {
  const { t } = useTranslation();

  const [step, setStep] = useState<CompanyExistsWizardStep>("JoinOrCreateCompanyIntro");
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const userInfo = userInfoVar();
  const userId = userInfo?.userId || "";
  const userCompanyIds = userInfoVar()?.companyIds;

  const { data: joinRequestsForUser } = useQuery<
    GetJoinRequestsForUserResponse,
    GetJoinRequestsForUserVariables
  >(getJoinRequestsForUser, {
    variables: { userId: userId },
    context: getHasuraRoleContext(UserRole.CreateCompany),
  });

  const showBackButton =
    (userCompanyIds && userCompanyIds.length > 0) ||
    (joinRequestsForUser && joinRequestsForUser?.companyJoinRequest.length > 0);

  const businessDataRow = ({
    title,
    value,
  }: {
    title: string;
    value: string | undefined;
  }): JSX.Element => {
    return (
      <div className="d-flex flex-row">
        <span className="col col-4 p-0">{title}</span>
        <span className="col col-8 font-heavy p-0">{value}</span>
      </div>
    );
  };

  const [sendJoinRequests] = useMutation<JoinRequestResult, { objects: JoinRequestObject[] }>(
    insertJoinRequests
  );

  const selectedCompaniesToggler = (checkboxId: string) => {
    const selectedCompaniesCopy = [...selectedCompanies];
    const checkboxIdIndexInSelected = selectedCompaniesCopy.indexOf(checkboxId);
    if (checkboxIdIndexInSelected > -1) {
      selectedCompaniesCopy.splice(checkboxIdIndexInSelected, 1);
    } else {
      selectedCompaniesCopy.push(checkboxId);
    }
    setSelectedCompanies(selectedCompaniesCopy);
  };

  return (
    <div>
      {step === "JoinOrCreateCompanyIntro" && (
        <div>
          <StepHeader>{t("signup.companyExistsWizardHeader")}</StepHeader>
          <div className="mt-4 mb-4">
            {businessDataRow({
              title: t("signup.companyExistsWizardBusinessName"),
              value: companyInformation?.companyName,
            })}
            {businessDataRow({
              title: t("signup.companyExistsWizardBusinessId"),
              value: companyInformation?.businessId,
            })}
          </div>
          {companies && companies.length > 0 && (
            <div>
              <p className="mt-3 mb-3 font-heavy">
                {t("signup.companyExistsWizardCompaniesFound")}
              </p>
              <div className="mb-3">
                {companies?.map((company) => {
                  const isJoinRequest = joinRequestsForUser?.companyJoinRequest.some(
                    (req) => req.companyId === company.id
                  );
                  const isInCompany = userCompanyIds?.includes(company.id);
                  return (
                    <div key={company.id} className="bg-gray-100 border-gray-300 p-2 mb-2 ">
                      <Checkbox
                        id={company.id}
                        name={company.id}
                        className={"d-flex flex-row align-items-center"}
                        checked={
                          isJoinRequest || isInCompany || selectedCompanies.includes(company.id)
                        }
                        label={
                          <>
                            <p className="mb-n1">{company.businessName}</p>

                            {(isJoinRequest || isInCompany) && (
                              <span className="text-small color-gray-700">
                                {isInCompany
                                  ? t("signup.companyExistsWizardUserAlreadyInCompany")
                                  : t("signup.companyExistsWizardUserJoinRequestSentToCompany")}
                              </span>
                            )}
                          </>
                        }
                        value={company.id}
                        onChange={(e) => selectedCompaniesToggler(e.target.value)}
                        disabled={isJoinRequest || isInCompany}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="d-flex flex-column align-items-center">
                <Button
                  variant="primary"
                  className="col col-6 btn-fit-content"
                  disabled={selectedCompanies.length === 0}
                  onClick={() => {
                    if (userInfo && userInfo.email) {
                      sendJoinRequests({
                        variables: {
                          objects: generateJoinRequestObjects(
                            selectedCompanies,
                            userInfo.userId,
                            userInfo.email
                          ),
                        },
                        refetchQueries: [getJoinRequestsForUser],
                        context: getHasuraRoleContext(UserRole.CreateCompany),
                      }).then(() => setStep("JoinRequestSent"));
                    }
                  }}
                >
                  {t("signup.companyExistsWizardJoinRequestButton")}
                </Button>
              </div>
              <p className="mb-4 mt-4">{t("signup.companyExistsWizardAddNewCompanyText")}</p>
            </div>
          )}
          <div className="d-flex flex-column align-items-center mb-5">
            <Button
              variant="light"
              className="col col-6 btn-fit-content"
              onClick={() => {
                setStep("CreateNewCompany");
              }}
            >
              {t("signup.companyExistsWizardAddNewCompanyButton")}
            </Button>
          </div>
          {showBackButton && (
            <Link to="/">{t("signup.companyExistsWizardBacktoDatahubButton")}</Link>
          )}
          <p className="mt-4 px-3 text-smaller color-gray-800 text-center">
            <Trans i18nKey="signup.wrongBusinessIdDescription">
              if you have registered i18n placeholder
              <a href="mailto:datahub@visitfinland.com">datahub@visitfinland.com</a>
            </Trans>
          </p>
        </div>
      )}
      {step === "JoinRequestSent" && <JoinRequestSent />}
      {step === "CreateNewCompany" && (
        <CreateAccountWizard
          companyInformation={companyInformation}
          backButtonOnClick={() => setStep("JoinOrCreateCompanyIntro")}
        />
      )}
    </div>
  );
};

export default CompanyExistsWizard;
