import styled from "styled-components";
import { breakpointPixels } from "../css/breakpoints";

const ListItemThumbnailContainer = styled.div`
  display: none;
  @media (max-width: ${breakpointPixels.md}) {
    display: block;
  }
  @media (min-width: ${breakpointPixels.xl}) {
    display: block;
  }
`;

export default ListItemThumbnailContainer;
