import { TFunction } from "i18next";
import moment from "moment";

import {
  ColumnList,
  Company,
  CompanyList,
  CuratorAreaCompanies,
  TableFooter,
} from "../types/companyTypes";

const baseListing = ({
  businessName,
  postalAddresses,
  businessId,
  createdAt,
  productsAggregate,
  products,
  datahubType,
}: Company | CuratorAreaCompanies) => ({
  businessName,
  city: postalAddresses[0]?.city || "",
  businessId: businessId || "",
  productUpdate: products?.length > 0 ? products[0].updatedAt : "",
  productCount: productsAggregate.aggregate.count || 0,
  datahubType,
  createdAt,
});

const companyListing = ({ contactDetails, websiteUrl }: CuratorAreaCompanies) => ({
  contactDetails: contactDetails.map((item) =>
    item.email ? `${item.email}\n\n${item.phone}` : ""
  ),
  websiteUrl: websiteUrl || "",
});

const adminDashboardListing = ({
  officialName,
  businessEntity,
  createdAt,
  id,
  productPostalCodes,
}: Company) => {
  const postalCodes = productPostalCodes
    .map(({ postalAddresses }) => postalAddresses.map(({ postalCode }) => postalCode))
    .flat();

  return {
    businessId: businessEntity?.businessId || "",
    ellipsis: {
      id,
      officialName,
      createdAt,
      productPostalCodes: postalCodes,
    },
  };
};

export const composeCompanyListingData = (companies: CuratorAreaCompanies[]): CompanyList[] =>
  companies.map((dbCompany: CuratorAreaCompanies) => ({
    ...companyListing(dbCompany),
    ...baseListing(dbCompany),
  })) || [];

export const composeCompanyData = (companies: Company[]): CompanyList[] =>
  companies.map((dbCompany: Company) => ({
    ...baseListing(dbCompany),
    ...adminDashboardListing(dbCompany),
  })) || [];

export const composeTableColumns = (companyData: CompanyList[], t: TFunction): ColumnList[] => {
  const columnList: ColumnList[] = [];
  const keySet = new Set();

  companyData.forEach((item) =>
    Object.entries(item).forEach(([key, value]) => {
      if (value) {
        keySet.add(key);
      }
    })
  );

  columnList.push({
    Header: t<string>("companyTable.marketingNameHeader"),
    accessor: "businessName" as const,
    Footer: (props: TableFooter): string => {
      const sum = props.globalFilteredRows.reduce(
        (acc, { original: { productCount } }) => (acc += productCount),
        0
      );

      const companyAndProductText = t("companies.totalCompaniesAndProducts", {
        companyCount: props.globalFilteredRows.length,
        productCount: sum,
      });

      const companyText = t("companies.totalCompanies", {
        companyCount: props.globalFilteredRows.length,
      });

      return sum > 1 ? companyAndProductText : companyText;
    },
  });

  columnList.push({
    Header: t<string>("companyTable.cityHeader"),
    accessor: "city" as const,
  });

  if (keySet.has("businessId")) {
    columnList.push({
      Header: t<string>("companyTable.businessIdHeader"),
      accessor: "businessId" as const,
    });
  }

  if (keySet.has("contactDetails")) {
    columnList.push({
      Header: t<string>("companyTable.contactDetails"),
      accessor: "contactDetails" as const,
    });
  }

  if (keySet.has("websiteUrl")) {
    columnList.push({
      Header: t<string>("companyTable.websiteUrl"),
      accessor: "websiteUrl" as const,
    });
  }

  if (keySet.has("productCount")) {
    columnList.push({
      Header: t<string>("companyTable.productCountHeader"),
      accessor: "productCount" as const,
    });
  }

  if (keySet.has("productUpdate")) {
    columnList.push({
      Header: t<string>("companyTable.lastProductUpdate"),
      accessor: "productUpdate" as const,
      Cell: (props: { value: string }) => {
        if (!props.value) {
          return "-";
        }

        return moment(props.value).format("D.M.YYYY");
      },
    });
  }

  if (keySet.has("datahubType")) {
    columnList.push({
      Header: t<string>("companyTable.companyType"),
      accessor: "datahubType" as const,
      Cell: (props: { value: string }) => {
        return props.value.toUpperCase();
      },
    });
  }

  columnList.push({
    accessor: "ellipsis" as const,
  });

  return columnList;
};
