import { gql } from "@apollo/client";

import { UserRole } from "../../types";

export const buildDeleteSocialMediaDataMutation = (
  userRole: UserRole.ManageCompany | UserRole.ManageProducts | UserRole.ManageCuration
) => {
  const updateProduct =
    userRole === UserRole.ManageProducts || userRole === UserRole.ManageCuration;

  const getName = () => {
    return `DeleteSocialMediaData${updateProduct ? "ForProduct" : "ForCompany"}`;
  };

  return gql`
    mutation ${getName()}($verifiedLinksToDelete: [uuid!]!, $socialMediaId: uuid!) {
      deleteSocialMediaLinks(where: { linkId: { _in: $verifiedLinksToDelete } }) {
        affected_rows
      }
      deleteVerifiedLink(where: { id: { _in: $verifiedLinksToDelete } }) {
        affected_rows
      }
      ${
        updateProduct
          ? `updateProduct(
        where: { socialMediaId: { _eq: $socialMediaId } }
        _set: { socialMediaId: null }
      ) {
        affected_rows
      }`
          : `updateCompany(
        where: { socialMediaId: { _eq: $socialMediaId } }
        _set: { socialMediaId: null }
      ) {
        affected_rows
      }`
      }
      deleteSocialMediaByPk(id: $socialMediaId) {
        id
      }
    }
  `;
};

export type DeleteSocialLinksVars = {
  verifiedLinksToDelete: string[];
  socialMediaId: string;
};
