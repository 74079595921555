import {
  BulletinTargetGroup,
  CurationStatusesFilter,
  DurationType,
  ImportSteps,
  Language,
  Month,
  PricingType,
  PricingUnit,
  ProductTargetGroup,
  ProductType,
  SocialMediaPlatform,
  Weekday,
} from "../types";
import { capitalize, formatNumberWithCurrencySymbol } from "./functions";
import { ProductCapacity, ProductInformation } from "../graphqlQueries/getProduct";

export const getProductTypeHeaderKey = (type: ProductType) => {
  if (type === ProductType.RentalService) {
    return "productCategories.rentalServiceHeader";
  }
  return `productCategories.${type}Header`;
};

export const getProductTypeIcon = (type: ProductType) => {
  switch (type) {
    case ProductType.Accommodation:
      return "home";
    case ProductType.Attraction:
      return "archway";
    case ProductType.Experience:
      return "skiing";
    case ProductType.Event:
      return "calendar";
    case ProductType.RentalService:
      return "car-side";
    case ProductType.Restaurant:
      return "coffee";
    case ProductType.Shop:
      return "shopping-basket";
    case ProductType.Venue:
      return "venue";
    case ProductType.Transportation:
      return "transportation";
  }
};

export const getTargetGroupKey = (type: ProductTargetGroup) => {
  if (type === ProductTargetGroup.BTOC) {
    return "productInfo.targetGroupBtoC";
  }

  if (type === ProductTargetGroup.BTOB) {
    return "productInfo.targetGroupBtoB";
  }
  return "";
};

export const getBulletinTargetGroupKeys = (type: BulletinTargetGroup) => {
  switch (type) {
    case BulletinTargetGroup.DMO:
      return "dashboard.bulletinTargetDmo";
    case BulletinTargetGroup.TC:
      return "dashboard.bulletinTargetTc";
    case BulletinTargetGroup.API:
      return "dashboard.bulletinTargetApi";
    default:
      return "";
  }
};

type DurationKeys = {
  [key in DurationType]: string;
};

const durationKeys: DurationKeys = {
  days: "productInfo.durationDays",
  hours: "productInfo.durationHours",
  minutes: "productInfo.durationMinutes",
  weeks: "productInfo.durationWeeks",
};

export const getDurationTypeKey = (type: DurationType) => {
  return durationKeys[type];
};

type PriceUnitKeys = {
  [key in PricingUnit]: string;
};

const priceKeys: PriceUnitKeys = {
  day: "productInfo.pricingUnitDay",
  hour: "productInfo.pricingUnitHour",
  person: "productInfo.pricingUnitPerson",
  week: "productInfo.pricingUnitWeek",
  select: "select.placeholder",
};

export const getPriceUnitKey = (unit: PricingUnit) => {
  return priceKeys[unit];
};

export const getOpeningHoursWeekdayKey = (weekday: Weekday, short?: boolean) => {
  return `openingHours.${weekday}.${short ? "short" : "full"}`;
};

export const getMonthKey = (month: Month) => `productInfo.availability${capitalize(month)}`;

export const getCurationStatusKey = (type: CurationStatusesFilter) => {
  switch (type) {
    case CurationStatusesFilter.New:
      return "curatorWorkspace.statusFilterNew";
    case CurationStatusesFilter.Pending:
      return "curatorWorkspace.statusFilterPending";
    case CurationStatusesFilter.Updated:
      return "curatorWorkspace.statusFilterUpdated";
    case CurationStatusesFilter.Approved:
      return "curatorWorkspace.statusFilterApproved";
    case CurationStatusesFilter.ApprovedAndUpdated:
      return "curatorWorkspace.statusFilterApprovedAndUpdated";
    case CurationStatusesFilter.All:
      return "curatorWorkspace.statusFilterAll";
    case CurationStatusesFilter.Draft:
      return "curatorWorkspace.statusFilterDraft";
    case CurationStatusesFilter.CurationPending:
      return "curatorWorkspace.statusFilterCurationPending";
    case CurationStatusesFilter.AllCompanyProducts:
      return "curatorWorkspace.AllCompanyProducts";
    default:
      return "";
  }
};

type ViewProductDuration = {
  [key in DurationType]: string;
};

const durationKeysByType: ViewProductDuration = {
  minutes: "viewProduct.durationTypeMinutes",
  hours: "viewProduct.durationTypeHours",
  days: "viewProduct.durationTypeDays",
  weeks: "viewProduct.durationTypeWeeks",
};

export const getViewProductDuration = (type: DurationType): string => {
  return durationKeysByType[type];
};

type SocialMediaPlatformTexts = {
  [key in SocialMediaPlatform]: string;
};

export const socialMediaPlatforms = [
  SocialMediaPlatform.Facebook,
  SocialMediaPlatform.Instagram,
  SocialMediaPlatform.Youtube,
  SocialMediaPlatform.Twitter,
  SocialMediaPlatform.Weibo,
  SocialMediaPlatform.Vkontakte,
  SocialMediaPlatform.Trip_advisor,
  SocialMediaPlatform.TikTok,
  SocialMediaPlatform.LinkedIn,
  SocialMediaPlatform.WeChat,
  SocialMediaPlatform.Reddit,
  SocialMediaPlatform.Whatsapp,
];

const socialMediaPlatformsTexts: SocialMediaPlatformTexts = {
  facebook: "socialMediaPlatform.facebook",
  instagram: "socialMediaPlatform.instagram",
  youtube: "socialMediaPlatform.youtube",
  twitter: "socialMediaPlatform.twitter",
  weibo: "socialMediaPlatform.weibo",
  vkontakte: "socialMediaPlatform.vkontakte",
  trip_advisor: "socialMediaPlatform.tripAdvisor",
  tik_tok: "socialMediaPlatform.tiktok",
  linkedin: "socialMediaPlatform.linkedin",
  we_chat: "socialMediaPlatform.wechat",
  reddit: "socialMediaPlatform.reddit",
  whatsapp: "socialMediaPlatform.whatsapp",
};

export const getSocialMediaPlatformKey = (platform: SocialMediaPlatform) =>
  socialMediaPlatformsTexts[platform];

export const getPricingUnitTranslation = (unit: string | undefined | null) => {
  switch (unit) {
    case "person":
      return "viewProduct.pricingUnitPerson";
    case "day":
      return "viewProduct.pricingUnitDay";
    case "hour":
      return "viewProduct.pricingUnitHour";
    case "week":
      return "viewProduct.pricingUnitWeek";
    default:
      return "viewProduct.pricingUnitNotSet";
  }
};

type CapacityGroupSizeTextData = {
  text: string;
  values: {
    max?: number;
    min?: number;
    capacity?: number;
  };
};

export const getCapacityOrGroupSizeText = (
  type: ProductType,
  capacity?: ProductCapacity
): CapacityGroupSizeTextData | null => {
  if (!capacity || !type) {
    return null;
  }

  const minCapacity = capacity.min;
  const maxCapacity = capacity.max;

  const isVenue = type === ProductType.Venue;
  const isTransportation = type === ProductType.Transportation;

  const getText = ({
    venueText,
    transportationText,
    baseText,
  }: {
    venueText: string;
    transportationText: string;
    baseText: string;
  }) => {
    if (isVenue) {
      return venueText;
    }
    if (isTransportation) {
      return transportationText;
    }

    return baseText;
  };

  switch (true) {
    case !minCapacity && !!maxCapacity:
      return {
        text: getText({
          venueText: "viewProduct.capacityMax",
          transportationText: "viewProduct.transportationCapacityMax",
          baseText: "viewProduct.groupsizeMax",
        }),
        values: { max: maxCapacity },
      };
    case !!minCapacity && !maxCapacity:
      return {
        text: getText({
          venueText: "viewProduct.capacityMin",
          transportationText: "viewProduct.transportationCapacityMin",
          baseText: "viewProduct.groupsizeMin",
        }),
        values: { min: minCapacity },
      };
    case !!minCapacity && !!maxCapacity && minCapacity === maxCapacity:
      return {
        text: getText({
          venueText: "viewProduct.capacityMinEqualsMax",
          transportationText: "viewProduct.transportationCapacityMinEqualsMax",
          baseText: "viewProduct.groupsizeMinEqualsMax",
        }),
        values: { capacity: minCapacity },
      };
    case !!minCapacity && !!maxCapacity:
      return {
        text: getText({
          venueText: "viewProduct.capacityMinMax",
          transportationText: "viewProduct.transportationCapacityMinMax",
          baseText: "viewProduct.groupsizeMinMax",
        }),
        values: { min: minCapacity, max: maxCapacity },
      };

    default:
      return null;
  }
};

export const getPricingTexts = (
  priceFrom: number | null,
  priceTo: number | null,
  pricingType: PricingType
) => {
  const free = pricingType === PricingType.Free || (priceFrom === 0 && priceTo === 0);
  const from = priceFrom !== null && formatNumberWithCurrencySymbol(priceFrom);
  const to = priceTo !== null && formatNumberWithCurrencySymbol(priceTo);
  switch (true) {
    case free:
      return {
        text: "viewProduct.pricingFree",
        values: {},
      };
    case !!priceFrom && !priceTo:
      return {
        text: "viewProduct.pricingFrom",
        values: { fromPrice: from },
      };
    case !!priceFrom && !!priceTo && priceFrom === priceTo:
      return {
        text: "viewProduct.priceFromEqualsTo",
        values: { price: from },
      };
    case (!!priceFrom || priceFrom === 0) && !!priceTo:
      return {
        text: "viewProduct.pricingFromTo",
        values: { fromPrice: from, toPrice: to },
      };

    default:
      return { text: "", values: {} };
  }
};

export const importProductButtonTexts = (step: ImportSteps) => {
  switch (step) {
    case ImportSteps.Connect:
      return "common.nextButtonText";
    case ImportSteps.Confirm:
      return "common.confirm";
    case ImportSteps.Import:
      return "common.import";
    case ImportSteps.Upload:
      return "common.Uploaded";
    default:
      return "common.nextButton";
  }
};

const NAME_START_INDEX = 0;

export const shortenText = (str: string, toLength: number) =>
  str.length > toLength ? `${str.substring(NAME_START_INDEX, toLength).trim()}...` : str;

export const languagesInOrder: Language[] = [
  "en",
  "fi",
  "sv",
  "de",
  "es",
  "fr",
  "zh",
  "ja",
  "ko",
  "ru",
  "it",
  "ar",
  "nl",
  "et",
  "no",
  "pl",
  "pt",
  "da",
];

export const customLanguageSort = (langs: Language[]) =>
  langs.sort((a, b) => languagesInOrder.indexOf(a) - languagesInOrder.indexOf(b));

export const sortProductsByLanguage = (items: ProductInformation[]) =>
  items
    .slice()
    .sort(
      ({ language: langA }, { language: langB }) =>
        languagesInOrder.indexOf(langA) - languagesInOrder.indexOf(langB)
    );
