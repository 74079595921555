import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { getErrorCount, jumpToError } from "../../utils/formUtils";
import ConfirmButton from "../ConfirmButton";
import Icon from "../Icon";
import IconButton from "../IconButton";
import SubmitSpinner from "../SubmitSpinner";
import Toolbar from "../Toolbar";
import { CURATOR_FORM_NAME } from "./CuratorProductForm";
import ErrorNotification from "./ErrorNotification";

type CuratorProductToolbarProps = {
  onDiscardChanges: () => void;
};

const CuratorProductToolbar = ({ onDiscardChanges }: CuratorProductToolbarProps) => {
  const { t } = useTranslation();

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [show, setShow] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);

  const { formState, errors, register, trigger } = useFormContext();
  const { isSubmitting } = formState;

  const errorCount = getErrorCount(errors);

  const validateChanges = async () => {
    if (await trigger()) {
      setShowCommentModal(true);
    } else {
      setSubmitCount(submitCount + 1);
    }
  };

  return (
    <>
      <Toolbar
        notification={
          <ErrorNotification
            errorCount={errorCount}
            submitCount={submitCount}
            jumpToError={() => jumpToError({ trigger })}
          />
        }
      >
        <ConfirmButton
          id="discard-changes"
          confirmStyle="info"
          showIcon
          headerContent={t("productInfo.discardPopoverHeader")}
          popOverContent={<p className="pb-3">{t("productInfo.discardPopoverDescription")}</p>}
          confirmButtonContent={t("common.discardChanges")}
          triggerButtonContent={t("common.discardChanges")}
          disabled={isSubmitting}
          onConfirmClick={onDiscardChanges}
          show={show}
          closePopover={() => setShow(!show)}
        />
        <IconButton
          variant="primary"
          className="ml-3"
          hideIcon={!isSubmitting}
          iconElement={<SubmitSpinner />}
          disabled={isSubmitting}
          onClick={() => validateChanges()}
        >
          {t("productInfo.saveChanges")}
        </IconButton>
      </Toolbar>
      <Modal show={showCommentModal}>
        <div className="d-flex w-100 justify-content-between align-items-center pl-4 pr-2 py-3 bg-light">
          <h4 className="font-light m-0">{t("productInfo.curatorModalHeader")}</h4>
          <IconButton
            variant="transparent"
            iconElement={<Icon size="medium" color="black" name="times" />}
            onClick={() => {
              setShowCommentModal(false);
            }}
          />
        </div>
        <Modal.Body>
          <div>
            <Form.Group controlId="curatorComment">
              <Form.Label>{t("productInfo.curatorModalCommentLabel")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                name="curatorComment"
                isInvalid={errors.curatorComment}
                ref={register()}
              />
              <p className="color-gray-900 text-smaller mt-2">
                {t("productInfo.curatorModalCommentDescription")}
              </p>
            </Form.Group>
            <div className="d-flex w-100 justify-content-end">
              <Button
                variant="primary"
                className="ml-3"
                disabled={isSubmitting}
                type="submit"
                form={CURATOR_FORM_NAME}
                onClick={() => setShowCommentModal(false)}
              >
                {t("productInfo.saveChanges")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CuratorProductToolbar;
