import { useCallback, useEffect, useState } from "react";

export const useDebouncedEffect = (effect: () => void, delay: number, deps: string[]) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};

export const useDebouncedCallback = <T>(effect: (args: T) => void, delay: number) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return useCallback(
    (args: T) => {
      const id = setTimeout(() => {
        effect(args);
      }, delay);
      setTimer((oldTimer) => {
        if (oldTimer) {
          clearTimeout(oldTimer);
        }
        return id;
      });
    },
    [effect, delay]
  );
};
