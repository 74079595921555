import { HasuradbCategoryGroup } from "./getTagsWithCategories";
import { gql } from "@apollo/client";

export const getMainCategories = gql`
  query getMainCategories($productType: hasuradb_product_types_enum!) {
    productTypeMainCategory(where: { productType: { _eq: $productType } }) {
      categoryGroup {
        groupName
        tags(order_by: { tag: asc }) {
          tag
        }
      }
    }
  }
`;

export type MainCategoryQueryVariables = {
  productType: string;
};
export interface HasuradbMainCategoryGroup {
  categoryGroup: HasuradbCategoryGroup;
}

export interface MainCategoryQueryResult {
  productTypeMainCategory: HasuradbMainCategoryGroup[];
}
