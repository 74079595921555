import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { CurationStatusesFilter, ProductType } from "../types";
import { STATUS_KEY } from "../utils/filterUtils";
import { ExternalProduct, productTypes } from "../utils/productFormUtils/productUtils";
import { COMPANY_PATH, PRODUCTS_PATH } from "../utils/routeConstants";
import { useCompanyId } from "./hooks/useCompanyId";
import Icon from "./Icon";
import ImportProductAccordion from "./importProductWizard/ImportProductAccordion";
import ImportProductWizard from "./importProductWizard/ImportProductWizard";
import Separator from "./Separator";

type ProductTypeModalProps = {
  onHide: () => void;
  onSelect: (category: ProductType) => void;
  show: boolean;
  showCloseButton?: boolean;
};

export const ProductTypeButton = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 150px;
  width: inherit;
  padding: 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  font-weight: normal;
  background: var(--color-white);
  &:hover {
    background: var(--color-gray-200);
  }
`;

const ProductTypeDescription = styled.p`
  font-size: 12px;
  color: var(--color-gray-600);
  margin: 0;
  padding: 0;
`;

const IconContainer = styled.div`
  height: 40px;
  width: 40px;
`;

const ProductTypeModal = ({
  show,
  onHide,
  onSelect,
  showCloseButton = true,
}: ProductTypeModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [companyId] = useCompanyId();

  const [showImportDialogFor, setShowImportDialogFor] = useState<
    ExternalProduct.Bokun | ExternalProduct.Johku | null
  >(null);
  const [uploading, setUploading] = useState(false);

  const onHideHandler = () => {
    // Stop closing of modal while uploading
    if (uploading) {
      return;
    }
    setShowImportDialogFor(null);
    onHide();
  };

  const importWizardOnHideHandler = () => {
    setUploading(false);
    setShowImportDialogFor(null);
    onHide();
    history.push({
      pathname: `${COMPANY_PATH}/${companyId}${PRODUCTS_PATH}`,
      search: `${STATUS_KEY}=${CurationStatusesFilter.Draft}`,
    });
    // fixme: later when filtering of products is refactored find a way to remove this reload
    window.location.reload();
  };

  return (
    <Modal show={show} animation={false} onHide={() => onHideHandler()} centered size="lg">
      {!showImportDialogFor && (
        <>
          <Modal.Header closeButton={showCloseButton}>
            <h3 className="m-0 ml-2 p-0">{t("productInfo.selectProductTypeHeader")}</h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {productTypes.map((category) => (
                <Col xs="12" sm="6" lg="4" key={category.type}>
                  <ProductTypeButton className="border" onClick={() => onSelect(category.type)}>
                    <IconContainer>
                      <Icon color="primary-dark" name={category.icon} />
                    </IconContainer>
                    <h4>{t(category.headerKey)}</h4>
                    <ProductTypeDescription>{t(category.descKey)}</ProductTypeDescription>
                  </ProductTypeButton>
                </Col>
              ))}
            </Row>
            <Separator />
            <ImportProductAccordion onChildClick={setShowImportDialogFor} />
          </Modal.Body>
        </>
      )}
      {showImportDialogFor && (
        <ImportProductWizard
          importingFrom={showImportDialogFor}
          showCloseButton={showCloseButton}
          onBackButtonPress={() => setShowImportDialogFor(null)}
          uploading={() => {
            setUploading((curr) => !curr);
            return uploading;
          }}
          onHide={importWizardOnHideHandler}
        />
      )}
    </Modal>
  );
};

export default ProductTypeModal;
