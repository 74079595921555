import { Controller, FieldError, useFormContext } from "react-hook-form";
import React, { useRef } from "react";

import Icon from "./Icon";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

const StyledInputContainer = styled.div`
  flex-flow: wrap;
  height: auto;
  .added-tag {
    font-family: var(--font-light);
    background: var(--color-light-blue);
    border-radius: 4px;
    margin-top: 0.15em;
    margin-bottom: 0.15em;
    align-items: center;
    display: flex;
    flex-direction: row;

    button {
      background: none;
      border: none;
      padding: 0;
      margin-left: 0.25em;
    }
    &.hasError {
      border: 1px solid var(--color-error);
      background: var(--color-primary-light);
    }
  }
  input {
    border: 0;
    &:focus-visible {
      outline: 0;
    }
  }
  &.hasError {
    border: 2px solid var(--color-error);
  }
`;

type DataHubTagInputProps = {
  onTagInput: (tag: string, index: number) => void;
  onTagRemoval: (index: number) => void;
  tags?: { label: string; value?: string }[];
  name: string;
  showSpinner?: boolean;
};

const DataHubTagInput: React.FunctionComponent<DataHubTagInputProps> = ({
  onTagInput,
  tags = [],
  onTagRemoval,
  name,
  showSpinner,
}) => {
  const { t } = useTranslation();
  const { control, errors } = useFormContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const handleKeyInput = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const lineBreakKeys = ["Enter", "Tab", " ", ","];
    if (!lineBreakKeys.includes(e.key)) {
      return;
    }
    const value = (e.target as HTMLInputElement).value.trim().replace(" ", "");
    if (value.length === 0 || !value || inputRef.current === null) {
      return;
    }

    e.preventDefault();

    inputRef.current.value = "";
    inputRef.current.focus();

    onTagInput(value, index);
  };

  return (
    <div>
      <StyledInputContainer
        className={`d-flex flex-row bg-white px-2 form-control ${errors?.[name] ? "hasError" : ""}`}
        onClick={() => {
          if (!inputRef.current) {
            return;
          }
          inputRef.current.focus();
        }}
      >
        {tags?.map((tag, i) => {
          return (
            <li
              className={`added-tag px-2 mr-1 ${errors?.[name]?.[i] ? "hasError" : ""}`}
              key={`email-${tag?.label}-${i}`}
            >
              <div className="text-small">{tag?.label}</div>
              <div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    onTagRemoval(i);
                  }}
                >
                  <svg
                    height="14"
                    width="14"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                  </svg>
                </button>
              </div>
            </li>
          );
        })}
        {showSpinner && (
          <li className="added-tag px-2 mr-1" key={`email-spinner`}>
            <div className="text-small">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                aria-hidden="true"
                style={{ verticalAlign: "sub" }}
              />
              <span className={"ml-1"}>{t("userManagement.searchingUser")}</span>
            </div>
          </li>
        )}
        <Controller
          control={control}
          name={name}
          defaultValue={[]}
          render={() => (
            <input ref={inputRef} onKeyDown={(e) => handleKeyInput(e, tags.length)} type="text" />
          )}
        />
      </StyledInputContainer>
      {errors?.[name] && (
        <div className="text-danger d-flex flex-row mt-1">
          <div className="mr-2">
            <Icon name="warning-triangle" size="small" />
          </div>
          {errors?.[name].map((error: FieldError, pos: number) => (
            <div className="text-small" key={`${error.message}${pos}`}>
              {t(error.message as string)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DataHubTagInput;
