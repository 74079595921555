import React, { CSSProperties } from "react";

import { ReactComponent as AngleLeft } from "../icons/line-awesome/angle-left-solid.svg";
import { ReactComponent as AngleRight } from "../icons/line-awesome/angle-right-solid.svg";
import { ReactComponent as Archway } from "../icons/line-awesome/archway-solid.svg";
import { ReactComponent as ArrowLeft } from "../icons/line-awesome/arrow-left-solid.svg";
import { ReactComponent as ArrowRight } from "../icons/line-awesome/arrow-right-solid.svg";
import { ReactComponent as Bars } from "../icons/line-awesome/bars-solid.svg";
import { ReactComponent as Bell } from "../icons/line-awesome/bell.svg";
import { ReactComponent as BokunDatahubPink } from "../icons/custom-icons/pink-bokun-datahub.svg";
import { ReactComponent as BokunExampleBig } from "../icons/custom-icons/bokun-example.svg";
import { ReactComponent as BokunGray } from "../icons/custom-icons/gray-bokun-logo.svg";
import { ReactComponent as BokunIdExample } from "../icons/custom-icons/bokun-id-example.svg";
import { ReactComponent as BokunOriginal } from "../icons/custom-icons/logo-bokun.svg";
import { ReactComponent as BokunPink } from "../icons/custom-icons/pink-bokun-logo.svg";
import { ReactComponent as BrokenLink } from "../icons/custom-icons/broken-link.svg";
import { ReactComponent as Calendar } from "../icons/line-awesome/calendar.svg";
import { ReactComponent as CarSide } from "../icons/line-awesome/car-side-solid.svg";
import { ReactComponent as Check } from "../icons/line-awesome/check-solid.svg";
import { ReactComponent as CheckCircle } from "../icons/line-awesome/check-circle-solid.svg";
import { ReactComponent as CheckDouble } from "../icons/line-awesome/check-double-solid.svg";
import { ReactComponent as ChevronDown } from "../icons/custom-icons/chevron-down.svg";
import { ReactComponent as ChevronRight } from "../icons/custom-icons/chevron-right.svg";
import { ReactComponent as ChevronUp } from "../icons/custom-icons/chevron-up.svg";
import { ReactComponent as CircleFilled } from "../icons/circle-filled.svg";
import { ReactComponent as Coffee } from "../icons/line-awesome/coffee-solid.svg";
import { ReactComponent as CommentAlt } from "../icons/line-awesome/comment-alt.svg";
import { ReactComponent as Copyright } from "../icons/line-awesome/copyright-solid.svg";
import { ReactComponent as DatahubPink } from "../icons/custom-icons/pink-datahub-logo.svg";
import { ReactComponent as DoubleAngleLeft } from "../icons/line-awesome/angle-double-left-solid.svg";
import { ReactComponent as DoubleAngleRight } from "../icons/line-awesome/angle-double-right-solid.svg";
import { ReactComponent as Edit } from "../icons/line-awesome/edit.svg";
import { ReactComponent as EllipsisVertical } from "../icons/line-awesome/ellipsis-v-solid.svg";
import { ReactComponent as Envelope } from "../icons/custom-icons/envelope.svg";
import { ReactComponent as ExclamationCircle } from "../icons/line-awesome/exclamation-circle-solid.svg";
import { ReactComponent as ExclamationSolid } from "../icons/line-awesome/exclamation-solid.svg";
import { ReactComponent as ExternalLinkAlt } from "../icons/line-awesome/external-link-alt-solid.svg";
import { ReactComponent as ExternalProduct } from "../icons/custom-icons/external-product.svg";
import { ReactComponent as Filter } from "../icons/line-awesome/filter-solid.svg";
import { ReactComponent as Home } from "../icons/line-awesome/home-solid.svg";
import { ReactComponent as Image } from "../icons/line-awesome/image.svg";
import { ReactComponent as Info } from "../icons/line-awesome/info-solid.svg";
import { ReactComponent as JohkuDatahubPink } from "../icons/custom-icons/pink-johku-datahub.svg";
import { ReactComponent as JohkuOriginal } from "../icons/custom-icons/johku-logo.svg";
import { ReactComponent as JohkuPink } from "../icons/custom-icons/pink-johku-logo.svg";
import { ReactComponent as Language } from "../icons/line-awesome/language-solid.svg";
import { ReactComponent as LifeBuoy } from "../icons/custom-icons/life-buoy.svg";
import { ReactComponent as Lock } from "../icons/line-awesome/lock-solid.svg";
import { ReactComponent as MapMarkerAltSolid } from "../icons/line-awesome/map-marker-alt-solid.svg";
import { ReactComponent as MapMarkerSolid } from "../icons/line-awesome/map-marker-solid.svg";
import { ReactComponent as News } from "../icons/custom-icons/icons-news.svg";
import { ReactComponent as PinkSuccess } from "../icons/custom-icons/pink-success.svg";
import { ReactComponent as QuestionCircle } from "../icons/line-awesome/question-circle.svg";
import { ReactComponent as Search } from "../icons/line-awesome/search-solid.svg";
import { ReactComponent as ShoppingBasket } from "../icons/line-awesome/shopping-basket-solid.svg";
import { ReactComponent as Skiing } from "../icons/line-awesome/skiing-solid.svg";
import { ReactComponent as Sync } from "../icons/line-awesome/sync-solid.svg";
import { ReactComponent as Times } from "../icons/line-awesome/times-solid.svg";
import { ReactComponent as ToolSolid } from "../icons/line-awesome/tools-solid.svg";
import { ReactComponent as TranslationsToDatahub } from "../icons/custom-icons/translationstodatahub.svg";
import { ReactComponent as Transportation } from "../icons/custom-icons/transportation-category.svg";
import { ReactComponent as Trash } from "../icons/line-awesome/trash-alt.svg";
import { ReactComponent as UnLock } from "../icons/line-awesome/lock-open-solid.svg";
import { ReactComponent as UserPlus } from "../icons/line-awesome/user-plus-solid.svg";
import { ReactComponent as Venue } from "../icons/custom-icons/product-category-icon-venue.svg";
import { ReactComponent as WarningTriangle } from "../icons/line-awesome/exclamation-triangle-solid.svg";

export type IconName =
  | "car-side"
  | "calendar"
  | "archway"
  | "skiing"
  | "coffee"
  | "home"
  | "shopping-basket"
  | "check"
  | "warning-triangle"
  | "exclamation-circle"
  | "exclamation"
  | "external-link-alt"
  | "check-circle"
  | "map-marker-alt-solid"
  | "map-marker-solid"
  | "comment-alt"
  | "language"
  | "bars"
  | "filter"
  | "chevron-right"
  | "chevron-down"
  | "chevron-up"
  | "search"
  | "circle-filled"
  | "times"
  | "check-double"
  | "arrow-left"
  | "arrow-right"
  | "ellipsis-v"
  | "copyright"
  | "envelope"
  | "translations-to-datahub"
  | "bell"
  | "user-plus"
  | "news"
  | "edit"
  | "trash"
  | "venue"
  | "transportation"
  | "doubleAngleLeft"
  | "doubleAngleRight"
  | "angleLeft"
  | "angleRight"
  | "bokunOriginal"
  | "bokunPink"
  | "bokunGray"
  | "successPink"
  | "datahubPink"
  | "bokunDatahubPink"
  | "bokunIdExample"
  | "bokunExample"
  | "image-placeholder"
  | "brokenLink"
  | "sync"
  | "info"
  | "questionCircle"
  | "lifeBuoy"
  | "johkuOriginal"
  | "johkuPink"
  | "johkuDatahubPink"
  | "externalProduct"
  | "lock"
  | "unlock"
  | "toolSolid";

const icons: {
  [key in IconName]: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
} = {
  "car-side": CarSide,
  calendar: Calendar,
  archway: Archway,
  skiing: Skiing,
  coffee: Coffee,
  home: Home,
  "shopping-basket": ShoppingBasket,
  check: Check,
  "warning-triangle": WarningTriangle,
  "exclamation-circle": ExclamationCircle,
  exclamation: ExclamationSolid,
  "external-link-alt": ExternalLinkAlt,
  "check-circle": CheckCircle,
  "map-marker-alt-solid": MapMarkerAltSolid,
  "comment-alt": CommentAlt,
  language: Language,
  bars: Bars,
  filter: Filter,
  "chevron-down": ChevronDown,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  search: Search,
  "circle-filled": CircleFilled,
  times: Times,
  "check-double": CheckDouble,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "ellipsis-v": EllipsisVertical,
  copyright: Copyright,
  envelope: Envelope,
  "translations-to-datahub": TranslationsToDatahub,
  news: News,
  edit: Edit,
  trash: Trash,
  venue: Venue,
  transportation: Transportation,
  bell: Bell,
  "user-plus": UserPlus,
  doubleAngleLeft: DoubleAngleLeft,
  angleLeft: AngleLeft,
  doubleAngleRight: DoubleAngleRight,
  angleRight: AngleRight,
  bokunOriginal: BokunOriginal,
  bokunPink: BokunPink,
  bokunGray: BokunGray,
  johkuOriginal: JohkuOriginal,
  johkuPink: JohkuPink,
  johkuDatahubPink: JohkuDatahubPink,
  successPink: PinkSuccess,
  datahubPink: DatahubPink,
  bokunDatahubPink: BokunDatahubPink,
  bokunIdExample: BokunIdExample,
  bokunExample: BokunExampleBig,
  "image-placeholder": Image,
  brokenLink: BrokenLink,
  sync: Sync,
  info: Info,
  questionCircle: QuestionCircle,
  lifeBuoy: LifeBuoy,
  externalProduct: ExternalProduct,
  "map-marker-solid": MapMarkerSolid,
  lock: Lock,
  unlock: UnLock,
  toolSolid: ToolSolid,
};

export type IconSize = "x-small" | "small" | "medium" | "large" | "x-large";

export type IconColor =
  | "current"
  | "primary"
  | "primary-dark"
  | "green"
  | "gray-900"
  | "gray-500"
  | "gray-400"
  | "gray-800"
  | "gray-600"
  | "blue"
  | "dark-blue"
  | "black"
  | "orange"
  | "invalid"
  | "white";

type IconProps = {
  name: IconName;
  color?: IconColor;
  style?: CSSProperties;
  size?: IconSize;
  className?: string;
};

const fillColors: Record<IconColor, string> = {
  primary: "var(--color-primary)",
  "primary-dark": "var(--color-primary-dark)",
  current: "currentColor",
  green: "var(--color-green)",
  "gray-400": "var(--color-gray-400)",
  "gray-500": "var(--color-gray-500)",
  "gray-800": "var(--color-gray-800)",
  "gray-600": "var(--color-gray-600)",
  "gray-900": "var(--color-gray-900)",
  blue: "var(--color-blue)",
  "dark-blue": "var(--color-dark-blue)",
  black: "var(--color-black)",
  orange: "var(--color-orange)",
  invalid: "var(--color-invalid)",
  white: "var(--color-white)",
};

const sizes: Record<IconSize, string> = {
  "x-small": "10px",
  small: "16px",
  medium: "20px",
  large: "30px",
  "x-large": "100px",
};

const Icon = ({ name, style, size, color = "current", className }: IconProps) => {
  const IconElement = icons[name];
  const width = size ? sizes[size] : "auto";
  const height = size ? sizes[size] : "auto";

  return (
    <IconElement
      className={className || ""}
      style={{
        fill: fillColors[color],
        width,
        height,
        ...style,
      }}
    />
  );
};

export default Icon;
