import React, { CSSProperties } from "react";
import Icon from "../Icon";

type AccordionIconProps = {
  open: boolean;
  style?: CSSProperties;
};

const AccordionIcon = ({ open, style = {} }: AccordionIconProps) => {
  const rotate = open ? "rotate(90deg)" : "rotate(0)";
  const iconStyle = { transform: rotate, transition: "all 0.2s ease-out", ...style };

  return <Icon name="chevron-right" size="medium" style={iconStyle} />;
};

export default AccordionIcon;
