import { Button, Modal } from "react-bootstrap";

import Icon from "./Icon";
import ModalHeaderIconContainer from "./ModalHeaderIconContainer";
import React from "react";
import { default as c } from "classnames";

type ModalHeaderProps = {
  headerIcon?: React.ReactNode;
  headerText: React.ReactNode | string;
  headerClassName?: string;
  onHide?: () => void;
};

const ModalHeader = ({ headerText, headerIcon, headerClassName, onHide }: ModalHeaderProps) => (
  <Modal.Header
    closeButton
    className={headerClassName ? headerClassName : ""}
    onHide={() => {
      onHide && onHide();
    }}
  >
    {headerIcon && (
      <ModalHeaderIconContainer className="mr-3">{headerIcon}</ModalHeaderIconContainer>
    )}
    {headerText}
  </Modal.Header>
);

type ModalBodyProps = {
  children: React.ReactNode;
};

const ModalBody = ({ children }: ModalBodyProps) => (
  <Modal.Body className="bg-white px-2 py-2 px-md-4 py-md-4">{children}</Modal.Body>
);

type ModalProps = {
  children: React.ReactNode;
  headerText: React.ReactNode;
  onHide: () => void;
  show?: boolean;
};

type InfoModalProps = ModalProps & {
  buttonText: string;
};

export const InfoModal = ({ headerText, children, onHide, buttonText }: InfoModalProps) => {
  return (
    <Modal show animation={false} onHide={onHide} centered>
      <ModalHeader headerText={headerText} onHide={onHide} />
      <ModalBody>{children}</ModalBody>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="primary"
          style={{
            minWidth: "150px",
          }}
          onClick={onHide}
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ConfirmModal = ({ headerText, children, onHide }: ModalProps) => {
  return (
    <Modal show animation={false} onHide={onHide}>
      <ModalHeader
        headerText={headerText}
        headerIcon={<Icon name="check" color="green" />}
        onHide={onHide}
      />
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export const ErrorModal = ({ headerText, children, onHide }: ModalProps) => {
  return (
    <Modal show animation={false} onHide={onHide}>
      <ModalHeader
        headerText={headerText}
        headerIcon={<Icon name="warning-triangle" color="primary-dark" />}
        onHide={onHide}
      />
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

type ModalSize = "sm" | "lg" | "xl" | "fullscreen";

type BasicInfoModalProps = {
  header: React.ReactNode;
  onCancel: () => void;
  centered?: boolean;
  footer?: React.ReactNode;
  size?: ModalSize;
  dialogClassName?: string;
  className?: string;
};

export const BasicInfoModal: React.FC<BasicInfoModalProps> = ({
  header,
  onCancel,
  centered,
  size,
  footer,
  children,
  dialogClassName,
  className,
}) => {
  const modalSize = size === "fullscreen" ? undefined : size;

  return (
    <Modal
      show
      animation={false}
      onHide={onCancel}
      centered={centered}
      size={modalSize}
      dialogClassName={dialogClassName}
      className={c(className, { fullScreenModal: size === "fullscreen" })}
    >
      <ModalHeader headerText={header} onHide={onCancel} />
      <ModalBody>{children}</ModalBody>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

type BasicModalProps = BasicInfoModalProps & {
  onProceed: () => void;
  proceedText: string;
  cancelText: string;
  disableProceed?: boolean;
  proceedOnMouseDown?: boolean;
};

export const BasicModal: React.FC<BasicModalProps> = ({
  onCancel,
  onProceed,
  proceedText,
  cancelText,
  disableProceed,
  proceedOnMouseDown = false,
  ...basicInfoModalProps
}) => {
  return (
    <BasicInfoModal
      onCancel={onCancel}
      {...basicInfoModalProps}
      footer={
        <div className="d-flex flex-row">
          <Button
            variant="secondary"
            style={{
              minWidth: "150px",
            }}
            onClick={onCancel}
            onMouseDown={() => proceedOnMouseDown && onCancel()}
          >
            {cancelText}
          </Button>
          <Button
            variant="primary"
            style={{
              minWidth: "150px",
            }}
            disabled={disableProceed}
            onClick={onProceed}
            onMouseDown={() => proceedOnMouseDown && onProceed()}
          >
            {proceedText}
          </Button>
        </div>
      }
    />
  );
};
