import React from "react";
import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Icon from "../../components/Icon";

//TODO: check if this is relevant anymore
const DASHBOARD_REDIRECT_DELAY_MS = 100;

type CreateAccountSaveConfirmationProps = {
  isCurator: boolean;
};

const CreateAccountSaveConfirmation = ({ isCurator }: CreateAccountSaveConfirmationProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className="d-flex align-items-center flex-column color-gray-900">
      <Icon name="check-circle" color="gray-400" style={{ width: "50px" }} />
      <h1 className="text-normal-size font-light my-4">
        {isCurator ? t("signup.confirmationTextCurator") : t("signup.confirmationText")}
      </h1>
      <Trans i18nKey="signup.visitFinlandProcesses">
        <p className="text-center px-2 text-larger">
          <>first</>
          <strong>second</strong>
        </p>
      </Trans>
      <p className="my-3 text-center">{t("signup.confirmationWillBeSent")}</p>
      <Button
        className="mt-5"
        onClick={() => {
          setTimeout(() => {
            history.push("/dashboard");
          }, DASHBOARD_REDIRECT_DELAY_MS);
        }}
      >
        {t("signup.confirmationCta")}
      </Button>
    </div>
  );
};

export default CreateAccountSaveConfirmation;
