import { HasuraInsertProductVariables } from "../../graphqlQueries/insertProduct";
import { UpdateOpeningHoursVariables } from "../../graphqlQueries/updateOpeningHours";
import { HasuraUpdateProductVariables } from "../../graphqlQueries/updateProduct";
import {
  BusinessHoursFormValues,
  CurationStatus,
  MyProductFormValues,
  ProductCurationStatus,
} from "../../types";
import {
  composeOpeningHoursData,
  composePublishedProduct,
  composeUpdatedProduct,
} from "./productToHasuraComposer";

export type BaseUpdateParams = {
  productId: string;
  companyId: string;
  formValues: MyProductFormValues;
};

type UpdateProductParams = BaseUpdateParams & {
  curationStatus: CurationStatus | null;
  productCurationStatus: ProductCurationStatus | null;
  isCurator?: boolean;
};

export const getInsertPublishedVariables = (
  formValues: MyProductFormValues,
  companyId: string
): HasuraInsertProductVariables => composePublishedProduct(formValues, companyId);

export const getUpdateProductVariables = ({
  companyId,
  curationStatus,
  formValues,
  productCurationStatus,
  productId,
  isCurator,
}: UpdateProductParams): HasuraUpdateProductVariables =>
  composeUpdatedProduct({
    data: getInsertPublishedVariables(formValues, companyId),
    curationStatus,
    productCurationStatus,
    productId,
    isCurator,
  });

export const getUpdateOpeningHoursVariables = (
  formValues: BusinessHoursFormValues,
  businessHoursId: string
): UpdateOpeningHoursVariables => ({
  businessHoursId,
  openingHoursData: composeOpeningHoursData(formValues, businessHoursId),
});
