import { gql } from "@apollo/client";

const getTagsWithCategories = gql`
  query getTagsWithCategories {
    categoryGroup {
      groupName
      tags(order_by: { tag: asc }) {
        tag
      }
    }
  }
`;

export interface Tag {
  tag: string;
}

export interface HasuradbCategoryGroup {
  groupName: string;
  tags: Tag[];
}

export interface TagsQueryResult {
  categoryGroup: HasuradbCategoryGroup[];
}

export default getTagsWithCategories;
