import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import Footer from "../components/Footer";
import DataHubUserLogos from "../components/landingPage/DataHubUserLogos";
import ServiceBox from "../components/landingPage/ServiceBox";
import ServiceBoxItem from "../components/landingPage/ServiceBoxItem";
import { useIsMediumOrSmaller } from "../components/MediaQuery";
import PublicHeader from "../components/PublicHeader";
import { breakpointPixels } from "../css/breakpoints";
import christmasImg from "../images/landingpage/christmas_decorations_1920.jpg";
import archipelagoImg from "../images/landingpage/Finland_Pellinki_Archipelago_darker.jpg";
import arcticSaunaImg from "../images/landingpage/Finland_Repo_Arctic_Sauna_World_1920.jpg";
import illustrationHorizontal from "../images/landingpage/illustration_horizontal.svg";
import illustrationVertical from "../images/landingpage/illustration_vertical.svg";
import kalakukkoImg from "../images/landingpage/kalakukko_1920.jpg";
import dataHubVideoPlaceholder from "../images/landingpage/video_placeholder.jpg";
import zoneAtlasImg from "../images/landingpage/zone_atlas.png";
import ExternalLink from "../components/ExternalLink";
import Icon from "../components/Icon";
import { useQuery } from "@apollo/client";
import {
  frontPageCounts,
  FrontpageCountsResult,
} from "../graphqlQueries/InstructionsAndFaq/frontpageCounts";
import Loading from "../components/Loading";

const dataHubVideoUrl =
  "https://datahub.visitfinland.com/images/datahub/DataHub_Animation_2021_1920x1080_FIN.mp4";

const LandingTextContainer = styled.div`
  background-image: url(${archipelagoImg});
  background-repeat: no-repeat;
  background-position: 90% 35%;
  background-size: 130%;
  display: flex;
  align-items: center;
  padding-top: 150px;
  padding-bottom: 160px;
  flex-direction: column;
  @media (max-width: ${breakpointPixels.md}) {
    padding: 7rem 2rem;
  }
  @media (max-width: ${breakpointPixels.sm}) {
    background-size: auto;
  }
`;

const IntroductionText = styled.h2`
  text-align: center;
  font-size: 55px;
  @media (max-width: ${breakpointPixels.md}) {
    font-size: 36px;
    max-width: 600px;
  }
  color: var(--color-white);
  max-width: 800px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: 900;
`;

export const IntroductionContainer = styled.div`
  margin-left: auto !important;
  margin-right: auto !important;
  padding-top: 3rem !important;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 1080px;
  @media (max-width: ${breakpointPixels.lg}) {
    padding: 3rem 2rem 0 2rem;
  }
  @media (max-width: ${breakpointPixels.sm}) {
    padding: 3rem 0 0 0;
  }
`;

const IntroductionHeader = styled.h1`
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-white);
`;

const DataHubContentNumber = styled.p`
  font-size: 70px;
  line-height: normal;
  color: var(--color-primary);
  margin: 0;
`;

const DataHubContentText = styled.p`
  font-family: var(--font-heavy);
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--color-gray-800);
`;

const FitImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

const FitVerticalImage = styled.img`
  object-fit: cover;
  width: 100%;
  padding-right: 20%;
  padding-left: 20%;
`;

type CroppedImageProps = {
  src: string;
  offsetY?: string;
  offsetX?: string;
};

const CroppedImage = styled.div<CroppedImageProps>`
  background: ${(props) =>
    `url(${props.src}) no-repeat ${props.offsetX ?? "50%"} ${props.offsetY ?? "50%"};`}
  background-size: cover;
  width: 100%;
  height: 385px;
`;

const OverlayTextDesktop = styled.div`
  margin-top: 6vh;
  width: 250px;
  right: 3vw;
  @media (max-width: ${breakpointPixels.lg}) {
    right: -4vw;
    margin-top: 3.8vh;
  }
`;
const OverlayTextMobile = styled.div`
  bottom: 8rem;
  left: 6rem;
  width: 220px;
`;

const LandingPage = () => {
  const { keycloak } = useKeycloak();
  const { t, i18n } = useTranslation();
  const isMediumScreen = useIsMediumOrSmaller();

  const { data } = useQuery<FrontpageCountsResult>(frontPageCounts, {
    fetchPolicy: "network-only",
  });

  const { productCount, organizationCount } = data?.frontPages?.[0] ?? {};

  const DataHubSvgOverlayTexts = () => (
    <ul>
      <li>{t("landing.vfDigitalChannels")}</li>
      <li>{t("landing.destinationSites")}</li>
      <li>{t("landing.travelApplications")}</li>
      <li>{t("landing.travelMarketplaces")}</li>
      <li>{t("landing.etc")}</li>
    </ul>
  );

  const goToRegistration = () => keycloak?.register({ locale: i18n.language });

  return (
    <div className="w-100">
      <div className="sticky-top border-bottom bg-white">
        <PublicHeader />
      </div>
      <div className="w-100 h-100">
        <LandingTextContainer>
          <IntroductionHeader className="mb-2">{t("landing.pageHeader")}</IntroductionHeader>
          <IntroductionText>{t("landing.dataHubIntroText")}</IntroductionText>
        </LandingTextContainer>
        <IntroductionContainer>
          <div className="row flex-column flex-sm-row pt-lg-5 font-heavy mx-0">
            <div className="col-lg-4">
              <h3 className="text-landing-h3">{t("landing.dataHubContentHeader")}</h3>
              <DataHubContentNumber>{organizationCount ?? <Loading />}</DataHubContentNumber>
              <DataHubContentText>{t("landing.dataHubContentCompanies")}</DataHubContentText>
              <DataHubContentNumber>{productCount ?? <Loading />}</DataHubContentNumber>
              <DataHubContentText>{t("landing.dataHubContentProducts")}</DataHubContentText>
              <Button className="mt-3" variant="outline-primary" onClick={goToRegistration}>
                {t("landing.dataHubContentJoin")}
              </Button>
            </div>
            <div className="col-lg-8 mt-lg-0 col-sm mt-4">
              <video
                className="border-gray-300"
                width="100%"
                poster={dataHubVideoPlaceholder}
                controls
              >
                <source src={dataHubVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="mt-5 py-4 px-3">
            <h3 className="text-landing-h3">{t("landing.dataHubDescriptionHeader")}</h3>
            <div className="row pt-5">
              <ServiceBoxItem
                title={t("landing.dataHubDescriptionTitle1")}
                text={t("landing.dataHubDescriptionText1")}
              />
              <ServiceBoxItem
                title={t("landing.dataHubDescriptionTitle2")}
                text={t("landing.dataHubDescriptionText2")}
              />
              <ServiceBoxItem
                title={t("landing.dataHubDescriptionTitle3")}
                text={t("landing.dataHubDescriptionText3")}
              />
            </div>
          </div>
          <div className="row w-100">
            {isMediumScreen ? (
              <div className="position-relative">
                <FitVerticalImage className="my-5" src={illustrationVertical} />
                <OverlayTextMobile className="col-sm position-absolute">
                  <DataHubSvgOverlayTexts />
                </OverlayTextMobile>
              </div>
            ) : (
              <div className="col-sm-12 position-relative">
                <div className="row w-100">
                  <FitImage className="my-5" src={illustrationHorizontal} />
                  <OverlayTextDesktop className="col-sm position-absolute">
                    <DataHubSvgOverlayTexts />
                  </OverlayTextDesktop>
                </div>
              </div>
            )}
          </div>

          <div className="row flex-column flex-sm-row pt-5 mx-0 text-larger">
            <div className="col-lg mb-4">
              <h3 className="text-landing-h3 mb-4">{t("landing.faqHeader")}</h3>
              <p className="color-gray-800 mb-1">{t("landing.faqText")}</p>
              <ExternalLink href="faq" data-cy="link-to-faq">
                {t("ukk.header")}
              </ExternalLink>
              <p className="color-gray-800 mt-2">{t("landing.faqLinkInformation")}</p>
            </div>
          </div>

          <div className="row flex-column flex-sm-row pt-5 mx-0 text-larger">
            <div className="col-lg mb-4">
              <h3 className="text-landing-h3 mb-4">{t("landing.dataHubDemoHeader")}</h3>
              <p className="color-gray-800">{t("landing.dataHubDemoText")}</p>
              <NavLink to="/demo" activeClassName="nav-link-active" data-cy="link-to-demo">
                {t("landing.dataHubDemoLinkLabel")}
              </NavLink>
            </div>
            <div className="col-lg mb-4">
              <FitImage src={zoneAtlasImg} />
            </div>
          </div>

          <DataHubUserLogos />

          <CroppedImage className="mt-5" src={christmasImg} offsetY="80%" />
          <ServiceBox
            header={t("landing.dataHubForTCHeader")}
            text={t("landing.dataHubForTCText")}
            sectionId="for-tc"
          >
            <ServiceBoxItem text={t("landing.dataHubForTCBoxText1")} />
            <ServiceBoxItem text={t("landing.dataHubForTCBoxText2")} />
            <ServiceBoxItem text={t("landing.dataHubForTCBoxText3")} />
          </ServiceBox>

          <div className="mt-3 px-5 bg-gray-100">
            <h4 className="text-center text-landing-h4 pt-4">{t("landing.bokunJohkuHeader")}</h4>
            <p className="text-muted text-center">{t("landing.bokunJohkuBodyText")}</p>

            <Row className="px-md-5 px-lg-5">
              <Col className="px-3" xs={12} md={4} lg={4}>
                <Row style={{ height: "4.5rem" }}>
                  <Col className="my-auto text-right">
                    <Icon name="bokunPink" style={{ height: "2rem" }} />
                  </Col>
                  <Col className="my-auto text-left">
                    <Icon name="johkuPink" style={{ height: "2rem", paddingTop: "0.625rem" }} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-center px-3 color-gray-800">
                      {t("landing.bokunJohkuIconText1")}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col className="px-3" xs={12} md={4} lg={4}>
                <Row style={{ height: "4.5rem" }}>
                  <Col className="my-auto text-center">
                    <Icon name="check-double" color="primary" style={{ height: "3.5rem" }} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-center px-3 color-gray-800">
                      {t("landing.bokunJohkuIconText2")}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col className="px-3" xs={12} md={4} lg={4}>
                <Row style={{ height: "4.5rem" }}>
                  <Col className="my-auto text-center">
                    <Icon name="datahubPink" style={{ height: "2.7rem" }} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-center px-3 color-gray-800">
                      {t("landing.bokunJohkuIconText3")}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="text-center py-5">
            <Button variant="outline-primary" onClick={goToRegistration}>
              {t("landing.dataHubForTCJoin")}
            </Button>
          </div>

          <CroppedImage className="mt-5" src={kalakukkoImg} offsetY="40%" />
          <ServiceBox
            header={t("landing.dataHubForDMOHeader")}
            text={t("landing.dataHubForDMOText")}
            sectionId="for-dmo"
          >
            <ServiceBoxItem text={t("landing.dataHubForDMOBoxText1")} />
            <ServiceBoxItem text={t("landing.dataHubForDMOBoxText2")} />
            <ServiceBoxItem text={t("landing.dataHubForDMOBoxText3")} />
          </ServiceBox>
          <div className="text-center py-5">
            <Button variant="outline-primary" onClick={goToRegistration}>
              {t("landing.dataHubForDMOJoin")}
            </Button>
          </div>

          <CroppedImage className="mt-5" src={arcticSaunaImg} offsetY="90%" />
          <ServiceBox
            header={t("landing.dataHubForPartnersHeader")}
            text={t("landing.dataHubForPartnersText")}
            sectionId="for-partners"
          >
            <ServiceBoxItem text={t("landing.dataHubForPartnersBoxText1")} />
            <ServiceBoxItem text={t("landing.dataHubForPartnersBoxText2")} />
            <ServiceBoxItem text={t("landing.dataHubForPartnersBoxText3")} />
          </ServiceBox>
          <div className="text-center py-5">
            <Button variant="outline-primary" onClick={goToRegistration}>
              {t("landing.dataHubForPartnersJoin")}
            </Button>
          </div>
        </IntroductionContainer>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
