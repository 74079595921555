import { ProductGroup } from "../../types";
import { gql } from "@apollo/client";

export const getProductGroupsQuery = gql`
  query GetProductGroups {
    productGroup(order_by: { createdAt: desc }) {
      id
      name
      description
      createdAt
      published
      publishedAt
      productsAggregate: products_aggregate(
        where: {
          product: {
            _and: {
              status: { _eq: published }
              productCurations: { curationStatus: { _eq: approved } }
            }
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export type GetProductGroupsResult = {
  productGroup: ProductGroup[];
};
