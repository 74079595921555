import { gql } from "@apollo/client";

const getUserProfilesQuery = gql`
  query getUserProfiles {
    userFullProfile {
      businessId
      createdTimestamp
      emailVerified
      enabled
      firstName
      lastName
      requestedGroup
      username
      id
      companyIds
    }
  }
`;

export type UserProfile = {
  id: string;
  createdTimestamp: string;
  username: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  requestedGroup: string;
  businessId: string;
  enabled: boolean;
  companyIds: string[];
};

export type GetUserProfilesResult = {
  userFullProfile: UserProfile[];
};

export default getUserProfilesQuery;
