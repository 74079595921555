import React, { useState } from "react";

import { DataHubButton } from "../DataHubButton";
import { DataHubPopOver } from "../DataHubPopOver";
import Icon from "../Icon";
import { ImageUploadError } from "../../types";
import { InvalidImageDescriptionContainer } from "./InvalidImageDescriptionContainer";
import styled from "styled-components";
import { useIsSmallScreen } from "../MediaQuery";
import { useTranslation } from "react-i18next";

const StyledModalButton = styled(DataHubButton)`
  color: var(--color-primary-dark);
  text-decoration: underline;
`;

const PopoverBodyContainer = styled.div`
  max-height: 30em;
  overflow-y: auto;
  white-space: pre-wrap;
`;

type ImageUploadErrorPopoverProps = {
  invalidImages: ImageUploadError[];
};

export const ImageUploadErrorPopover = ({ invalidImages }: ImageUploadErrorPopoverProps) => {
  const { t } = useTranslation();
  const isMobile = useIsSmallScreen();
  const [show, setShow] = useState(false);

  return (
    <div className="mt-3">
      <DataHubPopOver
        icon="warning-triangle"
        show={show}
        trigger="click"
        placement="bottom"
        variant="basic"
        header={t("imageUpload.failedUploads")}
        customStyle={isMobile ? undefined : { maxWidth: "60%" }}
        customClassName="datahub-image-upload-error-popover"
        triggerButton={
          <StyledModalButton
            className="font-light"
            variant="ghost-pink"
            iconElement={
              <Icon
                name="warning-triangle"
                size="medium"
                className="mr-2"
                style={{ marginTop: "-3px" }}
              />
            }
            onClick={() => {
              setShow(!show);
            }}
          >
            {t("imageUpload.failedUploadsNotificationButton")}
          </StyledModalButton>
        }
      >
        <PopoverBodyContainer>
          {invalidImages.map((image, index) => (
            <div className="d-flex flex-row mt-2" key={index}>
              <InvalidImageDescriptionContainer image={image} />
            </div>
          ))}
        </PopoverBodyContainer>
      </DataHubPopOver>
    </div>
  );
};
