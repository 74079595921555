import { gql } from "@apollo/client";

import { QueryFilterVariables } from "../utils/filterUtils";
import fragments from "./fragments";
import {
  allCurationFilter,
  approvedAndUpdatedCurationFilter,
  approvedCurationFilter,
  getProductQueryFilters,
  getQueryName,
  newCurationFilter,
  pendingCurationFilter,
  updatedCurationFilter,
} from "./productFilters";

const buildGetProductsWithoutCuratorQuery = (filters: QueryFilterVariables) => {
  const activeFilters = getProductQueryFilters(filters);

  return gql`
  query ${getQueryName("getProductsWithoutCurator", filters)}(
    $orderBy: order_by!
    $offset: Int!
    $limit: Int!
    ${filters.type ? "$type: [hasuradb_product_types_enum!]!" : ""}
    ${filters.tags ? "$tags: [String!]!" : ""}
    ${filters.search ? "$search: String!" : ""}
    ${filters.targetGroups ? "$targetGroups: [hasuradb_target_groups_enum!]!" : ""}
    ${filters.languages ? "$languages: [hasuradb_languages_enum]" : ""}
    ${filters.cities ? "$cities: [uuid!]!" : ""}
    ${filters.accessible ? "$accessible: Boolean!" : ""}
    ${filters.months ? "$months: [hasuradb_months_enum!]!" : ""}
    ${filters.certificates ? "$certificates: [String!]!" : ""}
    ${filters.curationStatus ? "$curationStatus: [hasuradb_curation_status_enum!]!" : ""}
    ${
      filters.productCurationStatus
        ? "$productCurationStatus: [hasuradb_product_curation_status_enum!]!"
        : ""
    }
  ) {
    products:hasuradb_products_no_curator(
      where: ${activeFilters}
      order_by: { updatedAt: $orderBy }
      limit: $limit
      offset: $offset
    ) {
      ...CuratorProductData
      ...ProductCommentsCount
      ...ProductNewCommentsCountDmo
    }

    new:hasuradb_products_no_curator_aggregate(where: ${getProductQueryFilters(
      filters,
      newCurationFilter
    )} ) {
      aggregate {
        count
      }
    }
    updated:hasuradb_products_no_curator_aggregate(where: ${getProductQueryFilters(
      filters,
      updatedCurationFilter
    )} ) {
      aggregate {
        count
      }
    }
    pending:hasuradb_products_no_curator_aggregate(where: ${getProductQueryFilters(
      filters,
      pendingCurationFilter
    )} ) {
      aggregate {
        count
      }
    }
    approved:hasuradb_products_no_curator_aggregate(where: ${getProductQueryFilters(
      filters,
      approvedCurationFilter
    )} ) {
      aggregate {
        count
      }
    }
    approvedAndUpdated:hasuradb_products_no_curator_aggregate(where: ${getProductQueryFilters(
      filters,
      approvedAndUpdatedCurationFilter
    )} ) {
      aggregate {
        count
      }
    }
    all:hasuradb_products_no_curator_aggregate(where: ${getProductQueryFilters(
      filters,
      allCurationFilter
    )} ) {
      aggregate {
        count
      }
    }
  }

  ${fragments.product.curatorProductData}
  ${fragments.product.commentsCount}
  ${fragments.product.newCommentsCountForDmo}
`;
};

export default buildGetProductsWithoutCuratorQuery;
