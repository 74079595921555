import styled from "styled-components";

type SeparatorProps = {
  topMarginRem?: number;
  bottomMarginRem?: number;
  leftMarginRem?: number;
  rightMarginRem?: number;
};

const Separator = styled.div<SeparatorProps>`
  border-bottom: 1px solid var(--color-gray-400);
  margin-top: ${({ topMarginRem }) =>
    topMarginRem !== undefined ? `${topMarginRem}rem;` : "2rem;"};
  margin-bottom: ${({ bottomMarginRem }) =>
    bottomMarginRem !== undefined ? `${bottomMarginRem}rem;` : "2rem;"};
  margin-left: ${({ leftMarginRem }) =>
    leftMarginRem !== undefined ? `${leftMarginRem}rem;` : "0"};
  margin-right ${({ rightMarginRem }) =>
    rightMarginRem !== undefined ? `${rightMarginRem}rem;` : "0"};`;

export default Separator;
