import React from "react";
import { useQuery } from "@apollo/client";
import { getOtherRolePages } from "../../graphqlQueries/InstructionsAndFaq/getRelatedInstructions";
import { Col, Row } from "react-bootstrap";
import DummyLink from "../DummyLink";
import { useHistory, useLocation } from "react-router-dom";
import { RelatedRolesResponse } from "../../types/instructionsAndFaqTypes";
import { useTranslation } from "react-i18next";

type RelatedRolePagesProps = {
  rolePageId: string;
  currentRole: string;
  reload: () => void;
};

const RelatedRolePages = ({ rolePageId, currentRole, reload }: RelatedRolePagesProps) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { data } = useQuery<RelatedRolesResponse>(getOtherRolePages, {
    variables: {
      currentRolePage: rolePageId,
      locale: [i18n.language === "cimode" ? "en" : i18n.language],
    },
    fetchPolicy: "cache-and-network",
  });

  const getPath = (slug: string) => {
    const lastIndex = pathname.lastIndexOf("/");

    if (currentRole !== slug) {
      return pathname.slice(0, lastIndex).replace(currentRole, slug);
    }

    return pathname.slice(0, lastIndex);
  };

  if (data?.rolePages) {
    return (
      <Row className="mt-5">
        <Col xs={12}>
          <h6>{t("ukk.relatedRolepages")}</h6>
        </Col>
        <Col>
          <ul className="pl-0" style={{ listStyleType: "none" }}>
            {data.rolePages.map((it, i) => {
              return (
                <li key={i}>
                  <DummyLink
                    onClick={() => {
                      history.push(`${getPath(it.slug)}/`);
                      reload();
                    }}
                  >
                    {it.title}
                  </DummyLink>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    );
  }

  return null;
};

export default RelatedRolePages;
