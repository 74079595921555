import { useLazyQuery } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import { StatusCodes } from "http-status-codes";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import LanguageDropdown from "../../components/LanguageDropdown";
import Loading from "../../components/Loading";
import { breakpointPixels } from "../../css/breakpoints";
import { getCompanyByBusinessId } from "../../graphqlQueries/getCompanyId";
import getUserInfoLocal, { UserInfoData } from "../../graphqlQueries/getUserInfoLocal";
import logo from "../../images/datahub-logo.svg";
import signupBackgroundImage from "../../images/pallas_background_1920.jpg";
import { CompanyInformation, UserRole } from "../../types";
import { getDatahubApiEndpoint, getHasuraRoleContext } from "../../utils/functions";
import CompanyExistsWizard from "./CompanyExistsWizard";
import CompanyInfoError from "./CompanyInfoError";
import CompanyNotFound from "./CompanyNotFound";
import CreateAccountWizard from "./CreateAccountWizard";

const WizardContainer = styled.main`
  @media (min-width: ${breakpointPixels.sm}) {
    max-width: 550px;
    padding: 48px 88px;
    box-shadow: 1px 2px 11px 0 rgba(0, 0, 0, 0.23);
    height: 100%;
    max-height: 90vh;
    min-height: 60vh;
    overflow-y: auto;
  }
  padding: 0 30px;
  width: 100%;
  height: 100vh;
  background: var(--color-white);
`;

const SignUpContainerContainer = styled.div`
  @media (min-width: ${breakpointPixels.sm}) {
    position: fixed;
    background: url(${signupBackgroundImage}) no-repeat center center fixed;
    background-size: cover;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const crmCompanyFetchUrl = `${getDatahubApiEndpoint()}/crm/company?businessId=`;

type BusinessCompany = {
  id: string;
  officialName: string;
  businessName: string;
};

type BusinessEntity = {
  businessEntity: { companies: BusinessCompany[] }[];
};

type CompanyInfoStatus = "NotInitialized" | "Loading" | "OK" | "NotFound" | "Duplicate" | "Error";

const Wizard = () => {
  const [companyInfoStatus, setCompanyInfoStatus] = useState<CompanyInfoStatus>("NotInitialized");
  const [companyInformation, setCompanyInformation] = useState<CompanyInformation>();

  const { keycloak } = useKeycloak();
  const { data: userInfoData } = useQuery<UserInfoData>(getUserInfoLocal);

  const { t } = useTranslation();

  const [
    getBusinessData,
    {
      data: businessData,
      called: businessDataCalled,
      error: businessDataError,
      loading: businessDataLoading,
    },
  ] = useLazyQuery<BusinessEntity>(getCompanyByBusinessId);

  const userToken = keycloak?.token;
  const companyCheck = useCallback(
    async (businessId: string) => {
      if (userToken) {
        try {
          setCompanyInfoStatus("Loading");
          const response = await fetch(crmCompanyFetchUrl + businessId, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          });
          const statusCode = response.status;
          if (statusCode === StatusCodes.OK) {
            const data = await response.json();
            const companyData = data.dataFromCrm[0];

            setCompanyInformation({
              companyName: companyData.name,
              businessId: companyData.businessid,
            });
          } else if ([StatusCodes.BAD_REQUEST, StatusCodes.NOT_FOUND].includes(statusCode)) {
            setCompanyInfoStatus("NotFound");
            setCompanyInformation(undefined);
          } else {
            setCompanyInfoStatus("Error");
            setCompanyInformation(undefined);
          }
        } catch (error) {
          setCompanyInfoStatus("Error");
          console.error(error);
        }
      }
    },
    [userToken]
  );

  const businessId = userInfoData?.userInfo?.businessId;

  useEffect(() => {
    if (businessId) {
      companyCheck(businessId);
    } else {
      setCompanyInfoStatus("NotFound");
    }
  }, [businessId, companyCheck]);

  useEffect(() => {
    if (companyInformation?.businessId) {
      getBusinessData({
        context: getHasuraRoleContext(UserRole.CreateCompany),
        variables: { businessId: companyInformation.businessId },
      });
    }
  }, [companyInformation, getBusinessData]);

  useEffect(() => {
    if (businessDataCalled && !businessDataLoading) {
      if (businessDataError) {
        setCompanyInfoStatus("Error");
      } else if (businessData && businessData.businessEntity.length > 0) {
        setCompanyInfoStatus("Duplicate");
      } else {
        setCompanyInfoStatus("OK");
      }
    }
  }, [businessData, businessDataCalled, businessDataError, businessDataLoading]);

  return (
    <div className="d-flex justify-content-center mt-0 mt-md-5">
      <WizardContainer>
        <header className="d-flex justify-content-between mb-3">
          <img style={{ height: "40px" }} alt="Visit Finland data hub" src={logo} />
          <div className="d-flex">
            <LanguageDropdown />
            <Button
              className="ml-1"
              variant="link"
              onClick={() => {
                keycloak?.logout();
              }}
            >
              {t("common.logout")}
            </Button>
          </div>
        </header>
        {companyInfoStatus === "Loading" ? (
          <Loading />
        ) : (
          <div className="d-flex flex-column justify-content-center">
            {companyInfoStatus === "OK" && (
              <CreateAccountWizard companyInformation={companyInformation} />
            )}
            {companyInfoStatus === "Duplicate" && (
              <CompanyExistsWizard
                companyInformation={companyInformation}
                companies={businessData?.businessEntity[0].companies}
              />
            )}
            {companyInfoStatus === "NotFound" && <CompanyNotFound businessId={businessId} />}
            {companyInfoStatus === "Error" && <CompanyInfoError />}
            {companyInfoStatus !== "OK" && companyInfoStatus !== "Duplicate" && (
              <Button
                className="mt-3"
                variant="primary"
                onClick={() => {
                  keycloak?.logout();
                }}
              >
                {t("signup.frontPage")}
              </Button>
            )}
          </div>
        )}
      </WizardContainer>
    </div>
  );
};

const SignUp = () => {
  return (
    <SignUpContainerContainer className="signup-backgroung-image">
      <Wizard />
    </SignUpContainerContainer>
  );
};

export default SignUp;
