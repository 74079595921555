import DropdownTreeSelect, { TreeNode } from "react-dropdown-tree-select";
import { ProductCategory, UserRole } from "../types";
import React, { useMemo, useState } from "react";
import { getHasuraRoleContext, labelCompare } from "../utils/functions";
import getTagsWithCategories, { TagsQueryResult } from "../graphqlQueries/getTagsWithCategories";

import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { sortCategoryGroups } from "./productForm/ProductFormCategorySelects";

type ProductCategorySelectProps = {
  onChange: (selectedOptions: ProductCategory[]) => void;
  onBlur?: () => void;
  values: ProductCategory[] | null;
  disabled?: boolean;
};

const ProductCategorySelect = ({ onChange, values = [], disabled }: ProductCategorySelectProps) => {
  const { data, loading } = useQuery<TagsQueryResult>(getTagsWithCategories, {
    context: getHasuraRoleContext(UserRole.ViewOpenProducts),
  });
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string[]>([]);

  const categoryGroups = data?.categoryGroup;

  const groupedTags = useMemo(() => {
    if (!categoryGroups) {
      return [];
    }

    return categoryGroups
      .map((categoryGroup) => ({
        label: t(`categoryGroups.${categoryGroup.groupName}`),
        groupName: categoryGroup.groupName,
        value: categoryGroup.groupName,
        className: "groupHeader",
        expanded: expanded.includes(categoryGroup.groupName),
        children: categoryGroup.tags
          .map(({ tag }) => ({
            label: t(`tags.${tag}`),
            value: tag,
            className: "checkboxItem",
            checked: values?.find((v) => v.value === tag),
          }))
          .sort((a, b) => {
            if (a.label.startsWith("Other")) {
              return 1;
            }

            if (b.label.startsWith("Other")) {
              return -1;
            }

            return labelCompare(a, b);
          }),
      }))
      .sort(sortCategoryGroups);
  }, [categoryGroups, t, values, expanded]);

  if (loading) {
    return null;
  }

  const selectOnChange = (_: TreeNode, selectedNodes: TreeNode[]) => {
    onChange(
      selectedNodes.map((node) => ({
        label: node.label,
        value: node.value,
      }))
    );
  };

  return (
    <DropdownTreeSelect
      data={groupedTags}
      onNodeToggle={(node) => {
        if (node.expanded) {
          setExpanded([...expanded, node.value]);
        } else {
          setExpanded(
            expanded.filter((n) => {
              return n !== node.value;
            })
          );
        }
      }}
      texts={{
        placeholder: t("select.placeholder"),
        noMatches: t("select.noMatchesFound"),
      }}
      onChange={selectOnChange}
      keepOpenOnSelect
      keepTreeOnSearch={true}
      clearSearchOnChange={true}
      disabled={disabled}
    />
  );
};

export default ProductCategorySelect;
