import React, { useState } from "react";
import styled, { css } from "styled-components";

import { DataHubButton } from "../../DataHubButton";
import { DataHubPopOver } from "../../DataHubPopOver";
import { HasuradbProduct } from "../../../graphqlQueries/getProduct";
import Icon from "../../Icon";
import ProductListItemIconContainer from "../../products/ProductListItemIconContainer";
import ProductListItemLanguages from "../../ProductListItemLanguages";
import Thumbnail from "../../Thumbnail";
import { dayMonthYearFormat } from "../../../utils/format";
import { getProductTypeHeaderKey } from "../../../utils/localizationUtils";
import moment from "moment";
import { useIsSmallScreen } from "../../MediaQuery";
import { useTranslation } from "react-i18next";

type ListItemContainerProps = {
  selected: boolean;
  isPreview?: boolean;
};

const containerStyles = css<ListItemContainerProps>`
  outline: none;
  width: 100%;
  border: 0;
  &:active,
  &:focus {
    outline: 0;
  }
`;

export const ProductListItemHeader = styled.h2`
  text-align: left;
  margin-bottom: 0.25rem;
  color: var(--color-gray-900);
  font-size: 1rem;
  max-width: 50%;
`;

export const ListItemContainer = styled.div<ListItemContainerProps>`
  ${containerStyles}
  border-left: ${({ isPreview, selected }) =>
    isPreview ? "none" : `4px solid var(${selected ? "--color-gray-600" : "--color-gray-200"})`};
  border-bottom: 1px solid var(--color-gray-200);
  padding: 1rem;
  background: ${({ selected }) => (selected ? "var(--color-gray-200)" : "none")}
  
  ${({ isPreview }) =>
    isPreview &&
    `
    margin-bottom: 1em;
    background-color: var(--color-gray-100);
    border: 1px solid var(--color-gray-400);
    `}
`;

const ItemSelectButton = styled.button<ListItemContainerProps>`
  ${containerStyles}
  background: none;
  padding: 0;
`;

export const ItemDetailsContainer = styled.div<ListItemContainerProps>`
  ${containerStyles}
  padding: 0;
`;

type AddProductsProductListItemProps = {
  item: HasuradbProduct;
  selected: boolean;
  onItemClick?: (id: string) => void;
  onButtonClick: (product: HasuradbProduct) => void;
  canManage?: boolean;
  addedNew?: boolean;
  addedOld?: boolean;
  isPreview?: boolean;
  isLocked?: boolean;
  hasBeenConfirmed?: boolean;
  onEdit?: () => void;
  tcCompanyIds?: string[];
};

const AddProductsProductListItem = ({
  item,
  selected,
  onItemClick,
  onButtonClick,
  addedNew = false,
  addedOld = false,
  isPreview = false,
  isLocked = false,
  hasBeenConfirmed = false,
  canManage = false,
  onEdit,
  tcCompanyIds,
}: AddProductsProductListItemProps) => {
  const { t } = useTranslation();
  const isSmallScreen = useIsSmallScreen();
  const coverImage =
    item?.productImages?.find((image) => image.coverPhoto) ?? item?.productImages?.[0];
  const [showRemoveProductPopOver, setShowRemoveProductPopOver] = useState<boolean>(false);
  const disableRemoval =
    !canManage && tcCompanyIds && !tcCompanyIds?.find((id) => id === item.company.id);
  const notAdded = addedNew === false && addedOld === false;

  const DetailsContainer: React.FC = ({ children }) => {
    if (onItemClick) {
      return (
        <ItemSelectButton selected={selected} onClick={() => onItemClick(item.id)}>
          {children}
        </ItemSelectButton>
      );
    } else {
      return <ItemDetailsContainer selected={selected}>{children}</ItemDetailsContainer>;
    }
  };

  const ActionButton = () => {
    if (isLocked) {
      return null;
    }

    if (isPreview) {
      if (hasBeenConfirmed) {
        return (
          <DataHubPopOver
            icon="warning-triangle"
            show={showRemoveProductPopOver}
            trigger="click"
            placement="top"
            variant="confirmAndCancel"
            onCancel={() => setShowRemoveProductPopOver(false)}
            onConfirm={() => onButtonClick(item)}
            cancelText={t("common.cancel")}
            confirmText={t("common.confirm")}
            header={t("groupedProducts.removeProductPopoverHeader")}
            triggerButton={
              <DataHubButton
                iconElement={<Icon name="trash" size="medium" style={{ marginTop: "-3px" }} />}
                variant="secondary"
                size="sm"
                onClick={() => setShowRemoveProductPopOver(true)}
                disabled={disableRemoval}
              >
                {t("common.delete")}
              </DataHubButton>
            }
          >
            {t("groupedProducts.removeProductPopoverDescription")}
          </DataHubPopOver>
        );
      } else {
        return (
          <DataHubButton
            iconElement={<Icon name="trash" size="medium" style={{ marginTop: "-3px" }} />}
            variant="secondary"
            size="sm"
            onClick={() => onButtonClick(item)}
            disabled={disableRemoval}
          >
            {t("common.delete")}
          </DataHubButton>
        );
      }
    }

    if (notAdded) {
      return (
        <DataHubButton
          className="rounded-pill"
          variant="secondary"
          size="sm"
          onClick={() => {
            onButtonClick(item);
          }}
        >
          {t("groupedProducts.modalAddToProductGroupButton")}
        </DataHubButton>
      );
    }

    return (
      <DataHubButton
        iconElement={
          <Icon name="check" size="medium" style={{ marginTop: "-3px", marginRight: "6px" }} />
        }
        variant={"secondary"}
        className="rounded-pill bg-primary-dark border-primary-dark"
        size="sm"
        onClick={() => {
          onButtonClick(item);
        }}
        disabled={addedOld}
      >
        {t("groupedProducts.modalProductAdded")}
      </DataHubButton>
    );
  };

  return (
    <ListItemContainer key={item.id} selected={selected} isPreview={isPreview}>
      <div className="d-flex justify-content-between flex-wrap-reverse">
        <div className="mr-4">
          <div className="color-gray-600">{moment(item.updatedAt).format(dayMonthYearFormat)}</div>
        </div>
        <div className="d-flex flex-row mb-2">
          {onEdit && (
            <DataHubButton
              iconElement={<Icon name="edit" size="medium" style={{ marginTop: "-3px" }} />}
              variant="secondary"
              size="sm"
              disabled={isLocked || disableRemoval}
              onClick={() => onEdit()}
              className="mr-2"
            >
              {t("common.edit")}
            </DataHubButton>
          )}
          <ActionButton />
        </div>
      </div>

      <DetailsContainer>
        <div className="d-flex w-100">
          <div className="pl-0 mr-4">
            <Thumbnail
              image={coverImage}
              showDelete={false}
              size="small"
              altText={coverImage?.altText ?? ""}
              listItemStyle
            />
          </div>
          <div className="w-100">
            <div className="mb-1 mr-1">
              <ProductListItemHeader className="clamp-2">
                {item.productInformations[0].name}
              </ProductListItemHeader>
            </div>
            <div className="my-1 text-small text-left">{item.company.businessName}</div>
            <div className="my-1">
              <ProductListItemIconContainer iconName="map-marker-alt-solid">
                {item.postalAddresses[0]?.city ?? ""}
              </ProductListItemIconContainer>
            </div>
            {isSmallScreen && <div>{t(getProductTypeHeaderKey(item.type))}</div>}
            <div className="d-flex justify-content-between">
              <div className="px-0">
                <ProductListItemLanguages
                  languages={item.productInformations.map((information) => information.language)}
                />
              </div>
              {!isSmallScreen && (
                <div className="text-right mr-1">{t(getProductTypeHeaderKey(item.type))}</div>
              )}
            </div>
          </div>
        </div>
      </DetailsContainer>
    </ListItemContainer>
  );
};

export default AddProductsProductListItem;
