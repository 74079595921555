import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useState } from "react";

import { DataHubButton } from "../DataHubButton";
import { DataHubPopOver } from "../DataHubPopOver";
import Icon from "../Icon";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StickyMenu = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 950;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 22%);
`;

type GroupFormToolbarProps = {
  rootPagePath: string;
  isEditing?: boolean;
  shouldDisableSave?: boolean;
  canRemove?: boolean;
  onRemove?: () => void;
  shouldEnableLockingControls: boolean;
  isLocked?: boolean;
  onLock?: () => void;
  onUnLock?: () => void;
  hasUnSavedChanges: boolean;
  setShouldAllowPrompt: (value: boolean) => void;
};

const GroupFormToolbar: React.FunctionComponent<GroupFormToolbarProps> = ({
  rootPagePath,
  isEditing,
  shouldDisableSave,
  canRemove,
  onRemove,
  shouldEnableLockingControls,
  isLocked,
  onLock,
  onUnLock,
  hasUnSavedChanges,
  setShouldAllowPrompt,
}) => {
  const [showRemovalPopover, setShowRemovalPopover] = useState<boolean>(false);
  const [showUnLockPopover, setUnLockPopOver] = useState<boolean>(false);
  const [showDiscardChangesPopover, setShowDiscardChangesPopover] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <StickyMenu className="d-flex bg-white border-bottom-gray-300 p-4 justify-content-end">
      {
        // Show when editing an existing group
        !isLocked && (
          <DataHubPopOver
            icon="warning-triangle"
            show={showDiscardChangesPopover}
            trigger="click"
            placement="top"
            variant="confirmAndCancel"
            onCancel={() => {
              setShouldAllowPrompt(true);
              return setShowDiscardChangesPopover(false);
            }}
            onConfirm={() => {
              history.push(rootPagePath);
            }}
            cancelText={t("common.cancel")}
            confirmText={t("common.confirm")}
            header={t("productInfo.discardPopoverHeader")}
            triggerButton={
              <DataHubButton
                className="mr-3"
                variant="ghost"
                disabled={!hasUnSavedChanges}
                onClick={() => {
                  setShouldAllowPrompt(false);
                  setShowDiscardChangesPopover(true);
                }}
              >
                {t("groupedProducts.discardChanges")}
              </DataHubButton>
            }
          >
            {t("productInfo.discardPopoverDescription")}
          </DataHubPopOver>
        )
      }
      {isEditing && onRemove && canRemove && (
        <DataHubPopOver
          icon={"warning-triangle"}
          show={showRemovalPopover}
          trigger="click"
          placement="top"
          variant="confirmAndCancel"
          onCancel={() => setShowRemovalPopover(false)}
          onConfirm={() => {
            onRemove();
            setShowRemovalPopover(false);
          }}
          cancelText={t("common.cancel")}
          confirmText={t("common.delete")}
          header={t("groupedProducts.removeGroupHeader")}
          triggerButton={
            <DataHubButton
              variant="ghost"
              className="mr-4"
              onClick={() => setShowRemovalPopover(true)}
            >
              {t("groupedProducts.remove")}
            </DataHubButton>
          }
        >
          {t("groupedProducts.removeGroupHeaderBody")}
        </DataHubPopOver>
      )}
      {
        // Show when creating a new group
        !isLocked && (
          <DataHubButton
            className="mr-3"
            variant="primary"
            disabled={shouldDisableSave}
            onClick={() => {
              setShouldAllowPrompt(false);
            }}
            type="submit"
          >
            {t("groupedProducts.save")}
          </DataHubButton>
        )
      }
      {!isLocked && onLock && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="lock-product-tooltip">{t("groupedProducts.lockToolTip")}</Tooltip>}
        >
          <span>
            <DataHubButton
              variant="primary"
              disabled={!shouldEnableLockingControls}
              style={shouldEnableLockingControls ? {} : { pointerEvents: "none" }}
              iconElement={<Icon name="lock" size="medium" />}
              onClick={() => onLock()}
            >
              {t("groupedProducts.lockGroup")}
            </DataHubButton>
          </span>
        </OverlayTrigger>
      )}
      {isLocked && onUnLock && (
        <DataHubPopOver
          icon={"warning-triangle"}
          show={showUnLockPopover}
          trigger="click"
          placement="top"
          variant="confirmAndCancel"
          onCancel={() => setUnLockPopOver(false)}
          onConfirm={() => {
            onUnLock();
            setUnLockPopOver(false);
          }}
          confirmButtonVariant="secondary"
          cancelButtonVariant="ghost"
          cancelText={t("common.cancel")}
          confirmText={t("common.confirm")}
          header={t("groupedProducts.groupUnLockPopOverHeader")}
          triggerButton={
            <DataHubButton
              variant="secondary"
              iconElement={<Icon name="unlock" size="medium" />}
              onClick={() => setUnLockPopOver(true)}
            >
              {t("groupedProducts.unLockGroup")}
            </DataHubButton>
          }
        >
          {t("groupedProducts.groupUnLockPopOverBody")}
        </DataHubPopOver>
      )}
    </StickyMenu>
  );
};

export default GroupFormToolbar;
