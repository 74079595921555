import { gql } from "@apollo/client";

const getUserProfilesByCompanyQuery = gql`
  query getUserProfilesByCompany($companyId: uuid!) {
    userFullProfileByCompany(companyId: $companyId) {
      firstName
      lastName
      username
      id
    }
  }
`;

export type BasicUserProfile = {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
};

export type GetUserProfilesByCompanyResult = {
  userFullProfileByCompany: BasicUserProfile[];
};

export type GetUserProfilesByCompanyVariables = {
  companyId: string;
};

export default getUserProfilesByCompanyQuery;
