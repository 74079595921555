import { gql } from "@apollo/client";

const getCompanyNamesQuery = gql`
  query Companies($companyIds: [uuid!]) {
    company(where: { id: { _in: $companyIds } }, order_by: { businessName: asc }) {
      id
      officialName
      businessName
    }
  }
`;

export interface CompanyNamesQueryVars {
  companyIds: string[];
}

export interface Company {
  id: string;
  officialName: string;
  businessName: string;
}

export interface CompaniesResult {
  company: Company[];
}

export default getCompanyNamesQuery;
