import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import Icon from "./Icon";

type IconContainerProps = {
  left: boolean;
};

const defaultInputPadding = "0.75rem";
const iconContainerWidth = "40px";

const IconContainer = styled.div<IconContainerProps>`
  position: absolute;
  top: 0px;
  ${({ left }) => (left ? "left: 0px;" : "right: 0px;")}
  width: ${iconContainerWidth};
  height: 100%;
  z-index: 110;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type IconInputProps = {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  placeholder?: string;
  className?: string;
  iconPosition?: "right" | "left";
  icon: React.ReactNode;
};

const IconInput = ({
  value,
  onChange,
  onClear,
  placeholder,
  icon,
  iconPosition = "left",
}: IconInputProps) => {
  return (
    <div className="position-relative">
      <Form.Control
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{
          paddingRight: iconPosition === "left" ? defaultInputPadding : iconContainerWidth,
          paddingLeft: iconPosition === "left" ? iconContainerWidth : defaultInputPadding,
        }}
      />
      <IconContainer left={iconPosition === "left"}>{icon}</IconContainer>
      {onClear && (
        <IconContainer left={false} onClick={onClear}>
          <Icon
            name="times"
            size="medium"
            className="cursor-default font-heavy"
            style={{ width: 12, height: 12 }}
          />
        </IconContainer>
      )}
    </div>
  );
};

export default IconInput;
