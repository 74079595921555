import React from "react";
import { useQuery } from "@apollo/client";
import { Col, Row, Spinner } from "react-bootstrap";
import Footer from "../../components/Footer";
import { IntroductionContainer } from "../LandingPage";
import RoleCard from "../../components/faq/RoleCard";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getRolePages } from "../../graphqlQueries/InstructionsAndFaq/getRolePages";
import { getInstructionPages } from "../../graphqlQueries/InstructionsAndFaq/getInstructionPages";
import {
  BaseData,
  InstructionPageResponse,
  RolePage,
  RolePageCard,
} from "../../types/instructionsAndFaqTypes";
import { useIsSmallScreen } from "../../components/MediaQuery";
import InstructionSearch from "../../components/faq/InstructionSearch";
import { useLocation } from "react-router-dom";
import { InstructionPageHolder } from "../../components/faq/InstructionPageHolder";

const LandingTextContainer = styled.div<{ bg: string }>`
  background-image: ${({ bg = "" }) => `url(${bg})`};
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  height: 240px;
  flex-direction: column;
`;
const IntroductionHeader = styled.h1`
  font-size: 35px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-black);
`;

const InstructionsAndFaq = () => {
  const { i18n, t } = useTranslation();
  const { pathname } = useLocation();
  const isMobile = useIsSmallScreen();

  const { data: preview, loading: loadingPreview, error: errorPreview } = useQuery<RolePageCard>(
    getRolePages,
    {
      variables: {
        locale: [i18n.language === "cimode" ? "en" : i18n.language],
      },
      fetchPolicy: "network-only",
    }
  );

  const {
    data: instructionData,
    loading: loadingInstructionPage,
    error: errorInstructionPage,
  } = useQuery<InstructionPageResponse>(getInstructionPages, {
    variables: {
      locale: [i18n.language],
    },
    fetchPolicy: "network-only",
  });

  const roleCardData = (item: RolePage): BaseData[] | undefined => {
    if (!item.instructionsComponent) {
      return [];
    }

    const instructions = item.instructionsComponent
      .filter((it) => it.subTitleInstructions && it.subTitleInstructions?.length > 0)
      .map((it) => it.subTitleInstructions);

    return instructions.reduce((prev, curr) => {
      if (!prev || !curr) {
        return prev;
      }
      return prev.concat(curr);
    }, []);
  };

  return (
    <InstructionPageHolder>
      {loadingPreview || loadingInstructionPage ? (
        <Row className="justify-content-center my-5">
          <Spinner animation="border" />
        </Row>
      ) : (
        <>
          {instructionData ? (
            <LandingTextContainer
              bg={
                isMobile
                  ? instructionData.instructionPages[0]?.imageMobile.url
                  : instructionData.instructionPages[0]?.image.url
              }
            >
              <>
                <IntroductionHeader className="my-auto">
                  {instructionData.instructionPages[0]?.title}
                </IntroductionHeader>
              </>
            </LandingTextContainer>
          ) : (
            <LandingTextContainer bg="">
              <>
                {/*fall back text if nothing else*/}
                <IntroductionHeader className="my-auto">{t("ukk.header")}</IntroductionHeader>
              </>
            </LandingTextContainer>
          )}
          <IntroductionContainer>
            <Row className="justify-content-center">
              <Col xs={6} className="text-center">
                <h2 className="h4">{t("ukk.searchHeader")}</h2>
              </Col>
            </Row>
            <Row className="justify-content-center mb-5">
              <Col xs={6}>
                <InstructionSearch pathname={pathname} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-5">
                {errorPreview || errorInstructionPage ? (
                  <InstructionPageHolder>
                    <IntroductionContainer className="alert-danger">
                      <p className="p-4">{t("common.error")}</p>
                    </IntroductionContainer>
                  </InstructionPageHolder>
                ) : (
                  <>
                    {preview?.rolePages &&
                      preview.rolePages.map((it, i) => (
                        <RoleCard
                          key={i}
                          role={it.title}
                          data={roleCardData(it) || []}
                          imageUrl={it.image.url}
                          slug={it.slug}
                        />
                      ))}
                  </>
                )}
              </Col>
            </Row>
          </IntroductionContainer>
          <Footer />
        </>
      )}
    </InstructionPageHolder>
  );
};

export default InstructionsAndFaq;
