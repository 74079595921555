import {
  DataHubImage,
  FileWithSize,
  ImageUploadError,
  ImageUploadErrorEnum,
  ImageUploadResult,
  InvalidImageReasonJson,
} from "../types";

// type predicate for validateImages result
export const isImageUploadError = (
  image: ImageUploadError | FileWithSize
): image is ImageUploadError => {
  return (image as ImageUploadError).type !== undefined;
};
// type predicate for validateImages result
export const isFilWithSize = (image: ImageUploadError | FileWithSize): image is FileWithSize => {
  return (image as FileWithSize).file !== undefined;
};

export const createObjectUrl = (file: File): Promise<FileWithSize> => {
  const image = new Image();
  image.src = URL.createObjectURL(file);
  return new Promise<FileWithSize>((resolve) => {
    image.addEventListener("load", () => {
      resolve({ file, width: image.width, height: image.height });
    });
  });
};

export const validateImages = (validator: (file: FileWithSize) => ImageUploadError | undefined) => {
  return (file: FileWithSize) => {
    const validate = validator(file);

    if (validate) {
      return validate;
    }

    return file;
  };
};

export const uploadImageForm = async (
  imageEndpoint: string,
  authToken: string | undefined,
  formData: FormData,
  images: DataHubImage[],
  onChange: (images: DataHubImage[]) => void,
  setInvalidImages: (error: ImageUploadError[]) => void
) => {
  try {
    const response = await fetch(imageEndpoint, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    });

    const { uploadedImages, invalidImages }: ImageUploadResult = await response.json();

    const allImages = [...images, ...uploadedImages];
    const coverImage = allImages.find((image) => image.coverPhoto) ?? allImages[0];
    onChange(
      allImages.map((img) => {
        return { ...img, coverPhoto: img === coverImage };
      })
    );

    if (invalidImages.length > 0) {
      // handle cases where image upload fails in backend due to validation errors
      const mappedInvalidImages: ImageUploadError[] = invalidImages.map((image) => {
        const toJSON = JSON.parse(image.reason.error) as InvalidImageReasonJson;

        return {
          type:
            toJSON.type === "min"
              ? ImageUploadErrorEnum.TooSmallDimensions
              : ImageUploadErrorEnum.UploadFailed,
          variables: {
            name: toJSON.name,
            width: String(toJSON.value.width),
            height: String(toJSON.value.height),
          },
        };
      });

      setInvalidImages(mappedInvalidImages);
    }
  } catch (e) {
    console.error(e);
    setInvalidImages([
      {
        type: ImageUploadErrorEnum.UploadFailed,
      },
    ]);
  }
};
