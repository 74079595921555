import { ImageUploadError, ImageUploadErrorEnum } from "../../types";
import { useTranslation } from "react-i18next";
import React from "react";

export const InvalidImageDescriptionContainer = ({ image }: { image: ImageUploadError }) => {
  const { t } = useTranslation();
  const isTooBig = image?.type === ImageUploadErrorEnum.TooBigFile;
  const isTooSmall = image?.type === ImageUploadErrorEnum.TooSmallDimensions;
  const isTooManyFiles = image?.type === ImageUploadErrorEnum.TooManyFiles;

  if (isTooBig) {
    return (
      <p>
        {t("imageUpload.fileTooBig", {
          imageName: image.variables?.name,
        })}
      </p>
    );
  }

  if (isTooManyFiles) {
    return <p>{t("imageUpload.tooManyImages")}</p>;
  }

  if (isTooSmall) {
    return (
      <p>
        {t("imageUpload.tooSmallDimensions", {
          imageName: image.variables?.name,
          width: image?.variables?.width,
          height: image?.variables?.height,
        })}
      </p>
    );
  }

  return <p>{t("imageUpload.uploadError")}</p>;
};
