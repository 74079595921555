import React from "react";

import { OpeningHours } from "../../types";
import { sortOpeningHours } from "../../utils/timeUtils";
import { NotAvailable } from "../products/NotAvailable";
import ViewProductHeader from "../products/ViewProductHeader";
import ViewProductOpeningHour from "../products/ViewProductOpeningHour";

type ViewOpeningHoursProps = {
  header: React.ReactNode;
  openingHours: OpeningHours[];
};

const ViewOpeningHours = ({ header, openingHours }: ViewOpeningHoursProps) => {
  return (
    <div className="d-flex flex-column">
      {typeof header === "string" ? <ViewProductHeader>{header}</ViewProductHeader> : header}
      {openingHours.length > 0 ? (
        sortOpeningHours(openingHours).map((openingHour) => (
          <ViewProductOpeningHour key={openingHour.weekday} openingHour={openingHour} />
        ))
      ) : (
        <NotAvailable />
      )}
    </div>
  );
};

export default ViewOpeningHours;
