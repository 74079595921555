import {
  FormSectionSubHeader,
  FormSubDescription,
  FormSubSection,
  FormSubSectionContent,
} from "../FormSection";
import { Form } from "react-bootstrap";
import InputContainer from "./InputContainer";
import React from "react";
import { useFormContext } from "react-hook-form";
import Checkbox from "../Checkbox";
import ExternalLink from "../ExternalLink";
import { useIsMediumOrSmaller } from "../MediaQuery";
import { useTranslation } from "react-i18next";
import { CertificateLogo } from "../products/CertificateLogo";
import { CDN_STF_LOGO } from "../../utils/constants";

const SustainabilitySection: React.FunctionComponent<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  const isMediumOrSmallerScreen = useIsMediumOrSmaller();

  return (
    <FormSubSection>
      <FormSubDescription>
        <FormSectionSubHeader>{t("productInfo.sustainabilitySubHeader")}</FormSectionSubHeader>
        {!disabled && (
          <>
            <p>{t("productInfo.sustainabilityDescription1")}</p>
            <p>
              {t("productInfo.sustainabilityDescription2")}
              <ExternalLink className="d-block" href={t("productInfo.sustainableReadMoreUrl")}>
                {t("productInfo.sustainableReadMoreLink")}
              </ExternalLink>
            </p>
          </>
        )}
      </FormSubDescription>
      <FormSubSectionContent>
        {!isMediumOrSmallerScreen && (
          <Form.Label>{t("productInfo.sustainabilityCertified")}</Form.Label>
        )}
        <CertificateLogo url={CDN_STF_LOGO} size="200" altText={t("productInfo.stfLogoAltText")} />
        <InputContainer>
          <Checkbox
            id="stfCertified"
            value="stfCertified"
            key="stfCertified"
            name="stfCertified"
            ref={register()}
            label={t("productInfo.sustainabilityCertifiedLabel")}
            disabled={disabled}
          />
        </InputContainer>
      </FormSubSectionContent>
    </FormSubSection>
  );
};

export default SustainabilitySection;
