import { gql } from "@apollo/client";

const joinCompany = gql`
  mutation JoinCompany($companyId: uuid!) {
    JoinCompany(companyId: $companyId) {
      message
      status
    }
  }
`;

export const insertJoinRequests = gql`
  mutation insertCompanyJoinRequest($objects: [hasuradb_company_join_request_insert_input!]!) {
    insertCompanyJoinRequest(objects: $objects) {
      returning {
        status
        id
      }
    }
  }
`;

export type JoinCompanyVariables = {
  companyId: string;
};

export type JoinCompanyResult = {
  JoinCompany: {
    message: string;
    status: JoinCompanyStatus;
  };
};

export type JoinRequestObject = {
  companyId: string;
  userId: string;
  userEmail: string;
};

export type JoinRequestResult = {
  id: string;
  status: JoinCompanyResult;
};

export enum JoinCompanyStatus {
  Pending = "pending",
  Approved = "approved",
}

export default joinCompany;
